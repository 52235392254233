import React, { Fragment, useEffect, useRef, useState } from "react";
import { Redirect } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import usersDao from "../services/settings/usersDao";
import WaitingRoundSnippers from "../common/smallComponents.js/WaitingRoundSnippers";
import errorManagement from "../services/errorManagement";
import { DesignedChckBox } from "../common/smallComponents.js/DesignedIpt";
import { getDeviceType, getHeight, scrollToTop } from "../functions/PageAnimation";
import SuccessMessage from "../common/smallComponents.js/SuccessMessage";
import SignaturePad from "react-signature-canvas";
import { getBase64 } from "../functions/StrFunctions";
import MySubordinates from "./settings/MySubordinates";

const Profile = (props) => {
  //const { user: currentUser } = useSelector((state) => state.auth);
  const [currentUser, setCurrentUser] = useState( JSON.parse(localStorage.getItem("user")) );
  const { t } = useTranslation();
  const [mySubordinates, setMySubordinates] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState();
  const [myRoles, setMyRoles] = useState();
  const [subordinatesRoles, setSubordinatesRoles] = useState([]);
  const [error, setError] = useState();
  const [globalIdUpdated, setGlobalIdUpdated] = useState(false);
  const [msg, setMsg] = useState();
  const sigRef = useRef();
  const [changeSignature, setchangeSignature] = useState(false);

  useEffect(() => {
    usersDao.getMySubordinates(currentUser.email).then(
      (response) => {
        setMySubordinates(response);
        setLoading(false);
      },
      (error) => {
        setError(errorManagement.handleError(props, error));
        setLoading(false);
      }
    );

    usersDao.getRolesByMail(currentUser.email).then(
      (response) => {
        setMyRoles(response)
      }
    )
  }, []);

  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  const changeStatut = (e) => {
    var user = e.target.getAttribute("data-user");
    var statut = e.target.value;

    usersDao.changeStatut(user, statut, currentUser.email).then(
      (response) => {
      }
    )
  }

  const editRole = (e) => {
    setSubordinatesRoles([]);
    setLoading(true);
    var username = e.target.getAttribute("data-user");
    var email = e.target.getAttribute("data-email");

    var json = {
      "user": username,
      "email": email
    }

    setSelectedUser(json);

    usersDao.getRolesByMail(email).then(
      (response) => {
        var arr = new Array();
        response.map(
          (v) => arr.push(v.name)
        )

        setSubordinatesRoles(arr)
        setLoading(false);
      },
      (error) => {
        setError(errorManagement.handleError(props, error))
        setLoading(false);
      }
    );
  }

  const closeRoleDiv = () => {
    setSelectedUser();
  }

  const updateAuthority = (e) => {

    var auth = e.target.value;
    var selected = e.target.checked;

    usersDao.updateSubordinateRole(selectedUser.email, auth, currentUser.email, selected).then(
      (response) => {
      },
      (error) => {
        setError(errorManagement.handleError(props, error));
      }
    );
  }

  const save = (e) => {
    var field = e.target.getAttribute("data-field");
    var value = e.target.value;
    setLoading(true);
    usersDao.updateMyProfileById(currentUser.id, field, value).then(
      () => {
        var o = { ...currentUser };
        o[field] = value;
        localStorage.setItem("user", JSON.stringify(o));
        setLoading(false);
      },
      (error) => {
        setError(errorManagement.handleError(props, error));
        setLoading(false);
      }
    );
  }


  /**
   * Special case
   * @param {*} e 
   */
  const onTrigramChange = (e) => {
    setLoading(true);
    usersDao.getUserInfoByTrigram(e.target.value.toUpperCase(), currentUser.id).then(
      (response) => {

        var o = { ...currentUser };
        o.focus_id = response.PMATRI;
        o.trigram = response.ZN01PP;
        o.installer = response.ZN06PP === "O" ? 1 : 0;
        o.executive = response.ZN05PP === "O" ? 1 : 0;
        o.interim = response.CDINT === "O" ? "Oui" : "Non";

        localStorage.setItem("user", JSON.stringify(o));
        setLoading(false);
      },
      (error) => {
        setError(errorManagement.handleError(props, error));
        scrollToTop();
        setLoading(false);
      }
    );
  }

  /** */
  const onCollaboChange = (k, field, e) => {
    var arr = [...mySubordinates];
    arr[k][field] = e.target.value;
    setMySubordinates(arr);
  }

  /** */
  const updateProfile = (k) => {
    setError();
    setLoading(true);
    usersDao.updateCollabo(mySubordinates[k]).then(
      (response) => {
        setMsg("Sauvegardé");
        setLoading(false);
      },
      (error) => {
        setError(errorManagement.handleError(props, error));
        setLoading(false);
      }
    );
  }

  const addSignatureForm = (e) => {
    e.preventDefault();
    getBase64( e.target.signature.files[0], function(val){
      updateSignature(val)
    })
  }

  const addSignaturePad = () => {
    if(!sigRef.current.isEmpty()){
      updateSignature(sigRef.current.getTrimmedCanvas().toDataURL("image/png"))
    }
  }

  function updateSignature(base64){
    
    setLoading(true);
    usersDao.updateMyProfileById(currentUser.id, "signature", base64).then(
      () => {
        var o = { ...currentUser };
        o.signature = base64;
        localStorage.setItem("user", JSON.stringify(o));
        setCurrentUser(o)
        setchangeSignature(false);
        setLoading(false);
      },
      (error) => {
        setError(errorManagement.handleError(props, error));
        setLoading(false);
      }
    );

  }

  return (
    <div >
      {
        error &&
        <div className="alert alert-danger text-center font-weight-bold" role="alert"> {error} </div>
      }
      
      <div className="accordion accordion-flush m-auto" id="accordion-profile">

      <h2 className="accordion-header">
      <button className={"accordion-button fw-bold" 
          + ( (mySubordinates && mySubordinates.length > 0) ? " collapsed" : "")} type="button" 
          data-bs-toggle="collapse" data-bs-target="#flush-collapseProfile" 
          aria-expanded="false" aria-controls="flush-collapseProfile">

        <h5>Mon profil</h5>
        </button>
      </h2>

      <div id="flush-collapseProfile" className={"accordion-collapse " 
              + ( (mySubordinates && mySubordinates.length > 0) ? " collapse" : "")}
            data-bs-parent="#accordion-profile">

            <div className="accordion-body">
      

      {loading && <WaitingRoundSnippers />}

      <div className="input-group mb-3">
        <span className="input-group-text" style={{ width: "200px" }}>Identifiant</span>
        <input type="text" className="form-control" defaultValue={currentUser.username} readOnly />

        <span className="input-group-text" style={{ width: "200px" }}>Mon Email</span>
        <input type="text" className="form-control" defaultValue={currentUser.email} readOnly />

        <span className="input-group-text" style={{ width: "200px" }}>Intérimaire</span>
        <input type="text" className="form-control"
          value={currentUser.interim === null ? "N/A" : currentUser.interim === 1 ? "Oui" : "Non"}
          readOnly />
      </div>


      <div className="input-group mb-3">
        <span className="input-group-text" style={{ width: "200px" }}>Email de mon manager</span>
        <input type="text" className="form-control" data-field="manager_mail" onBlur={save}
          defaultValue={currentUser.manager_email} readOnly={loading} />

        <span className="input-group-text" style={{ width: "200px" }}>Trigramme</span>
        <input type="text" className="form-control" defaultValue={currentUser.trigram}
          readOnly={loading} onBlur={onTrigramChange} />

        {
          currentUser.roles.includes("ROLE_INTERNAL") &&
          <Fragment>
            <span className="input-group-text" style={{ width: "200px" }}>Global ID</span>
            <input type="text" className="form-control" data-field="global_id"
              defaultValue={currentUser.global_id || ""} onBlur={save} readOnly={loading} />
          </Fragment>
        }
      </div>

      <div className="input-group mb-3">
        <span className="input-group-text" style={{ width: "200px" }}>Nom</span>
        <input type="text" className="form-control" data-field="last_name"
          onBlur={save} defaultValue={currentUser.last_name} readOnly={loading} />

        <span className="input-group-text" style={{ width: "200px" }}>Prénom</span>
        <input type="text" className="form-control" data-field="first_name"
          onBlur={save} defaultValue={currentUser.first_name} readOnly={loading} />

        <span className="input-group-text" style={{ width: "200px" }}>Poste de travail</span>
        <select className="form-select" defaultValue={currentUser.workstation}
          onChange={save} disabled={loading} data-field="workstation">
          <option selected>Choisissez...</option>
          <option value="Cartérisation">Cartérisation</option>
          <option value="Montage">Montage</option>
          <option value="Intégration">Intégration</option>
          <option value="Intégration électrique">Intégration électrique</option>
        </select>
      </div>

      <div className="mb-3">
        <div>
          <h6>Ma signature</h6>
          {currentUser.signature && <div>
          <img src={currentUser.signature}></img>
          <button className="btn btn-outline-secondary" onClick={() => setchangeSignature(true)}>Modifier</button>
          </div>}

          {!currentUser.signature && <div>
          <button className="btn btn-outline-secondary" onClick={() => setchangeSignature(true)}>Ajouter une signature</button>
          </div>}
        </div>
      </div>
      
      </div>
      </div>
      </div>
      {/** end */}
      
      {changeSignature && <div className="custom-tooltip-v2">
        {loading && <WaitingRoundSnippers />}
        <div className="modal-body blue-purple-bg">
        <button type="button" className="btn-close" onClick={()=>setchangeSignature(false)}></button>
          <h5 className="text-center">Créer votre signature avec votre
            {getDeviceType() === "desktop" ? " souris" : " doigt"}</h5>
            <div className="text-center mb-3">
            <SignaturePad ref={sigRef} canvasProps={{ className: "signatureCanvas" }} />
      
            <div className="text-center">
              <button className="btn btn-warning me-3" onClick={()=>sigRef.current.clear()}>Effacer</button>
              <button className="btn btn-success" onClick={addSignaturePad}>Enregistrer</button>
            </div>
          </div>

          <h5 className="text-center">Ou importez un fichier image de votre signature scannée</h5>
          <form className="mb-3" onSubmit={addSignatureForm}>
            <input type="file" className="form-control mb-3" name="signature"
              accept="image/png, image/jpeg" required></input>
            <div className="text-center">
              <button className="btn btn-success">Enregistrer</button>
            </div>
          </form>
        </div>

      </div>}


      {
        globalIdUpdated &&
        <div className="alert alert-success">Global ID mis à jour. Reconnectez-vous.</div>
      }

      {/** MY ACCESS */}
      <div className="accordion accordion-flush m-auto" id="accordion">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
              {t('my access')}:
            </button>
          </h2>
          <div id="flush-collapseOne" className="accordion-collapse collapse"
            data-bs-parent="#accordion" style={{ maxWidth: "300px" }}>
            <div className="accordion-body">
              {
                myRoles &&
                myRoles.map(
                  (v, k) =>
                    <li key={"myrol_" + k}>{v.description}</li>
                )
              }
            </div>
          </div>
        </div>
      </div>


      {loading && <WaitingRoundSnippers />}


      {
        (mySubordinates && mySubordinates.length > 0 ) &&
          <MySubordinates msg={msg} setMsg={setMsg} mySubordinates={mySubordinates}
            onCollaboChange={onCollaboChange} changeStatut={changeStatut}
            updateProfile={updateProfile} editRole={editRole}/>
      }

      {
        (myRoles && !loading) &&
        <div className="card center-screen" hidden={!selectedUser} style={{ zIndex: 2000, width: "800px" }}>
          <button type="button" className="btn-close fixed-top"  aria-label="Close" onClick={closeRoleDiv}></button>
          <div className="card-body overflow-x-auto">
            {
              myRoles.map(
                (v, k) =>
                  <div key={"r_" + k} className="input-group mb-3">
                    <div className="input-group-text">
                      <input className="form-check-input mt-0"
                        type="checkbox" value={v.role_id} onClick={updateAuthority}
                        defaultChecked={subordinatesRoles.includes(v.name)} />
                    </div>
                    <input type="text" className="form-control"
                      value={v.description} readOnly />
                  </div>
              )
            }
          </div>

        </div>
      }
    </div>
  );
};

export default Profile;
