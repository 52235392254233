import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL + "settings";

const getUserByName = (term) => {
    return axios
        .post(API_URL + "/users/list/byName", {
            fake_header: authHeader(),
            term: term
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getUserByRole = (id) => {
    return axios
        .post(API_URL + "/users/list/byRole", {
            fake_header: authHeader(),
            id: id
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getUserInfo = (id) => {
    return axios
        .post(API_URL + "/users/edit",
            {
                fake_header: authHeader(),
                id: id
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getUserInfoByTrigram = (trigram, id) => {
    return axios
        .post(API_URL + "/users/set-trigram",
            {
                fake_header: authHeader(),
                trigram: trigram,
                id: id
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateUserRole = (id_user, id_role, add) => {
    return axios
        .post(API_URL + "/users/role/update", {
            fake_header: authHeader(),
            user_id: id_user,
            role_id: id_role,
            add_role: add
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getMySubordinates = (email) => {
    return axios
        .post(API_URL + "/users/list/mySubordinates", {
            fake_header: authHeader(),
            email: email
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const changeStatut = (email, statut, myEmail) => {
    return axios
        .post(API_URL + "/users/mySubordinates/updateStatut", {
            fake_header: authHeader(),
            email: email,
            statut: statut,
            myEmail: myEmail
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getRolesByMail = (email, statut, myEmail) => {
    return axios
        .post(API_URL + "/users/mySubordinates/edit", {
            fake_header: authHeader(),
            email: email,
            myEmail: myEmail
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateSubordinateRole = (email, role_id, myEmail, add) => {
    return axios
        .post(API_URL + "/users/mySubordinates/roles/update", {
            fake_header: authHeader(),
            email: email,
            myEmail: myEmail,
            role_id: role_id,
            add: add
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const setGlobalId = (email, globalId) => {
    return axios
        .post(API_URL + "/users/edit/update", {
            fake_header: authHeader(),
            field: "global_id",
            email: email,
            value: globalId
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateMyProfile = (email, field, value) => {
    return axios
        .post(API_URL + "/users/edit/update", {
            fake_header: authHeader(),
            field: field,
            email: email,
            value: value
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateMyProfileById = (id, field, value) => {
    return axios
        .post(API_URL + "/users/edit/update/by-id", {
            fake_header: authHeader(),
            field: field,
            id: id,
            value: value
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getUserByTrigramFocus = (trigram) => {
    return axios
        .post(API_URL + "/users/get/focus/by-trigram", {
            fake_header: authHeader(),
            trigram: trigram
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getSalariesList = () => {
    return axios
        .post(API_URL + "/users/get/focus/salaries", {
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getFocusLicenses = (isAdmin) => {
    return axios
        .post(API_URL + "/focus/get-licences", {
            fake_header: authHeader(),
            isAdmin: isAdmin
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const liberateFocusLicenses = (GID) => {
    return axios
        .post(API_URL + "/focus/licences/liberate", {
            fake_header: authHeader(),
            GID: GID
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const bookFocusLicenses = (userInfo, code, password) => {
    return axios
        .post(API_URL + "/focus/licences/book", {
            fake_header: authHeader(),
            userInfo: userInfo,
            code: code,
            password: password
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const blockFocusLicenses = (code) => {
    return axios
        .post(API_URL + "/focus/licences/block", {
            fake_header: authHeader(),
            code: code
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateCollabo = (o) => {
    return axios
        .post(API_URL + "/users/manager/update", {
            fake_header: authHeader(),
            ...o
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getUserByTrigramMsql = (trigram) => {
    return axios
        .post(API_URL + "/users/get-by-trigram/msql", {
            fake_header: authHeader(),
            trigram: trigram
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

export default {
    getUserByName,
    getUserByRole,
    getUserInfo,
    updateUserRole,
    getMySubordinates,
    changeStatut,
    getRolesByMail,
    updateSubordinateRole,
    setGlobalId,
    updateMyProfile,
    updateMyProfileById,
    getUserInfoByTrigram,
    getUserByTrigramFocus, 
    getSalariesList,
    getFocusLicenses,
    liberateFocusLicenses,
    bookFocusLicenses,
    blockFocusLicenses,
    updateCollabo,
    getUserByTrigramMsql
}