
const InvoicedCustomer = (props) => {
    return (
        < div className="table-responsive">
            <table className="table businessCardStyle">
                <thead>
                    <tr className="table-dark text-center rounded">
                        <th scope="col" colSpan={3}>Invoiced customer</th>
                    </tr>
                </thead>
                <tbody>
                    <tr >
                        <td style={{ width: "33.3%" }} className="border-end">
                            <div>
                                <h6>Customer ID</h6>

                                <input type="text" className="form-control" defaultValue={props.businessInfo.customer_id}
                                    onBlur={props.updateBusinessField} list="customer_id" data-field="customer_id"
                                    readOnly={props.readOnly} />
                            </div>
                        </td>

                        <td style={{ width: "33.3%" }} className="border-end">
                            <div>
                                <h6>VAT number</h6>
                                <div className="text-center fw-bold">
                                    {props.focusInfo.invoiced_vat_number}
                                </div>
                            </div>
                        </td>

                        <td style={{ width: "33.3%" }}>
                            <h6>Type of customer</h6>

                            <input type="text" className="form-control" defaultValue={props.businessInfo.type_customer}
                                onBlur={props.updateBusinessField} list="type_customer" data-field="type_customer"
                                readOnly={props.readOnly} />
                        </td>
                    </tr>

                    <tr>
                        <td style={{ width: "33.3%" }} className="border-end">
                            <div>
                                <h6>Contact 1</h6>
                                <div className="text-center fw-bold">
                                    <span>{props.focusInfo.invoiced_name}</span>
                                    <br></br>
                                    <span>{props.focusInfo.invoiced_tel}</span>
                                    <br></br>
                                    <span>{props.focusInfo.invoiced_mail}</span>
                                    <br></br>
                                </div>

                                <input className="form-control" placeholder="Second e-mail"
                                 defaultValue={props.businessInfo.inv_cus_mail_2}
                                 onBlur={props.updateBusinessField} data-field="inv_cus_mail_2"
                                 readOnly={props.readOnly}/>
                            </div>
                        </td>

                        <td style={{ width: "33.3%" }} className="border-end">
                            <h6>Contact 2</h6>

                            <input type="text" className="form-control mb-1" defaultValue={props.businessInfo.inv_cus_name}
                                onBlur={props.updateBusinessField} 
                                placeholder="Name - Position" data-field="inv_cus_name"
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-1" defaultValue={props.businessInfo.inv_cus_tel}
                                onBlur={props.updateBusinessField} 
                                placeholder="Tel" data-field="inv_cus_tel"
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control" defaultValue={props.businessInfo.inv_cus_mail}
                                onBlur={props.updateBusinessField} 
                                placeholder="Mail" data-field="inv_cus_mail"
                                readOnly={props.readOnly} />
                        </td>

                        <td style={{ width: "33.3%" }}>
                            <h6>Invoice address</h6>
                            <div className="text-center fw-bold">
                                <span>{props.focusInfo.client_code}</span>
                                <br></br>
                                <span>{props.focusInfo.invoiced_address}</span>
                                {props.focusInfo.invoiced_comp_address && <span>{props.focusInfo.invoiced_comp_address}</span>}
                                <br></br>
                                <span>{props.focusInfo.invoiced_postal_code}</span>
                                <br></br>
                                <span>{props.focusInfo.invoiced_country}</span>
                                <br></br>
                                <span>{props.focusInfo.invoiced_del_mail}</span>
                                <br></br>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>

        </div>
    )
}

export default InvoicedCustomer;