import { Fragment, useState } from "react";
import { array_move, arrayRemoveElt, removeEltArray } from "../../../../functions/ArrayFunctions";
import { ButtonWaiting } from "../../../../functions/PageAnimation";
import { forceString } from "../../../../functions/StrFunctions";

const MachPicking = (p) => {
    const [cursor, setCursor] = useState("");
    const [toAdd, setToAdd] = useState();
    const [list, setList] = useState([]);
    
    /**
     * Insert Row
     */
    const insert = (e) => {
        e.preventDefault();

        var arr = [...p.picking.machines];
        var container = e.target.container.value;

        var full = [...p.picking.machines[toAdd.k].fullPicking];
        
        //Merge all tables to make continuous ID
        var merged = [];
        for(let i=0; i<p.picking.machines.length; i++ )
            merged = merged.concat(p.picking.machines[i].picking);
        
        //Get the index in entire table
        var idx = 0;
        for( let i=0; i<toAdd.k; i++ ) idx += p.picking.machines[i].picking.length;
        idx += (toAdd.k2 + 1);

        //If the inserted row must be inside machine, the level is one, else, it's id number + 1 level
        var lvl = container === "-1" ? 1 : forceString( container ).split(".").length + 1;
        for( let i=0; i<list.length; i++ ){
            merged = incrementVersionTree(merged, idx, full[ parseInt( list[i] ) ], lvl);
            idx++;
        }

        //dispatch the picking
        var mach = 0;
        var newPick = [];
        for( let i=0; i<merged.length; i++ ){
            var o = merged[i];
            console.log( arr[mach].machine.no_machine, o.machine )
            if( arr[mach].machine.no_machine !== o.machine ){
                arr[mach].picking = newPick;
                newPick = [];
                mach++;
            }

            newPick.push(o);
        }

        arr[mach].picking = newPick;
        
        var o = { ...p.picking };
        o.machines = arr;
        p.setPicking(o)

        setToAdd();
        setList([]);

        p.setChangeDone(true);
    }

    const insertSpecific = (e) => {
        e.preventDefault();
        
        var arr = [...p.picking.machines];

        var o = {
            "av": "MA_OPT000_00001",
            "ar": "PA_SPE00_00001",
            "label": e.target.label.value,
            "type": e.target.type.value,
            "machine": arr[toAdd.k].machine.no_machine,
            "dim1": 0,
            "dim2": 0,
            "dim3": 0,
            "weight": 0,
            "qty": 1
        }

        //Merge all tables to make continuous ID
        var merged = [];
        for(let i=0; i<p.picking.machines.length; i++ )
            merged = merged.concat(p.picking.machines[i].picking);
        
        //Get the index in entire table
        var idx = 0;
        for( let i=0; i<toAdd.k; i++ ) idx += p.picking.machines[i].picking.length;
        idx += (toAdd.k2 + 1);
      
        var container = document.getElementById("expedition_container").value;
        var lvl = container === "-1" ? 1 : forceString( container ).split(".").length + 1;
        
        merged = incrementVersionTree(merged, idx, o, lvl);
     
        //dispatch the picking
        var mach = 0;
        var newPick = [];
        for( let i=0; i<merged.length; i++ ){
            var o = merged[i];
            if( arr[mach].machine.no_machine !== o.machine ){
                arr[mach].picking = newPick;
                newPick = [];
                mach++;
            }

            newPick.push(o);
        }

        arr[mach].picking = newPick;
        
        var o = { ...p.picking };
        o.machines = arr;
        p.setPicking(o)

        setToAdd();
        setList([]);

        p.setChangeDone(true);

    }

    /**
     * Delete Row
     */
    const deleteRow = (k, k2) => {
        var arr = [...p.picking.machines];

        //Merge all tables to make continuous ID
        var merged = [];
        for(let i=0; i<p.picking.machines.length; i++ )
            merged = merged.concat(p.picking.machines[i].picking);

        //Get the index in entire table
        var idx = 0;
        for( let i=0; i<k; i++ ) idx += p.picking.machines[i].picking.length;
        idx += k2;
        
        merged = deleteTreeRow(merged, k2);
        
        //dispatch the picking
        var mach = 0;
        var newPick = [];
        for( let i=0; i<merged.length; i++ ){
            var o = merged[i];
            if( arr[mach].machine.no_machine !== o.machine ){
                arr[mach].picking = newPick;
                newPick = [];
                mach++;
            }

            newPick.push(o);
        }

        arr[mach].picking = newPick;

        var o = { ...p.picking };
        o.machines = arr;
        p.setPicking(o)

        p.setChangeDone(true);
    }

    function incrementVersionTree(objects, newRowIndex, o, requestedLevel) {
        let newObjects = JSON.parse(JSON.stringify(objects)); // Deep copy to avoid mutating the original array
   
        // Split each version number into parts for each object
        newObjects.forEach(obj => {
            obj.idParts = forceString( obj.id ).split('.').map(Number);
        });
    
        // Determine the version number for the new row based on the requested level
        let precedingVersion = newObjects[newRowIndex - 1].idParts;
        let newVersion = [];
   
        // Build the new version based on the requested level
        if (requestedLevel > precedingVersion.length) {
            // If the requested level is deeper, extend the preceding version
            newVersion = [...precedingVersion];
            while (newVersion.length < requestedLevel - 1) {
                newVersion.push(0); // Fill missing levels with zero
            }
            newVersion.push(1); // Start the new subversion from 1
        } else {
            // Otherwise, increment the existing level
            if( requestedLevel === 1 ){
                newVersion.push(precedingVersion[0] + 1); 
            }else{
                newVersion = [...precedingVersion.slice(0, requestedLevel - 1)];
                newVersion.push(precedingVersion[requestedLevel - 1] + 1); 
            }
                      
        }

        // Add the new row into the array
        o.id = newVersion.join('.');
        newObjects.splice(newRowIndex, 0, {
            ...o
        });
    
        // Adjust the following rows
        for (let i = newRowIndex + 1; i < newObjects.length; i++) {
            let currentVersion = newObjects[i].idParts;
            if (currentVersion.length >= requestedLevel) {
                currentVersion[requestedLevel - 1]++;
                if (currentVersion.length > requestedLevel) {
                    currentVersion = currentVersion.slice(0, requestedLevel); // Trim sub-levels
                }
                newObjects[i].id = currentVersion.join('.');
            }
        }
    
        // Clean up temporary idParts field
        newObjects.forEach(obj => {
            delete obj.idParts;
        });
    
        return newObjects;
    }

    function deleteTreeRow(objects, deleteIndex) {

        let targetId = forceString( objects[deleteIndex].id );
        let targetLevel = targetId.split('.').length;
        
        // Remove the target item and its children
        let newObjects = objects.filter((obj, index) => {
            let currentLevel = forceString( obj.id ).split('.').length;
            // Retain only those objects that are not children of the deleted item
            if( index === deleteIndex ) return false;
            return !(index >= deleteIndex && 
                        currentLevel > targetLevel && forceString(obj.id).startsWith(targetId));
        });
        
        // Decrement following items
        for (let i = deleteIndex; i < newObjects.length; i++) {
            let currentIdParts = forceString(newObjects[i].id).split('.').map(Number);
            if (currentIdParts.length >= targetLevel) {
                currentIdParts[targetLevel - 1]--; // Decrement the appropriate part
                newObjects[i].id = currentIdParts.join('.');
            }
        }
    
        return newObjects;
    }

    function getTree(){
        var tree = [];

        var arr = [...p.picking.machines];

        var machine = { ...arr[toAdd.k] };
        var picking = [...machine.picking];

        for( let i = toAdd.k2; i>=0; i-- ){
            tree.push(picking[i]);
            //If we are in the top level add it and stop the search
            if( forceString( picking[i].id ).indexOf(".") === -1 ) break;            
        }

        return tree;
    }

    const updateField = (e, k, k2, field) => {
        var arr = [...p.picking.machines];

        var machine = { ...arr[k] };
        var picking = [...machine.picking];
        picking[k2][field] = e.target.value;

        var o = { ...p.picking };
        o.machines = arr;
        p.setPicking(o);

        p.setChangeDone(true);
    }

    const deleteMachine = (k) => {
        var o = { ...p.picking };
        var machines = [...o.machines];
        machines[k].picking = [];
        o.machines = machines;
        p.setPicking(o);

        p.setChangeDone(true);
    }

    const changeComment = (e) => {
        var val = e.target.value;
        var o = { ...p.picking };
        o.picking_head.comments = val;
        p.setPicking(o);

        p.setChangeDone(true);
    }

    const checkToAdd = (e, k) => {
        var arr = [...list];
        if (e.target.checked) { arr.push(k); }
        else { arr = removeEltArray(arr, k); }

        setList(arr);
    }

    return <Fragment>
        <h5 className="text-center">Picking list {p.pickingId ? "n°" + p.pickingId : ""}</h5>

        {p.picking && <Fragment>

            {p.picking.machines.map((v, k) => {
                return v.picking.length > 0 && <div key={"machine" + k} className="mb-3">
                    <h5>Machine <a href={"/machine?machine=" + v.machine.no_machine} target="_blank">{v.machine.no_machine}</a> ({v.machine.typ_config})
                        <img src="/common/remove_icon.png" onClick={() => deleteMachine(k)}
                            className="no-print cursor-pointer ms-2" style={{ width: "20px" }} />
                    </h5>

                    <table className="table">
                        <thead className="text-center">
                            <tr>
                                <th style={{ width: "30px", borderBottomStyle: "none", borderTopStyle: "none" }}></th>
                                <th scope="col">ID</th>
                                <th scope="col">Qté</th>
                                <th scope="col">Article</th>
                                <th scope="col">Désignation</th>
                                <th scope="col">Type de colisage</th>
                                <th scope="col">Longueur</th>
                                <th scope="col">Largeur</th>
                                <th scope="col">Hauteur</th>
                                <th scope="col">Poids</th>
                                <th scope="col" className="no-print"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {v.picking.map((v2, k2) => {
                                return <Fragment>
                                    <tr key={"pick" + k + "_" + k2} onMouseEnter={() => setCursor(k + "_" + k2)}
                                        onMouseLeave={() => setCursor("")}>
                                        <td style={{ borderBottomStyle: "none", borderTopStyle: "none" }}></td>
                                        <th scope="row">
                                            <div className={"ms-" + (forceString(v2.id).split(".").length -1 )}>#{v2.id}</div>
                                        </th>
                                        <td>
                                            <input className="form-control text-center" type="number" defaultValue={v2.qty || ""}
                                                onBlur={(e) => updateField(e, k, k2, "qty")} />
                                        </td>
                                        <td>{v2.ar}</td>
                                        <td>{v2.label}</td>
                                        <td>{v2.type}</td>
                                        <td>
                                            <input className="form-control text-center" type="number" defaultValue={v2.dim1 || ""}
                                                onBlur={(e) => updateField(e, k, k2, "dim1")} />
                                        </td>
                                        <td>
                                            <input className="form-control text-center" type="number" defaultValue={v2.dim2 || ""}
                                                onBlur={(e) => updateField(e, k, k2, "dim2")} />
                                        </td>
                                        <td>
                                            <input className="form-control text-center" type="number" defaultValue={v2.dim3 || ""}
                                                onBlur={(e) => updateField(e, k, k2, "dim3")} />
                                        </td>
                                        <td>
                                            <input className="form-control text-center" type="number" defaultValue={v2.weight || ""}
                                                onBlur={(e) => updateField(e, k, k2, "weight")} />
                                        </td>
                                        <td className="no-print">
                                            <img src="/common/remove_icon.png" className="see-img w-30 cursor-pointer"
                                                onClick={() => deleteRow(k, k2)} />
                                        </td>
                                    </tr>

                                    <tr key={"add" + k + "_" + k2} onMouseEnter={() => setCursor(k + "_" + k2)}
                                        onMouseLeave={() => setCursor("")} title="Ajouter un élément"
                                        className={cursor === k + "_" + k2 ? "no-print" : "no-print d-none"}>

                                        <td style={{ width: "30px", borderBottomStyle: "none", borderTopStyle: "none" }}
                                            className="cursor-pointer position-relative p-0"
                                            onClick={() => setToAdd({ k: k, k2: k2 })}>
                                            <img src="/common/add_btn.png" className="position-absolute"
                                                style={{ maxWidth: "30px", marginTop: "-15px", zIndex: "1000" }} />
                                        </td>

                                        <td colSpan={11} className="see-border p-0"></td>
                                    </tr>
                                </Fragment>
                            })}
                        </tbody>
                    </table>


                </div>
            })}

            <div className="form-floating col-6 offset-3 mb-3">
                <textarea className="form-control" placeholder="xxx" id="floatingTextarea2"
                    style={{ height: "100px", padding: "1rem .75rem" }} onBlur={changeComment} ></textarea>
                <label htmlFor="floatingTextarea2">Remarques</label>
            </div>

            <div className="text-center">
                <button className="btn btn-secondary" disabled={p.saving} onClick={()=>p.savePicking(false)}>
                    {p.saving && <ButtonWaiting />}
                    Sauvegarder
                </button>

                <button className="btn btn-success ms-3" disabled={p.saving} onClick={()=>p.savePicking(true)}>
                    {p.saving && <ButtonWaiting />}
                    Sauvegarder et envoyer un mail au SLOG
                </button>
            </div>

            {toAdd && <div className="custom-tooltip-v2">
                <div className="modal-body xl blue-purple-bg">
                    <button type="button" className="btn-close" onClick={() => setToAdd()}></button>

                    <h5 className="text-center">Insérer un élément à la machine {p.picking.machines[toAdd.k].machine.no_machine}</h5>

                    <h6 className="text-center">Choisissez un article compatible ci-dessous</h6>

                    <form onSubmit={insert} style={{ width: "800px" }} className="ms-auto me-auto">
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Ajouter dans </span>
                            <select className="form-select" name="container" id="expedition_container">
                                <option value='-1'>Machine {p.picking.machines[toAdd.k].machine.no_machine}</option>
                                {/**get the previous row(s) to insert into */}
                                { getTree().map( (v, k) => {
                                    return <option value={v.id}><strong>{v.id}</strong>: {v.ar} - {v.label}</option>
                                } ) }
                            </select>
                        </div>

                        <div style={{ height: "300px" }} className="overflow-y-auto mb-3">
                            <ul className="list-group">
                                {p.picking.machines[toAdd.k].fullPicking.map((v, k) => {
                                    return <li className="list-group-item" key={"prop" + k}>
                                        <input type="checkbox" className="me-2" onChange={(e) =>checkToAdd(e, k)}
                                            checked={list.includes(k)}/>
                                        {v.ar + " - " + v.label + " (" + v.type + ")"}
                                    </li>
                                })}

                            </ul>
                            
                        </div>
                            
                            {list.length > 0 && <div className="text-center">
                                <button className="btn btn-success">Ajouter</button>
                            </div>}


                    </form>


                    <h6 className="text-center">Ou ajoutez un élément spécifique</h6>

                    <form onSubmit={insertSpecific} className="ms-auto me-auto" style={{ width: "800px" }}>
                        <div className="input-group mb-3">
                            <span className="input-group-text">Désignation</span>
                            <input type="text" name="label" className="form-control" />

                            <label className="input-group-text">Type de colisage</label>
                            <select className="form-select" name="type" required>
                                <option value="">Choose...</option>
                                <option value="Caisse">Caisse</option>
                                <option value="Carton">Carton</option>
                                <option value="Chevalet">Chevalet</option>
                                <option value="Complet">Complet</option>
                                <option value="Palette">Palette</option>
                                <option value="Article">Article</option>
                            </select>

                            <button className="btn btn-success">Ajouter</button>
                        </div>
                    </form>

                </div>
            </div>}

        </Fragment>}

    </Fragment>
}

export default MachPicking;