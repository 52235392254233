import { useState } from "react";
import { Fragment } from "react";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ErrorMessage, { InfoMsg, WarningMsg } from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import { useEffect } from "react";
import getUrlParam from "../../functions/StrFunctions";
import errorManagement from "../../services/errorManagement";
import BomDao from "../../services/machines/BomDaoV2";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import Calculations from "./smallComp/Calculations";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import Historic from "./smallComp/Historic";
import { createColumnHelper } from "@tanstack/react-table";
import { confirmAlert } from "react-confirm-alert";

const BomReview = (props) => {

    const [data, setData] = useState([]);
    const [bomObj, setBomObj] = useState();
    const [machine, setMachine] = useState();
    const [machineInfo, setMachineInfo] = useState();
    const [typeBom, setTypeBom] = useState();
    const [temp, setTemp] = useState();
    const [existingBom, setExistingBom] = useState();
    const [focusBom, setFocusBom] = useState();
    const [speToCreate, setSpeToCreate] = useState();
    const [StdToCreate, setStdToCreate] = useState();
    const [historic, setHistoric] = useState([]);

    const [working, setWorking] = useState(false);
    const [progress, setProgress] = useState(-1);
    const [info, setInfo] = useState();
    const [warning, setWarning] = useState();
    const [filtered, setFiltered] = useState(false);

    const [tab, setTab] = useState(0);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const user = JSON.parse(localStorage.getItem("user"));
    const [readOnly] = useState(user && user.roles.includes("ROLE_BOM_VIEW"));
    const [isDesigner] = useState(user && user.roles.includes("ROLE_DESIGNER"));
    const [isValidator] = useState(user && user.roles.includes("ROLE_BOM_VALIDATION"));
    const [isPurchase] = useState(user && user.roles.includes("ROLE_PURCHASE"));
    const [isAdmin] = useState(user && user.roles.includes("ROLE_ADMIN"));
    const [toChangeMode, setToChangeMode] = useState();

    const onChangeValue = (e) => {
        var table = e.target.getAttribute("data-table");
        var field = e.target.getAttribute("data-field");
        var k = e.target.getAttribute("data-key");
        var oldValue = data[k][field];
        var value = e.target.value.trim();

        if (value !== oldValue && table !== "focus") {
            BomDao.updateBomFieldV2(machine, table, field, data[k], typeBom, value).then(
                () => { },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                }
            )
        }
    }

    function getColumns() {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('cat', {
            header: 'Catégorie',
            cell: i => <div className="">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('se', {
            header: 'S/E',
            cell: i => <div className="">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('rep', {
            header: 'Repère',
            cell: i => <div className="">{i.getValue()}</div>
        }));

        if( bomObj && bomObj.temBom && bomObj.temBom.length > 0 ){
            arr.push(columnHelper.accessor('qty', {
                header: 'Qté temp',
                cell: i => <div className="">{i.getValue()}</div>
            }));
        }

        if( bomObj && bomObj.intBom && bomObj.intBom.length > 0 ){
            arr.push(columnHelper.accessor('validated_qty', {
                header: 'Qté def',
                cell: i => <div className="">{i.getValue()}</div>
            }));
        }

        if( bomObj && bomObj.focBom && bomObj.focBom.length > 0 ){
            arr.push(columnHelper.accessor('validated_qty', {
                header: 'Qté Focus',
                cell: i => <div className="">{i.getValue()}</div>
            }));
        }
        
        

        arr.push(columnHelper.accessor('french_label', {
            header: 'Désignation FR',
            cell: i => <div className="">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('ref', {
            header: 'Référence',
            cell: i => <div className="">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('maker', {
            header: 'Fabricant',
            cell: i => <div className="">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('ar', {
            header: 'Code article',
            cell: i => <div className="">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('stock_at_term', {
            header: 'Stock',
            cell: i => <div className="">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('OBSES', {
            header: 'Coms',
            cell: i => <div className="">{
                (i.getValue() && i.getValue() !== null && i.getValue().trim() !== "") &&
                <img title={i.getValue()} src={"/common/tooltip.png"}></img>
            }</div>
        }));

        return arr;
    }

    //Set columns
    const columns = getColumns();

    useEffect(() => {
        var machine = getUrlParam(props, "machine");
        var type = getUrlParam(props, "type");
        setMachine(machine);
        setTypeBom(type);

        BomDao.getBomV2(machine, type).then(
            (r) => {
                setData(r.list);
                setMachineInfo(r.list[0]);
                setBomObj(r);
                if (r.info !== "") setInfo(r.info);
                if (r.warning !== "") setWarning(r.warning);
                if (r.error !== "") setError(r.error);
                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        );

    }, []);

    /**
     * 
     */
    const validateBom = () => {

        confirmAlert({
            title: "Confirmation",
            message: "Etes-vous sûr de vouloir valider la nomenclature de la machine " + machineInfo.machine + " ? ",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setWorking(true);
                        BomDao.confirmBom(machineInfo.machine, typeBom, bomObj.speToCreate).then(
                            () => {
                                window.location.reload();
                            },
                            (error) => {
                                setWorking(false);
                                setError(errorManagement.handleError(props, error));
                                scrollToTop();
                            }
                        );
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const filter = (field) => {
        switch (field) {
            case "spe": setData(bomObj.speToCreate); setTab(1); setFiltered(true); break;
            case "std": setData(bomObj.stdToCreate); setTab(1); setFiltered(true); break;
            case "blocked": setData(bomObj.blocked); setTab(1); setFiltered(true); break;
            case "notNeeded": setData(bomObj.notNeeded); setTab(1); setFiltered(true); break;
            case "tempDef": setData(bomObj.diffTempValid); setTab(1); setFiltered(true); break;
        }




    }

    /** */
    const tab0 = () => {
        setTab(0);
        if (filtered) { setData(bomObj.list); setFiltered(false) }
    }

    return <Fragment>


        {machineInfo && <div className="card text-center" style={{ marginTop: 0, width: "350px" }}>
            <h4>Machine: {machineInfo &&
                <a target="_blank" href={"/machine?machine=" + machineInfo.machine}>{machineInfo.machine} ({machineInfo.typ_config})</a>}</h4>

            <h5>
                Affaire: <a target="_blank" href={"/business?code=" + machineInfo.affaire}>{machineInfo.affaire}</a></h5>
        </div>}

        {loading && <WaitingRoundSnippers />}
        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        <ul className="nav nav-tabs">
            <li className="nav-item">
                <a className={'nav-link ' + (tab === 0 ? "active" : "")}
                    aria-current="page" href="#" onClick={tab0}>Introduction</a>
            </li>

            <li className="nav-item">
                <a className={'nav-link ' + (tab === 1 ? "active" : "")}
                    href="#" onClick={() => setTab(1)}>Nomenclature</a>
            </li>
        </ul>

        {!loading &&
            <Fragment>
                { /** Explanations et buttons */
                    tab === 0 && <div>
                        {warning && <WarningMsg warning={warning} warningState={setWarning} />}
                        {info && <InfoMsg info={info} infoState={setInfo} />}

                        <ul className="list-group col-6 offset-3 fw-bold">
                            {bomObj.speToCreate.length > 0 &&
                                <li className="list-group-item">
                                    {bomObj.speToCreate.length} article(s) spécifiques à créer
                                    <button className="btn btn-outline-secondary ms-3" onClick={() => filter("spe")}>Voir</button>
                                </li>
                            }

                            {bomObj.stdToCreate.length > 0 &&
                                <li className="list-group-item">
                                    {bomObj.stdToCreate.length} article(s) standards à créer
                                    <button className="btn btn-outline-secondary ms-3" onClick={() => filter("std")}>Voir</button>
                                </li>
                            }

                            {bomObj.blocked.length > 0 &&
                                <li className="list-group-item">
                                    {bomObj.blocked.length} article(s) bloqué(s)
                                    <button className="btn btn-outline-secondary ms-3" onClick={() => filter("blocked")}>Voir</button>
                                </li>
                            }

                            {bomObj.notNeeded.length > 0 &&
                                <li className="list-group-item">
                                    {bomObj.notNeeded.length} article(s) coché(s) "Non" en besoins et donc ne seront pas commandés
                                    <button className="btn btn-outline-secondary ms-3" onClick={() => filter("notNeeded")}>Voir</button>
                                </li>
                            }

                            {bomObj.diffTempValid.length > 0 &&
                                <li className="list-group-item">
                                    {bomObj.diffTempValid.length} différence(s) entre la nomenclature temporaire et celle déjà validée.
                                    <button className="btn btn-outline-secondary ms-3" onClick={() => filter("tempDef")}>Voir</button>
                                </li>
                            }
                        </ul>

                        {
                            !error && <Fragment>
                                {/** Validate BOM by any designer */
                                    ((isDesigner || isAdmin) && bomObj.origin === "temporary") &&
                                    <div className="mt-3 text-center">
                                        <button className="btn btn-success" onClick={validateBom} disabled={working}>
                                            {working && <ButtonWaiting />}
                                            Valider la nomenclature
                                        </button>
                                    </div>}

                                {/** Validate BOM by any designer */
                                    ((isValidator || isAdmin) && bomObj.status === "validated") &&
                                    <div className="mt-3 text-center">
                                        <button className="btn btn-success" onClick={validateBom} disabled={working}>
                                            {working && <ButtonWaiting />}
                                            Lancer en achats
                                        </button>
                                    </div>}
                            </Fragment>
                        }

                    </div>
                }

                {tab === 1 &&
                    <ReactTable columns={columns} data={data} origin="bom_v2"
                        classes={"no-padding"} top0={true} />}

            </Fragment>
        }

    </Fragment>

}

export default BomReview;