import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL + "business/info";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && "\"Bearer " + user.accessToken + "\"";

const createBusiness = (business) => {
    return axios
        .post(API_URL + "/create/business",
            {
                fake_header: authHeader(),
                business: business
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getBusinessInfo = (code) => {
    return axios
        .post(API_URL + "/get",
            {
                fake_header: authHeader(),
                business: code
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getBusinessInfoApproximative = (code) => {
    return axios
        .post(API_URL + "/get/nearest",
            {
                fake_header: authHeader(),
                business: code
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getTecmaFiles = (business, arr) => {
    return axios
        .post(API_URL + "/tecma/get/files",
            {
                fake_header: authHeader(),
                business: business,
                arr: arr
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const sendTecmaFiles = (o) => {
    return axios
        .post(API_URL + "/tecma/send/files",
            {
                fake_header: authHeader(),
                ...o
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getDp = () => {
    return axios
        .post(API_URL + "/get/dp",
            {
                fake_header: authHeader()
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getMachines = (code) => {
    return axios
        .post(API_URL + "/get/machines",
            {
                fake_header: authHeader(),
                business: code
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getConfigs = () => {
    return axios
        .post(API_URL + "/get/configs",
            {
                fake_header: authHeader()
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getAnnexes = (code) => {
    return axios
        .post(API_URL + "/get/annexes",
            {
                fake_header: authHeader(),
                business: code
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getRevisions = (code) => {
    return axios
        .post(API_URL + "/get/revisions",
            {
                fake_header: authHeader(),
                business: code
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getMachineCreationInfo = () => {
    return axios
        .post(API_URL + "/add/machine/get-infos",
            {
                fake_header: authHeader()
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateBusinessField = (business, field, value, typeField, translate) => {
    return axios
        .post(API_URL + "/update/field",
            {
                fake_header: authHeader(),
                business: business,
                field: field,
                value: value,
                typeField: typeField,
                translate: translate
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const deleteAnnexe = (business, fileName) => {
    return axios
        .post(API_URL + "/delete-annexe",
            {
                fake_header: authHeader(),
                business: business,
                file: fileName
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const createMachine = (o) => {
    return axios
        .post(API_URL + "/add/machine/save",
            {
                fake_header: authHeader(),
                config: o.config,
                serial: o.serial,
                line: o.line,
                old_ref: o.old_ref,
                business: o.business,
                id_monday: o.id_monday
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const deleteMachine = (o) => {
    return axios
        .post(API_URL + "/remove/machine",
            {
                fake_header: authHeader(),
                id_monday: o.id_monday,
                serial: o.no_machine
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateMondayField = (o) => {
    return axios
        .post(API_URL + "/update/monday",
            {
                fake_header: authHeader(),
                field: o.field,
                value: o.value,
                id_monday: o.id_monday,
                typeField: o.typeField
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const exportBusinessCard = (o) => {
    return axios
        .post(API_URL + "/export",
            {
                fake_header: authHeader(),
                ...o
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const exportPurchaseConditions = (o) => {
    return axios
        .post(API_URL + "/export/purchase-conditions",
            {
                fake_header: authHeader(),
                ...o
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const closeBusiness = (business, monday_ids) => {
    return axios
        .post(API_URL + "/close",
            {
                fake_header: authHeader(),
                business: business,
                monday_ids: monday_ids
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const deleteBusiness = (business, monday_ids) => {
    return axios
        .post(API_URL + "/delete",
            {
                fake_header: authHeader(),
                business: business,
                monday_ids: monday_ids
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const exportBusinessGuideLine = (business, language, version) => {
    return axios
        .post(API_URL + "/export/business-guide-line",
            {
                fake_header: authHeader(),
                business: business,
                language: language, 
                version: version
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const searchByTerm = (term, displayClosed) => {
    return axios
        .post(API_URL + "/search/by-term",
            {
                fake_header: authHeader(),
                term: term,
                displayClosed: displayClosed
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const searchByResp = (term, displayClosed) => {
    return axios
        .post(API_URL + "/search/by-responsible",
            {
                fake_header: authHeader(),
                term: term,
                displayClosed: displayClosed
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const searchInFocus = (business) => {
    return axios
        .post(API_URL + "/focus/search",
            {
                fake_header: authHeader(),
                business: business
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const exportDeliveryNote = (machines, crate, business, revisions, duplicata) => {
    return axios
        .post(API_URL + "/export/delivery-note",
            {
                fake_header: authHeader(),
                machines: machines,
                crate: crate,
                business: business,
                revisions: revisions,
                duplicata: duplicata
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getDeliveryNote = (business) => {
    return axios
        .post(API_URL + "/get/delivery-note",
            {
                fake_header: authHeader(),
                business: business
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getBusinessInfoFocus = (business) => {
    return axios
        .post(API_URL + "/focus/get",
            {
                fake_header: authHeader(),
                business: business
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const exportCustomerInvoice = (business, businessFocus, detail, revisions, div, duplicata) => {
    return axios
        .post(API_URL + "/export/customer-invoice",
            {
                fake_header: authHeader(),
                business: business,
                businessFocus: businessFocus,
                detail: detail,
                revisions: revisions,
                div: div,
                duplicata: duplicata
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getCustomerInvoice = (business) => {
    return axios
        .post(API_URL + "/get/customer-invoice",
            {
                fake_header: authHeader(),
                business: business
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getBillMachineList = () => {
    return axios
        .post(API_URL + "/bill/machines/list",
            {
                fake_header: authHeader()
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getBillMachinePagination = () => {
    return axios
        .post(API_URL + "/bill/machines/pagination",
            {
                fake_header: authHeader()
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getBillMaintenanceList = () => {
    return axios
        .post(API_URL + "/bill/maintenance/list",
            {
                fake_header: authHeader()
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getBillSparesList = () => {
    return axios
        .post(API_URL + "/bill/spares/list",
            {
                fake_header: authHeader()
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateBillMachine = (data, field, value) => {
    return axios
        .post(API_URL + "/bill/machines/update",
            {
                fake_header: authHeader(),
                data: data,
                field: field,
                value: value
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateSparesTimeline = (data) => {
    return axios
        .post(API_URL + "/bill/spares/timeline/update",
            {
                fake_header: authHeader(),
                ...data
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const notifyBillMachine = (row) => {
    return axios
        .post(API_URL + "/bill/machines/notify",
            {
                fake_header: authHeader(),
                row: row
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getBillFilter = (filter_code) => {
    return axios
        .post(API_URL + "/bill/filters/get",
            {
                fake_header: authHeader(),
                filter_code:filter_code
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateBillFilter = (o, filter_code) => {
    return axios
        .post(API_URL + "/bill/filters/update",
            {
                fake_header: authHeader(),
                ...o,
                filter_code: filter_code
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const deleteBillFilter = (o, filter_code) => {
    return axios
        .post(API_URL + "/bill/filters/delete",
            {
                fake_header: authHeader(),
                ...o, 
                filter_code: filter_code
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getBudget = (business) => {
    return axios
        .post(API_URL + "/budget",
            {
                fake_header: authHeader(),
                business: business
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const downloadBudget = (arr) => {
    return axios
        .post(API_URL + "/budget/export",
            {
                fake_header: authHeader(),
                arr: arr
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getContractReview = (business) => {
    return axios
        .post(API_URL + "/contract-review/get",
            {
                fake_header: authHeader(),
                business: business
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const exportContractReview = (o) => {
    return axios
        .post(API_URL + "/contract-review/export",
            {
                fake_header: authHeader(),
                ...o
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const exportContractReviewDocx = (o) => {
    return axios
        .post(API_URL + "/contract-review/export/docx",
            {
                fake_header: authHeader(),
                ...o
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getAddressBook = () => {
    return axios
        .post(API_URL + "/get/address-book",
            {
                fake_header: authHeader()
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

export default {
    getBusinessInfo,
    updateBusinessField,
    deleteAnnexe,
    getMachineCreationInfo,
    createMachine,
    deleteMachine,
    updateMondayField,
    exportBusinessCard,
    closeBusiness,
    deleteBusiness,
    exportBusinessGuideLine,
    getDp,
    getAnnexes,
    getConfigs,
    getMachines,
    getRevisions,
    searchByTerm,
    createBusiness,
    exportPurchaseConditions,
    searchInFocus,
    exportDeliveryNote,
    getDeliveryNote,
    getBusinessInfoFocus,
    exportCustomerInvoice,
    getCustomerInvoice,
    searchByResp,
    getBillMachineList,
    updateBillMachine,
    getBillFilter,
    updateBillFilter,
    getBusinessInfoApproximative,
    deleteBillFilter,
    getBillMaintenanceList,
    notifyBillMachine,
    getBillMachinePagination,
    getBillSparesList,
    updateSparesTimeline,
    getBudget,
    downloadBudget,
    getContractReview,
    exportContractReview,
    getAddressBook,
    exportContractReviewDocx,
    getTecmaFiles,
    sendTecmaFiles
}