import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const OtherInfo = (p) => {

    const { t } = useTranslation('article');

    
    return(

    <div>
      
        <ul className="list-group">
            <li className="list-group-item" aria-current="true">
                <strong>{t('qty_avai')}</strong>: {p.articleInfo.dispo}
            </li>
            <li className="list-group-item">
                <strong>{t('order')}</strong>: {p.articleInfo.tot_order}

                {
                    p.orders.length > 0 &&
                    <Fragment>
                        <br></br>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Fournisseur</th>
                                    <th scope="col">Qté</th>
                                    <th scope="col">Prix</th>
                                    <th scope="col">Affaire</th>
                                    <th scope="col">Machine</th>
                                    <th scope="col">Délai demandé</th>
                                    <th scope="col">Date accusée recpt.</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    p.orders.map((v, k) => {
                                        return <tr key={"ord"+k}>
                                            <th scope="row">{v.NOCFE}</th>
                                            <td>{v.CDFOE}</td>
                                            <td>{v.QTESE}</td>
                                            <td>{v.PUNTE}</td>
                                            <td>{v.NOCCE}</td>
                                            <td>{v.NONNE}</td>
                                            <td>{v.DTLIE}</td>
                                            <td>{v.DTCOE}</td>
                                            <td>
                                                <a target="_blank" href={"/purchase/order/edition?order=" + v.NOCFE}>Détail</a>
                                            </td>
                                        </tr>
                                    })
                                }

                            </tbody>
                        </table>
                    </Fragment>
                }
            </li>
            <li className="list-group-item">
                <strong>{t('qty_reserv')}</strong>: {p.articleInfo.qty_reserv}
            </li>
            <li className="list-group-item">
                <strong>Qté réservée/affectée</strong>: {p.articleInfo.reserved}
            </li>
            <li className="list-group-item">
                <strong>{t('qty_at_term')}</strong>: {p.articleInfo.disp_term}
            </li>
            <li className="list-group-item">
                <strong>Dernier fournisseur</strong>: {p.articleInfo.supplier}
            </li>
            {
                p.isInternal &&
                <li className="list-group-item">
                    <strong>PMP</strong>: {p.articleInfo.PXMPL}
                </li>
            }
            {
                p.isInternal &&
                <li className="list-group-item">
                    <strong>Dernier prix d'achat</strong>: {p.articleInfo.DEPAL}
                </li>
            }
            {
                p.isInternal &&
                <li className="list-group-item">
                    <a target="_blank" href={"/article/info/stock/dynamic?article=" + p.articleCode}>Stock dynamique</a>
                </li>
            }
        </ul>
    </div>)
}

export default OtherInfo;