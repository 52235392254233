/* Set the width of the side navigation to 250px */
export function openNav() {
    document.getElementById("mySidenav").style.width = "30%";
}

/* Set the width of the side navigation to 0 */
export function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
}

export function fixAtTop(minTop = 70, topPosition = 70) {

    window.addEventListener('scroll', function (e) {
        var element = document.getElementById('fixed-element');
        //var rect = element.getBoundingClientRect(); rect.top
          
        if (window.scrollY >= minTop) {
            element.classList.add('fixed-top');
            element.style.top = topPosition + "px"
        } else {
            element.classList.remove('fixed-top');
        }
    });


}