import { Fragment, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import i18n from "../../../i18n";
import CheckConnDao from "../../../services/common/CheckConnDao";


const NavigationSearch = (p) => {
    const [items, setItems] = useState([]);
    const locale = useState(i18n.language);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);

    useEffect(() => {
        
        if (!p.list) {
            CheckConnDao.getNavigation().then(
                (response) => {
                    if(response && response.length > 0) {
                        setItems(response);
                    }
                }, () => { }
            )
        } else {
            setItems(p.list);
        }

    }, []);

    const [searchText, setSearchText] = useState('');

    const handleSearch = (event) => {
        setHighlightedIndex(-1);
        setSearchText(event.target.value);
    };

    const highlightWords = (text, searchWords) => {
        //const sanitizedText = sanitizeString(text);
        let highlightedText = text;

        searchWords.forEach(word => {
            if (word) {
                if (word.length >= 3) {
                    const regex = new RegExp(`(${word})`, 'gi');
                    highlightedText = highlightedText.replace(regex, '<strong>$1</strong>');
                }
            }
        });

        return highlightedText;
    };

    const normalizeString = (str) => {
        return str.toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') // Remove accents
            .replace(/[^a-z0-9\s]/g, '') // Remove non-alphanumeric characters
            .replace(/s\b/g, '') // Remove trailing 's' for plural handling
            .trim();
    };

    const calculateRelevanceScore = (item, searchWords) => {
        const normalizedTitleFr = normalizeString(item.title_fr);
        const normalizedTitleEn = normalizeString(item.title_en);
        const normalizedDescriptionFr = normalizeString(item.description_fr);
        const normalizedDescriptionEn = normalizeString(item.description_en);

        let score = 0;
        searchWords.forEach(word => {
            if (word.length >= 3) {
                if (normalizedTitleFr.includes(word)) score++;
                if (normalizedTitleEn.includes(word)) score++;
                if (normalizedDescriptionFr.includes(word)) score++;
                if (normalizedDescriptionEn.includes(word)) score++;
            }
        });

        return score;
    };

    const getFilteredItems = () => {
        if (!searchText || searchText.length < 3) return [];

        const normalizedSearchText = normalizeString(searchText);
        const searchWords = normalizedSearchText.split(/\s+/).filter(word => word.length > 0);

        const scoredItems = items.map(item => ({
            ...item,
            score: calculateRelevanceScore(item, searchWords),
            highlightedTitleFr: highlightWords(item.title_fr, searchWords),
            highlightedTitleEn: highlightWords(item.title_en, searchWords),
            highlightedDescriptionFr: highlightWords(item.description_fr, searchWords),
            highlightedDescriptionEn: highlightWords(item.description_en, searchWords),
        }));

        return scoredItems
            .filter(item => item.score > 0) // Filter out items with no matches
            .sort((a, b) => b.score - a.score); // Sort by score in descending order
    };

    const filteredItems = getFilteredItems();

    const reset = () => {
        let elt = document.getElementById("ipt");
        elt.value = "";
        setSearchText('');
        elt.focus();
    }

    const goTo = (url) => {
        window.location.href = url;
    }

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowDown') {
            setHighlightedIndex(prevIndex => Math.min(prevIndex + 1, filteredItems.length - 1));
        } else if (event.key === 'ArrowUp') {
            setHighlightedIndex(prevIndex => Math.max(prevIndex - 1, 0));
        } else if (event.key === 'Enter' && highlightedIndex >= 0) {
            goTo(filteredItems[highlightedIndex].url);
        } else if (event.key === 'Escape') {
            reset();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [highlightedIndex, filteredItems]);

    function extractAroundStrong(text, contextWords = 3) {
        if (text && text.length < 400) return text;

        const pattern = new RegExp(`((?:\\S+\\s+){0,${contextWords}})<strong>(.*?)</strong>((?:\\s+\\S+){0,${contextWords}})`, 'g');
        const matches = [];
        let match;
        while ((match = pattern.exec(text)) !== null) {
            matches.push(`${match[1]}<strong>${match[2]}</strong>${match[3]}`);
        }
        return matches.join(' ... ');
    }

    return <Fragment>

        {items.length > 0 &&
            <div id="searchEngine">
                {searchText.length > 0 && <button type="button" className="btn-close" onClick={reset}></button>}                

                <input type="text" className="form-control" value={searchText} onChange={handleSearch}
                    placeholder="Type to search..." id="ipt" autoComplete="off" autoFocus />
                <ul className="list-group">
                    {filteredItems.map((item, index) => (
                        <li className="list-group-item" key={index} onClick={() => goTo(item.url)}
                            style={{ backgroundColor: index === highlightedIndex ? '#dee2e6' : '' }}>
                            {locale[0] === "fr" && <Fragment>
                                <div dangerouslySetInnerHTML={{ __html: item.highlightedTitleFr }}></div>
                                <div className="fst-italic" dangerouslySetInnerHTML={{ __html: extractAroundStrong(item.highlightedDescriptionFr) }}></div>
                            </Fragment>}

                            {locale[0] !== "fr" && <Fragment>
                                <div dangerouslySetInnerHTML={{ __html: extractAroundStrong(item.highlightedTitleEn) }}></div>
                                <div className="fst-italic" dangerouslySetInnerHTML={{ __html: item.highlightedDescriptionEn }}></div>
                            </Fragment>}

                        </li>
                    ))}
                </ul>
            </div>
        }

    </Fragment>

}

export default NavigationSearch;