import { Fragment, useEffect, useState } from "react";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import errorManagement from "../../services/errorManagement";
import { getHeight, scrollToTop } from "../../functions/PageAnimation";
import BusinessDao from "../../services/machines/BusinessDao";
import { createColumnHelper } from "@tanstack/react-table";
import Moment from "moment";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import { currentEnDate, currentFrDate, getFrFormat, nbFormat } from "../../functions/StrFunctions";
import { confirmAlert } from "react-confirm-alert";
import { DesignedChckBox } from "../../common/smallComponents.js/DesignedIpt";
import BillFilter from "./smallCom/Bill/BillFilter";
import MachineHelp from "./smallCom/Bill/MachineHelp";
import ReactPaginate from "react-paginate";

const BillMaintenance = (props) => {
    const [list, setList] = useState();
    const [data, setData] = useState([]);
    const [totPage, setTotPage] = useState();

    const [filter, setFilter] = useState();
    const [tab, setTab] = useState(0);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();

    const maxByPage = 300;

    const user = JSON.parse(localStorage.getItem("user"));
    const isAdmin = user && (user.roles.includes("ROLE_ADMIN_BILL") || user.roles.includes("ROLE_ADMIN"));
    //const reqBill = user && user.roles.includes("");

    Moment.locale('fr');

    useEffect(() => {
        getList();

        BusinessDao.getBillFilter("billing_track_maintenance").then(
            (r) => {
                setFilter(r);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        )
    }, []);

    function getList() {
        setError();
        setMsg();

        BusinessDao.getBillMaintenanceList().then(
            (r) => {
                //setData( r.slice(0, maxByPage) );
                setData(r);

                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }

    function changePage( page ){
        setData( list.slice( (page*maxByPage), (page*maxByPage)+maxByPage ) );
        document.getElementById("bill_table").scrollTo({ top: 0, behavior: 'smooth' });
    }

    function nb(nb){
        return nb ? nbFormat( parseFloat(nb), "fr-FR", 2 ) : "";
    }

    function getColor(i){
        var corrected_date = i.row.original.corrected_date;
        var bill_date = i.row.original.bill_date;

        var today = new Date();
        today.setHours(0, 0, 0, 0);

        if( !corrected_date || corrected_date === "" ) return "td-bg-red";

        corrected_date = new Date( corrected_date );
        corrected_date.setHours(0, 0, 0, 0);

        if( today > corrected_date && ( bill_date === null || bill_date === "" ) ) return "td-bg-orange";

        var calc_paym_date = i.row.original.calc_paym_date;
        var payed = i.row.original.payed;
        
        calc_paym_date = new Date( calc_paym_date );
        calc_paym_date.setHours(0, 0, 0, 0);

        if( today > calc_paym_date && ( payed === null || payed === "" ) ) return "td-bg-purple";
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('business', {
            header: 'Affaire',
            cell: i => <div className="td-bg-grey text-center fw-bold">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('proj_man', {
            header: 'CDP',
            cell: i => <div className={"text-center " + getColor(i) }>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('detail', {
            header: 'Détail',
            cell: i => <div className={"text-center " + getColor(i) }>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('step', {
            header: 'Etapes',
            cell: i => <div className={"text-center " + getColor(i) }>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('rate', {
            header: 'Taux',
            cell: i => <div className={"text-center " + getColor(i) }>{ nb(i.getValue()) }</div>
        }));

        arr.push(columnHelper.accessor('excl_tax_base', {
            header: 'Base HT',
            cell: i => <div className={"text-center " + getColor(i) }>{ nb(i.getValue()) }</div>
        }));

        arr.push(columnHelper.accessor('excl_tax_amount', {
            header: 'Montant HT',
            cell: i => <div className={"text-center " + getColor(i) }>{ nb(i.getValue()) }</div>
        }));

        arr.push(columnHelper.accessor('focus_date', {
            header: 'Date Focus',
            cell: i => <div className={"text-center " + getColor(i) }>{Moment(i.getValue()).format("DD/MM/YYYY")}</div>
        }));

        arr.push(columnHelper.accessor('corrected_date', {
            header: 'Date corrigée',
            cell: i => <div className={"text-center " + getColor(i) }> <input type="date" className="form-control"
                defaultValue={i.getValue()} onBlur={(e) => onFieldChange(e, i.row.id, "corrected_date")}
                readOnly={i.row.original.reason_for_delay === null}></input></div>
        }));

        arr.push(columnHelper.accessor('reason_for_delay', {
            header: 'Motif retard',
            cell: i => <div className={"text-center " + getColor(i) }>
                <select className="form-select" defaultValue={i.getValue()} title={i.getValue()}
                    style={{ minWidth: "150px" }} onChange={(e) => onFieldChange(e, i.row.id, "reason_for_delay")}>
                    <option value=""></option>
                    <option value="Retard notice">Retard notice</option>
                    <option value="Retard client">Retard client</option>
                    <option value="Correct. Wael">Correct. Wael</option>
                    <option value="Réserves documentaires">Réserves documentaires</option>
                    <option value="Réserves fonctionnelles">Réserves fonctionnelles</option>
                    <option value="Erreur Focus">Erreur Focus</option>
                    <option value="Revue CdP">Revue CdP</option>
                </select>
            </div>
        }));

        arr.push(columnHelper.accessor('payment_condition', {
            header: 'Condition de paiement',
            cell: i => <div className={"text-center " + getColor(i) }>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('deposit', {
            header: 'Caution',
            cell: i => <div className={"text-center " + getColor(i) }><input className="form-control" defaultValue={i.getValue()}
                onBlur={(e) => onFieldChange(e, i.row.id, "deposit")}
                readOnly={!isAdmin}></input></div>
        }));

        arr.push(columnHelper.accessor('comments', {
            header: 'Commentaire',
            cell: i => <div className={"text-center " + getColor(i) }><textarea className="form-control"
                onBlur={(e) => onFieldChange(e, i.row.id, "comments")}
                defaultValue={i.getValue()}></textarea></div>
        }));

        arr.push(columnHelper.accessor('vat', {
            header: 'TVA', cell: i => <div className={"text-center " + getColor(i) }>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('ttc_amount', {
            header: 'Mnt à facturer', cell: i => <div className={"text-center " + getColor(i) }>{ nb(i.getValue()) }</div>
        }));

        arr.push(columnHelper.accessor('bill_number', {
            header: 'N° DA/Facture',
            cell: i => <div className={"text-center " + getColor(i) }>
                {i.getValue()}
                {i.getValue() && <Fragment>
                    <br></br>
                    <span style={{ fontSize: "13px" }}>Facturé le: {getFrFormat(i.row.original.bill_date)}</span>
                </Fragment>}
            </div>
        }));

        arr.push(columnHelper.accessor('calc_paym_date', {
            header: 'Date de paiement calculée',
            cell: i => <div className={"text-center " + getColor(i) }>{getFrFormat(i.getValue())}</div>
        }));

        arr.push(columnHelper.accessor('payed', {
            header: 'Payé',
            cell: i => <div className={"text-center " + getColor(i) }>{
                    (i.row.original.bill_number && isAdmin) &&
                <DesignedChckBox checked={i.getValue() === 'O'} onChange={(e) => onPayedChange(i.row.id)} />
            }

            </div>
        }));

        arr.push(columnHelper.accessor('eff_paym_date', {
            header: 'Date de paiement effectué',
            cell: i => <div className={"text-center " + getColor(i) }>
                <input type="date" className="form-control" defaultValue={i.getValue()}
                    onBlur={(e) => onFieldChange(e, i.row.id, "eff_paym_date")} 
                    readOnly={!isAdmin || i.row.original.payed !== 'O' }></input>
            </div>
        }));

        arr.push(columnHelper.accessor('to_bill', {
            header: 'A facturer',
            cell: i => <div className={"text-center " + getColor(i) }>{
                    (!i.row.original.bill_number) && <div className="display-flex">
                    <DesignedChckBox checked={i.getValue() === 'O'} onChange={(e) => toBillChange(i.row.id)} />
                    {i.getValue() === 'O' && 
                    <div>Demande envoyée</div>
                    }                    
                </div>
            }
            </div>
        }));

        return arr;
    }

    //Set columns
    const columns = getColumns();

    // On field change
    const onFieldChange = (e, id, field) => {
        var val = e.target.value.trim() === "" ? null : e.target.value.trim();
        var arr = [...data];
        arr[id][field] = val;
        setData(arr);
        save(id, field, val);

        //Special case
        if( field === "corrected_date" ) save(id, "sc:corrected_date", val);
        
    }

    // On to bill change
    const toBillChange = (id) => {
        var val = data[id].to_bill;
        var arr = [...data];

        if (!val) {
            var today = new Date();
            today.setHours(0,0,0,0);
            var corrected_date = data[id].corrected_date;
            
            if( corrected_date === null || corrected_date === "" ){
                setError("La date corrigée est vide, essayez de mettre à jour un autre champ puis actualisez la page.");
                scrollToTop();
                return;
            }

            corrected_date = new Date( data[id].corrected_date );
            corrected_date.setHours(0,0,0,0);

            if( corrected_date < today ){
                setError("La date corrigée est inférieure à la date du jour !<br>" +
                "Modifiez cette date pour pouvoir envoyer la demande de facturation.");
                scrollToTop();
                return;
            }

            arr[id].to_bill = 'O';
            setData(arr);

            save(id, "to_bill", "O");
        } else {
            confirmAlert({
                title: "Confirmation",
                message: "Etes-vous sûr(e) de vouloir annuler la demande de facturation de l'affaire "
                    + arr[id].business + ", de l'étape " + arr[id].step + " ?",
                buttons: [
                    {
                        label: "Oui",
                        onClick: () => {
                            arr[id].to_bill = null;
                            setData(arr);

                            save(id, "to_bill", null);
                        }
                    },
                    {
                        label: "Non"
                    }
                ]
            });

        }
    }

    // Payed checked
    const onPayedChange = (id) => {
        var val = data[id].payed;
        var arr = [...data];

        if (!val) {
            arr[id].payed = 'O';
            arr[id].eff_paym_date = currentFrDate();
            setData(arr);

            save(id, "payed", 'O');
            save(id, "eff_paym_date", currentEnDate());
        } else {
            confirmAlert({
                title: "Confirmation",
                message: "Etes-vous sûr(e) de vouloir décocher le paiement de le facture n° "
                    + arr[id].bill_number + " ?",
                buttons: [
                    {
                        label: "Oui",
                        onClick: () => {
                            arr[id].payed = null;
                            arr[id].eff_paym_date = null;
                            setData(arr);

                            save(id, "payed", null);
                            save(id, "eff_paym_date", null);
                        }
                    },
                    {
                        label: "Non"
                    }
                ]
            });

        }
    }

    // Save in data base
    function save(id, field, value) {

        BusinessDao.updateBillMachine(data[id], field, value).then(
            () => {
                if (!data[id].hasRow) {
                    var arr = [...data];
                    arr[id].hasRow = "yes";
                    setData(arr);
                }
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        )
    }

    return <Fragment>
        {loading && <WaitingRoundSnippers />}<br></br>
        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        <ul className="nav nav-tabs">
            <li className="nav-item">
                <a className={'nav-link ' + (tab === 0 ? "active" : "")}
                    aria-current="page" href="#" onClick={() => setTab(0)}>Liste</a>
            </li>

            <li className="nav-item">
                <a className={'nav-link ' + (tab === 1 ? "active" : "")}
                    href="#" onClick={() => setTab(1)}>Filtres permanents</a>
            </li>

            <li className="nav-item">
                <a className={'nav-link ' + (tab === 2 ? "active" : "")}
                    href="#" onClick={() => setTab(2)}>Explications</a>
            </li>
        </ul>

        {/** TABLE */}
        { (tab === 0 ) &&
          data.length > 0 && 
                    <ReactTable columns={columns} data={data}
                        origin="bill_machines" classes={"no-padding fixFstCol"} top0={true} />
             
        }


        {/** Filters */}
        {(tab === 1 && filter) && <BillFilter filter={filter} setFilter={setFilter} 
            setError={setError} filter_code="billing_track_maintenance"/>}

        {/** HELPS */}
        { (tab === 2) && <MachineHelp /> }
    </Fragment>
}

export default BillMaintenance;