import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Quality = (props) => {
    const { t } = useTranslation();

    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Qualité/SLOG
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">

                {
                    (props.roles.includes("ROLE_ADMIN") || props.roles.includes("ROLE_NC_WRITE")) &&
                    <Fragment>
                        <li>
                            <a className="dropdown-item" href="/storehouse/quality/nc">Fiche de non conformité</a>
                        </li>
                    </Fragment>
                }

                <li>
                    <a href={"/storehouse/quality/nc-list"} className="dropdown-item">
                        Suivi des NC
                    </a>
                </li>

            </ul>
        </li>
    )

}

export default Quality;