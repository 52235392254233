import React, { Fragment, useEffect, useState } from "react";
import "../../css/supplyChain/subcontracting.css";
import errorManagement from "../../services/errorManagement";
import subcontracting from "../../services/supplyChain/subcontracting";

const SubcontractingScan = (props) => {
    const [msg, setMsg] = useState();
    const [error, setError] = useState(null);
    let timer = null;
    let str = "";


    useEffect(() => {
        setTimeout(function () { focusScan() }, 500);
    }, []);

    const onReaderChange = (e) => {
        str += e.target.value;

        if (timer) clearTimeout(timer);

        timer = setTimeout(() => {
            var arr = str.split("-");

            if (arr[0] === "NOMOVE") {
                setSent( arr[3], arr[4], true );
                setMsg(arr[2] + " scanné (consommable)")
            } else {
                setSent( arr[6], arr[4], false );
                setMsg(arr[2] + " scanné")
            }
            str = "";

        }, 500);

        focusScan();
    }

    {/** */ }
    function setSent(id, qty, isCsm) {
        // Update the "Sent qty"
        subcontracting.updateField("sent2", qty, id).then(
            () => { }, (error) => { setError(errorManagement.handleError(props, error)); });

        // If it's consumable it's not moved so need to complete the "moved qty"
        if (isCsm) {
            subcontracting.updateField("sent", qty, id).then(
                () => { }, (error) => { setError(errorManagement.handleError(props, error)); });
        }
    }

    {/** */ }
    function focusScan() {
        document.getElementById("scanField").value = "";
        document.getElementById("scanField").focus();
    }

    return <Fragment>
        <h3 className="text-center">Colisage sous-traitance</h3>

        <div className="col-6 offset-3 text-center mb-3">
            <input type="text" className="form-control fw-bold text-center" id="scanField" onChange={onReaderChange}
                placeholder="Scannez la data matrix" onBlur={() => focusScan()} onLoad={() => focusScan()} />
        </div>

        <div className="alert alert-info text-center fw-bold col-10 offset-1">{msg}</div>

    </Fragment>


};

export default SubcontractingScan;