import { useState } from "react";
import { ButtonWaiting, scrollToTop } from "../../../functions/PageAnimation";
import errorManagement from "../../../services/errorManagement";
import ArticleInfo from "../../../services/articles/ArticleInfo";
import { useTranslation } from "react-i18next";
import { arrayRemoveElt, getKey } from "../../../functions/ArrayFunctions";
import { Fragment } from "react";
import { forceString } from "../../../functions/StrFunctions";
import CheckConnDao from "../../../services/common/CheckConnDao";

const BasicInfo = (p) => {

    const user = JSON.parse(localStorage.getItem("user"));
    const [translating, setTranslating] = useState(false);
    const { t } = useTranslation('article');
    const [searchingPlan, setSearchingPlan] = useState(false);
    const [drawingFound, setDrawingFound] = useState(false);
    const token = user && "Bearer " + user.accessToken;

    const specificChange = user && user.roles.includes("ROLE_ART_CHANGEPLUS");

    const API_URL = process.env.REACT_APP_API_URL;
    var url = API_URL + "articles/info/get-drawing?Authorization=" + token + "&article=";

    const translate = async (e) => {
        e.preventDefault();
        p.setError();
        var lang = e.target.getAttribute("data-lang");
        var field = e.target.getAttribute("data-field");

        if(!p.articleInfo.ZN03PA || p.articleInfo.ZN03PA.trim() === "") return

        const translation = await CheckConnDao.translate(p.articleInfo.ZN03PA.trim(), lang);

        if(translation !== null){
            var o = {...p.articleInfo};
            o[field] = translation;
            p.setArticleInfo(o);
            p.ctrlAndUpdateArticle("fperart", field, translation, 
                "Traduction auto désignation " + lang.toUpperCase());
        }else{
            var url = "https://translate.google.com/?hl=fr&sl=auto&tl=" + lang + "&text=" + p.articleInfo.ZN03PA.replace(/ /g, '+') + "&op=translate";
            window.open(url, '_blank');
        }

       

        /*setTranslating(true);
        ArticleInfo.translate(p.articleInfo.ZN03PA, "fr", lang).then(
            (response) => {
                var o = { ...p.articleInfo };
                o[field] = response.translatedText;
                p.setArticleInfo(o);
                p.ctrlAndUpdateArticle("fperart", field, response.translatedText, "Traduction auto désignation " + lang.toUpperCase());
                setTranslating(false);
            },
            (error) => {
                p.setError(errorManagement.handleError(p.props, error));
                setTranslating(false);
                scrollToTop();
            }
        )*/
    }

    /**
     * Set blocking justification
     * @param {*} e 
     */
    const setBlockingCause = (e) => {
        var val = e.target.value;
        var key = getKey(p.change, "field", "BLOCL");
        var arr = [...p.change];
        arr[key].cause = val;

        //Save in Focus comment
        var intro = "Le " + new Date().toLocaleDateString("fr") + ", " + user.first_name + " " + user.last_name + " a "
            + (p.articleInfo.BLOCL === "A" ? "bloqué en appro" :
                p.articleInfo.BLOCL === "C" ? "bloqué complètement" : "débloqué") + " cet article "
            + "parce que: ";
        var com_no_ord = document.getElementById("com_norder");
        var o = { ...p.articleInfo };
        o["cause_blocking"] = val;

        var index = com_no_ord.value.indexOf("Le " + new Date().toLocaleDateString("fr") + ", " + user.first_name + " " + user.last_name + " a ");
        if (index !== -1) {
            com_no_ord.value = com_no_ord.value.substring(0, index) + intro + val;
        } else {
            com_no_ord.value = com_no_ord.value + intro + val;
        }

        p.setArticleInfo(o);

        changeCommentNoEvent("com_no_ord", com_no_ord.value);
    }

    /**
     * Change the obs after setting blocking justification
     * @param {*} field 
     * @param {*} value 
     */
    const changeCommentNoEvent = (field, value) => {
        var com_ord = document.getElementById("com_order");
        var com_norder = document.getElementById("com_norder");
        var old_value = p.articleInfo["comments"];

        var valueToSave = com_ord.value
            + "\r[----- Les observations ci-dessous sont masquées dans les commandes--]\r"
            + com_norder.value;

        var arr = [...p.change];

        var key = getKey(arr, "field", "obses");
        if (key === null) {
            var o = {
                table: "fartobs",
                field: "obses",
                old_value: old_value,
                new_value: valueToSave,
                label: "Commentaires",
                cause: ""
            }

            arr.push(o);
        } else {
            if (parseFloat(arr[key].old_value) === parseFloat(valueToSave)
                || arr[key].old_value === valueToSave
                || (arr[key].old_value === null && valueToSave === "")) {
                arr = arrayRemoveElt(arr, key);
            } else {
                arr[key].new_value = valueToSave;
            }
        }

        p.setChange(arr);

        var o = { ...p.articleInfo };
        o[field] = value;
        p.setArticleInfo(o);

        p.control(o, arr);
    }

    /**
     * Change obs and merge between obs displaying and hidden in order
     * @param {*} e 
     */
    const changeComment = (e) => {
        var com_ord = document.getElementById("com_order");
        var com_norder = document.getElementById("com_norder");
        var field = e.target.getAttribute("data-field");
        var value = e.target.value;
        var old_value = p.articleInfo["comments"];

        var valueToSave = com_ord.value
            + "\r[----- Les observations ci-dessous sont masquées dans les commandes--]\r"
            + com_norder.value;

        var arr = [...p.change];

        var key = getKey(arr, "field", "obses");
        if (key === null) {
            var o = {
                table: "fartobs",
                field: "obses",
                old_value: old_value,
                new_value: valueToSave,
                label: "Commentaires",
                cause: ""
            }

            arr.push(o);
        } else {
            if (parseFloat(arr[key].old_value) === parseFloat(valueToSave)
                || arr[key].old_value === valueToSave
                || (arr[key].old_value === null && valueToSave === "")) {
                arr = arrayRemoveElt(arr, key);
            } else {
                arr[key].new_value = valueToSave;
            }
        }

        p.setChange(arr);

        var o = { ...p.articleInfo };
        o[field] = value;
        p.setArticleInfo(o);

        p.control(o, arr);
    }

    /**
     * Search drawing on server
     */
    const searchPan = () => {
        setSearchingPlan(true);

        ArticleInfo.searchDrawing(p.articleCode, p.articleInfo.ZN02PA).then(
            (response) => {
                setDrawingFound(true);
                setSearchingPlan(false);
            },
            (error) => {
                p.setError(errorManagement.handleError(p.props, error));
                scrollToTop();
                setSearchingPlan(false);
            }
        )
    }

    /** */
    function isCat(cat, val) {
        return cat.trim().replaceAll("é", "").toUpperCase().replace(/ /g, '') ===
            forceString(val).trim().replaceAll("é", "").toUpperCase().replace(/ /g, '');
    }

    return (
        <div>
            {/** LABEL - NEED - VISIBLE */}
            <div className="d-flex">
                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <input type="text" className="form-control" onChange={p.updateArticle}
                        data-table="farticl" data-field="DES1L" value={p.articleInfo.DES1L}
                        id="des1l" readOnly={p.readonly} maxLength={30} />
                    <label htmlFor="des1l">{t('short_lab')}*</label>
                </div>

                <div className="form-check form-switch" style={{ width: "20%" }}>
                    <input className="form-check-input" type="checkbox" onChange={p.updateArticle}
                        data-table="farticl" data-field="VISIL" checked={p.articleInfo.VISIL === "O"}
                        readOnly={p.readonly} role="switch" id="visil" />
                    <label className="form-check-label" htmlFor="visil">{t('visible')}</label>

                    <input className="form-check-input" type="checkbox" onChange={p.updateArticle}
                        data-table="farticl" data-field="GEBEL" checked={p.articleInfo.GEBEL === "O"}
                        readOnly={p.readonly} role="switch" id="gebel" />
                    <label className="form-check-label" htmlFor="gebel">{t('need')}</label>
                </div>
            </div>

            {/** LABEL FR - LABEL EN - LABEL DE*/}
            <div className="d-flex">
                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <input type="text" className="form-control" onChange={p.updateArticle}
                        data-table="fperart" data-field="ZN03PA" value={p.articleInfo.ZN03PA}
                        id="zn03pa" readOnly={p.readonly} />
                    <label htmlFor="zn03pa">{t('com_lab')}*</label>
                </div>

                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <input type="text" className="form-control" onChange={p.updateArticle}
                        data-table="fperart" data-field="ZN12PA" value={p.articleInfo.ZN12PA}
                        id="zn12pa" />
                    <label htmlFor="zn12pa">{t('en_lab')}*</label>
                </div>

                <div style={{ marginRight: "10px", marginTop: "auto", marginBottom: "auto" }}>
                    <button className="btn btn-secondary" disabled={translating}
                        data-lang="en" data-field="ZN12PA" onClick={translate}>
                        {translating && <ButtonWaiting />}
                        Traduire
                    </button>
                </div>

                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <input type="text" className="form-control" onChange={p.updateArticle}
                        data-table="fperart" data-field="ZN14PA" value={p.articleInfo.ZN14PA}
                        id="ZN14PA" readOnly={p.readonly} />
                    <label htmlFor="ZN14PA">{t('de_lab')}*</label>
                </div>

                <div style={{ marginRight: "10px", marginTop: "auto", marginBottom: "auto" }}>
                    <button className="btn btn-secondary" disabled={translating}
                        data-lang="de" data-field="ZN14PA" onClick={translate}>
                        {translating && <ButtonWaiting />}
                        Traduire
                    </button>
                </div>
            </div>

            {/** PLAN REF - TECH LAB - MATTER */}
            <div className="d-flex">
                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <input type="text" className="form-control" onChange={p.updateArticle}
                        data-table="fperart" data-field="ZN02PA" value={p.articleInfo.ZN02PA}
                        id="zn02pa" readOnly={!specificChange} />
                    <label htmlFor="zn02pa">{t('plan')}*</label>
                </div>

                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <input type="text" className="form-control" onChange={p.updateArticle}
                        data-table="farticl" data-field="DES2L" value={p.articleInfo.DES2L}
                        id="des2l" readOnly={!specificChange} maxLength={30} />
                    <label htmlFor="des2l">{t('tec_lab')}*</label>
                </div>

                <div className="form-floating fillInBlank">
                    <select className="form-select" onChange={p.updateArticle}
                        data-table="farticl" data-field="MATIL" id="matil" disabled={p.readonly}>
                        <option value=""></option>
                        <option value="Métal" selected={p.articleInfo.MATIL === "Métal"}>{t('metal')}</option>
                        <option value="Plastique" selected={p.articleInfo.MATIL === "Plastique"}>{t('plastic')}</option>
                    </select>
                    <label htmlFor="matil">{t('matter')}</label>
                </div>

            </div>

            {/** CAT - CHAPTER - Family */}
            <div className="d-flex">
                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <select className="form-select" onChange={p.updateArticle}
                        data-table="fperart" data-field="ZN15PA" id="zn15pa" disabled={p.readonly}
                        title={p.articleInfo.ZN15PA}>
                        <option value=""></option>
                        <option value="Std" selected={isCat("std", p.articleInfo.ZN15PA)}>Std</option>
                        <option value="Std Pneu" selected={isCat("Std Pneu", p.articleInfo.ZN15PA)}>Std Pneu</option>
                        <option value="Hst" selected={isCat("hst", p.articleInfo.ZN15PA)}>Hst</option>
                        <option value="Hst Pneu" selected={isCat("hst pneu", p.articleInfo.ZN15PA)}>Hst Pneu</option>
                        <option value="Peint" selected={isCat("peint", p.articleInfo.ZN15PA)}>Peint</option>
                        <option value="Spé" selected={isCat("spé", p.articleInfo.ZN15PA)}>Spé</option>
                        <option value="CSM" selected={isCat("csm", p.articleInfo.ZN15PA)}>CSM</option>
                        <option value="CSM vis" selected={isCat("csm vis", p.articleInfo.ZN15PA)}>CSM vis</option>
                        <option value="CSM élec" selected={isCat("csm elec", p.articleInfo.ZN15PA)}>CSM élec</option>
                        <option value="CSM cablo" selected={isCat("csm cablo", p.articleInfo.ZN15PA)}>CSM cablo</option>
                        <option value="OLD" selected={isCat("old", p.articleInfo.ZN15PA)}>OLD</option>
                        <option value="CMP" selected={isCat("cmp", p.articleInfo.ZN15PA)}>CMP</option>
                        <option value="Presta" selected={isCat("presta", p.articleInfo.ZN15PA)}>Presta</option>
                    </select>
                    <label htmlFor="zn15pa">{t('category')}*</label>
                </div>

                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <select className="form-select" onChange={p.updateArticle}
                        data-table="farticl" data-field="CHAPL" id="chapl" disabled={p.readonly}>
                        <option value=""></option>
                        {
                            p.chapter.map(
                                (v, k) =>
                                    <option key={"chap" + k} value={v.CODCS}
                                        selected={parseInt(v.CODCS) === parseInt(p.articleInfo.CHAPL)}>{v.LI1CS}</option>
                            )
                        }
                    </select>
                    <label htmlFor="chapl">{t('chapter')}*</label>
                </div>

                <div className="form-floating mb-3 fillInBlank">
                    <select className="form-select" onChange={p.updateArticle}
                        data-table="FARTICL" data-field="CFAML" id="CFAML"
                        value={p.articleInfo.CFAML && p.articleInfo.CFAML.trim()} disabled={p.readonly}>
                        <option value=""></option>
                        <option value="MA" className="comp">Actionneur mecanique</option>
                        <option value="PA" className="comp">Actionneur pneumatique</option>
                        <option value="PV" className="comp">Composant pour le vide</option>
                        <option value="MS" className="comp">Eléments standards</option>
                        <option value="ML" className="comp">Guidage lineaire</option>
                        <option value="MR" className="comp">Guidage rotatif (rlt  palier)</option>
                        <option value="MM" className="comp">Matiere (plaque  plat  profile)</option>
                        <option value="OI" className="fab">Piece d'injection  Mat.plastiq</option>
                        <option value="OF" className="fab">Piece de fraisage</option>
                        <option value="OS" className="fab">Piece de mecano-soudure</option>
                        <option value="OT" className="fab">Piece de tôlerie</option>
                        <option value="OO" className="fab">Piece de tournage</option>
                        <option value="OG" className="fab">Piece forgee</option>
                        <option value="PR" className="comp">Raccord</option>
                        <option value="OE" className="fab">Tournage  Fraisage</option>
                        <option value="PF" className="comp">Traitement de l'air</option>
                        <option value="PT" className="comp">Tuyauterie</option>
                        <option value="MV" className="comp">Visserie / Stock géré à vue</option>
                    </select>
                    <label htmlFor="CFAML">Famille d'article*</label>
                </div>

                <div className="form-floating mb-3 fillInBlank ms-2">
                    <select className="form-select" onChange={p.updateArticle}
                        data-table="FARTICL" data-field="CATRL" id="CATRL"
                        value={p.articleInfo.CATRL && p.articleInfo.CATRL.trim()} disabled={p.readonly}>
                        <option value=""></option>
                        <option value="BPLUS">Article B+</option>
                        <option value="COMP">Composant</option>
                        <option value="FAB">Autres constructeurs</option>
                        <option value="SCL">Système d'encollage</option>
                    </select>
                    <label htmlFor="CATRL">Catégorie personnalisée</label>
                </div>


            </div>

            {/** COST -  HST CODE - COUNTRY */}
            <div className="d-flex">
                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <input type="text" className="form-control"
                        onChange={p.updateArticle} data-table="farticl" data-field="PXREL"
                        value={user.roles.includes("ROLE_INTERNAL") ? p.articleInfo.PXREL
                            : (p.articleInfo.PXREL * 1.45) * 1.05} id="pxrel" readOnly={!p.purchase} />
                    <label htmlFor="pxrel">{t('cost_prc')}</label>
                </div>

                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <input type="text" className="form-control" onChange={p.updateArticle}
                        data-table="farticl" data-field="DOUA" value={p.articleInfo.DOUA}
                        id="doua" readOnly={p.readonly} />
                    <label htmlFor="doua">{t('cust_nom')}</label>
                </div>

                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <input type="text" className="form-control" onChange={p.updateArticle}
                        data-table="fperart" data-field="ZN23PA" value={p.articleInfo.ZN23PA}
                        id="ZN23PA" readOnly={p.readonly} />
                    <label htmlFor="ZN23PA">{t('hs-code')}</label>
                </div>

                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <input type="text" className="form-control" onChange={p.updateArticle}
                        data-table="fperart" data-field="ZN17PA" value={p.articleInfo.ZN17PA}
                        id="zn17pa" readOnly={p.readonly} />
                    <label htmlFor="zn17pa">{t('country_origin')}</label>
                </div>
            </div>

            {/** STOCK MINI - REPLENISHMENT - SECU - LOCATION */}
            <div className="d-flex">
                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <input type="number" step="any" className="form-control" onChange={p.updateArticle}
                        data-table="farticl" data-field="STMIL" value={p.articleInfo.STMIL}
                        id="stmil" readOnly={p.readonly} />
                    <label htmlFor="stmil">{t('min_stck')}</label>
                </div>

                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <input type="number" step="any" className="form-control" onChange={p.updateArticle}
                        data-table="farticl" data-field="QREAL" value={p.articleInfo.QREAL}
                        id="qreal" readOnly={p.readonly} />
                    <label htmlFor="qreal">{t('reap_qty')}</label>
                </div>

                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <input type="number" step="any" className="form-control" onChange={p.updateArticle}
                        data-table="farticl" data-field="DELRL" value={p.articleInfo.DELRL}
                        id="delrl" readOnly={p.readonly} />
                    <label htmlFor="delrl">{t('reap_del')}</label>
                </div>

                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <input type="number" step="any" className="form-control" onChange={p.updateArticle}
                        data-table="farticl" data-field="STSEL" value={p.articleInfo.STSEL}
                        id="stsel" readOnly={p.readonly} />
                    <label htmlFor="stsel">{t('sec_stck')}</label>
                </div>

                <div className="form-floating me-3 fillInBlank">
                    <select className="form-select" onChange={p.updateArticle}
                        data-table="farticl" data-field="EMPSL" id="empsl" disabled={p.readonly}>
                        <option value=""></option>
                        <option value="878" selected={parseInt(p.articleInfo.EMPSL) === 878}>_EMB: sur stock</option>
                        <option value="879" selected={parseInt(p.articleInfo.EMPSL) === 879}>_sur affaire (hors stock)</option>
                    </select>
                    <label htmlFor="empsl">{t('location')}*</label>
                </div>

                <div className="form-floating fillInBlank">
                    <select className="form-select" disabled>
                        <option selected>Choose...</option>
                        {
                            p.unit.map(
                                (v2, k2) =>
                                    <option key={"unit_" + k2} selected={p.articleInfo.CDUNL === v2.CODCS}
                                        value={v2.CODCS}>{v2.LI1CS}</option>
                            )
                        }
                    </select>
                    <label htmlFor="empsl">{t('unit-base')}*</label>
                </div>
            </div>

            {/** BLOCKING - CAUSE */}
            
                <div className="d-flex">
                    <div className="form-floating fillInBlank mb-3" style={{ marginRight: "10px" }}>
                        <select className="form-select" onChange={p.updateArticle}
                            data-table="farticl" data-field="BLOCL" id="BLOCL" disabled={p.readonly}>
                            <option value="N">Aucun</option>
                            <option value="A" selected={p.articleInfo.BLOCL === "A"}>En approvisionnement</option>
                            <option value="C" selected={p.articleInfo.BLOCL === "C"}>Complet</option>
                        </select>
                        <label htmlFor="BLOCL">Blocage</label>
                    </div>
                    { !p.readonly &&
                        <div className="form-floating mb-3 fillInBlank"
                            style={{ marginRight: "10px", display: (getKey(p.change, "field", "BLOCL") !== null ? "" : "none") }}>
                            <textarea className="form-control" id="cause_block"
                                onChange={setBlockingCause} value={p.articleInfo.cause_blocking}></textarea>
                            <label htmlFor="cause_block">Pourquoi ce (dé)blocage ? Ce sera écrit dans Focus
                            </label>
                        </div>
                    }
                    <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                        <input type="text" className="form-control" onChange={p.updateArticle}
                            data-table="fperart" data-field="ZN08PA" value={p.articleInfo.ZN08PA}
                            id="ZN08PA" readOnly={p.readonly} />
                        <label htmlFor="ZN08PA">{t('replaced-by')}</label>
                    </div>

                </div>
            

            {/** COMMENTS */}
            <div className="d-flex">
                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <textarea className="form-control" data-table="farticl" data-field="com_ord" onChange={changeComment}
                        id="com_order" value={p.articleInfo.com_ord} style={{ minHeight: "100px" }}></textarea>
                    <label htmlFor="com_order">Observations pour les commandes</label>
                </div>

                <div className="form-floating mb-3 fillInBlank">
                    <textarea className="form-control" data-table="farticl" data-field="com_no_ord" onChange={changeComment}
                        id="com_norder" value={p.articleInfo.com_no_ord} style={{ minHeight: "100px" }}></textarea>
                    <label htmlFor="com_norder">Observations à masquer dans les commandes</label>
                </div>
            </div>

            {/** DIMENSIONS - WEIGHT - GENERIC */}
            <div className="d-flex">
                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <input type="number" step="any" className="form-control" onChange={p.updateArticle}
                        data-table="farticl" data-field="DIM1L" value={p.articleInfo.DIM1L}
                        id="DIM1L" readOnly={p.readonly} />
                    <label htmlFor="DIM1L">Dim 1</label>
                </div>

                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <input type="number" step="any" className="form-control" onChange={p.updateArticle}
                        data-table="farticl" data-field="DIM2L" value={p.articleInfo.DIM2L}
                        id="DIM2L" readOnly={p.readonly} />
                    <label htmlFor="DIM2L">Dim 2</label>
                </div>

                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <input type="number" step="any" className="form-control" onChange={p.updateArticle}
                        data-table="farticl" data-field="DIM3L" value={p.articleInfo.DIM3L}
                        id="DIM3L" readOnly={p.readonly} />
                    <label htmlFor="DIM3L">Dim 3</label>
                </div>

                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <input type="number" step="any" className="form-control" onChange={p.updateArticle}
                        data-table="farticl" data-field="POIDL" value={p.articleInfo.POIDL}
                        id="POIDL" readOnly={p.readonly} />
                    <label htmlFor="POIDL">{t('weight')}</label>
                </div>

                { (p.articleInfo.ZN15PA && p.articleInfo.ZN15PA.toLowerCase().replace("é", "e") === "spe" ) &&
                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <input type="text" className="form-control" onChange={p.updateArticle}
                        data-table="farticl" data-field="CGARL" value={p.articleInfo.CGARL}
                        id="CGARL" readOnly={p.readonly} />
                    <label htmlFor="CGARL">{t('generic')}*</label>
                </div>

                }

            </div>

            {/** SPARES - USURE - CRITICITY */}
            <div className="d-flex">
                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <select className="form-select" onChange={p.updateArticle}
                        data-table="fperart" data-field="ZN05PA" id="ZN05PA" value={p.articleInfo.ZN05PA} disabled={p.readonly}>
                        <option value=""></option>
                        <option value="O">Oui</option>
                        <option value="N">Non</option>
                        <option value="Indéfini">Indéfini</option>
                    </select>
                    <label htmlFor="ZN05PA">Sparespart</label>
                </div>

                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <input type="text" className="form-control" onChange={p.updateArticle}
                        data-table="fperart" data-field="ZN07PA" value={p.articleInfo.ZN07PA}
                        id="ZN07PA" readOnly={p.readonly} />
                    <label htmlFor="ZN07PA">Remplacé en SAV / sur site par</label>
                </div>

                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <select className="form-select" onChange={p.updateArticle}
                        data-table="fperart" data-field="ZN13PA" id="ZN13PA" value={p.articleInfo.ZN13PA} disabled={p.readonly}>
                        <option value=""></option>
                        <option value="O">Oui</option>
                        <option value="N">Non</option>
                        <option value="Indéfini">Indéfini</option>
                    </select>
                    <label htmlFor="ZN13PA">Pièce d'usure</label>
                </div>

                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                    <select className="form-select" onChange={p.updateArticle} value={p.articleInfo.ZN06PA}
                        data-table="fperart" data-field="ZN06PA" id="ZN06PA" disabled={p.readonly}>
                        <option value=""></option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                        <option value="Indéfini">Indéfini</option>
                    </select>
                    <label htmlFor="ZN06PA">Criticité</label>
                </div>
            </div>

            {
                (p.articleInfo.ZN09PA !== null && p.articleInfo.ZN09PA !== 'ignore') &&
                <Fragment>
                    {
                        drawingFound &&
                        <div className="alert alert-success fw-bold text-center">
                            The drawing has been found please refresh the page.
                        </div>
                    }
                    <div className="text-center" style={{ display: "flex" }}>
                        <a className="btn btn-success" style={{ marginLeft: "auto" }}
                            target="_blank" href={url + p.articleCode}>{t('see drawing')}</a>
                        <br></br>
                        <button className="btn btn-outline-danger" disabled={searchingPlan} onClick={searchPan}
                            style={{ marginRight: "auto", marginLeft: "10px" }}>
                            {searchingPlan && <ButtonWaiting />}
                            {t('dead link')}
                        </button>
                    </div>

                </Fragment>
            }

            {
                (p.articleInfo.ZN09PA === null && p.articleInfo.ZN02PA.startsWith("PL-")) &&
                <Fragment>
                    {
                        drawingFound &&
                        <div className="alert alert-success fw-bold text-center">
                            The drawing has been found please refresh the page.
                        </div>
                    }
                    <div className="text-center">
                        <button className="btn btn-outline-danger" disabled={searchingPlan} onClick={searchPan}>
                            {searchingPlan && <ButtonWaiting />}
                            {t('dead link')}
                        </button>
                    </div>

                </Fragment>
            }

        </div>)

}

export default BasicInfo;