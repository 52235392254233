import { Fragment, useState } from "react";
import { getBase64 } from "../../functions/StrFunctions";
import Camera, { FACING_MODES } from "react-html5-camera-photo";

{/**
    @param setImg

*/}
export const PhotoOrFile = (p) => {
    const [takeAPhoto, setTakeAPhoto] = useState(false);

    const onChooseFile = (e) => {
        if (e.target.files.length === 0)
            return;

        getBase64(e.target.files[0], (res) => {
            p.setImg(res);
        });
    }

    /**
     * Close photo device
     */
    const cancelPhoto = () => {
        setTakeAPhoto(false);
    }

    /**
     * Save the photo
     * @param {*} data 
     */
    const handlePhoto = (data) => {
        p.setImg(data);

        setTakeAPhoto(false);
    }

    return <Fragment>

        <div className="d-flex">
            <button type="button" className="custom-file-upload photo" style={{ marginLeft: "10px" }}
                onClick={() => setTakeAPhoto(true)}></button>

            <label className="custom-file-upload folder" style={{ marginLeft: "10px", marginRight: "auto" }}>
                <input type="file" accept="image/*" onChange={onChooseFile} />
            </label>

        </div>

        {takeAPhoto &&
            <div className="custom-tooltip">

                <button type="button" className="btn btn-info" onClick={cancelPhoto}>Annuler</button>

                <br></br>

                <Camera idealFacingMode={FACING_MODES.ENVIRONMENT} 
                    onTakePhoto={(data) => { handlePhoto(data) }} />
            </div>
        }

    </Fragment>

}
