import { Fragment, useEffect, useState } from "react";
import usersDao from "../../../../services/settings/usersDao";
import { forceString } from "../../../../functions/StrFunctions";
import { getHeight } from "../../../../functions/PageAnimation";

const ChooseActivity = (p) => {
    const [list, setList] = useState([]);
    const [noBom, setNoBom] = useState(false);
    const [displayDetail, setDisplayDetail] = useState(false);
    const me = JSON.parse(localStorage.getItem("user"));
    const isAdmin = me && (me.roles.includes("ROLE_ADMIN"));

    {/** Save the workstation  */ }
    const onWorkstationChoose = (e) => {
        e.preventDefault();
        var o = { ...p.user };
        o.workstation = e.target.workstation.value;
        p.setUser(o);

        usersDao.updateMyProfileById(o.id, "workstation", o.workstation);
    }

    useEffect(() => {
        if (p.user.workstation) {
            getTasks();
        } else {
            setList([]);
        }
    }, [p.user]);

    const getTasks = () => {
        if (p.bom.length === 0) {
            setNoBom(true);
        } else {
            if (p.user.workstation === "Montage") { setList(getAssembly()) }
            else if (p.user.workstation === "Cartérisation") { setList(getCarterisation()) }
            else if (p.user.workstation === "Intégration") { setList(getMecaIntegration()) }
            else if (p.user.workstation === "Intégration électrique") { setList(getElecIntegration()) }
        }
    }

    function getAssembly() {
        var arr = [];
        var o, ref = "";

        //Give NM but not FO-, IS-, HB-, TU-
        for (let i = 0; i < p.bom.length; i++) {
            o = p.bom[i];
            if (o.ref && o.ref.length > 17 && o.ref.startsWith("NM-")) {
                ref = o.ref.substring(0, 17);
                if (!ref.endsWith("FO-") && !ref.endsWith("IS-") && !ref.endsWith("HB-") && !ref.endsWith("TU-")) {
                    o.code = ref.split("-")[2];
                    o.station = "Montage";
                    arr.push(o);
                }
            }
        }

        return getStatus(arr);
    }

    function getCarterisation() {
        var arr = [];
        var o, ref = "";

        //Give NM and FO-, IS-, HB-, TU-
        for (let i = 0; i < p.bom.length; i++) {
            o = p.bom[i];
            if (o.ref && o.ref.length > 17 && o.ref.startsWith("NM-")) {
                ref = o.ref.substring(0, 17);
                if (ref.endsWith("FO-") || ref.endsWith("IS-") || ref.endsWith("HB-") || ref.endsWith("TU-")) {
                    o.code = ref.split("-")[2];
                    o.station = "Cartérisation";
                    arr.push(o);
                }
            }
        }

        return getStatus(arr);
    }

    function getMecaIntegration() {
        //Assembly + meca integration
        return getAssembly().concat(getIntegration("Intégration méca."));
    }

    function getIntegration(workstation) {
        var arr = [];
        var o, ref = "";

        //Give SE like %EG and ar like AR% and ref not like PR%
        for (let i = 0; i < p.bom.length; i++) {
            o = p.bom[i];
            if (o.ref && o.ref.length > 17) {
                ref = o.ref.substring(0, 17);
                if (o.se.endsWith("EG") && !ref.startsWith("PR-") && forceString(o.ar).startsWith("AR")) {
                    o.code = ref.split("-")[2];
                    o.station = workstation;
                    arr.push(o);
                }
            }
        }

        return getStatus(arr);
    }

    function getElecIntegration() {
        return getIntegration("Intégration élec.");
    }

    function getStatus(arr) {

        for (let i = 0; i < arr.length; i++) {
            var mysStatus = getMyStatus(arr[i]);
            arr[i].myStatus = mysStatus.status;
            
            var idx = forceString( mysStatus.comments ).indexOf("remplace");
            if( idx !== -1 )
                arr[i].blocking_cause = "Remplacé par " + mysStatus.comments.substring( 0, idx ).trim();
            
            arr[i].machineStatus = getMachineStatus(arr[i]);
            arr[i].statusByUser = statusByUser(arr[i], true);

            //Get blockage
            var blockage = false;
            for (let j = 0; j < arr[i].statusByUser.length; j++)
                if (arr[i].statusByUser[j].status === "Bloqué") blockage = true;

            arr[i].blockage = blockage;

            //Get order for sorting
            if( mysStatus.status === "Démarré" || mysStatus.status === "Redémarré" ){
                arr[i].rank = 0;
            }else if( mysStatus.status === "En attente" ){
                arr[i].rank = 1;
            }else if( mysStatus.status === "Terminé" || mysStatus.status === "Chgt. pers." ){
                arr[i].rank = 2;
            }
        }

        //Sort the array
        arr.sort( (a, b) => {return a.rank > b.rank ? 1 :  a.rank < b.rank ? -1 : 0 });

        return arr;
    }

    function getMyStatus(o) {
        var id = p.user.id;
        var workstation = [p.user.workstation.trim()];
        if (p.user.workstation.trim() === "Intégration") workstation.push("Montage");
        var ref = o.ref.trim();

        for (let i = 0; i < p.status.length; i++) {
            if (id === p.status[i].user_id && workstation.includes(p.status[i].workstation.trim())
                && ref === p.status[i].subassembly.trim()) {
                    return {status: p.status[i].status.trim(), comments: p.status[i].blocking_cause};
                }
        }

        return {status: "En attente"};
    }

    function getBg(status) {
        if (!status) return {};

        if (status === "En attente") {
            return { backgroundColor: "rgb(87, 155, 252)", color: "white" }
        } else if (status === "Démarré" || status === "Redémarré") {
            return { backgroundColor: "rgb(253,171,61)" }
        } else if (status === "Terminé" || status === "Chgt. pers.") {
            return { backgroundColor: "rgb(156, 211, 38)" }
        } else if (status === "Bloqué") {
            return { backgroundColor: "rgb(223, 47, 74)", color: "white" }
        }
    }

    const onChooseSubAssembly = (k) => {
        var o = { ...list[k] };
        o.status = getMyStatus(list[k]);
        p.setActivity(o);
        p.setStep("activity_mngt");
    }

    function getMachineStatus(o) {
        var machineData = [];

        //Get status of this task
        for (let i = 0; i < p.status.length; i++)
            if (o.ref.trim() === p.status[i].subassembly.trim()) machineData.push(p.status[i]);

        if (machineData.length === 0) return "En attente";

        // Group by user to obtain the latest status for each person
        const userStatusMap = getStatusByUser(machineData);

        // Check if at least one user has an uncompleted task
        for (let userId in userStatusMap) {
            const task = userStatusMap[userId];
            if (task.status !== "Terminé" && task.status !== "Chgt. pers.") {
                return "Démarré";
            }
        }

        return "Terminé";
    }

    function statusByUser(o, getArray = false) {
        var arr = []

        //Get status of this task
        for (let i = 0; i < p.status.length; i++)
            if (o.ref.trim() === p.status[i].subassembly.trim()) arr.push(p.status[i]);

        return getStatusByUser(arr, getArray);
    }

    function getStatusByUser(machineData, getArray = false) {
        const userStatusMap = {};
        const arr = [];

        machineData.forEach(task => {
            // If the user is already registered, we compare the dates
            if (!userStatusMap[task.user_id] || new Date(task.date) > new Date(userStatusMap[task.user_id].date))
                userStatusMap[task.user_id] = task;
        });

        if (getArray) {
            for (let userId in userStatusMap) arr.push(userStatusMap[userId])
            return arr;
        }

        return userStatusMap;
    }


    return <Fragment>

        {!p.user.workstation && <div className="ms-auto me-auto text-center mt-3" style={{ width: "300px" }}>
            <h5>Quel est votre poste ?</h5>

            <form onSubmit={onWorkstationChoose}>
                <div className="input-group mb-3">
                    <select className="form-select" name="workstation">
                        <option selected>Choisissez...</option>
                        <option value="Cartérisation">Cartérisation</option>
                        <option value="Montage">Montage</option>
                        <option value="Intégration">Intégration mécanique</option>
                        <option value="Intégration électrique">Intégration électrique</option>
                    </select>
                </div>

                <button className="btn btn-success">Valider</button>
            </form>

        </div>}

        {noBom && <div className="alert alert-warning mb-3 text-center">La nomenclature de la machine n'a pas été trouvée</div>}

        {list.length > 0 && <Fragment>
            <h5 className="text-center">Choisissez l'activité sur laquelle vous allez travailler</h5>

            <div className="table-responsive" id="status_table">
                <table className="table text-center align-middle m-auto" style={{maxWidth: "800px"}}>
                    <thead>
                        <tr className="fw-bold table-info text-center">
                            <td style={{maxWidth: "200px"}}>
                                Statut activité
                                {isAdmin && 
                                    <div className="fw-normal text-decoration-underline cursor-pointer" onClick={()=>setDisplayDetail(!displayDetail)}>
                                        { displayDetail ? "Pastilles" : "Détail"}</div>
                                }
                            </td>
                            <td>Code</td>
                            <td>Libellé</td>
                            <td style={{width: "200px"}}>Mon statut</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((v, k) => {
                            return <tr key={"se" + k}>

                                {displayDetail && <td className="text-center accordion"
                                    style={getBg(v.machineStatus)}>
                                    {v.machineStatus}

                                    {v.blockage && <div className="fst-italic bg-danger text-white rounded m-auto" style={{ width: "100px" }}>Blocage!</div>}

                                    <div>
                                        {v.statusByUser.length > 0 &&
                                            <div className="toDisplay">
                                                {v.statusByUser.map((v2, k2) => {
                                                    return <div className="fst-italic" title={v2.blocking_cause}
                                                        key={"stat" + k + "_" + k2}><strong>{v2.trigram}</strong>: {v2.status}</div>
                                                })}
                                            </div>
                                        }
                                    </div>
                                </td>}

                                {!displayDetail &&<td>
                                    <div style={Object.assign({width: "40px", height: "40px"}, getBg(v.machineStatus))} 
                                        className={"rounded-circle m-auto"}>

                                    </div>
                                </td>}
                                
                                <td>{v.code}</td>
                                <td>
                                    {v.french_label.trim()}
                                    <div className="fst-italic">{v.station}</div>
                                </td>
                                <td style={getBg(v.myStatus)}>
                                    {v.myStatus}
                                    {v.myStatus === "Chgt. pers." && 
                                        <div className="fst-italic" style={{fontSize: "12px"}}>{v.blocking_cause}</div>
                                    }
                                    <div></div>
                                </td>

                                <td className="text-center">
                                    <button className="btn btn-secondary"
                                        onClick={() => onChooseSubAssembly(k)}>Choisir cette activité</button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </Fragment>}

    </Fragment>
}

export default ChooseActivity;