import { Fragment } from "react";
import { a } from "react-router-dom";

const FAH = (props) => {

    return (
        (props && (props.roles.includes('ROLE_ADMIN') || props.roles.includes('ROLE_INTERNAL'))
        ) &&

        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                FAH
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                {/***************************************************************
                 ****************************** V2
                 ***************************************************************/}
                {
                    (props.user && props.user.trigram === null) &&
                    <li><a href={"/fah/v2/missing-trigram"} className="dropdown-item">Nouvelle FAH</a></li>
                }

                {
                    (props.user && props.user.trigram !== null) &&
                    <li><a href={"/fah/v2"} className="dropdown-item">Nouvelle FAH</a></li>
                }

                {
                    (props.user && props.user.trigram !== null) &&
                    <li><a href={"/fah/v2/my-tracking"} className="dropdown-item">Suivi de mes FAH</a></li>
                }

                {
                    (props.user && props.roles.includes("ROLE_FAH_MANAGER")) && <Fragment>
                        <li><hr className="dropdown-divider" /></li>
                        <li><a href={"/fah/v2/manager"} className="dropdown-item">Espace manager</a></li>
                    </Fragment>
                }

                {
                    (props.user && (props.roles.includes("ROLE_FAH_ACCOUNTANT") ||
                        props.roles.includes("ROLE_ADMIN"))) && <Fragment>
                        <li><hr className="dropdown-divider" /></li>
                        <li><a href={"/fah/v2/accountant"} className="dropdown-item">Espace compta.</a></li>
                    </Fragment>
                }
            </ul>
        </li>
    )

}

export default FAH;