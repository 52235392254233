import { Fragment, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { arrayRemoveElt } from "../../../../functions/ArrayFunctions";
import { ButtonWaiting } from "../../../../functions/PageAnimation";
import MachineInfo from "../../../../services/machines/MachineInfo";
import errorManagement from "../../../../services/errorManagement";
import BusinessDao from "../../../../services/machines/BusinessDao";
import ErrorMessage from "../../../../common/smallComponents.js/ErrorMessage";

const PartTwo = (props) => {

    const [addMachine, setAddMachine] = useState(false);
    const [checkingMachine, setCheckingMachine] = useState(false);
    const [errMachine, setErrMachine] = useState();
    const [err, setErr] = useState();

    /**
     * Delete machine
     * @param {*} e 
     */
    const deleteMachine = (e) => {
        var key = e.target.getAttribute("data-key");
        var arr = [...props.machines];
        setErr();

        confirmAlert({
            title: "Suppression de machine",
            message: "Etes-vous sûr(e) de vouloir supprimer la machine n° " + arr[key].no_machine + " ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setCheckingMachine(true);
                        BusinessDao.deleteMachine(arr[key]).then(
                            (response) => {
                                arr = arrayRemoveElt(arr, key);
                                props.setMachines(arr);
                                setCheckingMachine(false);
                            },
                            (error) => {
                                setErr(errorManagement.handleError(props, error));
                                setCheckingMachine(false);
                            }
                        );


                    }
                },
                {
                    label: "Non"
                }
            ]
        });

    }

    /**
     * Create machine in the business
     * @param {*} e 
     */
    const createMachine = (e) => {
        e.preventDefault();
        setErr();

        var o = {
            config: e.target.config.value,
            serial: e.target.serial.value,
            line: e.target.line.value,
            old_ref: e.target.old_ref.value,
            business: props.business,
            id_monday: props.machinesCreationInfo.id_monday //id monday of user
        }

        setCheckingMachine(true);

        BusinessDao.createMachine(o).then(
            (response) => {
                var arr = [...props.machines];
                var newMach = {
                    typ_config: o.config,
                    no_machine: o.serial,
                    old_serial_number: o.old_ref,
                    id_monday: response
                }
                arr.push(newMach);

                props.setMachines(arr);
                setCheckingMachine(false);
                setAddMachine(false);

                BusinessDao.getMachineCreationInfo().then(
                    (response) => {
                        props.setMachinesCreationInfo(response)
                    },
                    (error) => {

                    }
                )

                //increment number
                var lastNumber = props.machinesCreationInfo.lastSerialNumber;
                if (lastNumber.indexOf("/") !== -1) {
                    lastNumber = lastNumber.substring(0, lastNumber.indexOf("/") + 1)
                        + (parseInt(lastNumber.split("/")[1]) + 1)
                    var obj = { ...props.machinesCreationInfo };
                    obj.lastSerialNumber = lastNumber;
                    props.setMachinesCreationInfo(obj);
                }
            },
            (error) => {
                setErr(errorManagement.handleError(props, error));
                setCheckingMachine(false);
            }
        );
    }

    /**
     * Check if machine number exists to avoid conflict
     * @param {*} e 
     * @returns 
     */
    const checkMachineNb = (e) => {
        var val = e.target.value;
        var old = e.target.getAttribute("data-old");
        setErrMachine();

        if (val.trim() === "" || val.trim() === old) return;

        setCheckingMachine(true);

        MachineInfo.getOneMachineInfo(val).then(
            (response) => {
                if (response.affaire) {
                    setErrMachine("La machine " + val + " est affectée à l'affaire " + response.affaire);
                    e.target.value = old;
                }

                setCheckingMachine(false);
            },
            (error) => {
                setErrMachine(errorManagement.handleError(props, error));
                setCheckingMachine(false);
            }
        )
    }

    /**
     * On machine typing
     * @param {*} e 
     */
    const onMachineNumberChange = (e) => {
        var val = e.target.value;
        var key = e.target.getAttribute("data-key");
        var arr = [...props.machines];

        arr[key].no_machine = val;

        props.setMachines(arr);
    }

    /**
     * Check if we can change the serial number then do it
     * @param {*} e 
     * @returns 
     */
    const changeMachineNb = (e) => {
        var val = e.target.value;
        var old = e.target.getAttribute("data-old");
        var key = e.target.getAttribute("data-key");
        var arr = [...props.machines];
        setErr();

        if (val.trim() === "" || val.trim() === old) return;

        setCheckingMachine(true);

        MachineInfo.getOneMachineInfo(val).then(
            (response) => {
                if (response.no_machine) {
                    setErr("La machine " + val + " est affectée à l'affaire " + response.affaire);
                    arr[key].no_machine = old;
                    props.setMachines(arr);
                    setCheckingMachine(false);
                } else {

                    //Update Monday
                    var o = {
                        field: process.env.NODE_ENV === "development" ? "n__machine" : "dup__of_n__machine71",
                        value: val,
                        id_monday: arr[key].id_monday,
                        typeField: "text"
                    }

                    BusinessDao.updateMondayField(o);

                    MachineInfo.updateMachineField(old, "no_machine", val, "text").then(
                        (response) => {
                            setCheckingMachine(false);
                        },
                        (error) => {
                            setErr(errorManagement.handleError(props, error));
                            setCheckingMachine(false);
                        }
                    )
                }

            },
            (error) => {
                setErrMachine(errorManagement.handleError(props, error));
                setCheckingMachine(false);
            }
        )
    }

    /**
     * On choose config type
     * @param {*} e 
     */
    const changeConfig = (e) => {
        var val = e.target.value;
        var key = e.target.getAttribute("data-key");
        var arr = [...props.machines];
        setErr();

        //Update Monday
        var o = {
            field: "configuration_texte",
            value: val,
            id_monday: arr[key].id_monday,
            typeField: "text"
        }

        BusinessDao.updateMondayField(o);

        MachineInfo.updateMachineField(arr[key].no_machine, "typ_config", val, "text").then(
            (response) => {
                arr[key].typ_config = val;
                props.setMachines(arr);
                setCheckingMachine(false);
            },
            (error) => {
                setErr(errorManagement.handleError(props, error));
                setCheckingMachine(false);
            }
        )
    }

    /**
     * On old ref typing
     */
    const onOldRefChange = (e) => {
        var val = e.target.value;
        var key = e.target.getAttribute("data-key");
        var arr = [...props.machines];

        arr[key].old_serial_number = val;

        props.setMachines(arr);
    }

    /**
     * On validation old ref change
     */
    const changeOldRef = (e) => {
        if (props.readOnly) return;

        var val = e.target.value;
        var key = e.target.getAttribute("data-key");
        setErr();

        MachineInfo.updateMachineField(props.machines[key].no_machine, "old_serial_number", val, "text").then(
            (response) => {

            },
            (error) => {
                setErr(errorManagement.handleError(props, error));
            }
        )
    }


    return (
        < div className="table-responsive">
            {err && <ErrorMessage error={err} />}
            <table className="table businessCardStyle">
                <thead>
                    <tr className="bg-dark bg-gradient text-white text-center rounded">
                        <th scope="col">Machines</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="display-flex-wrap">
                            {props.machines && props.machines.map((v, k) => {

                                return (
                                    <Fragment>
                                        <div className="card" style={{ minWidth: "250px", marginLeft: "10px" }}>
                                            {
                                                (v.id_monday && !checkingMachine && !props.readOnly) &&
                                                <button type="button" className="btn-close" style={{ marginLeft: "auto" }}
                                                    aria-label="Close" data-key={k} onClick={deleteMachine}></button>

                                            }

                                            {
                                                checkingMachine && <ButtonWaiting />
                                            }

                                            <div className="input-group mb-3">
                                                <select className="form-select text-center fw-bold"
                                                    disabled={props.readOnly} data-key={k} onChange={changeConfig}>
                                                    <option selected>Choose...</option>
                                                    {props.configs.map(
                                                        (v2, k) => {
                                                            return (
                                                                <option value={v2.label} selected={v2.label === v.typ_config}>{v2.label}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>

                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control"
                                                    placeholder="xxx" value={v.no_machine} onChange={onMachineNumberChange}
                                                    data-old={v.originalnb} data-key={k} onBlur={changeMachineNb}
                                                    readOnly />
                                                <label>N° de série</label>
                                            </div>

                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control" onChange={onOldRefChange}
                                                    onBlur={changeOldRef} placeholder="xxx" data-key={k} value={v.old_serial_number}
                                                    readOnly={props.readOnly} />
                                                <label>Ancienne ref. si occasion</label>
                                            </div>

                                            <div className="text-center fw-bold">
                                                <a target="_blank" style={{ color: "black" }}
                                                    href={"/machine?machine=" + v.no_machine}>Ouvrir la fiche machine</a>
                                            </div>
                                        </div>
                                    </Fragment>
                                )
                            })}

                            {
                                props.machinesCreationInfo && props.machinesCreationInfo.id_monday && <Fragment>

                                    {
                                        addMachine &&
                                        <form onSubmit={createMachine}>
                                            <div className="card" style={{ minWidth: "250px", marginLeft: "10px" }}>

                                                <button type="button" className="btn-close" style={{ marginLeft: "auto" }}
                                                    aria-label="Close" onClick={() => setAddMachine(false)}></button>

                                                <h5 className="text-center">Nouvelle machine</h5>

                                                <div className="input-group mb-3">
                                                    <select className="form-select text-center fw-bold" name="config" required>
                                                        <option value="">Choose...</option>
                                                        {props.configs.map(
                                                            (v2, k) => {
                                                                return (
                                                                    <option value={v2.label}>{v2.label}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>

                                                <div className="form-floating mb-3">
                                                    <input type="text" className="form-control" name="serial" placeholder="xxx"
                                                        defaultValue={props.machinesCreationInfo.lastSerialNumber}
                                                        data-old={props.machinesCreationInfo.lastSerialNumber}
                                                        onBlur={checkMachineNb} required />
                                                    <label>N° de série</label>
                                                </div>
                                                {
                                                    errMachine && <div className="alert alert-danger">{errMachine}</div>
                                                }

                                                <div className="form-floating mb-3">
                                                    <input type="text" className="form-control" name="line"
                                                        maxLength={1} placeholder="xxx" required />
                                                    <label>N° de ligne</label>
                                                </div>

                                                <div className="form-floating mb-3">
                                                    <input type="text" className="form-control" name="old_ref" placeholder="xxx" />
                                                    <label>Ancienne ref. si occasion</label>
                                                </div>

                                                <div className="text-center">
                                                    <button className="btn btn-success" disabled={checkingMachine}>
                                                        {checkingMachine && <ButtonWaiting />}
                                                        Ajouter cette machine
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    }

                                    {
                                        !addMachine &&
                                        <div style={{ minWidth: "250px", margin: "auto" }}>
                                            <img src={"/common/add-logo.png"} onClick={() => setAddMachine(true)}></img>
                                        </div>
                                    }

                                </Fragment>
                            }

                        </td>
                    </tr>

                </tbody>
            </table>

        </div>
    )
}

export default PartTwo;