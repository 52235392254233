import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Moment from 'moment/moment';
import { Fragment, useEffect, useState } from "react";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ErrorMessage from '../../../../common/smallComponents.js/ErrorMessage';
import { ButtonWaiting, scrollToTop } from '../../../../functions/PageAnimation';
import { capitalizeFirstLetter, forceString } from '../../../../functions/StrFunctions';
import ShareFileDao from '../../../../services/common/ShareFileDao';
import errorManagement from '../../../../services/errorManagement';
import { downloadSingleFile, downloadTempFile } from '../../../../services/functions/FilesProcess';
import BusinessDao from '../../../../services/machines/BusinessDao';
const user = JSON.parse(localStorage.getItem("user"));

const ExportCr = (props) => {

    const user = JSON.parse(localStorage.getItem("user"));
    const [typeRevision, setTypeRevision] = useState(2);
    const [comments, setComments] = useState("");
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const [saveInNetwork, setSaveInNetwork] = useState(true);
    const [sendMail, setSendMail] = useState(false);
    const html = getHtml();
    const [recepients, setRecipients] = useState([]);
    const [po, setPO] = useState();
    const [rcDocx, setrcDocx] = useState();

    Moment.locale("fr");

    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(contentState))

    function getHtml() {
        var html = 'Hi All,<br><br>'

        html += "Please find attached Contract Review <strong>" + props.businessInfo.id_aff + "</strong> ";
        html += "for the deal involving the integrator <strong>" + props.businessInfo.customer_id + "</strong> ";
        html += "and end-user " + props.businessInfo.end_customer_id + "</strong>. ";
        html += "You can access the documents at: ";
        html += props.path ? "<a href=\"file:" + props.path + "\">" + props.path + "</a>" : "???";
        html += "<br><br>"
        html += "Warehouse location : <br><br>";
        html += "Equipment : ";

        var mach = '';
        var split = forceString(props.businessInfo.rc_machines).split(";");
        for (let i = 0; i < split.length; i++) if (split[i].trim() !== "") mach += "1x " + split[i].trim() + ", ";
        mach = mach.substring(0, mach.lastIndexOf(","));

        html += "<strong>" + mach + "</strong><br><br>";
        html += "Global amount: <strong>" + props.focusInfo.amount + " €</strong><br><br>";
        html += "Intervention date: <strong>" + props.businessInfo.com_del_d + "</strong><br><br>";
        html += "Sales Manager: <strong>" + capitalizeFirstLetter(props.focusInfo.salesrep_name, true) + "</strong><br><br>";
        html += "Project Manager: <strong>" + capitalizeFirstLetter(props.focusInfo.chef_proj_name, true) + "</strong><br><br>";
        html += "Service & Application:<br><br>";

        html += "Best regards,<br><br>";
        html += user.first_name + " " + user.last_name;

        return html;
    }

    useEffect(() => {
        var arr = [...props.rcRecepients];

        setRecipients(arr);

        //Get RC preview
        var o = {
            business: props.businessInfo,
            business_focus: props.focusInfo,
            business_revision: props.revisions,
            terms: props.terms
        }

        BusinessDao.exportContractReviewDocx(o).then(
            (response) => {
                setrcDocx(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error) + ": prévisualisation impossible");
                scrollToTop();
                setrcDocx();
            }
        )
    }, []);

    const onExport = () => {
        var err = "";
        setError();

        if (typeRevision === undefined) err += "Choisissez s'il s'agit d'une première diffusion, un duplicata ou une nouvelle révision.<br>";
        if (typeRevision !== undefined && typeRevision === 2 && comments.trim() === "") err += "Saisissez le suivi de révision ou \"duplicata\".<br>";

        if (err.length > 0) {
            setError(err);
            scrollToTop();
            return;
        }

        var o = {
            business: props.businessInfo,
            business_focus: props.focusInfo,
            business_revision: props.revisions,
            terms: props.terms,
            typeRevision: typeRevision,
            comments: comments,
            saveInNetwork: saveInNetwork,
            sendMail: sendMail,
            html: draftToHtml(convertToRaw(editorState.getCurrentContent())),
            path: props.path,
            recepients: recepients,
            po: po
        }

        setLoading(true);
        BusinessDao.exportContractReview(o).then(
            (response) => {
                if (saveInNetwork) {
                    props.setMsg("RC savuvegardée dans le dossier: " + props.path);
                    scrollToTop();
                } else {
                    var fileName = response.substring(response.lastIndexOf("\\") + 1, response.length);
                    downloadSingleFile("temp", fileName);
                }

                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )

    }

    const uploadPo = (e) => {
        var file = e.target.files;

        if (!file.length === 0) {
            setPO();
        } else {
            ShareFileDao.uploadTemp(file).then(
                (res) => {
                    setPO(res)
                }, (error) => {

                }
            )
        }

    }

    return (
        <div className="table-responsive">
            <br></br>

            {error && <ErrorMessage error={error} />}

            <div className="card">
                <div className="card-header mb-3">
                    <h4>Suivi de révision</h4>
                </div>

                <div className="mb-3">
                    <div className="form-check form-check-inline">
                        <label className="form-check-label fw-bold" style={{ width: "100px" }}>Il s'agit de</label>
                    </div>

                    {
                        (props.revisions && props.revisions.length <= 0) &&
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" checked={typeRevision === 0}
                                onClick={() => setTypeRevision(0)} />
                            <label className="form-check-label" for="inlineRadio1">Une première diffusion</label>
                        </div>
                    }

                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" checked={typeRevision === 1}
                            onClick={() => setTypeRevision(1)} />
                        <label className="form-check-label" for="inlineRadio1">Un duplicata</label>
                    </div>

                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" checked={typeRevision === 2}
                            onClick={() => setTypeRevision(2)} />
                        <label className="form-check-label" for="inlineRadio1">Une nouvelle révision</label>
                    </div>
                </div>

                {
                    typeRevision === 2 &&
                    <div className="form-floating mb-3">
                        <textarea className="form-control" placeholder="Leave a comment here"
                            value={comments} onChange={(e) => setComments(e.target.value)}></textarea>
                        <label for="floatingTextarea">Saisissez un suivi de révision</label>
                    </div>
                }

                <div className="mb-3">
                    {!props.path && <div className="form-check form-check-inline fw-bold text-danger">Enregistrement sur le serveur impossible, le dossier n'a pas été trouvé</div>}
                    {props.path &&
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" checked={saveInNetwork} onClick={(e) => setSaveInNetwork(e.target.checked)} />
                            <label className="form-check-label" for="inlineCheckbox1">Enregister directement sur le serveur</label>
                        </div>}
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" checked={sendMail} onClick={(e) => setSendMail(e.target.checked)} />
                        <label className="form-check-label" for="inlineCheckbox2">Notifier par mail</label>
                    </div>
                </div>

                {
                    sendMail &&
                    <div className='d-flex'>
                        <div className='card me-3' style={{ width: "350px" }}>
                            <h6 className='text-center'>Destinataires
                                <a href='/settings/contacts-mng' target='_blank' title='Modifier la liste de distribution'>
                                    <img src='/common/edit.png' className='ms-2' style={{ width: "20px" }} />
                                </a>
                            </h6>
                            {recepients.map((v, k) => {
                                return <div key={"rec" + k}>{v}</div>
                            })}
                        </div>
                        <div>
                            <Editor editorState={editorState}
                                onEditorStateChange={setEditorState}
                                wrapperClassName="draftEditor-wrapper" editorClassName="draftEditor-editor" />

                            <div className='mt-3'>

                                <h6>Ajouter un PO</h6>
                                <input type='file' name="po" className='form-control' onChange={uploadPo} />

                                {po && <Fragment>
                                    <p>Le fichier {po} sera joint au mail</p>
                                    <button className='btn btn-outline-danger'>Retirer ce fichier</button>
                                </Fragment>}

                            </div>

                        </div>
                    </div>
                }

                <br></br>

                <div className='alert alert-info col-8 offset-2'>
                    <h5>Révision(s) déjà saisie(s)</h5>
                    {
                        props.revisions &&
                        <Fragment>
                            <ul>
                                {
                                    props.revisions.map((v, k) => {
                                        return <li key={"rev" + k}>{v.date_suivi}: {v.commentaire_suivi}</li>
                                    })
                                }
                            </ul>
                        </Fragment>
                    }
                </div>

                <br></br>

                <div className='text-center'>
                    {!rcDocx && <div><ButtonWaiting />Prévisualisation en cours de création</div>}
                    {rcDocx && <button className='btn btn-secondary me-3'
                        onClick={() => downloadTempFile(rcDocx) }>Télécharger le brouillon</button>}

                    <button className='btn btn-success' onClick={onExport} disabled={loading}>
                        {loading && <ButtonWaiting />}
                        Exporter
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ExportCr;