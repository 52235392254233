import { Fragment, useEffect, useState } from "react";
import NeedsMngt from "../../services/articles/NeedsMngt";
import errorManagement from "../../services/errorManagement";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import { confirmAlert } from "react-confirm-alert";
import { ButtonWaiting } from "../../functions/PageAnimation";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { useMemo } from "react";
import { DefaultColumnFilter, SelectEmptyOrNot, filterMethods } from "../../functions/TableFuntions";
import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table";

const ListKitNeeds = (props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [list, setList] = useState();
    const [deleting, setDeleting] = useState(false);
    const [data, setData] = useState([]);
    const user = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        NeedsMngt.getKitNeedList().then(
            (response) => {
                setData(response);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        )
    }, []);

    //Set columns
    const columns = useMemo(
        () => [
            {
                Header: "#",
                accessor: "id"
            },
            {
                Header: "Référence du kit",
                accessor: "kit"
            },
            {
                Header: "Affaire",
                accessor: "business"
            },
            {
                Header: "Machine",
                accessor: "machine"
            },
            {
                Header: "Date",
                accessor: "date_use"
            },
            {
                Header: "SERIE Dispo à sortir du stock",
                accessor: "affected",
                Cell: (p) => p.value > 0 && <img src={"/common/check.png"} style={{ width: "40px" }}></img>
            }
        ], []
    );

    //Set filters for table
    const filterTypes = useMemo(() => (filterMethods()), []);

    //Set default columns of table
    const defaultColumn = useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter
        }),
        []
    );

    //Create table
    const tableInstance = useTable({ columns, data, defaultColumn, filterTypes }, useFilters, useGlobalFilter, useSortBy);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance


    const closeNeeds = (e) => {
        var id = e.target.getAttribute("data-id");

        confirmAlert({
            title: "Confirmation",
            message: "Cette action mettra cette ligne en statut clôturé, confirmez ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setDeleting(true);
                        NeedsMngt.closeKit(id).then(
                            (response) => {
                                var arr = [...data];
                                arr = arrayRemoveElt(arr, e.target.getAttribute("data-key"));
                                setDeleting(false);
                                setData(arr);
                            },
                            (error) => {
                                setError(errorManagement.handleError(props, error));
                                setDeleting(false);
                            }
                        )
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    return (
        <Fragment>
            <h4 className="text-center">Kits à traiter</h4>

            <br></br>
            {loading && <WaitingRoundSnippers />}
            {error && <ErrorMessage error={error} errorState={setError} />}
            {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

            {
                data.length > 0 &&
                <table {...getTableProps()} className="table text-center" id="subcontracting_table">
                    <thead>
                        {// Loop over the header rows
                            headerGroups.map(headerGroup => (
                                // Apply the header row props
                                <tr className="table-dark fixed-header" {...headerGroup.getHeaderGroupProps()}>
                                    {// Loop over the headers in each row
                                        headerGroup.headers.map(column => (
                                            // Apply the header cell props
                                            <th {...column.getHeaderProps(column.getSortByToggleProps(),)}>
                                                {column.render('Header')}

                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? ' 🔽'
                                                            : ' 🔼'
                                                        : ''}
                                                </span>

                                            </th>

                                        ))}

                                    <th scope="col">Progression</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>



                            ))}
                        {// Loop over the header rows
                            headerGroups.map(headerGroup => (
                                // Apply the header row props
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {// Loop over the headers in each row
                                        headerGroup.headers.map(column => (
                                            // Apply the header cell props
                                            <th {...column.getHeaderProps()}>
                                                {/* Render the columns filter UI */}
                                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                                            </th>
                                        ))}
                                    <th></th>
                                    <th></th>
                                </tr>
                            ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {// Loop over the table rows
                            rows.map((row, k) => {
                                // Prepare the row for display
                                prepareRow(row)
                                return (
                                    // Apply the row props
                                    <tr {...row.getRowProps()}>
                                        {// Loop over the rows cells
                                            row.cells.map(cell => {
                                                return (
                                                    <td {...cell.getCellProps()}>
                                                        {cell.render('Cell')}
                                                    </td>
                                                )
                                            })}

                                        <td>
                                            {((row.original.total_out / row.original.total_qty) * 100).toFixed(0)}%
                                        </td>

                                        <td>
                                            <a href={"/article/kit/business/display?id=" + row.original.id}
                                                style={{ marginLeft: "10px" }} target="_blank"
                                                className="btn btn-outline-secondary" disabled={deleting}>
                                                {deleting && <ButtonWaiting />}
                                                Entrer
                                            </a>

                                            {
                                                (user.roles.includes('ROLE_ADMIN') || user.roles.includes('ROLE_STOREHOUSE') || user.email === row.original.author) &&
                                                <a href="#" onClick={closeNeeds} style={{ marginLeft: "10px" }}
                                                    className="btn btn-outline-danger" data-id={row.original.id}
                                                    data-key={k} disabled={deleting}>
                                                    {deleting && <ButtonWaiting />}
                                                    Clôturer
                                                </a>
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            }

        </Fragment>
    )

}

export default ListKitNeeds;