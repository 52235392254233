
const FinalCustomer = (props) => {
    return (
        < div className="table-responsive">
            <table className="table businessCardStyle">
                <thead>
                    <tr className="table-dark text-center rounded">
                        <th scope="col" colSpan={3}>Final customer</th>
                    </tr>
                </thead>
                <tbody>
                    <tr >
                        <td style={{ width: "33.3%" }} className="border-end">
                            <div>
                                <h6>Final customer</h6>

                                <input type="text" className="form-control" defaultValue={props.businessInfo.end_customer_id}
                                    onBlur={props.updateBusinessField} data-field="end_customer_id"
                                    readOnly={props.readOnly} />
                            </div>
                        </td>

                        <td style={{ width: "33.3%" }} className="border-end">
                            <div>
                                <h6>VAT number</h6>
                                <div className="text-center fw-bold">{props.focusInfo.final_cli_vat}</div>
                            </div>
                        </td>

                        <td style={{ width: "33.3%" }}>
                            <h6>Business sector</h6>
                            <input type="text" className="form-control" defaultValue={props.businessInfo.secteur_act}
                                onBlur={props.updateBusinessField} list="secteur_act" data-field="secteur_act"
                                readOnly={props.readOnly} />
                        </td>
                    </tr>

                    <tr>
                        <td style={{ width: "33.3%" }} className="border-end">
                            <div>
                                <h6>Contact 1</h6>
                                <div className="text-center fw-bold">
                                    <span>{props.focusInfo.final_cli_contact}</span>
                                    <br></br>
                                    <span>{props.focusInfo.final_cli_tel}</span>
                                    <br></br>
                                    <span>{props.focusInfo.final_cli_mail}</span>
                                    <br></br>
                                </div>
                            </div>
                        </td>

                        <td style={{ width: "33.3%" }} className="border-end">
                            <h6>Contact 2</h6>

                            <input type="text" className="form-control mb-1" defaultValue={props.businessInfo.fin_cus_name}
                                onBlur={props.updateBusinessField} placeholder="Name - Position" data-field="fin_cus_name"
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-1" defaultValue={props.businessInfo.fin_cus_tel}
                                onBlur={props.updateBusinessField} placeholder="Tel" data-field="fin_cus_tel"
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control" defaultValue={props.businessInfo.fin_cus_mail}
                                onBlur={props.updateBusinessField}  placeholder="Mail" data-field="fin_cus_mail"
                                readOnly={props.readOnly} />
                        </td>

                        <td style={{ width: "33.3%" }}>
                            <h6>Delivery adddress</h6>
                            <div className="text-center fw-bold">
                                <span>{props.focusInfo.final_cli_name}</span>
                                <br></br>
                                <span>{props.focusInfo.final_cli_address}</span>
                                {props.focusInfo.final_cli_comp_address && <span>{props.focusInfo.final_cli_comp_address}</span>}
                                <br></br>
                                <span>{props.focusInfo.final_cli_postal_code}</span>
                                <br></br>
                                <span>{props.focusInfo.final_cli_country}</span>
                                <br></br>
                                <span>{props.focusInfo.final_cli_del_mail}</span>
                                <br></br>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>

        </div>
    )
}

export default FinalCustomer;