import { createColumnHelper } from "@tanstack/react-table";
import Moment from "moment";
import { Fragment, useEffect, useState } from "react";
import isEmail from "validator/lib/isEmail";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import { removeEltArray, sortArrayText } from "../../functions/ArrayFunctions";
import { ButtonWaiting, getHeight, scrollToTop } from "../../functions/PageAnimation";
import { getFrFormat } from "../../functions/StrFunctions";
import errorManagement from "../../services/errorManagement";
import orderInfo from "../../services/purchase/orderInfo";
import CustomToolTip from "../../common/smallComponents.js/CustomToolTip";
import { getExpander } from "../../common/smallComponents.js/Filter";

const OrderTracking = (props) => {
    const [data, setData] = useState();
    const [dynamicStck, setDynamicStck] = useState();
    const [tab, setTab] = useState(0);

    const [error, setError] = useState();
    const [msg, setMsg] = useState();

    const [checked, setChecked] = useState({});
    const [emailChange, setEmailChange] = useState();
    const [sending, setSending] = useState(false);

    Moment.locale('fr');

    useEffect(() => {
        orderInfo.ordersTracking().then(
            (response) => {
                setData(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
            }
        )

        orderInfo.dynamicStock().then(
            (response) => {
                handleDynamicStock(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
            }
        )
    }, []);

    function handleDynamicStock(arr) {
        var done = "";
        var newArr = [];

        var o = new Object();
        for (let i = 0; i < arr.length; i++) {

            if (done.indexOf(arr[i].ar) === -1 && o.ar) {
                //If found next order
                if (o.supplier) newArr.push(o);
                o = new Object();
            }

            if (arr[i].running_total <= 0 && done.indexOf(arr[i].ar) === -1) {
                done += arr[i].ar + ",";
                o = { ...arr[i] };
            }

            if (arr[i].type.trim() === "in" && o.ar && o.ar === arr[i].ar) {
                done = "";
                o.supplier = arr[i].supplier;
                o.del_date = arr[i].date;
            }

        }

        setDynamicStck(newArr);
    }

    const onCheckOrder = (e, father) => {
        var o = { ...checked };

        var orderContent = [];
        for (let i = 0; i < data[father].subRow.length; i++) orderContent.push(i)

        if (e.target.checked) { o[father] = orderContent; }
        else { delete o[father]; }

        setChecked(o);
    }

    const onCheckRow = (e, father, son) => {
        var o = { ...checked };

        if (e.target.checked) {
            if (father in o) {
                o[father].push(son);
            } else {
                o[father] = [son];
            }
        } else {
            var arr = [...o[father]];
            arr = removeEltArray(arr, son);
            if (arr.length === 0) {
                delete o[father];
            } else {
                o[father] = arr;
            }
        }

        setChecked(o);
    }

    const removeArtFromRecap = (father, son) => {
        var o = { ...checked };

        var arr = [...o[father]];
        arr = removeEltArray(arr, parseInt(son));
       
        if (arr.length === 0) {
            delete o[father];
        } else {
            o[father] = arr;
        }

        setChecked(o);
    }

    useEffect(() => {
        setColumns(getColumns(checked));
    }, [checked, data]);

    const getColumns = (checked) => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(getExpander(columnHelper));

        arr.push(columnHelper.accessor('order_n', {
            header: 'Commande',
            cell: i => <div className="text-center">
                <input className={"form-check-input me-2 " + i.getValue()} type="checkbox"
                    value={i.getValue()} onChange={(e) => onCheckOrder(e, i.row.id)}
                    data-key={i.row.id} checked={i.row.id in checked} />
                <a href={"/purchase/order/edition?order="+i.getValue()} target="_blank">{i.getValue()}</a>
            </div>
        }));

        arr.push(columnHelper.accessor('business', {
            header: 'Affaire',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('machine', {
            header: 'Machine',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('supplier', {
            header: 'Fournisseur',
            filterFn: 'selectMultiple',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('purchaser', {
            header: 'Acheteur',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('type', {
            header: 'Alerte',
            cell: i => <div className="text-center" 
                dangerouslySetInnerHTML={{__html: i.getValue().replace(",", "<br>")}}></div>
        }));

        arr.push(columnHelper.accessor('log', {
            header: 'Historique',
            cell: i => <div className="text-center cursor-pointer flexible-div"
                dangerouslySetInnerHTML={{ __html: i.getValue() }}></div>
        }));

        return arr;
    }

    const updateCom = (e, father, son) => {
        var order = data[father].order_n;
        var row = data[father].subRow[son].no_line;
        var value = e.target.value;
        
        orderInfo.updateBpOrderInfo({
            order: order,
            row: row,
            field: "coms",
            value: value
        });
    }

    //Set columns
    const [columns, setColumns] = useState(getColumns(checked));

    const getColumns2 = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('ar', {
            header: 'Article',
            cell: i => <div className="text-center">
                <a target="_blank" href={"/article/info/stock/dynamic?article=" + i.getValue()}>{i.getValue()}</a>
            </div>
        }));

        arr.push(columnHelper.accessor('date', {
            header: 'Première date en négatif',
            cell: i => <div className="text-center">{getFrFormat(i.getValue(), true)}</div>
        }));

        arr.push(columnHelper.accessor('del_date', {
            header: 'Dernière date de livraison maj',
            cell: i => <div className="text-center">{getFrFormat(i.getValue(), true)}</div>
        }));

        arr.push(columnHelper.accessor('supplier', {
            header: 'Commande / Fournisseur',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));


        return arr;
    }

    const columns2 = getColumns2();

    const onEmailChange = (e) => {
        e.preventDefault();
        setError();
        var email = e.target.address.value;
        if (!isEmail(email)) {
            setError("Saisissez une adresse mail valide");
            return;
        }

        var arr = [...data];
        var found = false;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i].supplier === emailChange.supplier) {
                found = true;
                arr[i].mail = email;
            } else if (found) {
                break;
            }
        }

        setData(arr);
        setEmailChange();

        //Save in Focus if checked
        if (e.target.focus_save.checked) orderInfo.updateSupplierEmail(email, emailChange.supplier)

    }

    const send = () => {
        setError();
        setSending(true);

        orderInfo.remindLate( getCheckedRows() ).then(
            (response) => {
                setSending(false);
                scrollToTop();
                setMsg("Envoyés !")
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setSending(false);
            }
        );
    }

    const onDateChange = (father, son, value) => {
        var arr = [...data];

        var o = arr[father].subRow[son];
        if( !o.old ) o.old = o.last_delivery_upd;
        o.last_delivery_upd = value;

        arr[father].subRow[son] = o;
        setData(arr);
    }

    const changeAllOrder = (father, value) => {
        var arr = [...data];

        var order = arr[father].subRow;
        for( let i=0; i<order.length; i++ ){
            if( checked[father].indexOf( i ) !== -1 ){
                if( !order[i].old ) order[i].old = order[i].last_delivery_upd;
                order[i].last_delivery_upd = value;
            }
        }

        setData(arr);
    }

    const validateDates = () => {
        setError();
        setSending(true);
        

        orderInfo.validateReminderDates( getCheckedRows() ).then(
            (response) => {
                setSending(false);
                scrollToTop();
                setMsg("Sauvegardé !")
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setSending(false);
            }
        );
    }

    const getCheckedRows = () => {
        var arr = [];

        for (let c in checked){
            var detail = data[c].subRow;
            var toDo = checked[c];
            
            for( let i=0; i<detail.length; i++ )
                if( toDo.includes( i ) ) arr.push( detail[i] );
        }

        return arr;
    }

    const renderSubComponent = (row) => {
        return <table className="table">
            <thead>
                <tr className="table-secondary text-center fw-bold">
                    <td>Commande</td>
                    <td>Affaire</td>
                    <td>Machine</td>
                    <td>Fournisseur</td>
                    <td>Acheteur</td>
                    <td>Article</td>
                    <td>Référence</td>
                    <td>Désignation</td>
                    <td>Dernière date de livraison maj</td>
                    <td>Alerte</td>
                    <td>Commentaire</td>
                    <td>Date de création de la ligne</td>
                    <td>Date de livraison demandée</td>
                    <td>Historique</td>
                </tr>
            </thead>
            <tbody>
                {row && row.original.subRow.map((v, k) => {
                    return <tr key={"subRow" + k}>
                        <td>
                            <input className={"form-check-input me-2"} type="checkbox"
                                onChange={(e) => onCheckRow(e, row.id, k)}
                                data-key={row.id} checked={isChecked(parseInt(row.id), k)} />
                            {v.order_n}</td>

                        <td>{v.business}</td>
                        <td>{v.machine}</td>
                        <td>{v.supplier}</td>
                        <td>{v.purchaser}</td>
                        <td>{v.item}</td>
                        <td>{v.ref}</td>
                        <td>{v.label}</td>
                        <td>{v.last_delivery_upd}</td>
                        <td>{v.type}</td>
                        <td>
                            <textarea className="form-control" defaultValue={v.coms}
                                onBlur={(e)=>updateCom(e, row.id, k)}/>
                        </td>
                        <td>{v.creation}</td>
                        <td>{v.initial_del_req}</td>
                        <td dangerouslySetInnerHTML={{ __html: v.log }}></td>
                    </tr>
                })}
            </tbody>
        </table>
    }

    function isChecked(father, son) {
        return father in checked && checked[father].includes(son);
    }

    return <Fragment>
        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        <ul className="nav nav-tabs">
            <li className="nav-item">
                <a className={'nav-link ' + (tab === 0 ? "active" : "")}
                    aria-current="page" href="#" onClick={() => setTab(0)}>
                    {!data && <ButtonWaiting />}Relances</a>
            </li>

            <li className="nav-item">
                <a className={'nav-link ' + (tab === 1 ? "active" : "")}
                    href="#" onClick={() => setTab(1)}>
                    {!dynamicStck && <ButtonWaiting />}Stock dynamique</a>
            </li>

            <li className="nav-item">
                <a className={'nav-link ' + (tab === 2 ? "active" : "")}
                    href="#" onClick={() => setTab(2)}>Explications</a>
            </li>
            {Object.keys(checked).length > 0 &&
                <Fragment>
                    <li className="nav-item">
                        <a className={'nav-link bg-success text-white ' + (tab === 4 ? "active" : "")}
                            href="#" onClick={() => setTab(4)}>Confirmer les dates</a>
                    </li>

                    <li className="nav-item">
                        <a className={'nav-link bg-warning text-black ' + (tab === 3 ? "active" : "")}
                            href="#" onClick={() => setTab(3)}>Relancer</a>
                    </li>
                </Fragment>
            }
        </ul>

        {/** TABLE */}
        {(tab === 0 && data) &&
            <ReactTable columns={columns} data={data} canExpand={true} renderSubComponent={renderSubComponent}
                origin="orders_tracking" classes={"no-padding fixFstCol "} top0={true} />
        }

        {(tab === 1 && dynamicStck) &&
            <ReactTable columns={columns2} data={dynamicStck}
                origin="dynamic_stck_tracking" classes={"no-padding fixFstCol"} top0={true} />
        }

        {tab === 2 &&
            <div className="ps-3">
                <h5 className="text-center">Explication des colonnes</h5>
                <h6>Alertes</h6>
                <p className="fst-italic">Concerne uniquement les article non reçus sur Focus et ayant un code article commencant par AR_</p>

                <ul>

                    <li>Non accusée réception
                        <ul>
                            <li>Date confirmée non saisie sur Focus</li>
                            <li>Commande créée il y a plus 4 jours</li>
                        </ul>
                    </li>

                    <li>Livraison non confirmée:
                        <ul>
                            <li>Date de livraison confirmée
                                (ou date de besoin si cette dernière n'est pas renseignée)
                                est prévue pour les 15 prochains jours</li>
                            <li>La livraison n'a pas été confirmée dans l'onglet perso de Focus ou via cet outil</li>
                        </ul>
                    </li>

                    <li>Retard de livraison:
                        <ul>
                            <li>Date de livraison confirmée
                                (ou date de besoin si cette dernière n'est pas renseignée)
                                est passée</li>
                            <li>L'article n'a pas été soldé en livraison sur Focus</li>
                        </ul>
                    </li>
                </ul>

                <h6>Historique</h6>
                <p>Il s'agit de toutes les actions effectuées via cet outil. Si vous relancez, il sera noté ici.</p>

                <h6>Relancer</h6>
                <p>Vous pouvez relancer soit:</p>
                <ul>
                    <li>L'ensemble des lignes d'une commande du tableau en cochant la case à cocher dans la colonne "Commande"
                        (vous pourrez toujours décocher une ligne de cette commande pour l'exclure)
                    </li>
                    <li>
                        Une ligne en cochant la case à cocher sur la colonne "Alerte"
                    </li>
                    <li>
                        Un onglet apparaîtra avec le récapitulatif des commandes / lignes à relancer par email
                    </li>
                    <li>
                        Vous pouvez retirer une ligne que vous avez coché à relancer en cliquant sur la croix à côté du code article
                    </li>
                </ul>

                <h6>Stock dynamique</h6>
                <p>Permet de voir quelle commande nous devrions avancer étant donné que le stock
                    sera en rupture avant la date de livraison et que des besoins seront présents.</p>
            </div>
        }

        {tab === 3 && <div>
            <h5 className="text-center">Récapitulatif des fournisseurs à relancer</h5>

            {Object.entries(checked).map(([k, value], idx) => {
                return <Fragment key={"recap" + k + idx}>
                    <Fragment >
                        <h5 className="d-flex mt-2">{data[k].supplier}: {data[k].mail}
                            <img className="ms-3 cursor-pointer" style={{ width: "20px" }} src="/common/edit.png"
                                title="Modifier l'adresse" onClick={() => setEmailChange({ supplier: data[k].supplier })} />
                        </h5>

                        <h6 className="ms-2">{data[k].order_n}</h6>
                    </Fragment>

                    {value.map((v2, k2) => {
                        return <div className="ms-3 d-flex">
                            <button type="button" className="btn-close ms-3" title="Retirer cet article"
                                onClick={() => removeArtFromRecap(k, k2)}></button>
                            {data[k].subRow[v2].item} ({data[k].subRow[v2].label}): {data[k].subRow[v2].type}
                        </div>

                    })}

                </Fragment>
            })}

            <div className="text-center">
                <button className="text-center btn btn-success" onClick={send} disabled={sending}>
                    {sending && <ButtonWaiting />}Envoyer les e-mails</button>
            </div>

            {emailChange && <div className="custom-tooltip-v2">
                <div className="modal-body blue-purple-bg">
                    <button type="button" className="btn-close ms-auto" onClick={() => setEmailChange()}></button>
                    <h5 className="text-center">Modifier l'adresse mail du fournisseur {emailChange.supplier}</h5>

                    {error && <ErrorMessage error={error} errorState={setError} />}

                    <form className="col-10 offset-1" onSubmit={onEmailChange}>
                        <input className="form-control text-center fw-bold mb-3" name="address"
                            placeholder="Nouvelle adresse" required />

                        <div className="form-check mb-3">
                            <input className="form-check-input" type="checkbox" name="focus_save" />
                            <label className="form-check-label">Sauvegarder la modification dans Focus (Fiche tiers - Renseignements administratifs)</label>
                        </div>

                        <div className="text-center">
                            <button className="btn btn-success">
                                Modifier</button>
                        </div>
                    </form>
                </div>
            </div>}

        </div>}

        {tab === 4 && <div>
            <h5 className="text-center">Récapitulatif des dates à valider</h5>

            <div className="overflow-x-auto mb-2" id="recap_order_valid"
                style={{ height: getHeight("recap_order_valid", 50) }}>
                {Object.entries(checked).map(([k, value], idx) => {
                    return <Fragment key={"recap" + k}>

                        <Fragment >
                            <h5 className="d-flex mt-2">{data[k].supplier}: {data[k].mail}</h5>
                            <h6 className="ms-2">{data[k].order_n}</h6>
                        </Fragment>

                        {value.map((v2, k2) => {
                            return <div className="ms-3 d-flex">
                                <button type="button" className="btn-close ms-3" title="Retirer cet article"
                                    onClick={() => removeArtFromRecap(k, k2)}></button>
                                <span style={{ width: "500px" }}>{data[k].subRow[v2].item} ({data[k].subRow[v2].label}): </span>

                                <div className="input-group mb-3" style={{ width: "400px" }}>
                                    <span className="input-group-text">Délai actualisé</span>
                                    <input className="form-control" type="date" value={data[k].subRow[v2].last_delivery_upd}
                                        onChange={(e) => onDateChange(k, k2, e.target.value)}></input>
                                </div>

                                <span className="ms-3 cursor-pointer toDisplay"
                                    onClick={(e) => changeAllOrder(k, data[k].subRow[v2].last_delivery_upd)}>Appliquer cette date sur toute la commande</span>
                            </div>
                        })}

                    </Fragment>
                })}
            </div>

            <div className="text-center">
                <button className="text-center btn btn-success" onClick={validateDates} disabled={sending}>
                    {sending && <ButtonWaiting />}Valider les dates</button>
            </div>

        </div>

        }
    </Fragment>

}

export default OrderTracking;