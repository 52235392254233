/**
 * send msg param msgState={setMsg} send msgState
 * @param {msg} props 
 */
const SuccessMessage = (props) => {
    
      const close = () => {
            props.msgState();
      }

      return <div className="alert alert-success text-center fw-bold" role="alert">
                  <span dangerouslySetInnerHTML={{ __html: props.msg }}></span>
                  {
                        props.msgState &&
                              <button type="button" className="btn-close" aria-label="Close" onClick={close}
                                    style={{ position: "absolute", right: "5px", top: "5px" }}></button>
                  }
            </div> 
    

}

export default SuccessMessage;