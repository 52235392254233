import { Fragment, useEffect, useState } from "react";
import { ButtonWaiting, scrollToTop } from "../../../functions/PageAnimation";
import ArticleInfo from "../../../services/articles/ArticleInfo";
import errorManagement from "../../../services/errorManagement";
import ErrorMessage from "../../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../../common/smallComponents.js/SuccessMessage";
import { currentFrDate } from "../../../functions/StrFunctions";

const OldIndex = (p) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const user = JSON.parse(localStorage.getItem("user"));


    const updateField = (e, k, field) => {
        var arr = [...p.oldRef];

        if (arr[k][field] === undefined) {
            arr[k].mode = "create"
        } else {
            if (!arr[k].mode) arr[k].mode = "update";
        }

        if( field === "com_ipt_user" ){
            arr[k].com_ipt = getOldChangeCom(arr[k]) + e.target.value;
        }else{
            arr[k][field] = e.target.value;
            arr[k].com_ipt = getOldChangeCom(arr[k]);
        }
        

        p.setOldRef(arr);
    }

    const getOldChangeCom = (o) => {
        var com_ipt = "Mise à jour du " + currentFrDate()
            + " par " + user.first_name + " " + user.last_name + ":\n"
            + "La ref " + p.articleInfo.ZN02PA + ":\n";

        var val = o.replace;
        com_ipt += "- " + (val === "Oui" ? "peut remplacer, en lieu et place, cet article"
            : val === "y_part" ? "peut remplacer, mais partiellement, cet article"
                : val === "Non" ? "ne peut pas remplacer cet article" : "") + "\n\n";

        val = o.blocking;
        com_ipt += "- " + (val === "rep" ? "doit être bloquée en réapprovisionnement"
            : val === "tot" ? "doit être bloquée totalement"
                : val === "no" ? "ne peut pas remplacer cet article" : "") + "\n\n";

        val = o.action;
        com_ipt += "- " + (val === "bom" ? "peut être utilisée en nomenclature"
            : val === "sav" ? "peut être utilisée au SAV"
                : val === "retrofit" ? "peut être utilisée en retrofit"
                    : val === "trash" ? "doit être jetée" : "") + "\n----------\n";

        return com_ipt;
    }

    /** Separate user comments and system comments */
    function getUserComment( val ){
        if( !val ) return "";

        var idx = val.indexOf("----------");
        return idx !== -1 ? val.substring( idx+11, val.length ) : "";
    }

    const addArticle = (e) => {
        e.preventDefault();
        var ar = e.target.ar.value;

        setLoading(true);
        ArticleInfo.articlesInfo(ar).then(
            (response) => {
                if (response.length === 0) {
                    setError("Article inconnu");
                    scrollToTop();
                } else {
                    var arr = [...p.oldRef];
                    var o = { ...response[0] };
                    o.mode = "create";
                    arr.push(o);
                    p.setOldRef(arr);
                }
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(p, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }

    return <Fragment>
        {error && <ErrorMessage error={error} />}
        {msg && <SuccessMessage msg={msg} />}

        <table className="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Reférence</th>
                    <th scope="col">Libellé</th>
                    <th scope="col">Qté</th>
                    <th scope="col">Blocage</th>

                    <th scope="col">Peut-être remplacé ?</th>
                    <th scope="col">Blocage à appliquer</th>
                    <th scope="col">Gestion du stock</th>
                    <th scope="col">Observation libre</th>
                </tr>
            </thead>
            <tbody>
                {
                    p.oldRef.map((v, k) => {
                        return <tr key={"old" + k}>
                            <th scope="row">
                                <a href={"/article/info/get?article=" + v.CDARL.trim()} target="_blank">{v.CDARL}</a>
                            </th>
                            <td>{v.ZN02PA}</td>
                            <td>{v.ZN03PA}</td>
                            <td>{parseInt(v.QTSTL) - parseInt(v.QTREL) + parseInt(v.QTCDL)}</td>
                            <td>{v.BLOCL === "A" ? "En appro" : v.BLOCL === "C" ? "Complet" : "Aucun"}</td>
                            <td>
                                <select className="form-control"
                                    onChange={(e) => updateField(e, k, "replace")} value={v.replace}>
                                    <option selected="">Choose...</option>
                                    <option value="Oui">Oui, en lieu et place</option>
                                    <option value="y_part">Oui, mais partiellement</option>
                                    <option value="Non">Non</option>
                                </select>
                            </td>
                            <td>
                                <select className="form-control"
                                    onChange={(e) => updateField(e, k, "blocking")} value={v.blocking}>
                                    <option selected="">Choose...</option>
                                    <option value="rep">En réapprovisionnement</option>
                                    <option value="tot">Total</option>
                                    <option value="no">Ne pas bloquer</option>
                                </select>
                            </td>
                            <td>
                                <select className="form-control"
                                    onChange={(e) => updateField(e, k, "action")} value={v.action}>
                                    <option selected="">Choose...</option>
                                    <option value="bom">Sortir en nomenclature</option>
                                    <option value="sav">SAV (stock de sécu)</option>
                                    <option value="retrofit">À modifier (retrofit)</option>
                                    <option value="trash">À jeter</option>
                                </select>
                            </td>
                            <td>
                                <textarea className="form-control" value={getUserComment(v.com_ipt)}
                                    onChange={(e) => updateField(e, k, "com_ipt_user")}></textarea>
                                {/*<textarea className="form-control" value={v.com_ipt} 
                                    style={{ height: ( v.com_ipt ? "150px" : "0px") }} readOnly></textarea>*/}
                            </td>
                        </tr>
                    })
                }
            </tbody>
        </table>

        <form onSubmit={addArticle} className="col-3">
            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">Ajouter un article</span>
                <input type="text" className="form-control" name="ar" />
                <button className="btn btn-outline-secondary" disabled={loading}>
                    {loading && <ButtonWaiting />}
                    Rechercher
                </button>
            </div>
        </form>

    </Fragment>
}

export default OldIndex;
