import { useState } from "react";
import { useEffect } from "react";
import CheckConnDao from "../../services/common/CheckConnDao";
import errorManagement from "../../services/errorManagement";
import { Fragment } from "react";
import usersDao from "../../services/settings/usersDao";
import { ButtonWaiting } from "../../functions/PageAnimation";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";

const MissingTrigram = (props) => {

    const user = JSON.parse(localStorage.getItem("user"));
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();


    useEffect(() => {
        if (user.trigram !== null) 
            window.location.href = "/fah/" + ( user.installer !== "O" ? "non-" : "" ) + "installer";
        
        setInterval(() => {
            document.getElementById("trigramIpt").focus();
        }, 500);
    }, []);

    /**
     * Save trigram
     */
    const saveTrigram = (e) => {
        e.preventDefault();
        setSaving(true);
        usersDao.getUserInfoByTrigram(e.target.trigram.value.toUpperCase(), user.id).then(
            (response) => {

                user.focus_id = response.PMATRI;
                user.trigram = response.ZN01PP;
                user.installer = response.ZN06PP === "O" ? 1 : 0;
                user.executive = response.ZN05PP === "O" ? 1 : 0;
                localStorage.setItem("user", JSON.stringify(user));

                window.location.href = "/fah/" + ( response.ZN06PP !== "O" ? "non-" : "" ) + "installer";
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setSaving(false);
            }
        );
    }

    return <Fragment>
        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        <div className="custom-tooltip">
            <h4 className="fw-bold text-center mb-3">Nous ne connaissons pas votre trigramme sur ce nouvel intranet.
                Merci de l'indiquer ci-dessous</h4>

            <form className="col-8 offset-2" onSubmit={saveTrigram}>
                <div className="input-group mb-3">
                    <span className="input-group-text fw-bold">Votre trigram</span>
                    <input type="text" className="form-control text-center" id="trigramIpt"
                        name="trigram" autoComplete="off" required />
                </div>

                <div className="text-center">
                    <button className="btn btn-success" disabled={saving}>
                        {saving && <ButtonWaiting />}
                        Soumettre
                    </button>
                </div>
            </form>
        </div>


    </Fragment>

}

export default MissingTrigram;