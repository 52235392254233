import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL + "common/share-file";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && "\"Bearer " + user.accessToken + "\"";

const canUpload = () => {
    return axios
        .post(API_URL + "/canUpload", {
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const uploadFiles = (recipients, files, bodyMail) => {
    let formData = new FormData();

    for (let i = 0; i < recipients.length; i++) formData.append("recipients", recipients[i]);
    formData.append("bodyMail", bodyMail);
    formData.append("files", files);

    formData.append("fake_header", token);

    return axios({
        method: "post",
        url: API_URL + "/upload",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    })
        .then((response) => {
            return response.data;
        });
}

const uploadFilesV2 = (recipients, files, bodyMail) => {
    let formData = new FormData();

    for (let i = 0; i < recipients.length; i++) formData.append("recipients", recipients[i]);
    formData.append("bodyMail", bodyMail);
    formData.append("files", files);

    formData.append("fake_header", token);

    return axios({
        method: "post",
        url: API_URL + "/upload/v2",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    })
        .then((response) => {
            return response.data;
        });
}

const getShare = (id) => {
    return axios
        .post(API_URL + "/public/get-files-to-download", {
            id: id
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getShareV2 = (id) => {
    return axios
        .post(API_URL + "/public/get-files-to-download/v2", {
            id: id
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const uploadAnyFiles = (url, objects, files) => {
    let formData = new FormData();

    for (const property in objects) formData.append(property, objects[property]);
    for (let i = 0; i < files.length; i++) formData.append("files", files[i]);

    formData.append("fake_header", token);

    return axios({
        method: "post",
        url: process.env.REACT_APP_API_URL + url,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => {
        return response.data;
    });

}

const uploadAnyWhere = (folder, files) => {
    let formData = new FormData();

    for (let i = 0; i < files.length; i++) formData.append("files", files[i]);

    formData.append("folder", folder);
    formData.append("fake_header", token);

    return axios({
        method: "post",
        url: API_URL + "/upload/internal",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => {
        return response.data;
    });

}

const uploadTemp = (files) => {
    let formData = new FormData();

    for (let i = 0; i < files.length; i++) formData.append("files", files[i]);

    formData.append("fake_header", token);

    return axios({
        method: "post",
        url: API_URL + "/upload/temp",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => {
        return response.data;
    });

}

const uploadByType = (type, label, file) => {
    let formData = new FormData();

    formData.append("type", type);
    formData.append("label", label);
    formData.append("fake_header", token);
    formData.append("file", file);

    return axios({
        method: "post",
        url: API_URL + "/by-type/upload",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => {
        return response.data;
    });

}

const getDocsByType = (type) => {
    return axios
        .post(API_URL + "/by-type/get", {
            type: type,
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const deleteDocsByType = (id, url) => {
    return axios
        .post(API_URL + "/by-type/delete", {
            id: id,
            url: url,
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getUrlToDisplayDocByType = ( id ) => {
    var token = user && "Bearer " + user.accessToken + "";
    return API_URL + "/by-type/display?Authorization=" + token + "&id=" + id;
}


export default {
    canUpload,
    uploadFiles,
    getShare,
    uploadAnyFiles,
    uploadAnyWhere,
    uploadFilesV2,
    getShareV2,
    uploadByType,
    getDocsByType,
    deleteDocsByType,
    getUrlToDisplayDocByType,
    uploadTemp
}