import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Reports = (props) => {
    const { t } = useTranslation();
    const user = JSON.parse(localStorage.getItem("user"));
    const API_URL = process.env.REACT_APP_API_URL;

    const { user: currentUser } = useSelector((state) => state.auth);
    const token = user && "Bearer " + user.accessToken;

    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {t('reports')}
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                {
                    ( props.roles && ( props.roles.includes("ROLE_WIP") || props.roles.includes("ROLE_ADMIN") ) ) &&
                        <Fragment>
                            <li>
                                <a target="_blank" href={API_URL +"machines/reports/download-wip?Authorization=" + token} className="dropdown-item">
                                    WIP
                                </a>
                            </li>
                        </Fragment>
                }
            </ul>
        </li>
    )

}

export default Reports;