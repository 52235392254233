import { Fragment, useEffect, useState } from "react";
import { getCurrentDateTime } from "../../../../functions/StrFunctions";
import ProdTrackingDao from "../../../../services/machines/ProdTrackingDao";
import errorManagement from "../../../../services/errorManagement";
import { confirmAlert } from "react-confirm-alert";

const ActivityMngt = (p) => {
    const [isBlocked, setIsBlocked] = useState(false);
    const [statusByUser, setStatusByUser] = useState([]);
    const [canContinue, setCanContinue] = useState();

    useEffect(() => {
        setCanContinue(getIfCanContinue());
    }, [p.status])

    function getIfCanContinue() {
        //This machine never started
        if (!p.status || p.status.length === 0) return true;

        var ref = p.activity.ref.trim();

        //Get last status by user for this task
        var arr = getStatusByUser(ref);
        setStatusByUser(arr);

        //This task has never started
        if (arr.length === 0) return true;

        var id = p.user.id;
        var workstation = [p.user.workstation.trim()];
        if (p.user.workstation.trim() === "Intégration") workstation.push("Montage");

        for (let i = 0; i < arr.length; i++) {
            if (id === arr[i].user_id && workstation.includes(arr[i].workstation.trim())
                && ref === arr[i].subassembly.trim()) return true;
        }

        return false;
    }

    function getStatusByUser(ref) {
        var machineData = [];

        //Get status of this task
        for (let i = 0; i < p.status.length; i++)
            if (ref === p.status[i].subassembly.trim()) machineData.push(p.status[i]);

        if (machineData.length === 0) return machineData;

        // Group by user to obtain the latest status for each person
        const userStatusMap = {};

        machineData.forEach(task => {
            // If the user is already registered, we compare the dates
            if (!userStatusMap[task.user_id] || new Date(task.date) > new Date(userStatusMap[task.user_id].date))
                userStatusMap[task.user_id] = task;
        });

        return machineData;
    }


    const createTask = ( comments = "" ) => {
        p.setError();

        //The param can be an event...
        if( comments.type ) comments = "";

        var o = { ...p.activity };
        o.status = "Démarré";
        o.date = getCurrentDateTime("en");
        o.user_id = p.user.id;
        o.blocking_cause = comments;

        saveTask(o);
    }

    const saveTask = (o, changeState = true) => {
        p.setLoading(true);

        ProdTrackingDao.createTaskV2(o).then(
            (response) => {
                if( changeState ){
                    p.setLoading(false);
                    p.setStatus(response);
                    p.setActivity(o);
                }                
            },
            (error) => {
                p.setError(errorManagement.handleError(p.props, error));
                p.setLoading(false);
            }
        );

    }

    const endTask = () => {
        p.setError();

        confirmAlert({
            title: "Fin de tâche",
            message: "Confirmez-vous avoir complété votre tâche ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        var o = { ...p.activity };
                        o.status = "Terminé";
                        o.blocking_cause = "";
                        o.date = getCurrentDateTime("en");
                        o.user_id = p.user.id;

                        saveTask(o);
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const switchPerson = (e) => {
        p.setError();

        var k = e.target.getAttribute("data-key");
        var previousUser = p.activity.statusByUser[k];

        confirmAlert({
            title: "Fin de tâche",
            message: "Vous prenez la place de " + previousUser.last_name + " " + previousUser.first_name + " sur cette tâche ?",
            buttons: [{ label: "Oui", onClick: () => { doSwitch(previousUser) }},{ label: "Non"}]
        });
    }

    const doSwitch = (previousUser) => {
        p.setError();

        var msg = p.user.last_name + " " + p.user.first_name + " remplace " + previousUser.last_name + " " + previousUser.first_name;
        
        //Set chgt pers for the previous...
        var o = { ...p.activity };
        o.status = "Chgt. pers.";
        o.blocking_cause = msg;
        o.date = getCurrentDateTime("en");
        o.user_id = previousUser.user_id;

        saveTask(o, false);

        //...then new task for the second
        createTask(msg);

    }

    const setBlocking = (e) => {
        e.preventDefault();

        p.setError();

        var o = { ...p.activity };
        o.status = "Bloqué";
        o.blocking_cause = e.target.blocking_cause.value;
        o.date = getCurrentDateTime("en");
        o.user_id = p.user.id;

        setIsBlocked(false);
        saveTask(o);
    }

    const setRestarted = () => {
        p.setError();

        var o = { ...p.activity };
        o.status = "Redémarré";
        o.blocking_cause = "";
        o.date = getCurrentDateTime("en");
        o.user_id = p.user.id;

        saveTask(o);
    }

    return <div>
        <h5 className="text-center mb-3">{p.activity.station} {p.activity.french_label}</h5>

        {/** I have already start this task */}
        {canContinue && <Fragment>

            {(p.activity.status === "En attente" && !p.loading) &&
                <div className="text-center mt-3" onClick={createTask}>
                    <button className="pushable green">
                        <span className="front green">Démarrer tâche</span>
                    </button>
                </div>}

            {(p.activity.status !== "En attente" && !p.loading) && <Fragment>

                {((p.activity.status === "Démarré" || p.activity.status === "Redémarré" )) &&
                    <div className="d-flex d-wrap">

                        <div className="text-center col-6">
                            <button className="pushable green mb-5" onClick={endTask}>
                                <span className="front green">Tâche terminée</span>
                            </button>
                        </div>

                        <div className="text-center col-6">
                            <button className="pushable red" onClick={() => setIsBlocked(true)}>
                                <span className="front red">Tâche bloquée</span>
                            </button>
                        </div>

                    </div>
                }

                {p.activity.status === "Bloqué" &&
                    <div className="text-center mt-3">
                        <button className="pushable yellow">
                            <span className="front yellow" onClick={setRestarted}>Reprendre tâche</span>
                        </button>
                    </div>
                }

                {isBlocked && <form onSubmit={setBlocking}>
                    <h4 className="text-center">Saisissez la raison du blocage</h4>

                    <select className="form-select ms-auto me-auto mb-3" name="blocking_cause"
                        style={{ maxWidth: "300px" }} required>
                        <option value="">Selectionnez</option>
                        <option value="Erreur BE">Erreur BE</option>
                        <option value="Pièce NC">Pièce NC</option>
                        <option value="Pièces manquantes">Pièces manquantes</option>
                        <option value="Recherche de matériel">Recherche de matériel</option>
                        <option value="Erreur de stock">Erreur de stock</option>
                        <option value="Pas d'emplacement pour machine">Pas d'emplacement pour machine</option>
                        <option value="Assistance sur une autre activité">Assistance sur une autre activité</option>
                    </select>

                    <div className="text-center">
                        <button className="btn btn-success">Valider</button>
                    </div>
                </form>}

                { (p.activity.status === "Terminé" || p.activity.status === "Chgt. pers." ) &&
                    <div className="text-center mt-3">
                        <button className="pushable yellow">
                            <span className="front yellow" onClick={setRestarted}>Redémarrer tâche</span>
                        </button>
                    </div>
                }

            </Fragment>}
        </Fragment>}

        {/** I never did this task but someone done */}
        {!canContinue && <Fragment>
            {(p.activity.status === "En attente" && !p.loading) &&
                <Fragment>
                    {p.activity.statusByUser && p.activity.statusByUser.map((v, k) => {
                        return ( v.status !== "Terminé" && v.status !== "Chgt. pers.") &&
                            <div className="text-center mt-3" key={"replace"+k}>
                                <button className="pushable orange">
                                    <span className="front orange" data-key={k}
                                        onClick={switchPerson}>Remplacer {v.first_name} {v.last_name}</span>
                                </button>
                            </div>
                    })}

                    <div className="text-center mt-3">
                        <button className="pushable blue">
                            <span className="front blue" onClick={createTask}>Renfort de personnel</span>
                        </button>
                    </div>
                </Fragment>
            }
        </Fragment>}



    </div>

}

export default ActivityMngt;