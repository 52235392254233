import { useEffect, useRef, useState } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import CheckList from "../../services/customClient/CheckList";
import errorManagement from "../../services/errorManagement";
import i18next from "i18next";
import getUrlParam, { extractValueFromTag } from "../../functions/StrFunctions";
import { scrollToTop } from "../../functions/PageAnimation";
import NC from "./common/NC";

const MaintenanceRecapPublic = (props) => {
    const { t } = useTranslation(['check-list', 'common']);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [msg, setMsg] = useState();
    const [nc, setNc] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const [sigSeeImg, setsigSeeImg] = useState();
    const [sigClientImg, setsigClientImg] = useState();


    useEffect(() => {
        CheckList.getCheckListFormById(getUrlParam(props, "id"), i18next.language).then(
            (response) => {
                var mach = "";
                var compil = new Array();
                var list = new Array();
                var o = new Object();

                response.map((v, k) => {
                    if (k === 0) {
                        o.machine = v.mach_nb;
                        mach = v.mach_nb;
                        setsigSeeImg( extractValueFromTag(v.signature, "see") );
                        setsigClientImg( extractValueFromTag(v.signature, "client") );
                    } else if (k > 0 && mach !== v.mach_nb) {
                        o.list = list;
                        compil.push(o);

                        o = new Object();
                        o.machine = v.mach_nb;
                        mach = v.mach_nb;
                        list = new Array();
                    }

                    list.push(v);
                })

                o.list = list;
                compil.push(o);

                getNonConformities(compil);

                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        );
    }, []);


    function getNonConformities(compil) {
        var machines = new Array();
        var curChapter = -1;

        compil.map(v => {
            //Set the machine
            var machine = { machine: v.machine }

            var chapters = new Array();
            var nc;
            var chap;
            //For each form question
            v.list.map(v2 => {
                if (parseInt(v2.chapter) !== parseInt(curChapter)) {
                    //If a new chapter save the previous (except if we are starting)
                    if (curChapter !== -1) {
                        chap.nc = nc;
                        chapters.push(chap);
                    }

                    //Start a new chapter
                    chap = new Object();
                    nc = new Array();
                    chap.chapter = v2.chapter;
                    chap.label = v2.chapter_label;
                    chap.notes = extractValueFromTag(v2.value1, "notes");
                    chap.changed = extractValueFromTag(v2.value1, "changed");
                    chap.toChange = extractValueFromTag(v2.value1, "toChange");
                    curChapter = parseInt(v2.chapter);
                }

                //Save non conformity
                if (v2.conform === 0) nc.push(v2);

            })


            curChapter = -1;
            chap.nc = nc;
            chapters.push(chap);

            //Set array of chapters in machine
            machine.chapters = chapters;
            machines.push(machine);
        });

        setNc(machines);
    }


    return <Fragment>
        <h4 className="text-center">{t('overview', { ns: 'check-list' })}</h4>

        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}
        {loading && <WaitingRoundSnippers />}

        {
            nc && <Fragment>
                <NC nc={nc}/>

                <br></br>
                <table className="table text-center">
                    <thead>
                        <tr >
                            <th scope="col"></th>
                            <th scope="col">Sealed Air</th>
                            <th scope="col">Client</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">Signature</th>
                            <td>
                                <img src={sigSeeImg}></img>
                            </td>
                            <td>
                                <img src={sigClientImg}></img>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </Fragment>
        }
    </Fragment>

}

export default MaintenanceRecapPublic;