import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const Subcontracting = (props) => {
    const { t } = useTranslation("article");

    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Articles
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                    <a href="/article/info/search" className="dropdown-item">
                        {t('art-search')}
                    </a>
                </li>

                {
                    (props.roles && (props.roles.includes("ROLE_CREATE_ART") || props.roles.includes("ROLE_ADMIN"))) &&
                    <li>
                        <a href={"/article/create"} className="dropdown-item">
                            Demande de création d'article
                        </a>
                        <a href="/article/create/list" className="dropdown-item">
                            Liste demande de création d'AR
                        </a>
                    </li>
                }

                <li><hr className="dropdown-divider" /></li>

                {(props.isInternal) &&
                    <li>
                        <span className="dropdown-item">Visualiser commande achats</span>
                        <form action={"/purchase/order/edition"}>
                            <div className="form-floating mb-3 col-10 offset-1" style={{ display: "flex" }}>
                                <input type="text" className="form-control" id="orderView" name="order" placeholder="N° de commande" required />
                                <label htmlFor="orderView">N° de commande</label>
                                <button className="btn btn-success">ok</button>
                            </div>
                        </form>
                    </li>
                }

                <li><hr className="dropdown-divider" /></li>

                {
                    (props.roles && (props.roles.includes("ROLE_ART_REQUEST") || props.roles.includes("ROLE_ADMIN"))) &&
                    <li>
                        <a href={"/storehouse/articles/request"} className="dropdown-item">
                            Demande de sortie comptoir
                        </a>
                    </li>

                }

                {
                    (props.showThrow) && (
                        <li>
                            <a href={"/article/disposables/list"} className="dropdown-item">
                                Jets d'articles
                            </a>
                        </li>
                    )
                }

                {(props.isInternal) && (
                    <li>
                        <a href={"/article/info/stock/dynamic"} className="dropdown-item">
                            Stock dynamique d'article
                        </a>
                    </li>
                )
                }

                {
                    (props.roles && (props.roles.includes("ROLE_HST") || props.roles.includes("ROLE_ADMIN"))) &&
                    <Fragment>
                        <li><hr className="dropdown-divider" /></li>
                        <li>
                            <a href={"/customer-client/hts/form"} className="dropdown-item">
                                HTS code form
                            </a>
                        </li>

                        <li>
                            <a href={"/customer-client/hts/list"} className="dropdown-item">
                                HTS code requests
                            </a>
                        </li>
                    </Fragment>
                }

                {
                    (props.roles && (props.roles.includes("ROLE_PURCHASE") || props.roles.includes("ROLE_ADMIN"))) &&
                    <Fragment>
                        <li><hr className="dropdown-divider" /></li>
                        <li>
                            <a href={"/purchase/screws/order"} className="dropdown-item">
                                Achat de visserie
                            </a>
                        </li>
                    </Fragment>
                }
            </ul>
        </li>
    )

}

export default Subcontracting;