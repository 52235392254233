import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL +"autocomplete/users";

const getEmails = () => {
    return axios
    .post(API_URL + "/get-emails",
    {fake_header: authHeader()},
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

export default {
    getEmails
};