import Moment from 'moment';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SideNavBarV3, isSideBarOpened } from '../../common/smallComponents.js/DesignedIpt';
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { getDeviceType } from "../../functions/PageAnimation";
import getUrlParam, { forceString } from "../../functions/StrFunctions";
import errorManagement from "../../services/errorManagement";
import subcontracting from "../../services/supplyChain/subcontracting";
import PackingListMenu from "./functions/PackingListMenu";

const PackingList = (props) => {
    const [pin, setPin] = useState(isSideBarOpened());
    const { t } = useTranslation('subcontracting');
    const [loading, setLoading] = useState(false);
    const [machine, setMachine] = useState("");
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [chat, setChat] = useState([]);
    const [openChat, setOpenChat] = useState(false);
    const [machineInfo, setMachineInfo] = useState();
    const [updating, setUpdating] = useState(false);
    const [files, setFiles] = useState([]);
    const [labelsToPrint, setLabelsToPrint] = useState([]);

    const user = JSON.parse(localStorage.getItem("user"));

    const isInternal = user && user.roles.includes("ROLE_INTERNAL")

    const [columns, setColumns] = useState(subcontracting.getColumnsV2(t, machineInfo));

    Moment.locale('fr');

    /**
     * Opening
     */
    useEffect(() => {
        var machine = getUrlParam(props, "machine");

        if (machine !== null) { setMachine(machine); getSubcontractingTable(null, machine); }
    }, []);

    /**
     * Update column
     */
    useEffect(() => {
        setColumns(subcontracting.getColumnsV2(t, machineInfo, isInternal,
            { updateData, checkDblClick, labelsToPrint, setLabelsToPrint, cancelQtySent, setExternalComment }));
    }, [data, labelsToPrint]);

    /**
     * Get data
     * @param {*} e 
     * @param {*} machine_param 
     */
    const getSubcontractingTable = (e, machine_param) => {
        if (e !== null) e.preventDefault();

        subcontracting.getSubcontractingTableV2(machine_param || machine, setLoading, setError,
            setData, setFiles, setMachine, setMachineInfo, setChat, props);
    }

    /**
     * Update data change 
     * 
     * @param {*} e 
     * @param {*} fromDblClick 
     * @returns 
     */
    const updateData = (e, fromDblClick) => {
        var elt_id = e.target.getAttribute("id");
        var field = elt_id.substring(0, elt_id.lastIndexOf("_"));
        var k = e.target.getAttribute("data-key");
        var row_id = e.target.getAttribute("data-id");
        var value = e.target.value;

        // If the field is sent2 and come from dbl click then copy sent field value
        if (field === "sent2" && fromDblClick) {
            var sent = data[k].sent;
            if (sent === null) return;
            value = sent;
            document.getElementById(elt_id).value = sent;
        }

        // If the value is not the same of the original value cancel
        if (forceString(data[k][field]).trim() !== forceString(value).trim()) {
            subcontracting.updateField(field, value, row_id).then(
                () => { },
                (error) => { setError(errorManagement.handleError(props, error)); }
            );

            var arr = [...data];
            arr[k][field] = value;
            setData(arr);
        }

    }

    const setExternalComment = (e) => {
        var row_id = e.target.getAttribute("data-id");
        var value = e.target.value;
        subcontracting.setExternalComment(row_id, value);
    }

    /** Cancel sent quantity */
    const cancelQtySent = (k) => {
        var arr = [...data];
        arr[k].sent2 = null;
        arr[k].sent2Date = null;
        setData(arr);

        subcontracting.updateField("sent2", null, arr[k].id).then(() => { }, (error) => { setError(errorManagement.handleError(props, error)); });
        subcontracting.updateField("sent2Date", null, arr[k].id).then(() => { }, (error) => { setError(errorManagement.handleError(props, error)); });
    }

    /**
     * Check double tap on tablet
     */
    var lastClickTime = 0;
    var delay = 300;
    const checkDblClick = (e) => {
        var currentTime = new Date().getTime();
        var timeDiff = currentTime - lastClickTime;
        if (timeDiff < delay) updateData(e, true);
        lastClickTime = currentTime;
    }

    /**
     * Update qty moved
     * @returns 
     */
    const updateMovementQty = () => {
        if (updating) return;
        var arr = [...data];
        setUpdating(true);
        subcontracting.getMovementNb(machine, data, machineInfo).then(
            (response) => {
                setData(response);
                setUpdating(false);
            },
            (error) => { setError(errorManagement.handleError(props, error)); setUpdating(false); }
        );
    }

    /**
     * chat
     */
    const onChatWrite = (e) => {
        e.preventDefault();
        var txt = e.target.chat.value;
        if (txt.trim() === "") return;

        var arr = [...chat];

        var o = {
            user: user.first_name + " " + user.last_name,
            date: new Date(),
            msg: txt
        }

        arr.push(o);
        setChat(arr);
        e.target.chat.value = "";
        var elt = document.getElementById("chat-view");
        setTimeout(() => {
            elt.scrollTop = elt.scrollHeight;
        }, 200);
        saveChat(arr);
    }

    const deleteMessage = (e) => {
        var key = parseInt(e.target.getAttribute("data-key"));
        var arr = [...chat];
        arr = arrayRemoveElt(arr, key);
        setChat(arr);
        saveChat(arr);
    }

    const saveChat = (arr) => {
        subcontracting.saveChat(machine, arr).then((response) => { }, (error) => { });
    }



    return <div className="bg-see custom-bootstrap" style={{ height: "91.5vh", marginTop: "-1.5em" }}>
        <SideNavBarV3 setPin={setPin} content={<div>
            { machineInfo && <PackingListMenu user={user} data={data} machineInfo={machineInfo} 
                setError={setError} props={props} labelsToPrint={labelsToPrint} setData={setData}/>}

        </div>} />

        <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>

            {/*<h3 className="text-center">{t('subcontracting')}</h3>*/}

            {loading && <WaitingRoundSnippers />}
            {error && <ErrorMessage error={error} errorState={setError} />}

            {/** Search form */}
            <form className={getDeviceType() == "desktop" ? "col-4 offset-4" : ""}>
                <div className="display-flex">
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Machine {t('subcontracting')}</span>
                        <input type="text" className="form-control fw-bold text-center" placeholder="Machine" name="machine" />
                        <button className="btn btn-success">{t('search')}</button>
                    </div>

                    <div>
                        {<img onClick={updateMovementQty} title="Mettre à jour les qtés mouvementées"
                            className={"cursor-pointer" + (updating ? " rotating-left" : "")}
                            src="/common/refresh.png" style={{ marginLeft: "10px", width: "40px" }}></img>}
                    </div>
                </div>
            </form>

            {/** Table */}
            <ReactTable columns={columns} data={data} origin="subcontracting"
                top0="true" classes={"no-padding"} />


            {/** Chat button */}
            {data.length > 0 && <img id="logo-chat" src={"/common/chat.png"} onClick={() => setOpenChat(!openChat)}></img>}

            <div id="chat" style={{ display: (openChat ? "" : "none") }}>
                <div id="close-div">
                    <button type="button" className="btn-close" onClick={() => setOpenChat(!openChat)} aria-label="Close"></button>
                </div>

                <div id="chat-view" >
                    {
                        chat.map((v, k) => {
                            return <div className={"bubble " + (v.user === user.first_name + " " + user.last_name ? "me" : "other")} key={"chat_" + k}>
                                <span className="user">{v.user + " " + Moment(v.date).format("DD/MM/yyyy")}:</span>
                                <p className="msg" dangerouslySetInnerHTML={{ __html: v.msg }}></p>
                                {
                                    (v.user === user.first_name + " " + user.last_name) && <div className="delete">
                                        <span data-key={k} onClick={deleteMessage}>Delete</span>
                                    </div>
                                }
                            </div>
                        })
                    }
                </div>

                <br></br>
                <form onSubmit={onChatWrite} id="send-chat">
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" name="chat" placeholder="Type here..." />
                        <button className="btn btn-secondary">Envoyer</button>
                    </div>
                </form>
            </div>
        </div>

    </div>

}

export default PackingList;