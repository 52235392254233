import Moment from "moment";
import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { scrollToTop } from "../../functions/PageAnimation";
import getUrlParam, { getDaysOfWeek } from "../../functions/StrFunctions";
import fahDao from "../../services/common/fahDaoV2";
import errorManagement from "../../services/errorManagement";
import CommonRequests from "./smallComponents/CommonRequests";
import { caculateSchedule } from "./smallComponents/FahHeader";
import { calculateSumarry, checkBeforSubmit, checkConstrainsts } from "./smallComponents/InstallerCalculation";
import InstallerPC from "./smallComponents/InstallerPC";
import PeriodForm from "./smallComponents/PeriodForm";

const Installer = (props) => {
 
    Moment.locale("fr");

    const user = JSON.parse(localStorage.getItem("user"));
    const [userInfo, setUserInfo] = useState({...user});
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [msg, setMsg] = useState();
    const [isManager, setIsManager] = useState(false);
    const [response, setResponse] = useState();
    const [periodFromTrack, setPeriodeFromTrack] = useState();

    const [weekDays, setWeekDays] = useState();

    const [week, setWeek] = useState();
    const [year, setYear] = useState();

    const [hours, setHours] = useState([]);
    const [abs, setAbs] = useState([]);
    const [emptyObj, setEmptyObj] = useState();
    const [soldeC2, setSoldeC2] = useState(0);

    const [err, setErr] = useState( new Object() );

    const [summary, setSumarry] = useState({
        work_time: 0, wrk_or_ass: 0,
        cons_wrk_or_ass: 0, exc_hours: 0, abs_tot_d: 0, abs_tot_h: 0,
        abs_disease: 0, abs_c1: 0, abs_c2: 0, move: 0,
        p100: 0, p125: 0, p150: 0, p50: 0, p_d_100: 0, c1_100: 0, c1_125: 0,
        c1_150: 0, majD: 0, c2_25: 0, c2_100: 0, c2_50: 0,
        tot_p: 0, tot_c1: 0, tot_c2: 0, 
        night_justif: "", over_justif: "", move_justif: "", comment_except_bonus: ""
    });

    useEffect(() => {
        if (user && user.trigram === null) window.location.href = "/fah/missing-trigram";
        //cHECK NEW TRIGRAM EXSISTS

        var weekParam = getUrlParam(props, "week");
        var yearParam = getUrlParam(props, "year");
        var oldBusiness = getUrlParam(props, "oldBusiness") !== null;
        var oldWeek = getUrlParam(props, "oldWeek");
        var oldYear = getUrlParam(props, "oldYear");
        var oldHours = getUrlParam(props, "oldHours") !== null;

        setUserInfo(user);
        //Check if the is week/year in param get infos
        if (weekParam !== null) {
            setWeek(weekParam);
            setYear(yearParam);

            /**
             * Get days of choosen week
             */
            var arr = getDaysOfWeek(weekParam, yearParam);
            setWeekDays(arr);

            var params = {
                week: weekParam,
                year: yearParam,
                oldBusiness: oldBusiness,
                oldWeek: oldWeek,
                oldYear: oldYear,
                oldHours: oldHours,
                start: Moment(arr[0].en_date).format("YYYY-MM-DD"),
                end: Moment(arr[6].en_date).format("YYYY-MM-DD"),
                trigram: user.trigram
            }

            CommonRequests.getInstaller(params, setWeekDays, setAbs, setHours, setEmptyObj, 
                   user, setUserInfo, null, setResponse, setError, props, setSoldeC2);

        }else if( getUrlParam(props, "week_from_track") !== null ){
            setPeriodeFromTrack( { week: getUrlParam(props, "week_from_track"),
                                    year: getUrlParam(props, "year_from_track") })
        }
    }, []);

    /**
     * On abs change calculate the hours
    */
    useEffect(() => {
        var timeout = setTimeout(() => {
            if (weekDays !== undefined) {
                clearTimeout(timeout);
                setWeekDays(caculateSchedule(weekDays, abs));
                calculateSumarry(abs, hours, summary, setSumarry, weekDays);
                checkConstrainsts(err, setErr, weekDays, hours, summary, setError, abs);
            }
        }, 100);
    }, [abs, hours]);

    /**
     * 
     */
    useEffect( () => {
        if( weekDays !== undefined && hours !== undefined ) 
            checkConstrainsts(err, setErr, weekDays, hours, summary, setError, abs);
    }, [summary]);

    /**
     * Save FAH
     */
    const save = (e) => {
        if( error ){
            scrollToTop();
            return;
        }

        const send = e.target.getAttribute("data-send") === "true" ? true : false;
        setSaving(true);

        if( send ){
            var err = checkBeforSubmit(weekDays, hours, summary, abs);
            if( err !== "" ){
                setError(err);
                scrollToTop();
                setSaving(false);
                return;
            }
        }
        
        fahDao.saveInstallerHours(week, year, hours, abs, summary, send, isManager, userInfo).then(
            (response) => {
                scrollToTop();
                setSaving(false);
                setMsg("Sauvegardé !");
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setSaving(false);
            }
        );
    }

    const refresh = () => {
        var url = window.location.href;
        window.location.href = url.substring(0, url.indexOf("?") );
    }

    const recalculateSchedule = () => {
        setWeekDays(caculateSchedule(weekDays, abs));
        calculateSumarry(abs, hours, summary, setSumarry, weekDays);
        checkConstrainsts(err, setErr, weekDays, hours, summary, setError, abs);
    }

    return <Fragment>
        {!week && <PeriodForm periodFromTrack={periodFromTrack} />}

        {
            week && <Fragment>
                {hours.length === 0 && <WaitingRoundSnippers />}<br></br>
                {error && 
                    <Fragment>
                        <ErrorMessage error={error} />
                        <br></br>
                    </Fragment>
                }
                {msg && 
                    <Fragment>
                        <SuccessMessage msg={msg} msgState={setMsg} />
                        <div className="text-center mb-3">
                            <button className="btn btn-secondary" onClick={refresh}>Nouvelle FAH</button>
                        </div>
                        <br></br>
                    </Fragment>
                }

                <InstallerPC weekDays={weekDays} hours={hours} setHours={setHours}
                    emptyObj={emptyObj} week={week} year={year} abs={abs} setAbs={setAbs}
                    summary={summary} setSumarry={setSumarry} loading={loading} err={err} 
                    error={error} save={save} saving={saving} userInfo={userInfo} 
                    recalculateSchedule={recalculateSchedule} soldeC2={soldeC2}/>

            </Fragment>
        }

    </Fragment>

}

export default Installer;