import { useEffect } from "react";
import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import DisposableArticles from "../../services/articles/DisposableArticles";
import errorManagement from "../../services/errorManagement";


const ToThrowProposals = (props) => {
    const[list, setList] = useState();
    const[error, setError] = useState();
    const[loading, setLoading] = useState(true);
    const[amount, setAmount] = useState(0);
    const[count, setCount] = useState(0);

    useEffect( () => {
        DisposableArticles.getProposals().then(
            (response) => {
                setList(response)
                setLoading(false);
            }, 
            (error) => {
                setError( errorManagement.handleError(props, error) );
                setLoading(false);
            }
        )
    },[] );

    const onOneCheck = (e) => {
        var ar = e.target.getAttribute("data-ar");
        var arr = new Array();

        var json;
        list.map( (v) =>
            {
                json = v;
                if( json.cdaro === ar ) json["checked"] = e.target.checked;

                arr.push(json);
            }
        )
        
        setList(arr);
        
        calculateAmount();
    }

    const checkAll = (e) => {
        var arr = new Array();

        var json;
        list.map( (v) =>
            {
                json = v;
                json["checked"] = e.target.checked;

                arr.push(json);
            }
        )
        
        setList(arr);
        
        calculateAmount();
    }

    function calculateAmount(){
        var a = 0, c = 0;

        list.map( (v) => { 
            if( v.checked ){
                a += parseFloat( v.price ); 
                c++;
            }  
        } )

        setAmount( a.toFixed(2) );
        setCount(c);
    }

    const onThrowClick = () => {
        confirmAlert({
            title: "Confirmation",
            message: "Etes-vous sûr(e) de vouloir jeter " + count 
                + " article" +  (count>1 ? "s" : "") + "?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setLoading(true);
                        DisposableArticles.addToThrow(list).then(
                            (response) => {
                                props.history.push("/article/disposables/list");
                                window.location.reload();
                            }, 
                            (error) => {
                                setError( errorManagement.handleError(props, error) );
                                setLoading(false);
                            }
                        )
                    }
                },
                {
                    label: "Non"
                }
        ]});
    }

    return (
        <div>
            <h4 className="text-center">Proposition de jets d'articles</h4>

            {
                count > 0 &&
                    <div className="text-center">
                        <button type="button" onClick={onThrowClick}
                            className="btn btn-danger"
                            disabled={loading}>Jeter { count } article{count>1 ? "s" : ""}</button>
                        <br/><br/>
                    </div>                    
            }

            { ( loading ) && 
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status"></div>
                </div>
            }

            {
                list &&
                <div className="col-10 offset-1">
                    <table className="table table-striped text-center">
                        <thead className="table-dark">
                            <tr>
                                <th scope="col">A jeter</th>
                                <th scope="col">Articles</th>
                                <th scope="col">Référence</th>
                                <th scope="col">Qté</th>
                                <th scope="col">Montant total</th>
                                <th scope="col">Dernier mouvement sur affaire</th>
                                <th scope="col">Non utilisé depuis</th>
                            </tr>
                            <tr className="table-dark">
                                <td>
                                    <input type="checkbox" onChange={checkAll}></input>
                                </td>
                                <td colSpan={6} className="text-center fw-bold">
                                    Montant total des articles cochés: {amount}€
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                list.map( (v, k) => 
                                    <tr key={k}>
                                        <th>
                                            <input type="checkbox" checked={v.checked}
                                                data-ar={ v.cdaro } onChange={onOneCheck}></input>
                                        </th>
                                        <td>{ v.cdaro }</td>
                                        <td>{ v.DES2L }</td>
                                        <td>{ v.qtstl }</td>
                                        <td>{ v.price }</td>
                                        <td>{ v.last_mvt_date }</td>
                                        <td>{ v.non_used } ans</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            }
        </div>


    )
}

export default ToThrowProposals;