import { useEffect, useState } from "react";
import { arrayRemoveElt } from "../../../functions/ArrayFunctions";
import { getDateTimeFromDb } from "../../../functions/StrFunctions";
import CsContracts from "../../../services/customClient/CsContracts";
import SuccessMessage from "../../../common/smallComponents.js/SuccessMessage";
import errorManagement from "../../../services/errorManagement";
import { ButtonWaiting } from "../../../functions/PageAnimation";
import ErrorMessage from "../../../common/smallComponents.js/ErrorMessage";

const IncidentMail = (p) => {
    var [cc, setCc] = useState(["support.pack@sealedair.com"]);
    const [sending, setSending] = useState(false);
    const [msg, setMsg] = useState();
    const [err, setErr] = useState();

    useEffect(()=>{
        msgFr();
    }, []);

    function msgFr(){
        var msg = 'Cher client,\r\n'
        +'Ceci est un suivi concernant votre demande précédente n°' + p.it.id + ' :'												+ '\r\n'
        +'\r\n' 
        +'Ticket ouvert: ' 						+	getDateTimeFromDb( p.it.call_time , "en " ) + '\r\n' 
        +'Ticket fermé: ' 						+	getDateTimeFromDb( p.it.end_of_intervention , "en " )+ '\r\n' 
        +'N° affaire: ' 					+	p.it.business 													+ '\r\n' 
        +'Nom du client: ' 						+	p.it.client														+ '\r\n' 
        +'Contact client: ' 					+	p.it.contact														+ '\r\n' 
        +'Numéro du contact: ' 						+	p.it.tel															+ '\r\n' 
        +'Email du contact: ' 					+	p.it.mail															+ '\r\n' 
        +'Etat de la machine au départ: '+ 	(p.it.state_mach_start == 'M' ? 'Machine running' : 'Machine stopped')+ '\r\n'
        +'Motif de l\'appel: '				+	p.it.request_subject												+ '\r\n'
        +'Pièce incriminée: '					+	p.it.defective_parts												+ '\r\n'
        +'Solutions: '							+	p.it.solutions													+ '\r\n'
        +'Etat de la machine à la fin :'		+	(p.it.state_mach_end == 'M' ? 'Machine running' : 'Machine stopped')	+ '\r\n'
        +'Intervenant: '				+	p.it.speaker														+ '\r\n'
        +'Brève description du problème : ' 	+	p.it.comments														+ '\r\n' 
        + '\r\n' 
        + 'Pour toute information complémentaire merci de nous contacter par mail à support.pack@sealedair.com';

        document.getElementById("comment").value = msg;
    }

    function msgEn(){
        var msg = 'Dear client,\r\n'
        +'This is a follow-up on your previous request #' + p.it.id + ' :'												+ '\r\n'
        +'\r\n' 
        +'Ticket opened: ' 						+	getDateTimeFromDb( p.it.call_time , "en " ) + '\r\n' 
        +'Ticket closed: ' 						+	getDateTimeFromDb( p.it.end_of_intervention , "en " )+ '\r\n' 
        +'Customer account: ' 					+	p.it.business 													+ '\r\n' 
        +'Customer name: ' 						+	p.it.client														+ '\r\n' 
        +'Customer contact: ' 					+	p.it.contact														+ '\r\n' 
        +'Contact phone: ' 						+	p.it.tel															+ '\r\n' 
        +'Contact E-Mail: ' 					+	p.it.mail															+ '\r\n' 
        +'State of the machine before support: '+ 	(p.it.state_mach_start == 'M' ? 'Machine running' : 'Machine stopped')+ '\r\n'
        +'Reason for the call: '				+	p.it.request_subject												+ '\r\n'
        +'Piece incriminated: '					+	p.it.defective_parts												+ '\r\n'
        +'Solutions: '							+	p.it.solutions													+ '\r\n'
        +'Machine status after support :'		+	(p.it.state_mach_end == 'M' ? 'Machine running' : 'Machine stopped')	+ '\r\n'
        +'support technician : '				+	p.it.speaker														+ '\r\n'
        +'Brief description of the problem : ' 	+	p.it.comments														+ '\r\n' 
        + '\r\n' 
        + 'For more  information please contact us by email at support.pack@sealedair.com';

        document.getElementById("comment").value = msg;
    }

    const addCc = () => {
        var arr = [...cc];
        arr.push("");
        setCc(arr);
    }

    const deleteCc = (k) => {
        var arr = [...cc];
        arr = arrayRemoveElt(arr, k);
        setCc(arr);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        var cc = [];
        for(let i=0; i<e.target.cc.length; i++) cc.push( e.target.cc[i].value );

        var o = {
            from: e.target.from.value, 
            to: e.target.to.value, 
            cc: cc, 
            subject: e.target.subject.value,
            msg: e.target.msg.value
        }
      
        setSending(true);

        CsContracts.sendTicket(o).then(
            (r) => {
                setMsg("Envoyé");
                setSending(false);
            },
            (error) => {
                setErr(errorManagement.handleError(p.props, error));
                setSending(false);
            }
        )

        console.log(o)
      
    }

    return <div className="custom-tooltip-v2">
        <div className="modal-body xl blue-purple-bg">
            <button type="button" className="btn-close" onClick={() => p.setSend(false)}></button>

            {msg && <SuccessMessage msg={msg}/>}
            {err && <ErrorMessage err={err}/>}

            <h4 className="text-center">Nouveau message au client</h4>
            <br></br>

            <div className="d-flex mb-3">
                <div className="ms-auto">
                    <img src="/common/fr_lang.png" className="cursor-pointer" onClick={msgFr}></img>
                </div>
                <div className="me-auto">
                    <img src="/common/en_lang.png" className="cursor-pointer" onClick={msgEn}></img>
                </div>
            </div>

            <form className="col-8 offset-2" onSubmit={onSubmit}>
                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "80px" }}>From</span>
                    <input type="text" className="form-control"
                        defaultValue="Service Client B+ Equipment" name="from" required />
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "80px" }}>To</span>
                    <input type="text" className="form-control" name="to" required />
                </div>

                {cc.map((v, k) => {
                    return <div className="input-group mb-3">
                        <span className="input-group-text" style={{ width: "80px" }}>Cc</span>
                        <input type="text" className="form-control" name="cc"
                            defaultValue={v} required />
                        {k > 0 && <a href="#" className="btn btn-danger" onClick={()=>deleteCc(k)}>Effacer</a>}
                    </div>
                })}

                <a href="#" className="btn btn-outline-secondary mb-3" onClick={addCc}>Add a cc</a>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "80px" }}>Subject</span>
                    <input type="text" className="form-control" name="subject" required />
                </div>

                <div className="form-floating mb-3">
                    <textarea className="form-control no-custom" placeholder="x" name="msg" 
                        style={{height: "150px"}} id="comment" required></textarea>
                    <label>Message</label>
                </div>

                <div className="text-center">
                     <button type="submit" className="btn btn-success" disabled={sending}>
                        {sending && <ButtonWaiting />}
                        Send</button>
                </div>

            </form>
        </div>
    </div>

}

export default IncidentMail;