import Excel from 'exceljs';
import Moment from "moment";



export const getXlsxContent = (file) => {
    const wb = new Excel.Workbook();
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);
    reader.onload = () => {
        const buffer = reader.result;
        wb.xlsx.load(buffer).then((workbook) => {
            workbook.eachSheet((sheet, id) => {

                sheet.eachRow((row, rowIndex) => {
                });
            });
        });
    };
}

export const getXlsxSheetContent = (file, index, callback) => {
    const wb = new Excel.Workbook();
    const reader = new FileReader();
    var arr = new Array();

    reader.readAsArrayBuffer(file);
    reader.onload = () => {
        const buffer = reader.result;
        wb.xlsx.load(buffer).then((workbook) => {

            const sht = wb.getWorksheet(index);

            sht.eachRow((row, rowIndex) => {
                arr.push({
                    rowIndex: rowIndex,
                    row: row
                });
            });

            callback(arr);
        });
    };

}

export const getSubAssemblySheetContent = (file, index) => {
    const wb = new Excel.Workbook();
    const reader = new FileReader();
    var arr = new Array();
    var header = -1;

    reader.readAsArrayBuffer(file);
    return new Promise(
        (resolve, reject) => {
            reader.onload = () => {
                const buffer = reader.result;
                wb.xlsx.load(buffer).then((workbook) => {
    
                    const sht = wb.getWorksheet("Feuil1");
                    var h;
                    var se = file.name.split("-")[2];

                    sht.eachRow((row, rowIndex) => {
                        // Get header start
                        if( header === -1 &&
                                ( row._cells[2] !== undefined && getVal( row._cells[2]._value.model ).toString().toUpperCase().indexOf( "DATE" ) !== -1 )){
                            header = rowIndex+1;
                            try{
                                h = getHeaderIdx(sht, rowIndex);
                            }catch(e){
                                reject(e.message);
                            } 
                        }
                        
                        //Get rows
                        if( header !== -1 && rowIndex>header ){
                            var cat = row._cells[h.cat] !== undefined ? getVal( row._cells[h.cat]._value.model ).toString() : "";
                            var rep = row._cells[h.rep] !== undefined ? getVal( row._cells[h.rep]._value.model ).toString() : "";
                            var qty = row._cells[h.qty] !== undefined ? getVal( row._cells[h.qty]._value.model ).toString() : "";
                            var ref = row._cells[h.ref] !== undefined ? getVal( row._cells[h.ref]._value.model ).toString() : "";
                            
                            if( rep.trim() !== "" && (qty.trim() !== "" && !isNaN(qty) && parseFloat(qty) > 0 )
                                && ref.trim() !== "" ){
                                    arr.push({
                                        cat: cat,
                                        rep: rep,
                                        qty: qty,
                                        se: se,
                                        french_label: row._cells[h.desFr] !== undefined ? getVal( row._cells[h.desFr]._value.model ).toString() : "",
                                        english_label: row._cells[h.desEn] !== undefined ? getVal( row._cells[h.desEn]._value.model ).toString() : "",
                                        ref: ref,
                                        maker: row._cells[h.fab] !== undefined ? getVal( row._cells[h.fab]._value.model ).toString() : "",
                                        ar: row._cells[h.ar] !== undefined ? getVal( row._cells[h.ar]._value.model ).toString() : "",
                                        usure: row._cells[h.usure] !== undefined ? getVal( row._cells[h.usure]._value.model ).toString() : "",
                                        rechange: row._cells[h.rech] !== undefined ? getVal( row._cells[h.rech]._value.model ).toString() : "",
                                        remark_ar: row._cells[h.remAr] !== undefined ? getVal( row._cells[h.remAr]._value.model ).toString() : "",
                                        remark_nm: row._cells[h.remNm] !== undefined ? getVal( row._cells[h.remNm]._value.model ).toString() : "",
                                        ind: row._cells[h.ind] !== undefined ? getVal( row._cells[h.ind]._value.model ).toString() : "",
                                        unit: row._cells[h.unit] !== undefined ? getVal( row._cells[h.unit]._value.model ).toString() : "",
                                        family_prod: row._cells[h.fam] !== undefined ? getVal( row._cells[h.fam]._value.model ).toString() : "",
                                        type_prod: row._cells[h.typ] !== undefined ? getVal( row._cells[h.typ]._value.model ).toString() : "",
                                        chapter: row._cells[h.chap] !== undefined ? getVal( row._cells[h.chap]._value.model ).toString() : "",
                                        workstation: row._cells[h.workstation] !== undefined ? getVal( row._cells[h.workstation]._value.model ).toString() : ""
                                    });
                            }
                        }
                    });

                    if( header === -1 ) reject("Cellule \"Date\" non trouvé dans le fichier " + file.name )

    
                    resolve( arr );
                });
            };  
        }
    ).catch( function(e) { throw new Error(e) } ) 
}

export function getValue(o) {
    if (o === undefined) return "";

    if (o.richText !== undefined) {
        var txt = "";
        for (let i = 0; i < o.richText.length; i++) txt += o.richText[i].text;
        return txt;
    }

    return o;
}


export function getVal(o, show) {
    show && console.log(o)
    if (o.value !== undefined || o.result !== undefined ){
        if( o.result !== undefined ){
            return o.result;
        }else if (o.value.richText !== undefined) {
            var txt = "";
            for (let i = 0; i < o.value.richText.length; i++) txt += o.value.richText[i].text;
            return txt;
        }else if( o.value instanceof Date ){
            return Moment( new Date(o.value) ).format(o.style.numFmt.replace(";@", "").toUpperCase());
        }else{
            return o.value;
        }
    }else{
        return "";
    }
    
}

function getHeaderIdx(sht, row){
    var o = {cat: null, rep: null, qty: null, desFr: null, desEn: null, ref: null, fab: null, 
        ar: null, usure: null, rech: null, remAr: null, remNm: null, ind:null, fam: null, typ: null, 
        unit: null, chap: null, workstation: null}
    var col = sht._rows[row]._cells;

    for( let i=0; i<col.length; i++){
        if( getVal( col[i]._value.model ).toUpperCase().startsWith("CAT") ) o.cat = i;
        if( getVal( col[i]._value.model ).toUpperCase().startsWith("REP") ) o.rep = i;
        if( getVal( col[i]._value.model ).toUpperCase().startsWith("QTE") && o.qty === null ) o.qty = i;
        if( getVal( col[i]._value.model ).toUpperCase().indexOf("DESIGNATION") !== -1 && o.desFr === null ) o.desFr = i;
        if( getVal( col[i]._value.model ).toUpperCase().indexOf("DESIGNATION") !== -1 && o.desFr !== null ) o.desEn = i;
        if( getVal( col[i]._value.model ).toUpperCase().startsWith("REF") ) o.ref = i;
        if( getVal( col[i]._value.model ).toUpperCase().startsWith("FABRICANT") ) o.fab = i;
        if( getVal( col[i]._value.model ).toUpperCase().startsWith("CODE") ) o.ar = i;
        if( getVal( col[i]._value.model ).toUpperCase().startsWith("PIECE D'USURE") ) o.usure = i;
        if( getVal( col[i]._value.model ).toUpperCase().indexOf("RECHANGE") !== null ) o.rech = i;
        if( getVal( col[i]._value.model ).toUpperCase().indexOf("REMARQUE") !== -1 && o.remAr === null ) o.remAr = i;
        if( getVal( col[i]._value.model ).toUpperCase().indexOf("REMARQUE") !== -1 ) o.remNm = i;
        if( getVal( col[i]._value.model ).toUpperCase().startsWith("IND") || 
                getVal( col[i]._value.model ).toUpperCase().indexOf("REV") !== -1 ) o.ind = i;
        if( getVal( col[i]._value.model ).toUpperCase().startsWith("UNITE") ) o.unit = i;
        if( getVal( col[i]._value.model ).toUpperCase().startsWith("FAMILLE PRODUIT") ) o.fam = i;
        if( getVal( col[i]._value.model ).toUpperCase().startsWith("TYPE PRODUIT") ) o.typ = i;
        if( getVal( col[i]._value.model ).toUpperCase().startsWith("CHAPITRE") ) o.chap = i;
        if( getVal( col[i]._value.model ).toUpperCase().startsWith("PICKING") ) o.workstation = i;
    } 

    var err = "";
    if( o.cat === null ) err += "La colonne \"Catégorie\" est introuvable<br>";
    if( o.rep === null ) err += "La colonne \"Repère\" est introuvable<br>";
    if( o.qty === null ) err += "La colonne \"Qté\" est introuvable<br>";
    if( o.desFr === null ) err += "La colonne \"Désignation FR\" est introuvable<br>";
    if( o.desEn === null ) err += "La colonne \"Désignation EN\" est introuvable<br>";
    if( o.ref === null ) err += "La colonne \"Référence\" est introuvable<br>";
    if( o.fab === null ) err += "La colonne \"Fabricant\" est introuvable<br>";
    if( o.ar === null ) err += "La colonne \"Code article\" est introuvable<br>";
    if( o.usure === null ){
        if( o.ar === null ){
            err += "La colonne \"Pièce D'usure\" est introuvable<br>";
        }else{
            o.usure = o.ar +1;
        }
    } 
    if( o.rech === null ) err += "La colonne \"Pièces rechange\" est introuvable<br>";
    if( o.remAr === null ) err += "La colonne \"Remarques\" est introuvable<br>";
    if( o.ind === null ) err += "La colonne \"IND\" est introuvable<br>";
    /*if( o.unit === null ) err += "La colonne \"UNIT\" est introuvable<br>";
    if( o.fam === null ) err += "La colonne \"FAMILLE PRODUIT\" est introuvable<br>";
    if( o.typ === null ) err += "La colonne \"TYPE PRODUIT\" est introuvable<br>";
    if( o.chap === null ) err += "La colonne \"CHAPITRE\" est introuvable<br>";*/

    if(err !== "") throw new Error(err);
    return o;
}