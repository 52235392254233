import { useEffect, useMemo } from "react";
import { useState } from "react";
import ticketsDao from "../../services/qhse/ticketsDao";
import Moment from "moment";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import errorManagement from "../../services/errorManagement";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { DefaultColumnFilter, filterMethods } from "../../functions/TableFuntions";
import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table";

const BbsoUserGoals = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [adding, setAdding] = useState(false);
    const [error, setError] = useState();
    Moment.locale("fr");

    useEffect(() => {
        ticketsDao.getBbsoGoalsList().then(
            (response) => {
                setData(response);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: "Objectif BBSO",
                accessor: "goal"
            },
            {
                Header: "Nom",
                accessor: "lastName"
            },
            {
                Header: "Prénom",
                accessor: "firstName"
            },
            {
                Header: "Email",
                accessor: "mail"
            },
            {
                Header: "Service",
                accessor: "department"
            },
            {
                Header: "Global ID",
                accessor: "globalId"
            },
            {
                Header: "Contrat",
                accessor: "contract"
            },

        ], []
    );

    //Set filters for table
    const filterTypes = useMemo(() => (filterMethods()), []);

    //Set default columns of table
    const defaultColumn = useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter
        }),
        []
    );

    //Create table
    const tableInstance = useTable({ columns, data, defaultColumn, filterTypes }, useFilters, useGlobalFilter, useSortBy);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance

    /**
     * Add new employee
     * @param {*} e 
     * @returns 
     */
    const addEmployee = (e) => {
        e.preventDefault(addEmployee);
        setError();
        setAdding(true);
        var goal = e.target.goal.value;
        var last_name = e.target.last_name.value;
        var first_name = e.target.first_name.value;
        var trigram = e.target.trigram.value;
        var globalId = e.target.globalId.value;
        var mail = e.target.mail.value;
        var contract = e.target.contract.value;

        for (let i = 0; i < data.length; i++) {
            if (data[i].globalId.toUpperCase().trim() === globalId.toUpperCase().trim()) {
                setError("Ce global ID existe déjà !");
                setAdding(false);
                return;
            }
        }

        ticketsDao.addBbsoGoalsList(goal, trigram, last_name, first_name, globalId, contract, mail).then(
            (response) => {
                setAdding(false);
                var arr = [...data];
                arr.push({
                    goal: goal,
                    trigram: trigram,
                    lastName: last_name,
                    firstName: first_name,
                    globalId: globalId,
                    contract: contract,
                    mail: mail
                });
                setData(arr);
                e.target.goal.value = "";
                e.target.trigram.value = "";
                e.target.last_name.value = "";
                e.target.first_name.value = "";
                e.target.globalId.value = "";
                e.target.contract.value = "";
                e.target.mail.value = "";
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setAdding(false);
            }
        );

    }

    /**
     * Delete employee
     * @param {*} e 
     */
    const deleteEmployee = (e) => {
        var key = e.target.getAttribute("data-key");
        var arr = [...data];
        setData(arrayRemoveElt(arr, key));

        ticketsDao.deleteBbsoGoalsList(arr[key].globalId).then(
            (response) => {
                
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );
    }

    const updateField = (e) => {
        var field = e.target.getAttribute("data-field");
        var key = e.target.getAttribute("data-key");
        var value = e.target.value;

        ticketsDao.updateBbsoGoalsList(field, value, data[key].globalId).then(
            (response) => {
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );

    }

    const onFieldChange = (e) => {
        var key = e.target.getAttribute("data-key");
        var field = e.target.getAttribute("data-field");
        var value = e.target.value;
        var arr = [...data];
        arr[key][field] = value;
        setData(arr);
    }

    return (
        <div>
            <h2 className="text-center">Liste des BBSO</h2>

            <form onSubmit={addEmployee}>
                <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">Ajouter un salarié</span>
                    <input type="number" className="form-control" name="goal" placeholder="Objectif" required />
                    <input type="text" className="form-control" name="last_name" placeholder="Nom" required />
                    <input type="text" className="form-control" name="first_name" placeholder="Prénom" required />
                    <input type="hidden" className="form-control" name="trigram" value="" placeholder="Trigramme" required />
                    <input type="text" className="form-control" name="globalId" placeholder="Global ID" required />
                    <input type="text" className="form-control" name="mail" placeholder="E-mail" required />
                    <input type="text" className="form-control" name="contract" placeholder="Contrat" required />
                    <button type="submit" className="btn btn-outline-success" disabled={adding}>
                        {adding && (<span className="spinner-border spinner-border-sm"></span>)}Ajouter</button>
                </div>
            </form>

            {
                loading && <WaitingRoundSnippers />
            }

            {error && <ErrorMessage error={error} />}


            {
                data &&
                <table {...getTableProps()} className="table text-center" id="subcontracting_table">
                    <thead>
                        {// Loop over the header rows
                            headerGroups.map(headerGroup => (
                                // Apply the header row props
                                <tr className="table-dark fixed-header" {...headerGroup.getHeaderGroupProps()}>
                                    {// Loop over the headers in each row
                                        headerGroup.headers.map(column => (
                                            // Apply the header cell props
                                            <th {...column.getHeaderProps(column.getSortByToggleProps(),)}>
                                                {column.render('Header')}

                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? ' 🔽'
                                                            : ' 🔼'
                                                        : ''}
                                                </span>

                                            </th>
                                        ))}
                                    <th></th>

                                </tr>



                            ))}
                        {// Loop over the header rows
                            headerGroups.map(headerGroup => (
                                // Apply the header row props
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {// Loop over the headers in each row
                                        headerGroup.headers.map(column => (
                                            // Apply the header cell props
                                            <th {...column.getHeaderProps()}>
                                                {/* Render the columns filter UI */}
                                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                                            </th>
                                        ))}
                                    <th></th>
                                </tr>


                            ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {// Loop over the table rows
                            rows.map(row => {
                                // Prepare the row for display
                                prepareRow(row)
                                return (
                                    // Apply the row props
                                    <tr {...row.getRowProps()} >
                                        {// Loop over the rows cells
                                            row.cells.map(cell => {
                                                return (
                                                    <td {...cell.getCellProps()}>
                                                        {
                                                            cell.render('Cell')
                                                        }
                                                    </td>
                                                )
                                            })}
                                        <td>
                                            <button className="btn btn-outline-danger" data-key={row.id} onClick={deleteEmployee}>
                                                Supprimer
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            }

        </div>)
}

export default BbsoUserGoals;