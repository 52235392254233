import { createColumnHelper } from "@tanstack/react-table";
import Moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { SideNavBarV3, isSideBarOpened } from "../../common/smallComponents.js/DesignedIpt";
import ErrorMessage, { InfoMsg, WarningMsg } from "../../common/smallComponents.js/ErrorMessage";
import LabelsChoice from "../../common/smallComponents.js/LabelsChoice";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { removeEltArray } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import getUrlParam, { compareTwoDates, currentEnDate } from "../../functions/StrFunctions";
import CsContracts from "../../services/customClient/CsContracts";
import errorManagement from "../../services/errorManagement";
import { downloadTempFile } from "../../services/functions/FilesProcess";

const Picking = (props) => {
    const [pin, setPin] = useState(isSideBarOpened());
    const [business, setBusiness] = useState();
    const [error, setError] = useState();
    const [warning, setWarning] = useState();
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState();
    const [pcikingDates, setPickingDates] = useState([]);
    const [printing, setPrinting] = useState(false);
    const [start, setStart] = useState();
    const [labelMode, setLabelMode] = useState();
    const [printBusiness, setprintBusiness] = useState(false);
    const [printReliquat, setprintReliquat] = useState(false);
    const [duplicateLabels, setduplicateLabels] = useState(1);
    const [ignoreErr, setIgnoreErr] = useState(false);

    const [picking, setPicking] = useState();
    const [reliquat, setReliquat] = useState([]);
    const needPck = "UJLTNH";
    const [firstDate, setFirstDate] = useState("");

    Moment.locale("fr");

    const user = JSON.parse(localStorage.getItem("user"));
    const isAdmin = user && user.roles.includes("ROLE_ADMIN");
    const isStorehouse = user && (isAdmin || user.roles.includes("ROLE_STOREHOUSE"));
    const isCs = user && (isAdmin || user.roles.includes("ROLE_CUSTOMER_CLIENT"));

    useEffect(() => {
        var business = getUrlParam(props, "business");

        if (business !== null) {
            setBusiness(business);
            searchPicking(business);
        }
    }, []);

    /** */
    const searchPicking = (business) => {
        setLoading(true);

        CsContracts.getPicking(business).then(
            (response) => {
                setPicking(response);
                checkList(response);

                var arr = new Array();
                var ld = "";
                for( let i=0; i<response.length; i++ ){
                    var v = response[i];
                    if (v.date_picking !== null && !arr.includes(v.date_picking)){
                        if( ld === "" ){ ld = v.date_picking }
                        else{ if( compareTwoDates( v.date_picking, ld ) > 0 ) ld = v.date_picking;}
                        arr.push(v.date_picking)
                    }
                }
                
                setPickingDates(arr);
                setFirstDate( ld );

                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }

    /** */
    const checkReliq = (e, k) => {
        var arr = [...reliquat];
        var arr2 = [...picking];
        if (e.target.checked) {
            arr.push(k);
            arr2[k].reliquat = "O";
        } else {
            arr = removeEltArray(arr, k);
            arr2[k].reliquat = undefined;
        }

        setPicking(arr2);
        setReliquat(arr);
    }

    /** */
    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        if (isStorehouse) {
            arr.push(columnHelper.accessor('reliquat', {
                header: 'Rlq',
                cell: i => <div className="text-center no-print">
                    { i.row.original.NOCDF && 
                    <input type="checkbox" onChange={(e) => checkReliq(e, i.row.id)}
                        checked={reliquat.includes(i.row.id)}></input>}
                </div>
            }));

            arr.push(columnHelper.accessor('xxxx', {
                header: 'Ctrl colisage',
                cell: i => <div className={"text-center "}>
                    { i.row.original.NOCDF && 
                    <input type="checkbox"></input>}
                </div>
            }));
        }

        if (isCs) {
            arr.push(columnHelper.accessor('visible', {
                header: 'Masquer',
                cell: i => <div className="text-center no-print">
                    { i.row.original.NOCDF && 
                    <input type="checkbox" checked={i.getValue() && i.getValue() !== 'O'}
                        onChange={(e) => updateField(e, i.row.id, "visible")}></input>}
                </div>
            }));
        }

        arr.push(columnHelper.accessor('NLIGF', {
            header: 'Li',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('ARAVF', {
            header: 'Ref B+',
            cell: i => <div className={"text-center "}>
                <a href={"/article/info/get?article=" + i.getValue()} target="_blank">{i.getValue()}</a>
                
                { i.row.original.NOCDF && 
                    <Fragment>
                {i.row.original.date_picking !== null &&
                    <Fragment>
                        <br></br>
                        <span style={{ fontSize: "12px" }}>Picking du {Moment(i.row.original.date_picking).format("DD/MM/yyyy")}</span>
                    </Fragment>
                }
                {i.row.original.date_picking === null &&
                    <Fragment>
                        <br></br>
                        <span style={{ fontSize: "12px" }}>Picking non lancé</span>
                    </Fragment>
                }
                {i.row.original.date_picking !== firstDate && 
                    <div className="bg-info-subtle rounded" style={{ fontSize: "12px" }}>Nouveau</div>}

                { (i.row.original.initial_qty && i.row.original.initial_qty !== i.row.original.QTARF) && 
                    <div className="bg-info-subtle rounded" style={{ fontSize: "12px" }}>Qté MAJ (anc. {i.row.original.initial_qty} )</div>
                }
                </Fragment>
                }

                { !i.row.original.NOCDF && 
                <div>
                    <div>{i.row.original.initial_ar}</div>
                    <div className="bg-danger-subtle rounded" style={{ fontSize: "12px" }}>Supprimé (anc. {i.row.original.initial_qty} )</div>  
                </div>}
            </div>
        }));

        if (isCs) {
            arr.push(columnHelper.accessor('BLOCL', {
                header: 'Blocage art.',
                cell: i => <div className={"text-center "}>
                    {(i.getValue() && i.getValue() !== "N" ) && "Bloqué !"}
                </div>
            }));
        }

        arr.push(columnHelper.accessor('QTSTL', {
            header: 'Qté en stock',
            cell: i => <div className={"text-center "}>
                { i.row.original.NOCDF && 
                    <div>Actuel: {i.getValue()}<br></br>
                <span className="fst-italic" style={{fontSize: "12px"}}>Prev.: {i.getValue() - i.row.original.QTREL + i.row.original.QTCDL }</span>
                </div>}
            </div>
        }));

        arr.push(columnHelper.accessor('QTARF', {
            header: isCs ? 'Qte att.' : 'Qte att./sortie',
            cell: i => <div className={"text-center "}>
                {i.getValue()}

            </div>
        }));

        if (isCs) {
            arr.push(columnHelper.accessor('moved', {
                header: 'Qté sortie',
                cell: i => <div className={"text-center "}>{i.getValue()}</div>
            }));
        }

        arr.push(columnHelper.accessor('ZN04PA', {
            header: 'Renvoi',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('ZN02PA', {
            header: 'Libellé technique',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('ZN03PA', {
            header: 'Désignation',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('detail_order', {
            header: 'Cmd. stock',
            cell: i => <div className={"text-center "} dangerouslySetInnerHTML={{ __html: getLinks(i.getValue()) }}></div>
        }));

        arr.push(columnHelper.accessor('need_order', {
            header: 'Cmd. besoins',
            cell: i => <div className={"text-center "} dangerouslySetInnerHTML={{ __html: getLinks(i.getValue()) }}></div>
        }));

        arr.push(columnHelper.accessor('DTLIM', {
            header: 'Délai du besoin',
            cell: i => i.row.original.NOCDF && 
                <div style={{ height: "100%" }} className={"text-center "
                + ( i.row.original.moved < i.row.original.QTARF && compareTwoDates(currentEnDate(), i.getValue()) < 0 ? 
                        "bg-danger text-white" : "")}>
                {i.getValue() === null ? "" : Moment(i.getValue()).format("DD/MM/yyyy")}
                {i.row.original.moved < i.row.original.QTARF && compareTwoDates(currentEnDate(), i.getValue()) < 0 && <Fragment>
                    <br></br>
                    (Retard)
                </Fragment>}
            </div>
        }));

        arr.push(columnHelper.accessor('com', {
            header: 'Commentaires SAV',
            cell: i =>  i.row.original.NOCDF && 
                    <div className={"text-center"}>
                <textarea className="form-control no-print" defaultValue={i.getValue()}
                    onBlur={(e) => updateField(e, i.row.id, "com")} readOnly={!isCs} />
                <span className="print-only">{i.getValue()}</span>
            </div>
        }));

        arr.push(columnHelper.accessor('com_mag', {
            header: 'Observation MAG',
            cell: i => i.row.original.NOCDF && 
            <div className={"text-center"}>
                <textarea className="form-control no-print" defaultValue={i.getValue()}
                    onBlur={(e) => updateField(e, i.row.id, "com_mag")} readOnly={!isStorehouse} />
                <span className="print-only">{i.getValue()}</span>
            </div>
        }));

        return arr;
    }

    /** */
    function getLinks(txt) {
        if (!txt) return "";

        var url = "";
        txt.split("<br/>").map(v => {
            if (v !== "") url += '<a target="_blank" href="/purchase/order/edition?order=' + v.split(":")[0].trim() + '">' + v + '</a><br/>';
        })

        return url;

    }

    /** Download file*/
    const downloadXls = (mode) => {
        var arr = getCleanedArr(getArray(mode));

        setLoading(true);
        CsContracts.downloadPicking(arr).then(
            (r) => {
                setLoading(false);
                downloadTempFile(r);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }

    function getCleanedArr(arr){
        var arr2 = [];

        for( let i=0; i<arr.length; i++ )
            if(arr[i].NOCDE) arr2.push( arr[i] );
        
        return arr2;
    }

    function getArray(mode) {
        var arr = new Array();
        if (mode === "all") {
            arr = [...picking];
        } else if (mode === "reliquat") {
            reliquat.map(v => arr.push(picking[parseInt(v)]));
        } else if (mode.startsWith("date:")) {
            var date = mode.substring(mode.indexOf(":") + 1, mode.length)
            picking.map(v => { if (v.date_picking !== null && v.date_picking === date) arr.push(v) })
        }
        return arr;
    }

    /** Update hidden row and comments */
    const updateField = (e, k, field) => {
        var arr = [...picking];

        if (field === "visible") {
            arr[k].visible = e.target.checked ? "N" : "O";
        } else {
            arr[k][field] = e.target.value;
        }

        setPicking(arr);
    }

    /** Update need dates */
    const updateNeedDate = (e) => {
        e.preventDefault();

        var d1 = e.target.need_date.value;
        if (compareTwoDates(currentEnDate(), d1) >= 7) {
            setLoading(true);

            CsContracts.updateNeedDate(business, d1).then(
                () => {
                    window.location.reload();
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    setLoading(false);
                    scrollToTop();
                }
            )
        } else {
            setError(error + "<br/><br/>La date doit être > ou = à 7j à compter d'aujourd'hui")
        }
    }

    /** Control list validity */
    function checkList(list) {
        if (list.length === 0) return;

        var info = "", blocked_term = "", not_need = "", blocked = "", error_fct = "", change_date = "";
        //Check if need packaging
        if (needPck.indexOf(list[0].NOCDE.charAt(0)) !== -1 && isStorehouse)
            info += "Prévoir pour emballage: palette plastique ou caisse en bois NIMP15<br>";

        if (isCs) {
            for (let i = 0; i < list.length; i++) {
                var o = list[i];

                //Check blocked articles and not enough stock
                if (o.BLOCL !== "N" && ((parseFloat(o.QTSTL) - parseFloat(o.QTARF)) < 0))
                    blocked += o.ARAVF.trim() + ", ";

                // Check at term not enough and blocked: warning
                if (o.BLOCL !== "N" && (o.QTSTL - o.QTREL + o.QTCDL + o.QTARF) < 0 && blocked.indexOf(o.ARAVF) !== -1)
                    blocked_term += o.ARAVF.trim() + ", ";

                //Date de besoin qté insuffisante et date de commande trop rapprochée
                var dc = compareTwoDates(currentEnDate(), o.DTLIM);
                if ((o.DTLIM && (parseFloat(o.QTSTL) - parseFloat(o.QTARF)) < 0)
                    && dc !== null && dc < 7) change_date += o.ARAVF.trim() + ", ";

                //Check needs
                if (o.NORMM === null) not_need += o.ARAVF.trim() + ", ";

                //Check function code
                if (o.NONNF && o.NONNF !== "SAV") error_fct += o.ARAVF.trim() + ", "
            }
        }

        var warning = "";
        if (blocked_term !== "") warning = "Article(s) bloqué(s) et insuffisant(s) à terme: " + blocked_term + "<br/>";
        if (not_need !== "") warning += "Article(s) non coché(s) en besoin et donc non commandé(s): " + not_need + "<br/>"

        var error = "";
        if (blocked !== "") error = "Article(s) bloqué(s) sans stock: " + blocked + "<br/>";
        if (error_fct !== "") error = "Code fonction erroné (attendu \"SAV\"): " + error_fct + "<br/>";
        if (change_date !== "") error = "Article(s) à commander mais dont la date de besoin inférieure à 7 jours par rapport à la date du jour: " + change_date + "<br/>";


        if (info !== "") setInfo(info);
        if (warning !== "") setWarning(warning);
        if (error !== "") setError(error)
    }

    //Set columns
    const columns = getColumns();

    /** */
    const save = () => {
        if (error) {
            setIgnoreErr(true);
        } else {
            saveAndSend();
        }
    }

    const forceSend = (e) => {
        e.preventDefault();
        saveAndSend(error, e.target.cause.value);
    }

    function saveAndSend(err = null, msg = null) {
        setLoading(true);
        CsContracts.savePicking(picking, isCs, err, msg).then(
            () => {
                setMsg("Sauvegardé " + ( isCs ? " et envoyé" : "" ) );
                var arr = [...picking];
                arr.map(v => { if (!v.date_picking) v.date_picking = currentEnDate() });
                setPicking(arr);
                setLoading(false);
                setIgnoreErr(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                setIgnoreErr(false);
                scrollToTop();
            }
        )
    }

    /** */
    const printLabel = (idx) => {
        var arr = getArray(labelMode);

        setLoading(true);
        setPrinting(true);
        CsContracts.downloadLabels(arr, printBusiness, idx, printReliquat, duplicateLabels).then(
            (r) => {
                setLoading(false);
                setPrinting(false);
                downloadTempFile(r);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                setPrinting(false);
                scrollToTop();
            }
        )
    }

    const notifyLabel = () => {
        setLoading(true);
        CsContracts.notifyDelta(getArray("reliquat")).then(
            (r) => {
                setLoading(false);
                setMsg("Envoyé !");
                scrollToTop();
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }

    return <div className="bg-see navbarV3 right-side">

        <SideNavBarV3 setPin={setPin} content={<div style={{ width: "280px" }} className="ms-auto me-auto">
            {picking && <div>
                <h6 className="text-center">Etiquettes</h6>

                <div>
                    <ul>
                        <li>
                            <a onClick={() => setLabelMode("all")}>Tout le picking</a>
                        </li>

                        {pcikingDates.length > 1 && <Fragment>
                            {pcikingDates.map((v, k) => {
                                return <li key={"down" + k}>
                                    <a onClick={() => setLabelMode("date:" + v)}>Picking du {v}</a>
                                </li>
                            })}
                        </Fragment>}

                        {reliquat.length > 0 && <li>
                            <a onClick={() => setLabelMode("reliquat")}>Reliquats sélectionnés</a>
                        </li>}
                    </ul>
                </div>

                {labelMode &&
                    <div>
                        <LabelsChoice onChooseTag={printLabel} printingLabel={printing} start={start} />
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox"
                                checked={printBusiness} onChange={() => setprintBusiness(!printBusiness)} />
                            <label className="form-check-label">
                                Ajouter une étiquette Affaire/date
                            </label>
                        </div>

                        <div className="form-check">
                            <input className="form-check-input" type="checkbox"
                                checked={printReliquat} onChange={() => setprintReliquat(!printReliquat)} />
                            <label className="form-check-label">
                                Ajouter une étiquette Affaire/Reliquat
                            </label>
                        </div>

                        <div className="input-group mb-3">
                            <span className="input-group-text">Nb étiquettes Aff./date</span>
                            <input type="number" className="form-control text-center fw-bold" value={duplicateLabels}
                                onChange={(e) => setduplicateLabels(e.target.value)} style={{ width: "60px" }} />
                        </div>
                    </div>
                }

                <div className="h-divider"><div className="shadow-divider"></div></div>
            </div>}

            <h6 className="text-center">Télécharger</h6>
            <ul>
                <li>
                    <a onClick={() => downloadXls("all")}>Tout le picking</a>
                </li>

                {pcikingDates.length > 1 && <Fragment>
                    {pcikingDates.map((v, k) => {
                        return <li key={"down" + k}>
                            <a onClick={() => downloadXls("date:" + v)}>Picking du {v}</a>
                        </li>
                    })}
                </Fragment>}

                {reliquat.length > 0 && <li>
                    <a onClick={() => downloadXls("reliquat")}>Reliquats sélectionnés</a>
                </li>}
            </ul>

            <div className="h-divider"><div className="shadow-divider"></div></div>
            
            <h6 className="text-center">Navigation</h6>
            <a href="/customer-client/picking/list">Liste des affaires SAV</a>


        </div>} />

        <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>
            
            {
                (!picking && !loading) && <Fragment>
                    <h5 className="text-center">Picking</h5>
                    <div className="col-6 offset-3">
                        <form>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Code affaire</span>
                                <input type="text" className="form-control" name="business" />
                                <button className="btn btn-outline-success">Rechercher</button>
                            </div>
                        </form>
                    </div>
                </Fragment>
            }


            {loading && <WaitingRoundSnippers />}


            {picking && <Fragment>
                <div className="display-flex">
                    <div className="card col-4 mt-0 me-auto ms-0" style={{ minWidth: "500px" }}>
                        <h5 className="card-title mb-1">{picking[0].NOCDE}: {picking[0].RSDUE}</h5>

                        <span className="card-text">
                            <b>Adresse: </b> {picking[0].ADRLE} {picking[0].ADCLE} {picking[0].CPOLE} {picking[0].ADVLE}, <b>{picking[0].CPALE}</b>
                        </span>

                        <span className="card-text"><b>Date d'expédition: </b> {Moment(picking[0].delivery.substring(0, picking[0].delivery.indexOf("T"))).format("DD/MM/yyyy")}</span>
                        <span className="card-text"><b>Date de livraison: </b> {Moment(picking[0].DLIVT).format("DD/MM/yyyy")}</span>
                    </div>

                    <div className="text-center col-2 mt-0 me-auto ms-0">
                        <h5 className="text-center mb-3">Détail de l'affaire SAV</h5>

                        <button className="btn btn-outline-success mt-auto mb-auto"
                            disabled={loading} onClick={save}>
                            {loading && <ButtonWaiting />}
                            {isCs ? "Valider et envoyer le picking" : "Sauvegarder les commentaires"}
                        </button>

                        {(isStorehouse && reliquat.length > 0) &&
                            <button className="btn btn-outline-success mt-2 mb-auto"
                                disabled={loading} onClick={notifyLabel}>
                                {loading && <ButtonWaiting />}
                                Notifier le reliquat
                            </button>
                        }
                    </div>

                    {(error || warning || info || msg) &&
                        <div className="card mt-0">
                            {error && <Fragment>
                                <ErrorMessage error={error} />
                                {error.indexOf("date de besoin inférieure") !== -1 &&
                                    <form onSubmit={updateNeedDate}>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text">Nouvelle date de besoins</span>
                                            <input type="date" className="form-control" name="need_date" required />
                                            <button className="btn btn-outline-success" disabled={loading}>
                                                {loading && <ButtonWaiting />}
                                                Mettre à jour</button>
                                        </div>
                                    </form>}
                            </Fragment>}

                            {warning && <WarningMsg warning={warning} warningState={setWarning} />}
                            {info && <InfoMsg info={info} infoState={setInfo} />}
                            {msg && <SuccessMessage msg={msg} msgState={setMsg} />}
                        </div>}

                </div>

                <ReactTable columns={columns} data={picking} show={1000}
                    origin="cs_picking" classes={"no-padding fixFstCol"} top0={true} />

            </Fragment>
            }

            {ignoreErr &&
                <div className="custom-tooltip-v2">
                    <div className="modal-body blue-purple-bg">
                        <button type="button" className="btn-close" onClick={() => setIgnoreErr(false)}></button>
                        <h5 className="text-center">Confirmation d'envoi de picking</h5>

                        <div className="fw-bold text-center mb-3">
                            Une erreur a été détectée sur ce picking:<br></br>
                            <span className="fst-italic" dangerouslySetInnerHTML={{ __html: error }}></span><br></br>
                            Si vous souhaitez tout de même envoyer ce dernier en achats et magasin,
                            saisissez le motif de ce forcing et cliquez sur "Envoyer". Sinon fermez ce pop-up
                        </div>


                        <form onSubmit={forceSend}>
                            <textarea className="form-control mb-3" placeholder="Tapez le motif" name="cause"
                                required></textarea>
                            <div className="text-center">
                                <button type="submit" className="btn btn-success" disabled={loading}>
                                    {loading && <ButtonWaiting />}
                                    Envoyer</button>
                            </div>
                        </form>
                    </div>
                </div>
            }
        </div>
    </div>

}

export default Picking;