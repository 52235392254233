import { getTxtAreaValue } from "../../../functions/FormCtrl";
import { extractValue, extractValueFromTag } from "../../../functions/StrFunctions";


export function checkAllFilled(t, list) {

    var err = "";
    list.map(v => {
        if (v.hasConform === 1 && v.conform === null)
            err += "Machine " + v.mach_nb + ": " + t('answer missing', { ns: 'check-list' }) + ", chap: " + v.chapter_label
                + ", qst: " + v.question + "<br>";

        if (v.hasPhoto === 1 && v.photo === null && v.conform === 0)
            err += "Machine " + v.mach_nb + ": " + t('photo missing', { ns: 'check-list' }) + ", chap: " + v.chapter_label
                + ", qst: " + v.question + "<br>";

        if (v.textarea !== null && v.textarea.trim() !== "" && getTxtAreaValue(v.textarea) === "")
            err += "Machine " + v.mach_nb + ": " + t('answer missing', { ns: 'check-list' }) + ", chap: " + v.chapter_label
                + ", qst: " + v.question + "<br>";

        if (v.inputs.length > 0) {
            v.inputs.map(v2 => {
                if (extractValue(v2).trim() === "")
                    err += "Machine " + v.mach_nb + ": " + t('answer missing', { ns: 'check-list' }) + ", chap: " + v.chapter_label
                        + ", qst: " + v.question + "<br>";
            });
        }

        if (v.conform !== null && v.conform === 0 &&
            (v.value1 === null || extractValueFromTag(v.value1, "notes") === ""))
            err += "Machine " + v.mach_nb + ": " + t('notes expected', { ns: 'check-list' })
                + ", qst: " + v.question + ", chap: " + v.chapter_label + "<br>";
    });

    return err;
}