import { Fragment, useState } from "react";
import MachineInfo from "../../services/machines/MachineInfo";
import errorManagement from "../../services/errorManagement";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import transport from "../../services/supplyChain/transport";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import { setMessage } from "../../actions/message";
import { arrayRemoveElt, getKeyString, removeEltArray } from "../../functions/ArrayFunctions";

const DepartureRequest = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [machines, setMachines] = useState();
    const [business, setBusiness] = useState();
    const [list, setList] = useState();

    const [selectedMachines, setSelectedMachines] = useState([]);

    const searchBusiness = (e) => {
        e.preventDefault();
        var business = e.target.business.value.toUpperCase();
        setError();
        setLoading(true);

        MachineInfo.getAllMachineInfo(business).then(
            (response) => {
                setList(response);
                var arr = new Array();
                var  m ="";

                response.map( v => {
                    if( m.indexOf( v.no_machine ) === -1 ) arr.push(v);
                    m += v.no_machine + ";";
                });

                setMachines(arr);
                setBusiness(response[0].id_aff);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );
    }

    const onMachineSelection = (e, machine) => {
        var arr = [...selectedMachines];

        if( e.target.checked ){
            arr.push(machine);
        }else{
            arr = arrayRemoveElt(arr, getKeyString(arr, "no_machine", machine.no_machine))
        }

        setSelectedMachines(arr);
        
    }

    const send = (e) => {
        e.preventDefault();
        setError();

        if(selectedMachines.length === 0){
            setError("Selectionnez au moins une machine à expédier");
            scrollToTop();
            return;
        }

        var decharg = e.target.decharg.value;
        var charg = e.target.charg.value;
        var delivery = e.target.delivery.value;
        var comments = e.target.comments.value;

        setLoading(true);
        transport.createDepartureRequest(business, selectedMachines, decharg, charg, delivery, comments).then(
            (response) => {
                setMsg("Demande envoyée.");
                scrollToTop();
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )

    }

    return <Fragment>
        <h3 className="text-center">Demande de départ de machine</h3>
        <br></br>

        {error && <ErrorMessage error={error} />}
        { msg && <SuccessMessage  msg={msg} /> }

        <form className="col-6 offset-3" onSubmit={searchBusiness}>
            <div className="input-group mb-3">
                <span className="input-group-text">Code affaire</span>
                <input type="text" className="form-control fw-bold text-center" name="business" required />
                <button className="btn btn-success" disabled={loading}>
                    {loading && <ButtonWaiting />}
                    Rechercher
                </button>
            </div>
        </form>

        {loading && <WaitingRoundSnippers />}

        {
            machines &&
            <form onSubmit={send}>
                <br></br>
                <h4 className="text-center">Choisissez les machines que vous expédiez</h4>

                <div className="text-center">
                    <div className="btn-group" role="group">
                        {
                            machines.map((v, k) => {
                                return <Fragment key={"m" + k}>
                                    <input type="checkbox" className="btn-check machine" 
                                        value={k} id={k} autocomplete="off" onClick={(e)=>onMachineSelection(e, v)}/>
                                    <label className="btn btn-outline-success" htmlFor={k}>
                                        {v.no_machine}<br></br>
                                        {v.typ_config}
                                    </label>
                                </Fragment>
                            })
                        }



                    </div>
                </div>

                <br></br>
                <div className="form-floating col-6 offset-3">
                    <select className="form-select" name="decharg" required>
                        <option value="">Choisissez...</option>
                        <option value="A la charge de B+ / Non connu">A la charge de B+ / Non connu</option>
                        <option value="A la charge de B+ / Par l’arrière">A la charge de B+ / Par l’arrière</option>
                        <option value="A la charge de B+ / Par le côté">A la charge de B+ / Par le côté</option>
                        <option value="A la charge du client / Non connu">A la charge du client / Non connu</option>
                        <option value="A la charge du client / Par l’arrière">A la charge du client / Par l’arrière</option>
                        <option value="A la charge du client / Par le côté">A la charge du client / Par le côté</option>
                    </select>
                    <label for="floatingSelect">Décharg. et manut.</label>
                </div>

                <br></br>
                <div className="form-floating mb-3 col-6 offset-3">
                    <input type="date" className="form-control" name="charg" placeholder="xxx" required/>
                    <label for="floatingInput">Date de chargement</label>
                </div>

                <br></br>
                <div className="form-floating mb-3 col-6 offset-3">
                    <input type="date" className="form-control" name="delivery" placeholder="xxx" required/>
                    <label for="floatingInput">Date de livraison</label>
                </div>

                <br></br>
                <div className="form-floating mb-3 col-6 offset-3">
                    <textarea className="form-control" name="comments" placeholder="xxx"></textarea>
                    <label for="floatingInput">Commentaires (optionnel)</label>
                </div>

                <br></br>
                <div className="text-center">
                <button className="btn btn-success" disabled={loading}>
                    {loading && <ButtonWaiting />}
                    Envoyer</button>
                </div>
            </form>
        }

    </Fragment>

}

export default DepartureRequest;