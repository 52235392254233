import axios from "axios";
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import ShareFileDao from "../../services/common/ShareFileDao";
import errorManagement from "../../services/errorManagement";
import { removeSpecialCharacters } from "../../functions/StrFunctions";
import { strictSearch } from "../../common/smallComponents.js/Filter";

const ShareFiles = (props) => {
    const [recipient, setRecipient] = useState([""]);
    const [files, setFiles] = useState([]);
    const [filesName, setFilesName] = useState([]);
    const user = JSON.parse(localStorage.getItem("user"));
    const html = "Sélectionnez un modèle en cliquant sur les drapeaux ci-dessus ou saisissez votre mail ici. <strong>Attention la balise [download_link] doit figurer quelque part dans le corps du mail</strong>.";
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(contentState))
    const [error, setError] = useState();
    const [msg, setMsg] = useState();

    const API_URL = process.env.REACT_APP_API_URL + "common/share-file";        
    const token = user && "\"Bearer " + user.accessToken + "\"";

    const chunkSize = 5 * 1024 * 1024;//its 5MB, increase the number measure in mb
    const [showProgress, setShowProgress] = useState(false)
    const [counter, setCounter] = useState(1)
    const [fileToBeUpload, setFileToBeUpload] = useState({})
    const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0)
    const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize)
    const [progress, setProgress] = useState(0)
    const [fileGuid, setFileGuid] = useState("")
    const [fileSize, setFileSize] = useState(0)
    const [chunkCount, setChunkCount] = useState(0);

    const [fileToDo, setFileToDo] = useState(0);
    const [fileDone, setFileDone] = useState(0);
    const [currentFile, setCurrentFile] = useState();

    const [sending, setSending] = useState(false);

    const progressInstance = <ProgressBar animated now={progress} label={`${progress}%`} />;

    const { t } = useTranslation('shareFiles');

    //Check if connected or has right
    useEffect(() => {
        ShareFileDao.canUpload().then(
            (response) => {

            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );
    });

    useEffect(() => {
        if (fileSize > 0) {
            fileUpload(counter);
        }
    }, [fileToBeUpload, progress])

    useEffect( () => {
        startUpload(currentFile);
    }, [fileToDo, fileDone] );


    /**
     * Add recipient
     * @param {*} e 
     */
    const addRecipient = (e) => {
        var arr = [...recipient];
        arr.push("");
        setRecipient(arr);
    }

    const onRecipientChange = (e) => {
        var key = e.target.getAttribute("data-key");
        var arr = [...recipient];
        arr[key] = e.target.value;
        setRecipient(arr);
    }

    /**
     * Remove recipient
     * @param {*} e 
     */
    const removeRecipient = (e) => {
        var key = e.target.getAttribute("data-key");
        var arr = [...recipient];
        arr = arrayRemoveElt(arr, key);
        setRecipient(arr);
    }

    /**
     * Add file to share
     * @param {*} e 
     */
    const shareFiles = (e) => {
        var arr = [...files];
        for (let i = 0; i < e.length; i++)arr.push(e[i]); 
        setFiles(arr);
     
        setFileToDo( e.length );

    }

    const startUpload = (e) => {
        if( fileDone < fileToDo ){
            resetChunkProperties();
            const _file = files[fileDone];
            setFileSize(_file.size)
            const _totalCount = _file.size % chunkSize == 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
            setChunkCount(_totalCount)
            setFileToBeUpload(_file)
            var fName = _file.name.split(".")
            const _fileID = Date.now() + "_" + removeSpecialCharacters( fName[0] ).trim() + "." + fName[1].trim();// + "." + _file.name.split('.').pop();
            setFileGuid(_fileID);
            var arr = [...filesName];
            arr.push(_fileID);
            setFilesName(arr);
        }
        
    }

    const fileUpload = () => {
        setCounter(counter + 1);
        if (counter <= chunkCount) {
            var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
            uploadChunk(chunk);
        }
    }

    const resetChunkProperties = () => {
        setShowProgress(true)
        setProgress(0)
        setCounter(1)
        setBeginingOfTheChunk(0)
        setEndOfTheChunk(chunkSize)
    }

    const uploadChunk = async (chunk) => {
        try {
            let formData = new FormData();
            formData.append("chunk", chunk);
            formData.append("id", counter);
            formData.append("fileName", fileGuid);
            formData.append("fake_header", token);

            //debugger
            const response = await axios({
                method: "post",
                url: API_URL + "/upload-chunks/v2", //Change 24-04-25
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            });

            //debugger
            const data = response.data;
           
            if (data === "ok" ) {
                setBeginingOfTheChunk(endOfTheChunk);
                setEndOfTheChunk(endOfTheChunk + chunkSize);
                
                if (counter == chunkCount) {
                    console.log('Process is complete, counter', counter)
                    await uploadCompleted();
                    setFileDone( fileDone+1 );
                } else {
                    var percentage = ((counter / chunkCount) * 100).toFixed(2);
                    setProgress(percentage);
                }
            } else {
                console.log('Error Occurred:', data.errorMessage)
            }
        } catch (error) {
            setError(errorManagement.handleError(props, error));
            scrollToTop();
        }
    }

    const uploadCompleted = async () => {
        var formData = new FormData();
        formData.append('fileName', fileGuid);
        formData.append("fake_header", token);

        const response = await axios({
            method: "post",
            url: API_URL + "/upload-complete/v2", //Change 24-04-25
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        });

        const data = response.data;
        if (data === "ok") {
            setProgress(100);

        }
    }

    /**
     * Remove file shared
     * @param {*} e 
     */
    const removeFileShared = (e) => {
        var key = e.target.getAttribute("data-key");
        var arr = [...files];
        arr = arrayRemoveElt(arr, key);
        setFiles(arr);
    }

    const changeLanguage = (e) => {
        var lang = e.target.getAttribute("data-lang");
        var html;
        var d = new Date();
        var numberOfDaysToAdd = 6;
        var expiration = d.setDate(d.getDate() + numberOfDaysToAdd);
        expiration = new Date(expiration);

        if (lang === "fr") {
            html = "Bonjour,<br><br>Veuillez télécharger les fichiers suivants depuis notre site web :<br>";
            for (var i = 0; i < files.length; i++) html += "- " + files[i].name + "<br>";
            html += "<br>Lien de téléchargement<br>[download_link]<br><br>"
            html += "Ce lien expirera le " + Moment(expiration).format("DD/MM/YYYY") + ".<br><br>"
            html += "Cordialement,<br>" + user.first_name + " " + user.last_name;
        } else {
            html = "Hello,<br><br>Please find below files available for download :<br>";
            for (var i = 0; i < files.length; i++) html += "- " + files[i].name + "<br>";
            html += "<br>";
            html += "Download link<br>[download_link]<br><br>"
            html += "This link will expire on " + Moment(expiration).format("yyyy-MM-DD") + ".<br><br>"
            html += "Thank you,<br>" + user.first_name + " " + user.last_name;
        }

        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setEditorState(() => EditorState.createWithContent(contentState));
    }


    const submit = () => {
        setError();
        setMsg();
        var mail = draftToHtml(convertToRaw(editorState.getCurrentContent()));

        if (recipient[0].length === 0) {
            setError("Veuillez saisir au moins un destinataire !");
            scrollToTop();
            return;
        }

        for (var i = 0; i < recipient.length; i++) {
            if (!isEmail(recipient[i])) {
                setError("Au moins une adresse mail est invalide !");
                scrollToTop();
                return;
            }
        }

        if (files.length === 0) {
            setError("Veuillez partager au moins un fichier !");
            scrollToTop();
            return;
        }

        if (mail.indexOf("[download_link]") === -1) {
            setError("La balise <strong>[download_link]</strong> doit figurer dans le corps du mail afin que le(s) destinataire(s) puissent cliquer.");
            scrollToTop();
            return;
        }

        setSending(true);
        //Change 24-04-25
        ShareFileDao.uploadFilesV2(recipient, filesName, mail).then(
            (response) => {
                setMsg("Le mail a été envoyé !");
                scrollToTop();
                setSending(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setSending(false);
            }
        );

        
    }

    return <Fragment>
        <h2 className="text-center">{t('file sharing')}</h2>

        {error && <ErrorMessage error={error} />}

        {msg && <SuccessMessage msg={t('has been sent')} />}

        <div className="alert alert-info col-6 offset-3 fw-bold text-center">
            {t('will receive')}
        </div>

        <ul className="list-group col-6 offset-3">
            <li className="list-group-item disabled fw-bold" aria-disabled="true">{t('recipients')}</li>
            {
                recipient.map(
                    (v, k) =>
                        <li className="list-group-item" key={"rec" + k} style={{ display: "flex" }}>
                            <input type="text" className="form-control" data-key={k}
                                placeholder="Saisir l'adresse mail" value={v} onChange={onRecipientChange} />
                            {
                                k > 0 && <img src={"/common/remove_icon.png"} onClick={removeRecipient}
                                    style={{ width: "30px" }} data-key={k}></img>
                            }
                        </li>
                )
            }
        </ul>

        <div className="col-6 offset-3">
            <img src={"/common/add-logo.png"} title="Ajouter une adresse" onClick={addRecipient}></img>
        </div>

        <br></br>

        <div className="col-6 offset-3">
            <h4 className="text-center">{t('choose the files')}</h4>
            <FileUploader handleChange={shareFiles} name="file" multiple={true} />
            <br></br>
            <h4 className="text-center">{t('uploaded')} {fileDone} / {fileToDo}</h4>
            
            { showProgress && progressInstance}
            <br></br>
            {
                filesName.length > 0 &&
                <Fragment>
                    <ul className="list-group">
                        <li className="list-group-item disabled fw-bold">{t('shared files')}</li>
                        {
                            filesName.map(
                                (v, k) =>
                                    <li className="list-group-item" key={"file" + k} style={{ display: "flex" }}>
                                        {v}
                                        <img src={"/common/remove_icon.png"} data-key={k} onClick={removeFileShared}
                                            style={{ width: "30px", display: "block", marginLeft: "auto" }}></img>
                                    </li>
                            )
                        }
                    </ul>
                </Fragment>


            }
        </div>

        <br></br>

        <div className="col-6 offset-3">
            <h4 className="text-center">{t('type you body mail')}</h4>
            <div className="text-center">
                <img src={"/common/en_lang.png"} title="Corps de mail en anglais" data-lang="en" onClick={changeLanguage}></img>
                <img src={"/common/fr_lang.png"} title="Corps de mail en français" data-lang="fr" onClick={changeLanguage}></img>
            </div>
            <br></br>
            <Editor editorState={editorState}
                onEditorStateChange={setEditorState}
                wrapperClassName="draftEditor-wrapper" editorClassName="draftEditor-editor" />
        </div>
        <br></br>
        
        {
            (fileDone > 0 && fileDone >= fileToDo) &&
                <div className="text-center">
                    <button className="btn btn-success" onClick={submit} disabled={sending}>
                        { sending && <ButtonWaiting  /> }
                        {t('send')}
                    </button>
                </div>
        }
        
    </Fragment>

}

export default ShareFiles;