import i18next from "i18next";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import SignaturePad from "react-signature-canvas";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import Table from "../../common/smallComponents.js/Table";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { arrayRemoveElt, getKeyInt } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import { extractValueFromTag } from "../../functions/StrFunctions";
import CheckList from "../../services/customClient/CheckList";
import errorManagement from "../../services/errorManagement";
import { checkAllFilled } from "./common/MaintenanceFunctions";
import NC from "./common/NC";

const MaintenanceRecap = (props) => {
    const { t } = useTranslation(['check-list', 'common']);
    const [list, setList] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [msg, setMsg] = useState();
    const [machines, setMachines] = useState([]);
    const [progress, setProgress] = useState(-1);
    const [forms, setForms] = useState();
    const [nc, setNc] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const [client, setClient] = useState();
    const [signing, setSigning] = useState(false);
    const [sigSeeImg, setsigSeeImg] = useState();
    const [sigClientImg, setsigClientImg] = useState();

    const sigSee = useRef({});
    const sigClient = useRef({});

    const columns = useMemo(
        () => [
            {
                Header: "Check",
                accessor: "xxx",
                Cell: (props) =>
                    <Fragment>{
                        !props.row.original.deleted &&
                        <input type="checkbox" data-machine={props.row.original.mach_nb.trim()}
                            data-date={props.row.original.control_date} data-id={props.row.id}
                            defaultChecked={props.row.original.checked} onClick={addMachine}></input>
                    }</Fragment>
            },
            {
                Header: "Machine",
                accessor: "mach_nb"
            },
            {
                Header: "Control date",
                accessor: "control_date"
            },
            {
                Header: "Controller",
                accessor: "controller"
            },
            {
                Header: "",
                accessor: "xxxx",
                Cell: (props) =>
                    <Fragment>
                        {
                            !props.row.original.deleted &&
                            <button className="btn btn-outline-danger" onClick={deleteForm}
                                data-machine={props.row.original.mach_nb.trim()}
                                data-date={props.row.original.control_date} data-id={props.row.id}
                                disabled={props.row.original.deleting}>
                                {props.row.original.deleting && <ButtonWaiting />}
                                {t('delete', { ns: 'check-list' })}
                            </button>
                        }
                        {
                            props.row.original.deleted &&
                            <span>Removed</span>
                        }
                    </Fragment>
            }
        ], [machines, list]
    );

    useEffect(() => {
        CheckList.getPendingForms().then(
            (response) => {
                setList(response)
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        );
    }, []);

    const addMachine = (e) => {
        var id = e.target.getAttribute("data-id");
        var machine = e.target.getAttribute("data-machine");
        var date = e.target.getAttribute("data-date");

        var arr = [...machines];
        var arrList = [...list];

        if (e.target.checked) {
            arr.push({ machine: machine, date: date, id: id });
            arrList[id].checked = true;
        } else {
            arr = arrayRemoveElt(arr, getKeyInt(arr, "id", id));
            delete arrList[id].checked;
        }

        setList(arrList);
        setMachines(arr);
    }


    const getForms = async (e) => {
        setLoading(true);
        setForms([]);
        setProgress(0);
        var compil = new Array();

        for (let i = 0; i < machines.length; i++) {

            await CheckList.getCheckListForm(machines[i].machine, i18next.language, machines[i].date).then(
                (response) => {
                    compil.push({
                        machine: response.machine,
                        list: response.form
                    });
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    setLoading(false);
                    scrollToTop();
                }
            );

            setProgress((((i + 1) / machines.length) * 100).toFixed(0));
        }

        setForms(compil);

        var err = "";
        compil.map(v => { err += checkAllFilled(t, v.list); })

        if (err !== "") {
            setError(err);
            scrollToTop();
            setLoading(false);
            return;
        } else {
            getNonConformities(compil)
        }

        setLoading(false);

    }

    function getNonConformities(compil) {
        var machines = new Array();
        var curChapter = -1;

        compil.map(v => {
            //Set the machine
            var machine = { machine: v.machine }

            var chapters = new Array();
            var nc;
            var chap;
            //For each form question
            v.list.map(v2 => {
                if (parseInt(v2.chapter) !== parseInt(curChapter)) {
                    //If a new chapter save the previous (except if we are starting)
                    if (curChapter !== -1) {
                        chap.nc = nc;
                        chapters.push(chap);
                    }

                    //Start a new chapter
                    chap = new Object();
                    nc = new Array();
                    chap.chapter = v2.chapter;
                    chap.label = v2.chapter_label;
                    chap.notes = extractValueFromTag(v2.value1, "notes");
                    chap.changed = extractValueFromTag(v2.value1, "changed");
                    chap.toChange = extractValueFromTag(v2.value1, "toChange");
                    curChapter = parseInt(v2.chapter);
                }

                //Save non conformity
                if (v2.conform === 0) nc.push(v2);

            })


            curChapter = -1;
            chap.nc = nc;
            chapters.push(chap);

            //Set array of chapters in machine
            machine.chapters = chapters;
            machines.push(machine);
        });

        setNc(machines);
    }



    const send = () => {
        var err = "";
        if (!client || client === "") err = "Client name is required<br>";
        if (sigClient.current.isEmpty()) err += "Client signature is required<br>";
        if (sigSee.current.isEmpty()) err += "SEE signature is required";

        if (err !== "") {
            setError(err);
            scrollToTop();
            return;
        }

        var sigClientImg = sigClient.current.getTrimmedCanvas().toDataURL("image/png");
        var sigSeeImg = sigSee.current.getTrimmedCanvas().toDataURL("image/png");
        var signatures = "<see>" + sigSeeImg + "</see><client>" + sigClientImg + "</client>";

        setSigning(true);
        CheckList.validateChckList(machines, signatures).then(
            () => {
                setMsg("Sent");
                setsigSeeImg(sigSeeImg);
                setsigClientImg(sigClientImg);
                scrollToTop();
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );
    }

    const clear = (e) => {
        if (e.target.getAttribute("data-sign") === "see") {
            sigSee.current.clear();
        } else {
            sigClient.current.clear();
        }
    }

    const deleteForm = (e) => {
        var machine = e.target.getAttribute("data-machine");
        var date = e.target.getAttribute("data-date");
        var id = e.target.getAttribute("data-id");

        confirmAlert({
            title: t('form deletion', { ns: 'check-list' }),
            message: t('confirm deletion', { ns: 'check-list' }).replace(":machine", machine).replace(":date", date),
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        var arr = [...list];
                        arr[id].deleting = true;
                        setList(arr);

                        CheckList.deleteForm(machine, date).then(
                            () => {
                                arr = [...list];
                                delete arr[id].deleting;
                                arr[id].deleted = true;
                                setList(arr);
                            },
                            (error) => {
                                setError(errorManagement.handleError(props, error));
                                scrollToTop();
                            }
                        );


                    }
                },
                {
                    label: "No"
                }
            ]
        });

    }


    return <Fragment>
        <h4 className="text-center">{t('overview', { ns: 'check-list' })}</h4>
        {forms && <div className="text-center">
            <a href="#" onClick={() => window.location.reload()}>Cancel</a>
        </div>}

        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}
        {loading && <WaitingRoundSnippers />}

        {(!forms && list) && <Table columns={columns} data={list} />}

        {(progress >= 0 && progress < 100) && <Fragment>
            <div className="col-8 offset-2">
                <ProgressBar animated now={progress} label={`${progress}%`} />
                <br></br>
            </div>
        </Fragment>
        }

        {
            (!forms && machines.length > 0) && <div className="text-center">
                <button className="btn btn-secondary" onClick={getForms}>Start</button>
            </div>
        }

        {
            nc && <Fragment>
                <NC nc={nc} />

                <br></br>
                <table className="table text-center">
                    <thead>
                        <tr >
                            <th scope="col"></th>
                            <th scope="col">Sealed Air</th>
                            <th scope="col">Client</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">{t('last name', { ns: 'common' })}</th>
                            <td>{user.last_name + " " + user.first_name}</td>
                            <td>
                                <input className="form-control" onChange={(e) => setClient(e.target.value)}></input>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Signature</th>
                            <td>
                                <SignaturePad ref={sigSee} canvasProps={{ className: "signatureCanvas" }} />
                                <br></br>
                                <button className="btn btn-outline-secondary" data-sign="see" onClick={clear}>
                                    {t('clear', { ns: 'check-list' })}
                                </button>
                            </td>
                            <td>
                                <SignaturePad ref={sigClient} canvasProps={{ className: "signatureCanvas" }} />
                                <br></br>
                                <button className="btn btn-outline-secondary" data-sign="client" onClick={clear}>
                                    {t('clear', { ns: 'check-list' })}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                {
                    !sigClientImg &&
                    <div className="text-center mb-3">
                        <button className="btn btn-outline-success" onClick={send} disabled={signing}>
                            {signing && <ButtonWaiting />}
                            {t('send', { ns: 'check-list' })}
                        </button>
                    </div>
                }
            </Fragment>
        }
    </Fragment>

}

export default MaintenanceRecap;