import { Fragment, useEffect, useState } from "react";
import { getDeviceType, getHeight, scrollToTop } from "../../../functions/PageAnimation";
import { getKey, getKeyString } from "../../../functions/ArrayFunctions";
import { confirmAlert } from "react-confirm-alert";
import CheckList from "../../../services/customClient/CheckList";
import errorManagement from "../../../services/errorManagement";
import { createIptStr, extractName, extractValue, getBase64 } from "../../../functions/StrFunctions";
import { getTxtAreaName, getTxtAreaValue, writeTxtAreaValue } from "../../../functions/FormCtrl";
import Camera, { FACING_MODES } from "react-html5-camera-photo";

const CheckListForm = (p) => {
    const [subset, setSubset] = useState();
    const [curSubset, setCurSubset] = useState();
    const [margerSense, setMargerSense] = useState();
    const [list, setList] = useState();
    const [form, setForm] = useState();
    const [sense, setSense] = useState();
    const [response, setResponse] = useState();
    const [controller, setController] = useState();
    const [ctrlDate, setCtrlDate] = useState();
    const [status, setStatus] = useState();
    const [deviceType] = useState(getDeviceType());
    const [changeDone, setChangeDone] = useState(false);
    const [loading, setLoading] = useState(false);
    const [takeAPhoto, setTakeAPhoto] = useState(false);
    const [dataPhoto, setDataPhoto] = useState();
    const [keyPhoto, setKeyPhoto] = useState();
    const [showPhoto, setshowPhoto] = useState();

    const [showHelp, setShowHelp] = useState(false);

    useEffect(() => {
        setGlobalStatus();
        getDistinctSubset();
    }, []);

    useEffect(() => {
        if (p.form) {
            changeSubset(null, p.form.form[0].chapter_label);
            setGlobalStatus();
            getDistinctSubset();
        }
    }, [p.tab])

    {/** chenge subset */ }
    const changeSubset = (e, subset) => {
        var se = subset || e.target.value;

        setCurSubset(se);

        if (se === "") { setForm(); return; }

        var arr = new Array();
        var found = false;

        p.form.form.map(v => {
            if (v.chapter_label.trim() === se.trim()) {
                setMargerSense(v.value2)
                arr.push(v);
            } else if (found) { return; }
        });

        setController(arr[0].ctrl_name);
        setCtrlDate(arr[0].control_date);

        setForm(arr);

        if(document.getElementById('integrationForm'))
            document.getElementById('integrationForm').scrollTop = 0;
    }

    {/** */ }
    const setGlobalStatus = () => {
        var status = "Terminé";
        p.form.form.map(v => {
            if (parseInt(v.conform) === 0) {
                status = "Non conforme";
                return;
            } else if (v.conform === null && status !== "Non conforme") {
                status = "En cours";
            }
        });

        setStatus(status);
    }

    {/** */ }
    function getDistinctSubset() {
        var arr = new Array();
        var o, key;
        var label;

        p.form.form.map(v => {
            key = getKey(arr, "chapter", v.chapter);
            if (key === null) {

                o = {
                    "chapter": v.chapter,
                    "label": v.chapter_label,
                    "conform": v.conform === 1 ? 1 : 0,
                    "no_conform": v.conform === 0 ? 1 : 0,
                    "cpt": 1
                }
                arr.push(o);
            } else {
                arr[key].cpt++;
                if (v.conform === 1) arr[key].conform++;
                if (v.conform === 0) arr[key].no_conform++;
            }
        })

        setSubset(arr);
    }

    {/** */ }
    const onSubsetChoose = (e) => {
        var se = e.target.value;
        if (changeDone) {
            confirmAlert({
                title: "Confirmation",
                message: "Des changements n'ont pas été sauvegardés, voulez-vous quitter sans enregistrer ?",
                buttons: [
                    {
                        label: "Oui",
                        onClick: () => { setChangeDone(false); changeSubset(null, se) }
                    },
                    {
                        label: "Non",
                        onClick: () => { document.getElementById("subsetChoice").value = curSubset; return; }
                    }
                ]
            });
        } else {
            changeSubset(e);
        }
    };

    {/** */ }
    const onClickConform = (conform, k) => {
        //Key of parent form
        var pK = form[k].question - 1;

        // Update parent form
        var o = { ...p.form }
        var arr = [...o.form];
        arr[pK].conform = conform;
        o.form = arr;
        p.setForm(o);

        setChangeDone(true);
    }

    const onSubmit = () => {
        // Save
        setLoading(true);
        CheckList.saveAnyForm(p.machineInfo.no_machine, p.form.date, p.form.form, p.typeForm, null).then(
            () => {
                setChangeDone(false);
                setLoading(false);
            }, (error) => {
                p.setError(errorManagement.handleError(p, error));
                setLoading(false);
                scrollToTop();
            }
        )

        var idx = getKeyString(subset, "label", curSubset);

        if (idx < subset.length - 1) {
            changeSubset(null, subset[idx + 1].label)
        } else {
            setCurSubset();
            setForm();
        }

        document.getElementById('integrationForm').scrollTop = 0;
    }

    /**
* Open photo device
* @param {*} e 
*/
    const takePhoto = (e) => {
        setKeyPhoto(parseInt(e.target.getAttribute("data-key")));
        setTakeAPhoto(true);
    }

    /**
     * On choose file
     * @param {*} e 
     * @returns 
     */
    const onChooseFile = (e) => {
        if (e.target.files.length === 0)
            return;

        var arr = [...form];
        var k = e.target.getAttribute("data-key");
        getBase64(e.target.files[0], (res) => {
            arr[k].photo = res
            setForm(arr);
        });
    }

    /**
     * Close photo device
     */
    const cancelPhoto = () => {
        setTakeAPhoto(false);
    }

    /**
     * Save the photo
     * @param {*} data 
     */
    const handlePhoto = (data) => {
        var arr = [...form];
        arr[keyPhoto].photo = data
        setForm(arr);

        setKeyPhoto();
        setTakeAPhoto(false);
    }

    /**
     * Delete the taken photo or uploaded img
     */
    const deletePhoto = (e) => {
        var arr = [...form];
        delete arr[showPhoto.key].photo;
        setForm(arr);
        setshowPhoto();
    }


    /**
     * 
     * @param {*} e 
     */
    const displayPhoto = (e) => {
        var k = e.target.getAttribute("data-key");
        setshowPhoto({
            key: k,
            data: form[k].photo
        });
    }

    {/** Update who what when */ }
    const updateWww = (e, field, k) => {
        var val = e.target.value;
        var arr = [...form];
        arr[k][field] = val;
        setForm(arr);
    }

    /**
     * 
     * @param {*} e 
     */
    const changeTxtArea = (e) => {
        var key = e.target.getAttribute("data-key");
        var name = e.target.getAttribute("name");
        var value = e.target.value;

        var arr = [...form];
        form[key].textarea = writeTxtAreaValue(name, value);

        setForm(arr);
    }

    /**
     * 
     * @param {*} e 
     */
    const changeIptArea = (e) => {
        var key = e.target.getAttribute("data-key");
        var key2 = e.target.getAttribute("data-key2");
        var value = e.target.value;

        var arr = [...form];
        var ipt = form[key].inputs[key2];
        arr[key].inputs[key2] = createIptStr(ipt, value);

        setForm(arr);
    }

    const onCheck = (e, k, k2) => {
        var arr = [...form];
        var chk = arr[k].checkboxs;


        for (let i = 0; i < chk.length; i++) {
            if (i === k2) {
                if (e.target.checked) {
                    chk[i] = chk[i].substring(chk[i], chk[i].indexOf(">")) + "checked "
                        + chk[i].substring(chk[i].indexOf(">"), chk[i].length)
                } else {
                    chk[i] = chk[i].replace("checked ", "");
                }
            }
        }


        setForm(arr);
    }

    function isChecked(val) {
        return val.indexOf("checked") !== -1;
    }

    const onCheckOpt = (e, k, k2) => {
        var arr = [...form];

        var opt = arr[k].options;

        for (let i = 0; i < opt.length; i++) {
            if (i === k2) {
                opt[i] = opt[i].substring(opt[i], opt[i].indexOf(">")) + "checked "
                    + opt[i].substring(opt[i].indexOf(">"), opt[i].length)
            } else {
                opt[i] = opt[i].replace("checked ", "");
            }
        }

        setForm(arr);
    }

    return <Fragment>
        <div>
            { /** SUBSET SUMMARY */
                subset && <Fragment>
                    <div className="input-group mb-3">
                        {status &&
                            <span style={{ maxWidth: "10px" }} className={"form-select text-center fw-bold "
                                + (status === "Non conforme" ? "bg-danger bg-gradient text-white" :
                                    status === "Terminé" ? "bg-success bg-gradient" : "bg-warning bg-gradient")}></span>
                        }

                        <select className="form-select text-center fw-bold " id="subsetChoice"
                            onChange={onSubsetChoose} value={curSubset ? curSubset.trim() : ""}>
                            <option value="">Choisissez le sous-ensemble</option>
                            {subset.map((v, k) =>
                                <option key={"se" + k}
                                    className={v.no_conform > 0 ? "bg-danger bg-gradient text-white" :
                                        v.conform === v.cpt ? "bg-success bg-gradient text-white" :
                                            v.conform > 0 ? "bg-warning bg-gradient" : ""}
                                    value={v.label.trim()}>{v.label}</option>)}
                        </select>

                        <span className="input-group-text">Contrôle</span>
                        {response && <input type="date" className="form-control text-center fw-bold"
                            value={ctrlDate} readOnly />}

                        {controller &&
                            <Fragment>
                                <span className="input-group-text">Contrôleur</span>
                                <input type="text" className="form-control text-center fw-bold"
                                    value={controller} readOnly />
                            </Fragment>}


                        <select className="form-select text-center fw-bold">
                            <option>Légende</option>
                            <option className="bg-danger bg-gradient text-white">Au moins une non conformité signalée</option>
                            <option className="bg-warning bg-gradient">En cours de contrôle sans non conformité</option>
                            <option className="bg-success bg-gradient text-white">Tout est conforme</option>
                            <option>Non commencé</option>
                            <option style={{ color: "red" }}>Libellé contrôle en rouge: a été non-conforme autrefois</option>
                        </select>

                    </div>


                </Fragment>}

            { /** FORM TO COMPLETE */
                form && <div>
                    <ul className="list-group overflow-auto" id="integrationForm"
                        style={{ height: getHeight("integrationForm", 80) }}>
                        {
                            form.map(
                                (v, k) => {

                                    return <Fragment key={"form" + k}>
                                        {(k === 0 && v.rubric_label !== '') &&
                                            <li key={"rq" + k} className="list-group-item fw-bold bg-secondary bg-gradient text-white">
                                                {v.rubric_label}
                                            </li>
                                        }

                                        {
                                            (k > 0 && v.rubric_label !== '' && form[k - 1].rubric_label !== v.rubric_label) &&
                                            <li key={"rq" + k} className="list-group-item fw-bold bg-secondary bg-gradient text-white">
                                                {v.rubric_label}
                                            </li>
                                        }
                                        <li key={"q" + k} className="list-group-item">
                                            <div className="question">
                                                <div className="left-side" style={{ width: "170px" }} >
                                                    {
                                                        v.hasConform === 1 && <Fragment>
                                                            {/** Conform button */}
                                                            <input type="radio" className="btn-check"
                                                                data-key={k} data-conform="true"
                                                                checked={v.conform !== null && v.conform}
                                                                onChange={() => onClickConform(1, k)} />
                                                            <label className="btn btn-outline-success"
                                                                htmlFor={"q_" + v.question + "_c"} data-conform="true"
                                                                data-key={k} onClick={() => onClickConform(1, k)}>
                                                                {/* deviceType === "desktop" ? "Conform" : */}
                                                                <img src="/common/good_blck.png" style={{ width: "30px" }} data-key={k} />
                                                            </label>

                                                            {/** Non conform button */}
                                                            <input type="radio" className="btn-check"
                                                                data-key={k} data-conform="false"
                                                                checked={v.conform !== null && !v.conform}
                                                                onChange={() => onClickConform(0, k)} />
                                                            <label className="btn btn-outline-danger" data-conform="false"
                                                                data-key={k} htmlFor={"q_" + v.question + "_nc"}
                                                                onClick={() => onClickConform(0, k)}>
                                                                {/* deviceType === "desktop" ? "Non conform" : */}
                                                                <img src={"/common/not_good_blck.png"} data-key={k} style={{ width: "30px" }} />
                                                            </label>
                                                        </Fragment>
                                                    }
                                                    { /** Cancel conform answer button */
                                                        v.conform !== null &&
                                                        <button type="button" className="btn-close" onClick={() => onClickConform(null, k)}
                                                            data-key={k} aria-label="Close"></button>
                                                    }

                                                </div>

                                                <div className="right-side">
                                                    {v.question})&nbsp;
                                                    <span style={{ color: (v.conform && v.conform === 1 && v.hasBeenNC === 1) ? "red" : "black" }}
                                                        dangerouslySetInnerHTML={{ __html: v.question_label }}></span>

                                                    {/** Text area */
                                                        (v.textarea !== null && v.textarea !== "") &&
                                                        <div>
                                                            <textarea className="form-control" name={getTxtAreaName(v.textarea)}
                                                                value={getTxtAreaValue(v.textarea)} data-key={k}
                                                                onChange={changeTxtArea}></textarea>
                                                        </div>
                                                    }

                                                    {/** inputs */
                                                        (v.inputs !== null && v.inputs.length > 0) &&
                                                        <div>
                                                            {
                                                                v.inputs.map((v2, k2) => {
                                                                    return <div className="form-floating mb-3" key={"ipt" + k2}>
                                                                        <input type="text" className="form-control" id={"ipt" + k + "_" + k2}
                                                                            name={extractName(v2)} value={extractValue(v2)} placeholder="xxx" data-key={k}
                                                                            data-key2={k2} onChange={changeIptArea} />
                                                                        <label htmlFor={"ipt" + k + "_" + k2}>{extractName(v2)}</label>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    }

                                                    {/** Checkbox */
                                                        (v.checkboxs !== null && v.checkboxs.length > 0) &&
                                                        <div>
                                                            {
                                                                v.checkboxs.map((v2, k2) => {
                                                                    return <div className="form-check" key={"chk" + k2}>
                                                                        <input className="form-check-input" type="checkbox" data-key={k2}
                                                                            onChange={(e) => onCheck(e, k, k2)} checked={isChecked(v2)} />
                                                                        <label className="form-check-label" for="flexCheckDefault">
                                                                            {extractValue(v2)}
                                                                        </label>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    }

                                                    {/** Options */
                                                        (v.options !== null && v.options !== "") &&
                                                        <div>
                                                            {
                                                                v.options.map((v2, k2) => {
                                                                    return <div className="form-check" key={"ipt" + k2}>
                                                                        <input type="radio" className="form-check-input" data-key={k}
                                                                            data-key2={k2} name={extractName(v2)} checked={isChecked(v2)}
                                                                            onChange={(e) => onCheckOpt(e, k, k2)} />
                                                                        <label htmlFor={"ipt" + k + "_" + k2}
                                                                            className="form-check-label">{extractValue(v2)}</label>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    }

                                                    {/** Photos */
                                                        v.hasPhoto === 1 && <Fragment>
                                                            {
                                                                v.photo &&
                                                                <div>
                                                                    <img src={v.photo} style={{ width: "50px" }} data-key={k} onClick={displayPhoto}></img>
                                                                </div>
                                                            }

                                                            {
                                                                !v.photo && <Fragment>
                                                                    <div style={{ display: "flex", marginBottom: "10px" }}>
                                                                        {
                                                                            !takeAPhoto &&
                                                                            <Fragment  >
                                                                                <button type="button" className="custom-file-upload photo" style={{ marginLeft: "10px" }}
                                                                                    data-key={k} onClick={takePhoto}></button>

                                                                                <label className="custom-file-upload folder" style={{ marginLeft: "10px", marginRight: "auto" }}>
                                                                                    <input type="file" accept="image/*" data-key={k} onChange={onChooseFile} />
                                                                                </label>
                                                                            </Fragment>
                                                                        }


                                                                    </div>


                                                                    {dataPhoto &&
                                                                        <div className="text-center" style={{ marginBottom: "20px" }}>
                                                                            <img src={dataPhoto} style={{ maxWidth: getDeviceType() === "mobile" ? "300px" : "500px" }} />
                                                                            <br></br>
                                                                            <button type="button" style={{ marginTop: "10px", marginLeft: "10px" }} className="btn btn-danger" onClick={deletePhoto}>Supprimer</button>
                                                                        </div>
                                                                    }
                                                                </Fragment>
                                                            }

                                                        </Fragment>
                                                    }

                                                    {/** who what when */
                                                        v.conform === 0 && <div className="input-group mt-1">
                                                            <input className="form-control" placeholder="Cause" defaultValue={v.what}
                                                                onChange={(e) => updateWww(e, "what", k)} style={{ minWidth: "100px" }}></input>

                                                            <input className="form-control" placeholder="Action" defaultValue={v.who}
                                                                onChange={(e) => updateWww(e, "who", k)} style={{ minWidth: "100px" }}></input>

                                                            <input type="date" className="form-control" placeholder="Délai" defaultValue={v.when}
                                                                onChange={(e) => updateWww(e, "when", k)} style={{ minWidth: "100px" }}></input>

                                                            <select className="form-select" placeholder="Délai" defaultValue={v.level}
                                                                onChange={(e) => updateWww(e, "level", k)} style={{ minWidth: "100px" }}>
                                                                <option value="">Choose...</option>
                                                                <option value="1">Niveau 1</option>
                                                                <option value="2">Niveau 2</option>
                                                                <option value="3">Niveau 3</option>
                                                            </select>

                                                            <input className="form-control" type="date" defaultValue={v.resolution}
                                                                onChange={(e) => updateWww(e, "resolution", k)} style={{ minWidth: "100px" }}></input>

                                                            <button className="btn btn-outline-secondary">
                                                                <img src="/common/question-mark.png" style={{ width: "25px" }}
                                                                    onClick={() => setShowHelp(true)} />
                                                            </button>
                                                        </div>
                                                    }

                                                    {/** Additional display */
                                                        p.addDisplay && p.addDisplay[v.question] &&
                                                        <div>{p.addDisplay[v.question]}</div>

                                                    }

                                                </div>


                                            </div>
                                        </li>

                                    </Fragment>
                                }
                            )
                        }
                    </ul>

                    <br></br>

                    <div className="text-center">
                        <button className="btn btn-success" onClick={onSubmit} disabled={loading}>
                            {loading && <span className="spinner-border spinner-border-sm"></span>}
                            Enregistrer
                        </button>
                    </div>

                    {takeAPhoto &&
                        <div className="custom-tooltip">

                            <button type="button" className="btn btn-info" onClick={cancelPhoto}>Annuler</button>

                            <br></br>

                            <Camera idealFacingMode={FACING_MODES.ENVIRONMENT} onTakePhoto={(data) => { handlePhoto(data) }} />
                        </div>
                    }

                    {
                        showPhoto && <div className="custom-tooltip" >
                            <button type="button" className="btn-close"
                                aria-label="Close" onClick={() => setshowPhoto()}></button>
                            <img src={showPhoto.data} style={{ width: "100%", height: "100%" }}></img>
                            <button style={{ marginLeft: "10px" }} onClick={deletePhoto}
                                className="btn btn-outline-danger">Delete</button>
                        </div>
                    }

                    {
                        showHelp && <div className="custom-tooltip-v2">
                            <div className="modal-body blue-purple-bg xl p-2">
                                <button type="button" className="btn-close" onClick={() => setShowHelp(false)}></button>
                                <div className="overflow-y-auto">
                                    <h4 className="mb-3">Plan d'action</h4>
                                    <p>Les données à remplir sont respectivement:</p>
                                    <ul>
                                        <li>Personne ou service concerné</li>
                                        <li>Action à mener</li>
                                        <li>Date prévisionnelle de résolution</li>
                                        <li>Niveau de criticité</li>
                                        <li>Date réelle de résolution</li>
                                    </ul>
                                    <br></br>

                                    <h4 className="mb-3">Niveaux de criticité</h4>
                                    <h5>Niveau 1</h5>
                                    <ul>
                                        <li>Défaut mineur facile à corriger</li>
                                        <li>N’affecte pas les fonctionnalités et n’affectera pas les
                                            performances de la machine</li>
                                        <li>Ceux-ci seront comptés comme une machine sans problème mais
                                            le résultat FPY et les ajustements doivent être documentés.</li>

                                    </ul>

                                    <h5>Niveau 2</h5>
                                    <ul>
                                        <li>Les défaut majeurs-significatifs sont identifiés
                                            et nécessite des actions et la cause doit être recherchée.</li>
                                    </ul>

                                    <h5>Niveau 3</h5>
                                    <ul>
                                        <li>Critique. La machine échoue aux tests de sécurité ou de fonctionnement 
                                            ou de performance et la cause première doit être recherchée.</li>
                                    </ul>


                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>


    </Fragment>

}

export default CheckListForm;