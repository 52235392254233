import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./App.css";
import "./css/common/InputPhone.css";
import "./css/common/common-print.scss";
import "./css/common/custom-btn.scss";
import "./css/common/div.scss";
import "./css/common/divider.scss";
import "./css/common/side-navbar.css";
import "./css/common/table.scss";
import "./css/fah/fahPc.scss";
import "./css/supplyChain/strorehouse.scss";
import "./functions/SideNav.js";
import i18n from './i18n';

import BoardAdmin from "./components/BoardAdmin";
import BoardModerator from "./components/BoardModerator";
import BoardUser from "./components/BoardUser";
import Home from "./components/Home";
import Profile from "./components/Profile";
import Confirmation from "./components/auth/Confirmation";
import ForgottenPassword from "./components/auth/ForgottenPassword";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";

import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";

import { history } from "./helpers/history";

// import AuthVerify from "./common/AuthVerify";
import { useTranslation } from "react-i18next";
import ScrollToTop from "react-scroll-to-top";
import LocaleContext from "./LocaleContext";
import EventBus from "./common/EventBus";
import Admin from "./common/smallComponents.js/navbar/Admin";
import Articles from "./common/smallComponents.js/navbar/Articles";
import BOM from "./common/smallComponents.js/navbar/BOM";
import BusinessAndMachine from "./common/smallComponents.js/navbar/BusinessAndMachine";
import CustomerClient from "./common/smallComponents.js/navbar/CustomerClient";
import FAH from "./common/smallComponents.js/navbar/FAH.js";
import Manufacturing from "./common/smallComponents.js/navbar/Manufacturing";
import ODMnav from "./common/smallComponents.js/navbar/ODMnav.js";
import ProfileNav from "./common/smallComponents.js/navbar/ProfileNav";
import Purchase from "./common/smallComponents.js/navbar/Purchase";
import Quality from "./common/smallComponents.js/navbar/Quality.js";
import Reports from "./common/smallComponents.js/navbar/Reports";
import Storehouse from "./common/smallComponents.js/navbar/Storehouse";
import Subcontracting from "./common/smallComponents.js/navbar/Subcontracting";
import SupplyChain from "./common/smallComponents.js/navbar/SupplyChain";
import Tickets from "./common/smallComponents.js/navbar/Tickets";
import Tools from "./common/smallComponents.js/navbar/Tools.js";
import Article from "./components/articles/Article";
import ArticleStock from "./components/articles/ArticleStock";
import CreateArticle from "./components/articles/CreateArticle";
import CreateNeeds from "./components/articles/CreateNeeds";
import CreationRequests from "./components/articles/CreationRequests.js";
import DisplayKiNeeds from "./components/articles/DisplayKiNeeds";
import DisplayNeeds from "./components/articles/DisplayNeeds";
import DisposablesArticlesMgt from "./components/articles/DisposablesArticlesMgt";
import Kit from "./components/articles/Kit";
import KitList from "./components/articles/KitList";
import ListKitNeeds from "./components/articles/ListKitNeeds";
import ListNeeds from "./components/articles/ListNeeds";
import SearchArticle from "./components/articles/SearchArticle";
import ToThrowProposals from "./components/articles/ToThrowProposals";
import ValidateThrow from "./components/articles/ValidateThrow";
import LogOut from "./components/auth/LogOut";
import RegisterInterim from "./components/auth/RegisterInterim.js";
import ResetPassword from "./components/auth/ResetPassword";
import BomDetail from "./components/bom/Bom";
import BomList from "./components/bom/BomList";
import BomReview from "./components/bom/BomReview.js";
import ReadBom from "./components/bom/ReadBom";
import Annoucement from "./components/common/Annoucement.js";
import DownloadFiles from "./components/common/DownloadFiles";
import FocusLicense from "./components/common/FocusLicense.js";
import ListOdm from "./components/common/ListOdm.js";
import ODMRequest from "./components/common/ODMRequest.js";
import ShareFiles from "./components/common/ShareFiles";
import SiteMap from "./components/common/SiteMap.js";
import Test from "./components/common/Test.js";
import Toolbox from "./components/common/Toolbox.js";
import Commissioning from "./components/customClient/Commissioning.js";
import CustomInvoice from "./components/customClient/CustomInvoice.js";
import FAT from "./components/customClient/FAT.js";
import HSTlist from "./components/customClient/HSTlist";
import HTS from "./components/customClient/HTS";
import IncidentTicket from "./components/customClient/IncidentTicket.js";
import MaintenanceCheck from "./components/customClient/MaintenanceCheck";
import MaintenanceCheckList from "./components/customClient/MaintenanceCheckList";
import MaintenanceRecap from "./components/customClient/MaintenanceRecap";
import MaintenanceRecapPublic from "./components/customClient/MaintenanceRecapPublic";
import Picking from "./components/customClient/Picking.js";
import PickingAlerts from "./components/customClient/PickingAlerts.js";
import PickingList from "./components/customClient/PickingList.js";

import MissingTrigram from "./components/fah/MissingTrigram";

import AccountantV2 from "./components/fahV2/Accountant.js";
import InstallerV2 from "./components/fahV2/Installer";
import ManagerV2 from "./components/fahV2/Manager.js";
import MissingTrigramV2 from "./components/fahV2/MissingTrigram";
import MyFahTrackingV2 from "./components/fahV2/MyFahTracking.js";



import Request from "./components/common/Request.js";
import GenerateSparesBom from "./components/customClient/GenerateSparesBom.js";
import Accountant from "./components/fahV2/Accountant.js";
import BillMachines from "./components/machines/BillMachines.js";
import BillMaintenance from "./components/machines/BillMaintenance.js";
import BillSpares from "./components/machines/BillSpares.js";
import BusinessBudget from "./components/machines/BusinessBudget.js";
import BusinessCard from "./components/machines/BusinessCard";
import ContractReview from "./components/machines/ContractReview.js";
import GeneralPlanning from "./components/machines/GeneralPlanning.js";
import MachineCard from "./components/machines/MachineCard";
import MachinePwd from "./components/machines/MachinePwd.js";
import MySpace from "./components/machines/MySpace";
import NeedDates from "./components/purchase/NeedDates";
import OrderEdition from "./components/purchase/OrderEdition";
import OrderMachineCheck from "./components/purchase/OrderMachineCheck";
import OrderTracking from "./components/purchase/OrderTracking.js";
import ScrewsOrder from "./components/purchase/ScrewsOrder";
import NotifyArticleRequest from "./components/push-notification/ArticleRequestNotification";
import BbsoForm from "./components/qhse/BbsoForm";
import BbsoList from "./components/qhse/BbsoList";
import BbsoUserGoals from "./components/qhse/BbsoUserGoals";
import Building from "./components/qhse/Building";
import BuildingList from "./components/qhse/BuildingList";
import ChemicalHazard from "./components/qhse/ChemicalHazard.js";
import EquipmentList from "./components/qhse/EquipmentList";
import EquipmentRequest from "./components/qhse/EquipmentRequest";
import EquipmentRequestList from "./components/qhse/EquipmentRequestList";
import QualityFeedbackForm from "./components/qhse/QualityFeedbackForm";
import QualityFeedbackList from "./components/qhse/QualityFeedbackList";
import VisitForm from "./components/qhse/VisitForm";
import VisitTable from "./components/qhse/VisitTable";
import ContactsMngt from "./components/settings/ContactsMngt";
import FormCreation from "./components/settings/FormCreation.js";
import FormMgt from "./components/settings/FormMgt";
import Navigation from "./components/settings/Navigation.js";
import RolesManagement from "./components/settings/RolesManagement";
import UserEdit from "./components/settings/UserEdit";
import UserSetting from "./components/settings/UserSetting";
import UsersManagement from "./components/settings/UsersManagement";
import ArticleRequest from "./components/storehouse/ArticleRequest";
import ArticleRequestList from "./components/storehouse/ArticleRequestList";
import ArticlesLocation from "./components/storehouse/ArticlesLocation.js";
import Inventory from "./components/storehouse/Inventory.js";
import MachineBomOut from "./components/storehouse/MachineBomOut.js";
import MoveArticleRequest from "./components/storehouse/MoveArticleRequest";
import MoveInScan from "./components/storehouse/MoveInScan";
import MoveOutPc from "./components/storehouse/MoveOutPc.js";
import MoveScan from "./components/storehouse/MoveScan";
import NonConformityCard from "./components/storehouse/NonConformityCard.js";
import NonConformityList from "./components/storehouse/NonConformityList.js";
import Poster from "./components/storehouse/Poster.js";
import Receiption from "./components/storehouse/Receiption";
import ScanInventory from "./components/storehouse/ScanInventory";
import ScanScrews from "./components/storehouse/ScanScrews";
import StorageCtrl from "./components/storehouse/StorageCtrl.js";
import StoreageReceiption from "./components/storehouse/StoreageReceiption";
import Tags from "./components/storehouse/Tags.js";
import DepartureRequest from "./components/supplyChain/DepartureRequest";
import Expeditions from "./components/supplyChain/Expeditions.js";
import NotifyExpedition from "./components/supplyChain/NotifyExpedition";
import PackingList from "./components/supplyChain/PackingList.js";
import SubcontractingList from "./components/supplyChain/SubcontractingList";
import SubcontractingManagement from "./components/supplyChain/SubcontractingManagement";
import SubcontractingScan from "./components/supplyChain/SubcontractingScan";
import SubcontractingTable from "./components/supplyChain/SubcontractingTable";
import Transport from "./components/supplyChain/Transport";
import ProdTracking from "./components/workshop/ProdTracking.js";
import ProdTrackingList from "./components/workshop/ProdTrackingList.js";
import DoCheckList from "./components/workshop/checkList/DoCheckList";
import ElecChkList from "./components/workshop/checkList/ElecChkList";
import IntegrationChkList from "./components/workshop/checkList/IntegrationChkList";
import CheckConnDao from "./services/common/CheckConnDao.js";
import MachineDashboard from "./services/machines/MachineDashboard.js";
import userService from "./services/user.service.js";
import RotatingInventory from "./components/storehouse/RotatingInventory.js";
import BpTwoStock from "./components/storehouse/BpTwoStock.js";
import RotatingInventoryScan from "./components/storehouse/RotatingInventoryScan.js";
import ProdTrackingV2 from "./components/workshop/ProdTrackingV2.js";
import MachineInReserve from "./components/machines/MachineInReserve.js";
import NotifyPackExpedition from "./components/supplyChain/NotifyPackExpedition.js";


const App = (props) => {
  const { t } = useTranslation();
  const [locale, setLocale] = useState(i18n.language);

  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [showManufacturingBoard, setShowManufacturingBoard] = useState(false);
  const [showSubcontractingingBoard, setShowSubcontractingingBoard] = useState(false);
  const [isInternal, setIsInternal] = useState(false);
  const [showArticles, setShowArticles] = useState(false);
  const [showThrow, setShowThrow] = useState(false);
  const [showCustClient, setShowCustClient] = useState(false);
  const [isMaintenance, setIsMaintenance] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  i18n.on('languageChanged', (lng) => setLocale(i18n.language));

  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
      setIsInternal(currentUser.roles.includes("ROLE_INTERNAL"));
      setShowManufacturingBoard(currentUser.roles.includes("ROLE_ADMIN") || currentUser.roles.includes("ROLE_MANUFACTURING"));
      setShowSubcontractingingBoard(currentUser.roles.includes("ROLE_ADMIN") || currentUser.roles.includes("ROLE_SUBCONTRACTING"));
      setShowArticles(currentUser.roles.includes("ROLE_ADMIN") || currentUser.roles.includes("ROLE_INTERNAL") || currentUser.roles.includes("ROLE_SEE"));
      setShowCustClient(currentUser.roles.includes("ROLE_ADMIN") || currentUser.roles.includes("ROLE_CUSTOMER_CLIENT"));
      setShowThrow(currentUser.roles.includes("ROLE_ADMIN") || currentUser.roles.includes("ROLE_THROW"));
    } else {
      setShowModeratorBoard(false);
      setShowAdminBoard(false);
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  useEffect(() => {
    userService.isInMaintenance().then(
      (response) => {
        setIsMaintenance(response)
      }
    )

    //Check connection
    var path = window.location.pathname;
    var ignore = ["/", "/home", "/login", "/logout", "/register", "/user", "/mail-confirm", "/password-forgotten", "/reset-password", "/download-files"];

    CheckConnDao.userChecking().then(() => { }, (error) => {
      if (!ignore.includes(path)) {
        localStorage.setItem("url_back", window.location.pathname + window.location.search);
        window.location.href = "/login";
      } 
      
      logOut();
    });

  }, []);

  useEffect(() => {
    setInterval(() => {
      userService.isInMaintenance().then(
        (response) => {
          setIsMaintenance(response)
        }
      )
    }, 10000);
  }, [isMaintenance]);

  return (
    <Router history={history}>
      <LocaleContext.Provider value={{ locale, setLocale }}>

        <div>
          <nav className="navbar sticky-top navbar-expand-xl text-white no-print" style={{zIndex: "10000"}}>
            <div className="container-fluid">
              <a href="/" className="navbar-brand">
                <img src='/common/corporate-logos_white cropped.png' id="site-logo" />
              </a>

              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" id="navbar-toggler-app" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                  <li className="nav-item ">
                    <a href={"/home"} className="nav-link text-white">{t('home')}</a>
                  </li>

                  {/** FAH */}
                  { currentUser && <FAH roles={currentUser.roles} user={currentUser} />}

                  {/** BOM */}
                  {currentUser && <BOM roles={currentUser.roles} />}

                  {/** QUALITY */}
                  {
                    (currentUser && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_NC_WRITE') || currentUser.roles.includes('ROLE_NC_READ'))) &&
                    <Quality roles={currentUser.roles} />
                  }

                  {/** STOREHOUSE */}
                  {
                    (currentUser && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_STOREHOUSE'))) &&
                    <Storehouse roles={currentUser.roles} />
                  }

                  {/** SUPPLY CHAIN */}
                  {
                    (currentUser && (currentUser.roles.includes('ROLE_ADMIN') 
                                      || currentUser.roles.includes('ROLE_TRANSPORT')
                                      || currentUser.roles.includes("ROLE_WRITE_FM")  )) &&
                    <SupplyChain roles={currentUser.roles} />
                  }

                  {/** MACHINE CARD */}
                  {
                    (currentUser && (currentUser.roles.includes('ROLE_ADMIN')
                      || currentUser.roles.includes('ROLE_INTERNAL') 
                      || currentUser.roles.includes("ROLE_SEE")
                      || currentUser.roles.includes("ROLE_SALESMAN"))) &&
                    <BusinessAndMachine roles={currentUser.roles} />
                  }

                  {/** PARTS */}
                  {
                    (currentUser && showArticles) && <Articles showThrow={showThrow} isInternal={isInternal} roles={currentUser && currentUser.roles} />
                  }

                  {/** REPORTS */}
                  {
                    (currentUser && (currentUser.roles.includes('ROLE_ADMIN')
                      || currentUser.roles.includes('ROLE_WIP'))) &&
                    <Reports roles={currentUser.roles} />
                  }

                  {/** PURCHASE */}
                  {
                    (currentUser && (currentUser.roles.includes('ROLE_ADMIN')
                      || currentUser.roles.includes('ROLE_PURCHASE'))) &&
                    <Purchase roles={currentUser.roles} />
                  }

                  {/** MANUFACTURING */}
                  {(currentUser && showManufacturingBoard) && <Manufacturing roles={currentUser && currentUser.roles} />}

                  {/** SUBCONTRACTING */}
                  {(currentUser && showSubcontractingingBoard) && <Subcontracting isInternal={isInternal} showAdminBoard={showAdminBoard} />}

                  {/** MISSIONS ORDERS */
                    (currentUser && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_INTERNAL'))) &&
                    <ODMnav roles={currentUser.roles} />

                  }

                  {/** CUSTOMER CLIENT  */}
                  {(currentUser && showCustClient) && <CustomerClient roles={currentUser && currentUser.roles} />}

                  {/** TICKETS */}
                  {(currentUser && isInternal) && <Tickets roles={currentUser && currentUser.roles} />}

                  {(currentUser && (isInternal || currentUser.roles.includes('ROLE_SEE'))) &&
                    <Tools roles={currentUser.roles} isInternal={isInternal} />}

                  {(currentUser && showAdminBoard) && <Admin />}

                </ul>

                <ProfileNav currentUser={currentUser} />
              </div>

            </div>
          </nav>

          <div className="container-fluid bodyContent">
            <div className="no-print">
            <br></br>
            </div>
            {
              isMaintenance && <div>
                <h2 className="text-center">Maintenance en cours</h2>
                <h3 className="text-center">Je suis de retour dans quelques secondes</h3>
                <div className="text-center">
                  <img style={{ width: "50%", marginLeft: "auto" }} src="/img/maintenance.png"></img>
                </div>
              </div>
            }
            {
              !isMaintenance &&
              <Switch>
                <Route exact path={["/", "/home"]} component={Home} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/logout" component={LogOut} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/register/interim" component={RegisterInterim} />
                <Route exact path="/profile" component={Profile} />
                <Route path="/user" component={BoardUser} />
                <Route path="/mod" component={BoardModerator} />
                <Route path="/admin" component={BoardAdmin} />
                <Route path="/mail-confirm" component={Confirmation} />
                <Route path="/password-forgotten" component={ForgottenPassword} />
                <Route path="/reset-password" component={ResetPassword} />

                {/** TOOLS */}
                <Route path="/share-files" component={ShareFiles} />
                <Route path="/download-files" component={DownloadFiles} />
                <Route exact path="/tool-box" component={Toolbox} />
                <Route exact path="/site-map" component={SiteMap} />
                <Route exact path="/focus/licenses" component={FocusLicense} />
                <Route exact path="/annoucement" component={Annoucement} />
                <Route exact path="/navigation/management" component={Navigation} />


                {/** WORKSHOP */}
                <Route exact path="/workshop/check-list/assembly" component={DoCheckList} />
                <Route exact path="/workshop/check-list/integration" component={IntegrationChkList} />
                <Route exact path="/workshop/check-list/electrical" component={ElecChkList} />
                <Route exact path="/workshop/production/tracking" component={ProdTracking} />
                <Route exact path="/workshop/production/tracking/v2" component={ProdTrackingV2} />
                <Route exact path="/workshop/production/tracking/list" component={ProdTrackingList} />

                {/** SUBCONTRACTING */}
                <Route path="/subcontracting/list" component={SubcontractingList} />
                <Route path="/subcontracting/get-table" component={SubcontractingTable} />
                <Route path="/subcontracting/packing-list" component={PackingList} />
                <Route path="/subcontracting/scan" component={SubcontractingScan} />
                <Route path="/subcontracting/manage" component={SubcontractingManagement} />

                {/** SUPPLY CHAIN */}
                <Route path="/supply-chain/transport/list" component={Transport} />
                <Route path="/supply-chain/departure/request" component={DepartureRequest} />
                <Route exact path="/supply-chain/notify/expedition" component={NotifyExpedition} />
                <Route exact path="/supply-chain/notify/expedition/pack" component={NotifyPackExpedition} />
                <Route path="/supply-chain/tools/expedition" component={Expeditions} />

                {/** SETTINGS */}
                <Route path="/settings/get-roles" component={RolesManagement} />
                <Route exact path="/settings/user-management" component={UsersManagement} />
                <Route exact path="/settings/user/edit" component={UserEdit} />
                <Route path="/settings/user-setting" component={UserSetting} />
                <Route path="/settings/contacts-mng" component={ContactsMngt} />
                <Route exact path="/settings/form-mng" component={FormCreation} />
                <Route exact path="/settings/form-mng/v1" component={FormMgt} />


                {/** PURCHASE */}
                <Route exact path="/purchase/order/machine/check" component={OrderMachineCheck} />
                <Route exact path="/purchase/order/edition" component={OrderEdition} />
                <Route exact path="/purchase/need-dates" component={NeedDates} />
                <Route exact path="/purchase/screws/order" component={ScrewsOrder} />
                <Route exact path="/purchase/orders/tracking" component={OrderTracking} />

                {/** ARTICLES */}
                <Route path="/article/disposables/list" component={DisposablesArticlesMgt} />
                <Route path="/article/disposables/public/validation" component={ValidateThrow} />
                <Route path="/article/disposables/proposals" component={ToThrowProposals} />
                <Route exact path="/article/info/stock/dynamic" component={ArticleStock} />
                <Route exact path="/article/info/search" component={SearchArticle} />
                <Route exact path="/article/info/get" component={Article} />
                <Route exact path="/article/create" component={CreateArticle} />
                <Route exact path="/article/create/list" component={CreationRequests} />
                <Route exact path="/article/needs/create" component={CreateNeeds} />
                {/*delete*/}
                <Route exact path="/article/needs/list" component={ListNeeds} />
                {/*delete*/}
                <Route exact path="/article/needs/display" component={DisplayNeeds} />
                
                <Route exact path="/article/kit/list" component={KitList} />
                <Route exact path="/article/kit" component={Kit} />
                <Route exact path="/article/kit/need/list" component={ListKitNeeds} />
                <Route exact path="/article/kit/business/display" component={DisplayKiNeeds} />

                {/** CUSTOMER CLIENT **/}
                <Route exact path="/customer-client/check-list/maintenance/list" component={MaintenanceCheckList} />
                <Route exact path="/customer-client/check-list/maintenance" component={MaintenanceCheck} />
                <Route exact path="/customer-client/check-list/commissioning" component={Commissioning} />
                <Route exact path="/customer-client/maintenance/resume" component={MaintenanceRecap} />
                <Route exact path="/public/customer-client/maintenance/resume" component={MaintenanceRecapPublic} />
                <Route exact path="/customer-client/hts/form" component={HTS} />
                <Route exact path="/customer-client/hts/list" component={HSTlist} />
                <Route exact path="/customer-client/bill/maintenance" component={BillMaintenance} />
                <Route exact path="/customer-client/bill/spares" component={BillSpares} />
                <Route exact path="/customer-client/custom-invoice" component={CustomInvoice} />
                <Route exact path="/customer-client/picking" component={Picking} />
                <Route exact path="/customer-client/picking/list" component={PickingList} />
                <Route exact path="/customer-client/picking/alerts" component={PickingAlerts} />
                <Route exact path="/customer-client/picking/spares-bom" component={GenerateSparesBom} />
                <Route exact path="/customer-client/incident-ticket/list" component={IncidentTicket} />
                <Route exact path="/customer-client/fat" component={FAT} />

                {/** STOREHOUSE */}
                <Route exact path="/storehouse/movement/receiption" component={Receiption} />
                <Route exact path="/storehouse/articles/request" component={ArticleRequest} />
                <Route exact path="/storehouse/articles/request/get" component={MoveArticleRequest} />
                <Route exact path="/storehouse/articles/request/list" component={ArticleRequestList} />
                <Route exact path="/storehouse/articles/locations" component={ArticlesLocation} />
                <Route exact path="/storehouse/articles/tags" component={Tags} />
                <Route exact path="/storehouse/storage/receiption" component={StoreageReceiption} />
                <Route exact path="/storehouse/storage/b2" component={BpTwoStock} />
                <Route exact path="/storehouse/scan/move-out" component={MoveScan} />
                <Route exact path="/storehouse/scan/move-in" component={MoveInScan} />
                <Route exact path="/storehouse/articles/request/notification" component={NotifyArticleRequest} />
                <Route exact path="/storehouse/articles/inventory/scan" component={ScanInventory} />
                <Route exact path="/storehouse/articles/screws/scan" component={ScanScrews} />
                <Route exact path="/storehouse/quality/nc" component={NonConformityCard} />
                <Route exact path="/storehouse/quality/nc-list" component={NonConformityList} />
                <Route exact path="/storehouse/pc/move-out" component={MoveOutPc} />
                <Route exact path="/storehouse/machine/move-out" component={MachineBomOut} />
                <Route exact path="/storehouse/storage-control" component={StorageCtrl} />
                <Route exact path="/storehouse/inventory" component={Inventory} />
                <Route exact path="/storehouse/rotating/inventory" component={RotatingInventory} />
                <Route exact path="/storehouse/rotating/inventory/scan" component={RotatingInventoryScan} />
                <Route exact path="/storehouse/poster" component={Poster} />

                {/** QHSE */}
                <Route exact path="/qhse/visit/form" component={VisitForm} />
                <Route exact path="/qhse/visit/list" component={VisitTable} />
                <Route exact path="/qhse/bbso/form" component={BbsoForm} />
                <Route exact path="/qhse/bbso/list" component={BbsoList} />
                <Route exact path="/qhse/bbso/goals" component={BbsoUserGoals} />
                <Route exact path="/qhse/quality-feedback/form" component={QualityFeedbackForm} />
                <Route exact path="/qhse/quality-feedback/list" component={QualityFeedbackList} />
                <Route exact path="/qhse/building/form" component={Building} />
                <Route exact path="/qhse/building/list" component={BuildingList} />
                <Route exact path="/qhse/equipment/request/new" component={EquipmentRequest} />
                <Route exact path="/qhse/equipment/list" component={EquipmentList} />
                <Route exact path="/qhse/equipment/request/list" component={EquipmentRequestList} />
                <Route exact path="/qhse/chemical-hazard" component={ChemicalHazard} />

                {/** BOM */}
                <Route exact path="/bom/read" component={ReadBom} />
                <Route exact path="/bom/list" component={BomList} />
                <Route exact path="/bom/get" component={BomDetail} />
                <Route exact path="/bom/v2/get" component={BomReview} />

                {/** BUSINESS AND MACHINES */}
                <Route exact path="/my-space" component={MySpace} />
                <Route exact path="/business" component={BusinessCard} />
                <Route exact path="/machine" component={MachineCard} />
                <Route exact path="/machine/dashboard" component={MachineDashboard} />
                <Route exact path="/bill/machines/list" component={BillMachines} />
                <Route exact path="/budget" component={BusinessBudget} />
                <Route exact path="/contract-review" component={ContractReview} />
                <Route exact path="/general-planning" component={GeneralPlanning} />
                <Route exact path="/machine/pwd" component={MachinePwd} />
                <Route exact path="/machine/in-reserve" component={MachineInReserve} />


                {/** FAH */}
                <Route exact path="/fah/missing-trigram" component={MissingTrigram} />
                {/*<Route exact path="/fah/non-installer" component={NonInstaller} />
                <Route exact path="/fah/installer" component={Installer} />
                <Route exact path="/fah/my-tracking" component={MyFahTracking} />
                <Route exact path="/fah/manager" component={Manager} />
                */}
                <Route exact path="/fah/accountant" component={Accountant} />
                {/*V2*/}
                <Route exact path="/fah/v2/missing-trigram" component={MissingTrigramV2} />
                <Route exact path="/fah/v2" component={InstallerV2} />
                <Route exact path="/fah/v2/my-tracking" component={MyFahTrackingV2} />
                <Route exact path="/fah/v2/manager" component={ManagerV2} />
                <Route exact path="/fah/v2/accountant" component={AccountantV2} />

                {/** ODM */}
                <Route exact path="/odm/request" component={ODMRequest} />
                <Route exact path="/odm/list" component={ListOdm} />

                <Route exact path="/test/anything" component={Test} />
              </Switch>
            }

            <Request props={props}/>

            <ScrollToTop smooth />

          </div>

          {/* <AuthVerify logOut={logOut}/> */}
        </div>
      </LocaleContext.Provider>
    </Router>
  );
};

export default App;
