import { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import MachineInfo from "../../../../services/machines/MachineInfo";
import errorManagement from "../../../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../../../functions/PageAnimation";
import { confirmAlert } from "react-confirm-alert";
import { arrayRemoveElt } from "../../../../functions/ArrayFunctions";

const MachinesCat = (p) => {
    var [cat, setCat] = useState([]);
    var [loading, setLoading] = useState(false);

    useEffect(() => {
        var cur = p.machCat[0].group;
        var group = new Array();
        var arr = new Array();
        arr.push(p.machCat[0]);

        for (let i = 1; i < p.machCat.length; i++) {
            if (cur === p.machCat[i].group) {
                arr.push(p.machCat[i]);
            } else {
                group.push(arr);

                arr = new Array();
                arr.push(p.machCat[i]);
                cur = p.machCat[i].group;
            }
        }

        setCat(group)
    }, []);

    const onChange = (e) => {
        var parent = e.target.getAttribute("data-parent");
        var k = e.target.getAttribute("data-key");
        var field = e.target.getAttribute("data-field");
        var value = e.target.value;

        var arr = [...cat];
        arr[parent][k][field] = value;
        setCat(arr);
    }

    const saveValue = (e) => {
        var parent = e.target.getAttribute("data-parent");
        var k = e.target.getAttribute("data-key");
        var field = e.target.getAttribute("data-field");
        var value = e.target.value;

        if(value === "") return;
        
        MachineInfo.updateMachinesCat( field, value, cat[parent][k].typ_config ).then(
            () => { },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        )
    }

    const deleteTypeConfig = (e) => {
        var parent = e.target.getAttribute("data-parent");
        var k = e.target.getAttribute("data-key");
        var config = cat[parent][k].typ_config;

        confirmAlert({
            title: "Suppression de type de configuration",
            message: "Etes-vous sûr de vouloir supprimer le type de configuration " + config + " ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        var arr = [...cat];
                        var arr2 = [...arr[parent]]

                        arr2 = arrayRemoveElt(arr2, k);

                        arr[parent] = arr2;
                        setCat(arr);

                        MachineInfo.deleteMachinesCat(config).then(
                            () => {
                            },
                            (error) => {
                                p.setError(errorManagement.handleError(p, error));
                                scrollToTop();
                            }
                        )

                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const addCategory = (e) => {
        e.preventDefault();
        p.setError();

        var o ={
            group: e.target.group.value,
            typ_config: e.target.typ_config.value,
            weight: e.target.weight.value,
            price: e.target.price.value
        }

        for( let i=0; i<cat.length; i++ ){
            for( let j=0; j < cat[i].length; j++)
                if( cat[i][j].typ_config === o.typ_config ) {
                    p.setError("Ce type de config existe déjà !");
                    scrollToTop();
                    return
                }
        }

        setLoading(true);
        MachineInfo.addMachinesCat( o ).then(
            () => { 
                setLoading(false)
                e.target.typ_config.value = "";
                e.target.weight.value = "";
                e.target.price.value = "";
            },
            (error) => {
                setLoading(false)
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        )
    }

    return <Fragment>

        <div className="accordion" id="acrordionGroup">

            {
                cat && cat.map(
                    (v, k) => {
                        return <div className="accordion-item col-8 offset-2">
                            <h2 className="accordion-header" id={"heading" + k} key={"acc" + k}>
                                <button className="accordion-button collapsed fw-bold" type="button"
                                    data-bs-toggle="collapse" data-bs-target={"#collapse" + k}
                                    aria-expanded="false" aria-controls={"collapse" + k}>
                                    {v[0].group.replaceAll("_", " ")}
                                </button>
                            </h2>
                            <div id={"collapse" + k} className="accordion-collapse collapse"
                                aria-labelledby={"heading" + k} data-bs-parent="#acrordionGroup">
                                <div className="accordion-body">
                                    {
                                        v.map((v2, k2) => {
                                            return <div className="display-flex" key={"r" + k2}>
                                                <div className="form-floating mb-3" style={{margin: "auto"}}>
                                                    <input type="text" className="form-control" data-parent={k} data-key={k2} data-field="typ_config"
                                                       value={v2.typ_config} placeholder="xxx" readOnly/>
                                                    <label for="floatingInput">Type de configuration</label>
                                                </div>

                                                <div className="form-floating mb-3" style={{margin: "auto"}}>
                                                    <input type="number" className="form-control" data-parent={k} data-key={k2} data-field="weight" 
                                                       value={v2.weight} placeholder="xxx" onChange={onChange} onBlur={saveValue} />
                                                    <label for="floatingInput">Poids</label>
                                                </div>

                                                <div className="form-floating mb-3" style={{margin: "auto"}}>
                                                    <input type="number" className="form-control" data-parent={k} data-key={k2} data-field="price"
                                                       value={v2.price} placeholder="xxx" onChange={onChange} onBlur={saveValue} />
                                                    <label for="floatingInput">Prix</label>
                                                </div>
                                                
                                                <div style={{margin: "auto"}}>
                                                    <button className="btn btn-outline-danger" data-parent={k} data-key={k2} 
                                                        onClick={deleteTypeConfig} style={{width: "200px"}}>
                                                        Supprimer {v2.typ_config}
                                                    </button>
                                                </div>
                                            </div>
                                        })
                                    }

                                    <form className="mt-3" onSubmit={addCategory}>
                                        <div className="input-group">
                                            <span className="input-group-text">Nouveau type de config</span>
                                            <input className="form-control" name="group" value={v[0].group} readOnly/>
                                            <input className="form-control" name="typ_config" placeholder="Type de configuration" required/>
                                            <input type="number" className="form-control" name="weight" placeholder="Poids" required/>
                                            <input type="number" className="form-control" name="price" placeholder="Prix" required/>
                                            <button className="btn btn-outline-success" disabled={loading}>
                                                {loading && <ButtonWaiting />}
                                                Ajouter</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }
                )
            }


        </div>

    </Fragment>

}

export default MachinesCat;