
/**
 * If the qty sent to supplier is upper or equal than the need color the entire row green
 * @param {*} row 
 * @returns 
 */
export function setSent(row) {
    var sent_qty = row.cells[8].value;

    if (sent_qty === null || sent_qty === undefined) return "";

    var need_qty = row.cells[4].value;

    if (parseFloat(sent_qty) >= parseFloat(need_qty)) return "table-success";

    return "";
}

export function isSentClass(row) {
    var sent_qty = row.original.sent;

    if (sent_qty === null || sent_qty === undefined) return "";

    var need_qty = row.original.qty;

    if (parseFloat(sent_qty) >= parseFloat(need_qty)) return "green-bg text-center fw-bold";

    return "";
}

/**
 * Set readonly attribute according authentication access
 * @param {*} id 
 * @param {*} currentUser 
 * @returns 
 */
export function readOnly(id, currentUser) {
    if ((id === "sent" || id === "bp_internal_remarks") && !currentUser.roles.includes("ROLE_INTERNAL"))
        return true;

    if ((id === "bp_external_remarks") && !currentUser.roles.includes("ROLE_EXTERNAL"))
        return true;

    return false;
}

/**
 * Hide the ID column
 * @param {*} id 
 * @returns 
 */
export function hideFirstColumn(id) {
    if (id === "id") return "none"

    return false;
}