import { Fragment, useEffect, useState } from "react";
import ShareFileDao from "../../services/common/ShareFileDao";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting, getHeight, scrollToTop } from "../../functions/PageAnimation";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { getDateTimeFromDb, getRelevanceScore } from "../../functions/StrFunctions";
import NewProductForm from "./smallComponent/NewProductForm";
import ticketsDao from "../../services/qhse/ticketsDao";
import NewProductsList from "./smallComponent/NewProductsList";

const ChemicalHazard = (props) => {
    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState();
    const user = JSON.parse(localStorage.getItem("user"));
    const isQhse = (user.roles.includes("ROLE_QHSE") || user.roles.includes("ROLE_ADMIN"));
    const [tab, setTab] = useState(0);

    const [fds, setFds] = useState([]);
    const [ndp, setNdp] = useState([]);
    const [newProdList, setNewProdList] = useState();

    const [filteValue, setFilterValue] = useState("");
    const [selectedForm, setselectedForm] = useState();

    useEffect(() => {
        ShareFileDao.getDocsByType("fds").then(
            (r) => { setFds(r); setLoading(false); },
            (error) => { setError(errorManagement.handleError(props, error)); scrollToTop(); setLoading(false); }
        )

        ShareFileDao.getDocsByType("ndp").then(
            (r) => { setNdp(r); setLoading(false); },
            (error) => { setError(errorManagement.handleError(props, error)); scrollToTop(); setLoading(false); }
        )

        ticketsDao.getNewChemicalProduct().then(
            (r) => { setNewProdList(r); },
            (error) => { setError(errorManagement.handleError(props, error)); scrollToTop(); }
        )
    }, []);

    const onSubmit = (e, type) => {
        e.preventDefault();
        setError();

        var label = e.target.label.value;
        var file = e.target.file.files[0]

        setUploading(true);
        ShareFileDao.uploadByType(type, label, file).then(
            (r) => {
                if (type === "fds") {
                    var arr = [...fds]
                    arr.push({ id: r.id, label: label, url: r.url, creation_date: new Date() })
                    setFds(arr);
                } else {
                    var arr = [...ndp]
                    arr.push({ id: r.id, label: label, url: r.url, creation_date: new Date() })
                    setNdp(arr);
                }

                setUploading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setUploading(false);
            }
        )

    }

    const deleteFile = (type, k) => {
        var id;
        var url;
        if (type === "fds") {
            var arr = [...fds];
            id = arr[k].id;
            url = arr[k].url;
            arr = arrayRemoveElt(arr, k);
            setFds(arr);
        } else {
            var arr = [...ndp];
            id = arr[k].id;
            url = arr[k].url;
            arr = arrayRemoveElt(arr, k);
            setNdp(arr);
        }

        ShareFileDao.deleteDocsByType(id, url)
    }

    const filter = (e) => {
        var val = e.target.value;

        setFilterValue(val);
        var tFds = [...fds];
        var tNdp = [...ndp];

        for (let i = 0; i < tFds.length; i++) {
            if (val === "") {
                tFds[i].hide = false;
            } else {
                var score = getRelevanceScore(tFds[i], val);
                tFds[i].hide = score === 0;
            }
        }

        for (let i = 0; i < tNdp.length; i++) {
            if (val === "") {
                tNdp[i].hide = false;
            } else {
                var score = getRelevanceScore(tNdp[i], val);
                tNdp[i].hide = score === 0;
            }
        }

        setFds(tFds);
        setNdp(tNdp);
    }

    const openNewProduct = (k) => {
        setTab(2);
        setselectedForm(newProdList[k]);
    }

    return <div>
        <h5 className="text-center mb-3">Risque Chimique</h5>
        {error && <ErrorMessage error={error} errorState={setError} />}
        {loading && <WaitingRoundSnippers />}

        {!loading && <Fragment>
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <a className={'nav-link ' + (tab === 0 ? "active" : "")}
                        aria-current="page" href="#" onClick={() => setTab(0)}>Fiche de Données de Sécurité</a>
                </li>

                <li className="nav-item">
                    <a className={'nav-link ' + (tab === 1 ? "active" : "")}
                        href="#" onClick={() => setTab(1)}>Notice de Poste</a>
                </li>

                <li className="nav-item">
                    <a className={'nav-link ' + (tab === 2 ? "active" : "")}
                        href="#" onClick={() => setTab(2)}>Formulaire nouveau produit</a>
                </li>

                <li className="nav-item">
                    <a className={'nav-link ' + (tab === 3 ? "active" : "")}
                        href="#" onClick={() => setTab(3)}>Liste des nouveaux produits</a>
                </li>
            </ul>

            { /** Fiche de Données de Sécurité */
                tab === 0 &&
                <div>

                    <input className="form-control ms-auto me-auto fw-bold text-center mt-1" onChange={filter}
                        style={{ width: "400px" }} placeholder="Recherche rapide" value={filteValue} />

                    <div className="card mb-3 col-8 offset-2 overflow-x-auto" id="secu_data"
                        style={{ maxHeight: getHeight("secu_data", 0), marginTop: "20px" }}>
                        <h5>Fiche de Données de Sécurité</h5>

                        {fds.length === 0 && <span className="mb-3 fw-bold">Aucun fichier ajouté</span>}
                        {fds.length > 0 && <ul className="list-group mb-3">
                            {fds.map((v, k) => {
                                return !v.hide && <li key={"fds" + k} className="list-group-item">
                                    <div className="d-flex">
                                        <div>
                                            <span className="fw-bold">{v.label}</span>
                                            <br></br>
                                            <span className="fst-italic" style={{ fontSize: "12px" }}>Date: {getDateTimeFromDb(v.creation_date, "fr")}</span>
                                        </div>


                                        <div className="ms-auto me-3 mt-auto mb-auto">
                                            <a className="btn btn-success" target="_blank"
                                                href={ShareFileDao.getUrlToDisplayDocByType(v.id)}>Ouvrir</a>
                                        </div>
                                        {isQhse &&
                                            <div className="mt-auto mb-auto">
                                                <button className="btn btn-danger" onClick={() => deleteFile("fds", k)}>Supprimer</button>
                                            </div>
                                        }
                                    </div>
                                </li>
                            })}
                        </ul>}

                        {isQhse &&
                            <form onSubmit={(e) => onSubmit(e, "fds")}>
                                <h6 className="text-center">Ajouter un fichier</h6>

                                <div className="input-group mb-3">
                                    <span className="input-group-text" >Libellé</span>
                                    <input type="text" className="form-control" name="label" required />

                                    <input type="file" className="form-control" name="file" accept="application/pdf" required></input>


                                    <button className="btn btn-outline-success" type="submit" disabled={uploading}>
                                        {uploading && <ButtonWaiting />}
                                        Ajouter
                                    </button>
                                </div>
                            </form>
                        }

                    </div>
                </div>


            }

            {/**Notice de Poste */
                tab === 1 && <div>

                    <input className="form-control ms-auto me-auto fw-bold text-center mt-1" onChange={filter}
                        style={{ width: "400px" }} placeholder="Recherche rapide" value={filteValue} />

                    <div className="card mb-3 col-8 offset-2 overflow-x-auto" id="poste_notice"
                        style={{ maxHeight: getHeight("poste_notice", 0) }}>
                        <h5>Notice de Poste</h5>

                        {ndp.length === 0 && <span className="mb-3 fw-bold">Aucun fichier ajouté</span>}
                        {ndp.length > 0 && <ul className="list-group mb-3">
                            {ndp.map((v, k) => {
                                return !v.hide && <li key={"ndp" + k} className="list-group-item">
                                    <div className="d-flex">
                                        <div>
                                            <span className="fw-bold">{v.label}</span>
                                            <br></br>
                                            <span className="fst-italic" style={{ fontSize: "12px" }}>Date: {getDateTimeFromDb(v.creation_date, "fr")}</span>
                                        </div>

                                        <div className="ms-auto me-3 mt-auto mb-auto">
                                            <a className="btn btn-success" target="_blank"
                                                href={ShareFileDao.getUrlToDisplayDocByType(v.id)}>Ouvrir</a>
                                        </div>

                                        {isQhse &&
                                            <div className="mt-auto mb-auto">
                                                <button className="btn btn-danger" onClick={() => deleteFile("ndp", k)}>Supprimer</button>
                                            </div>}
                                    </div>
                                </li>
                            })}
                        </ul>}

                        {isQhse &&
                            <form onSubmit={(e) => onSubmit(e, "ndp")}>
                                <h6 className="text-center">Ajouter un fichier</h6>

                                <div className="input-group mb-3">
                                    <span className="input-group-text" >Libellé</span>
                                    <input type="text" className="form-control" name="label" required />

                                    <input type="file" className="form-control" name="file" accept="application/pdf" required></input>

                                    <button className="btn btn-outline-success" type="submit" disabled={uploading}>
                                        {uploading && <ButtonWaiting />}
                                        Ajouter
                                    </button>
                                </div>
                            </form>
                        }
                    </div>
                </div>

            }

            {tab === 2 && <NewProductForm selectedForm={selectedForm} setselectedForm={setselectedForm}/>}

            {(tab === 3 && newProdList) && <NewProductsList list={newProdList} openNewProduct={openNewProduct}/>}
        </Fragment>}

    </div>
}

export default ChemicalHazard;