import React, { useEffect, useState } from "react";
import getUrlParam from "../../functions/StrFunctions";
import errorManagement from "../../services/errorManagement";
import usersDao from "../../services/settings/usersDao";

const UserSetting = (props) => {
    const[userInfo, setUserInfo] = useState(null);
    const[userRoles, setUserRoles] = useState(null);
    const [error, setError] = useState(null);
   
    useEffect( () => {
        getUser(getUrlParam(props, "id"));
    },[] );

    const getUser = (id) => {
        usersDao.getUserInfo(id).then(
            (response) => {
                setUserInfo(response.user_info);
                setUserRoles(response.roles);
            },
            (error) => {
                setError( errorManagement.handleError(props, error) );
            }
        );
    }

    const onRoleChecking = (e) => {
        usersDao.updateUserRole( userInfo.id, e.target.value, e.target.checked );
    }

    return (
        <div>
            <h1 className="text-center">Gestion d'utilisateur</h1>
            
            <br></br>

            {
                error && 
                    <div className="alert alert-danger text-center font-weight-bold" role="alert"> {error} </div>
                
            }

            {
                !userRoles && 
                    <div className="text-center">
                        <div className="spinner-grow text-success" role="status"></div>
                        <div className="spinner-grow text-danger" role="status"></div>
                        <div className="spinner-grow text-warning" role="status"></div>
                        <div className="spinner-grow text-dark" role="status"></div>
                    </div>
            }

            {
                userInfo && 
                    <div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Username</span>
                            </div>
                            <input type="text" className="form-control" defaultValue={userInfo.username}/>
                        </div>

                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Email</span>
                            </div>
                            <input type="text" className="form-control" defaultValue={userInfo.email} readOnly/>
                        </div>

                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Statut</span>
                            </div>
                            <select className="custom-select" defaultValue={userInfo.statut}>
                                <option value="">Choose...</option>
                                <option value="active">Active</option>
                                <option value="blocked">Blocked</option>
                                <option value="...">...</option>
                            </select>
                        </div>
                    </div>
            }
            
            <br></br>

            {
                userRoles && 
                    userRoles.map( (v, k) => 
                        <div className="input-group mb-3" key={k}>
                            <div className="input-group-prepend">
                            <div className="input-group-text">
                                <input type="checkbox" value={v.id} defaultChecked={ v.hasRole } onClick={onRoleChecking}/>
                            </div>
                            </div>
                            <input type="text" className="form-control" defaultValue={v.name} readOnly/>
                        </div>
                    )
            }

        </div>

    );

}

export default UserSetting;