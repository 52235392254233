import i18next from "i18next";
import { Fragment, useEffect, useRef, useState } from "react";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { getDeviceType, scrollToTop } from "../../functions/PageAnimation";
import CheckList from "../../services/customClient/CheckList";
import errorManagement from "../../services/errorManagement";
import "react-html5-camera-photo/build/css/index.css";
import DataURIToBlob, { createIptStr, extractName, extractValue, getBase64 } from "../../functions/StrFunctions";
import getUrlParam from "../../functions/StrFunctions";
import { getDistinctSubset } from "../common/formFunctions";
import { getTxtAreaName, getTxtAreaValue, writeTxtAreaValue } from "../../functions/FormCtrl";
import { getKey } from "../../functions/ArrayFunctions";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import { checkAllFilled } from "./common/MaintenanceFunctions";

const MaintenanceCheck = (props) => {
    const { t } = useTranslation(['check-list', 'common']);
    const [loading, setLoading] = useState(false);
    const [machine, setMachine] = useState("");
    const [date, setDate] = useState("");
    const [error, setError] = useState(null);
    const [list, setList] = useState();
    const [form, setForm] = useState();
    const [deviceType, setTypeDevice] = useState();
    const [subset, setSubset] = useState();
    const [curSubset, setCurSubset] = useState();
    const [takeAPhoto, setTakeAPhoto] = useState(false);
    const [dataPhoto, setDataPhoto] = useState();
    const [keyPhoto, setKeyPhoto] = useState();
    const [showPhoto, setshowPhoto] = useState();
    const [notes, setNotes] = useState("");
    const [changed, setChanged] = useState("");
    const [toChange, setToChange] = useState("");
    const [value1, setValue1] = useState("");
    const [msg, setMsg] = useState();


    useEffect(() => {
        setTypeDevice(getDeviceType());

        onStart(getUrlParam(props, "machine"), getUrlParam(props, "date"));
    }, []);

    useEffect(() => {
        var flag = true;

        list && list.map(v => {
            if ((v.hasConform === 1 && v.conform === null)
                || (v.hasPhoto === 1 && v.photo === null)) flag = false;
        })

    }, [form]);

    /**
     * Get form
     * 
     * @param {*} e 
     * @returns 
     */
    const onStart = (machine, date) => {

        setError("");
        setLoading(true);

        setMachine(machine);
        setDate(date);

        CheckList.getCheckListForm(machine, i18next.language, date).then(
            (response) => {
                setMachine(response.machine)
                setList(response.form);
                var arr = getDistinctSubset(response.form, i18next.language);
                setSubset(arr);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        );

    }

    /**
     * Set subset choosen
     * @param {*} e 
     */
    const onSubsetChoose = (e) => {
        var se = e.target.value;
        setCurSubset(se);

        if (se === "") {
            setForm();
            return;
        }

        var arr = new Array();
        var found = false;


        list.map(v => {
            if (v.chapter_label.trim() === se.trim()) {
                arr.push(v);
            } else if (found) {
                return;
            }
        });

        setForm(arr);

        var value1 = arr[0].value1;
        if (value1 === null) {
            setNotes("");
            setChanged("");
            setToChange("");
        } else {
            setNotes(extractValueFromTag(value1, "notes"));
            setChanged(extractValueFromTag(value1, "changed"));
            setToChange(extractValueFromTag(value1, "toChange"));
        }
    };

    function extractValueFromTag(str, field) {
        return str.substring((str.indexOf("<" + field + ">") + field.length + 2), (str.indexOf("</" + field + ">")));
    }

    /**
     * 
     * @param {*} e 
     */
    const onClickConform = (e) => {
        var k = e.target.getAttribute("data-key");
        var conform = e.target.getAttribute("data-conform");
        var arr = [...form];
        arr[k].conform = !conform ? null : conform === "true" ? 1 : 0;
        setForm(arr);
    }

    /**
     * 
     * @param {*} e 
     */
    const onSubmit = (e) => {
        var k = parseInt(getKey(subset, "label", curSubset)) + 1;

        if (k <= subset.length - 1) {
            document.getElementById("subsetChoice").value = subset[k].label;
            document.getElementById("subsetChoice").dispatchEvent(new Event('change', { 'bubbles': true }));
        }

        //Save
        CheckList.saveForm(machine, date, form, value1).then(
            () => { },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        );
    }

    /**
 * Open photo device
 * @param {*} e 
 */
    const takePhoto = (e) => {
        setKeyPhoto(parseInt(e.target.getAttribute("data-key")));
        setTakeAPhoto(true);
    }

    /**
     * On choose file
     * @param {*} e 
     * @returns 
     */
    const onChooseFile = (e) => {
        if (e.target.files.length === 0)
            return;

        var arr = [...form];
        var k = e.target.getAttribute("data-key");
        getBase64(e.target.files[0], (res) => {
            arr[k].photo = res
            setForm(arr);
        });
    }

    /**
     * Close photo device
     */
    const cancelPhoto = () => {
        setTakeAPhoto(false);
    }

    /**
     * Save the photo
     * @param {*} data 
     */
    const handlePhoto = (data) => {
        var arr = [...form];
        arr[keyPhoto].photo = data
        setForm(arr);

        setKeyPhoto();
        setTakeAPhoto(false);
    }

    /**
     * Delete the taken photo or uploaded img
     */
    const deletePhoto = (e) => {
        var arr = [...form];
        delete arr[showPhoto.key].photo;
        setForm(arr);
        setshowPhoto();
    }


    /**
     * 
     * @param {*} e 
     */
    const displayPhoto = (e) => {
        var k = e.target.getAttribute("data-key");
        setshowPhoto({
            key: k,
            data: form[k].photo
        });
    }


    /**
     * 
     * @param {*} e 
     */
    const changeTxtArea = (e) => {
        var key = e.target.getAttribute("data-key");
        var name = e.target.getAttribute("name");
        var value = e.target.value;

        var arr = [...form];
        form[key].textarea = writeTxtAreaValue(name, value);

        setForm(arr);
    }

    /**
     * 
     * @param {*} e 
     */
    const changeIptArea = (e) => {
        var key = e.target.getAttribute("data-key");
        var key2 = e.target.getAttribute("data-key2");
        var value = e.target.value;

        var arr = [...form];
        var ipt = form[key].inputs[key2];
        arr[key].inputs[key2] = createIptStr(ipt, value);

        setForm(arr);
    }

    /**
     * 
     * @param {*} e 
     */
    const onFooterCommentChange = (e) => {
        var field = e.target.getAttribute("data-field");
        var value = e.target.value;
        var val1;

        if (field === "notes") {
            setNotes(value);
            val1 = "<notes>" + value + "</notes>" + "<changed>" + changed + "</changed>" + "<toChange>" + toChange + "</toChange>";
            setValue1(val1);
        } else if (field === "changed") {
            setChanged(value);
            val1 = "<notes>" + notes + "</notes>" + "<changed>" + value + "</changed>" + "<toChange>" + toChange + "</toChange>"
            setValue1(val1);
        } else if (field === "toChange") {
            setToChange(value);
            val1 = "<notes>" + notes + "</notes>" + "<changed>" + changed + "</changed>" + "<toChange>" + value + "</toChange>"
            setValue1(val1);
        }

        var arr = [...form];
        arr.map( v => v.value1 = val1 )
        setForm(arr);
    }

    const checkChkList = () => {
        var err = checkAllFilled(t, list);
        
        if( err !== "" ){
            setError(err);
            scrollToTop();
        }else{
            setMsg("Ok!");
            scrollToTop();
        }
    }

    return (
        <div>
            <h2 className="text-center">Maintenance (#{machine})</h2>

            { error && <ErrorMessage error={error} errorState={setError} /> }
            { msg && <SuccessMessage msg={msg} msgState={setMsg}/> }
            { loading && <WaitingRoundSnippers /> }

            {
                subset && <Fragment>
                    <div className="input-group mb-3">
                        <select className="form-select text-center fw-bold " id="subsetChoice" onChange={onSubsetChoose}>
                            <option value="">Choisissez le sous-ensemble</option>
                            {
                                subset.map((v, k) =>
                                    <option key={"se" + k}
                                        className={v.no_conform > 0 ? "bg-danger bg-gradient text-white" :
                                            v.conform === v.cpt ? "bg-success bg-gradient text-white" :
                                                v.conform > 0 ? "bg-warning bg-gradient" : ""}
                                        value={v.label.trim()}>{v.label}</option>)
                            }
                        </select>

                        <select className="form-select text-center fw-bold">
                            <option>Légende</option>
                            <option className="bg-danger bg-gradient text-white">Au moins une non conformité signalée</option>
                            <option className="bg-warning bg-gradient">En cours de contrôle sans non conformité</option>
                            <option className="bg-success bg-gradient text-white">Tout est conforme</option>
                            <option>Non commencé</option>
                            <option style={{ color: "red" }}>Libellé contrôle en rouge: a été non-conforme autrefois</option>
                        </select>
                        
                        <button className="btn btn-outline-secondary" onClick={checkChkList}>Check</button>
                    </div>


                </Fragment>}

            {
                form &&
                <Fragment>
                    <ul className="list-group" id="integrationForm">
                        {
                            form.map(
                                (v, k) => {

                                    return <Fragment>
                                        {(k === 0 && v.rubric_label !== '') &&
                                            <li key={"rq" + k} className="list-group-item fw-bold bg-secondary bg-gradient text-white">
                                                {v.rubric_label}
                                            </li>
                                        }

                                        {
                                            (k > 0 && v.rubric_label !== '' && form[k - 1].rubric_label !== v.rubric_label) &&
                                            <li key={"rq" + k} className="list-group-item fw-bold bg-secondary bg-gradient text-white">
                                                {v.rubric_label}
                                            </li>
                                        }
                                        <li key={"q" + k} className="list-group-item">
                                            <div className="question">
                                                <div className="left-side" style={deviceType === "mobile" ? { display: "inline-flex" } : {}} >
                                                    {
                                                        v.hasConform === 1 && <Fragment>
                                                            <input type="radio" className="btn-check"
                                                                data-key={k} data-conform="true"
                                                                checked={v.conform !== null && v.conform}
                                                                onClick={onClickConform} />
                                                            <label className="btn btn-outline-success"
                                                                htmlFor={"q_" + v.question + "_c"} data-conform="true"
                                                                data-key={k} onClick={onClickConform}>Conform</label>

                                                            <input type="radio" className="btn-check"
                                                                data-key={k} data-conform="false"
                                                                checked={v.conform !== null && !v.conform}
                                                                onClick={onClickConform} />
                                                            <label className="btn btn-outline-danger" data-conform="false"
                                                                data-key={k} htmlFor={"q_" + v.question + "_nc"}
                                                                onClick={onClickConform}>Non conforme</label>
                                                        </Fragment>
                                                    }
                                                    {
                                                        v.conform !== null &&
                                                        <button type="button" className="btn-close" onClick={onClickConform}
                                                            data-key={k} aria-label="Close"></button>
                                                    }

                                                </div>

                                                <div className="right-side">
                                                    {v.question})&nbsp;
                                                    <span style={{ color: (v.conform && v.conform === 1 && v.hasBeenNC === 1) ? "red" : "black" }}
                                                        dangerouslySetInnerHTML={{ __html: v.question_label }}></span>

                                                    {
                                                        (v.textarea !== null && v.textarea !== "") &&
                                                        <div>
                                                            <textarea className="form-control" name={getTxtAreaName(v.textarea)}
                                                                value={getTxtAreaValue(v.textarea)} data-key={k}
                                                                onChange={changeTxtArea}></textarea>
                                                        </div>
                                                    }

                                                    {
                                                        (v.inputs !== null && v.inputs.length > 0) &&
                                                        <div>
                                                            {
                                                                v.inputs.map((v2, k2) => {
                                                                    return <div className="form-floating mb-3">
                                                                        <input type="text" className="form-control" id={"ipt" + k + "_" + k2} 
                                                                            name={extractName(v2)} value={extractValue(v2)} placeholder="xxx" data-key={k}
                                                                            data-key2={k2} onChange={changeIptArea} />
                                                                        <label for={"ipt" + k + "_" + k2}>{extractName(v2)}</label>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    }

                                                    {
                                                        v.hasPhoto === 1 && <Fragment>
                                                            {
                                                                v.photo &&
                                                                <div>
                                                                    <img src={v.photo} style={{ width: "50px" }} data-key={k} onClick={displayPhoto}></img>
                                                                </div>
                                                            }

                                                            {
                                                                !v.photo && <Fragment>
                                                                    <div style={{ display: "flex", marginBottom: "10px" }}>
                                                                        {
                                                                            !takeAPhoto &&
                                                                            <Fragment  >
                                                                                <button type="button" className="custom-file-upload photo" style={{ marginLeft: "10px" }}
                                                                                    data-key={k} onClick={takePhoto}></button>

                                                                                <label className="custom-file-upload folder" style={{ marginLeft: "10px", marginRight: "auto" }}>
                                                                                    <input type="file" accept="image/*" data-key={k} onChange={onChooseFile} />
                                                                                </label>
                                                                            </Fragment>
                                                                        }


                                                                    </div>


                                                                    {dataPhoto &&
                                                                        <div className="text-center" style={{ marginBottom: "20px" }}>
                                                                            <img src={dataPhoto} style={{ maxWidth: getDeviceType() === "mobile" ? "300px" : "500px" }} />
                                                                            <br></br>
                                                                            <button type="button" style={{ marginTop: "10px", marginLeft: "10px" }} className="btn btn-danger" onClick={deletePhoto}>Supprimer</button>
                                                                        </div>
                                                                    }
                                                                </Fragment>
                                                            }

                                                        </Fragment>
                                                    }
                                                    <div></div>
                                                </div>


                                            </div>
                                        </li>
                                    </Fragment>
                                }
                            )
                        }
                    </ul>

                    <br></br>

                    <div className="form-floating mb-3">
                        <textarea className="form-control" placeholder="xxx" value={notes}
                            data-field="notes" onChange={onFooterCommentChange}></textarea>
                        <label for="floatingTextarea">Notes</label>
                    </div>

                    <div className="form-floating mb-3">
                        <textarea className="form-control" placeholder="xxx" value={changed}
                            data-field="changed" onChange={onFooterCommentChange}></textarea>
                        <label for="floatingTextarea">{t('changed cli', { ns: 'check-list' })}</label>
                    </div>

                    <div className="form-floating mb-3">
                        <textarea className="form-control" placeholder="xxx" value={toChange}
                            data-field="toChange" onChange={onFooterCommentChange}></textarea>
                        <label for="floatingTextarea">{t('change quickly', { ns: 'check-list' })}</label>
                    </div>

                    <div className="text-center mb-3">
                        <button className="btn btn-success" onClick={onSubmit} disabled={loading}>
                            {loading && <span className="spinner-border spinner-border-sm"></span>}
                            Enregistrer
                        </button>
                    </div>
                </Fragment>

            }

            {takeAPhoto &&
                <div className="custom-tooltip">

                    <button type="button" className="btn btn-info" onClick={cancelPhoto}>Annuler</button>

                    <br></br>

                    <Camera idealFacingMode={FACING_MODES.ENVIRONMENT} onTakePhoto={(data) => { handlePhoto(data) }} />
                </div>
            }

            {
                showPhoto && <div className="custom-tooltip" >
                    <button type="button" className="btn-close"
                        aria-label="Close" onClick={() => setshowPhoto()}></button>
                    <img src={showPhoto.data} style={{ width: "100%", height: "100%" }}></img>
                    <button style={{ marginLeft: "10px" }} onClick={deletePhoto}
                        className="btn btn-outline-danger">Delete</button>
                </div>
            }

            
        </div>

    )

}

export default MaintenanceCheck;