import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Download any file according response
 * @param {*} response 
 * @param {*} fileName 
 */
export const downloadFile = (response, fileName) => {

    var binaryData = [];
    binaryData.push(response.data);
    var csvURL = window.URL.createObjectURL(new Blob(binaryData, { type: "application/octet-stream" }));

    //var csvURL = window.URL.createObjectURL(response.data);
    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', fileName);
    tempLink.click();

    removeTempFile(fileName);
}

export const downloadSingleFile = (folder, file) => {
    if (file.indexOf("\\") !== -1) file = file.substring(file.lastIndexOf("\\") + 1, file.length);
    
    var csvURL = API_URL + "common/share-file/public/download/single?folder=" + folder + "&file=" + file
    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('target', '_blank');
    tempLink.setAttribute('download', file);
    tempLink.click();
}

export const downloadTempFile = (file) => {
    if (file.indexOf("\\") !== -1) file = file.substring(file.lastIndexOf("\\") + 1, file.length);

    var csvURL = API_URL + "common/share-file/public/download/single?folder=temp&file="
        + encodeURIComponent(file)
    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('target', '_blank');
    tempLink.setAttribute('download', file);
    tempLink.click();
}

export const getTempFileUrl = (file) => {
    if (file.indexOf("\\") !== -1) file = file.substring(file.lastIndexOf("\\") + 1, file.length);

    return API_URL + "common/share-file/public/download/single?folder=temp&file="
        + encodeURIComponent(file)
}

export const removeTempFile = (file) => {
    if (file.indexOf("\\") !== -1) file = file.substring(file.lastIndexOf("\\") + 1, file.length);

    setTimeout(() => {
        return axios
            .post(API_URL + "public/files/remove-temp-file", {
                file: file
            }, { Headers: authHeader() })
            .then((response) => {
                return response.data;
            });
    }, (5000));
}

export const removeFile = (folder, file) => {
    return axios
        .post(API_URL + "public/files/remove-file", {
            folder: folder,
            file: file
        }, { Headers: authHeader() })
        .then((response) => {
            return response.data;
        });

}

export const downloadTableInXls = (header, body) => {
    return axios
        .post(API_URL + "public/files/table/export-xls", {
            hd: header,
            bd: body
        }, { Headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}
 
export const compressFiles = (url, list, zip_name) => {
    return axios
        .post(API_URL + "public/files/zip", {
            fake_header: authHeader(),
            url: url,
            list: list,
            zip_name: zip_name,
            fake_header: authHeader()
        }, { Headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}