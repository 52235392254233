import { createColumnHelper } from "@tanstack/react-table";
import Moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import { scrollToTop } from "../../functions/PageAnimation";
import errorManagement from "../../services/errorManagement";
import subcontracting from "../../services/supplyChain/subcontracting";


const SubcontractingList = (props) => {
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [tab, setTab] = useState(0);
    const [err, setError] = useState();
    const [data, setData] = useState([]);

    const user = JSON.parse(localStorage.getItem("user"));
    const [isInternal] = useState(user && user.roles.includes("ROLE_INTERNAL"));

    Moment.locale('fr');

    useEffect(() => {
        if (isInternal) {
            subcontracting.getInternalList().then(
                (response) => {
                    setData(response);
                    getNonClosedRows(response);
                    setLoading(false);
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                    setLoading(false);
                }
            )
        } else {
            subcontracting.getExternalList().then(
                (response) => {
                    setList(response);
                    setData(response);
                    setLoading(false);
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                    setLoading(false);
                }
            )
        }


    }, []);

    function getNonClosedRows(list) {
        var arr = new Array();
        list.map((v) => { if (v.closed !== 1) arr.push(v); });
        setList(arr);
    }

    function getClosedRows(list) {
        var arr = new Array();
        list.map((v) => { if (v.closed === 1) arr.push(v); });
        setList(arr);
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('affaire', { header: 'Affaire' }));
        arr.push(columnHelper.accessor('no_machine', { header: 'Machine' }));
        arr.push(columnHelper.accessor('typ_config', { header: 'Type de machine' }));
        if (isInternal) arr.push(columnHelper.accessor('manufacturing', { header: 'Sous-traitant' }));

        arr.push(columnHelper.accessor('Date de création', {
            header: 'creation_date',
            cell: i => <span>{Moment(i.getValue()).format("DD/MM/YYYY")}</span>
        }));

        arr.push(columnHelper.accessor('x', {
            header: '',
            cell: i => <a target="blank" href={"/subcontracting/packing-list?machine=" + i.row.original.no_machine}
                className="btn btn-outline-success">Colisage</a>
        }));

        if (isInternal) {
            arr.push(columnHelper.accessor('xx', {
                header: '',
                cell: i => <a target="blank" href={"/subcontracting/manage?machine=" + i.row.original.no_machine}
                    className="btn btn-outline-secondary">Gérer</a>
            }));

            arr.push(columnHelper.accessor('xx', {
                header: 'Statut',
                cell: i => <select className="form-select" onChange={changeStatus}
                    defaultValue={i.row.original.closed} data-id={i.row.original.id}>
                    <option value="0">Choisir...</option>
                    <option value="2">Montage en cours</option>
                    <option value="3">Montage terminé</option>
                    <option value="1">Clôturé</option>
                </select>
            }));
        }



        return arr;
    }

    //Set columns
    const columns = getColumns();

    const changeTab = (e) => {
        var tab = parseInt(e.target.getAttribute("data-tab"));
        setTab(tab);

        if (tab === 0) {
            getNonClosedRows(data);
        } else {
            getClosedRows(data);
        }
    }

    const { t } = useTranslation();

    const changeStatus = (e) => {
        var id = parseInt(e.target.getAttribute("data-id"));
        var status = parseInt(e.target.value);
        updateList(id, status);

        subcontracting.updateStatus(id, status).then(
            () => {},
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        )
    }

    function updateList(id, status) {
        var arr = [...data];
        arr.map(v => {
            if (parseInt(v.id) === id) {
                v.closed = status;
                return;
            }
        });
        setData(arr);

        if (tab === 0) {
            getNonClosedRows(arr);
        } else {
            getClosedRows(arr);
        }
    }

    return (
        <div>
            <h4 className="text-center">{t('subcontracting list')}</h4>

            {
                isInternal &&
                <Fragment>
                    <form action={"/subcontracting/manage"} className="col-6 offset-3">
                        <div className="input-group mb-3">
                            <span className="input-group-text">Machine</span>

                            <input type="text" className="form-control text-center font-weight-bold"
                                name="machine" placeholder="Rechercher..." />
                            <button type="submit" className="btn btn-success" >Rechercher</button>
                        </div>
                    </form>


                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <a className={'nav-link ' + (tab === 0 ? "active" : "")}
                                aria-current="page" href="#" data-tab="0" onClick={changeTab}>En cours de partage</a>
                        </li>
                        <li className="nav-item">
                            <a className={'nav-link ' + (tab === 2 ? "active" : "")}
                                href="#" data-tab="2" onClick={changeTab}>Dernières ST clôturées</a>
                        </li>
                    </ul>
                </Fragment>
            }

            {
                loading &&
                <div className="text-center">
                    <div className="spinner-border text-success" role="status"></div>
                    <div className="spinner-border text-danger" style={{ marginLeft: "10px" }} role="status"></div>
                    <div className="spinner-border text-warning" style={{ marginLeft: "10px" }} role="status"></div>
                    <div className="spinner-border text-info" style={{ marginLeft: "10px" }} role="status"></div>
                </div>
            }

            <div className="col-8 offset-2">
                {
                    data.length > 0 &&
                    <ReactTable columns={columns} data={list} top0={true} origin="subcontracting" />
                }
            </div>


        </div>


    );

}

export default SubcontractingList;