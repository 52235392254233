import React, { useState } from "react";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import authService from "../../services/auth.service";
import errorManagement from "../../services/errorManagement";

const RegisterInterim = (props) => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [msg, setMsg] = useState();
  const [createdInFocus, setCreatedInFocus] = useState(true);

  const [username, setUsername] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");

  const handleRegister = (e) => {
    e.preventDefault();

    if( username.trim().length < 3 || lastName.trim().length < 3 || firstName.trim().length < 3){
      setError("Merci de compléter correctement le formulaire.");
      return;
    }
 
    setLoading(true);
    authService.createInterim(username, lastName, firstName, createdInFocus).then(
      (r) => {
          setMsg(r);
          setLoading(false);
      }, (error) => {
          setError(errorManagement.handleError(props, error));
          setLoading(false);
          scrollToTop();
      }
  )
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <h5 className="text-center mb-3">Création rapide de compte</h5>

        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        {!createdInFocus && <div className="alert alert-warning fw-bold text-center">
            Si cet intérimaire n'a pas été créé sur Focus, il faudra mettre à jour son profil 
            lorsque ce sera fait afin de pouvoir envoyer les FAH
          </div>}

        <form onSubmit={handleRegister}>

          <div className="form-group">
            <label className="fw-bold text-center">Trigramme</label>
            <div className="input-group mb-3">
              <input type="text" className="form-control" name="username" required 
                 onChange={(e) => setUsername(e.target.value.toUpperCase().trim())} value={username}/>
            </div>
          </div>

          <div className="form-group">
            <label className="fw-bold text-center">Nom</label>
            <div className="input-group mb-3">
              <input type="text" className="form-control" name="last_name" required 
                 onChange={(e) => setLastName(e.target.value)} value={lastName}/>
            </div>
          </div>

          <div className="form-group">
            <label className="fw-bold text-center">Prénom</label>
            <div className="input-group mb-3">
              <input type="text" className="form-control" name="first_name" required 
                 onChange={(e) => setFirstName(e.target.value)} value={firstName}/>
            </div>
          </div>

          <div className="form-check mb-3">
            <input className="form-check-input" type="checkbox" value="O" name="createdInFocus"
              checked={createdInFocus} onChange={()=>setCreatedInFocus(!createdInFocus)}/>
            <label className="form-check-label fw-bold">
              Le profil de cet intérimaire a été créé sur Focus
            </label>
          </div>

          <div className="text-center">
            <button className="btn btn-outline-success" disabled={loading}>
              {loading && <ButtonWaiting />}
              Créer
            </button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default RegisterInterim;
