import { useEffect } from "react"
import { useState } from "react"

const DropDownMenu = (props) => {
    const [dataList, setDataList] = useState([]);

    useEffect( () => {
        var prev = "";
        var arr = new Array();
        var arr2 = new Array();

        props.list.map( (v, k) => {
            if( k > 0 && v.field !== prev ){
                var o = {
                    code: prev,
                    arr: arr2
                }

                arr.push(o);
                arr2 = new Array();
            }

            arr2.push(v.label);

            prev = v.field;
        })

        arr.push(arr2);
        setDataList(arr);
    }, []);

    return (
        <div>
            
           { dataList &&
                dataList.map( (v, k) => {
                    return( 
                        <datalist key={"dp"+k} id={v.code}>
                            {
                                v.arr && v.arr.map( (v2, k2) => { 
                                    return <option value={v2} text={v2} key={"dp"+k+"v"+k2}></option> } 
                                )
                                }
                        </datalist>
                    )
                })
           }

        </div>
    )
}

export default DropDownMenu;