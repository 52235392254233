import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../../common/smallComponents.js/ReactTable";
import { getFrFormat } from "../../../functions/StrFunctions";

const Historic = (p) => {

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();
        arr.push(columnHelper.accessor('ar', 
            { header: 'Article', cell: (i) => <div className="text-center">{i.getValue()}</div> }));
        
            arr.push(columnHelper.accessor('field', {
            header: 'Champ',
            cell: (i) => <div className="text-center" dangerouslySetInnerHTML={{ __html: i.getValue() }}></div>
        }));
        
        arr.push(columnHelper.accessor('old_value', {
            header: 'Ancienne valeur',
            cell: (i) => <div className="text-center" dangerouslySetInnerHTML={{ __html: i.getValue() }}></div>
        }));
        
        arr.push(columnHelper.accessor('new_value', 
            { header: 'Nouvelle valeur', cell: (i) => <div className="text-center">{i.getValue()}</div> }));
        
        arr.push(columnHelper.accessor('user_change', {
            header: 'Utilisateur', cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('date_change', {
            header: 'Date', cell: (i) => <div className="text-center">
                { getFrFormat( i.getValue(), true )}
            </div>
        }));

        arr.push(columnHelper.accessor('justification', {
            header: 'Justification', cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));
        return arr;
    }

    //Set columns
    const columns = getColumns();

    return (
        <div>
            {p.histChange && <ReactTable columns={columns} data={p.histChange} top0={true} origin="hist_ar" />}
        </div>)
}

export default Historic;