import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LOGOUT } from "../../actions/types";

const LogOut = (props) => {
    const dispatch = useDispatch();

    useEffect( () => {
        localStorage.removeItem("user");

        dispatch({
            type: LOGOUT,
        });

        props.history.push("/login");
        window.location.reload();
    },[]);

    return(<div></div>);
}

export default LogOut;