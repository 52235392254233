import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Tools = (props) => {
    const { t } = useTranslation();

    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {t('tools')}
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">

                <a className="dropdown-item" href="/share-files">Envoi de fichiers</a>

                {props.isInternal && <Fragment>
                    <a className="dropdown-item" target="_blank" href="https://wkf.ms/45BEExU">Ticket intranet</a>
                    <a className="dropdown-item" href="/tool-box">Boîte à outils</a>
                    <a className="dropdown-item" href="/site-map">Plan du site</a>
                    <a className="dropdown-item" href="/focus/licenses">Comptes focus</a>

                    {props.roles && (props.roles.includes("ROLE_ADMIN") || props.roles.includes("ROLE_FORM_MANAGEMENT") ) &&
                        <a href={"/settings/form-mng/v1"} className="dropdown-item">
                            Form management
                        </a>
                    }                        
                   
                </Fragment>
                }


            </ul>
        </li>
    )

}

export default Tools;