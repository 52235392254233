import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { scrollToTop } from "../../functions/PageAnimation";
import getUrlParam from "../../functions/StrFunctions";
import CheckList from "../../services/customClient/CheckList";
import errorManagement from "../../services/errorManagement";
import { ChoiceForm } from "./smallContent/CheckChoices";
import CheckListForm from "./smallContent/CheckListForm";
import InterventionReport from "./smallContent/InterventionReport";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const Commissioning = (props) => {
    const [machineInfo, setMachineInfo] = useState();
    const [date, setDate] = useState();
    const [list, setList] = useState([]);
    const [form, setForm] = useState();
    
    const [machines, setMachines] = useState(['']);
    const [id, setId] = useState();
    const [compilation, setCompilation] = useState();

    const [reports, setReports] = useState();

    const [error, setError] = useState(null);
    const [msg, setMsg] = useState(null);
    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState(0);
    const lang = i18next.language;
    const { t } = useTranslation(['check-list', 'common']);



    useEffect(() => {
        var id = getUrlParam(props, "id");
        var machines = getUrlParam(props, "machines");

        if(!id){
            CheckList.getPendingFormsById("commissioning").then(
                (response) => {
                    setList(response);
                    setLoading(false);
                }, (error) => {
                    setError(errorManagement.handleError(props, error));
                    setLoading(false);
                    scrollToTop();
                }
            );
        }else{
            setId(id);
            CheckList.getChkCompilaitonById(machines, lang, id).then(
                (response) => {
                    response.compilation[0].parentIdx = 0;
                    setForm( response.compilation[0] );
                    setMachineInfo(response.machinesInfo[0]);

                    setCompilation(response.compilation);
                    setMachines(response.machinesInfo);
                    setReports(response.interventionReport);
                    setLoading(false);
                }, (error) => {
                    setLoading(false);
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                }
            );
        }     

    
    }, []);

    const changeTab = (idx) => {
        setTab(idx);
        var arr = [...compilation];
        arr[ form.parentIdx ] = {...form};
        setCompilation(arr);

        var o = {...arr[idx]}
        o.parentIdx = idx;
        setForm( o );
        setMachineInfo(machines[idx]);
    }

    const createCheckList = () => {
        setError();
        if (machines.length === 0) {
            setError("Saisissez au moins une machine");
            scrollToTop();
            return;
        }

        var arr = new Array();
        for (let i = 0; i < machines.length; i++)
            if (machines[i].trim() !== "" && machines[i].indexOf("/") !== -1) arr.push(machines[i])

        if (arr.length === 0) {
            setError("Saisissez le numéro de machine en incluant l'année de fabrication, ex: 21/1850");
            scrollToTop();
            return;
        }

        setLoading(true);
        CheckList.compileCheckListMachine(arr, "commissioning", lang).then(
            (r) => {
                window.location.href = "/customer-client/check-list/commissioning?id=" 
                    + r.id + "&machines=" + r.machines
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        );
    }

    function getResetBtn() {
        return <div className="text-center mb-2">
            <a href="/customer-client/check-list/commissioning">Changer de machine</a>
        </div>
    }

    return <Fragment>
        <h5 className="text-center">
            Commissioning {machineInfo && <Fragment>{machineInfo.affaire} - Mach: {machineInfo.no_machine} ({machineInfo.typ_config})</Fragment>}
        </h5>

        {loading && <WaitingRoundSnippers />}
        {error && <Fragment><ErrorMessage error={error} errorState={setError} />{getResetBtn()}</Fragment>}
        {msg && <Fragment><SuccessMessage msg={msg} msgState={setMsg} />{getResetBtn()}</Fragment>}

        {!id && <ChoiceForm list={list} setList={setList} machines={machines} setMachines={setMachines}
            createCheckList={createCheckList} loading={loading} />}

        {form && <Fragment>
            <ul className="nav nav-tabs fixed-header" style={{ backgroundColor: "white", overflowY: "auto" }}>
                { compilation.map( (v, k) => {
                    return <li className="nav-item fw-bold" key={"tab_"+k}>
                    <a className={"nav-link " + (tab === k ? "active" : "")} href="#"
                        onClick={() => changeTab(k)} key={"tab"+k}>Check list {v.machine}</a>
                    </li>
                })}

                

                <li className="nav-item fw-bold">
                    <a className={"nav-link " + (tab === compilation.length ? "active" : "")} href="#"
                        onClick={() => setTab(compilation.length)}>
                        Rapport d'intervention
                    </a>
                </li>

            </ul>

            { (tab >= 0 && tab < compilation.length ) &&
                <CheckListForm form={form} setForm={setForm} machineInfo={machineInfo} typeForm={"commissioning"}
                    setError={setError} tab={tab}/>}

            { (tab === compilation.length && compilation) && <InterventionReport typeForm={"commissioning"}
                date={date} setError={setError} compilation={compilation} setCompilation={setCompilation}
                reports={reports} setReports={setReports} machines={machines} id={id} props={props} lang={lang}/>}

        </Fragment>
        }



    </Fragment>
}

export default Commissioning;