import Moment from 'moment/moment';
import { useEffect, useState } from "react";
import { arrayRemoveElt, getKeyInt } from "../../../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../../../functions/PageAnimation";
import { confirmAlert } from 'react-confirm-alert';
import BusinessDao from '../../../../services/machines/BusinessDao';
import errorManagement from '../../../../services/errorManagement';

const BAContract = (p) => {
    const [comments, setComments] = useState("");
    const [date, setDate] = useState("");
    const [loading, setLoading] = useState(false);
    const [machineToOrder, setMachineToOrder] = useState([]);
    const [files, setFiles] = useState([]);


    const user = JSON.parse(localStorage.getItem("user"));

    Moment.locale("fr");

    const onCheckMachine = (e, k) => {
        var arr = [...machineToOrder];

        if (e.target.checked) { arr.push(k) }
        else { arr = arrayRemoveElt( arr, k ) }

        setMachineToOrder(arr);
    }

    const onCheckFiles = (e, v) => {
        var arr = [...files];

        if (e.target.checked) { arr.push( v) }
        else { arr = arrayRemoveElt(arr, getKeyInt(arr, "id", v.id) ) }

        setFiles(arr);
    }

    const send = () => {
        p.setError();

        if (machineToOrder.length === 0) {
            p.setError("Choisissez au moins une machine");
            scrollToTop();
            return;
        }

        var c = "";
        for (let i = 0; i <files.length; i++ ) c += files[i].type;
        if( c.indexOf("carton") === -1 || c.indexOf("implantation") === -1 ){
            p.setError("Choisissez les plans de cartons à envoyer ET d'implantation");
            scrollToTop();
            return;
        }

        
        var machines = [];
        var machNb = [];
        for( let i=0; i<machineToOrder.length; i++ ){
            var o = p.BAmachines[ machineToOrder[i] ];
           machines.push( o ); 
           machNb.push(o.no_machine);
        } 

        var o = {
            machines: machines,
            files: files,
            comment: comments,
            date: date
        }

        confirmAlert({
            title: "Confirmation",
            message: "Confirmez-vous la commande de: " + machNb.join(','),
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setLoading(true);

                        BusinessDao.sendTecmaFiles(o).then(
                            (response) => {
                                p.setMsg("Demande envoyée");
                                scrollToTop();
                                setLoading(false);
                            },
                            (error) => {
                                p.setError(errorManagement.handleError(p, error));
                                scrollToTop();
                                setLoading(false);
                            }
                        )
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    return <div style={{ padding: "10px" }}>

        <div className="card">
            <div className="card-header mb-3">
                <h4>Commande TECMA</h4>
            </div>

            <div className="d-flex">

                <div>
                    <h5>Machine(s) à commander</h5>
                    <ul className="list-group mb-3 me-3" style={{ width: "300px" }}>
                        {p.BAmachines && p.BAmachines.map((v, k) => {
                            return <li className="list-group-item" k={"ba" + k}>
                                <div className="form-check">
                                    {v.url && 
                                    <input className="form-check-input" type="checkbox" value={v}
                                        onChange={(e)=>onCheckMachine(e, k)} />
                                    }
                                    <label className="form-check-label" >
                                        {v.no_machine} ({v.typ_config})
                                    </label>
                                    {!v.url && <label className='text-danger'>FM non trouvée</label>}
                                </div>
                            </li>
                        })}

                    </ul>
                </div>

                <div style={{ width: "100%" }}>
                    <h5>Commentaire</h5>
                    <div className="form-floating mb-3">
                        <textarea className="form-control" placeholder="Leave a comment here"
                            onChange={(e) => setComments(e.target.value)} style={{ height: "100px" }}></textarea>
                        <label>Informations complémentaires à ajouter au mail</label>
                    </div>

                    <div className="input-group mb-3">
                        <span className="input-group-text">Date de mise à dispo (si différent du planning général)</span>
                        <input type="date" className="form-control"
                            onChange={(e) => setDate(e.target.value)} />
                    </div>
                </div>

            </div>


            <div className="d-flex">
                <div className="me-3">
                    <h5>Plan de cartons à envoyer</h5>
                    <ul className="list-group mb-3" style={{ width: "300px" }}>
                        {p.BAfiles && p.BAfiles.map((v, k) => {
                            return v.type === "carton" && <li className="list-group-item" k={"ba" + k}>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={v}
                                        onChange={(e)=>onCheckFiles(e, v)} />
                                    <label className="form-check-label" >
                                        {v.name}
                                    </label>
                                </div>
                            </li>
                        })}

                    </ul>
                </div>

                <div>
                    <h5>Plan d'implantation</h5>
                    <ul className="list-group mb-3" style={{ width: "300px" }}>
                        {p.BAfiles && p.BAfiles.map((v, k) => {
                            return v.type === "implantation" && <li className="list-group-item" k={"ba" + k}>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={v}
                                        onChange={(e)=>onCheckFiles(e, v)} />
                                    <label className="form-check-label" >
                                        {v.name}
                                    </label>
                                </div>
                            </li>
                        })}

                    </ul>
                </div>
            </div>

            <div className="text-center">
                <button className="btn btn-success" onClick={send} disabled={loading}>
                        {loading && <ButtonWaiting />}
                        Envoyer</button>
            </div>

        </div>


        <br></br>

    </div>

}

export default BAContract;