import { useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";
import usersDao from "../../services/settings/usersDao";

const CreateSignature = (p) => {
    const signature = useRef({});
    const user = JSON.parse(localStorage.getItem("user"));

    const clear = () => {
        signature.current.clear();
    }

    const save = () => {
        if( signature.current.isEmpty() ){
            alert("Merci de signer avant de valider !")
            return;
        }
       
        var signatureImg = signature.current.getTrimmedCanvas().toDataURL("image/png");
        user.signature = signatureImg;
        localStorage.setItem("user", JSON.stringify(user));
        usersDao.updateMyProfile(user.email, "signature", signatureImg);
        setTimeout(() => {
            window.location.reload();
        }, 500);        
    }

    return <div>
        <div style={{
            width: "100%", height: "100%", backgroundColor: "grey",
            opacity: "0.33", zIndex: "1", position: "absolute", top: "0", left: "0"
        }}>

        </div>
        <div className="custom-tooltip bg-info bg-gradient opacity-100">
            <h4 className="text-center">Création de votre signature</h4>
            <p className="text-center">Afin de générer des signatures automatiques, il nous faudrait enregister votre signature.
                <br />Signez donc dans l'encadré ci-dessous et validez.</p>

            <div className="text-center">
                <SignaturePad ref={signature} canvasProps={{ className: "signatureCanvas" }} />
                <br></br>
                
                <div className="display-flex">
                    <button className="btn btn-warning" onClick={clear} style={{margin: "auto"}}>
                        Effacer
                    </button>

                    <button className="btn btn-success" onClick={save} style={{margin: "auto"}}>
                        Valider
                    </button>
                </div>
            </div>
        </div>
    </div>
}

export default CreateSignature;