import { Fragment, useEffect, useState } from "react";
import getUrlParam from "../../functions/StrFunctions";
import CsContracts from "../../services/customClient/CsContracts";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import IncidentCard from "./smallContent/IncidentCard";
import { SideNavBarV3, isSideBarOpened } from "../../common/smallComponents.js/DesignedIpt";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import IncidentList from "./smallContent/IncidentList";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import IncidentDefaults from "./smallContent/IncidentDefaults";
import { confirmAlert } from "react-confirm-alert";

const IncidentTicket = (props) => {
    const [pin, setPin] = useState(isSideBarOpened());
    const [page, setPage] = useState("");
    const [defaults, setDefaults] = useState();
    const [hotline, setHotline] = useState();
    const [it, setIt] = useState();
    const [subsets, setSubsets] = useState();
    const [installers, setInstallers] = useState();
    const [machineInfo, setMachineInfo] = useState();
    const [id, setId] = useState();

    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [send, setSend] = useState(false);
    const [msg, setMsg] = useState();

    useEffect(() => {
        var page = getUrlParam(props, "page");
        var msg = getUrlParam(props, "msg");

        setId(getUrlParam(props, "id"));
        if (msg === "saved") setMsg("Sauvegardé");

        setPage(page);

        if (page === "update") {
            setLoading(true);
            get(getUrlParam(props, "machine"),
                parseInt(getUrlParam(props, "broken_art")) === 0 ? "ticket" : "broken",
                getUrlParam(props, "id"));
        }

    }, []);

    const onCreateTicket = (e) => {
        e.preventDefault();
        var machine = e.target.machine.value;
        var type = e.target.type.value;

        get(machine, type, null)
    }

    const get = (machine, type, id) => {
        setError();
        setLoading(true);
        CsContracts.getTicket(machine, type, id).then(
            (r) => {
                setLoading(false);
                setDefaults(r.defaults);
                setHotline(r.hotline);
                setIt(r.it);
                setSubsets(r.subsets);
                setMachineInfo(r.mach);
                setInstallers(r.installers);
                setPage("update");
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }

    const deleteIncident = () => {
        confirmAlert({
            title: "Confirmation",
            message: "Etes-vous sûr(e) de vouloir supprimer le ticket n°" + id,
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        CsContracts.deleteTicket(id).then(
                            (r) => {
                                window.location.href = "/customer-client/incident-ticket/list?page=list";
                            },
                            (error) => {
                                setError(errorManagement.handleError(props, error));
                                setLoading(false);
                                scrollToTop();
                            }
                        )
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    return <div className="bg-see" style={{ height: "91.5vh", marginTop: "-1.5em" }}>
        <SideNavBarV3 setPin={setPin} content={<div>

            <div className="ps-3">

                { (id && !loading ) && <Fragment>
                    <h6 className="text-center mb-3">Ticket incident n°{id}</h6>
                    <a href="#" onClick={()=>setSend(true)}>Envoyer cette fiche</a>
                    <br></br><br></br>
                    <a href="#" onClick={deleteIncident}>Supprimer cette fiche</a>
                    <br></br><br></br>
                </Fragment>}

                <h6 className="text-center mb-3">Menu</h6>
                <a href="/customer-client/incident-ticket/list?page=new">Créer nouveau ticket</a>
                <br></br><br></br>

                <a href="/customer-client/incident-ticket/list?page=list">Liste des tickets</a><br></br><br></br>

                <a href="/customer-client/incident-ticket/list?page=defaults">Liste des défauts</a>
            </div>
        </div>} />

        <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>
            {msg && <SuccessMessage msg={msg} msgState={setMsg} />}
            {loading && <WaitingRoundSnippers />}

            {page === "new" && <h3 className="text-center">Créer un ticket incident</h3>}
            {page === "list" && <h3 className="text-center">List des tickets incident</h3>}

            {error && <ErrorMessage error={error} errorState={setError} />}

            {page === "new" &&
                <form className="col-4 offset-4 mt-3 card" onSubmit={onCreateTicket}>
                    <h5 className="text-center">N° de machine</h5>
                    <input className="form-control mb-3 fw-bold text-center" name="machine"
                        placeholder="Saisissez le n° de machine" required autoFocus />

                    <br></br>
                    <h5 className="text-center">Type de ticket</h5>
                    <div className="d-flex mb-3">
                        <div className="m-auto">
                            <input type="radio" className="btn-check" name="type" id="ticket" value="ticket" required />
                            <label className="btn btn-outline-secondary" htmlFor="ticket">Ticket incident</label>
                        </div>

                        <div className="m-auto">
                            <input type="radio" className="btn-check" name="type" id="broken" value="broken" />
                            <label className="btn btn-outline-secondary" htmlFor="broken">Pièce(s) cassée(s)</label>
                        </div>
                    </div>
                    <br />
                    <div className="text-center">
                        <button type="submit" className="btn btn-success" disabled={loading}>
                            {loading && <ButtonWaiting />}
                            Commencer
                        </button>
                    </div>
                </form>}

            {(page === "update" && it) && <IncidentCard defaults={defaults} hotline={hotline} installers={installers}
                it={it} setIt={setIt} subsets={subsets} machineInfo={machineInfo} setLoading={setLoading} setError={setError}
                props={props} loading={loading} send={send} setSend={setSend}/>}

            {page === "list" && <IncidentList props={props} />}

            {page === "defaults" && <IncidentDefaults props={props} />}

        </div>
    </div>

}

export default IncidentTicket;