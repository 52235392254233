import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL +"settings";

const getRoles = () => {
    return axios
        .post(API_URL + "/roles/get-list", {
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateDescription = (id, description) => {
    return axios
        .post(API_URL + "/roles/update-description", {
                fake_header: authHeader(),
                id: id,
                description: description
            }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const addRole = (name, description) => {
    return axios
        .post(API_URL + "/roles/add", {
                fake_header: authHeader(),
                name: name,
                description: description
            }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

/**
 * 
 * @param {*} name 
 * @param {*} description 
 * @returns 
 */
const deleteRole = (id) => {
    return axios
        .post(API_URL + "/roles/delete", {
                fake_header: authHeader(),
                id: id
            }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const requestAccess = (arr, user) => {
    return axios
        .post(API_URL + "/roles/request", {
            fake_header: authHeader(),
            arr: arr,
            user: user
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

export default {
    getRoles,
    updateDescription,
    addRole,
    deleteRole,
    requestAccess
};