import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import errorManagement from "../../services/errorManagement";
import rolesDao from "../../services/settings/rolesDao";

const RolesManagement = (props) => {
    const [roles, setRoles] = useState(null);
    const [missing, setMissing] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(null);
    const[newRole, setNewRole] = useState("");
    const[newDescriptionRole, setNewDescriptionRole] = useState("");

    useEffect( () => {
        rolesDao.getRoles().then(
            (response) => {
                setRoles(response.roles);
                setMissing(response.missing);
            },
            (error) => {
                setError( errorManagement.handleError(props, error) )
            }
        );
    }, []);

    const onChangeDescription = (e) => {
        if( e.target.value !== e.target.getAttribute("old_value") )
            rolesDao.updateDescription(e.target.id, e.target.value);
    }

    const onClickDescription = (e) => {
        e.target.setAttribute("old_value", e.target.value)
    }

    const onNewRoleChange = (e) => {
        setNewRole(e.target.value);
    }

    const onNewDescriptionRoleChange = (e) => {
        setNewDescriptionRole(e.target.value);
    }

    const addNewRole = (e) => {
        e.preventDefault();
        setLoading(true);

        if( newRole === "" || newDescriptionRole === "" ){
            alert("Merci de tout remplir")
            setLoading(false);
            return;
        }

        rolesDao.addRole(newRole, newDescriptionRole).then(
            (response) => {
                setRoles(response.roles);
                setMissing(response.missing);
                setLoading(false);
            },
            (error) => {
                setError( errorManagement.handleError(props, error) );
                setLoading(false);
            }
        );
    }

    const deleteRole = (e) => {
        const id = e.target.getAttribute("id");
        const name = e.target.getAttribute("role-name");

        confirmAlert({
            title: "Supprimer le rôle",
            message: "Etes-vous sûr de vouloir supprimer le rôle " + name + " ainsi que chez tous les utilisateurs ?",
            buttons: [
            {
                label: "Oui",
                onClick: () => {
                    setLoading(true);
                    rolesDao.deleteRole(id).then(
                        (response) => {
                            setRoles(response.roles);
                            setMissing(response.missing);
                            setLoading(false);
                            setError(null);
                        },
                        (error) => {
                            setError( errorManagement.handleError(props, error) );
                            setLoading(false);
                        }
                    );
                }
            },
            {
                label: "Non"
            }
        ]});

    }

    return(
        <div>
            <h1 className="text-center">Gestion des roles</h1>
            
            <br></br>

            {
                !roles && 
                <div className="text-center">
                    <div className="spinner-grow text-success" role="status"></div>
                    <div className="spinner-grow text-danger" role="status"></div>
                    <div className="spinner-grow text-warning" role="status"></div>
                    <div className="spinner-grow text-dark" role="status"></div>
                </div>
            }

            {
                error && 
                    <div className="alert alert-danger text-center font-weight-bold" role="alert"> {error} </div>
                
            }

            {
                missing && 
                    <div className="alert alert-danger" role="alert">
                        Il manque les rôle suivant, dans l'énumération ERole:
                        <br></br>
                        {
                            missing.map( (v, k) => 
                            <ul key={"miss"+k}>
                                <li>{v}</li>                                
                            </ul>
                            )
                        }
                    </div>
                
            }

            {
                roles && 
                    <div className="alert alert-secondary" role="alert">
                        <form onSubmit={addNewRole}>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">Nouveau</span>
                                </div>
                                <input type="text" className="form-control" placeholder="Role name" onChange={onNewRoleChange}/>
                                <input type="text" className="form-control" placeholder="Description" onChange={onNewDescriptionRoleChange}/>
                                <button type="submit" className="btn btn-success" disabled={loading}>
                                    {loading && ( <span className="spinner-border spinner-border-sm"></span> )}
                                    <span>Ajouter</span>
                                </button>
                            </div>
                        </form>
                    </div>
            }

            {
                roles && 
                    
                    roles.map( (v, k) => 
                        <ul key={k} className="list-group">
                            <li className="list-group-item">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">{v.id}</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Role name" defaultValue={v.name} readOnly/>
                                    <input type="text" className="form-control" placeholder="Description"  
                                            defaultValue={v.description ? v.description : undefined}
                                        id={v.id} onBlur={onChangeDescription} onClick={onClickDescription}/>
                                    <button type="button" className="btn btn-danger" onClick={deleteRole} 
                                            id={v.id} role-name={v.name}>
                                        {loading && ( <span className="spinner-border spinner-border-sm"></span> )}
                                        <span id={v.id}>Supprimer</span>
                                    </button>
                                </div>
                            </li>
                        </ul>
                    )
            }

        </div>
    );

}

export default RolesManagement;