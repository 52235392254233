import { createColumnHelper } from "@tanstack/react-table";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DesignedChckBox } from "../../common/smallComponents.js/DesignedIpt";
import ReactTable from "../../common/smallComponents.js/ReactTable";

const MySubordinates = (p) => {
    const { t } = useTranslation();
    const [displayBlocked, setDisplayBlocked] = useState(false);
    const [list, setList] = useState();

    useEffect(() => {
        var arr = [];

        if (displayBlocked) { setList(p.mySubordinates) }
        else {
            for (let i = 0; i < p.mySubordinates.length; i++)
                if (p.mySubordinates[i].statut !== "blocked") arr.push(p.mySubordinates[i])

            setList(arr);
        }


    }, [p.mySubordinates, displayBlocked]);


    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('username',
            { header: 'Username', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('email', {
            header: 'Email',
            cell: (i) => <div className="text-center" dangerouslySetInnerHTML={{ __html: i.getValue() }}></div>
        }));

        arr.push(columnHelper.accessor('last_name', {
            header: 'Nom',
            cell: (i) => <div className="text-center">
                <input className="form-control" defaultValue={i.getValue()}
                    onChange={(e) => p.onCollaboChange(i.row.id, "last_name", e)}></input>
            </div>
        }));

        arr.push(columnHelper.accessor('first_name',
            {
                header: 'Prénom', cell: (i) => <div className="text-center">
                    <input className="form-control" defaultValue={i.getValue()}
                        onChange={(e) => p.onCollaboChange(i.row.id, "first_name", e)}></input>
                </div>
            }));

        arr.push(columnHelper.accessor('trigram', {
            header: 'Trigramme', cell: (i) => <div className="text-center">
                <input className="form-control" defaultValue={i.getValue()}
                    onChange={(e) => p.onCollaboChange(i.row.id, "trigram", e)}></input>
            </div>
        }));

        arr.push(columnHelper.accessor('global_id', {
            header: 'Global ID', cell: (i) => <div className="text-center">
                <input className="form-control" defaultValue={i.getValue()}
                    onChange={(e) => p.onCollaboChange(i.row.id, "global_id", e)}></input>
            </div>
        }));

        arr.push(columnHelper.accessor('interim', {
            header: 'Intérimaire', cell: (i) => <div className="text-center">
                {i.getValue() === 0 ? "Non" : "Oui"}</div>
        }));

        arr.push(columnHelper.accessor('statut', {
            header: 'Statut', cell: (i) => <div className="text-center">
                <select className="form-select custom-select" 
                    onChange={(e)=>{p.changeStatut(e); p.onCollaboChange(i.row.id, "statut", e)}}
                    value={i.row.original.statut} data-user={i.row.original.email}>
                    <option value="">Choose...</option>
                    <option value="active">{t('active')}</option>
                    <option value="blocked">{t('blocked')}</option>
                </select>
            </div>
        }));

        arr.push(columnHelper.accessor('company', {
            header: t('company'), cell: (i) => <div className="text-center">
                <input className="form-control" value={i.getValue()}
                    onChange={(e) => p.onCollaboChange(i.row.id, "company", e)}></input>
            </div>
        }));

        arr.push(columnHelper.accessor('xx', {
            header: 'Mot de passe', cell: (i) => <div className="text-center">
                <input type="password" className="form-control"
                    onChange={(e) => p.onCollaboChange(i.row.id, "new_pwd", e)}></input>
            </div>
        }));

        arr.push(columnHelper.accessor('workstation', {
            header: 'Poste de travail', cell: (i) => <div className="text-center">
                <select className="form-select" defaultValue={i.getValue()}
                    onChange={(e) => p.onCollaboChange(i.row.id, "workstation", e)}>
                    <option selected>Choisissez...</option>
                    <option value="Cartérisation">Cartérisation</option>
                    <option value="Montage">Montage</option>
                    <option value="Intégration">Intégration</option>
                    <option value="Intégration électrique">Intégration électrique</option>
                </select>
            </div>
        }));

        arr.push(columnHelper.accessor('xxx', {
            header: '', cell: (i) => <div className="text-center">
                <button className="btn btn-outline-warning"
                    onClick={() => p.updateProfile(i.row.id)}>Modifier</button>
            </div>
        }));

        arr.push(columnHelper.accessor('xxxx', {
            header: '', cell: (i) => <div className="text-center">
                <button className="btn btn-outline-info"
                            data-user={i.row.original.username} data-email={i.row.original.email}
                            onClick={p.editRole}>Accès</button>
            </div>
        }));

        return arr;
    }

    //Set columns
    const [columns, setColumns] = useState(getColumns());

    return <Fragment>
        {list && <div>
            <div className="ms-3 mt-2 display-flex ">
                <DesignedChckBox checked={!displayBlocked} onChange={() => setDisplayBlocked(!displayBlocked)} />
                <label className="fw-bold ms-2">Masquer mes collaborateurs bloqués</label>
            </div>

            <ReactTable columns={columns} data={list} top0={true} origin="my-subordinate" />

        </div>}
    </Fragment>


}

export default MySubordinates;