import { useEffect, useState } from "react";

const CrMachines = (props) => {
    const [machines, setMachines] = useState([]);

    useEffect( () => {
        var machs = props.businessInfo.rc_machines;
        if(machs !== null){ setMachines( machs.split(";") ); } 
        else{ setMachines( Array(14).fill("")) }
    }, []);

    const onMachineEnter = (e, k) => {
        var arr = [...machines];
        arr[k] = e.target.value;
        setMachines(arr);
        props.updateBusinessField(e, arr.join(";"));        
    }


    return (
        < div className="table-responsive">
            <table className="table businessCardStyle">
                <thead>
                    <tr className="table-dark text-center rounded">
                        <th scope="col">Machines</th>
                    </tr>
                </thead>
                <tbody>
                    <tr >
                        <td className="border-end">
                            <div className="input-group mb-3">
                                <span className="input-group-text">Number of lines</span>
                                <input type="number" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.nb_lign_comp}
                                    data-field="nb_lign_comp" />

                                <span className="input-group-text">New machines</span>
                                <input type="number" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.nb_mach_neuve}
                                    data-field="nb_mach_neuve" />

                                <span className="input-group-text">Second hand machines</span>
                                <input type="number" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.nb_mach_occas}
                                    data-field="nb_mach_occas" />

                                    <span className="input-group-text">Retrofit</span>
                                    <input type="number" className="form-control" readOnly={props.readOnly}
                                        onBlur={props.updateBusinessField} defaultValue={props.businessInfo.retrofit}
                                        data-field="retrofit" />

                                <span className="input-group-text">Packing process</span>
                                <input type="text" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.procede_emb}
                                    data-field="procede_emb" list="procede_emb"/>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td className="d-flex flex-wrap">
                        {machines.map((v, k) => {
                            return <input key={"k"+k} className="form-control ms-2 mb-2" 
                                        data-field="rc_machines" readOnly={props.readOnly}
                                        style={{width: "150px"}} placeholder={"Machine " + (k+1)}
                                        defaultValue={v} onBlur={(e) => onMachineEnter(e, k)}></input>
                        })}
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default CrMachines;