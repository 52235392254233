import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getHeight } from "../../../functions/PageAnimation";

const Storehouse = (props) => {
    const { t } = useTranslation();

    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Magasin
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown" id="storehouse-navarbar-menu"
                style={{ maxHeight: getHeight("storehouse-navarbar-menu", 100), overflowY: "auto" }}>
                {/** LABELS */}
                <a className="dropdown-item" href="/storehouse/articles/tags">Etiquettes</a>
                <div className="dropdown-divider"></div>

                {/** TABLES */}
                <a className="dropdown-item disabled dropdown-title text-center fw-bold" href="#">Tableaux</a>
                <a className="dropdown-item" href="/storehouse/articles/locations">Tableau de renvois</a>
                <a className="dropdown-item" href="/storehouse/storage/b2">Tableau de stock B+2</a>
                <a className="dropdown-item" href="/storehouse/inventory">Tableau d'inventaire</a>
                <a className="dropdown-item" href="/storehouse/rotating/inventory">Tableau d'inventaire tournant</a>
                <a className="dropdown-item" href="/storehouse/storage/receiption">Tableau rangement réception</a>
                <a className="dropdown-item" href="/storehouse/storage-control">Tableau contrôle rangement</a>

                <div className="dropdown-divider"></div>

                {/** JARVIS */}
                <a className="dropdown-item disabled dropdown-title text-center fw-bold" href="#">J.A.R.V.I.S</a>
                <li>
                    <form action={"/purchase/order/edition"}>
                        <div className="form-floating mb-3 col-10 offset-1" style={{ display: "flex" }}>
                            <input type="text" className="form-control" id="orderView" name="order" placeholder="N° de commande" required />
                            <label htmlFor="orderView">N° de commande</label>
                            <button className="btn btn-success">ok</button>
                        </div>
                    </form>
                </li>
                <a className="dropdown-item" href="/article/info/search">Affichage article réel</a>
                <a className="dropdown-item" href="/storehouse/pc/move-out">Mouvements de pièces</a>
                <a className="dropdown-item" href="/storehouse/articles/request/list">Sortie comptoir</a>

                <div className="dropdown-divider"></div>

                {/** SAV */}
                <a className="dropdown-item disabled dropdown-title text-center fw-bold" href="#">SAV</a>
                <a className="dropdown-item" href="/customer-client/picking/list">Planning</a>
                <a className="dropdown-item" href="http://10.141.44.140:8080/storehouse/delivery-note/free-packing-request/list">Tableau de colisages </a>
                <a className="dropdown-item" href="/article/kit/need/list">Kits repa</a>

                <div className="dropdown-divider"></div>

                {/** Listing */}
                <a className="dropdown-item dropdown-title text-center fw-bold" style={{ color: "#adb5bd" }}
                    href="/storehouse/machine/move-out">Affaires</a>

                <form action="/storehouse/machine/move-out">
                    <div className="input-group mb-3 col-11">
                        <input type="text" className="form-control" placeholder="Série" name="machine" />
                        <button type="submit" className="btn btn-success">Go!</button>
                    </div>
                </form>

                <div className="dropdown-divider"></div>

                {/** Affiche */}
                <a className="dropdown-item" href="/storehouse/poster">Affiche</a>

            </ul>
        </li>
    )

}

export default Storehouse;