/**
 * send error param error={...}
 * send errorState param errorState={setError}
 * @param {*} props 
 */
const ErrorMessage = (props) => {
      const close = () => {
            props.errorState();
      }

      return <div className={"alert alert-danger " + ( props.noCenter ? "" : "text-center") + " fw-bold"} role="alert">
                  <span dangerouslySetInnerHTML={{ __html: props.error }}></span>
                  {
                        props.errorState &&
                              <button type="button" className="btn-close" aria-label="Close" onClick={close}
                                    style={{ position: "absolute", right: "5px", top: "5px" }}></button>
                  }
            </div> 
    

}

export const InfoMsg = (props) => {
      const close = () => {
            props.infoState();
      }

      return <div className="alert alert-info text-center fw-bold" role="alert">
                  <span dangerouslySetInnerHTML={{ __html: props.info }}></span>
                  {
                        props.infoState &&
                              <button type="button" className="btn-close" aria-label="Close" onClick={close}
                                    style={{ position: "absolute", right: "5px", top: "5px" }}></button>
                  }
            </div> 
}

{/** warning={warning} + warningState={warningState} */}
export const WarningMsg = (props) => {
      const close = () => {
            props.warningState();
      }

      return <div className="alert alert-warning text-center fw-bold" role="alert">
                  <span dangerouslySetInnerHTML={{ __html: props.warning }}></span>
                  {
                        props.warningState &&
                              <button type="button" className="btn-close" aria-label="Close" onClick={close}
                                    style={{ position: "absolute", right: "5px", top: "5px" }}></button>
                  }
            </div> 
}

export default ErrorMessage;