import { Fragment, useEffect, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { ArticleAutoComplete, SideNavBarV3, isSideBarOpened } from "../../common/smallComponents.js/DesignedIpt";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { arrayRemoveElt, sortArrayNumber } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import errorManagement from "../../services/errorManagement";
import { downloadTempFile } from "../../services/functions/FilesProcess";
import Movement from "../../services/storehouse/Movement";
import { useReactToPrint } from "react-to-print";

const Inventory = (props) => {
    const [pin, setPin] = useState(isSideBarOpened());
    const [list, setList] = useState();
    const [locations, setLocations] = useState();
    const [chosenLoc, setChosenLoc] = useState();
    const [chosenList, setChosenList] = useState();
    const [error, setError] = useState();
    const year = new Date().getFullYear();
    const [nbElt, setNbElt] = useState(0);
    const [downloading, setDownloading] = useState(false);
    const [multipleRes, setMultipleRes] = useState();
    const [article, setArticle] = useState("");
    const user = JSON.parse(localStorage.getItem("user"));
    const n1 = user && ( user.roles.includes("ROLE_STOREHOUSE_N1") || user.roles.includes("ROLE_ADMIN") );

    useEffect(() => {
        Movement.getInventoryLocations().then(
            (r) => {
                var loc = [];
 
                for( let i=0; i<r.length; i++ ) if( !loc.includes( r[i].location ) ) loc.push( r[i].location )
                
                setLocations(loc);
                setList(r)
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            })
    }, []);

    

    const onLocationChoose = (e, val) => {
        e && e.preventDefault();
        var selected = e ? e.target.loc.value : val;

        setChosenLoc(selected);

        var arr = [];
        var cpt = 0;

        var found = false;
        for (let i = 0; i < list.length; i++) {
            var v = list[i];

            if (v.location === selected) {
                found = true;
                cpt++;
                v.total = getQty(v.fnc) + getQty(v.b2) + getQty(v.comatel) + getQty(v.mass) + getQty(v.stock);

                if (arr.length === 0 || arr[arr.length - 1].ar !== v.ar) {
                    v.group = [{ ...v }];
                    arr.push(v);
                } else {
                    arr[arr.length - 1].total += (v.total || 0)
                    arr[arr.length - 1].group.push(v);
                }

            } else if (found) { break; }
        }

        arr = sortArrayNumber(arr, "id", "desc");

        setChosenList(arr);
        setNbElt(cpt);
    }

    useEffect( () => {
        if( chosenList && article !== "" ){
            const element = document.querySelector('.bg-warning');
            if (element) 
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            
        }
    }, [chosenList] );

    const changeQty = (k, k2, field, val) => {
        var list = [...chosenList];

        if (k2 === 0) list[k][field] = val;

        var group = list[k].group;
        group[k2][field] = val;

        var tot = 0;

        for (let i = 0; i < group.length; i++) {
            var subTot = getQty(group[i].fnc) + getQty(group[i].b2) + getQty(group[i].comatel) +
                getQty(group[i].mass) + getQty(group[i].stock);
            group[i].total = subTot;
            tot += subTot;
        }

        list[k].total = tot;
        list[k].group = group;

        setChosenList(list);

        //Save in data base
        Movement.updateInventoryField(field, group[k2].id, val);
    }

    const deleteRow = (k, k2) => {
        var list = [...chosenList];
        var id = list[k].group[k2].id;

        confirmAlert({
            title: "Confirmation",
            message: "Confirmez-vous la suppression du contenant de l'article " + list[k].group[k2].ar + " ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        if (list[k].group.length === 1) {
                            list = arrayRemoveElt(list, k);
                        } else {
                            var gp = [...list[k].group];
                            var tot = gp[k2].total;

                            gp = arrayRemoveElt(gp, k2);
                            list[k].total -= tot;
                            list[k].group = gp;
                        }

                        setNbElt(nbElt - 1);

                        setChosenList(list);

                        //Save in database
                        Movement.deleteInventoryLocations(id);
                    }
                },
                {
                    label: "Non"
                }
            ]
        });

    }

    function getQty(val) { if (val === "" || val === 0 || isNaN(parseInt(val))) return 0; return parseInt(val) }

    const clearLocation = () => {
        confirmAlert({
            title: "Confirmation",
            message: "Confirmez-vous la suppression de TOUS les contenants "
                + (chosenLoc.startsWith("ET-") ? "de l'étagère " : "du rack ") + chosenLoc + " ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        Movement.deleteInventoryAllLocations(chosenLoc);
                        setChosenLoc();
                        setChosenList();
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const downloadXls = (value) => {
        setDownloading(true);
        Movement.downloadXls(value).then(
            (r) => {
                downloadTempFile(r)
                setDownloading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            })
    }

    const downloadArchive = () => {
        setDownloading(true);
        Movement.downloadArchive().then(
            (r) => {
                downloadTempFile(r)
                setDownloading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            })
    }

    const onArticleChoose = (ar) => {
        var arr = [];

        for (let i = 0; i < list.length; i++) {
            if (list[i].ar.toUpperCase().trim() === ar.toUpperCase().trim() &&
                !arr.includes(list[i].location))
                arr.push(list[i].location)
        }

        if (arr.length === 0) {
            setMultipleRes([]);
        } else if (arr.length === 1) {
            onLocationChoose(null, arr[0])
        } else {
            setMultipleRes(arr)
        }
    }

    return <div className="bg-see navbarV3 right-side" id="inventory">
        <SideNavBarV3 setPin={setPin} content={<div className="p-2">

            {chosenLoc && <Fragment>
                <div className="mb-3">
                    <h6 className="text-center">Choisir un autre emplacement</h6>
                    <form className="d-flex" onSubmit={onLocationChoose} >
                        <div className="ms-auto me-1" style={{ width: "100px" }}>
                            <select className="form-select" name="loc" required>
                                <option value="">Choisissez...</option>
                                {locations.map((v, k) => {
                                    return <option key={"loc" + k} value={v}>{v}</option>
                                })}
                            </select>
                        </div>

                        <button className="btn btn-secondary me-auto">Voir</button>
                    </form>
                </div>

                <div className="mb-3">
                    <a href="#" onClick={()=>window.print()}>Imprimer</a>
                </div>

                <div className="mb-3">
                    {downloading && <ButtonWaiting />}
                    {!downloading &&
                        <a href="#" onClick={() => downloadXls(chosenLoc)}>Télécharger l'Excel de {chosenLoc} pour Focus</a>}

                </div>

                {article !== "" &&
                    <div className="mb-3">
                        <a href="#" onClick={() => { setArticle("") }}>Effacer la recherche d'AR</a>
                    </div>
                }

                <div className="mb-3">
                    <a href="#" onClick={() => { setChosenList(); setChosenLoc() }}>Retour à l'accueil</a>
                </div>

                <br></br><br></br>
                {n1 && 
                <div>
                    <a className="text-danger" href="#" onClick={clearLocation}>Vider {chosenLoc.startsWith("ET-") ?
                        "cette étagère" : "ce rack"}</a>
                </div>}
            </Fragment>}



            { (n1 && !chosenLoc) && <Fragment>
                <div className="mt-auto">
                    <a href="#" className="text-danger">Supprimer tous les enregistrements</a>
                </div>
            </Fragment>}

        </div>} />

        <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>

            {error && <ErrorMessage error={error} errorState={setError} />}

            {!chosenLoc && <Fragment>
                <h4 className="text-center">Tableau d'inventaire</h4>
                <div className="alert alert-info">
                    <p className="fw-bold">Remarques & Rappels de quelques règles:</p>
                    <ul>
                        <li>Lors de la saisie des articles à inventorier, s'assurer que la case
                            "Prendre en compte les articles dont la quantité en stock est égale à 0" soit
                            décochée</li>

                        <li>Lors de la saisie des articles à inventorier, si vous filtrez sur les emplacements,
                            rien ne garantit que les articles qui sont hors stock ne soient pas inclus
                            (possibles erreurs de remplissage de la fiche article)</li>

                        <li className="text-danger">Avant d'importer le fichier de saisie d'inventaire dans Focus,
                            lancer la fonctionnalité "Correction de la qté. stk. de la fiche article/ qtés. emplac.</li>
                    </ul>
                </div>

                {!locations && <WaitingRoundSnippers />}

                {locations && <div>
                    <h5 className="text-center">Choisissez l'emplacement de l'armoire</h5>

                    <form className="d-flex mb-3" onSubmit={onLocationChoose} >
                        <div className="ms-auto me-1" style={{ width: "100px" }}>
                            <select className="form-select" name="loc" required>
                                <option value="">Choisissez...</option>
                                {locations.map((v, k) => {
                                    return <option key={"loc" + k} value={v}>{v}</option>
                                })}
                            </select>
                        </div>

                        <button className="btn btn-secondary me-auto">Voir</button>
                    </form>
                    <br></br>

                    <h5 className="text-center">Rechercher un article</h5>
                    <div className="text-center mb-3">
                        <ArticleAutoComplete value={article} onChooseArt={(e) => onArticleChoose(e)}
                            setValue={(val) => setArticle(val)} searchDetail={false} />

                        {multipleRes && <div className="alert alert-secondary col-4 offset-4 mt-3">
                            <h6 className="text-center mt-3">
                                {multipleRes.length === 0 && "Aucun enregistrement trouvé"}
                                {multipleRes.length > 0 && "Plusieurs emplacements trouvés"}
                            </h6>
                            <ul className="list-group">
                                {multipleRes.map((v, k) => {
                                    return <li key={"multi" + k} className="list-group-item list-group-item-secondary"
                                        onClick={() => { setMultipleRes(); onLocationChoose(null, v) }}>{v}</li>
                                })}
                            </ul>
                        </div>}

                    </div>

                    <h5 className="text-center">Télécharger le fichier Excel pour importer dans Focus</h5>
                    <div className="text-center mb-3">
                        <button className="btn btn-outline-success"
                            onClick={() => downloadXls(null)} disabled={downloading}>
                            {downloading && <ButtonWaiting />}Télécharger</button>
                    </div>
                    <br></br>

                    <h5 className="text-center">Télécharger le fichier Excel d'archive de l'inventaire</h5>
                    <div className="text-center mb-3">
                        <button className="btn btn-outline-success"
                            onClick={downloadArchive} disabled={downloading}>
                            {downloading && <ButtonWaiting />}Télécharger</button>
                    </div>

                </div>}

            </Fragment>}

            {chosenLoc && <div>
                <h2 className="text-center">INVENTAIRE {year}: {chosenLoc}</h2>

                <table className="table table-bordered text-center">
                    <thead>
                        <tr>
                            <th rowSpan={2} className="ar-col">AR</th>
                            <th rowSpan={2} className="ref-col">DÉSIGNATION / PLAN</th>
                            <th colSpan={5}>QTE</th>
                            <th rowSpan={2} className="qty-col">TOTAL</th>
                            <th rowSpan={2} className="no-print"></th>
                        </tr>
                        <tr>
                            <th className="qty-col">FNC</th>
                            <th className="qty-col">B+2</th>
                            <th className="qty-col">COMATEL</th>
                            <th className="qty-col">MASSE</th>
                            <th className="qty-col">STOCK</th>
                        </tr>
                    </thead>
                    <tbody>
                        {chosenList.map((v, k) => {
                            return <Fragment>
                                {v.group.length === 1 &&
                                    <tr key={"inv" + k}>
                                        <td className={article === v.ar ? "bg-warning bg-gradient" : ""}>{v.ar}</td>
                                        <td>{v.ref}</td>
                                        <td>
                                            <input className="form-control ms-auto me-auto no-print" type="number"
                                                style={{ width: "100px" }} onChange={(e) => changeQty(k, 0, "fnc", e.target.value)}
                                                value={v.fnc || ""} min={0}></input>

                                            <div className="text-center print-only">{v.fnc || ""}</div>
                                        </td>
                                        <td>
                                            <input className="form-control ms-auto me-auto no-print" type="number"
                                                style={{ width: "100px" }} onChange={(e) => changeQty(k, 0, "b2", e.target.value)}
                                                value={v.b2 || ""} min={0}></input>

                                            <div className="text-center print-only">{v.b2 || ""}</div>
                                        </td>
                                        <td>
                                            <input className="form-control ms-auto me-auto no-print" type="number"
                                                style={{ width: "100px" }} onChange={(e) => changeQty(k, 0, "comatel", e.target.value)}
                                                value={v.comatel || ""} min={0}></input>

                                            <div className="text-center print-only">{v.comatel || ""}</div>
                                        </td>
                                        <td>
                                            <input className="form-control ms-auto me-auto no-print" type="number"
                                                style={{ width: "100px" }} onChange={(e) => changeQty(k, 0, "mass", e.target.value)}
                                                value={v.mass || ""} min={0}></input>

                                            <div className="text-center print-only">{v.mass || ""}</div>
                                        </td>
                                        <td>
                                            <input className="form-control ms-auto me-auto no-print" type="number"
                                                style={{ width: "100px" }} onChange={(e) => changeQty(k, 0, "stock", e.target.value)}
                                                value={v.stock || ""} min={0}></input>

                                            <div className="text-center print-only">{v.stock || ""}</div>
                                        </td>
                                        <td className="text-center align-middle fw-bold">{v.total}</td>

                                        <td className="no-print cursor-pointer" onClick={() => deleteRow(k, 0)}>
                                            <img src="/common/remove_icon.png" style={{ width: "30px" }}></img>
                                        </td>
                                    </tr>
                                }

                                {v.group.length > 1 && <Fragment>
                                    {v.group.map((v2, k2) => {
                                        return <tr key={"inv" + k + '_' + k2}>
                                            <td className={article === v.ar ? "bg-warning bg-gradient" : "grey-back"}>{v.ar}</td>
                                            <td className="grey-back">{v.ref}</td>
                                            <td className={"text-start " + (k2 === 0 ? "grey-back" : "bg-dark")}>
                                                {k2 === 0 && <Fragment>
                                                    <input className="form-control ms-auto me-auto no-print" type="number"
                                                        style={{ width: "100px" }} onChange={(e) => changeQty(k, k2, "fnc", e.target.value)}
                                                        value={v2.fnc || ""} min={0}></input>

                                                    <div className="text-center print-only">{v2.fnc || ""}</div></Fragment>
                                                }
                                            </td>
                                            <td className={"text-start " + (k2 === 0 ? "grey-back" : "bg-dark")}>
                                                {k2 === 0 && <Fragment>
                                                    <input className="form-control ms-auto me-auto no-print" type="number"
                                                        style={{ width: "100px" }} onChange={(e) => changeQty(k, k2, "b2", e.target.value)}
                                                        value={v2.b2 || ""} min={0}></input>

                                                    <div className="text-center print-only">{v2.b2 || ""}</div></Fragment>
                                                }
                                            </td>
                                            <td className={"text-start " + (k2 === 0 ? "grey-back" : "bg-dark")}>
                                                {k2 === 0 && <Fragment>
                                                    <input className="form-control ms-auto me-auto no-print" type="number"
                                                        style={{ width: "100px" }} onChange={(e) => changeQty(k, k2, "comatel", e.target.value)}
                                                        value={v2.comatel || ""} min={0}></input>

                                                    <div className="text-center print-only">{v2.comatel || ""}</div></Fragment>
                                                }
                                            </td>
                                            <td className="text-start grey-back">
                                                <input className="form-control ms-auto me-auto no-print" type="number"
                                                    style={{ width: "100px" }} onChange={(e) => changeQty(k, k2, "mass", e.target.value)}
                                                    value={v2.mass || ""} min={0}></input>

                                                <div className="text-center print-only">{v2.mass || ""}</div>
                                            </td>
                                            <td className="text-start grey-back">
                                                <input className="form-control ms-auto me-auto no-print" type="number"
                                                    style={{ width: "100px" }} onChange={(e) => changeQty(k, k2, "stock", e.target.value)}
                                                    value={v2.stock || ""} min={0}></input>

                                                <div className="text-center print-only">{v2.stock || ""}</div>
                                            </td>
                                            <td className="text-center grey-back align-middle fw-bold">{v2.total || 0}</td>

                                            <td className="no-print cursor-pointer" onClick={() => deleteRow(k, k2)}>
                                                <img src="/common/remove_icon.png" style={{ width: "30px" }}></img>
                                            </td>
                                        </tr>


                                    })}

                                    <tr>
                                        <td colSpan={7} className="text-end fw-bold">Total {v.ar}</td>
                                        <td className="fw-bold">{v.total || 0}</td>
                                        <td className="no-print"></td>
                                    </tr>
                                </Fragment>
                                }
                            </Fragment>
                        })}
                    </tbody>
                </table>

                <div className="text-center fw-bold mb-3">
                    Nombre de contenants sur l'emplacement: {nbElt}
                </div>

                <div className="print-only fw-bold mt-auto">
                    <table className="table table-bordered text-center">
                        <tbody>
                            <tr>
                                <td className="grey-back">REPORT LE:</td>
                                <td style={{ minWidth: "150px" }}></td>
                                <td className="grey-back">PAR:</td>
                                <td style={{ minWidth: "150px" }}></td>
                                <td className="grey-back">SIGNATURE</td>
                                <td style={{ minWidth: "150px" }}></td>
                            </tr>

                            <tr>
                                <td className="grey-back">MASSE LE:</td>
                                <td style={{ minWidth: "150px" }}></td>
                                <td className="grey-back">PAR:</td>
                                <td style={{ minWidth: "150px" }}></td>
                                <td className="grey-back">SIGNATURE</td>
                                <td style={{ minWidth: "150px" }}></td>
                            </tr>

                            <tr>
                                <td className="grey-back">STOCK LE:</td>
                                <td style={{ minWidth: "150px" }}></td>
                                <td className="grey-back">PAR:</td>
                                <td style={{ minWidth: "150px" }}></td>
                                <td className="grey-back">SIGNATURE</td>
                                <td style={{ minWidth: "150px" }}></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

               
             
                <div className="pagebreak"></div>

                <h2 className="text-center">CONTRÔLE : {chosenLoc}</h2>

                <table className="table table-bordered text-center">
                    <thead>
                        <tr>
                            <th className="ar-col">AR</th>
                            <th className="ref-col">DÉSIGNATION / PLAN</th>
                            <th >2ème COMPTAGE</th>
                            <th >3ème COMPTAGE (si erreur)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {chosenList.map((v, k) => {
                            return <Fragment>
                                {v.group.length === 1 &&
                                    <tr key={"inv" + k}>
                                        <td className={article === v.ar ? "bg-warning bg-gradient" : ""}>{v.ar}</td>
                                        <td>{v.ref}</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                }

                                {v.group.length > 1 && <Fragment>
                                    {v.group.map((v2, k2) => {
                                        return <tr key={"inv" + k + '_' + k2}>
                                            <td className={article === v.ar ? "bg-warning bg-gradient" : "grey-back"}>{v.ar}</td>
                                            <td className="grey-back">{v.ref}</td>
                                            <td className={"text-start " + (k2 === 0 ? "grey-back" : "bg-dark")}></td>
                                            <td className={"text-start " + (k2 === 0 ? "grey-back" : "bg-dark")}></td>
                                        </tr>


                                    })}
                                </Fragment>
                                }
                            </Fragment>
                        })}
                    </tbody>
                </table>

                <div className="text-center fw-bold mb-3">
                    Nombre de contenants sur l'emplacement: {nbElt}
                </div>

                <div className="print-only fw-bold">
                    <table className="table table-bordered text-center align-middle">
                            <tr>
                                <td className="grey-back ">2ème COMPTAGE LE:</td>
                                <td style={{ minWidth: "90px" }}></td>
                                <td className="grey-back">PAR:</td>
                                <td style={{ minWidth: "90px" }}></td>
                                <td className="grey-back">SIGNATURE</td>
                                <td style={{ minWidth: "90px" }}></td>
                            </tr>

                            <tr>
                                <td className="grey-back">3ème COMPTAGE LE:</td>
                                <td style={{ minWidth: "90px" }}></td>
                                <td className="grey-back">PAR:</td>
                                <td style={{ minWidth: "90px" }}></td>
                                <td className="grey-back">SIGNATURE</td>
                                <td style={{ minWidth: "90px" }}></td>
                            </tr>
                    </table>
                </div>

            </div>}

        </div>

    </div>
}

export default Inventory;