import Moment, { weekdays } from "moment";
import fahDao from "../../../services/common/fahDaoV2";
import errorManagement from "../../../services/errorManagement";
import usersDao from "../../../services/settings/usersDao";
import { getDaysOfWeek } from "../../../functions/StrFunctions";


const getHoursInstaller = (user, weekParam, yearParam, oldBusiness, oldWeek, oldYear, oldHours, setHours, setEmptyObj, setError, setLoading, props) => {

    fahDao.getInstallerHours(user.focus_id, weekParam, yearParam, oldBusiness, oldWeek, oldYear, oldHours).then(
        (response) => {
            //Wait for schedule to be able to calculate 
            var timeout = setInterval(() => {
                clearInterval(timeout);
                setHours(response.hours);
                setEmptyObj(response.emptyObj);
                setLoading(false);
            }, 100);
        }, (error) => {
            setError(errorManagement.handleError(props, error));
            setLoading(false);
        }
    );
}

const getUser = (user, userInfo, setUserInfo, setError, setLoading, props) => {
    usersDao.getUserByTrigramFocus(user && user.trigram).then(
        (response) => {
            var o = { ...userInfo };
            o.focusInfo = response;
            setUserInfo(o);
        }, (error) => {
            setError(errorManagement.handleError(props, error));
            setLoading(false);
        }
    );
}

const getInstaller = (p, setWeekDays, setAbs, setHours, setEmptyObj, member, setMember, setLastTabLoad, 
        setResponse, setError, props, setSoldeC2) => {

    fahDao.getInstallerTimeClocking(p.focus_id, p.week, p.year, p.oldBusiness, p.oldWeek,
        p.oldYear, p.oldHours, p.start, p.end, p.trigram).then(
            (r) => {
                //console.log(r)
                setResponse(r);
                setWeekDays(getWeekDays(p.week, p.year, r.holidays, r.schedule));
                setAbs(r.absences);
                setHours(r.hours);
                setEmptyObj(r.hours);
                setSoldeC2(r.c2_solde);

                if( member ){
                    var o = { ...member };
                    o.focusInfo = r.user;
                    setMember(o);
                }                

                if( setLastTabLoad ) setLastTabLoad(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error))
            }
        );
}

/**
 * 
 */
function getWeekDays(week, year, holidays, schedule) {
    var arr = getDaysOfWeek(week, year);

    //Get holidays
    arr.map(v => {
        if (holidays.includes(Moment(v.en_date).format("YYYY-MM-DD"))) {
            v.holiday = "Férié";
            v.holidayClass = "holiday";
            v.schedule = 0;
        } else {
            v.holiday = "";
            v.holidayClass = "";
        }
    });

    // Get schedule
    arr[0].schedule = parseFloat(schedule.lundi_am) + parseFloat(schedule.lundi_ap);
    arr[1].schedule = parseFloat(schedule.mardi_am) + parseFloat(schedule.mardi_ap);
    arr[2].schedule = parseFloat(schedule.mercredi_am) + parseFloat(schedule.mercredi_ap);
    arr[3].schedule = parseFloat(schedule.jeudi_am) + parseFloat(schedule.jeudi_ap);
    arr[4].schedule = parseFloat(schedule.vendredi_am) + parseFloat(schedule.vendredi_ap);
    arr[5].schedule = parseFloat(schedule.samedi_am) + parseFloat(schedule.samedi_ap);
    arr[6].schedule = parseFloat(schedule.dimanche_am) + parseFloat(schedule.dimanche_ap);

    return arr;
}

export default {
    getHoursInstaller,
    getUser,
    getInstaller
}