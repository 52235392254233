import { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import getUrlParam, { getBase64 } from "../../functions/StrFunctions";
import BusinessDao from "../../services/machines/BusinessDao";
import errorManagement from "../../services/errorManagement";
import { scrollToTop } from "../../functions/PageAnimation";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import "../../css/machines/businessCard.scss";
import DropDownMenu from "./smallCom/business/dropDownMenu";
import PartOne from "./smallCom/business/PartOne";
import PartTwo from "./smallCom/business/PartTwo";
import PartThree from "./smallCom/business/PartThree";
import PartFour from "./smallCom/business/PartFour";
import PartFive from "./smallCom/business/PartFive";
import ShareFileDao from "../../services/common/ShareFileDao";
import PartSix from "./smallCom/business/PartSix";
import PartSeven from "./smallCom/business/PartSeven";
import PartEight from "./smallCom/business/PartEight";
import BAContract from "./smallCom/business/BAContract";
import DeliveryNote from "./smallCom/business/DeliveryNote";
import CustomerInvoice from "./smallCom/business/CustomerInvoice";

const BusinessCard = (props) => {

    const [business, setBusiness] = useState();
    const [businessInfo, setBusinessInfo] = useState();
    const [machines, setMachines] = useState();
    const [machinesCreationInfo, setMachinesCreationInfo] = useState();
    const [revisions, setRevisions] = useState();
    const [BAfiles, setBAFiles] = useState();
    const [configs, setConfigs] = useState();
    const [dp, setDp] = useState();
    const [annexes, setAnnexes] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [tab, setTab] = useState(0);
    const [imgDisplay, setImgDisplay] = useState();
    const [readOnly, setReadonly] = useState(true);
    const [BAmachines, setBAmachines] = useState([]);
    const [deliveryNote, setDeliveryNote] = useState([]);
    const [customerInvoice, setCustomerInvoice] = useState([]);
    const [businessInfoFocus, setBusinessInfoFocus] = useState();

    const user = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        var business = getUrlParam(props, "code");
        setBusiness(business);

        //Business info
        BusinessDao.getBusinessInfo(business).then(
            (response) => {
                setBusinessInfo(response);
                setLoading(false);

                if (user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_WRITE_FM")) setReadonly(false)
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

        //Dropdown menu
        BusinessDao.getDp().then(
            (response) => {
                setDp(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

        //Machines
        BusinessDao.getMachines(business).then(
            (response) => {
                setMachines(response);
                var arr = new Array();
                response.map(v => { if (v.group.startsWith("BA")) arr.push(v); })
                setBAmachines(arr);

                //Get tecma files
                if( arr.length > 0 ){
                    BusinessDao.getTecmaFiles(business, arr).then(
                        (response) => {
                            for( let  i=0; i<arr.length; i++ ) 
                                arr[i].url = getFm( arr[i].no_machine, response )
                            
                            setBAmachines(arr);
                            setBAFiles(response);
                        },
                        (error) => {
                            setError(errorManagement.handleError(props, error));
                            scrollToTop();
                            setLoading(false);
                        }
                    );
                }
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

        function getFm( machine, files ){
            if( !machine ) return null;
            if( machine.indexOf("/") ) machine = machine.split("/")[1];

            for( let i=0; i<files.length; i++ ){
                if( files[i].type === "fm" && files[i].name.indexOf( machine ) !== -1 )
                    return files[i].url;
            }

            return null;
        }

        //Configs
        BusinessDao.getConfigs().then(
            (response) => {
                setConfigs(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

        //Annexes
        BusinessDao.getAnnexes(business).then(
            (response) => {
                setAnnexes(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

        //Revisions
        BusinessDao.getRevisions(business).then(
            (response) => {
                var rev = new Array();
                var revBA = new Array();

                response.map(v => {
                    if (v.no_business.startsWith("BA")) {
                        revBA.push(v);
                    } else {
                        rev.push(v);
                    }
                })

                setRevisions(rev);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

        // Delivery note
        if ( (business.startsWith("U") || business.startsWith("J")) ) {
            BusinessDao.getDeliveryNote(business).then(
                (response) => {
                    setDeliveryNote(response);
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                    setLoading(false);
                }
            );

            BusinessDao.getBusinessInfoFocus(business).then(
                (response) => {
                    setBusinessInfoFocus(response);
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                    setLoading(false);
                }
            );

            BusinessDao.getCustomerInvoice(business).then(
                (response) => {
                    setCustomerInvoice(response);
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                    setLoading(false);
                }
            );
        }


        if (user && (user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_WRITE_FM"))) {
            BusinessDao.getMachineCreationInfo().then(
                (response) => {
                    setMachinesCreationInfo(response)
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                    setLoading(false);
                }
            )
        }

    }, []);

    const changeTab = (e) => {
        setTab(parseInt(e.target.getAttribute("data-tab")));
    }

    /**
     * Update business fields
     * @param {*} e 
     */
    const updateBusinessField = (e) => {
        if (readOnly) return;

        var field = e.target.getAttribute("data-field");
        var translate = e.target.getAttribute("data-translate") !== null;
        var value = e.target.value;
        var typeField = e.target.type;
        if (typeField === "textarea" || typeField === "select-one") typeField = "text";
        setError();

        BusinessDao.updateBusinessField(business, field, value, typeField, translate).then(
            (response) => {
                
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );
    }

    /**
     * Change state of field
     * @param {*} e 
     */
    const onBusinessChange = (e) => {
        if (readOnly) return;

        var field = e.target.getAttribute("data-field");
        var value = e.target.value;

        var o = { ...businessInfo };
        o[field] = value;
        setBusinessInfo(o);
    }

    /**
     * Add logo client
     * @param {*} e 
     */
    const addLogo = (e) => {
        if (readOnly) return;

        var field = e.target.getAttribute("data-field");
        var o = { ...businessInfo };

        getBase64(e.target.files[0], (res) => {
            o[field] = res;
            setBusinessInfo(o);
            e.target.value = "";
        });

        ShareFileDao.uploadAnyFiles("business/info/upload-file", 
                { business: business, field: field } , e.target.files).then(
            (response) => {},
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }

    const addAnnexe = (e) => {
        if (readOnly) return;

        var arr = [...annexes];

        getBase64(e.target.files[0], (res) => {
            arr.push({
                name: e.target.files[0].name,
                img: res,
                new: true
            });
            setAnnexes(arr);
            e.target.value = "";
        });

        //Save in database
        var o = { business: business };
        ShareFileDao.uploadAnyFiles("business/info/upload-annexe", o, e.target.files).then(
            (response) => {
                
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }

    /**
     * Display the image annexe
     * @param {*} e 
     */
    const displayAnnexe = (e) => {
        setImgDisplay(e.target.getAttribute("data-img"));
    }

    const deleteAnnexe = (e) => {
        if (readOnly) return;

        var key = e.target.getAttribute("data-key");
        var arr = [...annexes];

        BusinessDao.deleteAnnexe(business, arr[key].name).then(
            (response) => {
                
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )

        arr = arrayRemoveElt(arr, key);

        setAnnexes(arr);

    }

    return (
        <Fragment>

            <h4 className="text-center">{business}</h4>

            {error && <ErrorMessage error={error} errorState={setError} />}
            {msg && <SuccessMessage msg={msg} msgState={setMsg} />}
            {loading && <WaitingRoundSnippers />}



            <Fragment>
                <ul className="nav nav-tabs">
                    <li className="nav-item fw-bold">
                        <a className={"nav-link " + (tab === 0 ? "active" : "")}
                            href="#" onClick={changeTab} data-tab="0">Fiche affaire</a>
                    </li>
                    <li className="nav-item fw-bold">
                        <a className={"nav-link " + (tab === 2 ? "active" : "")}
                            href="#" onClick={changeTab} data-tab="2">Autres infos & actions</a>
                    </li>
                    {
                        !readOnly &&
                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 1 ? "active" : "")}
                                href="#" onClick={changeTab} data-tab="1">Exporter</a>
                        </li>
                    }
                    {
                        BAmachines.length > 0 &&
                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 3 ? "active" : "")}
                                href="#" onClick={changeTab} data-tab="3">Commande TECMA</a>
                        </li>
                    }
                    {
                        business && (business.startsWith("U") || business.startsWith("J") ) && <Fragment>
                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 4 ? "active" : "")}
                                href="#" onClick={changeTab} data-tab="4">Bordereau d'exp.</a>
                        </li>

                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 5 ? "active" : "")}
                                href="#" onClick={changeTab} data-tab="5">Edit. facture</a>
                        </li>

                        </Fragment>
                    }
                </ul>

                {
                    (tab === 0 && businessInfo && configs && annexes) &&
                    <Fragment>
                        {/** GENERAL + CONTEXTE + PRESTATION */}
                        <PartOne businessInfo={businessInfo} updateBusinessField={updateBusinessField}
                            onBusinessChange={onBusinessChange} readOnly={readOnly} />
                        <br></br>

                        {/** MACHINES */}
                        <PartTwo machines={machines} configs={configs} setMachines={setMachines} business={businessInfo}
                            machinesCreationInfo={machinesCreationInfo} setMachinesCreationInfo={setMachinesCreationInfo}
                            readOnly={readOnly} />
                        <br></br>

                        {/** DELIVERY ADDRESS - PROJECT MANAGER - YARD MANAGER - MAINTENANCE MANAGER - PURCHASER */}
                        <PartThree businessInfo={businessInfo} updateBusinessField={updateBusinessField}
                            onBusinessChange={onBusinessChange} readOnly={readOnly} />
                        <br></br>

                        {/** DOCUMENTS - PLANNING - LIMITS */}
                        <PartEight businessInfo={businessInfo} updateBusinessField={updateBusinessField}
                            onBusinessChange={onBusinessChange} readOnly={readOnly} />
                        <br></br>

                        {/** CUSTOMER DPT - OTHERS - PARTICULARITIES */}
                        <PartFour businessInfo={businessInfo} updateBusinessField={updateBusinessField}
                            onBusinessChange={onBusinessChange} readOnly={readOnly} />
                        <br></br>

                        {/** LOGOS + ANNEXES */}
                        <PartFive businessInfo={businessInfo} addLogo={addLogo} imgDisplay={imgDisplay}
                            annexes={annexes} addAnnexe={addAnnexe} displayAnnexe={displayAnnexe}
                            deleteAnnexe={deleteAnnexe} readOnly={readOnly} />
                        <br></br>
                    </Fragment>
                }

                {
                    (tab === 2 && businessInfo && machines) &&
                    <Fragment>
                        {/** OTHERS ACTION & ANNEXES */}
                        <PartSix businessInfo={businessInfo} updateBusinessField={updateBusinessField}
                            onBusinessChange={onBusinessChange} machines={machines} />
                        <br></br>
                    </Fragment>
                }

                {
                    (machines && tab === 1 && revisions && machines) &&
                    <Fragment>
                        {/** EXPORTS DOCS */}
                        <PartSeven businessInfo={businessInfo} updateBusinessField={updateBusinessField}
                            onBusinessChange={onBusinessChange} revisions={revisions} machines={machines} 
                            annexes={annexes} />
                    </Fragment>
                }

                {
                    (tab === 3 && BAmachines && BAfiles ) &&
                    <BAContract BAmachines={BAmachines} setBAmachines={setBAmachines}
                        BAfiles={BAfiles} setBAFiles={setBAFiles} setError={setError} setMsg={setMsg} />
                }

                {
                    (tab === 4 && businessInfo && machines) &&
                    <DeliveryNote businessInfo={businessInfo} machines={machines} setError={setError}
                        deliveryNote={deliveryNote} setMsg={setMsg} />
                }

                {
                    (tab === 5 && businessInfo && machines && businessInfoFocus) &&
                    <CustomerInvoice businessInfo={businessInfo} machines={machines} setError={setError}
                        customerInvoice={customerInvoice} businessInfoFocus={businessInfoFocus} setMsg={setMsg} />
                }


            </Fragment>

            {dp && <DropDownMenu list={dp} />}

        </Fragment >
    );

}

export default BusinessCard;