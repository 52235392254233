import { Fragment } from "react"
import Moment from "moment";
import CustomToolTip from "../../../common/smallComponents.js/CustomToolTip";

const QualityFbTable = (p) => {

    Moment.locale("fr");
  
    return <Fragment>

        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr className="table-info">
                        <th scope="col">Date</th>
                        <th scope="col">Auteur</th>
                        <th scope="col">Affaire</th>
                        <th scope="col">Machine</th>
                        <th scope="col">Type de machine</th>
                        <th scope="col">Description</th>
                        <th scope="col">Problème</th>
                        <th scope="col">Criticité</th>
                        <th scope="col">Solution</th>
                        {
                            p.isQhse &&
                            <Fragment>
                                <th scope="col">Qui ?</th>
                                <th scope="col">Quand ?</th>
                                <th scope="col">Quoi ?</th>
                            </Fragment>
                        }
                        <th scope="col">Statut</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        p.list.map(
                            (v, k) => {
                                return (
                                    <tr key={"l_" + k}>
                                        <th scope="row">{Moment(v.date).format("DD/MM/yyyy")}</th>
                                        <td>{v.last_name + ' ' + v.first_name}</td>
                                        <td>{v.business}</td>
                                        <td>{v.machine}</td>
                                        <td>{v.machine_type}</td>
                                        <td>
                                            <CustomToolTip props={
                                                { tooltipText: v.description, originText: v.description.substring(0, 31) + (v.description.length > 31 ? "..." : "") }} />
                                        </td>
                                        <td>
                                            <CustomToolTip props={
                                                { tooltipText: v.problem, originText: v.problem.substring(0, 31) + (v.problem.length > 31 ? "..." : "") }} />
                                        </td>
                                        <td>{v.criticality}</td>
                                        <td>{v.proposal && v.proposal.substring(0, 31) + (v.proposal.length > 31 ? "..." : "")}</td>
                                        {
                                            p.isQhse &&
                                            <Fragment>
                                                <td>
                                                    <input className="form-control" type="text" data-id={v.id} data-field="who"
                                                        onBlur={p.onFieldChange} defaultValue={v.who}></input>
                                                </td>
                                                <td>
                                                    <input className="form-control" type="date" data-id={v.id} data-field="[when]"
                                                        onBlur={p.onFieldChange} defaultValue={v.when}></input>
                                                </td>
                                                <td>
                                                    <input className="form-control" type="text" data-id={v.id} data-field="what"
                                                        onBlur={p.onFieldChange} defaultValue={v.what}></input>
                                                </td>
                                            </Fragment>}
                                        <td>
                                            <select className="form-select" onChange={p.onFieldChange} disabled={!p.isQhse}
                                                data-id={v.id} data-field="statut">
                                                <option value="Ouvert">Ouvert</option>
                                                <option value="En cours" selected={v.statut === 'En cours'}>En cours</option>
                                                <option value="Clôturé" selected={v.statut === 'Clôturé'}>Clôturé</option>
                                            </select>
                                        </td>
                                        <td>
                                            <a className="btn btn-outline-info" href={"/qhse/quality-feedback/form?id=" + v.id} target="_blank">Entrer</a>
                                        </td>

                                    </tr>)
                            }
                        )
                    }
                </tbody>
            </table>
            <br></br>
        </div>

    </Fragment>

}

export default QualityFbTable;