import { useState } from "react";
import { Fragment } from "react";
import ticketsDao from "../../services/qhse/ticketsDao";
import { useEffect } from "react";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import { arrayRemoveElt, getKey, getKeyInt } from "../../functions/ArrayFunctions";
import { confirmAlert } from "react-confirm-alert";

const EquipmentRequest = (props) => {
    const [category, setCategory] = useState();
    const [articles, setArticles] = useState();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [cart, setCart] = useState([]);

    useEffect(() => {
        ticketsDao.getEquipmentList().then(
            (response) => {
                setData(response);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );
    }, []);

    /**
     * 
     * @param {*} e 
     */
    const onCategoryChoose = (e) => {
        var cat = e.target.getAttribute("data-cat");

        var art = new Array();
        data.map((v, k) => {
            if (v.category === cat) {
                
                if( art.length === 0 && v.size !== null && v.size !== "" ){
                    v.sizeList = new Array();
                    v.sizeList.push(v);
                    art.push(v);
                }else if (art.length > 0 && v.size !== null && v.size !== "" && v.designation === art[art.length - 1].designation) {
                    if (art.length === 0) art.push(v);

                    if (art[art.length - 1].sizeList) {
                        art[art.length - 1].sizeList.push(v);
                    } else {
                        art[art.length - 1].sizeList = new Array();
                        art[art.length - 1].sizeList.push(v);
                    }
                    
                }else if (art.length > 0 && v.size !== null && v.size !== "" && v.designation !== art[art.length - 1].designation) {
                    v.sizeList = new Array();
                    v.sizeList.push(v);
                    art.push(v);                    
                } else {
                    art.push(v);
                }
            }
        });

        setCategory(cat);
        setArticles(art);
    }

    /**
     * 
     */
    const goBackCat = () => {
        var arr = [...data];
        arr.map( v => delete v.sizeList );
        setCategory();
        setArticles(arr);
    }

    const onPlusClick = (e) => {
        changeCart(e, "add");
    }

    const onLessClick = (e) => {
        changeCart(e, "remove");
    }

    const changeCart = (e, addRemove) => {
        var k = e.target.getAttribute("data-key");
        var id = e.target.getAttribute("data-id");
        var k2 = getKeyInt(data, "id", id);

        var arr = [...data];
        var arr2 = [...articles];

        if (addRemove === "add") {

            if (articles[k].sizeList) {
                if (articles[k].cart >= 1) return;

                arr2[k].cart = arr2[k].cart + 1;
                if (!cart.includes(id)) cart.push(id);
            } else {
                if (data[k2].cart >= 1) return;

                arr[k2].cart = arr[k2].cart + 1;
                if (!cart.includes(id)) cart.push(id);
            }

        } else {
            if (articles[k].sizeList) {
                if (articles[k].cart === 0) return;

                arr2[k].cart = arr2[k].cart - 1;
                if (parseInt(arr2[k].cart) === 0) {
                    var tempCart = [...cart];
                    tempCart = arrayRemoveElt(tempCart, getKey(id));
                    setCart(tempCart);
                }

                delete arr2[k].choosenId;
            } else {
                if (data[k2].cart === 0) return;

                arr[k2].cart = arr[k2].cart - 1;
                if (parseInt(arr[k2].cart) === 0) {
                    var tempCart = [...cart];
                    tempCart = arrayRemoveElt(tempCart, getKey(id));
                    setCart(tempCart);
                }
            }
        }

        setData(arr);
    }

    const onSizeSelect = (e) => {
        var k = e.target.getAttribute("data-key");
        var arr = [...articles];
        var id = e.target.value;

        if (id === "") {
            delete arr[k].choosenId;
        } else {
            arr[k].choosenId = id;
        }

        setArticles(arr);
    }

    const displayCart = () => {
        var art = new Array();
        cart.map(v => {
            art.push(data[getKeyInt(data, "id", v)]);
        })

        var plural = cart.length > 1;

        confirmAlert({
            customUI: ({ onClose, finalUser }) => {
                return (
                    <div className='custom-ui'>
                        <div className="bg-info bg-gradient"
                            style={{ backgroundColor: "grey", padding: "20px", borderRadius: "0.5em" }}>
                            <h1 className="text-center">Votre panier</h1>
                            <p>Votre panier contient {plural ? "les" : "l'"} article{plural && "s"} suivant{plural && "s"}:</p>

                            <ul className="list-group mb-3">
                                {
                                    art.map((v, k) => {
                                        return <li className="list-group-item display-flex">
                                            <div>
                                                <img style={{ maxWidth: "100px" }} src={v.photo}></img>
                                            </div>
                                            <div className="fw-bold">
                                                {v.designation}
                                                {(v.size !== null && v.size !== "") && <div>
                                                    Taille: {v.size}
                                                </div>}
                                            </div>
                                        </li>
                                    })
                                }

                            </ul>

                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="fu" placeholder="xxx" />
                                <label for="fu">Si pas pour vous, à qui est-ce destiné?</label>
                            </div>

                            <div className="fw-bold text-center mb-3">
                                <a href="#" className="text-dark" onClick={onClose}>Voulez-vous autre chose ?</a>
                            </div>

                            <div className="fw-bold text-center mb-3">
                                <button className="btn btn-success"
                                    onClick={() => { sendRequest(); onClose(); }}>
                                    Valider le panier
                                </button>
                            </div>

                        </div>
                    </div>
                );
            }
        });
    }

    const sendRequest = () => {
        setLoading(true);
        setMsg("Commande en cours, vous allez être redirigé dans un instant...");
        ticketsDao.newEquipmentRequest(cart, document.getElementById("fu").value).then(
            () => {
                window.location.href = "/qhse/equipment/request/list";
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );
    }

    return <Fragment>

        <h4 className="text-center">Demande d'équipement</h4>

        {error && <ErrorMessage error={error} errorState={setError} />}
        {loading && <WaitingRoundSnippers />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        {
            !loading && <Fragment>

                {/** DISPLAY CATEGORIES IF NOT SELECTED */
                    !category && <Fragment>
                        <h5 className="text-center">Choisissez le type de protection</h5>

                        {
                            cart.length > 0 &&
                            <div className="fixed-header">
                                <div style={{ marginLeft: "auto" }} onClick={displayCart}>
                                    <img src="/img/qhse/cart.png" style={{ position: "absolute", right: "5px" }}></img>
                                    <div className="fw-bold bg-success bg-gradient text-white text-center"
                                        style={{
                                            marginLeft: "auto", width: "30px", height: "30px",
                                            position: "absolute", right: "0px", marginTop: "40px", borderRadius: "50%"
                                        }}>
                                        {cart.length}
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="display-flex-wrap">

                            <div className="text-center" style={{ padding: "20px", margin: "auto" }}>
                                <img src="/img/qhse/feet_protection.png" data-cat="Pieds"
                                    style={{ width: "150px" }} onClick={onCategoryChoose}></img>
                                <br></br>
                                <span className="fw-bold">Protection des pieds</span>
                            </div>

                            <div className="text-center" style={{ padding: "20px", margin: "auto" }}>
                                <img src="/img/qhse/eyes_protection.png" data-cat="Yeux"
                                    style={{ width: "150px" }} onClick={onCategoryChoose}></img>
                                <br></br>
                                <span className="fw-bold">Protection des yeux</span>
                            </div>

                            <div className="text-center" style={{ padding: "20px", margin: "auto" }}>
                                <img src="/img/qhse/gloves.png" data-cat="Mains"
                                    style={{ width: "150px" }} onClick={onCategoryChoose}></img>
                                <br></br>
                                <span className="fw-bold">Protection des mains</span>
                            </div>

                            <div className="text-center" style={{ padding: "20px", margin: "auto" }}>
                                <img src="/img/qhse/hear_protection.png" data-cat="Ouie"
                                    style={{ width: "150px" }} onClick={onCategoryChoose}></img>
                                <br></br>
                                <span className="fw-bold">Protection de l'ouie</span>
                            </div>

                            <div className="text-center" style={{ padding: "20px", margin: "auto" }}>
                                <img src="/img/qhse/head_protection.png" data-cat="Tête"
                                    style={{ width: "150px" }} onClick={onCategoryChoose}></img>
                                <br></br>
                                <span className="fw-bold">Protection de la tête</span>
                            </div>

                            <div className="text-center" style={{ padding: "20px", margin: "auto" }}>
                                <img src="/img/qhse/body_protection.png" data-cat="Corps"
                                    style={{ width: "150px" }} onClick={onCategoryChoose}></img>
                                <br></br>
                                <span className="fw-bold">Protection du corps</span>
                            </div>

                            <div className="text-center" style={{ padding: "20px", margin: "auto" }}>
                                <img src="/img/qhse/mask.png" data-cat="Respiration"
                                    style={{ width: "150px" }} onClick={onCategoryChoose}></img>
                                <br></br>
                                <span className="fw-bold">Protection de la respiration</span>
                            </div>

                            <div className="text-center" style={{ padding: "20px", margin: "auto" }}>
                                <img src="/img/qhse/others.png" data-cat="Autre"
                                    style={{ width: "150px" }} onClick={onCategoryChoose}></img>
                                <br></br>
                                <span className="fw-bold">Autre</span>
                            </div>
                        </div>
                    </Fragment>
                }

                {
                    category && <Fragment>
                        <h5 className="text-center">Choisissez un ou plusieurs articles</h5>

                        <div className="fixed-header display-flex" style={{ backgroundColor: "white", zIndex: "9" }}>

                            <div className="display-flex bg-info bg-gradient mb-3 cursorPointer"
                                onClick={goBackCat} style={{ width: "330px", borderRadius: "12px", padding: "5px" }}>
                                <img src="/common/goBack.png" width="70px"></img>
                                <div className="fw-bold" style={{ margin: "auto", fontSize: "150%" }}>Retour aux catégories</div>
                            </div>

                            {
                                cart.length > 0 &&
                                <div style={{ marginLeft: "auto" }} onClick={displayCart}>
                                    <img src="/img/qhse/cart.png"></img>
                                    <div className="fw-bold bg-success bg-gradient text-white text-center"
                                        style={{ marginLeft: "auto", width: "30px", height: "30px", borderRadius: "50%" }}>
                                        {cart.length}
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="display-flex-wrap">
                            {
                                articles && articles.map((v, k) => {
                                    return <div className="text-center card mb-3 cursorPointer"
                                        key={"art" + k} data-id={v.id}
                                        style={{ padding: "20px", margin: "auto", width: "200px", height: "350px" }}>

                                        <img src={v.photo} className="text-center" style={{ width: "150px", maxHeight: "150px" }}></img>
                                        <br></br>

                                        <span className="fw-bold">{v.designation}</span>

                                        {v.sizeList &&
                                            !v.choosenId && <select className="form-select mb-3" onChange={onSizeSelect} data-key={k}>
                                                <option value="">Choisissez la taille</option>


                                                {
                                                    v.sizeList.map((v2, k2) => {
                                                        return <option key={k + "sz" + k2} value={v2.id}>{v2.size}</option>
                                                    })
                                                }
                                            </select>
                                        }

                                        {
                                            (!v.sizeList || (v.sizeList && v.choosenId)) &&
                                            <div className="input-group mb-3" style={{ marginTop: "auto" }}>
                                                <button className="btn btn-secondary" style={{ width: "40px" }}
                                                    data-id={v.choosenId || v.id} data-key={k} onClick={onLessClick}>-</button>
                                                <input className="form-control text-center" readOnly
                                                    value={v.cart}></input>
                                                <button className="btn btn-secondary" style={{ width: "40px" }}
                                                    data-id={v.choosenId || v.id} data-key={k} onClick={onPlusClick}>+</button>
                                            </div>
                                        }

                                    </div>

                                })
                            }
                        </div>
                    </Fragment>
                }
            </Fragment>
        }




    </Fragment>

}

export default EquipmentRequest;