import { useState } from "react";
import CsContracts from "../../services/customClient/CsContracts";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting } from "../../functions/PageAnimation";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import { downloadTempFile } from "../../services/functions/FilesProcess";

const GenerateSparesBom = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    const onSubmit = (e) => {
        e.preventDefault();

        setLoading(true);
        CsContracts.generateXlsBOM(e.target.business.value, e.target.file.files[0]).then(
            (response) => {
                downloadTempFile(response)
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        )
    }

    return <div>
        <h5 className="text-center">Création d'AV de spares sur Focus</h5>
        {error && <ErrorMessage error={error} errorState={setError} />}

        <div className="alert alert-info text-center">
            Le fichier à uploader doit être au format <strong>xlsx</strong> ne contenir que <strong>deux colonnes</strong>:
            la première étant les codes AR et la seconde, les quantités
        </div>

        <div className="col-6 offset-3">
            <form onSubmit={onSubmit}>
                <div className="input-group mb-3">
                    <span className="input-group-text" style={{width: "145px"}}>Code affaire</span>
                    <input type="text" className="form-control" name="business" required/>
                </div>

                <div className="input-group mb-3">
                    <input type="file" className="form-control" name="file" required/>
                </div>

                <div className="text-center">
                    <button className="btn btn-secondary" disabled={loading}>
                        {loading && <ButtonWaiting />}
                        Générer</button>
                </div>
            </form>
        </div>
    </div>
}

export default GenerateSparesBom;