import { Fragment } from "react"
import { useTranslation } from "react-i18next";

const NC = (p) => {
    const { t } = useTranslation(['check-list', 'common']);

    return <Fragment>
        {
            p.nc.map((v, k) => {
                return <div className="card" key={"card" + k}>
                    <div className="card-body">
                        <h5 className="card-title mb-3">Machine #{v.machine}</h5>

                        {
                            v.chapters.map((v2, k2) => {
                                return <Fragment key={"chap" + k2}>
                                    <h6 className="card-subtitle mb-2 text-muted" style={{ marginLeft: "5px" }}>{v2.label}</h6>
                                    <div className="mb-3" style={{ marginLeft: "15px" }}>
                                        {v2.nc.length === 0 &&
                                            <p className="card-text text-success">{t('ntr', { ns: 'check-list' })}</p>}
                                        {v2.nc.length > 0 && v2.nc.map((v3, k3) => {
                                            return <Fragment key={"nc" + k3}>
                                                <p className="card-text text-danger fw-bold"
                                                    dangerouslySetInnerHTML={{ __html: v3.question + ') ' + v3.question_label }}></p>
                                                {v3.photo !== null &&
                                                    <img src={v3.photo} style={{maxWidth: "150px"}}></img>
                                                }
                                            </Fragment>
                                        })}
                                    </div>
                                    {
                                        (v2.notes !== "" || v2.changed !== "" || v2.toChange !== "") &&
                                        <div style={{ marginLeft: "15px" }}>
                                            <h6 className="card-subtitle mb-2 text-muted">Infos</h6>
                                            <ul>
                                                {v2.notes !== "" && <li>Notes: {v2.notes}</li>}
                                                {v2.changed !== "" && <li>{t('changed cli', { ns: 'check-list' })}: {v2.changed}</li>}
                                                {v2.toChange !== "" && <li>{t('change quickly', { ns: 'check-list' })}: {v2.toChange}</li>}
                                            </ul>
                                        </div>
                                    }
                                </Fragment>
                            })
                        }
                        <br></br><br></br>
                    </div>
                </div>
            })
        }
    </Fragment>

}


export default NC;