import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL +"articles/info";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && "\"Bearer " + user.accessToken + "\"";

/**
 * will search article like %term or ref like %term
 * @param {*} article 
 * @returns 
 */
const articlesInfo = (article) => {
    return axios
    .post(API_URL + "/multiple/info",
    {
        fake_header: authHeader(),
        article: article
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getDynamicStockInfo = ( article ) => {
    return axios
    .post(API_URL + "/stock/dynamic",
    {
        fake_header: authHeader(),
        article: article
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const changeIndexNeed = ( new_index, need ) => {
    return axios
    .post(API_URL + "/stock/dynamic/migrate",
    {
        fake_header: authHeader(),
        new_index: new_index,
        need: need
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getAllArtInfo = ( article ) => {
    return axios
    .post(API_URL + "/get",
    {
        fake_header: authHeader(),
        article: article
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getFullArtDb = () => {
    return axios
    .post(API_URL + "/get/full-db",
    {
        fake_header: authHeader()
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getAllArtAvailable= () => {
    return axios
    .post(API_URL + "/get/full-db/available",
    {
        fake_header: authHeader()
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getAllArtAutocompletion= (term) => {
    return axios
    .post(API_URL + "/get/autocompletion",
    {
        fake_header: authHeader(),
        term: term
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getAutocompletionTxt = () => {
    return axios
    .post(API_URL + "/get/autocompletion/txt",
    {
        fake_header: authHeader()
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getOrders = ( article ) => {
    return axios
    .post(API_URL + "/get/orders",
    {
        fake_header: authHeader(),
        article: article
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getSupplier = ( article ) => {
    return axios
    .post(API_URL + "/get/suppliers",
    {
        fake_header: authHeader(),
        article: article
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getPrices = ( article ) => {
    return axios
    .post(API_URL + "/get/prices",
    {
        fake_header: authHeader(),
        article: article
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getMoves = ( article ) => {
    return axios
    .post(API_URL + "/get/moves",
    {
        fake_header: authHeader(),
        article: article
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getHistoric = ( article ) => {
    return axios
    .post(API_URL + "/get/changes",
    {
        fake_header: authHeader(),
        article: article
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getAllArtInfoStrictSearch = ( article ) => {
    return axios
    .post(API_URL + "/get/strict",
    {
        fake_header: authHeader(),
        article: article
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getAllArtInfoStrictSearchLight = ( article ) => {
    return axios
    .post(API_URL + "/get/strict/light",
    {
        fake_header: authHeader(),
        article: article
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}
  

const updateArtInfo = ( article, ref, changes, newSupplier, supplierToRemove, oldRef ) => {
    return axios
    .post(API_URL + "/update",
    {
        fake_header: authHeader(),
        article: article,
        ref, ref,
        changes: changes,
        newSupplier: newSupplier,
        supplierToRemove: supplierToRemove,
        oldRef: oldRef
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getSupplierInfo = ( supplier ) => {
    return axios
    .post(API_URL + "/supplier-info",
    {
        fake_header: authHeader(),
        supplier: supplier
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getAlternativeBlockedArt = ( ref ) => {
    return axios
    .post(API_URL + "/blocked/get-alternative",
    {
        fake_header: authHeader(),
        ref: ref
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const closeNeeds = ( article, needs ) => {
    return axios
    .post(API_URL + "/stock/dynamic/close",
    {
        fake_header: authHeader(),
        article: article,
        needs: needs
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getPdfDrawing = ( article ) => {
    return axios
    .post(API_URL + "/get-drawing",
    {
        fake_header: authHeader(),
        article: article
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const searchDrawing = ( article, ref ) => {
    return axios
    .post(API_URL + "/request-drawing",
    {
        fake_header: authHeader(),
        article: article,
        ref: ref
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const searchDrawingUrl = ( ref ) => {
    return axios
    .post(API_URL + "/request-drawing-url",
    {
        fake_header: authHeader(),
        ref: ref
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getArticleToCreate = ( id ) => {
    return axios
    .post(API_URL + "/get-art-to-create",
    {
        fake_header: authHeader(),
        id: id
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getArticleToCreateList = () => {
    return axios
    .post(API_URL + "/creation/list",
    {
        fake_header: authHeader()
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getArticleCreatedToValidate = () => {
    return axios
    .post(API_URL + "/creation/focus-art/to-validate",
    {
        fake_header: authHeader()
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const deleteArtRequest = (id) => {
    return axios
    .post(API_URL + "/creation/delete",
    {
        fake_header: authHeader(),
        id: id
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

/**
 * 
 * @param {*} text to translate
 * @param {*} source fr
 * @param {*} target en
 */
const translate = (text, source, target) => {
    let data = {
        q : text,
        source: source,
        target: target
    }

    return axios
    .post("https://libretranslate.de/translate", data)
    .then((response) => {
        return response.data;
    });
}

const getOldRef = ( ref ) => {
    return axios
    .post(API_URL + "/get-old-index",
    {
        fake_header: authHeader(),
        ref: ref
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const saveCreationRequest = ( o, oldRef ) => {
    return axios
    .post(API_URL + "/creation/save",
    {
        fake_header: authHeader(),
        o,
        oldRef: oldRef
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const createArticleInFocus = ( o, oldRef ) => {
    return axios
    .post(API_URL + "/creation/focus-art",
    {
        fake_header: authHeader(),
        o,
        oldRef: oldRef
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const searchArticle = ( term, lang, invisible ) => {
    return axios
    .post(API_URL + "/search/focus",
    {
        fake_header: authHeader(),
        term: term,
        lang: lang,
        invisible: invisible
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const searchSplittedArticle = ( ar, label, ref, ref_frn, nbRecords, lang, invisible ) => {
    return axios
    .post(API_URL + "/search/focus/splitted",
    {
        fake_header: authHeader(),
        ar: ar,
        label: label,
        ref: ref,
        ref_frn: ref_frn,
        nbRecords: nbRecords,
        lang: lang,
        invisible: invisible
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const addPriceSell = ( article ) => {
    return axios
    .post(API_URL + "/sell-price/add",
    {
        fake_header: authHeader(),
        article: article
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const updatePriceSell = ( article, old ) => {
    return axios
    .post(API_URL + "/sell-price/update",
    {
        fake_header: authHeader(),
        article: article,
        old: old
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

export default {
    articlesInfo,
    getDynamicStockInfo,
    changeIndexNeed,
    getAllArtInfo,
    updateArtInfo,
    getSupplierInfo,
    getAlternativeBlockedArt,
    closeNeeds,
    getPdfDrawing,
    searchDrawing,
    getArticleToCreate,
    getArticleToCreateList,
    deleteArtRequest,
    translate,
    getOldRef,
    saveCreationRequest,
    createArticleInFocus,
    getAllArtInfoStrictSearch,
    getOrders,
    getSupplier,
    getPrices,
    getMoves,
    getHistoric,
    getFullArtDb,
    searchDrawingUrl,
    searchArticle,
    searchSplittedArticle,
    getAllArtAvailable,
    getAllArtAutocompletion,
    getArticleCreatedToValidate,
    addPriceSell,
    updatePriceSell,
    getAllArtInfoStrictSearchLight,
    getAutocompletionTxt
}