
const LimitOfServices = (props) => {
    return (
        < div className="table-responsive">
            <table className="table businessCardStyle">
                <thead>
                    <tr className="table-dark text-center rounded">
                        <th scope="col" colSpan={2}>Limit of service</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="border-end">
                            {/** PACKING - TRANSP - COMMENT */}
                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{ width: "200px" }}>Packing</span>
                                <input type="text" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.emb_aff_en}
                                    data-field="emb_aff_en" list="emb_aff" data-translate="true" />

                                <span className="input-group-text" style={{ width: "200px" }}>Transport</span>
                                <input type="text" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.transp_aff_en}
                                    data-field="transp_aff_en" list="transp_aff" data-translate="true" />

                                <span className="input-group-text" style={{ width: "200px" }}>Comment</span>
                                <input type="text" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.transp_incoterm_dt}
                                    data-field="transp_incoterm_dt" />
                            </div>

                            {/** UNLOADING  - INSTALLATION */}
                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{ width: "200px" }}>Unloading and handling</span>
                                <input type="text" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.decharg_manu_en}
                                    data-field="decharg_manu_en" list="decharg_manu" data-translate="true" />

                                <span className="input-group-text" style={{ width: "200px" }}>Installation</span>
                                <input type="text" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.install_aff_en}
                                    data-field="install_aff_en" list="install_aff" data-translate="true" />
                            </div>


                            {/** GO-LIVE  - TRAINING */}
                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{ width: "200px" }}>Go-live support</span>
                                <input type="text" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.assist_prod_en}
                                    data-field="assist_prod_en" list="assist_prod" data-translate="true" />

                                <span className="input-group-text" style={{ width: "200px" }}>Training</span>
                                <input type="text" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.formation_perso_en}
                                    data-field="formation_perso_en" list="formation_perso" data-translate="true" />
                            </div>


                            {/** USER MANUAL - DIGITAL(S) DOC */}
                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{ width: "200px" }}>User Manual language</span>
                                <input type="text" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.lang_notice_en}
                                    data-field="lang_notice_en" list="langue" data-translate="true" />

                                <span className="input-group-text" style={{ width: "200px" }}>digital(s) doc</span>
                                <input type="number" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.nb_copie_demat}
                                    data-field="nb_copie_demat" />
                            </div>
                        </td>

                        <td className="border-end">
                            <div className="form-floating mb-3">
                                <textarea className="form-control" placeholder="xxx" 
                                    style={{minWidth: "300px", minHeight: "100px"}} readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.com_serv_limit}
                                    data-field="com_serv_limit"></textarea>
                                <label className="fw-bold">Limit of service comment</label>
                            </div>

                            <div className="form-floating mb-3">
                                <textarea className="form-control" placeholder="xxx"
                                    style={{minWidth: "300px", minHeight: "100px"}} readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.decharg_manu_com}
                                    data-field="decharg_manu_com"></textarea>
                                <label className="fw-bold">Unloading and handling comment</label>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default LimitOfServices;