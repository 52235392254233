import { Fragment, useEffect, useState } from "react";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import ArticleInfo from "../../services/articles/ArticleInfo";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import { getFrFormat } from "../../functions/StrFunctions";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";

const CreationRequests = (props) => {
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [tab, setTab] = useState(0);

    const [list, setList] = useState();
    const [toValidate, setToValidate] = useState();
    const user = JSON.parse(localStorage.getItem("user"));
    const [purchase] = useState(user && (user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_PURCHASE")));

    useEffect(() => {
        ArticleInfo.getArticleToCreateList().then(
            (response) => {
                setList(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );

        ArticleInfo.getArticleCreatedToValidate().then(
            (response) => {
                setToValidate(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );

    }, []);

    /**
     * 
     */
    const cancelRequest = (id, k) => {
        var arr = [...list];
        arr = arrayRemoveElt(arr, k);
        setList(arr);

        ArticleInfo.deleteArtRequest(id);
    }

    return <Fragment>
        <h5 className="text-center">Liste des demandes en cours</h5>

        <div className="text-center mb-3">
            <a className="btn btn-outline-primary" href="/article/create" target="_blank">Nouvelle demande</a>
        </div>

        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        <ul className="nav nav-tabs">
            <li className="nav-item fw-bold">
                <a className={"nav-link " + (tab === 0 ? "active" : "")}
                    href="#" onClick={() => setTab(0)}>
                    {!list && <ButtonWaiting />}
                    Articles en attente de création
                </a>
            </li>
            <li className="nav-item fw-bold">
                <a className={"nav-link " + (tab === 1 ? "active" : "")}
                    href="#" onClick={() => setTab(1)} data-tab="1">
                    {!toValidate && <ButtonWaiting />}
                    {toValidate && <span>{toValidate.length} </span>}
                    Article(s) créé(s), en attente de mise à jour achats</a>
            </li>
        </ul>

        {/** WAITING... */
            (list && tab === 0) && <Fragment>

                {list.length === 0 && <div className="alert alert-info col-6 offset-3 fw-bold text-center">Aucune demande en cours</div>}

                {
                    list.length > 0 && <table className="table">
                        <thead>
                            <tr className="table-info">
                                <th>#</th>
                                <th>Degré d'urgence</th>
                                <th>Référence</th>
                                <th>Fabricant</th>
                                <th>Demandeur</th>
                                <th>Date</th>
                                <th>Progression globale</th>
                                <th>Progression BE</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.map((v, k) => {
                                    return <tr key={"i" + k}>
                                        <td>{v.id}</td>
                                        <td className={v.emergency === 2 ? "bg-success" : v.emergency === 1 ? "bg-warning" : "bg-danger"}>
                                            {v.emergency === 2 ? "Non urgent" : v.emergency === 1 ? "Urgent" : "Très urgent"}
                                        </td>
                                        <td>{v.ref}</td>
                                        <td>{v.maker}</td>
                                        <td>{v.author}</td>
                                        <td>{getFrFormat(v.date)}</td>
                                        <td>{parseFloat(v.progression).toFixed(2)}%</td>
                                        <td>{parseFloat(v.designer_progression).toFixed(2)}%</td>
                                        <td>
                                            <a className="btn btn-outline-secondary" target="_blank"
                                                href={"/article/create?id=" + v.id}>Entrer</a>
                                        </td>
                                        <td>
                                            {
                                                ((user.email === v.author) || user.roles.includes("ROLE_ADMIN")) &&
                                                <button className="btn btn-outline-danger"
                                                    onClick={() => cancelRequest(v.id, k)}>Annuler la demande</button>
                                            }
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                }

            </Fragment>
        }

        {/** To validate... */
            (toValidate && tab === 1) && <Fragment>

                {toValidate.length === 0 && <div className="alert alert-info col-6 offset-3 fw-bold text-center">Aucune demande en cours</div>}

                {
                    toValidate.length > 0 && <table className="table">
                        <thead>
                            <tr className="table-info">
                                <th>Article</th>
                                <th>Référence</th>
                                <th>Libellé</th>
                                <th>Créateur</th>
                                <th>Date de création</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                toValidate.map((v, k) => {
                                    return <tr key={"i" + k}>
                                        <td>{v.CDARL}</td>
                                        <td>{v.DES2L}</td>
                                        <td>{v.DES1L}</td>
                                        <td>{getFrFormat(v.DCREL)}</td>
                                        <td>{v.ZN16PA}</td>
                                        <td>
                                            {purchase &&
                                                <Fragment>
                                                    <a className="btn btn-outline-secondary" target="_blank"
                                                        href={"/article/info/get?article=" + v.CDARL.trim()}>
                                                        Entrer
                                                    </a>
                                                </Fragment>
                                            }
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                }

            </Fragment>
        }

    </Fragment>

}

export default CreationRequests;