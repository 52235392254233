import { Fragment, useEffect, useMemo, useState } from "react"
import Table from "../../common/smallComponents.js/Table";
import { getBase64 } from "../../functions/StrFunctions";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import ticketsDao from "../../services/qhse/ticketsDao";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import { confirmAlert } from "react-confirm-alert";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";


const EquipmentList = (props) => {
    const [takeAPhoto, setTakeAPhoto] = useState(false);
    const [dataPhoto, setDataPhoto] = useState();
    const [keyPhoto, setKeyPhoto] = useState();
    const [showPhoto, setshowPhoto] = useState();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();

    useEffect(() => {
        ticketsDao.getEquipmentList().then(
            (response) => {
                setData(response);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );
    }, []);

    const addRow = () => {
        var arr = [...data];
        arr.push({
            id: -1,
            category: null,
            designation: null,
            location: null,
            photo: null,
            type_user: null,
            brand: null,
            ref_art: null,
            order: null,
            qty_available: null,
            min_qty: null,
            size: null
        });

        setData(arr);
    }

    const onFieldChange = (e) => {
        var k = e.target.getAttribute("data-key");
        var field = e.target.getAttribute("data-field");
        var arr = [...data];

        arr[k][field] = e.target.value;
        setData(arr);
    }

    const takePhoto = (e) => {
        setKeyPhoto(parseInt(e.target.getAttribute("data-key")));
        setTakeAPhoto(true);
    }

    /**
     * On choose file
     * @param {*} e 
     * @returns 
     */
    const onChooseFile = (e) => {
        if (e.target.files.length === 0)
            return;

        var arr = [...data];
        var k = e.target.getAttribute("data-key");
        getBase64(e.target.files[0], (res) => {
            arr[k].photo = res
            setData(arr);
        });
    }

    /**
     * Close photo device
     */
    const cancelPhoto = () => {
        setTakeAPhoto(false);
    }

    /**
     * Save the photo
     * @param {*} data 
     */
    const handlePhoto = (d) => {
        var arr = [...data];
        arr[keyPhoto].photo = d
        setData(arr);

        setKeyPhoto();
        setTakeAPhoto(false);
    }

    /**
    * Delete the taken photo or uploaded img
    */
    const deletePhoto = (e) => {
        var k = showPhoto === undefined ? e.target.getAttribute("data-key") : showPhoto.key;
        var arr = [...data];
        arr[k].photo = null;
        setData(arr);
        setshowPhoto();
    }

    /**
     * 
     * @param {*} e 
     */
    const displayPhoto = (e) => {
        var k = e.target.getAttribute("data-key");
        setshowPhoto({
            key: k,
            data: data[k].photo
        });
    }

    /**
     * Save list
     */
    const save = () => {
        setLoading(true);

        ticketsDao.saveEquipmentList(data).then(
            () => {
                window.location.reload();
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );
    }

    /**
     * 
     * @param {*} e 
     */
    const deleteEquipment = (e) => {
        var k = e.target.getAttribute("data-key");
        var arr = [...data];

        confirmAlert({
            title: "Confirmation",
            message: "Etes-vous sûr de vouloir supprimer l'équipement: \""+arr[k].designation+"\" ? ",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        ticketsDao.deleteEquipmentList(arr[k].id).then(
                            () => {},
                            (error) => {
                                setError(errorManagement.handleError(props, error));
                                scrollToTop();
                            }
                        );

                        arr = arrayRemoveElt(arr, k);
                        setData(arr);
                    }
                },
                {
                    label: "Non"
                }
            ]
        });

        
    }

    const columns = useMemo(
        () => [
            {
                Header: "Catégorie", accessor: "category",
                Cell: props => <select className="form-select" value={props.value} data-field="category"
                    onChange={onFieldChange} data-key={props.row.id}>
                    <option value="">Choisissez</option>
                    <option value="Yeux">Yeux</option>
                    <option value="Mains">Mains</option>
                    <option value="Pieds">Pieds</option>
                    <option value="Ouie">Ouie</option>
                    <option value="Tête">Tête</option>
                    <option value="Corps">Corps</option>
                    <option value="Respiration">Respiration</option>
                    <option value="Autre">Autre</option>
                </select>
            },

            {
                Header: "Désignation", accessor: "designation",
                Cell: props => <input defaultValue={props.value} data-field="designation" style={{minWidth: "250px"}}
                    className="form-control" onBlur={onFieldChange} data-key={props.row.id} ></input>
            },

            {
                Header: "Emplacement", accessor: "location",
                Cell: props => <input defaultValue={props.value} data-field="location"
                    className="form-control" onBlur={onFieldChange} data-key={props.row.id} ></input>
            },

            {
                Header: "Tailles", accessor: "size",
                Cell: props => <input type="number" defaultValue={props.value} data-field="size"
                        className="form-control" onBlur={onFieldChange} data-key={props.row.id} ></input>
            },

            {
                Header: "Photo", accessor: "photo",
                Cell: props => <Fragment>

                    {
                        props.value !== null &&
                        <Fragment>
                            <img src={props.value} style={{ width: "70px" }} data-key={props.row.id} onClick={displayPhoto}></img>
                            <br></br>
                            <a href="#" data-key={props.row.id} onClick={deletePhoto}>Effacer</a>
                        </Fragment>
                    }

                    {
                        props.value === null &&
                        <div className="display-flex">
                            <button type="button" className="custom-file-upload photo"
                                data-key={props.row.id} onClick={takePhoto}></button>

                            <label className="custom-file-upload folder">
                                <input type="file" accept="image/*" data-key={props.row.id} onChange={onChooseFile} />
                            </label>
                        </div>
                    }
                </Fragment>
            },

            {
                Header: "Salarié / Visiteur", accessor: "type_user",
                Cell: props => <input defaultValue={props.value} data-field="type_user"
                    className="form-control" onBlur={onFieldChange} data-key={props.row.id} ></input>
            },
            {
                Header: "Marque", accessor: "brand",
                Cell: props => <input defaultValue={props.value} data-field="brand"
                    className="form-control" onBlur={onFieldChange} data-key={props.row.id} ></input>
            },

            {
                Header: "Ref article", accessor: "ref_art",
                Cell: props => <input defaultValue={props.value} data-field="ref_art" title={props.value}
                    className="form-control" onBlur={onFieldChange} data-key={props.row.id} ></input>
            },

            {
                Header: "Commande en cours", accessor: "order",
                Cell: props => <input defaultValue={props.value} data-field="order"
                    className="form-control" onBlur={onFieldChange} data-key={props.row.id} ></input>
            },

            {
                Header: "Stock", accessor: "qty_available",
                Cell: props => <input type="number" defaultValue={props.value} data-field="qty_available"
                    className="form-control" onBlur={onFieldChange} data-key={props.row.id} ></input>
            },
            {
                Header: "Seuil de commande", accessor: "min_qty",
                Cell: props => <input type="number" defaultValue={props.value} data-field="min_qty"
                    className="form-control" onBlur={onFieldChange} data-key={props.row.id} ></input>
            },
            {
                Header: " ", accessor: "xxx",
                Cell: props => <img src="/common/remove_icon.png" style={{width: "40px"}} 
                    onClick={deleteEquipment} data-key={props.row.id}/>
            },
        ], [data]
    );

    return <Fragment>
        <h4 className="text-center">Liste des équipements</h4>

        {error && <ErrorMessage error={error} errorState={setError} />}
        {loading && <WaitingRoundSnippers />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        <Table columns={columns} data={data} />

        {
            !loading && <button className="btn btn-outline-secondary" onClick={addRow}>Ajouter une ligne</button>
        }
        
        <br></br>
        <div className="text-center">
            <button className="btn btn-success mb-3" onClick={save} disabled={loading}>
                {loading && <ButtonWaiting />}
                Sauvegarder
            </button>
        </div>

        {takeAPhoto &&
            <div className="custom-tooltip">

                <button type="button" className="btn btn-info" onClick={cancelPhoto}>Annuler</button>

                <br></br>

                <Camera idealFacingMode={FACING_MODES.ENVIRONMENT} onTakePhoto={(data) => { handlePhoto(data) }} />
            </div>
        }

        {
            showPhoto && <div className="custom-tooltip" style={{ zIndex: "9" }}>
                <button type="button" className="btn-close"
                    aria-label="Close" onClick={() => setshowPhoto()}></button>
                <img src={showPhoto.data} style={{ width: "100%", height: "100%", maxWidth: "300px" }}></img>
                <button style={{ marginLeft: "10px" }} onClick={deletePhoto}
                    className="btn btn-outline-danger">Delete</button>
            </div>
        }
    </Fragment>



}

export default EquipmentList;