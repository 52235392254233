import { createColumnHelper } from "@tanstack/react-table";
import { Fragment, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { SideNavBarV3, isSideBarOpened } from "../../common/smallComponents.js/DesignedIpt";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import errorManagement from "../../services/errorManagement";
import { downloadSingleFile } from "../../services/functions/FilesProcess";
import Quality from "../../services/storehouse/Quality";
import { getCurrentWeekYear, getPreviousMonth, getPreviousYear } from "../../functions/StrFunctions";

const NonConformityList = (props) => {
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [loading, setLoading] = useState(true);
    const [searching, setSearching] = useState(false);
    const [data, setData] = useState([]);
    const [ncTypeList, setNcTypeList] = useState([]);
    const [pin, setPin] = useState(isSideBarOpened());

    const user = JSON.parse(localStorage.getItem("user"));
    const isStorehouse = user && (user.roles.includes("ROLE_STOREHOUSE") || user.roles.includes("ROLE_ADMIN"));
    const isPurchase = user && (user.roles.includes("ROLE_PURCHASE") || user.roles.includes("ROLE_ADMIN"));

    useEffect(() => {
        Quality.getNcList(null, true).then(
            (res) => {
                setData(res);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );

        // Get NC type list for dropdown menu
        Quality.getNcTypeList().then(
            (res) => {
                setNcTypeList(res);
            },
            () => { }
        );
    }, []);

    //<br></br><a target="_blank" style={{fontSize: "8px"}}href={"http://10.141.44.140:8080/storehouse/quality/display-nc/" + props.value}>{props.value} (old)</a>

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();
        arr.push(columnHelper.accessor('no_nc',
            {
                header: 'N° NC', cell: (i) => <div className="text-center" style={{ width: "70px" }}>
                    <a href={"/storehouse/quality/nc?id=" + i.getValue()}>{i.getValue()}</a>
                </div>
            }));

        arr.push(columnHelper.accessor('creation_date', {
            header: 'Date de création', cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('subcontractor', {
            header: 'Sous-traitant', cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('no_order',
            { header: 'N° de com.', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('ar', {
            header: 'Code Article', cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('designation', {
            header: 'Désignation', cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('ref', {
            header: 'Référence', cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('serial_num', {
            header: 'Machine / Aff.', cell: (i) => <div className="text-center">
                {i.getValue()}<br></br>
                <div className="fst-italic" style={{ fontSize: "12px" }}>{i.row.original.business}</div>
            </div>
        }));

        arr.push(columnHelper.accessor('comment', {
            header: 'Commentaires', cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('qty_in_order', {
            header: 'Qté en cde', cell: (i) => <div className="text-center" style={{ width: "70px" }}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('nc_qty', {
            header: 'Qté en N.C', cell: (i) => <div className="text-center" style={{ width: "70px" }}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('nc_back', {
            header: 'Date de retour de la N.C', cell: (i) => <div className="text-center">
                <input type="date" className="form-control" data-field="nc_back"
                    onChange={updateField} value={i.getValue() || ""} data-row={i.row.id} readOnly={!isStorehouse}></input>
            </div>
        }));

        arr.push(columnHelper.accessor('end_nc', {
            header: 'N.C. levée', cell: (i) => <div className="text-center" style={{ width: "100px" }}>
                <select className={"form-select " + (i.getValue() === "C" && "bg-danger text-white")} data-field="end_nc"
                    onChange={updateField} value={i.getValue() || ""} data-row={i.row.id} disabled={!isStorehouse}>
                    <option value="">Choisissez</option>
                    <option value="O">Oui</option>
                    <option value="N">Non</option>
                    <option value="C">Annulé</option>
                </select>
            </div>
        }));

        arr.push(columnHelper.accessor('observation', {
            header: 'OBSERVATIONS', cell: (i) => <div className="text-center">
                <textarea className="form-control" data-field="observation"
                    onBlur={updateField} defaultValue={i.getValue() || ""} data-row={i.row.id}></textarea>
            </div>
        }));

        if (isPurchase) {
            arr.push(columnHelper.accessor('nc_type', {
                header: 'Type de N.C', cell: (i) => <div className="text-center">
                    <input className="form-control" data-field="nc_type" title={i.getValue() || ""} style={{ minWidth: "150px" }}
                        onBlur={saveNcType} defaultValue={i.getValue() || ""} data-row={i.row.id} readOnly={!isPurchase}
                        list="nc-ty"></input>
                </div>
            }));

            arr.push(columnHelper.accessor('impact_date', {
                header: 'Date d\'impact', cell: (i) => <div className="text-center">
                    <input type="date" className="form-control" data-field="impact_date"
                        onChange={updateField} value={i.getValue() || ""} data-row={i.row.id}></input></div>
            }));
        }



        return arr;
    }

    //Set columns
    const columns = getColumns();

    /**
     * Search by term
     * @param {*} e 
     */
    const search = (e) => {
        e.preventDefault();
        setError();
        var term = e.target.term.value;
        var notClosedOnly = e.target.notClosedOnly.checked;

        setData([]);
        setSearching(true);
        Quality.getNcList(term, notClosedOnly).then(
            (res) => {
                setData(res);
                setSearching(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setSearching(false);
            }
        );
    }

    /**
     * Update field
     */
    const updateField = (e) => {
        var field = e.target.getAttribute("data-field");
        var row = e.target.getAttribute("data-row");
        var arr = [...data];
        arr[row][field] = e.target.value;

        //Special case on cancel NC
        if (field === "end_nc" && e.target.value === "C") {
            // Cancel date and trigram mandatories
            if ((arr[row].nc_back === null || arr[row].nc_back === "" || arr[row].observation === null || arr[row].observation === "")) {
                scrollToTop();
                setError("Veuillez saisir la date d'annulation de NC ET votre trigramme (obligatoire) + observation si besoin !");
            } else {
                setData(arr);

                confirmAlert({
                    title: "Confirmation",
                    message: "Devons-nous prévenir le service achat de l'annulation de la NC ?",
                    buttons: [
                        {
                            label: "Oui",
                            onClick: () => {
                                Quality.notifyCancellation(arr[row]);
                            }
                        },
                        {
                            label: "Non"
                        }
                    ],
                    overlayClassName: "overlay-custom-class-name"
                });
            }



        } else {
            setData(arr);
        }
    }

    /**
     * Save NC
     */
    const save = (e) => {
        setError();
        setSearching(true);
        Quality.saveNcList(data).then(
            (res) => {
                setMsg("Sauvegardé !");
                scrollToTop();
                setSearching(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setSearching(false);
            }
        );
    }

    /**
     * 
     * @param {*} e 
     */
    const saveNcType = (e) => {
        var row = e.target.getAttribute("data-row");
        var arr = [...data];
        var no_nc = arr[row].no_nc;
        var value = e.target.value;

        if (value === "") return;

        arr.map(v => { if (parseInt(v.no_nc) === parseInt(no_nc)) v.nc_type = value; })
        setData(arr);

        //Save in data base
        Quality.updateNcHeader(arr[row]);

        // Add in dropdown menu if not exists
        if (!ncTypeList.includes(value)) {
            var arr2 = [...ncTypeList];
            arr2.push(value);
            setNcTypeList(arr2);
        }
    }

    /**
     * 
     */
    const exportCurrentNc = () => {
        setError();
        setSearching(true);
        Quality.exportCurrentNc("current").then(
            (res) => {
                downloadSingleFile("temp", res.substring(res.lastIndexOf("\\") + 1));
                setSearching(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setSearching(false);
            }
        );
    }

    /**
     * 
     */
    const getNcByPeriod = (e) => {
        e.preventDefault();
        var start = e.target.from.value;
        var end = e.target.to.value;

        setError();
        setSearching(true);
        Quality.exportCurrentNc("by_period", start, end, null, null).then(
            (res) => {
                downloadSingleFile("temp", res.substring(res.lastIndexOf("\\") + 1));
                setSearching(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setSearching(false);
            }
        );
    }

    const getOrder = (e) => {
        e.preventDefault();
        var month = e.target.month.value;
        var year = e.target.year.value;

        setError();
        setSearching(true);
        Quality.exportCurrentNc("order", null, null, month, year).then(
            (res) => {
                downloadSingleFile("temp", res.substring(res.lastIndexOf("\\") + 1));
                setSearching(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setSearching(false);
            }
        );
    }

    return <div className="bg-see navbarV3 right-side">
        <SideNavBarV3 setPin={setPin} content={
            <div className="p-2">
                {loading && <WaitingRoundSnippers />}

                {!loading && <Fragment>
                    <a className="mb-3" href="/storehouse/quality/nc-list">Effacer la recherche</a>
                    <br></br><br></br>

                    <a className="mb-3" href="#" onClick={save}>Sauvegarder le tableau</a>
                    <br></br><br></br>

                    <a className="mb-3" href="#" onClick={exportCurrentNc}>Exporter les NC non-clôturée</a>
                    <br></br><br></br>

                    <form className="text-center mb-3" onSubmit={getNcByPeriod}>
                        <h5>Exporter NC par période</h5>

                        <div className="col-10 offset-1">
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">Du</span>
                                <input type="date" className="form-control" name="from" required />
                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">au</span>
                                <input type="date" className="form-control" name="to" required />
                            </div>

                            <div className="text-center">
                                <button className="btn btn-outline-success" disabled={searching}>
                                {searching && <ButtonWaiting />}Exporter</button>
                            </div>
                        </div>
                    </form>

                    <form className="text-center" onSubmit={getOrder}>
                        <h5>Exporter le détail de commandes</h5>

                        <div className="col-10 offset-1">
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">Mois</span>
                                <input type="number" className="form-control" name="month"
                                    defaultValue={getPreviousMonth()} required />
                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">Année</span>
                                <input type="number" className="form-control" name="year" 
                                   defaultValue={getPreviousYear()} required />
                            </div>

                            <div className="text-center">
                                <button className="btn btn-outline-success" disabled={searching}>
                                    {searching && <ButtonWaiting />}
                                    Exporter</button>
                            </div>
                        </div>
                    </form>
                </Fragment>}

            </div>
        }
        />

        <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>
            {loading && <WaitingRoundSnippers />}
            {error && <ErrorMessage error={error} errorState={setError} />}
            {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

            {!loading && <Fragment>
                <form onSubmit={search}>

                    <div className="col-10 offset-1">
                        <div className="p-2 input-group mb-3">
                            <input type="text" className="form-control text-center fw-bold" id="searchIpt" autoComplete="off"
                                placeholder="Rechercher par n° NC / n° affaire / code AR / sous-traitant" name="term" />

                            <div className="input-group-text">
                                <input className="form-check-input mt-0" name="notClosedOnly" type="checkbox" value="yes" />
                            </div>
                            <input type="text" className="form-control" value="Uniquement les NC non-levées" readOnly></input>

                            <button className="btn btn-outline-success" disabled={searching}>
                                {searching && <ButtonWaiting />}
                                Chercher
                            </button>
                        </div>
                    </div>

                </form>

                {data && <ReactTable columns={columns} data={data} top0={true} origin="nc_list" />}
            </Fragment>
            }

            <datalist id="nc-ty">
                {ncTypeList.map((v, k) => { return <option key={"ncTypeList" + k} value={v}>{v}</option> })}
            </datalist>
        </div>
    </div >

}

export default NonConformityList;