import { useEffect } from "react";
import { Fragment, useState } from "react";
import userService from "../../services/user.service";
import usersDao from "../../services/settings/usersDao";
import getUrlParam from "../../functions/StrFunctions";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";

const UserEdit = (props) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const [userInfo, setUserInfo] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);

    useEffect(() => {

        usersDao.getUserInfo(getUrlParam(props, "id")).then(
            (response) => {
                setUserInfo(response.user_info);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error))
                setLoading(false);
            }
        );
    }, []);

    const onChangeElt = (e) => {
        var field = e.target.getAttribute("data-field");
        var value = e.target.value;

        var o = { ...userInfo };
        o[field] = value;
        setUserInfo(o);
    }

    const save = (e) => {
        var field = e.target.getAttribute("data-field");
        var value = e.target.value;

        usersDao.updateMyProfileById(userInfo.id, field, value).then(
            () => { },
            (error) => {
                setError(errorManagement.handleError(props, error));
            }
        );
    }


    /**
     * Special case
     * @param {*} e 
     */
    const onTrigramChange = (e) => {
        setSaving(true);
        usersDao.getUserInfoByTrigram(e.target.value.toUpperCase(), userInfo.id).then(
            (response) => {
                if (userInfo.id === user.id) {
                    user.focus_id = response.PMATRI;
                    user.trigram = response.ZN01PP;
                    user.installer = response.ZN06PP === "O" ? 1 : 0;
                    user.executive = response.ZN05PP === "O" ? 1 : 0;
                    localStorage.setItem("user", JSON.stringify(user));
                }

                var o = { ...userInfo };
                o.focus_id = response.PMATRI;
                o.trigram = response.ZN01PP;
                o.installer = response.ZN06PP === "O" ? 1 : 0;
                o.executive = response.ZN05PP === "O" ? 1 : 0;
                setUserInfo(o);
                setSaving(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setSaving(false);
            }
        );
    }

    return <Fragment>

        <h4 className="text-center"> Mise à jour de la fiche {userInfo && userInfo.last_name} {userInfo && userInfo.first_name} </h4>
        {loading && <WaitingRoundSnippers />}
        {error && <ErrorMessage error={error} errorState={setError} />}

        {
            userInfo &&
            <div className="col-8 offset-2">

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "150px" }}>Email</span>
                    <input type="text" className="form-control" data-field="email"
                        onChange={onChangeElt} onBlur={save} value={userInfo.email} />
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "150px" }}>Username</span>
                    <input type="text" className="form-control" data-field="username"
                        onChange={onChangeElt} onBlur={save} value={userInfo.username} />
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "150px" }}>Statut</span>
                    <select className="form-select custom-select" data-field="statut"
                        onChange={onChangeElt} onBlur={save} value={userInfo.statut}>
                        <option value="">Choose...</option>
                        <option value="active">Actif</option>
                        <option value="blocked">Bloqué</option>
                    </select>
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "150px" }}>Entreprise</span>
                    <input type="text" className="form-control" data-field="company"
                        onChange={onChangeElt} onBlur={save} value={userInfo.company} />
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "150px" }}>Mail du manager</span>
                    <input type="text" className="form-control" data-field="manager_mail"
                        onChange={onChangeElt} onBlur={save} value={userInfo.manager_mail} />
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "150px" }}>Global ID</span>
                    <input type="text" className="form-control" data-field="global_id"
                        onChange={onChangeElt} onBlur={save} value={userInfo.global_id} />
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "150px" }}>Prénom</span>
                    <input type="text" className="form-control" data-field="first_name"
                        onChange={onChangeElt} onBlur={save} value={userInfo.first_name} />
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "150px" }}>Nom</span>
                    <input type="text" className="form-control" data-field="last_name"
                        onChange={onChangeElt} onBlur={save} value={userInfo.last_name} />
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "150px" }}>Tel</span>
                    <input type="text" className="form-control" data-field="phone_nb"
                        onChange={onChangeElt} onBlur={save} value={userInfo.phone_nb} />
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "150px" }}>Trigramme</span>
                    <input type="text" className="form-control" data-field="trigram"
                        onChange={onChangeElt} onBlur={onTrigramChange} value={userInfo.trigram} 
                        readOnly={saving}/>
                    { saving && <ButtonWaiting /> }
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "150px" }}>Installateur</span>
                    <input type="text" className="form-control" value={userInfo.installer ? "Oui" : "Non"} readOnly />
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "150px" }}>Manager</span>
                    <input type="text" className="form-control" value={userInfo.executive ? "Oui" : "Non"} readOnly />
                </div>


            </div>
        }


    </Fragment>

}

export default UserEdit;