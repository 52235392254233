import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import WaitingRoundSnippers from "../common/smallComponents.js/WaitingRoundSnippers";
import SuccessMessage from "../common/smallComponents.js/SuccessMessage";

const BoardModerator = () => {
  const [content, setContent] = useState("");
  const [msg, setMsg] = useState();
  const [err, setErr] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    UserService.getModeratorBoard().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setMsg();
    setErr();

    setLoading(true);
    UserService.testReturnTime(e.target.duration.value).then(
      (response) => {
        setMsg(response);
        setLoading(false);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

          setErr(_content);
          setLoading(false);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>{content}</h3>
        <br></br>
        {loading && <WaitingRoundSnippers />}
        {msg && <SuccessMessage msg={msg} /> }
        <form onSubmit={onSubmit}>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">Duration (in sec)</span>
            <input type="number" name="duration" className="form-control" 
              placeholder="Set the waiting time before return response by request" required/>
            <button className="btn btn-success" disabled={loading}>Test</button>
          </div>
        </form>

      </header>
    </div>
  );
};

export default BoardModerator;
