import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table";
import subcontracting from "../../services/supplyChain/subcontracting";
import { DefaultColumnFilter, filterMethods } from "../../functions/TableFuntions";
import "../../css/supplyChain/subcontracting.css"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import getUrlParam from "../../functions/StrFunctions";
import errorManagement from "../../services/errorManagement";
import { hideFirstColumn, setSent } from "./functions/SubcontractingTable";

import { closeNav, openNav } from "../../functions/SideNav";
import { FileUploader } from "react-drag-drop-files";
import { confirmAlert } from "react-confirm-alert";
import Moment from 'moment';
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import LabelsChoice from "../../common/smallComponents.js/LabelsChoice";
import { downloadSingleFile } from "../../services/functions/FilesProcess";

const SubcontractingTable = (props) => { 
    const { t } = useTranslation('subcontracting');
    const [loading, setLoading] = useState(false);
    const [machine, setMachine] = useState("");
    const [err, setErr] = useState(null);
    const [data, setData] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [files, setFiles] = useState([]);
    const [addingFiles, setAddingFiles] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const API_URL = process.env.REACT_APP_API_URL;
    const [chat, setChat] = useState([]);
    const [openChat, setOpenChat] = useState(false);
    const [machineInfo, setMachineInfo] = useState();
    const [displaySentDate, setDisplaySentDate] = useState(false);
    const [savingDate, setSavingDate] = useState(false);
    const [typeLabels, setTypeLabels] = useState("available");
    const [downloadingLab, setDownloadingLab] = useState(false);

    const { user: currentUser } = useSelector((state) => state.auth);
    const token = user && "Bearer " + user.accessToken;

    const fileTypes = ["JPG", "PNG", "GIF", "XLS", "XLSX", "PDF", "DOCX"];

    const columns = useMemo(
        () => subcontracting.getColumnsInfo(t), []
    );

    //If there is machine parameter launch search
    useEffect(() => {
        var machine = getUrlParam(props, "machine");
        if (machine !== null) {
            setMachine(machine);
            document.getElementById("machine-srch").value = machine;
            setTimeout(function () { document.getElementById("btn-validation").click() }, 500);
        }
    }, []);

    //Set filters for table
    const filterTypes = useMemo(() => (filterMethods()), []);

    //Set default columns of table
    const defaultColumn = useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter
        }),
        []
    );

    //Set in data base the comments
    const updateData = (id, value, e) => {
        var need_number = e.target.parentNode.parentNode.id;
        var old_value = e.target.getAttribute("oldValue").toString();

        if ((value === null) || (value.toString() === old_value)) return false;

        if (id === "bp_internal_remarks" && (
            currentUser.roles.includes("ROLE_INTERNAL") || currentUser.roles.includes("ROLE_ADMIN"))) {
            subcontracting.setInternalComment(need_number, value).then(
                (response) => {

                },
                (error) => {
                    setErr(errorManagement.handleError(props, error));
                    setLoading(false);
                }
            );
        } else if (id === "bp_external_remarks" && (
            currentUser.roles.includes("ROLE_EXTERNAL") || currentUser.roles.includes("ROLE_ADMIN"))) {
            subcontracting.setExternalComment(need_number, value);
        } else if (id === "sent" && (
            currentUser.roles.includes("ROLE_INTERNAL") || currentUser.roles.includes("ROLE_ADMIN"))) {

            subcontracting.setSentQty(need_number, value).then(
                (response) => {

                },
                (error) => {
                    setErr(errorManagement.handleError(props, error));
                    setLoading(false);
                }
            );
        } else if (id === "sent2Date" && (
            currentUser.roles.includes("ROLE_INTERNAL") || currentUser.roles.includes("ROLE_ADMIN"))) {
            //ok
        } else {
            alert("You can't change this field !");
            e.target.value = old_value;
        }

    };


    //Create table
    const tableInstance = useTable({ columns, data, defaultColumn, filterTypes, updateData, currentUser }, useFilters, useGlobalFilter, useSortBy);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance

    //Set machine on change
    const onMachineChange = (e) => {
        setMachine(e.target.value);
    }

    /**
     * Adding machine_param because setMachine come after function
     * @param {*} machine_param 
     */
    const getSubcontractingTable = (e, machine_param) => {
        try { e.preventDefault(); } catch (e) { }


        setLoading(true);
        setErr(null);
        setData([]);

        subcontracting.getSubcontractingTable(machine ? machine : machine_param).then(
            (response) => {
                if (response.list.length === 0) {
                    setErr("Aucune machine trouvée!");
                    setLoading(false);
                    return;
                }

                setData(response.list);
                setFiles(response.files)
                setLoading(false);
                setMachine(response.list[0].machine);
                setMachineInfo(response.machine_info);
                if (response.chat !== null) setChat(JSON.parse(response.chat));
            },
            (error) => {
                setErr(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }

    const shareFiles = (file) => {
        setAddingFiles(true);

        subcontracting.subcontractorShareFiles(machine, file).then(
            (response) => {

                if (response !== null)
                    setFiles(response)

                setAddingFiles(false);
            },
            (error) => {
                setErr(errorManagement.handleError(props, error, document));
                document.getElementsByClassName("scroll-to-top")[0].click();
                setAddingFiles(false);
            })
    };

    const removeFile = (e) => {
        const f = new Array(e.target.getAttribute("data-file"));
        setErr(null);

        confirmAlert({
            title: "Annulation de partage",
            message: "Etes-vous sûr de vouloir supprimer le fichier " + f,
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setLoading(true);

                        subcontracting.removeSubcontractorFile(machine, f).then(
                            (response) => {
                                if (response !== null)
                                    setFiles(response);

                                setLoading(false);
                            },
                            (error) => {
                                setErr(errorManagement.handleError(props, error));
                                setLoading(false);
                            }
                        );

                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    function downloadBplusFiles() {
        window.open("", "_blank").location.href =
            API_URL + "subcontracting/download-all-files?machine=" + machine + "&Authorization=" + token;
    }

    function downloadSubcontractorFiles() {
        window.open("", "_blank").location.href =
            API_URL + "subcontracting/download-subcontractor-all-files?machine=" + machine + "&Authorization=" + token;
    }

    /**
     * chat
     */
    const onChatWrite = (e) => {
        e.preventDefault();
        var txt = e.target.chat.value;
        if (txt.trim() === "") return;

        var arr = [...chat];

        var o = {
            user: user.first_name + " " + user.last_name,
            date: new Date(),
            msg: txt
        }

        arr.push(o);
        setChat(arr);
        e.target.chat.value = "";
        var elt = document.getElementById("chat-view");
        setTimeout(() => {
            elt.scrollTop = elt.scrollHeight;
        }, 200);
        saveChat(arr);
    }

    const onCloseChat = () => {
        setOpenChat(false);
    }

    const onOpenChat = () => {
        setOpenChat(true);
    }

    const deleteMessage = (e) => {
        var key = parseInt(e.target.getAttribute("data-key"));
        var arr = [...chat];
        arr = arrayRemoveElt(arr, key);
        setChat(arr);
        saveChat(arr);
    }

    const saveChat = (arr) => {
        subcontracting.saveChat(machine, arr).then((response) => { }, (error) => {  });
    }

    Moment.locale('fr');

    const getColumnContent = (cell) => {
        if (cell.column.id === "sent2Date") {
            return <Fragment>
                <input type="number" className="form-control text-center" value={data[cell.row.id].sent2} data-key={cell.row.id}
                    data-id={cell.row.cells[0].value} onBlur={onSent2QtyBlur} onDoubleClick={setSentQty}></input>
                {
                    data[cell.row.id].sent2Date !== null && <div>
                        <br></br>
                        {Moment(data[cell.row.id].sent2Date).format("DD/MM/yyyy")}
                    </div>
                }
            </Fragment>;
        }

        return cell.render('Cell')
    }

    const setSentQty = (e) => {
        var key = parseInt(e.target.getAttribute("data-key"));
        var value = data[key].sent;

        if (value === null) return;

        setSent2Qty(e.target.getAttribute("data-id"), value);

        var arr = [...data];
        arr[key].sent2 = value;
        setData(arr);
    }

    const onSent2QtyBlur = (e) => {
        var key = parseInt(e.target.getAttribute("data-key"));
        var value = e.target.value;

        if (value === null) return;

        setSent2Qty(e.target.getAttribute("data-id"), value);
    }

    const setSent2Qty = (id, value) => {

        subcontracting.setSent2Qty(id, value).then(
            (response) => {

            },
            (error) => {
                setErr(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }

    const onSentValidation = (e) => {
        e.preventDefault();
        var date = e.target.date.value;
        var machine = machineInfo.no_machine;
        setSavingDate(true);
        subcontracting.setDateSent(machine, date).then(
            (response) => {
                setSavingDate(false);
                setDisplaySentDate(false);
            },
            (error) => {
                setErr(errorManagement.handleError(props, error));
                setDisplaySentDate(false);
                scrollToTop();
            }
        );
    }

    const exportLabels = (start) => {
        var arr = new Array();

        if( typeLabels === "available" ){
            data.map(v => { if( v.affected && v.affected > 0 ) arr.push(v) });
        }else{
            data.map(v => { if( v.category.toUpperCase().startsWith("CSM") ) arr.push(v) });
        }
        
        setDownloadingLab(true);
        subcontracting.getLabels(arr, start, machineInfo.affaire, machineInfo.no_machine, typeLabels).then(
            (response) => {
                var file = response.substring(response.lastIndexOf("\\") + 1, response.length);
                downloadSingleFile("temp", file);
                setDownloadingLab(false);
            }, (error) => {
                setErr(errorManagement.handleError(props, error));
                setDownloadingLab(false);
            }
        )
    }
  

    return (
        <div className="full-screen-div">
            <h3 className="text-center">{t('subcontracting')}</h3>

            {data.length > 0 && <img id="logo-chat" src={"/common/chat.png"} onClick={onOpenChat}></img>}

            <div id="chat" style={{ display: (openChat ? "" : "none") }}>
                <div id="close-div">
                    <button type="button" className="btn-close" onClick={onCloseChat} aria-label="Close"></button>
                </div>

                <div id="chat-view" >
                    {
                        chat.map((v, k) => {
                            return <div className={"bubble " + (v.user === user.first_name + " " + user.last_name ? "me" : "other")} key={"chat_" + k}>
                                <span className="user">{v.user + " " + Moment(v.date).format("DD/MM/yyyy")}:</span>
                                <p className="msg" dangerouslySetInnerHTML={{ __html: v.msg }}></p>
                                {
                                    (v.user === user.first_name + " " + user.last_name) && <div className="delete">
                                        <span data-key={k} onClick={deleteMessage}>Delete</span>
                                    </div>
                                }
                            </div>
                        })
                    }
                </div>

                <br></br>
                <form onSubmit={onChatWrite} id="send-chat">
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" name="chat" placeholder="Type here..." />
                        <button className="btn btn-secondary">Envoyer</button>
                    </div>
                </form>
            </div>

            <form onSubmit={getSubcontractingTable}>
                <div className="row justify-content-center">
                    <div className="col-6">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Machine</span>
                            </div>
                            <input type="text" id="machine-srch" className="form-control text-center font-weight-bold" onChange={onMachineChange} />
                            <button type="submit" className="btn btn-success" disabled={loading} id="btn-validation">
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>{t('search')}</span></button>
                        </div>
                    </div>
                </div>
            </form>

            {
                data.length === 0 &&
                    <div className="text-center">
                        <a href={"/subcontracting/scan"}>Scanner les articles à empaqueter</a>
                    </div>
            }

            {
                err && (
                    <div className="alert alert-danger text-center font-weight-bold" role="alert">{err}</div>
                )
            }

            {
                data.length > 0 &&
                <div>
                    <div id="mySidenav" className="sidenav text-white" style={{ zIndex: 3 }}>
                        <div className="sideNavContent">
                            <a href="#" className="closebtn" onClick={closeNav}>&times;</a>

                            {currentUser.roles.includes("ROLE_EXTERNAL") &&
                                <Fragment>
                                    <div id="download-zone">
                                        <a href="#" onClick={downloadBplusFiles}>
                                            {t('download files')}
                                        </a>

                                        <br></br>
                                        <span className="text-decoration-underline">{t('my shared files')}</span>
                                        <br></br>
                                        <ul className="list-group">
                                            {files.length === 0 && <li className="list-group-item">{t('nothing sent')}</li>}
                                            {
                                                files.map((v, k) =>
                                                    <li key={"ssf_" + k} className="list-group-item">
                                                        {v}
                                                        {!loading &&
                                                            <img src="/common/remove_icon.png"
                                                                style={{ width: "30px", position: "absolute", right: "0" }}
                                                                onClick={removeFile} data-file={v} />
                                                        }
                                                        {loading &&
                                                            <img src="/common/spinner-img.gif"
                                                                style={{ width: "30px", position: "absolute", right: "0" }}
                                                                onClick={removeFile} data-file={v} />
                                                        }
                                                    </li>
                                                )

                                            }
                                        </ul>

                                        <br /><br />
                                        <FileUploader handleChange={shareFiles} name="file" types={fileTypes} multiple={true}
                                            children={!addingFiles ? (
                                                <div className="uploadFiles" style={{ overflow: "hidden" }}>
                                                    <h4 className="text-white">{t('select or drag')}</h4>
                                                    <span className="text-white">{fileTypes.join(", ")}</span>

                                                    <img className="sideNavUploadImg" src="/common/upload_icon.png" style={{ marginTop: "2%" }}></img>
                                                </div>) :
                                                (<div className="text-center">
                                                    {t('uploading')}
                                                </div>)
                                            } />
                                    </div>

                                </Fragment>
                            }

                            {currentUser.roles.includes("ROLE_INTERNAL") &&
                                <div>
                                    <a href="#" onClick={downloadSubcontractorFiles}>Télécharger les fichiers du sous-traitant</a>
                                </div>
                            }

                            {(currentUser.roles.includes("ROLE_STOREHOUSE") || currentUser.roles.includes("ROLE_ADMIN")) &&
                                <div>
                                    <br></br>
                                    <h5 className="text-center">Télécharger les étiquettes</h5>

                                    <div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" onClick={()=>setTypeLabels("available")}
                                                checked={typeLabels === "available"}/>
                                            <label className="form-check-label" for="inlineRadio1">Disponibles</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" onClick={()=>setTypeLabels("csm")}
                                                 checked={typeLabels === "csm"}/>
                                            <label className="form-check-label" for="inlineRadio2">Consommables</label>
                                        </div>
                                    </div>

                                    <LabelsChoice onChooseTag={exportLabels} printingLabel={downloadingLab} />
                                    
                                </div>
                            }

                        </div>
                    </div>

                    <button type="button" className="btn btn-secondary see-bg"
                        onClick={openNav} style={{ marginBottom: "5px" }}>
                        <img src="/common/menu.png" style={{ width: "25px" }}></img>
                    </button>
                </div>
            }

            {
                downloading && (
                    <div className="text-center">
                        <div className="spinner-grow text-info" role="status"></div>
                        <div className="spinner-grow text-success" role="status"></div>
                        <div className="spinner-grow text-dark" role="status"></div>
                    </div>
                )
            }




            {
                data.length > 0 && (
                    <table {...getTableProps()} className="table text-center" id="subcontracting_table">
                        <thead className="thead-dark">
                            {// Loop over the header rows
                                headerGroups.map(headerGroup => (
                                    // Apply the header row props
                                    <tr {...headerGroup.getHeaderGroupProps()} className="table-dark sticky">
                                        {// Loop over the headers in each row
                                            headerGroup.headers.map((column, k) => (
                                                // Apply the header cell props
                                                <th {...column.getHeaderProps(column.getSortByToggleProps(),)}
                                                    style={{ display: hideFirstColumn(column.id) }}>
                                                    {k === 1 &&
                                                        <div>
                                                            <span className="fw-light fst-italic">{machineInfo.affaire}</span>
                                                            <br></br>
                                                        </div>
                                                    }
                                                    {k === 2 &&
                                                        <div>
                                                            <span className="fw-light fst-italic">{machineInfo.no_machine}</span>
                                                            <br></br>
                                                        </div>
                                                    }
                                                    {k === 3 &&
                                                        <div>
                                                            <span className="fw-light fst-italic">{machineInfo.typ_config}</span>
                                                            <br></br>
                                                        </div>
                                                    }
                                                    {column.render('Header')}
                                                    {/* Add a sort direction indicator */}
                                                    <span>
                                                        {column.isSorted
                                                            ? column.isSortedDesc
                                                                ? ' 🔽'
                                                                : ' 🔼'
                                                            : ''}
                                                    </span>

                                                </th>
                                            ))}
                                    </tr>

                                ))}
                            {// Loop over the header rows
                                headerGroups.map(headerGroup => (
                                    // Apply the header row props
                                    <tr {...headerGroup.getHeaderGroupProps()} className="table-dark fixed-header-second">
                                        {// Loop over the headers in each row
                                            headerGroup.headers.map(column => (
                                                // Apply the header cell props
                                                <th {...column.getHeaderProps()}
                                                    style={{ display: hideFirstColumn(column.id) }}>
                                                    {/* Render the columns filter UI */}
                                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                                    {column.id === "sent2Date" && (
                                                        <Fragment>
                                                            {
                                                                displaySentDate &&
                                                                <form onSubmit={onSentValidation} style={{ display: "flex" }}>
                                                                    <input type="date" name="date" required></input>
                                                                    <button type="submit" className="btn btn-success" disabled={savingDate}>
                                                                        {savingDate && <ButtonWaiting />}
                                                                        Ok</button>
                                                                </form>
                                                            }
                                                            {
                                                                !displaySentDate &&
                                                                <a href="#" className="text-white" onClick={() => { setDisplaySentDate(true) }}>Saisisr</a>
                                                            }
                                                        </Fragment>
                                                    )}
                                                </th>
                                            ))}
                                    </tr>


                                ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {// Loop over the table rows
                                rows.map(row => {
                                    // Prepare the row for display
                                    prepareRow(row)
                                    return (
                                        // Apply the row props
                                        <tr {...row.getRowProps()}
                                            className={setSent(row)}
                                            id={row.cells[0].value}>
                                            {// Loop over the rows cells
                                                row.cells.map(cell => {
                                                    // Apply the cell props
                                                    return (
                                                        <td {...cell.getCellProps()}
                                                            style={{ display: hideFirstColumn(cell.column.id) }}>
                                                            {getColumnContent(cell)}
                                                        </td>
                                                    )
                                                })}
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>

                )

            }




        </div>
    );

};

export default SubcontractingTable;