import { Fragment, useEffect, useState } from "react";
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import Quality from "../../../services/storehouse/Quality";
import errorManagement from "../../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../../functions/PageAnimation";

const NcEmailSupplier = (p) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const contentBlock = htmlToDraft(getHtml());
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(contentState))

    useEffect( () => {
        const contentBlock = htmlToDraft(getHtml());
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setEditorState(() => EditorState.createWithContent(contentState));
    }, [p.files]);

    function getHtml(){
        var msg = "Bonjour,<br><br>";
		msg	+= "Veuillez trouver ci-joint une nouvelle fiche de non-conformité.<br>";
		msg	+= "Traitement de la NC :  M. Franck BARTHELEMY              <a href=\"mailto:franck.barthelemy@sealedair.com\">franck.barthelemy@sealedair.com</a>        tel : 07 85 54 80 53<br><br>";
		msg	+= "Sachez que nous avons besoin des pièces conformes aussi vite que possible, merci de revenir vers nous <b>dans les 48h avec votre résolution "
				+ "et nous indiquer ce que nous devons faire des pièces en NC.</b>"
				+ "<br>Merci de me mettre en copie lors de votre réponse.<br><br>";

		msg	+= "Le matériel non-conforme reste à votre disposition dans nos ateliers pendant 2 semaines, sans retour de votre part il sera jeté.<br>";
		msg	+= "Pour récupérer vos colis, merci de nous donner le jour de reprise et le nom du transporteur.<br>";
		msg	+= "Lors de votre livraison des pièces conformes merci de bien noter sur le BL le numéro de non-conformité concernée.<br>";

        var cpt = 0;
        for( let i =0; i<p.files.length; i++ ) if( p.files[i] ) cpt++;
        
        if( cpt === 1 ) msg += "<br>Vous trouverez ci-joint une photo des non-conformités.<br><br>";
        if( cpt > 1 ) msg += "<br>Vous trouverez ci-joint des photos des non-conformités.<br><br>";

        msg += "Merci de nous livrer la pièce conforme avec cette fiche de NC s'il vous plait.<br><br>";

        msg += user.first_name + " " + user.last_name  + "</br>";
        msg += "Purchasing Assistant<br>";
        msg += "Office: 07 86 14 57 63<br>SEALED AIR - B+ EQUIPMENT<br>465, avenue du Col de l’Ange<br>";
        msg += "13420 GEMENOS<br>SealedAir.com / Bplus-equip.com";

        return msg;
    }

    const send = () => {
        if( p.contacts.first_mail === null &&  p.contacts.second_mail === null && 
                p.contacts.third_mail === null &&  p.contacts.nc_1 === null
                &&  p.contacts.nc_2 === null &&  p.contacts.nc_3 === null) {
                    p.setError("Choisissez au moins un contact")
                    scrollToTop();
                    return;
        }
        
        var mail = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        p.setLoading(true);

        Quality.sendNcSupplier(p.rows, p.files, p.contacts, mail).then(
            (res) => {
                p.setMsg("Envoyé !");
                p.setLoading(false);
                scrollToTop();
            },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                p.setLoading(false);
                scrollToTop();
            }
        );
    }

    return <Fragment>



        <div className="accordion mt-3 mb-3 col-10 offset-1" id="accordionExample">
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed fw-bold text-center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span style={{ margin: "auto", width: "100%" }}>Envoyer un mail au fournisseur</span>
                    </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">

                        <Editor editorState={editorState}
                            onEditorStateChange={setEditorState}
                            wrapperClassName="draftEditor-wrapper" editorClassName="draftEditor-editor" />

                        <div className="text-center mt-1">
                            <button className="btn btn-success" onClick={send} disabled={p.loading}>
                                {p.loading && <ButtonWaiting />}
                                Envoyer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </Fragment>

}

export default NcEmailSupplier;