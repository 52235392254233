import { Fragment, useEffect, useState } from "react"
import ArticleInfo from "../../services/articles/ArticleInfo";
import errorManagement from "../../services/errorManagement";
import { scrollToTop } from "../../functions/PageAnimation";
import FreeTags from "./components/FreeTags";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import ExcelTags from "./components/ExcelTags";

const Tags = (props) => {

    const [list, setList] = useState([{ code: "", ref: "", label: "", qty: 1 }]);
    const [art, setArt] = useState();
    const [start, setStart] = useState(1);
    const [tagFormat, settagFormat] = useState("ARD");

    const [tab, setTab] = useState(0);

    const [error, setError] = useState();
    const [msg, setMsg] = useState();


    const getCss = (format) => {
        return {
            width: format === tagFormat ? "300px" : "250px",
            height: format === tagFormat ? "100px" : "80px",
            backgroundColor: format === tagFormat ? "#9eeaf9" : "transparent"
        }
    }

    return <Fragment>
        
        <ul className="nav nav-tabs mb-2">
            <li className="nav-item">
                <a className={'nav-link ' + (tab === 0 ? "active" : "")}
                    aria-current="page" href="#" onClick={() => setTab(0)}>Etiquettes libres</a>
            </li>

            <li className="nav-item">
                <a className={'nav-link ' + (tab === 1 ? "active" : "")}
                    href="#" onClick={() => setTab(1)}>Etiquettes Excel</a>
            </li>
        </ul>

        {error && <ErrorMessage error={error} errorState={setError} />}

        { tab === 0 && <FreeTags format={tagFormat} settagFormat={settagFormat} getCss={getCss} 
                setStart={setStart} start={start} list={list} setList={setList} setError={setError}/>
        }

        { tab === 1 && <ExcelTags setStart={setStart} start={start} setError={setError}/> }


    </Fragment>
}
export default Tags;