import { createColumnHelper } from "@tanstack/react-table";
import { scrollToTop } from "../../../functions/PageAnimation";
import { SelectEmptyOrNot } from "../../../functions/TableFuntions";
import errorManagement from "../../../services/errorManagement";
import BomDao from "../../../services/machines/BomDaoV2";
import MachineInfo from "../../../services/machines/MachineInfo";
import { confirmAlert } from "react-confirm-alert";
import { forceString } from "../../../functions/StrFunctions";

const getTempBom = (machine, type, setTemp, props, setLoading, setError) => {
    BomDao.getTempBomOnly(machine, type).then(
        (response) => {
            setTemp(response)
        },
        (error) => {
            setError(errorManagement.handleError(props, error));
            scrollToTop();
            setLoading(false);
        }
    );
}

const getIntranetBom = (machine, type, setExistingBom, props, setLoading, setError) => {
    BomDao.getSavedBomOnly(machine, type).then(
        (response) => {
            setExistingBom(response)
        },
        (error) => {
            setError(errorManagement.handleError(props, error));
            scrollToTop();
            setLoading(false);
        }
    );
}

const getFocusBom = (machine, type, setFocusBom, props, setLoading, setError) => {
    BomDao.getFocusBomOnly(machine, type).then(
        (response) => {
            setFocusBom(response)
        },
        (error) => {
            setError(errorManagement.handleError(props, error));
            scrollToTop();
            setLoading(false);
        }
    );
}

const getMachineInfos = (machine, setMachineInfo, props, setLoading, setError) => {
    MachineInfo.getOneMachineInfo(machine).then(
        (response) => {
            setMachineInfo(response)
        },
        (error) => {
            setError(errorManagement.handleError(props, error));
            scrollToTop();
            setLoading(false);
        }
    );
}

const tempVsIntranet = (temp, setData, setLoading, cols, setCols) => {
    var arr = new Array();

    // Compare temp -> intranet
    temp.map(v => {
        v.tempQty = v.qty;
        v.intranetQty = 0;//ToDo
        v.delta = v.tempQty + v.intranetQty;
        if (v.BLOCL && v.BLOCL === "N") v.BLOCL = null;
        if (speToCreate(v) || stdToCreate(v)) v.ar = "A créer";
        arr.push(v);
    });

    // Compare intranet -> temp: unuseful

    var o = { ...cols };
    if (!o.hidden.includes("intranetQty")) o.hidden.push("intranetQty");
    if (!o.hidden.includes("focusQty")) o.hidden.push("focusQty");
    if (!o.hidden.includes("delta")) o.hidden.push("delta");
    setCols(o)

    setData(arr);
    setLoading(false);
}

const intranetVsFocus = (intranet, focus, setData, setLoading, cols, setCols, setToAdd, setToRemove, setToUpd) => {
    var arr = new Array();
    var add = new Array();
    var remove = new Array();
    var qtyUpd = new Array();

    // Compare intranet -> focus
    intranet.map(v => {
        v.intranetQty = v.qty;
        v.focusQty = getFocusQty(v, focus);
        v.delta = v.intranetQty - v.focusQty;

        if( v.focusQty === 0 ) add.push(v);
        if( v.delta !== 0 && v.focusQty > 0 ) qtyUpd.push(v);

        if (v.BLOCL && v.BLOCL === "N") v.BLOCL = null;
        if (speToCreate(v) || stdToCreate(v)) v.ar = "A créer";
        arr.push(v);
    });

    // Compare focus -> intranet
    focus.map(v => {
        if( !isInIntranet(v, intranet) ){ 
            v.intranetQty = 0;
            v.focusQty = v.qty;
            v.delta = v.intranetQty - v.focusQty;
            arr.push(v); 
            remove.push(v);
        };
    });

    var o = { ...cols };
    if (!o.hidden.includes("tempQty")) o.hidden.push("tempQty");
    if (!o.hidden.includes("delta") && focus.length === 0) o.hidden.push("delta");
    if (!o.hidden.includes("focusQty") && focus.length === 0) o.hidden.push("focusQty");
    setCols(o)

    setData(arr);
    setToAdd(add);
    setToUpd(qtyUpd);
    setToRemove(remove);

    setLoading(false);
}

function getFocusQty(v, focus) {
    if (focus.length === 0) return 0;
    if ( v.ref.startsWith("NM-") || v.ref.startsWith("NE-") ) return v.qty;

    var intranetSrch = forceString(v.se).trim() + forceString(v.rep).replace('.0').toUpperCase().replace(".0", '').trim()
                + forceString(v.ar).replace(/ /g, '').trim().toUpperCase();

    var v2;
    for( let i=0; i<focus.length; i++ ){
        v2 = focus[i];
        var focusSrch = forceString(v2.se).trim() + forceString(v2.rep).replace('.0').toUpperCase().replace(".0", '').trim()
            + forceString(v2.ar).replace(/ /g, '').trim().toUpperCase();

            if( intranetSrch === focusSrch )
                return v2.qty;
    }

    return 0;
}

function isInIntranet(v, intranet){
    if (intranet.length === 0) return false;
    if ( v.ar && v.ar !== null && v.ar.startsWith("AV_") ) return true;
    
    var focusSrch = forceString(v.se).trim() + forceString(v.rep).replace('.0').toUpperCase().replace(".0", '').trim()
    + forceString(v.ar).replace(/ /g, '').trim().toUpperCase();

    var v2;
    for( let i=0; i<intranet.length; i++ ){
        v2 = intranet[i];
        var intranetSrch = forceString(v2.se).trim() + forceString(v2.rep).replace('.0').toUpperCase().replace(".0", '').trim()
            + forceString(v2.ar).replace(/ /g, '').trim().toUpperCase();

            if( intranetSrch === focusSrch )
                return true;
    }

    return false;
}

const getColsHeader = (cols, type, onChangeValue) => {
    var arr = new Array();
    const columnHelper = createColumnHelper();

    arr.push(columnHelper.accessor('cat', {
        header: 'Catégorie',
        cell: i => <input className="form-control" defaultValue={i.getValue()}
                    onBlur={onChangeValue} data-key={i.row.index} title={i.getValue()}
                    data-table={type} data-field="cat" data-id={i.row.original.id}
                    readOnly={type && type === "focus"}
                    style={{ maxWidth: "60px" }}></input>,
        footer: info => info.column.id,
    }));

    arr.push(columnHelper.accessor('se', {
        header: 'S/E',
        footer: info => info.column.id,
    }));

    arr.push(columnHelper.accessor('rep', {
        header: 'Repère',
        footer: info => info.column.id,
    }));

    if (!cols.hidden.includes("tempQty")) {
        arr.push(columnHelper.accessor('tempQty', {
            header: 'Qté à commander',
            cell: i => <input type="number" className="form-control" defaultValue={i.getValue()}
                        onBlur={onChangeValue} data-key={i.row.index} title={i.getValue()}
                        data-table={type} data-field="qty" data-id={i.row.original.id}
                        readOnly={type && type === "focus"}
                        style={{ maxWidth: "60px" }}></input>,
            footer: info => info.column.id,
        }));
    }

    if (!cols.hidden.includes("intranetQty")) {
        arr.push(columnHelper.accessor('intranetQty', {
            header: 'Qté à commander',
            cell: i => <input type="number" className="form-control" defaultValue={i.getValue()}
                        onBlur={onChangeValue} data-key={i.row.index} title={i.getValue()}
                        data-table={type} data-field="qty" data-id={i.row.original.id}
                        readOnly={type && type === "focus"}
                        style={{ maxWidth: "60px" }}></input>,
            footer: info => info.column.id,
        }));
    }

    if (!cols.hidden.includes("focusQty")) {
        arr.push(columnHelper.accessor('focusQty', {
            header: 'Qté Focus',
            footer: info => info.column.id,
        }));
    }

    if (!cols.hidden.includes("delta")) {
        arr.push(columnHelper.accessor('delta', {
            header: 'Delta',
            cell: i => <div className="text-center fw-bold">{i.getValue()}</div>,
            footer: info => info.column.id,
        }));
    }

    arr.push(columnHelper.accessor('french_label', {
        header: 'Désignation FR',
        footer: info => info.column.id,
    }));

    arr.push(columnHelper.accessor('ref', {
        header: 'Référence',
        cell: i => <input className="form-control" defaultValue={i.getValue()}
                    onBlur={onChangeValue} data-key={i.row.index} title={i.getValue()}
                    data-table={type} data-field="ref" data-id={i.row.original.id}
                    readOnly={type && type === "focus"}
                    style={{minWidth: "250px"}}></input>,
        footer: info => info.column.id,
    }));

    arr.push(columnHelper.accessor('maker', {
        header: 'Fabricant',
        footer: info => info.column.id,
    }));

    arr.push(columnHelper.accessor('ar', {
        header: 'Code article',
        cell: i => <input className="form-control" defaultValue={i.getValue()}
                    onBlur={onChangeValue} data-key={i.row.index} title={i.getValue()}
                    data-table={type} data-field="ar" data-id={i.row.original.id}
                    readOnly={type && type === "focus"}
                    style={{minWidth: "150px"}}></input>,
        footer: info => info.column.id,
    }));

    arr.push(columnHelper.accessor('stock_at_term', {
        header: 'Stock',
        footer: info => info.column.id,
    }));

    arr.push(columnHelper.accessor('BLOCL', {
        header: 'Blocage',
        cell: i => i.getValue() === undefined ? null :
            i.getValue() === "C" ? "Complet" : i.getValue() === "A" ? "Appro" : "",
        footer: info => info.column.id,
    }));

    arr.push(columnHelper.accessor('GEBEL', {
        header: 'En besoin',
        footer: info => info.column.id,
    }));

    arr.push(columnHelper.accessor('OBSES', {
        header: 'Observation',
        cell: i => (i.getValue() && i.getValue() !== null && i.getValue().trim() !== "") ?
            <img title={i.getValue()} src={"/common/tooltip.png"}></img> : "",
        footer: info => info.column.id,
    }));

    return arr;
}

const getTempGlobalAnalyse = async (temp, setInfo, setSpeToCreate) => {
    var artSpeToCreate = new Array();
    var nbStdToCreate = 0;
    var nbBlocked = 0;
    var nbNotNeeded = 0;

    await temp.map(v => {
        if (speToCreate(v)) {
            artSpeToCreate.push(v);
        }
        if (stdToCreate(v)) nbStdToCreate++;
        if (isBlocked(v)) nbBlocked++;
        if (isNotNeed(v)) nbNotNeeded++;
    })

    var text = "Récapitulatif:<br><ul>";
    text += "<li>" + artSpeToCreate.length + " art. spé à créer.</li>"
    text += "<li>" + nbStdToCreate + " art. std à créer.</li>"
    text += "<li>" + nbBlocked + " art. bloqués.</li>"
    text += "<li>" + nbNotNeeded + " non en besoin.</li>";
    text += "</ul>";

    setSpeToCreate(artSpeToCreate);

    setInfo(text)
}

function speToCreate(v) {
    if (!v.se.endsWith("EG") && (v.ar === "" || v.ar === "A créer") && parseInt(v.is_spe) > 0) {
        return true;
    } else if (v.se.endsWith("EG") && (v.ar === "" || v.ar === "A créer") && parseInt(v.is_spe) > 0 && v.ref.startsWith("PL-")) {
        return true;
    } else {
        return false;
    }
}
function stdToCreate(v) {
    if (!v.se.endsWith("EG") && (v.ar === "" || v.ar === "A créer") && parseInt(v.is_spe) === 0) {
        return true
    } else if (v.se.endsWith("EG") && (v.ar === "" || v.ar === "A créer") && parseInt(v.is_spe) === 0 && v.ref.startsWith("PL-")) {
        return true;
    } else {
        return false;
    }
}

function isBlocked(v) {
    if (v.BLOCL !== undefined && v.BLOCL !== null && v.BLOCL !== "N" && v.ZN15PA !== null) {
        if (!v.ZN15PA.toUpperCase().startsWith("CSM") && !v.ZN15PA.toUpperCase().startsWith("CMP") && !v.ZN15PA.toUpperCase().startsWith("HST PNEU")) {
            return parseFloat(v.stock_at_term) <= 0;
        } else {
            return false;
        }
    } else {
        return false;
    }

}

function isNotNeed(v) {
    return (v.GEBEL !== undefined && v.GEBEL === "N" && parseFloat(v.stock_at_term) <= 0
        && (v.ZN15PA !== null && !v.ZN15PA.toUpperCase().startsWith("CSM") && !v.ZN15PA.toUpperCase().startsWith("CMP")))
}

const validateXlsBom = (machine, existingBom, typeBom, speToCreate, setWorking, setError, p) => {

    confirmAlert({
        title: "Confirmation",
        message: "Etes-vous sûr de vouloir valider la nomenclature de la machine " + machine
            + " ? " + (existingBom ? "Cette action écrasera la nomenclature déjà enregistrée dans l'intranet." : ""),
        buttons: [
            {
                label: "Oui",
                onClick: () => {
                    setWorking(true);
                    BomDao.confirmBom(machine, typeBom, speToCreate).then(
                        () => {
                            window.location.reload();
                        },
                        (error) => {
                            setWorking(false);
                            setError(errorManagement.handleError(p, error));
                            scrollToTop();
                        }
                    );
                }
            },
            {
                label: "Non"
            }
        ]
    });
}

const validateIntranetBom = (machine, typeBom, focusBom, setWorking, setError, setMsg, p) => {
    confirmAlert({
        title: "Confirmation",
        message: "Etes-vous sûr de vouloir lancer la machine " + machine
            + " des nomenclatures en achats ? ",
        buttons: [
            {
                label: "Oui",
                onClick: () => {
                    setWorking(true);

                    BomDao.notifyPurchaser(machine, typeBom, (focusBom && focusBom.length > 0) ).then(
                        () => {
                            setMsg("Un mail a été envoyé, vous serez en copie !");
                            setWorking(false);
                            scrollToTop();
                        },
                        (error) => {
                            setWorking(false);
                            setError(errorManagement.handleError(p, error));
                            scrollToTop();
                        }
                    );
                }
            },
            {
                label: "Non"
            }
        ]
    });
}

export default {
    getTempBom,
    getIntranetBom,
    getFocusBom,
    getMachineInfos,
    tempVsIntranet,
    getColsHeader,
    getTempGlobalAnalyse,
    validateXlsBom,
    intranetVsFocus,
    validateIntranetBom
}