import { createColumnHelper } from "@tanstack/react-table";
import { Fragment, useState } from "react";
import ReactTable from "../../../common/smallComponents.js/ReactTable";
import { ButtonWaiting } from "../../../functions/PageAnimation";

const TempCompareTab = (p) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const [working, setWorking] = useState(false);


    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('cat', {
            header: 'Catégorie',
            filterFn: 'selectMultiple',
            cell: i => <div className="display-flex">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('se', {
            header: 'S/E',
            cell: i => <div className="text-center fw-bold">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('rep', {
            header: 'Repère',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('qty', {
            header: 'Qté',
            cell: i => <div className="display-flex">
                <button className={"btn "
                    + (i.row.original.type_rst === "add" ? "btn-success " :
                        i.row.original.type_rst === "remove" ? "btn-danger " : "")}>{i.row.original.type_rst === "add" ? "+ " :
                            i.row.original.type_rst === "remove" ? "- " : "="}</button>
                <input type="number" className="form-control text-center" defaultValue={i.getValue()}
                    onBlur={p.onFieldBlur} data-key={i.row.original.parent_key}
                    data-table={i.row.original.table} data-field="qty" data-id={i.row.original.id}
                    readOnly={i.row.original.qty_changing}></input>
                {i.row.original.qty_changing && <button className="btn"><ButtonWaiting /></button>}
            </div>
        }));

        arr.push(columnHelper.accessor('french_label', {
            header: 'Désignation FR',
            cell: i => <div>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('ref', {
            header: 'Référence',
            cell: i => <div className="display-flex">
                <input className="form-control" defaultValue={i.getValue()} //onChange={p.onFieldChange}
                    onBlur={p.onFieldBlur} style={{ minWidth: "250px" }} data-key={i.row.original.parent_key} title={i.getValue()}
                    data-table={i.row.original.table} data-field="ref" data-id={i.row.original.id}></input>
                {i.row.original.ref_changing && <button className="btn"><ButtonWaiting /></button>}
            </div>
        }));

        arr.push(columnHelper.accessor('maker', {
            header: 'Fabricant',
            cell: i => <div className="display-flex">
                <input className="form-control" defaultValue={i.getValue()} //onChange={p.onFieldChange}
                    onBlur={p.onFieldBlur} data-key={i.row.original.parent_key} title={i.getValue()}
                    data-table={i.row.original.table} data-field="maker" data-id={i.row.original.id}></input>
                {i.row.original.maker_changing && <button className="btn"><ButtonWaiting /></button>}
            </div>
        }));

        arr.push(columnHelper.accessor('ar', {
            header: 'Code article',
            filterFn: 'strWithEmpty',
            cell: i => <Fragment>
                <div className="display-flex">
                    <input className="form-control" defaultValue={i.getValue()} //onChange={p.onFieldChange}
                        onBlur={p.onFieldBlur} data-key={i.row.original.parent_key} title={i.getValue()}
                        data-table={i.row.original.table} data-field="ar" data-id={i.row.original.id}
                        style={{ minWidth: "150px" }}></input>
                    {i.row.original.ar_changing && <button className="btn"><ButtonWaiting /></button>}
                </div>
                {i.row.original.artproposed !== undefined && i.row.original.artproposed !== null &&
                    <Fragment>
                        <a>Prop: <a href={"/article/info/get?article=" + i.row.original.artproposed}>{i.row.original.artproposed}</a></a>
                    </Fragment>}
            </Fragment>
        }));

        arr.push(columnHelper.accessor('stock_at_term', {
            header: 'Stock',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('OBSES', {
            header: 'Coms',
            cell: i => (i.getValue() && i.getValue() !== null && i.getValue().trim() !== "") ?
                <img title={i.getValue()} src={"/common/tooltip.png"}></img> : ""
        }));

        return arr;
    }

    //Set columns
    const columns = getColumns();



    return <Fragment>

        <ReactTable columns={columns} data={p.list}
            origin="bom_content" classes={"no-padding"} top0={true} />
    </Fragment>

}

export default TempCompareTab;