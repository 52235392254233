import React from 'react'
import { useAsyncDebounce } from 'react-table'
// A great library for fuzzy filtering/sorting items
import {matchSorter} from 'match-sorter'
import { useTranslation } from 'react-i18next'

// Define a default UI for filtering
export function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)
  
    return (
      <span>
        Search:{' '}
        <input
          value={value || ""}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={'${count} records...'}
          style={{
            fontSize: '1.1rem',
            border: '0',
          }}
        />
      </span>
    )
}

// Define a default UI for filtering
export function DefaultColumnFilter( { column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length

    return (
        <input
        value={filterValue || ''}
        onChange={e => {
            setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`} 
        className="inputFilter"/>
    )
}

// This is a custom filter UI for selecting a unique option from a list
export function SelectColumnFilter( { column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set()
      preFilteredRows.forEach(row => {
        options.add(row.values[id])
      })
      return [...options.values()]
    }, [id, preFilteredRows])
  
    // Render a multi-select box
    return (
      <select style={{maxWidth: "150px", borderRadius: "12px"}}
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          ( option !== undefined && option !== "" && option !== null ) &&
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    )
  }

  //WSO function empty or not
  export function SelectEmptyOrNot( { column: { filterValue, setFilter, preFilteredRows, id } }) {
    const {t} = useTranslation();
    // Render a multi-select box
    return (
      <select className='inputFilter'
        value={filterValue}
        onChange={e => { setFilter(e.target.value || undefined) }} >
          <option value="">{t('all')}</option>
          <option value="empty">{t('empty')}</option>
          <option value="not empty">{t('not empty')}</option>
      </select>
    )
  }

// WSO: filter methods
export function filterMethods(){
  return {// Add a new fuzzyTextFilterFn filter type.
    fuzzyText: fuzzyTextFilterFn,
    // Or, override the default text filter to use "startWith"
    text: (rows, id, filterValue) => {
      return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue !== undefined
          ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
          : true
      })
    },
    empty: (rows, id, filterValue) => {
      return rows.filter(row => {
        const rowValue = row.values[id]
        if( filterValue === "not empty" ){
          return rowValue !== undefined && rowValue !== null && rowValue !== "";
        }else if( filterValue === "empty" ){
          return rowValue === undefined || rowValue === null || rowValue === "";
        }else{
          return false;
        }
      })
    },
  }
}

// This is a custom filter UI that uses a slider to set the filter value between a column's
// min and max values
export function SliderColumnFilter( { column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the min and max using the preFilteredRows
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      preFilteredRows.forEach(row => {
        min = Math.min(row.values[id], min)
        max = Math.max(row.values[id], max)
      })
      return [min, max]
    }, [id, preFilteredRows])
  
    return (
      <>
        <input
          type="range"
          min={min}
          max={max}
          value={filterValue || min}
          onChange={e => {
            setFilter(parseInt(e.target.value, 10))
          }}
        />
        <button onClick={() => setFilter(undefined)}>Off</button>
      </>
    )
  }

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
export function NumberRangeColumnFilter( { column: { filterValue = [], preFilteredRows, setFilter, id } }) {
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      preFilteredRows.forEach(row => {
        min = Math.min(row.values[id], min)
        max = Math.max(row.values[id], max)
      })
      return [min, max]
    }, [id, preFilteredRows])
  
    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        <input
          value={filterValue[0] || ''}
          type="number"
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
          }}
          placeholder={`Min (${min})`}
          style={{
            width: '70px',
            marginRight: '0.5rem',
          }}
        />
        to
        <input
          value={filterValue[1] || ''}
          type="number"
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
          }}
          placeholder={`Max (${max})`}
          style={{
            width: '70px',
            marginLeft: '0.5rem',
          }}
        />
      </div>
    )
  }

export function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}
