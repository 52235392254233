import { Fragment, useEffect, useState } from "react";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import ODM from "../../services/common/ODM";
import { createColumnHelper } from "@tanstack/react-table";
import { getFrFormat } from "../../functions/StrFunctions";
import ReactTable from "../../common/smallComponents.js/ReactTable";

const ListOdm = (props) => {

    const [list, setList] = useState();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();

    useEffect(() => { getList(""); }, []);

    function getList(term) {
        setError();
        setMsg();

        ODM.getOdmList(term).then(
            (r) => {
                setList(r);
                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();
        arr.push(columnHelper.accessor('id',
            {
                header: '#', cell: (i) => <div className="text-center">{i.getValue()}</div>
            }));

        arr.push(columnHelper.accessor('name', {
            header: 'Nom', cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('surname', {
            header: 'Prénom', cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('business',
            {
                header: 'Affaires', cell: (i) => <div className="text-center">{i.getValue()}</div>
            }));

        arr.push(columnHelper.accessor('creation', {
            header: 'Date de création', cell: (i) => <div className="text-center">
                {getFrFormat(i.getValue(), true)}
            </div>
        }));

        arr.push(columnHelper.accessor('xx', {
            header: '', cell: (i) => <div className="text-center">
                <a href={"/odm/request?id=" + i.row.original.id}>Accéder</a>
            </div>
        }));
        return arr;
    }

    //Set columns
    const columns = getColumns();

    return <Fragment>
        <h5 className="text-center fw-bold">Liste des Ordres De Mission</h5>

        {loading && <WaitingRoundSnippers />}<br></br>
        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}



        <div className="text-center mb-2">
            <a className="btn btn-outline-secondary" href="/odm/request">Nouvel ODM</a>
        </div>

        {
            list && <Fragment>
                <form onSubmit={(e) => { e.preventDefault(); getList(e.target.term.value) }}>
                    <div className="input-group mb-3">
                        <span className="input-group-text">Rechercher</span>
                        <input type="text" className="form-control" name="term"
                            placeholder="Trigramme, nom, prénom, affaire, client" />
                        <button className="btn btn-outline-success">Chercher</button>
                    </div>
                </form>

                <ReactTable columns={columns} data={list} top0={true} origin="odm_list" />
            </Fragment>
        }

    </Fragment>

}

export default ListOdm;