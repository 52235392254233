import { createColumnHelper } from "@tanstack/react-table";
import Moment from "moment";
import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import { ButtonWaiting, getHeight, scrollToTop } from "../../functions/PageAnimation";
import { getCurrentWeekYear, getDaysOfWeek, weeksInYear } from "../../functions/StrFunctions";
import fahDao from "../../services/common/fahDaoV2";
import errorManagement from "../../services/errorManagement";
import CommonRequests from "./smallComponents/CommonRequests";
import { caculateSchedule } from "./smallComponents/FahHeader";
import { calculateSumarry, checkBeforSubmit, checkConstrainsts } from "./smallComponents/InstallerCalculation";
import InstallerPC from "./smallComponents/InstallerPC";
import SendToInterim from "./smallComponents/SendToInterim";
import Tracking from "./smallComponents/Tracking";

const Manager = (props) => {
    const [response, setResponse] = useState();
    const [tab, setTab] = useState(3);
    const [lastTagName, setlastTagName] = useState();
    const [lastTabLoad, setLastTabLoad] = useState(false);

    const [tracking, setTracking] = useState();
    const [toSendInterim, setToSendInterim] = useState({ week: null, year: null });

    const [myStaff, setMyStaff] = useState();
    const [myInterim, setMyInterim] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [disRejectCause, setDisRejectCause] = useState(false);

    const [week, setWeek] = useState();
    const [year, setYear] = useState();
    const [trackYear, setTrackYear] = useState();

    const [member, setMember] = useState();
    const [weekDays, setWeekDays] = useState();
    const [curWeek, setCurWeek] = useState();
    const [curYear, setCurYear] = useState();
    const [oldBusiness, setOldBusiness] = useState(false);
    const [oldWeek, setOldWeek] = useState();
    const [oldYear, setOldYear] = useState();
    const [oldHours, setOldHours] = useState(false);

    const [saving, setSaving] = useState(false);

    const [toCheck, setToCheck] = useState();
    const [checkingMode, setCheckingMode] = useState(false);
    const [checkCursor, setCheckCursor] = useState(0);

    const [hours, setHours] = useState([]);
    const [abs, setAbs] = useState([]);
    const [emptyObj, setEmptyObj] = useState();
    const [soldeC2, setSoldeC2] = useState(0);

    const [err, setErr] = useState(new Object());

    /*const [summaryNonInst, setSumarryNonInst] = useState({
        tpstrav: 0, hrexc: 0,
        conge_ded_jour: 0, conge_ded_heure: 0, hr_exc_100: 0, hr_sup_125: 0,
        hr_sup_150: 0, maj_nuit: 0, maj_dim_ferie: 0, tot_cpt: 0, tpstrav: 0,
        abs_disease: 0, abs_c1: 0,
        night_justif: "", over_justif: "", move_justif: "", comment_except_bonus: ""
    });*/

    const [summaryInst, setSumarryInst] = useState({
        work_time: 0, wrk_or_ass: 0,
        cons_wrk_or_ass: 0, exc_hours: 0, abs_tot_d: 0, abs_tot_h: 0,
        abs_disease: 0, abs_c1: 0, abs_c2: 0, move: 0,
        p100: 0, p125: 0, p150: 0, p50: 0, p_d_100: 0, c1_100: 0, c1_125: 0,
        c1_150: 0, majD: 0, c2_25: 0, c2_100: 0, c2_50: 0,
        tot_p: 0, tot_c1: 0, tot_c2: 0,
        night_justif: "", over_justif: "", move_justif: "", comment_except_bonus: ""
    });

    useEffect(() => {
        var curPeriod = getCurrentWeekYear();
        var y = curPeriod.week === 1 ? curPeriod.year - 1 : curPeriod.year;
        var w = curPeriod.week === 1 ? weeksInYear( y ) : curPeriod.week - 1;
        setCurWeek(w);
        setCurYear(y);
        setWeek(w);
        setYear(y);
        setTrackYear(y);
        setOldWeek(w);
        setOldYear(y);
        setToSendInterim({ week: w, year: y })

        // Get ly staff
        fahDao.getMyStaff().then(
            (response) => {
                setMyStaff(response);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
            }
        );

        // Get tracking
        getTracking(y);

        // Get fah to check
        fahDao.getToCheckManager().then(
            (response) => {
                setToCheck(response);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
            }
        );

        //Get interim
        getInterim(w, y);
    }, []);

    /** */
    const doFah = (k) => {
        setLastTabLoad(true);
        setMember(myStaff[k]);

        doFahCommon(myStaff[k], week, year);
    }

    function getColor(i){
        var msg = i.row.original.msg;

        if( !msg ){
            return "";
        }else if ( msg === "Ignoré" ){
            return "td-bg-yellow";
        }else if ( msg === "Validé" ){
            return "td-bg-green";
        }else if ( msg === "Refusé" ){
            return "td-bg-red";
        }

    }

    /** FAH TO VALIDATE TABLE */
    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('lastname', {
            header: 'Nom',
            cell: i => <div className="td-bg-grey text-center fw-bold">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('firstname', {
            header: 'Prénom',
            cell: i => <div className={"text-center " + getColor(i) }>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('trigram', {
            header: 'Trigramme',
            cell: i => <div className={"text-center " + getColor(i) }>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('isInterim', {
            header: 'Intérimaire ?',
            cell: i => <div className={"text-center " + getColor(i) }>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('week_tc', {
            header: 'Période à valider',
            cell: i => <div className={"text-center " + getColor(i) }>{i.getValue() + '/' + i.row.original.year_tc}</div>
        }));

        arr.push(columnHelper.accessor('msg', {
            header: '',
            cell: i => <div className={"text-center " + getColor(i) }>{
                <button className="btn btn-outline-secondary" onClick={() => startCheck(i.row.id)}>Commencer la validation ici</button>}</div>
        }));

        
        return arr;
    }

//Set columns
const columns = getColumns();

    //On abs change calculate the hours
    useEffect(() => {
        //if (abs && hours) setLastTabLoad(false);

        if (member && member.isInstaller) {

            var timeout = setTimeout(() => {
                if (weekDays !== undefined) {
                    clearTimeout(timeout);
                    setWeekDays(caculateSchedule(weekDays, abs));
                    /*if (member.isInstaller === "N") {
                        calculateSumarryNi(abs, hours, summaryNonInst, setSumarryNonInst, weekDays);
                        checkConstrainstsNi(err, setErr, weekDays, hours, summaryNonInst, setError, abs);
                    } else {*/
                        calculateSumarry(abs, hours, summaryInst, setSumarryInst, weekDays);
                        checkConstrainsts(err, setErr, weekDays, hours, summaryInst, setError, abs);
                    //}
                }
            }, 100);
        }
    }, [abs, hours]);

    // On summary non installer change check constraints
    /*useEffect(() => {
        if (weekDays !== undefined && hours !== undefined)
            checkConstrainstsNi(err, setErr, weekDays, hours, summaryNonInst, setError, abs);
    }, [summaryNonInst]);*/

    // On summary installer change check constraints
    useEffect(() => {
        if (weekDays !== undefined && hours !== undefined)
            checkConstrainsts(err, setErr, weekDays, hours, summaryInst, setError, abs);
    }, [summaryInst]);

    /**
     * Save 
     * 
     * @returns 
     */
    const save = () => {
        if (error) {
            scrollToTop();
            return;
        }

        setSaving(true);
        
        //member.isInstaller === "N" ? checkBeforSubmitNi(weekDays, hours, summaryNonInst, abs): 
        var err = checkBeforSubmit(weekDays, hours, summaryInst, abs);

        if (err !== "") {
            setError(err);
            scrollToTop();
            setSaving(false);
            return;
        }

        /*if (member.isInstaller === "N") {
            saveNonInstaller();
        } else {*/
            saveInstaller();
        //}

        if (checkingMode) {
            var c = parseInt(checkCursor);

            setTimeout(() => { setMember(); }, 100);
            if (c >= toCheck.length - 1) {
                var arr = [...toCheck];
                toCheck[c].msg = "Validé";
                setToCheck(arr);

                setTab(1);
                setlastTagName();
            } else {
                //Do FAH
                doFahCommon(toCheck[c + 1], toCheck[c + 1].week_tc, toCheck[c + 1].year_tc);

                var arr = [...toCheck];
                toCheck[c].msg = "Validé";
                setToCheck(arr);
                setCheckCursor(c + 1);
            }
        } else {
            setTab(0);
            setlastTagName();
        }

    }

    const saveInstaller = () => {
        fahDao.saveInstallerHours(week, year, hours, abs, summaryInst, true, true, member).then(
            () => {
                setSaving(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setSaving(false);
            }
        );
    }

    /*const saveNonInstaller = () => {
        fahDao.saveNonInstallerHours(week, year, hours, abs, summaryNonInst, true, true, member).then(
            () => {
                setSaving(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setSaving(false);
            }
        );
    }*/

    const getTracking = (y) => {
        setTracking();

        fahDao.getTrackingManager(y).then(
            (response) => {
                setTracking(response);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
            });
    }

    const sortCheckList = (e) => {
        var arr = [...toCheck];

        if (e.target.value === "name") {
            arr.sort(sortByName);
        } else {
            arr.sort(sortByPeriod);
        }

        setToCheck(arr);
    }

    function sortByName(a, b) {
        const nameA = a.lastname.toUpperCase();
        const nameB = b.lastname.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    }

    function sortByPeriod(a, b) {
        if (a.year_tc !== b.year_tc) return a.year_tc - b.year_tc;
        return a.week_tc - b.week_tc;
    }

    {/** Start checking */ }
    const startCheck = (k) => {
        setCheckCursor(k);
        setMember();
        //Do FAH
        doFahCommon(toCheck[k], toCheck[k].week_tc, toCheck[k].year_tc);
    }

    const skip = () => {
        setStatus("Ignoré");
    }

    const reject = () => {
        setDisRejectCause(true);
    }

    const sendReject = (e) => {
        e.preventDefault();
        var cause = e.target.cause.value;

        if (cause.trim() !== "") {
            fahDao.managerReject(member, week, year, "N", cause);
            setDisRejectCause(false);
            setStatus("Refusé");
        }
    }

    function setStatus(msg) {
        setError();
        setTimeout(() => { setMember(); }, 100);

        var c = parseInt(checkCursor);
        if (c >= toCheck.length - 1) {
            var arr = [...toCheck];
            toCheck[c].msg = msg;
            setToCheck(arr);

            setTab(1);
            setlastTagName();
        } else {
            //Do FAH
            doFahCommon(toCheck[c + 1], toCheck[c + 1].week_tc, toCheck[c + 1].year_tc);

            var arr = [...toCheck];
            toCheck[c].msg = msg;
            setToCheck(arr);
            setCheckCursor(c + 1);
        }
    }

    /**
     * Get specific FAH to do or check it
     * @param {*} member 
     * @param {*} week 
     * @param {*} year 
     */
    const doFahCommon = async (member, week, year) => {
        setWeek(week);
        setYear(year);
        setAbs([]);
        setHours([]);

        setLastTabLoad(true);
        setMember(member);
        var arr = getDaysOfWeek(week, year);
        setWeekDays(arr);

        setTab(2);
        setlastTagName("FAH de " + member.trigram);

        var params = {
            focus_id: member.focus_id,
            week: week,
            year: year,
            oldBusiness: oldBusiness,
            oldWeek: oldWeek,
            oldYear: oldYear,
            oldHours: oldHours,
            start: Moment(arr[0].en_date).format("YYYY-MM-DD"),
            end: Moment(arr[6].en_date).format("YYYY-MM-DD"),
            trigram: member.trigram
        }
        
        CommonRequests.getInstaller(params, setWeekDays, setAbs, setHours, setEmptyObj, member,
            setMember, setLastTabLoad, setResponse, setError, props, setSoldeC2);
        
        /*if (member.isInstaller === "O") { } else {
            CommonRequests.getNonInstaller(params, setWeekDays, setAbs, setHours, setEmptyObj, member,
                setMember, setLastTabLoad, setResponse, setError, props);
        }*/


    }

    /**
     * 
     * @param {*} week 
     */
    const doFahForm = (week, k) => {
        setYear(trackYear);
        setWeek(week + 1);
      
        doFahCommon(tracking[k], week+1, trackYear);
        setTab(2);
    }

    /**
     * Get interim to send FAh to agency
     */
    const getInterim = (week, year) => {
        setMyInterim();

        fahDao.getMyInterim(week, year).then(
            (response) => {
                setMyInterim(response);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }

    return <Fragment>
        {error && <ErrorMessage error={error} />}

        {/** TABS */}
        <ul className="nav nav-tabs">
            <li className="nav-item">
                <a className={"nav-link " + (tab === 3 && "active")} href="#" onClick={() => { setTab(3); setCheckingMode(false) }}>
                    {!tracking && <ButtonWaiting />}
                    Suivi des FAH
                </a>
            </li>

            <li className="nav-item">
                <a className={"nav-link " + (tab === 0 && "active")} aria-current="page" href="#" onClick={() => { setTab(0); setCheckingMode(false) }}>
                    {!myStaff && <ButtonWaiting />}
                    Faire une FAH
                </a>
            </li>

            <li className="nav-item">
                <a className={"nav-link " + (tab === 1 && "active")} href="#" onClick={() => { setTab(1); setCheckingMode(true) }}>
                    {!toCheck && <ButtonWaiting />}
                    FAH à valider
                </a>
            </li>

            {
                lastTagName &&
                <li className="nav-item">
                    <a className={"nav-link " + (tab === 2 && "active")} href="#" onClick={() => { setTab(2); setCheckingMode(false) }}>
                        {lastTabLoad && <ButtonWaiting />}
                        {lastTagName}
                    </a>
                </li>
            }

            <li className="nav-item">
                <a className={"nav-link " + (tab === 4 && "active")} href="#" onClick={() => { setTab(4); setCheckingMode(false) }}>
                    {!myInterim && <ButtonWaiting />}
                    Envoyer FAH aux boites d'interim
                </a>
            </li>
        </ul>

        {/** DO FAH */}
        {
            (myStaff && tab === 0) && <Fragment>
                <div className="card col-6 offset-3 text-center" style={{ minWidth: "300px" }}>
                    <h5>Période à traiter</h5>

                    <div className="input-group mb-3">
                        <input type="number" className="form-control text-center fw-bold" onChange={(e) => setWeek(e.target.value)} value={week || ""} />
                        <input type="number" className="form-control text-center fw-bold" onChange={(e) => setYear(e.target.value)} value={year || ""} />
                    </div>

                    <div className="input-group mb-3">
                        <div className="input-group-text">
                            <input className="form-check-input mt-0" name="oldBusiness" type="checkbox" value="O"
                                onChange={(e) => { setOldBusiness(!oldBusiness) }} defaultChecked={oldBusiness} />
                        </div>
                        <input type="text" className="form-control fw-bold" defaultValue="Rapatrier les affaires" readOnly />
                    </div>

                    {
                        oldBusiness && <Fragment>
                            <div className="input-group mb-3">
                                <input type="number" name="oldWeek" className="form-control text-center fw-bold"
                                    defaultValue={curWeek} required={oldBusiness} />
                                <input type="number" name="oldYear" className="form-control text-center fw-bold"
                                    defaultValue={curYear} required={oldBusiness} />
                            </div>

                            <div className="input-group mb-3">
                                <div className="input-group-text">
                                    <input className="form-check-input mt-0" name="oldHours" type="checkbox" value="O"
                                        onChange={(e) => { setOldHours(!oldHours) }} checked={oldHours} />
                                </div>
                                <input type="text" className="form-control fw-bold" value="Rapatrier les heures" readOnly />
                            </div>
                        </Fragment>
                    }

                    <p>Cliquez sur le nom de la personne dont vous voulez faire la FAH</p>
                    <ul className="list-group">
                        {
                            myStaff.map((v, k) => {
                                return <li key={"member" + k} className="list-group-item" onClick={() => doFah(k)}>
                                    {v.lastname.trim()} {v.firstname.trim()} ({v.trigram.trim()}) - {v.isInterim === "N" ? "non " : ""} intérimaire
                                </li>
                            })
                        }
                    </ul>
                </div>
            </Fragment>
        }

        {/** Do or see FAH NON installer */}
        {/*
            (tab === 2 && week && member && member.isInstaller === "N") &&
            <NonInstallerPC weekDays={weekDays} hours={hours} setHours={setHours}
                emptyObj={emptyObj} week={week} year={year} abs={abs} setAbs={setAbs}
                summary={summaryNonInst} setSumarry={setSumarryNonInst} loading={lastTabLoad} err={err}
                error={error} save={save} saving={saving} userInfo={member} ignoreTop="true"
                isManager="true" trigram={member.trigram} />
        */}

        {/** Do or see FAH installer */}
        {
            (tab === 2 && week && member) &&
            <InstallerPC weekDays={weekDays} hours={hours} setHours={setHours}
                emptyObj={emptyObj} week={week} year={year} abs={abs} setAbs={setAbs}
                summary={summaryInst} setSumarry={setSumarryInst} loading={lastTabLoad} err={err}
                error={error} save={save} saving={saving} userInfo={member} ignoreTop="true"
                isManager="true"  soldeC2={soldeC2}/>
        }

        {/** To check */}
        {
            (tab === 1 && toCheck) && 
            <div className="table-responsive" id="manager_track_table" style={{ height: getHeight("manager_track_table") }}>
                <ReactTable columns={columns} data={toCheck}
                        origin="manager_track_fah" classes={"no-padding fixFstCol"} top0={true} />
            </div>
        }

        {/** Tracking */}
        {
            (tab === 3 && tracking) && <Tracking trackYear={trackYear} setTrackYear={setTrackYear}
                tracking={tracking} getTracking={getTracking}
                setYear={setYear} setWeek={setWeek} doFah={doFahForm} />
        }

        {
            (tab === 2 && checkingMode && member) &&
            <div className="text-center mb-3">
                <button className="btn btn-warning me-3" onClick={skip}>Ignorer pour le moment</button>
                <button className="btn btn-danger" onClick={reject}>Refuser</button>
            </div>
        }

        {disRejectCause &&
            <div className="custom-tooltip">
                <button type="button" className="btn-close" onClick={() => setDisRejectCause(false)}></button>
                <h5 className="text-center">Veuillez saisir la raison du refus (obligatoire)</h5>
                <h6 className="text-center">Période: {week}/{year} - {member.lastname}</h6>
                <form onSubmit={sendReject} className="text-center">
                    <textarea className="form-control mb-3" name="cause"></textarea>
                    <button className="btn btn-primary">Envoyer le refus</button>
                </form>
            </div>
        }

        {/** To send to interim */}
        {
            (tab === 4 && myInterim && myStaff) && <SendToInterim myInterim={myInterim} myStaff={myStaff}
                getInterim={getInterim} toSendInterim={toSendInterim} setToSendInterim={setToSendInterim}
                setMyInterim={setMyInterim} />
        }
    </Fragment>

}

export default Manager;