import { createColumnHelper } from "@tanstack/react-table";
import Moment from "moment";
import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import { currentEnDate } from "../../functions/StrFunctions";
import CheckConnDao from "../../services/common/CheckConnDao";
import errorManagement from "../../services/errorManagement";
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const Annoucement = (props) => {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState();

    const contentBlock = htmlToDraft("");
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(contentState))

    const contentBlockEn = htmlToDraft("");
    const contentStateEn = ContentState.createFromBlockArray(contentBlockEn.contentBlocks);
    const [editorStateEn, setEditorStateEn] = useState(() => EditorState.createWithContent(contentStateEn))

    Moment.locale('fr');

    useEffect(() => {
        setLoading(true);
        CheckConnDao.getAnnouncements(true).then(
            (r) => {
                setList(r);
                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }, []);

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();
        arr.push(columnHelper.accessor('id', { header: '#', cell: (i) => <div className="text-center">{i.getValue()}</div> }));
        arr.push(columnHelper.accessor('msg_fr', { header: 'Message français', 
            cell: (i) => <div className="text-center" dangerouslySetInnerHTML={{ __html: i.getValue() }}></div> }));
        arr.push(columnHelper.accessor('msg_en', { header: 'Message anglais', 
            cell: (i) => <div className="text-center" dangerouslySetInnerHTML={{ __html: i.getValue() }}></div> }));
        arr.push(columnHelper.accessor('date', { header: 'Date', cell: (i) => <div className="text-center">{i.getValue()}</div> }));
        arr.push(columnHelper.accessor('x', {
            header: '', cell: (i) => <div className="text-center">
                <button className="btn btn-outline-danger" onClick={() => deleteAnn(i.row.id)}>Supprimer</button>
            </div>
        }));

        return arr;
    }

    //Set columns
    const columns = getColumns();

    const submit = (e) => {
        e.preventDefault();

        var msg_fr = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        var msg_en = draftToHtml(convertToRaw(editorStateEn.getCurrentContent()));
        var o = { msg_fr: msg_fr, msg_en: msg_en }

        setSaving(true);
        CheckConnDao.addAnnouncements(o).then(
            (id) => {
                var arr = [...list];
                
                arr.push({ id: id, msg_en: o.msg_en, msg_fr: o.msg_fr, date: currentEnDate() });
                setList(arr);
                setSaving(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setSaving(false);
            }
        )
    }

    const deleteAnn = (k) => {
        CheckConnDao.deleteAnnouncement(list[k].id);

        var arr = [...list];
        arr = arrayRemoveElt(arr, k);
        setList(arr);

    }

    return <Fragment>

        <h5 className="text-center">Annonces</h5>

        {loading && <WaitingRoundSnippers />}
        {error && <ErrorMessage error={error} errorState={setError} />}

        <div>
            <form onSubmit={submit}>
                <div className="d-flex">
                    <div>
                        <h6>Message français</h6>
                        <Editor editorState={editorState}
                            onEditorStateChange={setEditorState}
                            wrapperClassName="draftEditor-wrapper mb-3" editorClassName="draftEditor-editor" />
                    </div>
                    <div>
                        <h6>Message anglais</h6>
                        <Editor editorState={editorStateEn}
                            onEditorStateChange={setEditorStateEn}
                            wrapperClassName="draftEditor-wrapper mb-3" editorClassName="draftEditor-editor" />
                    </div>
                </div>




                <div className="text-center mb-3">
                    <button className="btn btn-outline-secondary" disabled={saving}>
                        {saving && <ButtonWaiting />}
                        Enregistrer
                    </button>
                </div>

            </form>
        </div>

        {list.length > 0 &&
            <ReactTable columns={columns} data={list} top0={true} origin="annoucements" />
        }

    </Fragment>
}

export default Annoucement;