import { Fragment } from "react";
import { getCurrentWeekYear } from "../../../functions/StrFunctions";
import { arrayRemoveElt } from "../../../functions/ArrayFunctions";
import ErrorMessage from "../../../common/smallComponents.js/ErrorMessage";
import { useState } from "react";
import fahDao from "../../../services/common/fahDaoV2";
import errorManagement from "../../../services/errorManagement";
import { ButtonWaiting } from "../../../functions/PageAnimation";

const Schedules = (p) => {
    var days = ["lundi_", "mardi_", "mercredi_", "jeudi_", "vendredi_", "samedi_", "dimanche_"];
    var o = {
        "trigramme": "",
        "semaine": "",
        "lundi_am": "",
        "lundi_ap": "",
        "mardi_am": "",
        "mardi_ap": "",
        "mercredi_am": "",
        "mercredi_ap": "",
        "jeudi_am": "",
        "jeudi_ap": "",
        "vendredi_am": "",
        "vendredi_ap": "",
        "samedi_am": 0,
        "samedi_ap": 0,
        "dimanche_am": 0,
        "dimanche_ap": 0,
        "annee": getCurrentWeekYear().year
    }
    const [error, setError] = useState();
    const [saving, setSaving] = useState(false);

    const getValue = (row, col, moment) => {
        return p.schedules[row][days[col] + moment];
    }

    const changeValue = (e, row, col, moment) => {
        var arr = [...p.schedules];
        arr[row][days[col] + moment] = e.target.value;
        p.setSchedules(arr);
    }

    const changeField = (e, row, field) => {
        var arr = [...p.schedules];
        arr[row][field] = e.target.value;
        p.setSchedules(arr);
    }

    const addRow = () => {
        var arr = [...p.schedules];
        arr.push({...o});
        p.setSchedules(arr);
    }

    const removeRow = (row) => {
        var arr = [...p.schedules];
        arr = arrayRemoveElt(arr, row);
        p.setSchedules(arr);
    }

    const save = () => {
        setError();
        var flag = false;
        for( let i =0; i<p.schedules.length; i++ ){
            var row = p.schedules[i];
        
            if( row.trigramme.trim() === "" ) flag = true;
            if( isNaN( row.semaine ) ) flag = true;
            if( isNaN( row.annee ) ) flag = true;

            for( let j=0; j<7; j++ ){
                if( isNaN( row[days[j] + "am"] ) ) flag = true;
                if( isNaN( row[days[j] + "ap"] ) ) flag = true;
            }
        }

        if( flag ){
            setError("Tous les champs sont obligatoires");
        }else{
            setSaving(true);
            fahDao.saveSchedules(p.schedules).then(
                () => {
                    setSaving(false);
                }, (error) => {
                    setSaving(false);
                    setError(errorManagement.handleError(p, error));
                }
            );
        }
    }

    return <Fragment>
        <h4 className="text-center">Heures normales</h4>
        <h6 className="text-center">Il s'agit de renseigner les heures normales effectuées par les personnes à temps partiel</h6>

        {error && <ErrorMessage error={error} errorState={setError}/>}

        <table className="table table-bordered">
            <thead>
                <tr className="table-primary fw-bold text-center">
                    <td>Trigramme</td>
                    <td>Période</td>
                    <td>Lundi</td>
                    <td>Mardi</td>
                    <td>Mercredi</td>
                    <td>Jeudi</td>
                    <td>Vendredi</td>
                    <td>Samedi</td>
                    <td>Dimanche</td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                {
                    p.schedules.map((v, k) => {
                        return <tr>
                            <td>
                                <div className="input-group">
                                    <input type="text" className="form-control" value={v.trigramme}
                                        onChange={(e) => changeField(e, k, "trigramme")} />
                                </div>
                            </td>

                            <td>
                                <div className="display-flex">
                                    <div className="form-floating me-1">
                                        <input type="number" className="form-control"
                                            placeholder="xxx" value={v.semaine}
                                            onChange={(e) => changeField(e, k, "semaine")} />
                                        <label for="floatingInput">Semaine</label>
                                    </div>

                                    <div className="form-floating">
                                        <input type="number" className="form-control"
                                            placeholder="xxx" value={v.annee}
                                            onChange={(e) => changeField(e, k, "annee")} />
                                        <label for="floatingInput">Année</label>
                                    </div>
                                </div>
                            </td>

                            {[...Array(7)].map((v2, k2) => {
                                return <td>
                                    <div className="display-flex">
                                        <div className="form-floating me-1">
                                            <input type="number" className="form-control"
                                                placeholder="xxx" value={getValue(k, k2, "am")}
                                                onChange={(e) => changeValue(e, k, k2, "am")} />
                                            <label for="floatingInput">Jour</label>
                                        </div>

                                        <div className="form-floating">
                                            <input type="number" className="form-control"
                                                placeholder="xxx" value={getValue(k, k2, "ap")}
                                                onChange={(e) => changeValue(e, k, k2, "ap")} />
                                            <label for="floatingInput">Nuit</label>
                                        </div>
                                    </div>
                                </td>
                            })}

                            <td>
                                <img src="/common/remove_icon.png" className="cursor-pointer" 
                                    onClick={ () => removeRow(k)}  style={{ width: "30px" }}></img>
                            </td>
                        </tr>
                    })
                }
                <tr>
                    <td colSpan={9}>
                        <button className="btn btn-secondary" onClick={addRow}>Ajouter une ligne</button>
                    </td>
                </tr>
            </tbody>
        </table>

        <div className="text-center">
            <button className="btn btn-outline-success" onClick={save} disabled={saving}>
                {saving && <ButtonWaiting />}
                Sauvegarder
            </button>
        </div>

    </Fragment>
}

export default Schedules;