import { useMemo } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { DefaultColumnFilter, filterMethods } from "../../functions/TableFuntions";
import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import { useEffect } from "react";
import bomOrderDao from "../../services/purchase/bomOrderDao";
import errorManagement from "../../services/errorManagement";
import orderInfo from "../../services/purchase/orderInfo";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import { downloadSingleFile } from "../../services/functions/FilesProcess";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../common/smallComponents.js/ReactTable";

const ScrewsOrder = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [downloading, setDownloading] = useState(false);

    useEffect(() => {
        bomOrderDao.getScrewsToOrder().then(
            (response) => {
                setData(response);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        )
    }, []);

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();
        
        arr.push(columnHelper.accessor('ar', {
            header: 'Article',
            cell: (i) => <div className="text-center">
                <a target="_blank" href={"/article/info/get?article="+i.getValue()}>{i.getValue()}</a></div>
        }));
        
        arr.push(columnHelper.accessor('label', {
            header: 'Désignation',
            cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));
        
        arr.push(columnHelper.accessor('type', {
            header: 'Type',
            cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));
        
        arr.push(columnHelper.accessor('qty_to_order', {
            header: 'Qté en unité de stock',
            cell: (i) => <div className="text-center">
                <input type="number" className="form-control text-center fw-bold"
                    defaultValue={i.getValue()} onBlur={onChangeQty}
                    data-key={i.row.id}></input>
            </div>
        }));
        
        arr.push(columnHelper.accessor('unit', {
            header: 'Conditionnement',
            cell: (i) => <div className="text-center">
                <select className="form-control text-center"
                    onChange={onConditionningChange} data-key={i.row.id}>
                    <option value="" disabled>...</option>
                    { i.row.original.supplierPack.map( (v, k) => {
                            return <option 
                                selected={i.getValue()===v.cdunu && i.row.original.supplier===v.CDFOU} value={k}>
                                    {v.cdunu + ' (' + v.CDFOU + ")"}</option>
                        })
                    }
                </select>

                <span>Prix: {i.row.original.price} €, coeff: {i.row.original.coeff}</span>
                
            </div>
        }));
        
        arr.push(columnHelper.accessor('qty_order', {
            header: 'Qté en unité de commande',
            cell: (i) => <div className="text-center">
                <input type="number" className="form-control text-center fw-bold"
                    defaultValue={i.getValue()} onBlur={onChangeQtyOrdered}
                    data-key={i.row.id}></input>
            </div>
        }));
        
        arr.push(columnHelper.accessor('supplier', {
            header: 'Fournisseur',
            cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));
        
        arr.push(columnHelper.accessor('order', {
            header: 'N° de commande',
            filterFn: 'strWithEmpty',
            cell: (i) => <div className="text-center">
                {i.row.original.supplier !== "" &&
                    <input className="form-control text-center fw-bold"
                        data-supplier={i.row.original.supplier}
                        defaultValue={i.getValue()}
                        onBlur={onOrderChange}></input>
                }
            </div>
        }));
        
       
        return arr;
    }

    //Set columns
    const columns = getColumns();

    const onChangeSupplier = (e) => {
        var val = e.target.value;
        var key = e.target.getAttribute("data-key");

        var arr = [...data];
        
        arr[key].supplier = val.toUpperCase();
        setData(arr);
    }

    const checkSupplier = (e) => {
        var val = e.target.value;
        var key = e.target.getAttribute("data-key");
        var art = data[key].ar;
        setError();

        orderInfo.getSupplierConditionning(val, art).then(
            (response) => {
                var arr = [...data];

                if (response.length === 0) {
                    arr[key].supplier = "";
                    arr[key].supplierPack = new Array();
                    setData(arr);
                    setError("Ce fournisseur n'existe pas ou n'a pas été associé à cet article. Veuillez ré-essayer");
                    scrollToTop();
                } else {
                    arr[key].supplierPack = response;
                    setData(arr);
                }

            },
            (error) => {
                var arr = [...data];
                arr[key].supplier = "";
                setData(arr);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );
    }

    const onConditionningChange = (e) => {
        var val = e.target.value;
        var key = e.target.getAttribute("data-key");

        var arr = [...data];
        var chosen = arr[key].supplierPack[parseInt(val)];

        arr[key].price = chosen.pxunu;
        arr[key].coeff = chosen.CFCOU;
        arr[key].qty_order = parseFloat(arr[key].qty_to_order)*parseFloat(chosen.CFCOU);
        arr[key].supplier = chosen.CDFOU;
        arr[key].unit = chosen.cdunu;

        /*var arr2 = [...arr[key].supplierPack];

        arr2.map(v => {
            if (v.cdunu === val) {
                v.price = v.pxunu;
                v.coeff = v.CFCOU;
                arr[key].qty_order = parseFloat(arr[key].qty_to_order)*parseFloat(v.CFCOU);
            } else {
                v.price = undefined;
                v.coeff = undefined;
            }
        });

        arr[key].supplierPack = arr2;*/
        setData(arr);
    }

    const onOrderChange = (e) => {
        var supplier = e.target.getAttribute("data-supplier").trim();
        var order = e.target.value;

        var arr = [...data];
        arr.map(v => {
            if (v.supplier.trim() === supplier)
                v.order = order;
        });

        setData(arr);
    }

    const onChangeQty = (e) => {
        var val = e.target.value;
        var key = e.target.getAttribute("data-key");

        var arr = [...data];
        arr[key].qty_to_order = val;
        arr[key].qty_order = parseFloat(val)*parseFloat(arr[key].coeff);
        
        setData(arr);
    }

    const onChangeQtyOrdered = (e) => {
        var val = e.target.value;
        var key = e.target.getAttribute("data-key");

        var arr = [...data];
        arr[key].qty_order = val;
        arr[key].qty_to_order = parseFloat(val)/parseFloat(arr[key].coeff);
        
        setData(arr);
    }

    const generateXls = () => {
        var err = "";
        setError();

        var supMiss = "";
        var packMiss = "";
        var orderMiss = "";

        var flag = false;
        data.map( v => {
            if( v.supplier.trim() === "" ) supMiss += "<li>"+v.ar+" n'a pas de fournisseur rempli</li>";

            if(v.price) flag = true;
            

            if( !flag ) packMiss += "<li>"+v.ar+" n'a pas de conditionnement rempli</li>";

            if(!v.order || v.order.trim() === "") orderMiss += "<li>"+v.ar+" n'a pas de n° de commande rempli</li>";
            flag = false;
        });

        err += supMiss.length>0 ? supMiss : "";
        err += packMiss.length>0 ? packMiss : "";
        err += orderMiss.length>0 ? orderMiss : "";

        if( err.length > 0 ){
            setError(err);
            scrollToTop();
        }else{
            setDownloading(true);

            bomOrderDao.generateXlsScrewsToOrder(data).then(
                (response) => {
                    setDownloading(false);
                    downloadSingleFile("temp", response);
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                    setDownloading(false);
                }
            )
        }
    }

    return (
        <Fragment>
            <h4 className="text-center">Achat de visserie</h4>

            {loading && <WaitingRoundSnippers />}
            {error && <ErrorMessage error={error} errorState={setError}/>}
            {msg && <SuccessMessage msg={msg} />}

            
            
            <div className="text-center">
                <button className="btn btn-success" 
                    onClick={generateXls} disabled={downloading}>
                        {downloading && <ButtonWaiting />}
                        Générez le fichier de commandes
                        </button>
                <br></br><br></br>
            </div>

            {data && <ReactTable columns={columns} data={data} top0={true} origin="screw_order" />}

        </Fragment>
    )

}

export default ScrewsOrder;