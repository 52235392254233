import { Fragment, useEffect } from "react";
import { useState } from "react";
import MachineInfo from "../../../../services/machines/MachineInfo";
import errorManagement from "../../../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../../../functions/PageAnimation";
import { arrayRemoveElt, getKey, getKeyString } from "../../../../functions/ArrayFunctions";
import { confirmAlert } from "react-confirm-alert";
const PartTwo = (p) => {
    const [dp] = useState(p.dp);
    const [creating, setCreating] = useState(false);
    const bipiste = p.machineInfo.group === "BA_TECMA_30_40" || p.machineInfo.group === "EC16";
    var emptyBox = {
        type_caisse: null,
        cannelure_caisse: null,
        l_dim_ext: null,
        w_dim_ext: null,
        h_dim_ext: null,
        l_dim_int: null,
        w_dim_int: null,
        h_dim_int: null,
        particularites_caisse: null,
        haut_ext_brut: null,
        haut_ext_maxi: null,
        haut_ext_mini: null,
        rabat_caisse: null,
        no_caisse: null,
        no_piste: null,
        no_machine: null,
        h_ext_ap_red_coiffe_max: null,
        h_ext_ap_red_coiffe_min: null,
        no_machine: p.machineInfo.no_machine
    };

    /**
     * Add box 1
     * @param {*} e 
     */
    const addBox1 = async (e) => {
        var arr = [...p.box1];
        var o = { ...emptyBox };
        o.no_piste = 1;
        o.no_caisse = arr.length + 1;
        arr.push(o);

        var created = await createBox(1, o.no_caisse);
        if (created === "ok") {
            p.setBox1(arr);
        } else {
            p.setError(created);
        }

    }

    /**
     * Add box 2
     * @param {*} e 
     */
    const addBox2 = async (e) => {
        var arr = [...p.box2];
        var o = { ...emptyBox };
        o.no_piste = 2;
        o.no_caisse = arr.length + 1;
        arr.push(o);

        const created = await createBox(2, o.no_caisse);
        if (created === "ok") {
            p.setBox2(arr);
        } else {
            p.setError(created);
        }

    }

    const createBox = async (track, box) => {
        setCreating(true);
        return new Promise(
            (resolve, reject) => {
                MachineInfo.createBox(p.machineInfo.no_machine, track, box).then(
                    () => {
                        setCreating(false);
                        resolve("ok");
                    },
                    (error) => {
                        setCreating(false);
                        reject(errorManagement.handleError(p, error));
                        scrollToTop();
                    }
                )
            }
        ).catch(function (e) { throw new Error(e) });
    }

    /**
     * On field change for select
     * @param {*} e 
     */
    const onBoxChange = (e) => {
        if( p.readOnly ) return;

        var track = e.target.getAttribute("data-track");
        var box = e.target.getAttribute("data-box");
        var field = e.target.getAttribute("data-field");
        var value = e.target.value;

        var arr = [...(parseInt(track) === 1 ? p.box1 : p.box2)];
        var k = getKeyString(arr, "no_caisse", box);

        arr[k][field] = value;

        if (parseInt(track) === 1) {
            p.setBox1(arr);
        } else {
            p.setBox2(arr);
        }

        MachineInfo.updateBox(p.machineInfo.no_machine, field, value, track, box).then(
            () => {
            },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        )
    }

    const onBoxChangeNoSave = (e) => {
        var track = e.target.getAttribute("data-track");
        var box = e.target.getAttribute("data-box");
        var field = e.target.getAttribute("data-field");
        var value = e.target.value;

        var arr = [...(parseInt(track) === 1 ? p.box1 : p.box2)];
        var k = getKeyString(arr, "no_caisse", box);

        arr[k][field] = value;

        if (parseInt(track) === 1) {
            p.setBox1(arr);
        } else {
            p.setBox2(arr);
        }
    }

    /**
     * 
     */
    const removeBox = (e) => {
        var track = e.target.getAttribute("data-track");
        var box = e.target.getAttribute("data-box");

        confirmAlert({
            title: "Suppression de caisse",
            message: "Etes-vous sûr de vouloir supprimer la caisse " + box,
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        var arr = [...(parseInt(track) === 1 ? p.box1 : p.box2)];
                        var k = getKeyString(arr, "no_caisse", box);

                        arr = arrayRemoveElt(arr, k);

                        arr.map((v, k) => v.no_caisse = k + 1);

                        if (parseInt(track) === 1) {
                            p.setBox1(arr);
                        } else {
                            p.setBox2(arr);
                        }

                        MachineInfo.deleteBox(p.machineInfo.no_machine, track, box).then(
                            () => {
                            },
                            (error) => {
                                p.setError(errorManagement.handleError(p, error));
                                scrollToTop();
                            }
                        )

                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    /**
     * 
     */
    const onDimensionChange = (e) => {
        if( p.readOnly ) return;

        var k = e.target.getAttribute("data-key");
        var track = e.target.getAttribute("data-track");
        var field = e.target.getAttribute("data-field");
        var value = e.target.value;

        var arr = [...(parseInt(track) === 1 ? p.box1 : p.box2)];
        arr[k][field] = value;

        var boxType = arr[k].type_caisse;
        //Get in dropdown menu list the cannelure info presents in info1
        var cannelure = parseFloat(p.dp[getKey(p.dp, "label", arr[k].cannelure_caisse)].info1);

        if (field !== "haut_ext_brut" && boxType !== null && cannelure && value !== "") {
            //Get field to calculate
            var field2 = field.substring(0, 6) + (field.endsWith("_ext") ? "int" : "ext");

            //Calculate
            if (boxType === "FEFCO 0200") {
                arr[k][field2] = applyFormula(value, field, cannelure, 2);
            } else if (boxType === "FEFCO 0701") {
                var multiplication = field.startsWith("h_") ? 1 : 2;
                arr[k][field2] = applyFormula(value, field, cannelure, multiplication);
            } else if (boxType === "FEFCO 0453" || boxType === "Super-Eco int." || boxType === "Super-Eco ext." || boxType === "I-Pack") {
                var multiplication = field.startsWith("l_") ? 4 : field.startsWith("w_") ? 2 : 1;
                arr[k][field2] = applyFormula(value, field, cannelure, multiplication);
            }
        }

        //Special case
        if (field === "h_dim_ext" || field === "w_dim_ext" || field === "haut_ext_brut") {
            if (p.machineInfo.group.startsWith("IN")) {
                var h_ext = arr[k].h_dim_ext;
                var multiplication = cannelure < 3 ? 1 : 10;
                var can = cannelure < 3 ? 30 : cannelure;

                if (h_ext !== null)
                    arr[k].h_ext_ap_red_coiffe_max = applyFormula(h_ext, "data_ext", can, multiplication);

            } else if (p.machineInfo.group.startsWith("EC")) {
                var h_ext = arr[k].haut_ext_brut;
                var w_ext = arr[k].w_dim_ext;

                if (h_ext !== null && w_ext !== null) {
                    arr[k].haut_ext_mini = HighMaxEcubeFormula(h_ext, w_ext, "mini");
                    arr[k].haut_ext_maxi = HighMaxEcubeFormula(h_ext, w_ext, "maxi");
                }
            }
        }

        if (parseInt(track) === 1) {
            p.setBox1(arr);
        } else {
            p.setBox2(arr);
        }

    }

    //Save dimensions on blur of complete box
    const saveDimensions = (e) => {
        if( p.readOnly ) return;
        
        var k = e.target.getAttribute("data-key");
        var track = e.target.getAttribute("data-track");

        var arr = [...(parseInt(track) === 1 ? p.box1 : p.box2)];
        MachineInfo.updateBoxDimensions(arr[k]).then(
            () => {
            },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        )
    }

    function applyFormula(value, field, cannelure, multiplication) {
        if (field.endsWith("_ext")) {
            return parseFloat(value) - (multiplication * cannelure);
        } else {
            return parseFloat(value) + (multiplication * cannelure);
        }
    }

    function HighMaxEcubeFormula(hext, wext, mini_maxi) {
        if (hext == "" || wext == "") return 0;

        if (mini_maxi == "maxi") return parseFloat(hext) - ((parseFloat(wext) / 2) + 15);
        if (mini_maxi == "mini") return parseFloat(hext) - (parseFloat(wext - 10));
    }

    return <div className="table-responsive">

        {/** BOX 1 */}
        <div className="table-responsive">
            <table className="table businessCardStyle">
                <thead>
                    {
                        bipiste &&
                        <tr className="text-center">
                            <th scope="col" colSpan={3}>Piste 1</th>
                        </tr>
                    }
                    <tr className="bg-dark bg-gradient text-white text-center rounded">
                        {
                            p.box1.map((v, k) => {
                                return <th scope="col" key={"box1H" + k} style={{ width: "33.3%" }}>Caisse {v.no_caisse}</th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {
                            p.box1.map((v, k) => {
                                return <td scope="col" key={"box1D" + k} className="border-end">
                                    <div style={{ display: "flex" }}>
                                        { /** PISTE 1 - TYPE */}
                                        <div className="form-floating mb-3">
                                            <select className="form-select" id="Fabricant" data-field="type_caisse" disabled={p.readOnly}
                                                data-box={v.no_caisse} data-track="1" value={v.type_caisse} onChange={onBoxChange}>
                                                <option value=""></option>
                                                {
                                                    p.dp && p.dp.map((v, k2) => {
                                                        if (v.field === "type_caisse")
                                                            return <option key={"box1Type" + k2} value={v.label}>{v.label}</option>
                                                    })
                                                }
                                            </select>
                                            <label for="Fabricant">Type</label>
                                        </div>

                                        { /** PISTE 1 - CANNELURE */}
                                        <div className="form-floating mb-3" style={{ marginLeft: "10px" }}>
                                            <select className="form-select" id="Fabricant" data-field="cannelure_caisse" disabled={p.readOnly}
                                                data-box={v.no_caisse} data-track="1" value={v.cannelure_caisse} onChange={onBoxChange}>
                                                <option value=""></option>
                                                {
                                                    p.dp && p.dp.map((v, k2) => {
                                                        if (v.field === "cannelure")
                                                            return <option key={"box1Type" + k2} value={v.label}>{v.label}</option>
                                                    })
                                                }
                                            </select>
                                            <label for="Fabricant">Cannelure</label>
                                        </div>

                                    </div>

                                    { /** PISTE 1 - DIMENSIONS L */}
                                    <div style={{ display: "flex" }}>
                                        <div className="form-floating mb-3">
                                            <input type="number" className="form-control" value={v.l_dim_ext}
                                                placeholder="xxx" data-field="l_dim_ext" onBlur={saveDimensions}
                                                data-box={v.no_caisse} data-track="1" data-key={k}
                                                onChange={onDimensionChange} readOnly={p.readOnly} />
                                            <label>L (ext)</label>
                                        </div>


                                        <div className="form-floating mb-3">
                                            <input type="number" className="form-control" value={v.l_dim_int}
                                                placeholder="xxx" data-field="l_dim_int" data-key={k}
                                                data-box={v.no_caisse} data-track="1" onBlur={saveDimensions}
                                                onChange={onDimensionChange} readOnly={p.readOnly} />
                                            <label>L (int)</label>
                                        </div>

                                    </div>

                                    { /** PISTE 1 - DIMENSIONS W */}
                                    <div style={{ display: "flex" }}>
                                        <div className="form-floating mb-3">
                                            <input type="number" className="form-control" value={v.w_dim_ext}
                                                placeholder="xxx" data-field="w_dim_ext" data-key={k}
                                                data-box={v.no_caisse} data-track="1" onBlur={saveDimensions}
                                                onChange={onDimensionChange} readOnly={p.readOnly} />
                                            <label>W (ext)</label>
                                        </div>


                                        <div className="form-floating mb-3">
                                            <input type="number" className="form-control" value={v.w_dim_int}
                                                placeholder="xxx" data-field="w_dim_int" data-key={k}
                                                data-box={v.no_caisse} data-track="1" onBlur={saveDimensions}
                                                onChange={onDimensionChange} readOnly={p.readOnly} />
                                            <label>W (int)</label>
                                        </div>

                                    </div>

                                    { /** PISTE 1 - DIMENSIONS H */}
                                    {
                                        (p.machineInfo.group !== "EC08" && p.machineInfo.group !== "EC16") &&
                                        <Fragment>
                                            <div style={{ display: "flex" }}>
                                                <div className="form-floating mb-3">
                                                    <input type="number" className="form-control" value={v.h_dim_ext}
                                                        placeholder="xxx" data-field="h_dim_ext" data-key={k}
                                                        data-box={v.no_caisse} data-track="1" onBlur={saveDimensions}
                                                        onChange={onDimensionChange} readOnly={p.readOnly} />
                                                    <label>H (ext)</label>
                                                </div>


                                                <div className="form-floating mb-3">
                                                    <input type="number" className="form-control" value={v.h_dim_int}
                                                        placeholder="xxx" data-field="h_dim_int" data-key={k}
                                                        data-box={v.no_caisse} data-track="1" onBlur={saveDimensions}
                                                        onChange={onDimensionChange} readOnly={p.readOnly} />
                                                    <label>H (int)</label>
                                                </div>

                                            </div>
                                        </Fragment>
                                    }

                                    {/** PISTE 1 - DIMENSIONS H (EC08 & EC16) */}
                                    {
                                        (p.machineInfo.group === "EC08" || p.machineInfo.group === "EC16") &&
                                        <Fragment>
                                            <div style={{ display: "flex" }}>
                                                <div className="form-floating mb-3">
                                                    <input type="number" className="form-control" value={v.haut_ext_brut}
                                                        placeholder="xxx" data-field="haut_ext_brut" data-key={k}
                                                        data-box={v.no_caisse} data-track="1" onBlur={saveDimensions}
                                                        onChange={onDimensionChange} readOnly={p.readOnly} />
                                                    <label>H ext (brut)</label>
                                                </div>

                                                <div className="form-floating mb-3" style={{ marginLeft: "10px" }}>
                                                    <input type="number" className="form-control" value={v.haut_ext_maxi}
                                                        placeholder="xxx" data-field="haut_ext_maxi" data-key={k}
                                                        data-box={v.no_caisse} data-track="1" onBlur={saveDimensions}
                                                        onChange={onDimensionChange} readOnly={p.readOnly} />
                                                    <label>H ext (maxi)</label>
                                                </div>

                                                <div className="form-floating mb-3" style={{ marginLeft: "10px" }}>
                                                    <input type="number" className="form-control" value={v.haut_ext_mini}
                                                        placeholder="xxx" data-field="haut_ext_mini" data-key={k}
                                                        data-box={v.no_caisse} data-track="1" onBlur={saveDimensions}
                                                        onChange={onDimensionChange} readOnly={p.readOnly} />
                                                    <label>H ext (mini)</label>
                                                </div>

                                            </div>

                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control" value={v.rabat_caisse}
                                                    placeholder="xxx" data-field="rabat_caisse" data-key={k}
                                                    data-box={v.no_caisse} data-track="1"
                                                    onBlur={onBoxChange} onChange={onBoxChangeNoSave} readOnly={p.readOnly} />
                                                <label>Rabat plié en dernier</label>
                                            </div>
                                        </Fragment>
                                    }

                                    {/** PISTE 1 - DIMENSIONS H (sans coiffe) */}
                                    {
                                        (p.machineInfo.group === "IK" || p.machineInfo.group === "IN") &&
                                        <Fragment>
                                            <div style={{ display: "flex" }}>
                                                <div className="form-floating mb-3">
                                                    <input type="number" className="form-control" value={v.h_ext_ap_red_coiffe_min}
                                                        placeholder="xxx" data-field="h_ext_ap_red_coiffe_min" data-key={k}
                                                        data-box={v.no_caisse} data-track="1" onBlur={saveDimensions}
                                                        onChange={onDimensionChange} readOnly={p.readOnly} />
                                                    <label>H sans coiffe (mini)</label>
                                                </div>

                                                <div className="form-floating mb-3" style={{ marginLeft: "10px" }}>
                                                    <input type="number" className="form-control" value={v.h_ext_ap_red_coiffe_max}
                                                        placeholder="xxx" data-field="h_ext_ap_red_coiffe_max" data-key={k}
                                                        data-box={v.no_caisse} data-track="1" onBlur={saveDimensions}
                                                        onChange={onDimensionChange} readOnly={p.readOnly} />
                                                    <label>H sans coiffe (maxi)</label>
                                                </div>
                                            </div>
                                        </Fragment>
                                    }

                                    {/** PARTICULARITES */}
                                    <div className="form-floating">
                                        <textarea className="form-control" placeholder="xxx" value={v.particularites_caisse}
                                            data-field="particularites_caisse" data-key={k}
                                            data-box={v.no_caisse} data-track="1"
                                            onBlur={onBoxChange} onChange={onBoxChangeNoSave} readOnly={p.readOnly}></textarea>
                                        <label for="floatingTextarea">Particularités</label>
                                    </div>

                                    {!p.readOnly && <Fragment>
                                        <br></br>
                                        <div className="text-center">
                                            <button className="btn btn-outline-danger"
                                                data-box={v.no_caisse} data-track="1" onClick={removeBox}>Supprimer la caisse</button>
                                        </div>
                                    </Fragment>}

                                </td>
                            })
                        }

                        {
                            p.box1.length < 3 &&
                            <td className="text-center">
                                {!p.readOnly &&
                                    <button className="btn btn-outline-secondary" onClick={addBox1} disabled={creating}>
                                        {creating && <ButtonWaiting />}
                                        Ajouter une caisse
                                    </button>
                                }
                            </td>
                        }
                    </tr>

                </tbody>
            </table>

        </div>

        {/********************************************************
         * ************************  BOX 2 ***********************
         * *******************************************************/}
        {
            bipiste && <Fragment>
                <div className="table-responsive">
                    <table className="table businessCardStyle">
                        <thead>
                            <tr className="text-center">
                                <th scope="col" colSpan={3}>Piste 2</th>
                            </tr>
                            <tr className="bg-dark bg-gradient text-white text-center rounded">
                                {
                                    p.box2.map((v, k) => {
                                        return <th scope="col" key={"box12" + k} style={{ width: "33.3%" }}>Caisse {v.no_caisse}</th>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {
                                    p.box2.map((v, k) => {
                                        return <td scope="col" key={"box2D" + k} className="border-end">
                                            <div style={{ display: "flex" }}>
                                                { /** PISTE 2 - TYPE */}
                                                <div className="form-floating mb-3">
                                                    <select className="form-select" id="Fabricant" data-field="type_caisse" disabled={p.readOnly}
                                                        data-box={v.no_caisse} data-track="2" value={v.type_caisse} onChange={onBoxChange}>
                                                        <option value=""></option>
                                                        {
                                                            p.dp && p.dp.map((v, k2) => {
                                                                if (v.field === "type_caisse")
                                                                    return <option key={"box1Type" + k2} value={v.label}>{v.label}</option>
                                                            })
                                                        }
                                                    </select>
                                                    <label for="Fabricant">Type</label>
                                                </div>

                                                { /** PISTE 2 - CANNELURE */}
                                                <div className="form-floating mb-3" style={{ marginLeft: "10px" }}>
                                                    <select className="form-select" id="Fabricant" data-field="cannelure_caisse" disabled={p.readOnly}
                                                        data-box={v.no_caisse} data-track="2" value={v.cannelure_caisse} onChange={onBoxChange}>
                                                        <option value=""></option>
                                                        {
                                                            p.dp && p.dp.map((v, k2) => {
                                                                if (v.field === "cannelure")
                                                                    return <option key={"box1Type" + k2} value={v.label}>{v.label}</option>
                                                            })
                                                        }
                                                    </select>
                                                    <label for="Fabricant">Cannelure</label>
                                                </div>

                                            </div>

                                            { /** PISTE 2 - DIMENSIONS L */}
                                            <div style={{ display: "flex" }}>
                                                <div className="form-floating mb-3">
                                                    <input type="number" className="form-control" value={v.l_dim_ext}
                                                        placeholder="xxx" data-field="l_dim_ext" onBlur={saveDimensions}
                                                        data-box={v.no_caisse} data-track="2" data-key={k}
                                                        onChange={onDimensionChange} readOnly={p.readOnly} />
                                                    <label>L (ext)</label>
                                                </div>


                                                <div className="form-floating mb-3">
                                                    <input type="number" className="form-control" value={v.l_dim_int}
                                                        placeholder="xxx" data-field="l_dim_int" data-key={k}
                                                        data-box={v.no_caisse} data-track="2" onBlur={saveDimensions}
                                                        onChange={onDimensionChange} readOnly={p.readOnly} />
                                                    <label>L (int)</label>
                                                </div>

                                            </div>

                                            { /** PISTE 2 - DIMENSIONS W */}
                                            <div style={{ display: "flex" }}>
                                                <div className="form-floating mb-3">
                                                    <input type="number" className="form-control" value={v.w_dim_ext}
                                                        placeholder="xxx" data-field="w_dim_ext" data-key={k}
                                                        data-box={v.no_caisse} data-track="2" onBlur={saveDimensions}
                                                        onChange={onDimensionChange} readOnly={p.readOnly} />
                                                    <label>W (ext)</label>
                                                </div>


                                                <div className="form-floating mb-3">
                                                    <input type="number" className="form-control" value={v.w_dim_int}
                                                        placeholder="xxx" data-field="w_dim_int" data-key={k}
                                                        data-box={v.no_caisse} data-track="2" onBlur={saveDimensions}
                                                        onChange={onDimensionChange} readOnly={p.readOnly} />
                                                    <label>W (int)</label>
                                                </div>

                                            </div>

                                            { /** PISTE 2 - DIMENSIONS H */}
                                            {
                                                (p.machineInfo.group !== "EC08" && p.machineInfo.group !== "EC16") &&
                                                <Fragment>
                                                    <div style={{ display: "flex" }}>
                                                        <div className="form-floating mb-3">
                                                            <input type="number" className="form-control" value={v.h_dim_ext}
                                                                placeholder="xxx" data-field="h_dim_ext" data-key={k}
                                                                data-box={v.no_caisse} data-track="2" onBlur={saveDimensions}
                                                                onChange={onDimensionChange} readOnly={p.readOnly} />
                                                            <label>H (ext)</label>
                                                        </div>


                                                        <div className="form-floating mb-3">
                                                            <input type="number" className="form-control" value={v.h_dim_int}
                                                                placeholder="xxx" data-field="h_dim_int" data-key={k}
                                                                data-box={v.no_caisse} data-track="2" onBlur={saveDimensions}
                                                                onChange={onDimensionChange} readOnly={p.readOnly} />
                                                            <label>H (int)</label>
                                                        </div>

                                                    </div>
                                                </Fragment>
                                            }

                                            {/** PISTE 2 - DIMENSIONS H (EC08 & EC16) */}
                                            {
                                                (p.machineInfo.group === "EC08" || p.machineInfo.group === "EC16") &&
                                                <Fragment>
                                                    <div style={{ display: "flex" }}>
                                                        <div className="form-floating mb-3">
                                                            <input type="number" className="form-control" value={v.haut_ext_brut}
                                                                placeholder="xxx" data-field="haut_ext_brut" data-key={k}
                                                                data-box={v.no_caisse} data-track="2" onBlur={saveDimensions}
                                                                onChange={onDimensionChange} readOnly={p.readOnly} />
                                                            <label>H ext (brut)</label>
                                                        </div>

                                                        <div className="form-floating mb-3" style={{ marginLeft: "10px" }}>
                                                            <input type="number" className="form-control" value={v.haut_ext_maxi}
                                                                placeholder="xxx" data-field="haut_ext_maxi" data-key={k}
                                                                data-box={v.no_caisse} data-track="2" onBlur={saveDimensions}
                                                                onChange={onDimensionChange} readOnly={p.readOnly} />
                                                            <label>H ext (maxi)</label>
                                                        </div>

                                                        <div className="form-floating mb-3" style={{ marginLeft: "10px" }}>
                                                            <input type="number" className="form-control" value={v.haut_ext_mini}
                                                                placeholder="xxx" data-field="haut_ext_mini" data-key={k}
                                                                data-box={v.no_caisse} data-track="2" onBlur={saveDimensions}
                                                                onChange={onDimensionChange} readOnly={p.readOnly} />
                                                            <label>H ext (mini)</label>
                                                        </div>

                                                    </div>

                                                    <div className="form-floating mb-3">
                                                        <input type="text" className="form-control" value={v.rabat_caisse}
                                                            placeholder="xxx" data-field="rabat_caisse" data-key={k}
                                                            data-box={v.no_caisse} data-track="2"
                                                            onBlur={onBoxChange} onChange={onBoxChangeNoSave} readOnly={p.readOnly} />
                                                        <label>Rabat plié en dernier</label>
                                                    </div>
                                                </Fragment>
                                            }

                                            {/** PISTE 2 - DIMENSIONS H (sans coiffe) */}
                                            {
                                                (p.machineInfo.group === "IK" || p.machineInfo.group === "IN") &&
                                                <Fragment>
                                                    <div style={{ display: "flex" }}>
                                                        <div className="form-floating mb-3">
                                                            <input type="number" className="form-control" value={v.h_ext_ap_red_coiffe_min}
                                                                placeholder="xxx" data-field="h_ext_ap_red_coiffe_min" data-key={k}
                                                                data-box={v.no_caisse} data-track="2" onBlur={saveDimensions}
                                                                onChange={onDimensionChange} readOnly={p.readOnly} />
                                                            <label>H sans coiffe (mini)</label>
                                                        </div>

                                                        <div className="form-floating mb-3" style={{ marginLeft: "10px" }}>
                                                            <input type="number" className="form-control" value={v.h_ext_ap_red_coiffe_max}
                                                                placeholder="xxx" data-field="h_ext_ap_red_coiffe_max" data-key={k}
                                                                data-box={v.no_caisse} data-track="2" onBlur={saveDimensions}
                                                                onChange={onDimensionChange} readOnly={p.readOnly} />
                                                            <label>H sans coiffe (maxi)</label>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            }

                                            {/** PARTICULARITES */}
                                            <div className="form-floating">
                                                <textarea className="form-control" placeholder="xxx" value={v.particularites_caisse}
                                                    data-field="particularites_caisse" data-key={k}
                                                    data-box={v.no_caisse} data-track="2"
                                                    onBlur={onBoxChange} onChange={onBoxChangeNoSave} readOnly={p.readOnly}></textarea>
                                                <label for="floatingTextarea">Particularités</label>
                                            </div>
                                            {!p.readOnly && <Fragment>
                                                <br></br>
                                                <div className="text-center">
                                                    <button className="btn btn-outline-danger"
                                                        data-box={v.no_caisse} data-track="2" onClick={removeBox}>Supprimer la caisse</button>
                                                </div>
                                            </Fragment>}
                                        </td>
                                    })
                                }

                                {
                                    p.box2.length < 3 &&
                                    <td className="text-center">
                                        {!p.readOnly &&
                                            <button className="btn btn-outline-secondary" onClick={addBox2} disabled={creating}>
                                                {creating && <ButtonWaiting />}
                                                Ajouter une caisse
                                            </button>
                                        }
                                    </td>
                                }
                            </tr>

                        </tbody>
                    </table>

                </div>
            </Fragment>
        }
    </div>
}

export default PartTwo;