import { Fragment, useState } from "react";
import LabelsChoice from "../../../common/smallComponents.js/LabelsChoice";
import WaitingRoundSnippers from "../../../common/smallComponents.js/WaitingRoundSnippers";
import { scrollToTop } from "../../../functions/PageAnimation";
import errorManagement from "../../../services/errorManagement";
import { downloadTempFile } from "../../../services/functions/FilesProcess";
import Movement from "../../../services/storehouse/Movement";

export const MachineBomOutMenu = (p) => {
    const [downloadingLab, setdownloadingLab] = useState(false);
    const [openLabelsChoice, setopenLabelsChoice] = useState(false);

    {/** */ }
    const exportLabels = (start) => {
        var toPrint = [];

        if (p.subsetToPrint.length === 0 && p.artToPrint.length === 0) {
            if (p.filter === "all" && p.workstation === "all") {
                toPrint = [...p.list];
            }else{
                p.list.map(v => { if ( canPrint(v) ) toPrint.push(v); });
            }
        } else {
            //Checked se or articles
            p.list.map(v => {
                if ((p.subsetToPrint.includes(v.se) || p.artToPrint.includes(v.need_num)) && canPrint(v))
                    toPrint.push(v);
            })
        }

        if (toPrint.length === 0) {
            p.setError("Aucune étiquette de disponible à sortir");
            scrollToTop();
            return;
        }

        setdownloadingLab(true);
        Movement.downloadMachineBomLabels(toPrint, start).then(
            (response) => {
                downloadTempFile(response)
                setdownloadingLab(false);
            },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
                setdownloadingLab(false);
            }
        );
    }

    function canPrint(v) {
        if (p.filter === "available" && (v.qte_restante === 0 || v.qte_affectee === 0) ) {
            return false;
        } else if (p.filter === "completeAvailable" && (v.qte_restante === 0 || v.qte_affectee !== v.qte_restante)) {
            return false;
        } else if( p.workstation !== 'all' && p.workstation !== v.workstation ){
            return false;
        }

        return true;
    }

    {/** */ }
    function getPlural() { return p.artToPrint.length > 1 ? "s" : ""; }

    {/** */ }
    const getConsumables = () => {
        var machine = p.list[0].machine;
        var idx = machine.indexOf("/");
        if (idx !== -1) machine = machine.substring(idx + 1, machine.length).trim();

        setdownloadingLab(true);
        Movement.getConsumablesBom(machine, p.list[0].business).then(
            (response) => {
                var arr = [...p.list];
                arr = arr.concat(response)
                p.setList(arr);
                p.setConsumable(true);
                setdownloadingLab(false);
            },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
                setdownloadingLab(false);
            }
        );
    }

    return <Fragment>

        {downloadingLab && <WaitingRoundSnippers />}

        {
            !downloadingLab && <Fragment>
                <div className="mb-3">
                    <a href="#" className="mb-3" onClick={() => window.print()}>Imprimer le listing</a>
                </div>

                <div>
                    <a href="#" onClick={() => setopenLabelsChoice(!openLabelsChoice)}>Imprimer les étiquettes</a>
                </div>

                {(p.subsetToPrint.length > 0 || p.artToPrint.length > 0) &&
                    <div className="fst-italic">Seuls les éléments cochés seront étiquettés</div>}

                {openLabelsChoice &&
                    <LabelsChoice onChooseTag={exportLabels} printingLabel={downloadingLab} />}

                {p.artToPrint.length > 0 &&
                    <a className="mt-3" href="#">Clôturer le{getPlural()} besoin{getPlural()} de{getPlural()} {p.artToPrint.length} article{getPlural()}</a>}

                <h5 className="text-center mt-3">Filtres</h5>
                <h6 className="text-center">Par disponibilté</h6>
                <div className="mb-3">
                    <div className="form-check mt-3">
                        <input className="form-check-input" type="radio" onChange={() => p.setFilter("all")}
                            checked={p.filter === "all"} />
                        <label className="form-check-label">
                            Tout afficher
                        </label>
                    </div>

                    <div className="form-check">
                        <input className="form-check-input" type="radio" onChange={() => p.setFilter("completeAvailable")}
                            checked={p.filter === "completeAvailable"} />
                        <label className="form-check-label">
                            Uniquement les dispo. complètes
                        </label>
                    </div>
                </div>

                <h6 className="text-center">Par poste</h6>
                <div className="mb-3">
                    <div className="form-check mt-3">
                        <input className="form-check-input" type="radio"
                            onChange={() => p.setWorkstation("all")} checked={p.workstation === "all"} />
                        <label className="form-check-label">
                            Tout afficher
                        </label>
                    </div>

                    <div className="form-check">
                        <input className="form-check-input" type="radio"
                            onChange={() => p.setWorkstation("M")} checked={p.workstation === "M"} />
                        <label className="form-check-label">
                            Montage sur table
                        </label>
                    </div>

                    <div className="form-check">
                        <input className="form-check-input" type="radio"
                            onChange={() => p.setWorkstation("I")} checked={p.workstation === "I"} />
                        <label className="form-check-label">
                            Intégration
                        </label>
                    </div>

                    <div className="form-check">
                        <input className="form-check-input" type="radio"
                            onChange={() => p.setWorkstation("C")} checked={p.workstation === "C"} />
                        <label className="form-check-label">
                            Cartérisation
                        </label>
                    </div>

                    <div className="form-check">
                        <input className="form-check-input" type="radio" onChange={() => p.setWorkstation("E")}
                            checked={p.workstation === "E"} />
                        <label className="form-check-label">
                            Electricité
                        </label>
                    </div>
                </div>

                {/* <a href="#" className="mb-3" onClick={getConsumables}>Ajouter les consommables</a>


                <a href="#">Solder tous les besoins</a>*/}
            </Fragment>
        }


    </Fragment>
}