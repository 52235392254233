import { Fragment, useEffect, useState } from "react";
import { currentFrDate, forceString } from "../../../../functions/StrFunctions";

const MachPacking = (p) => {
    const [nbPalet, setNbPalet] = useState(0);
    const [nbCartons, setNbCartons] = useState(0);
    const [nbMaterials, setNbMaterials] = useState(0);
    const [totWeight, settotWeight] = useState(0);

    useEffect(() => {
        try {
            var pal = 0, car = 0, mat = 0, wei = 0;

            var mach = p.picking.machines;
            for (let i = 0; i < mach.length; i++) {
                var pList = mach[i].picking;
                for (let j = 0; j < pList.length; j++) {
                    if (pList[j].display) {
                        if (pList[j].type === "Palette") { pal++ }
                        else if (pList[j].type === "Complet") { mat++ }

                        wei += parseFloat(pList[j].weight);
                    }
                }
            }

            setNbPalet(pal);
            setNbMaterials(mat);
            settotWeight(wei);
        } catch (error) {
            console.error("error")
        }

    }, [])

    function getIndentation(id){
        var nb = forceString( id ).split(".").length - 1;
        return {marginLeft: (nb*10) + "px"}
    }

    return <div>

        <table className="table">
            <thead>
                <tr>
                    <td className="border" style={{ width: "33.33%" }}>
                        <img src="/common/official logo.png" className="ms-2" />
                        <div>
                            Ship From<br></br>
                            {p.picking.shipment.cn_sender}<br></br>
                            {p.picking.shipment.address_sender}<br></br>
                            {p.picking.shipment.comp_add_sender && <Fragment>
                                {p.picking.shipment.comp_add_sender}<br></br>
                            </Fragment>}
                            {p.picking.shipment.cp_sender} - {p.picking.shipment.city_sender} - {p.picking.shipment.country_sender}
                        </div>
                    </td>
                    <td className="border text-center fw-bold" style={{ width: "33.33%" }}>
                        <h4>{p.tab === "packing" ? "Packing list" : "Delivery note"}</h4>
                    </td>
                    <td className="border" style={{ width: "33.33%" }}>
                        <div>Shipping date : {currentFrDate()}</div>
                        <div>Shipping n° : EXP{p.picking.shipment.id}</div>
                        <div>{p.tab === "packing" ? "Packing list" : "Delivery note"} n° : {p.picking.picking_id}</div>
                        <div>PO n° : {forceString(p.picking.machines[0].business.cde_client).replace("PO", "")}</div>
                    </td>
                </tr>
            </thead>
        </table>

        <table className="table table-bordered">
            <thead>
                <tr className="fw-bold">
                    <td>Sold to :</td>
                    <td>Ship to :</td>
                    <td>Reference :</td>
                    <td>Remarks :</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        {p.picking.businessFocus.RSDFE}<br></br>
                        {p.picking.businessFocus.ARDFE}<br></br>
                        {p.picking.businessFocus.ARCFE && <Fragment>
                            {p.picking.businessFocus.ARCFE}<br></br>
                        </Fragment>}
                        {p.picking.businessFocus.CPOFE} - {p.picking.businessFocus.AVIFE} - {p.picking.businessFocus.CPAFE}
                    </td>
                    <td>
                        {p.picking.shipment.cn_recip}<br></br>
                        {p.picking.shipment.address_recip}<br></br>
                        {p.picking.shipment.comp_add_recip && <Fragment>
                            {p.picking.shipment.comp_add_recip}<br></br>
                        </Fragment>}
                        {p.picking.shipment.cp_recip} - {p.picking.shipment.city_recip} - {p.picking.shipment.country_recip}
                        <br></br>
                        <br></br>
                        {p.picking.shipment.ln_recip} {p.picking.shipment.fn_recip}<br></br>
                        {p.picking.shipment.tel_recip}
                    </td>
                    <td>
                        Project: {p.picking.businessFocus.NOCDE}
                    </td>
                    <td>
                        {p.picking.picking_head.comments}
                    </td>
                </tr>
            </tbody>
        </table>

        <table className="table table-bordered">
            <thead>
                <tr className="fw-bold table-secondary">
                    <td>Packing n°</td>
                    <td>Package type</td>
                    <td>Qty</td>
                    <td>Material code</td>
                    <td>Description</td>
                    <td>Gross weight</td>
                    <td>Dim.</td>
                </tr>
            </thead>
            <tbody>
                {p.picking.machines.map((v, k) => {
                    return <Fragment> {v.picking.length > 0 && <tr>
                        <td colSpan={7}>Machine {v.machine.no_machine} ({v.machine.typ_config})</td>
                    </tr>}

                        {v.picking.map((v2, k2) => {
                            return <tr key={"p"+k2 + "_" + k}>
                                <td>
                                    <div style={getIndentation(v2.id)}>#{v2.id}</div>
                                </td>
                                <td>{v2.type}</td>
                                <td>{v2.qty}</td>
                                <td>{v2.ar}</td>
                                <td>{v2.label}</td>
                                <td>{v2.weight}</td>
                                <td>{v2.dim1 + "x" + v2.dim2 + "x" + v2.dim3}</td>
                            </tr>
                        })}
                    </Fragment>

                })}

            </tbody>
        </table>

        <table className="table table-bordered">
            <thead>
                <tr className="fw-bold table-secondary">
                    <td>Palet</td>
                    <td>Cartons</td>
                    <td>Materials</td>
                    <td>N.Weight (Kgs.)</td>
                    {p.tab !== "packing" && <Fragment>
                        <td>Shipper Sign.</td>
                        <td>Carrier Sign</td>
                        <td>Receiver Sign</td>
                    </Fragment>}
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{nbPalet}</td>
                    <td>{nbCartons}</td>
                    <td>{nbMaterials}</td>
                    <td>{totWeight}</td>
                    {p.tab !== "packing" && <Fragment>
                        <td></td>
                        <td></td>
                        <td></td>
                    </Fragment>}
                </tr>
            </tbody>
        </table>

    </div>

}

export default MachPacking;