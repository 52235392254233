import { createColumnHelper } from "@tanstack/react-table";
import { Fragment } from "react";
import ReactTable from "../../../common/smallComponents.js/ReactTable";
import { getDate } from "../../../functions/StrFunctions";

const NewProductsList = (p) => {
    const user = JSON.parse(localStorage.getItem("user"));

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('id', {
            header: "ID",
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('form', {
            header: "Nom du produit",
            cell: i => <div className={"text-center "}>{ extractField(i.getValue(), "prodName" )}</div>
        }));

        arr.push(columnHelper.accessor('author_name', {
            header: "Demandeur",
            cell: i => <div className={"text-center "}>{ i.getValue() }</div>
        }));

        arr.push(columnHelper.accessor('qhse_validation_date', {
            header: "Validation QHSE",
            cell: i => <div className={"text-center "}>
                {i.getValue() === null ? "Non validé" : getDate(i.getValue(), "fr") }
            </div>
        }));

        arr.push(columnHelper.accessor('qhse_com', {
            header: "Commentaire QHSE",
            cell: i => <div className={"text-center "}>{ i.getValue() }</div>
        }));

        arr.push(columnHelper.accessor('doct_validation_date', {
            header: "Validation médecin",
            cell: i => <div className={"text-center "}>
                {i.getValue() === null ? "Non validé" : getDate(i.getValue(), "fr") }
            </div>
        }));

        arr.push(columnHelper.accessor('xx', {
            header: "TODO list",
            cell: i => <div 
                dangerouslySetInnerHTML={{__html: getCheckList(i.row.original.form)}}>
            </div>
        }));

        arr.push(columnHelper.accessor('author', {
            header: "",
            cell: i => <div className={"text-center "}>
                {parseInt( i.getValue() ) === user.id && 
                    <button className="btn btn-outline-success"
                        onClick={()=>p.openNewProduct(i.row.id)}>Ouvrir</button> }
            </div>
        }));

        return arr;
    }

    function extractField(val, field){
        var obj = JSON.parse(val);
        return obj[field];
    }

    function getCheckList( form ){
        var checklist = extractField(form, "checklist") || "";
        
        var missing = "";
        if( checklist.indexOf("fds") === -1 ) missing += "- FDS dans l'intranet<br>";
        if( checklist.indexOf("ndp") === -1 ) missing += "- NDP dans l'intranet<br>";
        if( checklist.indexOf("training") === -1 ) missing += "- Formation nouveau produit<br>";
        if( checklist.indexOf("tags") === -1 ) missing += "- Etiquettes<br>";
        if( checklist.indexOf("prev") === -1 ) missing += "- Moyens de préventions<br>";

        return missing;
    }

    //Set columns
    const columns = getColumns();

    return <Fragment>
        <h5 className="text-center">Liste des nouveaux produits</h5>

        <ReactTable columns={columns} data={p.list}
            origin="new_prod_list" classes={"no-padding"} top0={true} />
    </Fragment>

}

export default NewProductsList;