import { Fragment, useEffect, useState } from "react";
import CsContracts from "../../../services/customClient/CsContracts";
import errorManagement from "../../../services/errorManagement";
import { scrollToTop } from "../../../functions/PageAnimation";
import WaitingRoundSnippers from "../../../common/smallComponents.js/WaitingRoundSnippers";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../../common/smallComponents.js/ReactTable";
import { getDateTimeFromDb } from "../../../functions/StrFunctions";

const IncidentList = (p) => {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);

    useEffect(() => {
        CsContracts.getTicketsList().then(
            (r) => {
                setList(r)
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(p.props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }, []);

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('id', {
            header: '#',
            cell: i => <div className="text-center fw-bold">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('broken_art', {
            header: 'Type de ticket',
            cell: i => <div className="text-center">{i.getValue() === 0 ? "Incident" : "Pièces cassées"}</div>
        }));

        arr.push(columnHelper.accessor('speaker', {
            header: 'Intervenant',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('affaire', {
            header: 'Affaire',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('client', {
            header: 'Client',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('no_machine', {
            header: 'Machine',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('typ_config', {
            header: 'Type de machine',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('request_subject', {
            header: 'Motif de demande',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('call_time', {
            header: 'Date intervention',
            cell: i => <div className="text-center">{getDateTimeFromDb(i.getValue(), "fr")}</div>
        }));

        arr.push(columnHelper.accessor('intervention_duration', {
            header: 'Durée intervention',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('xx', {
            header: '',
            cell: i => <div className="text-center">
                <a href={"/customer-client/incident-ticket/list?page=update&id=" + i.row.original.id
                    + "&machine=" + i.row.original.no_machine.trim() + "&broken_art=" + i.row.original.broken_art}
                    className="btn btn-outline-secondary">Entrer</a>
            </div>
        }));

        return arr;
    }
    //Set columns
    const columns = getColumns();

    return <Fragment>
        <h6 className="text-center">Liste des tickets créés</h6>

        {loading && <WaitingRoundSnippers />}

        {!loading && <ReactTable columns={columns} data={list} show={500}
            origin="cs_incident_list" classes={"no-padding fixFstCol"} top0={true} />}
    </Fragment>

}

export default IncidentList;