import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Loading from "./components/common/Loading";
import { createRoot } from 'react-dom/client';
import { CookiesProvider } from "react-cookie";
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';


const container = document.getElementById('root');
const root = createRoot(container);

root.render(

  <Provider store={store}>

    <Suspense fallback={<Loading />}>
        <CookiesProvider defaultSetOptions={{ path: '/' }}>
          <App />
        </CookiesProvider>
    </Suspense>
  </Provider>,
  //document.getElementById("root")
);

// If you want your app to work offline and load faster, you can chađinge
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
