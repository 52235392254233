import { Fragment } from "react";

const Logos = (props) => {
    return (
        <Fragment>
            <div className="table-responsive">
                <table className="table businessCardStyle">
                    <thead>
                        <tr className="bg-dark bg-gradient text-white text-center rounded">
                            <th scope="col">Logo client</th>
                            <th scope="col">Logo client final</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {/** CLIENT */}
                            <td style={{ width: "33.3%" }} className="border-end">
                                {!props.readOnly &&
                                    <div className="mb-3 text-center">
                                        <label for="formFile"
                                            className="form-label fw-bold">{props.businessInfo.logo_client ?
                                                "Modifier" : "Ajouter"} le logo</label>
                                        <input className="form-control" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                            type="file" onChange={props.addLogo} data-field="logo_client" />
                                    </div>
                                }


                                {props.businessInfo.logo_client &&
                                    <div className="text-center">
                                        <img src={props.businessInfo.logo_client} style={{ maxWidth: "300px" }}></img>
                                    </div>
                                }
                            </td>

                            {/** FINAL CLIENT */}
                            <td style={{ width: "33.3%" }}>
                                {!props.readOnly &&
                                    <div className="mb-3 text-center">
                                        <label for="formFile"
                                            className="form-label fw-bold">{props.businessInfo.logo_cli_fin ?
                                                "Modifier" : "Ajouter"} le logo</label>
                                        <input className="form-control" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                            type="file" onChange={props.addLogo} data-field="logo_cli_fin" />
                                    </div>
                                }
                                {props.businessInfo.logo_cli_fin &&
                                    <div className="text-center">
                                        <img src={props.businessInfo.logo_cli_fin} style={{ maxWidth: "300px" }}></img>
                                    </div>
                                }

                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </Fragment>
    )
}

export default Logos;