import { createColumnHelper } from "@tanstack/react-table";
import Moment from "moment";
import { getFrFormat } from "../../../functions/StrFunctions";
import ReactTable from "../../../common/smallComponents.js/ReactTable";

const Moves = (p) => {

    const getCol = (v) => {
        return v.CDMVO && v.CDMVO.startsWith("E") ? "text-primary" : "text-danger";
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();
        
        arr.push(columnHelper.accessor('DATMO', {
            header: 'Date mouvement',
            cell: (i) => <div className={"text-center " + getCol(i.row.original)} >
                { getFrFormat( i.getValue(), true )}</div>
        }));
        
        arr.push(columnHelper.accessor('LI1CS', {
            header: 'Type de sortie',
            cell: (i) => <div  className={"text-center " + getCol(i.row.original)} >{i.getValue()}</div>
        }));
        
        arr.push(columnHelper.accessor('NOBLO', {
            header: 'N° de BL',
            cell: (i) => <div  className={"text-center " + getCol(i.row.original)} >{i.getValue()}</div>
        }));
        
        arr.push(columnHelper.accessor('NOCCO', {
            header: 'N° d\'affaire',
            cell: (i) => <div  className={"text-center " + getCol(i.row.original)} >{i.getValue()}</div>
        }));
        
        arr.push(columnHelper.accessor('NONNO', {
            header: 'Machine',
            cell: (i) => <div  className={"text-center " + getCol(i.row.original)} >
                {i.getValue()}
                {i.row.original.typ_config && " (" + i.row.original.typ_config + ")"}
                </div>
        }));
        
        arr.push(columnHelper.accessor('NOCOO', {
            header: 'N° de commande',
            cell: (i) => <div  className={"text-center " + getCol(i.row.original)} >
                {i.getValue() && i.getValue() + " (" + i.row.original.NOFOO + ")" }</div>
        }));
        
        arr.push(columnHelper.accessor('QRUSO', {
            header: 'Qté du mouvement',
            cell: (i) => <div  className={"text-center " + getCol(i.row.original)} >{i.getValue()}</div>
        }));
        
        arr.push(columnHelper.accessor('UNCDO', {
            header: 'Unité de cmd',
            cell: (i) => <div  className={"text-center " + getCol(i.row.original)} >
                {i.row.original.PUFAO !== null && i.row.original.UNCDO}</div>
        }));
        
        arr.push(columnHelper.accessor('PUFAO', {
            header: 'Prix facturé par unité de cmd',
            cell: (i) => <div  className={"text-center " + getCol(i.row.original)} >
                {i.getValue() > 0 && <div>
                    {i.getValue()}€ <span style={{fontSize:"13px"}}>({i.row.original.PXARO} €/u</span>)
                </div>}</div>
        }));
        
        arr.push(columnHelper.accessor('COMMENTAIRE', {
            header: 'Commentaire',
            cell: (i) => <div  className={"text-center " + getCol(i.row.original)} >{i.getValue()}</div>
        }));
        
       
        return arr;
    }

    //Set columns
    const columns = getColumns();

    
    return(
    <div>
        {p.moves && <ReactTable columns={columns} data={p.moves} top0={true} origin="move_ar" />}
    </div>)
}

export default Moves;