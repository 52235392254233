import { createColumnHelper } from "@tanstack/react-table";
import { Fragment, useState } from "react";
import ReactTable from "../../../common/smallComponents.js/ReactTable";
import { ButtonWaiting, scrollToTop } from "../../../functions/PageAnimation";
import errorManagement from "../../../services/errorManagement";
//import BomDao from "../../../services/machines/BomDao";
import BomDaoV2 from "../../../services/machines/BomDaoV2";

const FocusCompareTab = (p) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const [working, setWorking] = useState(false);

    /**
 * Generate xls file for update
 */
    const xlsUpdate = () => {
        var addedArt = new Array();
        var err = "";
        p.list.map(v => {
            if (v.type_rst === "add") {
                if ((v.ar === null || v.ar.trim() === "") && !v.ref.startsWith("NM-") && !v.ref.startsWith("NE-")) {
                    err += "Aucun code article pour la ref. " + v.ref + "<br>";
                } else {
                    addedArt.push(v);
                }

                if (v.cat === null || v.cat.trim === "" || v.cat === "?")
                    err += v.ref + "n'a pas de catégorie correctement renseigné. Synchonisez les et ré-essayez<br>";
            }
        });

        if (err !== "") {
            p.setError(err);
            scrollToTop();
            return;
        }

        setWorking(true);
        //Get general BOM and check if the others subsets are created if there are
        var eg = new Array();
        var se, ref;
        addedArt.map(v => {
            se = v.se.trim();
            ref = v.ref.toString().replace(/ /g, '').trim().toUpperCase();
            if (se.endsWith("EG") && (ref.startsWith("NM-") || ref.startsWith("NE-"))) eg.push(v);
        });

        var othSubset = new Array();
        p.setError();

        var isEc16 = p.machineInfo.typ_config.startsWith("EC16");
        eg.map(v => {
            if (!isEc16) if (v.ref.split("-")[2].charAt(0) !== p.machineInfo.no_ligne) othSubset.push(v.ref)
        });

        if (othSubset.length > 0) {
            BomDaoV2.getOtherMachineSubset(othSubset).then(
                (response) => {
                    var err = "";
                    var flag = false;
                    var otSb = new Array();

                    othSubset.map(v => {
                        flag = false;
                        response.map(v2 => {
                            if (v.trim() === v2.destt.trim()) {
                                otSb.push({
                                    code: v2.cdart,
                                    ref: v.trim()
                                });
                                flag = true;
                            }
                        });

                        if (!flag) err += "<li>" + v + "</li>";
                    });

                    if (err !== "") {
                        p.setError("Des sous-ensembles font référence à d'autres sous-ensemble qui n'ont pas été importés "
                            + "dans Focus, veuillez commencer par ceux-là: " + err);
                        setWorking(false);
                    } else {
                        //merge founded codes with bom array
                        var arr = [...addedArt];
                        arr.map(v => {
                            var se = v.se.trim();
                            var ref = v.ref.toString().replace(/ /g, '').trim().toUpperCase();
                            if (se.endsWith("EG") && (ref.startsWith("NM-") || ref.startsWith("NE-"))) {
                                //Search the code
                                otSb.map(v2 => {
                                    if (ref.trim() === v2.ref.trim()) {
                                        v.ar = v2.code;
                                        return;
                                    }
                                })
                            }
                        });

                        addedArt = arr;
                        createUpdateFile(arr);
                    }
                },
                (error) => {
                    p.setError(errorManagement.handleError(p, error));
                    scrollToTop();
                }
            );
        } else {
            createUpdateFile(addedArt);
        }
    }

    /**
     * 
     * @param {*} arr 
     */
    const createUpdateFile = (arr) => {
        BomDaoV2.generateUpdateXls(p.machineInfo, arr, p.typeBom).then(
            (response) => {
                p.setMsg(response);
                setWorking(false);
            },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
                setWorking(false);
            }
        )
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('cat', {
            header: 'Catégorie',
            filterFn: 'selectMultiple',
            cell: i => <div className="display-flex">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('se', {
            header: 'S/E',
            cell: i => <div className="text-center fw-bold">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('rep', {
            header: 'Repère',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('qty', {
            header: 'Qté',
            cell: i => <div className="display-flex">
                <button className={"btn "
                    + (i.row.original.type_rst === "add" ? "btn-success " :
                        i.row.original.type_rst === "remove" ? "btn-danger " : "")}>{i.row.original.type_rst === "add" ? "+ " :
                            i.row.original.type_rst === "remove" ? "- " : "="}</button>
                <input type="number" className="form-control text-center" defaultValue={i.getValue()}
                    onBlur={p.onFieldBlur} data-key={i.row.original.parent_key}
                    data-table={i.row.original.table} data-field="qty" data-id={i.row.original.id}
                    readOnly={i.row.original.qty_changing || i.row.original.table === "focus"}></input>
                {i.row.original.qty_changing && <button className="btn"><ButtonWaiting /></button>}
            </div>
        }));

        arr.push(columnHelper.accessor('french_label', {
            header: 'Désignation FR',
            cell: i => <div>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('ref', {
            header: 'Référence',
            cell: i => <div className="display-flex">
                <input className="form-control" defaultValue={i.getValue()} //onChange={p.onFieldChange}
                    onBlur={p.onFieldBlur} style={{ minWidth: "250px" }} data-key={i.row.original.parent_key} title={i.getValue()}
                    data-table={i.row.original.table} data-field="ref" data-id={i.row.original.id}></input>
                {i.row.original.ref_changing && <button className="btn"><ButtonWaiting /></button>}
            </div>
        }));

        arr.push(columnHelper.accessor('maker', {
            header: 'Fabricant',
            cell: i => <div className="display-flex">
                <input className="form-control" defaultValue={i.getValue()} //onChange={p.onFieldChange}
                    onBlur={p.onFieldBlur} data-key={i.row.original.parent_key} title={i.getValue()}
                    data-table={i.row.original.table} data-field="maker" data-id={i.row.original.id}></input>
                {i.row.original.maker_changing && <button className="btn"><ButtonWaiting /></button>}
            </div>
        }));

        arr.push(columnHelper.accessor('ar', {
            header: 'Code article',
            filterFn: 'strWithEmpty',
            cell: i => <Fragment>
                <div className="display-flex">
                    <input className="form-control" defaultValue={i.getValue()} //onChange={p.onFieldChange}
                        onBlur={p.onFieldBlur} data-key={i.row.original.parent_key} title={i.getValue()}
                        data-table={i.row.original.table} data-field="ar" data-id={i.row.original.id}
                        style={{ minWidth: "150px" }}></input>
                    {i.row.original.ar_changing && <button className="btn"><ButtonWaiting /></button>}
                </div>
                {i.row.original.artproposed !== undefined && i.row.original.artproposed !== null &&
                    <Fragment>
                        <a>Prop: <a href={"/article/info/get?article=" + i.row.original.artproposed}>{i.row.original.artproposed}</a></a>
                    </Fragment>}
            </Fragment>
        }));

        arr.push(columnHelper.accessor('stock_at_term', {
            header: 'Stock',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('OBSES', {
            header: 'Coms',
            cell: i => (i.getValue() && i.getValue() !== null && i.getValue().trim() !== "") ?
                <img title={i.getValue()} src={"/common/tooltip.png"}></img> : ""
        }));

        return arr;
    }
 
    //Set columns
    const columns = getColumns();

    return <Fragment>
        {
            (user.roles.includes('ROLE_PURCHASE') || user.roles.includes('ROLE_ADMIN')) &&
            <div className="mb-3 text-center">
                <button className="btn btn-success" disabled={working}
                    onClick={xlsUpdate}>
                    {working && <ButtonWaiting />}
                    Générer fichier d'import
                </button>
            </div>
        }

<ReactTable columns={columns} data={p.list}
                origin="bom_content" classes={"no-padding"} top0={true} />
    </Fragment>

}

export default FocusCompareTab;