import { Fragment, useEffect } from "react";
import { useState } from "react";
import i18next from "i18next";

const FormList = (props) => {

    var form = props.form;
    var readonly = props.readOnly !== null ? props.readonly : false;
    const [lang, setLang] = useState(i18next.language);
 
    /**
     * Check radio option
     * @param {*} e 
     */
    const setOption = (e) => {
        const qst = parseInt(e.target.getAttribute("data-qst"));
        const key = parseInt(e.target.getAttribute("data-key"));

        var arr = [...form];

        var o = arr[qst].options[key];

        arr[qst].options.map(
            (v, k) => { 
                if (k === key) { 
                    v.checked = true 
                } else if( v.name === o.name ){ 
                    v.checked = false 
                } 
            }
        );

        props.onChange(arr);
    }

    /**
     * check the checkbox
     * @param {*} e 
     */
    const setChecked = (e) => {
        const qst = parseInt(e.target.getAttribute("data-qst"));
        const key = parseInt(e.target.getAttribute("data-key"));
        const checked = e.target.checked;

        var arr = [...form];
        arr[qst].chkboxs[key].checked = checked;
        props.onChange(arr);
    }

    /**
     * Set textarea
     */
    const setTextArea = (e) => {
        const qst = parseInt(e.target.getAttribute("data-qst"));
        const key = parseInt(e.target.getAttribute("data-key"));
        const txt = e.target.value;

        var arr = [...form];
        arr[qst].textarea[key].value = txt;
        props.onChange(arr);
    }

    /**
     * Set input text
     * @param {*} e 
     */
    const setInputText = (e) => {
        const qst = parseInt(e.target.getAttribute("data-qst"));
        const key = parseInt(e.target.getAttribute("data-key"));
        const value = e.target.value;

        var arr = [...form];
        arr[qst].inputs[key].value = value;
        props.onChange(arr);
    }

    return (
        <ul className="list-group">
            {form && form.map(
                (v, k) =>
                    <li className="list-group-item">
                        <span className="fw-bold"
                            dangerouslySetInnerHTML={{ __html: (k + 1) + ") " + v.en_label }}></span>
                        <br></br>

                        {/** Textarea */}
                        {
                            (v.textarea !==null && v.textarea !== "") &&
                            v.textarea.map((v2, k2) => {
                                return (
                                    <textarea key={"txt_" + k2 + "_" + v} className="form-control"
                                        data-key={k2} data-qst={k} onChange={setTextArea}
                                        name={"qst_" + v.question} value={v2.value}
                                        readOnly={readonly}></textarea>
                                )

                            })
                        }

                        {/** Checkbox */}
                        {
                            ( v.chkboxs !== null && v.chkboxs !== "" ) &&
                            v.chkboxs.map((v2, k2) => {
                                return (
                                    <label key={"chk_" + k2 + "_" + v} className="radio-inline" style={{ marginLeft: "5%" }}>
                                        <input type="checkbox" name={"qst_" + v.question}
                                            data-key={k2} data-qst={k}
                                            checked={v2.checked} onChange={setChecked}
                                            disabled={readonly} />
                                        <span style={{ marginLeft: "10px" }}
                                            dangerouslySetInnerHTML={{ __html: v2.value }}></span>
                                    </label>
                                )

                            })
                        }

                        {/** Options */}
                        {
                            ( v.options!==null && v.options !== "" )&&
                            v.options.map((v2, k2) => {
                                return (
                                    <Fragment>
                                        {
                                            (!readonly || (readonly && v2.checked)) &&
                                                <Fragment>
                                                    <label key={"opt_" + k2 + "_" + v} className="radio-inline" style={{ marginLeft: "5%" }}>
                                                        <input type="radio" name={"qst_" + v.question + "_" + v2.name}
                                                            data-key={k2} data-qst={k}
                                                            checked={v2.checked} onChange={setOption}
                                                            disabled={readonly} />
                                                        <span style={{ marginLeft: "10px" }}
                                                            dangerouslySetInnerHTML={{ __html: v2.value }}></span>
                                                    </label>
                                                    {
                                                        (v.options[k2 + 1] && v2.name !== v.options[k2 + 1].name ) &&
                                                            <hr style={{ borderTop: "1px solid black", width: "20%", 
                                                                            marginTop: "5px", marginBottom: "5px", marginLeft: "4%" }}/>
                                                    }
                                                </Fragment>
                                        }
                                    </Fragment>
                                )

                            })
                        }

                        {/** Inputs */}
                        {
                            (v.inputs !== null && v.inputs !== "") &&
                                v.inputs.map((v2, k2) => {
                                    return (
                                        <div key={"inp_" + k2 + "_" + v} className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1">{v2.name}</span>
                                            <input type="text"
                                                data-key={k2} data-qst={k} onChange={setInputText}
                                                className="form-control" value={v2.value}
                                                readOnly={readonly} />
                                        </div>
                                )

                            })
                        }

                    </li>
            )}
        </ul>
    )
}

export default FormList;