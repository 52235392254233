import { Fragment, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { scrollToTop } from "../../functions/PageAnimation";
import getUrlParam from "../../functions/StrFunctions";
import errorManagement from "../../services/errorManagement";
import orderInfo from "../../services/purchase/orderInfo";
import Labels from "../../services/storehouse/Labels";
import Movement from "../../services/storehouse/Movement";

const Receiption = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const token = currentUser && "Bearer " + currentUser.accessToken;
    const API_URL = process.env.REACT_APP_API_URL;

    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState();
    const [header, setHeader] = useState();
    const [comment, setComment] = useState();
    const [detail, setDetail] = useState();
    const [nbReception, setNbReceiption] = useState(0);
    const [receiptionStart, setReceiptionStart] = useState(false);
    const [date, setDate] = useState("");
    const [BL, setBL] = useState("");
    const [label, setLabel] = useState([]);
    const [printingLabel, setPrintingLabel] = useState(false);
    var refreshStatus;

    useEffect(() => {
        if (currentUser === null) {
            localStorage.setItem("url_back", window.location.pathname);
            props.history.push('/login');
            window.location.href = "/login";
        } else if (currentUser.global_id === null || currentUser.global_id.trim() === "") {
            setError("Votre global ID n'est pas renseigné. Veuillez le saisir dans votre "
                + "<a href='/profile'>profil</a>.")
        } else {
            var param = getUrlParam(props, "order");

            if (param === null) {
                setError("Aucun numéro de commande saisi !");
            } else {
                setOrder(param);
                getOrderInfo(param);
            }
        }

    }, []);

    const getOrderInfo = (order) => {
        orderInfo.getOrderInfo(order).then(
            (response) => {
                setLoading(false);
                setHeader(response.header);
                setComment(response.header_comment);
                setDetail(response.detail);
                if (response.receiption !== "") {
                    setReceiptionStart(true);
                    setMsg(response.receiption);
                    
                    //Loop until message is "Réception terminée"
                    if(!response.receiption.startsWith("Réception terminée")  ){
                        refreshStatus = setInterval( function(){ getStatus(refreshStatus, order) }, 4000);
                    }else{
                        setReceiptionStart(false);
                        clearInterval(refreshStatus);
                    }
                }else{
                    setReceiptionStart(false);
                    clearInterval(refreshStatus);
                }
            },
            (error) => {
                setError("Impossible de récupérer les infos de la commande, rafraîchissez la page: " 
                            + errorManagement.handleError(props, error));
                scrollToTop();
            }
        )
    }

    function getStatus(refreshStatus, order) {
        setError();
        Movement.getReceiptionStatus(order).then(
            (response) => {
                
                if( response.startsWith("Réception terminée") ){
                    clearInterval(refreshStatus);
                    setMsg( "Mise à jour de la page."+ "<br>" + response  );
                    getOrderInfo(order);
                }else{
                    setMsg(response + ", mise à jour dans quelques secondes.");
                                        
                }
            },
            (error) => {
                setError("Impossible de récupérer la progression de la récption, rafraîchissez la page: "
                    + errorManagement.handleError(props, error));
            }
        );

        setMsg();

    }

    /**
     * On manually changy qty
     * 
     * @param {*} e 
     */
    const onQtyChange = (e) => {
        var qty = parseInt(e.target.value === "" ? 0 : e.target.value);
        var row = parseInt(e.target.getAttribute("data-row"));

        detail.map((v, k) => {
            if (v.NLIGE === row) {

                //Check if the qty to move is upper than remained
                if (qty > v.remained) {
                    confirmAlert({
                        title: "Réception excédentaire",
                        message: "Etes-vous sûr de vouloir recevoir " + qty + " " + v.CDARE + " alors qu'il ne reste que " + v.remained + " à receptionner ?",
                        buttons: [
                            {
                                label: "Oui",
                                onClick: () => { changeQty(qty, k, qty > 0); }
                            },
                            {
                                label: "Non"
                            }
                        ]
                    });
                } else {
                    changeQty(qty, k, qty > 0);
                }

            }
        })


    }

    /**
     * On check one checkbox
     * @param {*} e 
     */
    const onCheck = (e) => {
        var row = parseInt(e.target.getAttribute("data-row"));
        var checked = e.target.checked;

        var tot = 0;
        detail.map((v, k) => {
            if (v.NLIGE === row) changeQty(checked ? v.remained : 0, k, checked);
            if (v.checked && v.remained > 0) { tot++; }
        })

        setNbReceiption(tot);
    }

    /**
     * Chekc all checkbox
     */
    const onCheckAll = (e) => {
        var checked = e.target.checked;

        var tot = 0;
        detail.map((v, k) => {
            changeQty(checked ? v.remained : 0, k, checked);
            if (checked && v.remained > 0) tot++;
        });

        setNbReceiption(tot);
    }

    /**
     * Change qty to move
     * @param {*} row 
     * @param {*} qty 
     */
    const changeQty = (qty, index, checked) => {
        var arr = [...detail];
        arr[index].to_move = qty;
        arr[index].checked = checked;

        setDetail(arr);
    }

    /**
     * If receiption is < remained and no more receiption will occur
     * @param {*} e 
     */
    const closeRow = (e) => {
        var row = parseInt(e.target.getAttribute("data-row"));
        var checked = e.target.checked;
        var arr = [...detail];

        arr.map(v => {
            if (v.NLIGE === row) v.close = checked;
        });

        setDetail(arr);

    }

    /**
     * Start articles receiption
     */
    const doReceiption = () => {
        setReceiptionStart(true);
        setError();

        if (date.trim() === "" || BL.trim() === "") {
            setError("Merci de saisir la date et le numéro de BL de réception");
            setReceiptionStart(false);
            scrollToTop();
            return;
        }

        confirmAlert({
            title: "Validation de reception",
            message: "Etes-vous sûr de vouloir receptionner " + nbReception + " article(s) ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        Movement.doReceiption(order, date, BL, detail).then(
                            (response) => {
                                //getOrderInfo(order);
                                setNbReceiption(0);
                                //setReceiptionStart(false);

                                //Loop until message is "Réception terminée"
                                refreshStatus = setInterval( function(){ getStatus(refreshStatus, order) } , 4000);
                                scrollToTop();
                            },
                            (error) => {
                                var err = errorManagement.handleError(props, error);
                                setError(err + ": veuillez rafraîchir la page.");
                                scrollToTop();
                            }
                        );
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const onChangetDate = (e) => {
        setDate(e.target.value);
    }

    const onChangeBl = (e) => {
        setBL(e.target.value)
    }

    /**
     * Set label to print
     * @param {*} e 
     */
    const printLabel = (e) => {
        var checked = e.target.checked;

        if (checked) {
            var arr = [...label];
            var index = parseInt(e.target.getAttribute("data-key"));
            arr.push(detail[index]);
            setLabel(arr);
        } else {
            var arr = new Array();
            var row = parseInt(e.target.getAttribute("data-row"));
            arr.map(v => { if (v.NLIGE !== row) arr.push(v) });
            setLabel(arr);
        }

    }

    /**
     * Print all labels
     */
    const printAllLabel = (e) => {
        var checked = e.target.checked;

        if (checked) {
            var arr = [...detail];
            setLabel(arr);
        } else {
            var arr = new Array();
            setLabel(arr);
        }

        var checkboxes = document.getElementsByClassName("label-chk");
        for (var i = 0; i < checkboxes.length; i++) checkboxes[i].checked = checked;
    }

    /**
     * Create labels then download file
     */
    const exportLabels = () => {
        setPrintingLabel(true);
        Labels.createLabels(order, label).then(
            (response) => {
                setPrintingLabel(false);
                window.open("", "_blank").location.href =
                    API_URL + "download/temp?file=" + response + "&Authorization=" + token;
            },
            (error) => {
                setPrintingLabel(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        )
    }

    return (
        <div className="order-edition">
            {error && <ErrorMessage error={error} />}

            {loading && <WaitingRoundSnippers />}

            {
                (msg) &&
                    <SuccessMessage msg={msg}></SuccessMessage>
            }

            {
                header &&
                <div>
                    {/** Header */}
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <img src={"/Logo B+ Eq SealedAir.png"} style={{ width: "50%" }} />
                                <br />
                                <span className="fw-bold">Adresse de livraison:</span>
                                <br />
                                <span>{header.NMLIC}</span>
                                <br />
                                <span>{header.ADRLC + ' ' + header.ADRLC2}</span>
                            </div>

                            <div className="col text-center">
                                <h1>
                                    <span>{'Commande n°' + header.NOCFC}</span>
                                    {
                                        (header.NOAVC != null && detail[0].ZN11PH != 'N') && <span> {"_" + header.NOAVC} </span>
                                    }
                                </h1>
                                <h2>{'Fournisseur: ' + header.CDFOC}</h2>
                                <span>
                                    {"Le " + header.DTCRC + ", suivi par " + (header.LI1CS != null ? header.LI1CS : header.CACHC)}
                                </span>
                                {
                                    detail[0].ZN10PH != null &&
                                    <div>
                                        <br></br>
                                        <span>{"Donneur d'ordre: " + detail[0].ZN10PH}</span>
                                    </div>
                                }

                                <br></br>

                                {
                                    currentUser.roles.includes("ROLE_ART_MOVEMENT") &&
                                    <div className="alert alert-secondary no-print">
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" style={{ width: "200px" }}>Date de réception</span>
                                            <input type="date" className="form-control" onChange={onChangetDate} value={date} />
                                        </div>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" style={{ width: "200px" }}>N° de BL</span>
                                            <input type="text" className="form-control" onChange={onChangeBl} value={BL} />
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>

                        {/*<img className="no-print" src={"/img/print.png"} onClick={window.print} 
                                style={{ position: "absolute", top: "80px", right: "10px" }} ></img>*/}
                    </div>

                    {/** Header comments */}
                    {
                        (comment != null && comment !== "") &&
                        <div className="alert alert-info fw-bold" role="alert">Remarque: {comment}</div>
                    }

                    <br></br>

                    {/** Detail className="table-responsive" */}
                    <div>
                        <table className="table text-center">
                            <thead className="table-dark">
                                <tr className="fixed-header">
                                    <th scope="col">#</th>
                                    <th scope="col">Désignation / Observation</th>
                                    <th scope="col"> Référence Code article </th>
                                    <th scope="col">Affaire</th>
                                    <th scope="col" className="no-print">Date de livraison</th>
                                    <th scope="col">Qté</th>
                                    {/**<th scope="col" className="print-only">P.U.H.T (en €)</th>
                                        <th scope="col" className="print-only">P.T.H.T (en €)</th>*/}
                                    {
                                        currentUser.roles.includes("ROLE_ART_MOVEMENT") &&
                                        <Fragment>
                                            <th scope="col" className="no-print">Qté à réceptionner</th>
                                            <th scope="col" className="no-print">Tout selectionner <input type="checkbox" onChange={onCheckAll} /></th>
                                            <th scope="col" className="no-print">Solder en livraison</th>
                                        </Fragment>
                                    }
                                    {/*<th scope="col" className="no-print">
                                            A étiquetter <input type="checkbox" onClick={printAllLabel}/>
                                        </th>*/}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    detail.map((v, k) => {
                                        return (
                                            <Fragment key={"cmd" + k}>
                                                <tr>
                                                    <td>{v.NLIGE}</td>
                                                    <td>
                                                        {v.ZN03PA}
                                                        <br />
                                                        Ref: {v.ZN02PA}
                                                    </td>
                                                    <td>
                                                        {
                                                            v.ZN09PA === null && <span style={{ fontSize: "12px" }}>{v.ZN02PA}</span>
                                                        }
                                                        {
                                                            (v.ZN09PA != null && v.ZN09PA !== 'ignore') && <a target="_blank" style={{ fontSize: "12px" }} href={"http://10.141.44.140:8080/display-doc?path=" + encodeURIComponent(v.ZN09PA)}>{v.ZN02PA}</a>
                                                        }
                                                        <br></br>
                                                        <a target="_blank" href={"http://10.141.44.140:8080/article-info/basic-info/" + v.CDARE}>{v.CDARE}</a>
                                                        {
                                                            v.ZN04PA !== null && <span className="fst-italic"> ({v.ZN04PA})</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        {(v.NONNE !== "SAV" && v.NOCCE === null) && "ACHSTOCK"}
                                                        {(v.NONNE !== "SAV" && v.NOCCE !== null) && v.NOCCE}
                                                        {v.NONNE === "SAV" && <a target="_blank" href={"http://10.141.44.140:8080/storehouse/picking/list?business=" + v.NOCCE + "&delivery_date=&warranty=&type_pack=&standard=&status=&prc=&affected=&coms="}>{v.NOCCE}</a>}
                                                        <br></br>
                                                        {v.NONNE + (v.IDEPE !== null ? " / " + v.IDEPE : "")}
                                                    </td>
                                                    <td className="no-print">{v.DTLIE}</td>
                                                    <td>{parseFloat(v.QTEGE).toFixed(1)}<br />{v.LIBELLE_UNITE}</td>
                                                    {/**<td className="print-only">
                                                            { parseFloat( v.PUNDE ).toFixed(1) }
                                                        </td>
                                                        <td className="print-only">
                                                            {parseFloat( v.QTEGE * v.PUNDE ).toFixed(1)}
                                                        </td>*/}
                                                    {
                                                        currentUser.roles.includes("ROLE_ART_MOVEMENT") &&
                                                        <Fragment>
                                                            <td style={{ maxWidth: "150px" }} className="no-print">
                                                                {(v.remained > 0 && v.SLDQE !== 'S') &&
                                                                    <input type="number" step="any" className="form-control text-center"
                                                                        value={v.checked === null || !v.checked ? 0 : v.to_move}
                                                                        onChange={onQtyChange} data-row={v.NLIGE}
                                                                        min="0" />}
                                                            </td>

                                                            <td className="no-print">
                                                                {(v.remained > 0 && v.SLDQE !== 'S') &&
                                                                    <input type="checkbox" onChange={onCheck} data-row={v.NLIGE} style={{ width: "30px", height: "30px" }}
                                                                        checked={v.checked !== null && v.checked} />}
                                                                {(v.SLDQE === 'S' ? "Soldé en livraison" : v.remained <= 0 ? "Réceptionné en intégralité" : "")}
                                                            </td>


                                                            <td className="no-print">
                                                                {(v.checked && v.to_move < v.remained) &&
                                                                    <input type="checkbox" style={{ width: "30px", height: "30px" }}
                                                                        value={v.close} data-row={v.NLIGE} onChange={closeRow} />}
                                                            </td>

                                                        </Fragment>
                                                    }

                                                    {/*<td className="no-print">
                                                            <input type="checkbox" className="label-chk" style={{width: "30px", height: "30px"}} 
                                                                    data-key={k} data-row={v.NLIGE} onChange={printLabel} />
                                                            </td>*/}
                                                </tr>
                                                <tr className={v.REFAU === null ? "d-none" : ""}>
                                                    <td colSpan="10" >{v.REFAU}</td>
                                                </tr>
                                                <tr className={v.COMMW === null ? "d-none" : ""}>
                                                    <td colSpan="10" >{v.COMMW}</td>
                                                </tr>
                                            </Fragment>
                                        );
                                    })
                                }


                            </tbody>

                        </table>
                    </div>

                    <div>
                        Observations:
                        <br></br>
                        {detail[0].ZN01PH}
                        <br></br>
                        {detail[0].ZN02PH}
                    </div>

                    <div className="text-center">
                        {nbReception > 0 &&
                            <button className="btn btn-success" disabled={receiptionStart}
                                onClick={doReceiption}>
                                {receiptionStart && <span className="spinner-border spinner-border-sm"></span>}
                                {!receiptionStart && "Réceptionner " + nbReception + " article(s)"}
                                {receiptionStart && "Réception en cours..."}
                            </button>
                        }

                        {
                            label.length > 0 &&
                            <button className="btn btn-secondary no-print" disabled={printingLabel} onClick={exportLabels}>
                                {printingLabel && <span className="spinner-border spinner-border-sm"></span>}
                                {printingLabel && "Création d'étiquettes..."}
                                {!printingLabel && "Imprimer les étiquettes"}
                            </button>
                        }
                    </div>
                    <br></br>

                </div>


            }


        </div>
    )
}

export default Receiption
