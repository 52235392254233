import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import { removeEltArray } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import getUrlParam from "../../functions/StrFunctions";
import errorManagement from "../../services/errorManagement";
import Movement from "../../services/storehouse/Movement";
import { MachineBomOutMenu } from "./components/MachineBomOutMenu";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { isSideBarOpened, SideNavBarV3 } from "../../common/smallComponents.js/DesignedIpt";

const MachineBomOut = (props) => {
    const [pin, setPin] = useState(isSideBarOpened());
    const [machine, setMachine] = useState();
    const [searching, setSearching] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();

    const [list, setList] = useState();
    const [response, setResponse] = useState();
    const [businessList, setBusinessList] = useState();
    const [filter, setFilter] = useState("all");
    const [workstation, setWorkstation] = useState("all");
    const [consumables, setConsumable] = useState();
    const [subsetToPrint, setSubsetToPrint] = useState([]);
    const [artToPrint, setArtToPrint] = useState([]);

    useEffect(() => {
        var machine = getUrlParam(props, "machine");
        setMachine(machine);

        if( machine ) getBom( machine, false );
    }, []);

    /** */
    const getBom = (machine, consumables) => {
        setError();

        Movement.getBom(machine, consumables).then(
            (response) => {
                checkDoublons(response);
                setResponse(response);
                setConsumable(consumables);
                
                setSearching(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setSearching(false);
            }
        );
    }

    {/** */ }
    function checkDoublons(arr) {
        var doublons = new Array();
        arr.map(v => { if (!doublons.includes(v.business)) doublons.push(v.business) })

        if (doublons.length > 1) { setBusinessList(doublons); }
        else { setList(arr); }
    }

    {/** */ }
    const chooseBusiness = (business) => {
        var arr = new Array();
        business = business.substring(0, business.length-1)
        response.map(v => { if (v.business.indexOf( business ) !== -1 ) arr.push(v) })
        setList(arr);
    }

    {/** */ }
    const getClass = (v, k) => {
        var c = "";
        var row = list[k];

        // Hide according all display - available only - completely avail
        if (filter === "available" && (row.qte_restante === 0 || row.qte_affectee === 0)) return "d-none";
        if (filter === "completeAvailable" && (row.qte_restante === 0 || row.qte_affectee !== row.qte_restante)) return "d-none";
        if( workstation !== 'all' && workstation !== row.workstation ) return "d-none";

        // Get color
        if (row.sold_out <= 0 || (row.closed && row.closed.endsWith("CLOSED"))) {
            c += " ok";
        } else if (v.article_cat.trim().toUpperCase() === "PEINT") {
            c += " paint"
        }

        // Only selected
        if (subsetToPrint.length > 0 && !subsetToPrint.includes(row.se)) c += " no-print"

        return c;
    }

    function getTitleClass(k) {
        if (subsetToPrint.length > 0 && !subsetToPrint.includes(list[k].se)) return " no-print"
    }

    {/** */ }
    function getSubsetName(se) {
        var arr = se.split("-");
        return arr.length < 5 ? se : arr[2];
    }

    {/**  */ }
    const updateSubsetToPrint = (e, k) => {
        var arr = [...subsetToPrint];
        if (e.target.checked) { arr.push(list[k].se); }
        else { arr = removeEltArray(arr, list[k].se) }

        setSubsetToPrint(arr);
    }

    {/** */ }
    const updateArtToPrint = (e, k) => {
        var arr = [...artToPrint];
        if (e.target.checked) { arr.push(list[k].need_num); }
        else { arr = removeEltArray(arr, list[k].need_num) }
        setArtToPrint(arr);
    }

    function getStationName(){
        if( workstation === "M" ) return "Montage sur table";
        if( workstation === "I" ) return "Intégration";
        if( workstation === "C" ) return "Cartérisation";
        if( workstation === "E" ) return "Electricité";
    }

    return <div className="bom-picking">
        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}
        {searching && <WaitingRoundSnippers />}

        {(businessList && !list) && <div className="card col-6 offset-3 text-center">
            <h5>Plusieurs affaires ont été trouvées pour cette machine</h5>
            <h6>Sélectionnez celle que vous voulez traiter</h6>

            <ul className="list-group">
                {
                    businessList.map((v, k) => {
                        return <li key={"doub" + k} className="list-group-item"
                            onClick={() => chooseBusiness(v)}>{v}</li>
                    })
                }
            </ul>
        </div>
        }

        {list && <div className="bg-see navbarV3 right-side">

            <SideNavBarV3 setPin={setPin} content={<div className="ps-2 pe-2">
                <MachineBomOutMenu list={list} setList={setList} subsetToPrint={subsetToPrint} artToPrint={artToPrint} 
                    filter={filter} setFilter={setFilter} consumables={consumables} setConsumable={setConsumable}
                    setError={setError} workstation={workstation} setWorkstation={setWorkstation}/>
            </div>}/>

            <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>
                {/** Header */}
                <div className="display-flex" style={{width: "100%"}}>
                    <div className="fw-bold ps-3" style={{width: "100%"}}>Affaire: {list[0].business}</div>
                    { workstation !== "all"  && <div className="fw-bold text-center" style={{width: "100%"}}>{getStationName()}</div> }
                    <div className="fw-bold text-end pe-3" style={{width: "100%"}}>{list[0].machine}</div>
                </div>

                {/** List */}
                {list.map((v, k) => {
                    return <Fragment key={"ar" + k}>
                        {(k === 0 || v.se !== list[k - 1].se) &&
                            <div className={"se-title " + getTitleClass(k)}>
                                <input type="checkbox" className="me-2 no-print" onClick={(e) => updateSubsetToPrint(e, k)} />
                                {getSubsetName(v.se)}
                            </div>}

                        <div className={"ar " + getClass(v, k)}>
                            <div className="display-flex">
                                <div>
                                    <input type="checkbox" className="me-2 no-print" onClick={(e) => updateArtToPrint(e, k)} />
                                </div>
                                <div className="me-2">{v.article}</div>
                                <div className="me-2">({v.qte_affectee} / {v.qte_initiale})</div>
                                {v.ordered_qty > 0 && <div>(en cmd)</div>}
                                {v.blocked === "C" && <div className="me-2 text-danger fw-bold">Bloqué !</div>}
                                {v.location && <div className="ms-auto">({v.location})</div>}
                            </div>
                        </div>
                    </Fragment>
                })}

            </div>

        </div>}

        {/** test 1580 */}
    </div>
}

export default MachineBomOut;