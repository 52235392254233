import { createColumnHelper } from "@tanstack/react-table";
import Moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { InfoMsg, WarningMsg } from "../../common/smallComponents.js/ErrorMessage";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { scrollToTop } from "../../functions/PageAnimation";
import CsContracts from "../../services/customClient/CsContracts";
import errorManagement from "../../services/errorManagement";
import { getFrFormat } from "../../functions/StrFunctions";

const PickingAlerts = (props) => {
    const [error, setError] = useState();
    const [warning, setWarning] = useState();
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState(true);
    const [msg, setMsg] = useState();

    const [list, setList] = useState();

    Moment.locale("fr");

    useEffect(() => {
        CsContracts.getPickingAlerts().then(
            (response) => {
                setList(response);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }, []);



    /** */
    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('affaire', {
            header: 'Affaire',
            cell: i => <div className={"text-center "}>
            <a href={"/customer-client/picking?business=" + i.getValue()} target="_blank">{i.getValue()}</a>
        </div>
        }));

        arr.push(columnHelper.accessor('article', {
            header: 'Article',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('delai_besoin', {
            header: 'Date de besoin init.',
            cell: i => <div className={"text-center "}>{ getFrFormat( i.getValue(), true ) }</div>
        }));

        arr.push(columnHelper.accessor('qte_initiale', {
            header: 'Qté initiale',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('qtstl', {
            header: 'Qte Stock',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('qte_affectee', {
            header: 'Qté reservée pour l\'aff.',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('qte_recu_corr', {
            header: 'Qté reçue sur aff.',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('qte_picked_corr', {
            header: 'Qté mouvt du stock',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('qte_initiale', {
            header: 'Qté initiale',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('cmd_besoin', {
            header: 'Qté cmd / aff.',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('last_order_need_date', {
            header: 'Date livraison cmd sur aff.',
            cell: i => <div className={"text-center "}>{getFrFormat( i.getValue(), true )}</div>
        }));

        arr.push(columnHelper.accessor('cmd_stck', {
            header: 'Qté cmd / stck.',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('last_order_stck_date', {
            header: 'Date livraison cmd sur sck.',
            cell: i => <div className={"text-center "}>{getFrFormat( i.getValue(), true )}</div>
        }));

        arr.push(columnHelper.accessor('alert', {
            header: 'Alerte',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));


        return arr;
    }

    //Set columns
    const columns = getColumns();


    return <Fragment>
        {warning && <WarningMsg warning={warning} warningState={setWarning} />}
        {info && <InfoMsg info={info} infoState={setInfo} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}
        {loading && <WaitingRoundSnippers />}

        <h4 className="text-center">Alertes pickings</h4>

        {list && <Fragment>
            
            <ReactTable columns={columns} data={list} show={1000}
                origin="cs_picking_alerts" classes={"no-padding fixFstCol"} top0={true} />

        </Fragment>
        }

    </Fragment>

}

export default PickingAlerts;