import { Fragment, useMemo, useState } from "react";
import Table from "../../../common/smallComponents.js/Table";
import { ButtonWaiting } from "../../../functions/PageAnimation";

const NoNeedTab = (p) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const [working, setWorking] = useState(false);

    const columns = useMemo(
        () => [
            { Header: "Catégorie", accessor: "cat", filterFn: 'selectMultiple', },
            { Header: "S/E", accessor: "se" },
            { Header: "Repère", accessor: "rep" },
            {
                Header: "Qté", accessor: "qty",
                Cell: (props) => <div className="display-flex">
                    <input type="number" className="form-control text-center" defaultValue={props.value}
                        onChange={p.onFieldChange} onBlur={p.onFieldBlur} data-key={props.row.original.parent_key}
                        data-table="" data-field="qty" data-id={props.row.original.id}
                        readOnly={props.row.original.qty_changing}></input>
                    {props.row.original.qty_changing && <button className="btn"><ButtonWaiting /></button>}
                </div>
            },
            { Header: "Désignation FR", accessor: "french_label" },
            {
                Header: "Référence", accessor: "ref",
                Cell: (props) => <div className="display-flex">
                    <input className="form-control" defaultValue={props.value} onChange={p.onFieldChange}
                        onBlur={p.onFieldBlur} style={{ minWidth: "250px" }} data-key={props.row.original.parent_key} title={props.value}
                        data-table="" data-field="ref" data-id={props.row.original.id}></input>
                    {props.row.original.ref_changing && <button className="btn"><ButtonWaiting /></button>}
                </div>
            },
            {
                Header: "Fabricant", accessor: "maker",
                Cell: (props) => <div className="display-flex">
                    <input className="form-control" defaultValue={props.value} onChange={p.onFieldChange}
                        onBlur={p.onFieldBlur} data-key={props.row.original.parent_key} title={props.value}
                        data-table="" data-field="maker" data-id={props.row.original.id}></input>
                    {props.row.original.maker_changing && <button className="btn"><ButtonWaiting /></button>}
                </div>
            },
            {
                Header: "Code article", accessor: "ar",
                filterFn: 'strWithEmpty',
                Cell: (props) => <div className="display-flex">
                    <input className="form-control" defaultValue={props.value} onChange={p.onFieldChange}
                        onBlur={p.onFieldBlur} data-key={props.row.original.parent_key} title={props.value}
                        data-table="" data-field="ar" data-id={props.row.original.id}
                        style={{ minWidth: "150px" }}></input>
                    {props.row.original.ar_changing && <button className="btn"><ButtonWaiting /></button>}
                </div>
            },
            { Header: "Stock", accessor: "stock_at_term" },
            {
                Header: "Coms", accessor: "OBSES",
                Cell: props => (props.value && props.value !== null && props.value.trim() !== "") ?
                    <img title={props.value} src={"/common/tooltip.png"}></img> : ""
            },
        ], []
    );

    return <Fragment>
        
        <Table columns={columns} data={p.list} />
    </Fragment>

}

export default NoNeedTab;