
const SparesHelp = () => {
    return <div className="col-10 offset-1 mt-3">
        <h5>Lignes affichées</h5>
        <p className="ms-4">
            Les lignes affichées proviennent de Focus uniquement. Si vous ne trouvez pas votre affaire ou
            s'il manque une ligne, vérifiez que les BL aient bien été renseignés sur Focus ou
            essayez de les retrouver à l'aide d'un filtre permanent.<br></br>
            Sont affichés dans le tableau de facturation des spares toutes les affaires répondants aux conditions suivantes:
            <ul>
                <li>Le code marketing est "SAV"</li>
                <li>Le sous-code marketing a pour code une des valeurs suivantes: 000, 110, 111, 112</li>
                <li>Le code affaire se termine epar un zéro</li>
                <li>L'affaire a été créée après le 1er janvier 2021</li>
                <li>L'affaire n'est pas clôturée</li>
            </ul>
        </p>

        <p>Contrairement au tableau de facturation de services, les spares sont reliés aux BL et non
            aux échéanciers des affaires. De ce fait, il y a autant de ligne qu'il y a de BL de créé
            sur une affaire. S'il y a deux échéanciers mais qu'un seul BL, une seule ligne ne s'affichera.</p>

        <p>Pour des raisons pratiques, nous avons forcé le lien entre les échéanciers et les BL.
            Si un nouveau BL est créé, le soir, une application va compter le nombre de BL existants
            et va numéroter le BL. Exemple: Sur une affaire X, il y a déjà 1 BL, le BL suivant sera
            numéroté 2. Ainsi, le BL 1 sera rattaché à la première ligne de l'échéancier et le BL 2
            sera rattaché au second échéancier.</p>
        <p>La liaison est faite en priorité sur le BL de sorte que si le second échéancier n'a pas été créé,
            la ligne s'affiche mais sans qu'une date de facturation prévue ne soit affichée.</p>

        <h5>Colonnes affichées</h5>
        <p className="ms-4">
            Vous pouvez choisir les colonnes à masquer, de même que leurs ordonnancements en cliquant en haut
            à droite du tableau sur l'icône:
            <img src="/common/settings.png" className="ms-2" style={{ maxWidth: "40px" }}></img>
        </p>

        <h5>Codes couleurs</h5>
        <p className="display-flex ms-4">
            <div className="red-td-bg p-1 text-white rounded text-center me-1"
                style={{ width: '70px' }}>Rouge</div>
            <div>
                Ligne complète en rouge = l'application de nuit n'a pas
                su lier la facturation au BL.
                <br></br>
                <span>3 colonnes = </span>
                <ul>
                    <li>le BL est partiel, il reste des choses à livrer</li>
                    <li>OU le montant du marché est erroné</li>
                </ul>
            </div>
        </p>

        <h5>Filtres permanents</h5>
        <p className="ms-4">
            Contrairement aux filtres que vous pouvez faire sur l'en-tête du tableau, il s'agit d'un filtre
            qui est sauvegardé pour vos futures visites. Il est également plus précis.
            <br></br>
            Pour enregistrer un filtre choisissez le champ sur lequel vous voulez filtrer, puis la condition
            qui doit s'appliquer pour afficher les enregistrements. Enfin cliquez sur "Ajouter". Une fois vos filtres
            paramétrés, cliquez sur "Actualiser la liste" pour mettre à jour le tableau avec vos filtres.
        </p>
        <p>
            <div className="fw-bold text-decoration-underline fst-italic">Exemples de filtres:</div>
            <ul>
                <li>
                    Afficher les retards de facturation:
                    <img src="/doc/bill_late.png"></img>
                </li>
                <li>
                    Afficher les retards de paiement:
                    <img src="/doc/payment_late.png"></img>
                </li>
            </ul>
        </p>
    </div>
}

export default SparesHelp;