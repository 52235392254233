import { Fragment } from "react";
import { getHeight } from "../../functions/PageAnimation";

const MachineInReserve = (props) => {

    return <div id="monday" style={{height: "100%"}}>
        <iframe src="https://view.monday.com/embed/1632481470-c1f19835555ea57772a867e00c1b278f?r=use1"
            style={{ border: "0", width: "100%", height: getHeight("monday") }}></iframe>
    </div >

}

export default MachineInReserve;