import { Fragment, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { getDeviceType, scrollToTop } from "../../functions/PageAnimation";
import getUrlParam from "../../functions/StrFunctions";
import errorManagement from "../../services/errorManagement";
import ticketsDao from "../../services/qhse/ticketsDao";
import CheckConnDao from "../../services/common/CheckConnDao";


const VisitForm = (props) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const [phone, setPhone] = useState();
    const [visitors, setVisitors] = useState([""]);
    const [meetingRoom, setMeetingRoom] = useState();
    const [meetingRoomLoc, setMeetingRoomLoc] = useState();
    const [arrival, setArrival] = useState();
    const [departure, setDeparture] = useState();
    const [company, setCompany] = useState();
    const [meal, setMeal] = useState();
    const [comments, setComments] = useState();
    const [particularMeal, setParticularMeal] = useState();
    const [nbMeal, setNbMeal] = useState();
    const [typeVisitor, setTypeVisitor] = useState();
    const [motif, setMotif] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);

    const [id, setId] = useState(false);


    useEffect(() => {
        CheckConnDao.internal().then(
            (response) => {},
            (error) => {errorManagement.handleError(props, error);});


        //get user number if exists
        setPhone(user && (user.phone_nb === null ? "" : user.phone_nb) );

        //Check if there is a ID you'll update
        var id = getUrlParam(props, "id");
        setId(id);

        if (id !== null) {
            ticketsDao.getTicket(id).then(
                (response) => {
                    setLoading(false);
                    setPhone(response.tel);
                    setArrival(response.arrival);
                    setDeparture(response.departure);
                    var arr = response.visitors.split(";");
                    setVisitors(arr.slice(0, arr.length - 1));
                    setCompany(response.company);
                    setMeetingRoom(response.meeting);
                    setMeetingRoomLoc(response.meeting_location);
                    setMeal(response.meal);
                    setNbMeal(response.nb_meal);
                    setParticularMeal(response.particular_meal);
                    setComments(response.comments);
                    setTypeVisitor(response.typeVisitor);
                    setMotif(response.motif);
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                    setLoading(false);
                }
            );
        } else {
            setLoading(false);
        }
    }, []);

    const onDepartureChange = (e) => {
        setDeparture(e.target.value);
    }

    const onArrivalChange = (e) => {
        setArrival(e.target.value);
    }

    const addVisitor = () => {
        var arr = [...visitors];
        arr.push("");
        setVisitors(arr);
    }

    const removeVisitor = (e) => {
        var key = parseInt(e.target.getAttribute("data-key"));
        var arr = [...visitors];
        arr = arrayRemoveElt(arr, key);
        setVisitors(arr);
    }

    const updateVisitor = (e) => {
        var key = parseInt(e.target.getAttribute("data-key"));
        var value = e.target.value;
        var arr = [...visitors];
        arr[key] = value;
        setVisitors(arr);
    }

    const updateMeetingRoom = (e) => {
        var value = parseInt(e.target.value);
        setMeetingRoom(value)
    }

    const onCompanyChange = (e) => {
        setCompany(e.target.value)
    }

    const onMeetingRoomLoc = (e) => {
        setMeetingRoomLoc(e.target.value)
    }

    const onMealChange = (e) => {
        setMeal(parseInt(e.target.value));
    }

    const onParticularMealChange = (e) => {
        setParticularMeal(e.target.value);
    }

    const onCommentChange = (e) => {
        setComments(e.target.value);
    }

    const onNbMealChange = (e) => {
        setNbMeal(e.target.value);
    }

    const onMotifChange = (e) => {
        setMotif(e.target.value);
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setError();
        if (!isValidPhoneNumber(phone)) {
            setError("Merci de saisir un numéro de téléphone valide.");
            scrollToTop();
            return;
        }
        
        var a = new Date(arrival);
        var d = new Date(departure);
        var current = new Date();

        if( d.getTime() < a.getTime() ){
            setError("La date de départ ne peut pas être antérieure à la date d'arrivée.");
            scrollToTop();
            return;
        }else if( d.getTime() < current.getTime() ){
            setError("La date ou l'heure de départ est antérieure à la date du jour.");
            scrollToTop();
            return;
        }
        
        setLoading(true);

        //Save the phone number
        user.phone_nb = phone;
        localStorage.setItem("user", JSON.stringify(user));

        if (id === null) {
            ticketsDao.saveVisit(phone, arrival, departure, visitors, company,
                meetingRoom, meetingRoomLoc, meal, particularMeal, comments, nbMeal, motif, typeVisitor).then(
    
                    (response) => {
                        window.location.href = "/qhse/visit/list";
                    },
                    (error) => {
                        setError(errorManagement.handleError(props, error));
                        scrollToTop();
                        setLoading(false);
                    }
    
                );
        }else{
            ticketsDao.saveUpdateVisit(phone, arrival, departure, visitors, company,
                meetingRoom, meetingRoomLoc, meal, particularMeal, comments, nbMeal, motif, typeVisitor, id).then(
    
                    (response) => {
                        window.location.href = "/qhse/visit/list";
                    },
                    (error) => {
                        setError(errorManagement.handleError(props, error));
                        scrollToTop();
                        setLoading(false);
                    }
    
                );
        }
    }

    const cancelVisit = (e) => {
        e.preventDefault();

        confirmAlert({
            title: "Annulation de visite",
            message: "Etes-vous sûr de vouloir annuler cette visite ?",
            buttons: [
            {
                label: "Oui",
                onClick: () => {
                    setLoading(true);
                    ticketsDao.cancelVisit(phone, arrival, departure, visitors, company,
                        meetingRoom, meetingRoomLoc, meal, particularMeal, comments, nbMeal, id).then(
            
                            (response) => {
                                window.location.href = "/qhse/visit/list";
                            },
                            (error) => {
                                setError(errorManagement.handleError(props, error));
                                scrollToTop();
                                setLoading(false);
                            }
            
                        );
                }
            },
            {
                label: "Non"
            }
        ]});
    }

    return (
        <div className={getDeviceType() === "desktop" ? "col-6 offset-3" : ""}>
            <h2 className="text-center">Nouvelle visite</h2>

            {error && <ErrorMessage error={error} />}

            {loading && <WaitingRoundSnippers />}

            {
                !loading &&

                <form onSubmit={onFormSubmit}>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Votre nom et prénom</span>
                        <input type="text" className="form-control" readOnly
                            defaultValue={user && (user.last_name + ' ' + user.first_name)} required />
                    </div>

                    <div style={{ display: "flex", width: "100%", marginBottom: "1rem" }}>
                        <span className="input-group-text spanPhone" id="basic-addon1">Votre téléphone</span>
                        <PhoneInput
                            international
                            defaultCountry="FR"
                            placeholder="Enter phone number"
                            value={phone}
                            onChange={setPhone} required />
                    </div>


                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Date et heure d'arrivée</span>
                        <input type="datetime-local" className="form-control"
                            value={arrival} onChange={onArrivalChange} required />
                    </div>

                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Date et heure de départ</span>
                        <input type="datetime-local" className="form-control"
                            value={departure} onChange={onDepartureChange} required />
                    </div>

                    {
                        visitors.map(
                            (v, k) => {
                                return (
                                    <div className="input-group mb-3" key={"visitor" + k}>
                                        <span className="input-group-text" id="basic-addon1">{"Visiteur " + (k + 1)}</span>
                                        <input type="text" className="form-control" value={v} onChange={updateVisitor} data-key={k} required />
                                        {
                                            k > 0 &&
                                            <img src={"/common/remove_icon.png"} onClick={removeVisitor} data-key={k}
                                                style={{ width: "30px", height: "30px", margin: "auto" }}></img>
                                        }
                                    </div>)
                            }
                        )
                    }
                    <img src={"/common/add-logo.png"} style={{ width: "30px", marginBottom: "1rem" }}
                        title="Ajouter un visiteur" onClick={addVisitor}></img>

                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Entreprise des visiteurs</span>
                        <input type="text" className="form-control"
                            value={company} onChange={onCompanyChange} required />
                    </div>

                    <div className="input-group mb-3">
                        <label className="input-group-text" for="inputGroupSelect01">Type de visiteur</label>
                        <select className="form-select" onChange={(e) => setTypeVisitor(e.target.value)} value={typeVisitor} required>
                            <option value="">Choisir...</option>
                            <option value="Intérimaire & stagiaire">Intérimaire & stagiaire</option>
                            <option value="Client">Client</option>
                            <option value="Employé SEE">Employé SEE</option>
                            <option value="Fournisseur/entreprise extérieure">Fournisseur/entreprise extérieure</option>
                        </select>
                    </div>

                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Motif</span>
                        <input type="text" className="form-control"
                            value={motif} onChange={onMotifChange} required />
                    </div>

                    <div className="input-group mb-3">
                        <label className="input-group-text" for="inputGroupSelect01">Salle de réunion ?</label>
                        <select className="form-select" onChange={updateMeetingRoom} required>
                            <option value="">Choisir...</option>
                            <option value="1" selected={meetingRoom === 1}>Oui</option>
                            <option value="0" selected={meetingRoom === 0}>Non</option>
                        </select>
                    </div>

                    {
                        (meetingRoom !== null && meetingRoom === 1) &&
                        <div className="col-11 offset-1">
                            <div className="input-group mb-3">
                                <span className="input-group-text">Laquelle ?</span>
                                <select className="form-select" onChange={onMeetingRoomLoc} required={meetingRoom && meetingRoom === 1}>
                                    <option value="">Choisir...</option>
                                    <option value="Calanques" selected={meetingRoomLoc === "Calanques"}>Calanques</option>
                                    <option value="B+ site 2" selected={meetingRoomLoc === "B+ site 2"}>B+ site 2</option>
                                    <option value="Cigale" selected={meetingRoomLoc === "Cigale"}>Cigale</option>
                                    <option value="Restanque" selected={meetingRoomLoc === "Restanque"}>Restanque</option>
                                    <option value="Salle Lavande" selected={meetingRoomLoc === "Salle Lavande"}>Salle Lavande</option>
                                    <option value="Salle Mistral" selected={meetingRoomLoc === "Salle Mistral"}>Salle Mistral</option>
                                    <option value="Salle Provence" selected={meetingRoomLoc === "Salle Provence"}>Salle Provence</option>
                                </select>
                            </div>
                        </div>
                    }

                    <div className="input-group mb-3">
                        <label className="input-group-text" for="inputGroupSelect01">Plateau repas ?</label>
                        <select className="form-select" onChange={onMealChange} required>
                            <option value="">Choisir...</option>
                            <option value="1" selected={meal === 1}>Oui</option>
                            <option value="0" selected={meal === 0}>Non</option>
                        </select>
                    </div>

                    {
                        (meal !== null && meal === 1) &&
                        <Fragment>
                            <div className="col-11 offset-1">
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">Nombre de plateau</span>
                                    <input type="number" className="form-control" required={meal && meal === "1"}
                                        value={nbMeal} onChange={onNbMealChange} />
                                </div>
                            </div>

                            <div className="col-11 offset-1">
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">Régime particulier</span>
                                    <input type="text" className="form-control"
                                        value={particularMeal} onChange={onParticularMealChange} />
                                </div>
                            </div>
                        </Fragment>

                    }

                    <div className="input-group mb-3">
                        <div className="input-group">
                            <span className="input-group-text">Commentaires</span>
                            <textarea className="form-control" value={comments} onChange={onCommentChange}></textarea>
                        </div>
                    </div>

                    <div className="text-center mb-3">
                        {
                            id !== null && 
                                <button type="buton" className="btn btn-danger" onClick={cancelVisit}>Annuler la visite</button>
                        }

                        <button type="submit" className="btn btn-success" style={{ marginLeft: "20%" }}>Valider</button>
                    </div>

                </form>
            }

        </div>)
}

export default VisitForm;