import { Fragment, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { ButtonWaiting } from "../../../functions/PageAnimation";
//import BomDao from "../../../services/machines/BomDao";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../../common/smallComponents.js/ReactTable";
import BomDaoV2 from "../../../services/machines/BomDaoV2";

const StdTab = (p) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const [working, setWorking] = useState(false);


    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('cat', {
            header: 'Catégorie',
            filterFn: 'selectMultiple',
            cell: i => <div className="display-flex">
                <input className="form-control" defaultValue={i.getValue()} //onChange={p.onFieldChange}
                    onBlur={p.onFieldBlur} data-key={i.row.parent_key} title={i.getValue()}
                    data-table="N/A" data-field="cat" data-id={i.row.original.id}
                    style={{ minWidth: "150px" }}></input>
                {i.row.original.cat_changing && <button className="btn"><ButtonWaiting /></button>}
            </div>
        }));

        arr.push(columnHelper.accessor('se', {
            header: 'S/E',
            cell: i => <div className="text-center fw-bold">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('rep', {
            header: 'Repère',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('qty', {
            header: 'Qté',
            cell: i => <div className="display-flex">
                <input type="number" className="form-control text-center" defaultValue={i.getValue()}
                    //onChange={p.onFieldChange} 
                    onBlur={p.onFieldBlur} data-key={i.row.original.parent_key}
                    data-table="" data-field="qty" data-id={i.row.original.id}
                    readOnly={i.row.original.qty_changing}></input>
                {i.row.original.qty_changing && <button className="btn"><ButtonWaiting /></button>}
            </div>
        }));

        arr.push(columnHelper.accessor('french_label', {
            header: 'Désignation FR',
            cell: i => <div>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('ref', {
            header: 'Référence',
            cell: i => <div className="display-flex">
                <input className="form-control" defaultValue={i.getValue()} //onChange={p.onFieldChange}
                    onBlur={p.onFieldBlur} style={{ minWidth: "250px" }} data-key={i.row.original.parent_key} title={i.getValue()}
                    data-table="" data-field="ref" data-id={i.row.original.id}></input>
                {i.row.original.ref_changing && <button className="btn"><ButtonWaiting /></button>}
            </div>
        }));

        arr.push(columnHelper.accessor('maker', {
            header: 'Fabricant',
            cell: i => <div className="display-flex">
                <input className="form-control" defaultValue={i.getValue()} //onChange={p.onFieldChange}
                    onBlur={p.onFieldBlur} data-key={i.row.original.parent_key} title={i.getValue()}
                    data-table="" data-field="maker" data-id={i.row.original.id}></input>
                {i.row.original.maker_changing && <button className="btn"><ButtonWaiting /></button>}
            </div>
        }));

        arr.push(columnHelper.accessor('ar', {
            header: 'Code article',
            filterFn: 'strWithEmpty',
            cell: i => <div>
                <div className="d-flex">
                <input className="form-control" defaultValue={i.getValue()} //onChange={p.onFieldChange}
                    onBlur={p.onFieldBlur} data-key={i.row.original.parent_key} title={i.getValue()}
                    data-table="" data-field="ar" data-id={i.row.original.id}
                    style={{ minWidth: "150px" }}></input>
                {i.row.original.ar_changing && <button className="btn"><ButtonWaiting /></button>}
                </div>
                {(i.row.original.artProposed !== null && i.row.original.artProposed !== "" )&&
                    <div className="d-flex">
                        Proposition: <a target="_blank" href={"/article/info/get?article=" + i.row.original.artProposed}>{i.row.original.artProposed}</a>
                    </div>}
                {i.row.original.ar_changing && <button className="btn"><ButtonWaiting /></button>}
            </div>
        }));

        arr.push(columnHelper.accessor('stock_at_term', {
            header: 'Stock',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('OBSES', {
            header: 'Coms',
            cell: i => (i.getValue() && i.getValue() !== null && i.getValue().trim() !== "") ?
                <img title={i.getValue()} src={"/common/tooltip.png"}></img> : ""
        }));

        return arr;
    }

    //Set columns
    const columns = getColumns();

    /**
     * Request article creation
     */
    const requestArtCreation = () => {
        confirmAlert({
            title: "Confirmation",
            message: "Etes-vous sûr de vouloir de demander la création de ces " + p.list.length + " article(s) ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        BomDaoV2.requestCreation(p.list, p.machine, "");
                        p.setMsg("En cours de traitement, un mail sera envoyé sous peu.");
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    /**
     * Search again the articles then refresh page
     */
    const searchArt = () => {
        setWorking(true);
        BomDaoV2.refreshArticleTableNoTemp(p.machine, p.typeBom).then((response) => { window.location.reload() })
    }

    return <Fragment>
        <div className="mb-3 text-center">
            <button className="btn btn-outline-success" disabled={working}
                onClick={searchArt}>
                {working && <ButtonWaiting />}
                Relancer la recherche
            </button>

            <button className="btn btn-outline-warning" disabled={working}
                onClick={requestArtCreation} style={{ marginLeft: "10px" }}>
                {working && <ButtonWaiting />}
                Transférer dans la demande de création d'AR
            </button>
        </div>



        <ReactTable columns={columns} data={p.list}
            origin="bom_content" classes={"no-padding"} top0={true} />
    </Fragment>

}

export default StdTab;