import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import FormList from "../../common/smallComponents.js/FormList";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import getUrlParam from "../../functions/StrFunctions";
import ArticleInfo from "../../services/articles/ArticleInfo";
import HtsDao from "../../services/customClient/HtsDao";
import errorManagement from "../../services/errorManagement";
import { compressFiles, downloadTempFile } from "../../services/functions/FilesProcess";

const HTS = (props) => {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState();
    const [menu, setMenu] = useState();
    const [form, setForm] = useState();
    const [date, setDate] = useState();
    const [article, setArticle] = useState();
    const [articleFound, setArticleFound] = useState(false);
    const [listOfArt, setListOfArt] = useState();
    const [msg, setMsg] = useState();
    const user = JSON.parse(localStorage.getItem("user"));
    const [readonly, setReadonly] = useState(user && user.roles && !user.roles.includes("ROLE_INTERNAL"));
    const [hstCode, setHstCode] = useState();
    const [classificationCode, setClassificationCode] = useState();
    const [lastClassNb, setLastClassNb] = useState();
    const [sending, setSending] = useState(false);
    const [addInfo, setAddInfo] = useState();
    const [attachments, setattachments] = useState([]);
    const [downloading, setdownloading] = useState(false);

    useEffect(() => {
        var article = getUrlParam(props, "article");

        if (article !== null) {
            setArticle(article);
            var d = getUrlParam(props, "date");
            getExistingForm(article, d);
            setDate(d);
        } else {
            newRequest();
        }

        HtsDao.getLastClassNbList().then(
            (response) => {
                setLastClassNb(response)
            }
        )


    }, []);

    /**
     * If no param create new request else update or read
     */
    const newRequest = () => {
        HtsDao.getForms().then(
            (response) => {
                setList(response);

                var arr = new Array();
                response.map(
                    v => { if (!arr.includes(v.chap_lab)) arr.push(v.chap_lab); }
                );

                setMenu(arr);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );
    }

    /**
     * If you want the existing form with answer
     * @param {*} article 
     * @param {*} date 
     */
    const getExistingForm = (article, date) => {
        HtsDao.getAnswers(article, date).then(
            (response) => {
                setForm(response.list);
                setClassificationCode(response.classificationCode);
                setattachments(response.attachments);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );
    }

    /**
     * Choose and display suitable form
     * @param {*} e 
     */
    const chooseForm = (e) => {
        const chap = e.target.value;
        var arr = new Array();

        //Select suitable questions
        list.map(v => { if (v.chap_lab === chap) arr.push(v) });
        setForm(arr);


        //Set classification form
        var found = false;
        lastClassNb.map(v => {
            if (v.label === chap) {
                setClassificationCode(v.label.replaceAll(" ", "").toUpperCase().substring(0, 4) + (parseInt(v.last_nb) + 1));
                found = true;
                return;
            }
        });

        if (!found)
            setClassificationCode(chap.replaceAll(" ", "").toUpperCase().substring(0, 4) + "1");
    }

    /**
     * Save the form
     * @param {*} e 
     */
    const submit = () => {
        setError();
        setMsg();

        if (article === null || article === undefined || article === "") {
            setError("Please fill in the article !");
            scrollToTop();
            return;
        }

        if (!classificationCode || classificationCode.trim().length === 0) {
            setError("Please fill in the classification code !");
            scrollToTop();
            return;
        }

        for( let i=0; i<attachments.length; i++ ){
            var o = attachments[i];
            if( !o.file || o.com.trim() === "" ){
                console.log(o)
                setError("Please complete the attachments info or remove the row");
                scrollToTop();
                return;
            }
        }

        setLoading(true);

        HtsDao.saveRequest(form, article, classificationCode).then(
            (response) => {
                saveAttachments();

                setMsg("Saved !");
                scrollToTop();
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );

        
    }

    function saveAttachments(){
        var files = [], coms = [];

        for(let i=0; i<attachments.length; i++){
            if( !attachments[i].form ){
                files.push( attachments[i].file );
                coms.push( attachments[i].com );
            }            
        }

        HtsDao.saveAttachment(files, coms, article);
    }

    /**
     * Set article
     * 
     * @param {*} e 
     */
    const onChangeArticle = (e) => {
        setArticleFound(false);
        setForm();
        setArticle(e.target.value.toUpperCase());
    }

    /**
     * Check if article exists
     */
    const checkArticle = (e) => {
        e.preventDefault();
        setLoading(true);
        setListOfArt();
        setArticleFound(false);
        setError();

        ArticleInfo.articlesInfo(article).then(
            (response) => {

                if (response.length === 0) {
                    setError("Article not found !");
                } else if (response.length === 1) {
                    setArticle(response[0].CDARL);
                    setArticleFound(true);
                } else {
                    setListOfArt(response);
                }

                setLoading(false);
            },
            (error) => {
                setLoading(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );
    }

    /**
     * Choose the article among the returned list
     * @param {*} e 
     */
    const chooseArt = (e) => {
        const ar = e.target.getAttribute("data-ar").trim();
        setListOfArt();
        setArticle(ar);
        setArticleFound(true);
    }

    /**
     * Create the HST code
     */
    const createHstCode = (e) => {
        e.preventDefault();

        if (hstCode.trim() === "") {
            setError("Please fill in the HST code at the end of the page");
            scrollToTop();
            return;
        }
        setLoading(true);

        HtsDao.updateHtsCode(article, date, hstCode, attachments).then(
            (response) => {
                setMsg("Saved !");
                scrollToTop();
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );
    }

    /**
     * 
     * @param {*} e 
     */
    const onHstCodeChange = (e) => {
        setHstCode(e.target.value);
    }

    const onClassificationCodeChange = (e) => {
        setClassificationCode(e.target.value);
    }

    const requestAddInfo = () => {
        setError();
        if (!addInfo || addInfo.trim().length === 0) {
            setError("Please indicate what additional information is required.");
            scrollToTop();
            return;
        }

        setSending(true);

        HtsDao.requestAddInfo(article, date, addInfo).then(
            (response) => {
                setMsg("Email sent !");
                scrollToTop();
                setSending(false);
            },
            (error) => {
                setSending(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );

    }

    const onAddInfoChange = (e) => {
        setAddInfo(e.target.value);
    }

    const addAttachment = () => {
        var arr = [...attachments];
        arr.push({ file: null, com: "" });
        setattachments(arr);
    }
    
    const deleteAttachment = (k) => {
        //If already saved delete completely
        if( attachments[k].id ) HtsDao.deleteAttachmentById( [attachments[k]] )
        

        var arr = arrayRemoveElt(attachments, k);
        setattachments(arr);
    }

    const updateAttachment = (e, k, type) => {
        var arr = [...attachments];

        if( type === "input" ){
            arr[k].com = e.target.value;
        }else{
            arr[k].file = e.target.files[0];
        }

        setattachments(arr);
    }

    const openAttachment = () => {
        setdownloading(true);
        var arr = [];
        for(let i=0; i<attachments.length; i++) arr.push(attachments[i].file);
        
        HtsDao.getAttachments(article, date).then(
            (response) => {
                downloadTempFile(response);
                setdownloading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setdownloading(false);
            }
        )
    }

    return (
        <div>

            {error && <ErrorMessage error={error} />}

            {loading && <WaitingRoundSnippers />}

            {msg && <SuccessMessage msg={msg} />}

            {
                menu &&
                <Fragment>
                    <form onSubmit={checkArticle}>
                        <div className="input-group mb-3">

                            <span className="input-group-text" id="basic-addon1">Article</span>
                            <input type="text" className="form-control text-uppercase text-center fw-bold" value={article} onChange={onChangeArticle} />
                            <button type="submit" className="btn btn-success" disabled={loading}>
                                {loading && <span className="spinner-border spinner-border-sm"></span>}
                                Check
                            </button>

                            {
                                articleFound &&
                                <Fragment>
                                    <span className="input-group-text">Form</span>
                                    <select className="form-select text-center fw-bold" onChange={chooseForm}>
                                        <option>Choose...</option>
                                        {
                                            menu.map((v, k) => {
                                                return <option key={"menu_" + k} value={v}>{v}</option>
                                            })
                                        }
                                    </select>
                                </Fragment>
                            }

                        </div>
                    </form>
                </Fragment>

            }

            {
                listOfArt &&
                <Fragment>
                    <h2 className="text-center">Multiples rows found. Choose among them.</h2>
                    <ul className="list-group">
                        {
                            listOfArt.map(
                                (v, k) => {
                                    return <li className="list-group-item" key={"frm_" + k}>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" value={v.CDARL} readOnly />
                                            <input type="text" className="form-control" value={v.ZN02PA} readOnly />
                                            <button type="button" className="btn btn-secondary"
                                                data-ar={v.CDARL} onClick={chooseArt}>Choose</button>
                                        </div>
                                    </li>
                                }
                            )
                        }
                    </ul>
                </Fragment>
            }

            {
                (!listOfArt && form) && <h2 className="text-center">{form[0].mach_type}</h2>
            }

            {
                form &&
                <Fragment>
                    <div className="col-6 offset-3">
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Classification Form Number</span>
                            <input type="text" className="form-control" value={classificationCode} readOnly={readonly}
                                onChange={onClassificationCodeChange} />
                        </div>
                    </div>

                    { (attachments && attachments.length > 0 ) && <div className="card" style={{width: "800px"}}>
                        <h6 className="text-center">Attachments</h6>
                        <ul className="list-group mb-3">
                            {attachments.map((v, k)=>{
                                return v.form && <li className="list-group-item" key={"att"+k}>
                                    <div><u className="me-1">File name:</u> {v.file_name}</div>
                                    <div><u className="me-1">Comment:</u> {v.com}</div>
                                </li>
                            })}
                        </ul>
                        <div className="text-center">
                        <button className="btn btn-secondary ms-auto" onClick={openAttachment} disabled={downloading}>
                            {downloading && <ButtonWaiting/>}
                            Download</button>
                        </div>
                    </div>}

                    <FormList form={form} onChange={setForm} readonly={readonly}></FormList>
                    <br></br>
                    {
                        !readonly &&
                        <Fragment>
                            <div className="text-center">

                                <div className="card">
                                    <h5 className="text-center">Joindre des fichiers</h5>

                                    {attachments.map((v, k) => {
                                        return <div className="input-group mb-3">
                                            <input type="file" className="form-control" onChange={(e)=>updateAttachment(e, k, "file")}/>
                                            <label className="input-group-text">Commentaire ou légende*</label>
                                            <input className="form-control"  onChange={(e)=>updateAttachment(e, k, "input")}
                                                value={v.com}/>
                                            <button className="btn btn-outline-danger"
                                                onClick={()=>deleteAttachment(k)}>Retirer</button>
                                        </div>
                                    })}

                                    <div>
                                        <button className="btn btn-secondary"
                                            onClick={addAttachment}>Ajouter</button>
                                    </div>
                                </div>

                                <button type="button" onClick={submit} className="btn btn-success" disabled={loading}>
                                    {loading && <span className="spinner-border spinner-border-sm"></span>}
                                    Submit
                                </button>
                            </div>
                            <br></br>
                            <br></br>
                        </Fragment>
                    }

                    {
                        (user && user.roles && (user.roles.includes("ROLE_HST_CREATION") || user.roles.includes("ROLE_ADMIN")) ) &&
                        <div style={{ display: "flex", width: "100%" }}>

                            <div style={{ width: "40%" }}>
                                <div className="form-floating">
                                    <textarea className="form-control" value={addInfo} onChange={onAddInfoChange}></textarea>
                                    <label for="floatingTextarea2">Need additionals informations</label>
                                </div>
                                <br></br>
                                <div className="text-center">
                                    <button className="btn btn-success" disabled={sending} onClick={requestAddInfo}>
                                        {sending && <span className="spinner-border spinner-border-sm"></span>}
                                        Send
                                    </button>
                                </div>
                            </div>
                            <div style={{ width: "10%" }} className="text-center fw-bold">OR</div>
                            <div style={{ width: "40%" }}>
                                <form onSubmit={createHstCode}>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text">HST code</span>
                                        <input type="text" className="form-control" placeholder="Write here the HST code"
                                            onChange={onHstCodeChange} value={hstCode} />
                                        <button type="submit" className="btn btn-success" disabled={loading}>
                                            {loading && <span className="spinner-border spinner-border-sm"></span>}
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    }
                    <br></br>
                </Fragment>
            }
        </div>
    )
}

export default HTS;