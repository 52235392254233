import { Fragment, useEffect, useState } from "react";
import parse from "paste-from-excel";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import NeedsMngt from "../../services/articles/NeedsMngt";
import errorManagement from "../../services/errorManagement";
import contactsDao from "../../services/settings/contactsDao";
import isEmail from "validator/lib/isEmail";

const CreateNeeds = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [copies, setCopies] = useState([]);
    const [contacts, setContacts] = useState();
    const [com, setCom] = useState("");

    const [label, setlabel] = useState({
        labels: [
            "Articles*", "Affaires*", "Machines*", "S/E*", "Rep*", "Qté*"]
    });
    const [inputvalue, setinputvalue] = useState({
        inputs: []
    });
 
    /**
     * Convert Excel copy to json
     * 
     * @param {*} index 
     * @param {*} elm 
     * @param {*} e 
     * @param {*} i 
     * @returns 
     */
    const handlePaste = (index, elm, e, i) => {
        return parse(e);
    };

    /**
     * Copy from values in input
     * @param {*} index 
     * @param {*} elm 
     * @param {*} e 
     * @param {*} i 
     */
    const handlePaste1 = (index, elm, e, i) => {

        setinputvalue((inputvalue) => ({
            ...inputvalue,
            inputs: inputvalue.inputs.map((item, i) =>
                index === i
                    ? {
                        ...item,
                        [elm]: e.target.value
                    }
                    : item
            )
        }));
    };

    useEffect(() => {

        // getContacts
        contactsDao.getAllUsersEmails().then(
            (response) => {
                setContacts(response)
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        )

        //Set 1000 rows
        resetTable();
    }, []);

    /**
     * Clear table
     */
    const resetTable = () => {
        var arr = new Array();
        for (let i = 0; i < 1000; i++) {
            arr.push({
                "Articles*": "",
                "Affaires*": "",
                "Machines*": "",
                "S/E*": "",
                "Rep*": "",
                "Qté*": ""
            });
        }
        setinputvalue({ inputs: arr })
    }

    /**
     * Get type of field for input
     * @param {*} elm 
     * @returns 
     */
    const typeOfField = (elm) => {
        elm = elm.toUpperCase();

        if (elm.indexOf("DATE") !== -1) {
            return "date";
        } else if (elm.indexOf("QTÉ") !== -1) {
            return "number";
        } else {
            return "text";
        }
    }

    /**
     * Extends values until last row
     */
    const extendValues = (row, elm, e, column) => {
        var value = e.target.value;
        if (value.trim() === "") return;

        var maxRow = 0;
        inputvalue.inputs.map((res, index) => {
            for (const key in res) {
                if (res[key].trim() !== "") {
                    maxRow++;
                    break;
                }
            }
        })

        var arr = [...inputvalue.inputs];
        for (let i = row; i < maxRow; i++)
            arr[i][elm] = value;


        setinputvalue({ inputs: arr })
    }

    /**
     * Remove one row
     * @param {*} e 
     */
    const removeRow = (e) => {
        var key = e.target.getAttribute("data-key");
        var arr = [...inputvalue.inputs];
        arr = arrayRemoveElt(arr, key);
        setinputvalue({ inputs: arr })
    }

    /**
     * Control and send
     */
    const validate = () => {
        setLoading(true);
        setError();
        var arr = [...inputvalue.inputs];
        var err = "";

        var maxRow = 0;
        inputvalue.inputs.map((res, index) => {
            for (const key in res) {
                if (res[key].trim() !== "") {
                    maxRow++;
                    break;
                }
            }
        })

        //Check at least one row is completed
        if (maxRow === 0) err = "Vous devez compléter au moins une ligne !";

        // Check if mandatories fields are filled in
        for (let i = 0; i < maxRow; i++) {
            if (arr[i]["Affaires*"].trim() === "" || arr[i]["Articles*"].trim() === "" ||
                arr[i]["Machines*"].trim() === "" || arr[i]["Rep*"].trim() === "" ||
                arr[i]["Qté*"].trim() === "") err += "Ligne " + (i + 1) + " incomplète.<br>";
        }

        //Check if emails are correctly written
        for( let i=0; i<copies.length; i++ )
            if(!isEmail(copies[i])) err += "\"" + copies[i] + "\" n'est pas une adresse mail valide.<br>";
        
        //Check if comments are written
        if( com.trim().length === 0 ) err += "Merci de saisir la raison de ces ajouts dans la zone commentaire.<br>";

        if (err !== "") {
            setLoading(false);
            setError(err);
            setMsg();
            scrollToTop();
            return;
        }

        arr = arr.slice(0, maxRow);

        // Check before insertion
        setMsg("Vérifications en cours. Veuillez patienter");
        scrollToTop();

        NeedsMngt.checkFreeNeeds(arr).then(
            (response) => {
                setMsg("Ok pas d'erreur détecté, sauvegarde en cours... Vous recevrez par mail en fin de traitement");
                save(arr, copies, com, response.subset, response.articles);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setMsg();
                scrollToTop();
                setLoading(false);
            }
        )

    }

    const save = (arr, copies, com, subset, articles) => {
        NeedsMngt.createFreeNeed(arr, copies, com, subset, articles).then(
            () => {
                setMsg("Ok besoins envoyés");
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setMsg();
                setLoading(false);
            }
        )
    }

    /**
     * add copy
     */
    const addCopy = () => {
        var arr = [...copies];
        arr.push("");
        setCopies(arr);
    }

    /**
     * Remove copy
     */
    const removeCopy = (e) => {
        var key = e.target.getAttribute("data-key");
        var arr = [...copies];
        arr = arrayRemoveElt(arr, key);
        setCopies(arr)
    }

    /**
     * On mail change
     */
    const onMailChange = (e) => {
        var key = e.target.getAttribute("data-key");
        var arr = [...copies];
        arr[key] = e.target.value;
        setCopies(arr);
    }

    return (
        <Fragment>
            <h3 className="text-center">Ajout de besoins</h3>

            <div className="accordion col-6 offset-3" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header text-center" id="headingOne">
                        <button className="accordion-button fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Indications
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <ul>
                                <li>Vous pouvez copier/coller les cellules d'un fichier Excel sur ce tableau.</li>
                                <li>Vous pouvez copier plusieurs colonnes si elles sont côte à côte ou une seule colonne à la fois.</li>
                                <li><i><b>Attention:</b> pour copier les dates sur ce tableau, elles devront être au format américain (yyyy-mm-dd) sur Excel</i></li>
                                <li>En double-cliquant sur une case vous dupliquez la valeur de celle-ci jusqu'à la dernière ligne remplie.</li>
                                <li> Le bouton Réinitialiser le tableau efface toutes les valeurs du tableau.</li>
                                <li>Les colonnes: Articles, Affaires, S/E, Rep, Qté et le commentaire doivent obligatoirement être remplies.</li>
                                <li>Pour lancer la requête d'achat / sorties de stock cliquez sur le bouton "Valider la demande"</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <br></br>

            {error && <ErrorMessage error={error} errorState={setError} />}
            {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

            <div className="text-center">
                <button type="button" className="btn btn-outline-danger"
                    onClick={resetTable} disabled={loading}>
                    {loading && <ButtonWaiting />}
                    Réinitialiser le tableau
                </button>
                <button type="button" className="btn btn-outline-success" style={{ marginLeft: "20px" }}
                    onClick={validate} disabled={loading}>
                    {loading && <ButtonWaiting />}
                    Valider la demander
                </button>
            </div>
            <br></br>

            <div style={{ display: "flex" }}>

                <div style={{ width: "50%" }}>
                    <h5 className="text-center">Destinataires (optionnel)</h5>
                    {!contacts && <div><ButtonWaiting />Chargement des contacts...</div>}
                    {contacts &&
                        <Fragment>
                            {copies.map((v, k) => {
                                return (<div className="input-group mb-3">
                                    <input type="mail" className="form-control" data-key={k} 
                                        onChange={onMailChange}
                                        list="contacts" placeholder="Adresse mail" value={v}/>
                                    <img src={"/common/remove_icon.png"} onClick={removeCopy}
                                        style={{ width: "30px" }} data-key={k}></img>
                                </div>)
                            })}
                            <img src={"/common/add-logo.png"} onClick={addCopy}></img>
                        </Fragment>
                    }
                    
                    <br></br><br></br>
                </div>

                <div style={{ width: "50%" }}>
                    <h5 className="text-center">Commentaires (raison de ces ajouts)</h5>
                    <textarea className="form-control" value={com} onChange={(e) => setCom(e.target.value)}></textarea>
                </div>
            </div>

            <table className="table table-striped">
                <thead>
                    <tr className="text-center table-info fixed-header">
                        <th>#</th>
                        {label.labels.map((elm, ind) => {
                            return (
                                <th>
                                    {elm}
                                </th>
                            );
                        })}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {inputvalue.inputs.map((res, index) => {
                        return (
                            <tr key={index}>
                                <th scope="row" className="text-center">{index + 1}</th>
                                {label.labels.map((elm, i) => {
                                    return (
                                        <Fragment>
                                            <td>
                                                <input
                                                    onInput={(e) => {
                                                        handlePaste1(index, elm, e, i);
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePaste(index, elm, e, i);
                                                    }}
                                                    onDoubleClick={(e) => {
                                                        extendValues(index, elm, e, i);
                                                    }}
                                                    type={typeOfField(elm)}
                                                    className="form-control"
                                                    value={res[elm]}
                                                />
                                            </td>
                                        </Fragment>
                                    );

                                })}
                                <td>
                                    <img src={"/common/remove_icon.png"} onClick={removeRow}
                                        style={{ width: "30px" }} data-key={index}></img>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            {
                contacts &&
                <datalist id="contacts">
                    {contacts.map((v, k) => <option key={"c" + k} value={v} />)}
                </datalist>
            }


        </Fragment>
    )

}

export default CreateNeeds;