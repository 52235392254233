import { Fragment, useState } from "react";
import { removeAndReturnArray } from "../../../../functions/ArrayFunctions";
import { getHeight } from "../../../../functions/PageAnimation";

const FocusPicking = (p) => {
    const [cursor, setCursor] = useState("");
    const [toAdd, setToAdd] = useState();
    const [list, setList] = useState([]);
    const [skeleton, setSkeleton] = useState([]);
    const [type, setType] = useState("container");

    const insertContent = (e) => {
        e.preventDefault();

        var arr = [...skeleton];
        arr.push({
            id: "",
            type: e.target.type.value
        })

        setSkeleton(arr);
    }

    const insertArticle = (e) => {
        e.preventDefault();

    } 

    return <Fragment>
        {p.picking && <Fragment>
            <table className="table">
                <thead className="text-center">
                    <tr>
                        <th style={{ width: "30px", borderBottomStyle: "none", borderTopStyle: "none" }}></th>
                        <th scope="col">ID</th>
                        <th scope="col">Qté</th>
                        <th scope="col">Article</th>
                        <th scope="col">Désignation</th>
                        <th scope="col">Type de colisage</th>
                        <th scope="col">Longueur</th>
                        <th scope="col">Largeur</th>
                        <th scope="col">Hauteur</th>
                        <th scope="col">Poids</th>
                        <th scope="col" className="no-print"></th>
                    </tr>
                </thead>
                <tbody>
                    {skeleton.map((v2, k2) => {
                        return <Fragment>
                            <tr key={"pick" + "_" + k2} onMouseEnter={() => setCursor("_" + k2)}
                                onMouseLeave={() => setCursor("")}>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{v2.type}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <img src="/common/remove_icon.png" className="see-img w-30 cursor-pointer"
                                        onClick={() => setSkeleton(removeAndReturnArray(skeleton, k2))} />
                                </td>
                            </tr>

                            <tr key={"add_" + k2} onMouseEnter={() => setCursor("_" + k2)}
                                onMouseLeave={() => setCursor("")} title="Ajouter un élément"
                                className={cursor === "_" + k2 ? "no-print" : "no-print d-none"}>

                                <td style={{ width: "30px", borderBottomStyle: "none", borderTopStyle: "none" }}
                                    className="cursor-pointer position-relative p-0"
                                    onClick={() => setToAdd({ k: k2 })}>
                                    <img src="/common/add_btn.png" className="position-absolute"
                                        style={{ maxWidth: "30px", marginTop: "-15px", zIndex: "1000" }} />
                                </td>

                                <td colSpan={11} className="see-border p-0"></td>
                            </tr>
                        </Fragment>
                    })}
                </tbody>
            </table>

            {skeleton.length === 0 &&
                <div className="text-center">
                    <button className="btn btn-secondary" onClick={() => setToAdd({ k: 0, type: "skeleton" })}>Ajouter un contenant</button>
                </div>}


            {toAdd && <div className="custom-tooltip-v2">
                <div className="modal-body xl blue-purple-bg">
                    <button type="button" className="btn-close" onClick={() => setToAdd()}></button>

                    <h5 className="text-center">Insérer un contenant ou articles</h5>

                    <div className="text-center mb-3">
                        <input type="radio" className="btn-check" name="type" checked={type === "container"}
                            onClick={() => setType("container")} />
                        <label className="btn" onClick={() => setType("container")}>Contenant</label>

                        {skeleton.length > 0 && <Fragment>
                            <input type="radio" className="btn-check" name="type" checked={type === "articles"}
                                onClick={() => setType("articles")} />
                            <label className="btn" onClick={() => setType("articles")}>Articles</label>
                        </Fragment>
                        }
                    </div>

                    {type === "container" &&
                        <form onSubmit={insertContent} className="ms-auto me-auto" style={{ width: "800px" }}>
                            <div className="input-group mb-3">
                                <label className="input-group-text">Type de colisage</label>
                                <select className="form-select" name="type" required>
                                    <option value="">Choose...</option>
                                    <option value="Complet">Container</option>
                                    <option value="Palette">Palette</option>
                                    <option value="Caisse">Caisse</option>
                                    <option value="Carton">Carton</option>
                                    <option value="Enveloppe">Enveloppe</option>
                                </select>

                                <button className="btn btn-success">Ajouter</button>
                            </div>
                        </form>}

                    {type === "articles" &&
                        <form onSubmit={insertArticle}>
                            <div className="overflow-y-auto mb-3" id="art_proposal" style={{ height: "500px" }}>
                                <table className="table">
                                    <thead>
                                        <tr className="table-info text-center">
                                            <th className="text-start">
                                                <input className="form-check-input" type="checkbox" />
                                            </th>
                                            <th>Article</th>
                                            <th>Ref / plan</th>
                                            <th>Libellé</th>
                                            <th>Origine</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {p.picking.picking.map((v, k) => {
                                            return <tr key={"prop" + k}>
                                                <td><input className="form-check-input" type="checkbox" /></td>
                                                <td>{v.CDARL}</td>
                                                <td>{v.ZN02PA}</td>
                                                <td>{v.ZN03PA}</td>
                                                <th>{v.source}</th>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="text-center">
                                <button className="btn btn-success">Ajouter</button>
                            </div>
                        </form>
                    }

                </div>
            </div>}
        </Fragment>
        }



    </Fragment>
}

export default FocusPicking;