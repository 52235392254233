import { Fragment, useState } from "react";

const Poster = (props) => {
    const [format, setFormat] = useState(1);
    const [size, setSize] = useState("fmiddle");
    const [case1, setCase1] = useState("");
    const [case2, setCase2] = useState("");
    const [case3, setCase3] = useState("");

    return <div style={{ height: "100%" }}>
        <div className="printLayoutContainer">
            <style type="text/css" media="print">
                {" @page { size: " + (format === 3 ? "portrait" : "landscape") + "; } "}
            </style>
        </div>
        <div className="card no-print" style={{ width: "800px" }}>
            <div className="d-flex mb-3">
                <div className="mt-auto mb-auto">
                    <h5>Choisissez le format:</h5>
                </div>

                <div className={"m-auto cursor-pointer rounded p-2 " + (format === 1 ? "border border-success" : "")}
                    onClick={() => setFormat(1)}>
                    <img src="/img/storehouse/poster_1.png" className="rounded" style={{ width: "100px" }}></img>
                </div>

                <div className={"m-auto cursor-pointer rounded p-2 " + (format === 2 ? "border border-success" : "")}
                    onClick={() => setFormat(2)}>
                    <img src="/img/storehouse/poster_2.png" style={{ width: "100px" }}></img>
                </div>

                <div className={"m-auto cursor-pointer rounded p-2 " + (format === 3 ? "border border-success" : "")}
                    onClick={() => setFormat(3)}>
                    <img src="/img/storehouse/poster_3.png" style={{ width: "100px" }}></img>
                </div>
            </div>

            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">Case 1</span>
                <input type="text" className="form-control" value={case1}
                    onChange={(e) => setCase1(e.target.value)} />

                <span className="input-group-text" id="basic-addon1">Case 2</span>
                <input type="text" className="form-control" value={case2}
                    onChange={(e) => setCase2(e.target.value)} />

                {format !== 1 && <Fragment>

                    <span className="input-group-text" id="basic-addon1">Case 3</span>
                    <input type="text" className="form-control" value={case3}
                        onChange={(e) => setCase3(e.target.value)} />
                </Fragment>
                }

                <label className="input-group-text">Options</label>
                <select className="form-select" value={size} onChange={(e) => setSize(e.target.value)}>
                    <option value="fsmall">Petite</option>
                    <option value="fmiddle">Moyenne</option>
                    <option value="fbig">Grande</option>
                </select>
            </div>

            <div className="text-center">
                <button className="btn btn-success" onClick={() => window.print()}>Imprimer</button>
            </div>
        </div>

        <table id="poster" className={size}>
            <tr>
                <td id="case_1">{case1}</td>
            </tr>

            <tr className={"empty_" + ( format === 1 ? "100" : format === 2 ? "20" : "200" ) }>
                <td></td>
            </tr>

            <tr>
                <td id="case_2">{case2}</td>
            </tr>

            {format !== 1 && <Fragment>
                <tr className={"empty_" + ( format === 1 ? "100" : format === 2 ? "20" : "200" ) }>
                    <td></td>
                </tr>

                <tr>
                    <td id="case_3">{case3}</td>
                </tr>
            </Fragment>}
        </table>


    </div>

}

export default Poster;