import { createColumnHelper } from "@tanstack/react-table";
import Moment from "moment";
import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import { currentEnDate } from "../../functions/StrFunctions";
import CheckConnDao from "../../services/common/CheckConnDao";
import errorManagement from "../../services/errorManagement";
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import NavigationSearch from "./comp/NavigationSearch";

const Navigation = (props) => {

    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState();
    const [tab, setTab] = useState(0);

    const contentBlock = htmlToDraft("");
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(contentState))

    const contentBlockEn = htmlToDraft("");
    const contentStateEn = ContentState.createFromBlockArray(contentBlockEn.contentBlocks);
    const [editorStateEn, setEditorStateEn] = useState(() => EditorState.createWithContent(contentStateEn))

    const [id, setId] = useState(null);

    useEffect(() => {
        CheckConnDao.getNavigation().then(
            (response) => {
                setList(response);
                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }, []);

    const submit = (e) => {
        e.preventDefault();

        var t = e.target;
        var msg_fr = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        var msg_en = draftToHtml(convertToRaw(editorStateEn.getCurrentContent()));

        var o = {
            id: (id && id < list.length) ? list[id].id : null,
            title_fr: t.title_fr.value,
            title_en: t.title_en.value,
            description_fr: msg_fr,
            description_en: msg_en,
            url: t.url.value
        }

        setSaving(true);
        CheckConnDao.addNavigation(o).then(
            (response) => {
                var arr = [...list];

                if (id) { arr[id] = o; }
                else { arr.push(o); }

                setList(arr);
                setId(null);

                document.getElementById("title_fr").value = "";
                document.getElementById("title_en").value = "";
                document.getElementById("url_ipt").value = "";

                const contentBlock = htmlToDraft("");
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                setEditorState(() => EditorState.createWithContent(contentState));
                setEditorStateEn(() => EditorState.createWithContent(contentState));

                setSaving(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setSaving(false);
            }
        )
    }

    const deleteRow = (k, id) => {
        var arr = [...list];
        arr = arrayRemoveElt(arr, k);
        setList(arr);

        CheckConnDao.deleteNavigation(id);
    }

    const updateRow = (k) => {
        var o = { ...list[k] };

        setTab(0);
        setId(k);

        setTimeout(() => {
            document.getElementById("title_fr").value = o.title_fr;
            document.getElementById("title_en").value = o.title_en;
            document.getElementById("url_ipt").value = o.url;
            const contentState = ContentState.createFromBlockArray(htmlToDraft(o.description_fr).contentBlocks);
            setEditorState(() => EditorState.createWithContent(contentState));

            const contentStateEn = ContentState.createFromBlockArray(htmlToDraft(o.description_en).contentBlocks);
            setEditorStateEn(() => EditorState.createWithContent(contentStateEn));
        }, 500);


    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('id', {
            header: '#',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('title_fr', {
            header: 'Titre',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('title_en', {
            header: 'Title',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('description_fr', {
            header: 'Description EN',
            cell: i => <div className="text-center" dangerouslySetInnerHTML={{ __html: i.getValue() }}></div>
        }));

        arr.push(columnHelper.accessor('description_en', {
            header: 'Description FR',
            cell: i => <div className="text-center" dangerouslySetInnerHTML={{ __html: i.getValue() }}></div>
        }));

        arr.push(columnHelper.accessor('url', {
            header: 'URL',
            cell: i => <div className="text-center">
                <a href={i.getValue()} target="_blank">{i.getValue()}</a></div>
        }));

        arr.push(columnHelper.accessor('xx', {
            header: '',
            cell: i => <div className="d-flex">
                <button className="btn btn-outline-secondary me-2" onClick={() => updateRow(i.row.id)}>
                    MAJ
                </button>

                <button className="btn btn-outline-danger" onClick={() => deleteRow(i.row.id, i.row.original.id)}>
                    Supprimer
                </button>
            </div>
        }));


        return arr;
    }

    const columns = getColumns();

    return <Fragment>
        <h5 className="text-center">Gestion du plan site</h5>
        {loading && <WaitingRoundSnippers />}
        {error && <ErrorMessage error={error} errorState={setError} />}

        <ul className="nav nav-tabs">
            <li className="nav-item">
                <a className={'nav-link ' + (tab === 0 ? "active" : "")}
                    aria-current="page" href="#" onClick={() => setTab(0)}>Ajouter</a>
            </li>

            <li className="nav-item">
                <a className={'nav-link ' + (tab === 1 ? "active" : "")}
                    href="#" onClick={() => setTab(1)}>Liste</a>
            </li>

            <li className="nav-item">
                <a className={'nav-link ' + (tab === 2 ? "active" : "")}
                    href="#" onClick={() => setTab(2)}>Tests</a>
            </li>
        </ul>

        {tab === 0 && <form className="card" onSubmit={submit}>

            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">Titre en français</span>
                <input type="text" className="form-control" name="title_fr" id="title_fr" required />

                <span className="input-group-text" id="basic-addon1">Titre en anglais</span>
                <input type="text" className="form-control" name="title_en" id="title_en" required />

                <span className="input-group-text" id="basic-addon1">URL</span>
                <input type="text" className="form-control" name="url" id="url_ipt" required />
            </div>



            <div className="d-flex">
                <div>
                    <h6>Description en français</h6>
                    <Editor editorState={editorState}
                        onEditorStateChange={setEditorState}
                        wrapperClassName="draftEditor-wrapper mb-3" editorClassName="draftEditor-editor" />
                </div>
                <div>
                    <h6>Description en anglais</h6>
                    <Editor editorState={editorStateEn}
                        onEditorStateChange={setEditorStateEn}
                        wrapperClassName="draftEditor-wrapper mb-3" editorClassName="draftEditor-editor" />
                </div>
            </div>




            <div className="text-center mb-3">
                <button className="btn btn-outline-secondary" disabled={saving}>
                    {saving && <ButtonWaiting />}
                    Enregistrer
                </button>
            </div>
        </form>
        }

        {tab === 1 && <ReactTable columns={columns} data={list}
            origin="navigation_mngt" classes={"no-padding fixFstCol"} top0={true} />
        }

        {tab === 2 && <NavigationSearch list={list}/>}

    </Fragment>
}

export default Navigation;