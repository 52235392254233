import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL +"purchase/info";

const getOrderInfo = (order) => {
    return axios
        .post(API_URL + "/order/edition", {
                fake_header: authHeader(),
                order: order
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const updateOrderPrice = (order, row, newPrice, frn) => {
    return axios
        .post(API_URL + "/order/update-price", {
                fake_header: authHeader(),
                order: order,
                row: row,
                newPrice: newPrice,
                frn: frn
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const updateQty = (order_nb, row) => {
    return axios
        .post(API_URL + "/order/update-qty", {
                fake_header: authHeader(),
                order_nb: order_nb,
                row: row
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const updatActualizedDate = (order, date) => {
    return axios
        .post(API_URL + "/order/update-actualized-date", {
                fake_header: authHeader(),
                order: order,
                date: date
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const updatActualizedDateOneRow = (order, line, date) => {
    return axios
        .post(API_URL + "/order/update-actualized-date/one", {
                fake_header: authHeader(),
                order: order,
                line: line,
                date: date
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const updatActualizedDateByArticles = (order, articles) => {
    return axios
        .post(API_URL + "/order/update-actualized-date/articles", {
                fake_header: authHeader(),
                order: order,
                articles: articles
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const createDrawingsZip = (order) => {
    return axios
        .post(API_URL + "/order/compile-drawings", {
                fake_header: authHeader(),
                order: order
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const createDrawingsZipAndSend = (order) => {
    return axios
        .post(API_URL + "/order/compile-drawings/send", {
                fake_header: authHeader(),
                order: order
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const getSupplierConditionning = (supplier, ar) => {
    return axios
        .post(API_URL + "/supplier/conditionning", {
                fake_header: authHeader(),
                supplier: supplier,
                ar: ar
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const addDeliveryRow = (supplier, lastRow, detail, business) => {
    return axios
        .post(API_URL + "/order/add-delivery", {
                fake_header: authHeader(),
                supplier: supplier,
                lastRow: lastRow,
                detail: detail,
                business: business
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const getDateToUpdate = (order, art) => {
    return axios
        .post(API_URL + "/order/check-dates", {
                fake_header: authHeader(),
                order: order,
                art: art
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const ordersTracking = () => {
    return axios
        .post(API_URL + "/orders/tracking", {
                fake_header: authHeader()
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const dynamicStock = () => {
    return axios
        .post(API_URL + "/dynamic-stock/tracking", {
                fake_header: authHeader()
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const updateSupplierEmail = (address, supplier) => {
    return axios
        .post(API_URL + "/update/supplier/email", {
                fake_header: authHeader(),
                address: address,
                supplier: supplier
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const updateBpOrderInfo = (o) => {
    return axios
        .post(API_URL + "/update/bp/order-info", {
                fake_header: authHeader(),
                ...o
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const remindLate = (list) => {
    return axios
        .post(API_URL + "/remind/send", {
                fake_header: authHeader(),
                list: list
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const validateReminderDates = (arr) => {
    return axios
        .post(API_URL + "/remind/validate", {
                fake_header: authHeader(),
                arr: arr
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

export default {
    getOrderInfo,
    updateOrderPrice,
    updatActualizedDate,
    createDrawingsZip,
    updateQty,
    getSupplierConditionning,
    updatActualizedDateOneRow,
    addDeliveryRow,
    getDateToUpdate,
    updatActualizedDateByArticles,
    ordersTracking,
    createDrawingsZipAndSend,
    dynamicStock, 
    updateSupplierEmail,
    remindLate,
    validateReminderDates,
    updateBpOrderInfo
}