import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL + "contacts";
const user = JSON.parse(localStorage.getItem("user"));

const getContactsByGroup = (group) => {
    return axios.post(API_URL + "/groups/get",
        {
            fake_header: authHeader(),
            email: user.email,
            group: group
        },
        { headers: textPlainHeader() }
    ).then((response) => { return response.data })
}

const addContact = (group, firstname, lastname, email) => {
    return axios.post(API_URL + "/contact/add",
        {
            fake_header: authHeader(),
            firstname: firstname,
            lastname: lastname,
            email: email,
            group: group
        },
        { headers: textPlainHeader() }
    ).then((response) => { return response.data })
}

const updateContact = (id, firstname, lastname, email) => {
    return axios.post(API_URL + "/contact/update",
        {
            fake_header: authHeader(),
            firstname: firstname,
            lastname: lastname,
            email: email,
            id: id
        },
        { headers: textPlainHeader() }
    ).then((response) => { return response.data })
}

const deleteContact = (id, group) => {
    return axios.post(API_URL + "/contact/delete",
        {
            fake_header: authHeader(),
            group: group,
            id: id
        },
        { headers: textPlainHeader() }
    ).then((response) => { return response.data })
}

const getGroups = () => {
    return axios.post(API_URL + "/groups/list",
        {
            fake_header: authHeader(),
            email: user.email
        },
        { headers: textPlainHeader() }
    ).then((response) => { return response.data })
}

const addGroup = (code, description, auth) => {
    return axios.post(API_URL + "/groups/add",
        {
            fake_header: authHeader(),
            email: user.email,
            code: code,
            description: description,
            auth: auth
        },
        { headers: textPlainHeader() }
    ).then((response) => { return response.data })
}

const updateGroup = (id, description, auth) => {
    return axios.post(API_URL + "/groups/update",
        {
            fake_header: authHeader(),
            description: description,
            auth: auth,
            id: id
        },
        { headers: textPlainHeader() }
    ).then((response) => { return response.data })
}

const deleteGroup = (id) => {
    return axios.post(API_URL + "/groups/delete",
        {
            fake_header: authHeader(),
            email: user.email,
            id: id
        },
        { headers: textPlainHeader() }
    ).then((response) => { return response.data })
}

const getAllUsersEmails = () => {
    return axios.post(API_URL + "/users/get/emails",
        {
            fake_header: authHeader()
        },
        { headers: textPlainHeader() }
    ).then((response) => { return response.data })
}

export default {
    getContactsByGroup,
    getGroups,
    addGroup,
    deleteGroup,
    updateContact,
    addContact,
    deleteContact,
    getAllUsersEmails,
    updateGroup
}