import { Fragment } from "react";
import QrReader from 'react-qr-scanner'

/**
 * setText={setText}
 * setError={setError}
 */
const QrCodeReader = (props) => {
    const previewStyle = {
        height: 150,
        width: 150,
    }

    const handleScan = (data) => {
        if (data !== null) props.setText(data.text);
    }

    const handleError = (err) => {
        props.setError(err)
    }

    return <Fragment>
        <div className="text-center">
            {props.frontCam && <QrReader delay={100} onError={handleError} onScan={handleScan}
                style={previewStyle} constraints={{ video: { facingMode: "user", zoom: 4 } }}
                qrArea={{ top: 100, right: 100, bottom: 300, left: 300 }}
                bounds={{ top: 100, right: 100, bottom: 300, left: 300 }}/>}

            {!props.frontCam &&
                <QrReader delay={100} onError={handleError} onScan={handleScan}
                    style={previewStyle} constraints={{ video: { facingMode: "environment", zoom: 4 } }}
                    qrArea={{ top: 100, right: 100, bottom: 300, left: 300 }}
                    bounds={{ top: 100, right: 100, bottom: 300, left: 300 }}/>}
        </div>
    </Fragment>

}

export default QrCodeReader;