import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { scrollToTop } from "../../functions/PageAnimation";
import errorManagement from "../../services/errorManagement";
import FormMgtDao from "../../services/settings/FormMgtDao";
import { addAfter, arrayRemoveElt } from "../../functions/ArrayFunctions";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";

const FormMgt = (props) => {
    const [list, setList] = useState();
    const [formList, setFormList] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [tab, setTab] = useState(0);
    const [chap, setChap] = useState();
    const [rubric, setRubric] = useState();
    const [question, setQuestion] = useState();
    const [msg, setMsg] = useState();
    const user = JSON.parse(localStorage.getItem("user"));
    const isAdmin = user && user.roles.includes("ROLE_ADMIN");

    const [form, setForm] = useState();
    const [index, setindex] = useState();
    const [machine_type, setmachine_type] = useState();

    const [distinctForm, setDistinctForm] = useState();
    const [distinctMachType, setDistinctMachType] = useState();
    
    const [selectedForm, setSelectedForm] = useState();
    const [selectedChap, setSelectedChap] = useState(-99);
    const [selectedRub, setSelectedRub] = useState(-99);

    /**
     * get all forms
     */
    useEffect(() => {
        setLoading(true);

        FormMgtDao.getForms().then(
            (response) => {
                setFormList(response);

                var df = [];
                var dmt = [];

                for( let i=0; i<response.length; i++ ){
                    if( !df.includes(response[i].form.trim()) ) df.push(response[i].form.trim());
                    if( !dmt.includes(response[i].mach_type.trim()) ) dmt.push(response[i].mach_type.trim());
                }

                setDistinctForm(df);
                setDistinctMachType(dmt);
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );
    }, []);

    /**
     * Set in array the choosen form
     * @param {*} e 
     */
    const getForm = (e) => {
        e.preventDefault();
        const f = e.target.form.value;
        const index = e.target.index.value;
        const machine_type = e.target.machine_type.value;

        searchForm(f, index, machine_type);
    }

    function searchForm(f, index, machine_type) {
        setSelectedForm(f);

        setChap();
        setRubric();
        setQuestion();
        setLoading(true);
        FormMgtDao.getForm(f, index, machine_type).then(
            (response) => {
                setList(response);
                setForm(f);
                setindex(index);
                setmachine_type(machine_type);

                var chapArr = new Array();
                var rubArr = new Array();
                var qstArr = new Array();

                var previousChap = -99;
                var previousRub = -99;
                var previousQst = -99;

                response.map(v => {
                    //Form 
                    if (v.form === f) {
                        if (parseInt(v.chapter) !== previousChap) { chapArr.push(v); previousChap = v.chapter; }
                        if (parseInt(v.rubric) !== previousRub && v.rubric !== null) { rubArr.push(v); previousRub = v.rubric; }
                        if (parseInt(v.question) !== previousQst && v.question !== null) { qstArr.push(v); previousQst = v.question; }
                    }
                });

                setChap(chapArr);
                setRubric(rubArr);
                setQuestion(qstArr);

                setLoading(false);
            },
            (error) => {
                setLoading(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );
    }

    /**
     * Change tab
     * @param {*} e 
     */
    const changeTab = (e) => {
        setTab(parseInt(e.target.getAttribute("data-tab")));
    }

    const setChoosenChap = (e) => {
        setSelectedChap(e.target.value);
    }

    const setChoosenRub = (e) => {
        setSelectedRub(e.target.value);
    }

    /**
     * On chapter tab: change chapter number
     */
    const onNbChange = (e) => {
        const key = e.target.getAttribute("data-key");
        const part = e.target.getAttribute("data-part");
        const val = e.target.value;

        if (part === "chap") {
            setChap(incrementChapterNumber(chap, key, val));
        } else if (part === "qst") {
            setQuestion(incrementQstNumber(question, key, val));
        }

    }

    /**
     * Increment chapter number
     * 
     * @param {*} key 
     * @param {*} val 
     */
    function incrementChapterNumber(chap, key, val) {
        var arr = [...chap];

        for (let i = key; i < chap.length; i++) {
            arr[i].chapter = val;
            val++;
        }

        return arr;
    }

    /**
     * Increment qst number
     * @param {*} rub 
     * @param {*} key 
     * @param {*} val 
     * @returns 
     */
    function incrementQstNumber(qst, key, val) {
        var arr = [...qst];

        for (let i = key; i < arr.length; i++) {
            arr[i].question = val;
            val++;
        }

        return arr;
    }

    /**
     * On any element change, except numbers which need incrementation
     * @param {*} e 
     */
    const onEltChange = (e) => {
        const key = e.target.getAttribute("data-key");
        const part = e.target.getAttribute("data-part");
        const field = e.target.getAttribute("data-field");
        const val = e.target.value;

        var arr;
        if (part === "chap") {
            arr = [...chap];
            arr[key][field] = val;
            setChap(arr);
        } else if (part === "rub") {
            arr = [...rubric];
            arr[key][field] = val;
            setRubric(arr);
        } else if (part === "qst") {
            arr = [...question];
            arr[key][field] = val;
            setQuestion(arr);
        }
    }

    /**
     * Add row
     * @param {*} e 
     */
    const addRow = (e) => {
        const key = parseInt(e.target.getAttribute("data-key"));
        const part = e.target.getAttribute("data-part");
        const field = e.target.getAttribute("data-field");

        var arr, arr2;
        if (part === "chap") {
            arr = [...chap.slice(0, key + 1)];
            var o = { ...arr[key] };
            o.chp_lab = "";
            arr.push(o);
            arr2 = [...chap.slice(key + 1, chap.length)];
            arr = arr.concat(arr2);
            setChap(incrementChapterNumber(arr, key, chap[key].chapter));
        } else if (part === "rub") {
            arr = [...rubric.slice(0, key + 1)];
            var o = { ...arr[key] };
            o.rub_lab = "";
            o.mach_type = machine_type;
            o.form = form;
            o.index = index;
            arr.push(o);
            arr2 = [...rubric.slice(key + 1, rubric.length)];
            arr = arr.concat(arr2);
            setRubric(arr);
        } else if (part === "qst") {
            arr = [...question.slice(0, key + 1)];
            var o = { ...arr[key] };
            o.mach_type = machine_type;
            o.form = form;
            o.index = index;
            o.qst_lab = "";
            o.en_qst = "";
            o.hasConform = 1;
            o.inputs = "";
            o.options = "";
            o.textarea = "";
            o.chkboxs = "";
            arr.push(o);
            arr2 = [...question.slice(key + 1, question.length)];
            arr = arr.concat(arr2);
            setQuestion(incrementQstNumber(arr, key, question[key].question));
        } else if (part === "opt") {
            arr = [...question];
            if (arr[key].options.length === 0) arr[key].options = new Array();
            arr[key].options.push({ "name": "", "value": "", required: false, checked: false });
            setQuestion(arr);
        } else if (part === "chkboxs") {
            arr = [...question];
            if (arr[key].chkboxs.length === 0) arr[key].chkboxs = new Array();
            arr[key].chkboxs.push({ "name": "", "value": "", required: false, checked: false });
            setQuestion(arr);
        } else if (part === "inputs") {
            arr = [...question];
            if (arr[key].inputs.length === 0) arr[key].inputs = new Array();
            arr[key].inputs.push({ "name": "", "value": "" });
            setQuestion(arr);
        } else if (part === "textarea") {
            arr = [...question];
            if (arr[key].textarea.length === 0) arr[key].textarea = new Array();
            arr[key].textarea.push({ "name": "", "value": "" });
            setQuestion(arr);
        }
    }

    /**
     * Remove row
     * @param {*} e 
     */
    const removeRow = (e) => {
        const key = parseInt(e.target.getAttribute("data-key"));
        const part = e.target.getAttribute("data-part");
        const field = e.target.getAttribute("data-field");
        //For attribute only
        const keyQst = e.target.getAttribute("data-keyQst");
        const keyAttr = e.target.getAttribute("data-keyAttr");

        var arr, arr2;
        if (part === "chap") {
            arr = [...chap.slice(0, key)];
            arr2 = [...chap.slice(key + 1, chap.length)];
            arr = arr.concat(arr2);
            setChap(incrementChapterNumber(arr, key, chap[key].rubric));
        } else if (part === "rub") {
            arr = [...rubric.slice(0, key)];
            arr2 = [...rubric.slice(key + 1, rubric.length)];
            arr = arr.concat(arr2);
            setRubric(arr);
        } else if (part === "qst") {
            arr = [...question.slice(0, key)];
            arr2 = [...question.slice(key + 1, question.length)];
            arr = arr.concat(arr2);
            setQuestion(incrementQstNumber(arr, key, question[key].question));
        } else if (part === "opt") {
            var qst = [...question];
            var opt = qst[keyQst].options;
            arr = opt.slice(0, keyAttr).concat(opt.slice(keyAttr + 1, opt.length));
            qst[keyQst].options = arr;
            setQuestion(qst);
        } else if (part === "chkboxs") {
            var qst = [...question];
            var chkboxs = qst[keyQst].chkboxs;
            arr = chkboxs.slice(0, keyAttr).concat(chkboxs.slice(keyAttr + 1, chkboxs.length));
            qst[keyQst].chkboxs = arr;
            setQuestion(qst);
        } else if (part === "inputs") {
            var qst = [...question];
            var inputs = qst[keyQst].inputs;
            arr = inputs.slice(0, keyAttr).concat(inputs.slice(keyAttr + 1, inputs.length));
            qst[keyQst].inputs = arr;
            setQuestion(qst);
        } else if (part === "textarea") {
            var qst = [...question];
            var textarea = qst[keyQst].textarea;
            arr = textarea.slice(0, keyAttr).concat(textarea.slice(keyAttr + 1, textarea.length));
            qst[keyQst].textarea = arr;
            setQuestion(qst);
        }
    }

    /**
     * Save chapter or rubric or question
     * @param {*} e 
     */
    const save = (e) => {
        setError();
        const part = e.target.getAttribute("data-part");

        setSaving(true);
        var arr;
        if (part === "chapter") {
            arr = chap;
        } else if (part === "rub") {
            arr = rubric;
        } else if (part === "qst") {
            arr = question;
        }

        FormMgtDao.saveForm(arr, part).then(
            (response) => {
                setSaving(false);
                setMsg("Sauvegardé");
                scrollToTop();
            },
            (error) => {
                setSaving(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        )

    }

    const deleteElt = (e) => {
        setError();
        const part = e.target.getAttribute("data-part");

        setSaving(true);
        var o = {
            part: part,
            index: chap[0].index,
            form: chap[0].form,
            chapter: e.target.getAttribute("data-chapter"),
            id: e.target.getAttribute("data-id")
        };

        FormMgtDao.deleteEltForm(o).then(
            () => {
                setSaving(false);
            },
            (error) => {
                setSaving(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        )

        removeRow(e);
    }

    const deleteForm = (k) => {
        var o = {
            form: formList[k].form,
            index: formList[k].index,
            mach_type: formList[k].mach_type
        };

        FormMgtDao.deleteForm(o).then(
            () => {
                setSaving(false);
            },
            (error) => {
                setSaving(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        )

        var arr = [...formList];
        arr = arrayRemoveElt(arr, k);
        setFormList(arr);
    }

    /**
     * Change option, chck...
     * @param {*} e 
     */
    const changeAttr = (e) => {
        const keyQst = e.target.getAttribute("data-keyQst");
        const keyAttr = e.target.getAttribute("data-keyAttr");
        const type = e.target.getAttribute("data-type");
        const attr = e.target.getAttribute("data-attr");

        var qst = [...question];

        if (type === "opt") {
            qst[keyQst].options[keyAttr][attr] = e.target.value;
        } else if (type === "chkboxs") {
            qst[keyQst].chkboxs[keyAttr][attr] = e.target.value;
        } else if (type === "inputs") {
            qst[keyQst].inputs[keyAttr][attr] = e.target.value;
        } else if (type === "textarea") {
            qst[keyQst].textarea[keyAttr][attr] = e.target.value;
        }

        setQuestion(qst);
    }

    const startRow = (e) => {
        const qstObj = {
            form: selectedForm,
            mach_type: machine_type,
            chapter: selectedChap,
            rubric: selectedRub,
            index: index,
            question: "",
            qst_lab: "",
            chkboxs: "",
            inputs: "",
            hasConform: 1,
            options: "",
            textarea: "",
            en_qst: "",
            required: false
        };

        var arr = [...question];

        if (arr.length === 0) {
            arr = new Array();
            arr.push(qstObj);
            setQuestion(arr);
            return;
        }

        var key = 0;
        arr.map(
            v => {
                if (v.chapter === parseInt(selectedChap)) {
                    if (v.rubric > parseInt(selectedRub))
                        return;
                } else if (v.chapter > parseInt(selectedChap)) {
                    return;
                }

                key++;
            }
        )

        arr = addAfter(arr, key, qstObj);
        setQuestion(incrementQstNumber(arr, 0, 1));
    }

    /**
     * Add rubric when there not present
     * @param {*} e 
     */
    const addRubric = (e) => {
        const rubricObj = {
            form: selectedForm,
            mach_type: machine_type,
            chapter: selectedChap,
            rubric: selectedChap,
            index: index,
            rub_lab: "",
            en_label: ""
        };

        var arr = [...rubric];

        if (arr.length === 0) {
            arr = new Array();
            arr.push(rubricObj);
            setRubric(arr);
            return;
        }

        var key = 0;
        arr.map(
            v => {
                if (v.chapter === parseInt(selectedChap)) {
                    if (v.rubric > parseInt(selectedRub))
                        return;
                } else if (v.chapter > parseInt(selectedChap)) {
                    return;
                }

                key++;
            }
        )

        arr = addAfter(arr, key, rubricObj);
        setRubric(arr);

    }

    /**
     * Add new chapter
     */
    const addNewChap = () => {
        const chapObj = {
            form: selectedForm,
            mach_type: machine_type,
            chapter: "",
            index: index,
            chp_lab: ""
        }

        var arr = [...chap];

        if (arr.length === 0) {
            arr = new Array();
            arr.push(chapObj);

        } else {
            arr.push(chapObj);
        }

        setChap(incrementChapterNumber(arr, 0, 1));
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('mach_type', {
            header: 'Type de machine',
            cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('index', {
            header: 'Version',
            cell: (i) => <div className="text-center" >{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('form', {
            header: 'Type de formulaire formulaire',
            cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('xxx', {
            header: '',
            cell: (i) => <div className="text-center">
                <button className="btn btn-outline-success" 
                    onClick={()=> searchForm(i.row.original.form, i.row.original.index, 
                        i.row.original.mach_type)}>Mettre à jour</button>

                {isAdmin && 
                    <button className="btn btn-outline-danger ms-3" 
                    onClick={()=> deleteForm(i.row.id)}>Supprimer</button>
                }
            </div>
        }));


        return arr;
    }

    //Set columns
    const columns = getColumns();

    const otheForm = () => {
        setChap();
        setRubric();
        setQuestion();
        setSelectedForm();
        setSelectedForm();
        setSelectedRub();
    }

    return (
        <div>
            {loading && <WaitingRoundSnippers />}
            {error && <ErrorMessage error={error} errorState={setError} />}
            {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

            {
                formList && <Fragment>
                    {!isAdmin && 
                    <form onSubmit={getForm}>
                        <div className="input-group mb-3">
                            <span className="input-group-text">Formulaire</span>
                            <select list="forms" name="form" className="form-select" required>
                                <option value=""></option>
                                {distinctForm.map( (v, k) => {
                                    return <option key={"form"+k} value={v}>{v}</option>
                                })}
                            </select>

                            <span className="input-group-text">Indice</span>
                            <input type="text" list="forms" name="index" defaultValue="A" required
                            className="form-control"></input>

                            <span className="input-group-text">Type de machine</span>
                            <select name="machine_type" className="form-select" required>
                                <option value=""></option>
                                {distinctMachType.map( (v, k) => {
                                    return <option key={"form"+k} value={v}>{v}</option>
                                })}
                            </select>

                            <button type="submit" className="btn btn-success">Commencer</button>
                        </div>
                        <br></br>

                    </form>}

                    {isAdmin && 
                    <form onSubmit={getForm}>
                        <div className="input-group mb-3">
                            <span className="input-group-text">Formulaire</span>
                            <input type="text" list="forms" name="form" className="form-control"></input>

                            <span className="input-group-text">Indice</span>
                            <input type="text" list="forms" name="index" defaultValue="A" className="form-control"></input>

                            <span className="input-group-text">Type de machine</span>
                            <input type="text" name="machine_type" className="form-control"></input>

                            <button type="submit" className="btn btn-success">Commencer</button>
                        </div>
                        <br></br>

                    </form>}



                    {!selectedForm &&
                        <ReactTable columns={columns} data={formList} top0={true} origin="form_setting" />
                    }

                </Fragment>
            }

            {
                chap &&
                <Fragment>
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <a className={"nav-link " + (tab === 0 ? "active" : "")}
                                href="#" onClick={changeTab} data-tab="0">Chapitres</a>
                        </li>
                        <li className="nav-item">
                            <a className={"nav-link " + (tab === 1 ? "active" : "")}
                                href="#" onClick={changeTab} data-tab="1">Rubriques</a>
                        </li>
                        <li className="nav-item">
                            <a className={"nav-link " + (tab === 2 ? "active" : "")}
                                href="#" onClick={changeTab} data-tab="2">Questions</a>
                        </li>
                        { selectedForm && 
                        <li className="nav-item">
                            <a href="#" className="nav-link" onClick={otheForm} data-tab="2">
                                Faire un autre formulaire</a>
                        </li>}
                    </ul>
                    <br></br>
                </Fragment>
            }

            {
                (chap && tab === 0) &&
                <Fragment>
                    {
                        <ul className="list-group">
                            {
                                chap.map((v, k) => {
                                    return <li key={"chap_" + k} className="list-group-item">
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1">N° de chapitre</span>
                                            <input type="number" className="form-control" data-key={k} data-part="chap"
                                                onChange={onNbChange} value={v.chapter} />

                                            <span className="input-group-text" id="basic-addon1">Type de machine</span>
                                            <input type="text" className="form-control" data-key={k} data-part="chap" data-field="mach_type"
                                                onChange={onEltChange} value={v.mach_type} />

                                            <span className="input-group-text" id="basic-addon1">Indice</span>
                                            <input type="text" className="form-control" data-key={k} data-part="chap" data-field="index"
                                                onChange={onEltChange} value={v.index} />

                                            <span className="input-group-text" id="basic-addon1">Libellé</span>
                                            <input type="text" className="form-control" data-key={k} data-part="chap" data-field="chp_lab"
                                                onChange={onEltChange} value={v.chp_lab} />

                                            <img src={"/common/remove_icon.png"} onClick={deleteElt} data-key={k} data-part="chap"
                                                data-chapter={v.chapter} data-id={-1} style={{ width: "30px" }}></img>
                                        </div>
                                        <img src={"/common/add-logo.png"} onClick={addRow} data-key={k} data-part="chap"
                                            style={{ width: "30px" }} title="Ajouter un chapitre ici"></img>
                                    </li>
                                })

                            }

                            <li className="list-group-item">
                                <img src={"/common/add-logo.png"} onClick={addNewChap} data-part="chap"
                                    style={{ width: "30px" }} title="Ajouter un chapitre"></img>
                            </li>

                            <li className="list-group-item text-center">
                                <button type="button" className="btn btn-outline-success"
                                    data-part="chapter" onClick={save} disabled={saving}>
                                    {saving && <span className="spinner-border spinner-border-sm"></span>}
                                    Sauvegarder
                                </button>
                            </li>
                        </ul>
                    }
                    <br></br>
                </Fragment>
            }

            {
                (rubric && tab === 1) &&
                <Fragment>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Chapitre</span>
                        <select className="form-select" onChange={setChoosenChap} >
                            <option>Choisissez le chapitre</option>
                            {
                                chap.map((v, k) => {
                                    return <option key={"cr_" + k} value={v.chapter} selected={parseInt(v.chapter) === parseInt(selectedChap)}>{v.chapter + ") " + v.chp_lab}</option>
                                })
                            }
                        </select>
                    </div>
                    <br></br>
                    <ul className="list-group">
                        {
                            rubric.map((v, k) => {
                                return (parseInt(v.chapter) === parseInt(selectedChap)) &&
                                    <li key={"rub_" + k} className="list-group-item">
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1">N° de rubrique</span>
                                            <input type="number" className="form-control" data-key={k} data-part="rub" data-field="rubric"
                                                onChange={onEltChange} value={v.rubric} />

                                            <span className="input-group-text" id="basic-addon1">Type de machine</span>
                                            <input type="text" className="form-control" data-key={k} data-part="rub" data-field="mach_type"
                                                onChange={onEltChange} value={v.mach_type} />


                                            <span className="input-group-text" id="basic-addon1">Indice</span>
                                            <input type="text" className="form-control" data-key={k} data-part="rub" data-field="index"
                                                onChange={onEltChange} value={v.index} />

                                            <span className="input-group-text" id="basic-addon1">Libellé français</span>
                                            <input type="text" className="form-control" data-key={k} data-part="rub" data-field="rub_lab"
                                                onChange={onEltChange} value={v.rub_lab} />

                                            <span className="input-group-text" id="basic-addon1">Libellé anglais</span>
                                            <input type="text" className="form-control" data-key={k} data-part="rub" data-field="en_label"
                                                onChange={onEltChange} value={v.en_label} />

                                            <img src={"/common/remove_icon.png"} onClick={deleteElt} data-key={k} data-part="rub"
                                                data-chapter={v.chapter} data-id={v.rubric} style={{ width: "30px" }}></img>
                                        </div>

                                        <img src={"/common/add-logo.png"} onClick={addRow} data-key={k} data-part="rub"
                                            title="Ajouter une rubrique" style={{ width: "30px" }} ></img>
                                    </li>
                            })
                        }

                        <li className="list-group-item text-center">
                            <img src={"/common/add-logo.png"} onClick={addRubric} data-part="rub"
                                title="Ajouter une rubrique" style={{ width: "30px" }} ></img>
                        </li>

                        <li className="list-group-item text-center">
                            <button type="button" className="btn btn-outline-success"
                                data-part="rub" onClick={save} disabled={saving}>
                                {saving && <span className="spinner-border spinner-border-sm"></span>}
                                Sauvegarder
                            </button>
                        </li>
                    </ul>

                </Fragment>
            }

            {
                (question && tab === 2) &&
                <Fragment>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Chapitre</span>
                        <select className="form-select" onChange={setChoosenChap}>
                            <option>Choisissez le chapitre</option>
                            {
                                chap.map((v, k) => {
                                    return <option key={"cr_" + k} value={v.chapter} selected={parseInt(v.chapter) === parseInt(selectedChap)}>{v.chapter + ") " + v.chp_lab}</option>
                                })
                            }
                        </select>

                        <span className="input-group-text" id="basic-addon1">Rubrique</span>
                        <select className="form-select" onChange={setChoosenRub} >
                            <option>Choisissez la rubrique</option>
                            {
                                rubric.map((v, k) => {
                                    return (
                                        parseInt(v.chapter) === parseInt(selectedChap) &&
                                        <option key={"rq_" + k} value={v.rubric} selected={parseInt(v.rubric) === parseInt(selectedRub)}>{v.rubric + ") " + v.rub_lab}</option>
                                    )
                                })
                            }
                        </select>

                    </div>
                    <br></br>
                    <ul className="list-group">
                        {
                            question.map((v, k) => {
                                return ((parseInt(v.chapter) === parseInt(selectedChap) && parseInt(v.rubric) === parseInt(selectedRub)) &&
                                    <li key={"rub_" + k} className="list-group-item">
                                        <div className="input-group mb-3">

                                            <span className="input-group-text" id="basic-addon1">N° de question</span>
                                            <input type="number" className="form-control" data-key={k} data-part="qst"
                                                onChange={onNbChange} value={v.question} />

                                            <span className="input-group-text" id="basic-addon1">Type de machine</span>
                                            <input type="text" className="form-control" data-key={k} data-part="qst" data-field="mach_type"
                                                onChange={onEltChange} value={v.mach_type} />

                                            <span className="input-group-text" id="basic-addon1">Indice</span>
                                            <input type="text" className="form-control" data-key={k} data-part="qst" data-field="index"
                                                onChange={onEltChange} value={v.index} />

                                            <div style={{ marginRight: "5px" }}>
                                                <input type="checkbox" className="btn-check" id={"hasConform" + v.question} checked={parseInt(v.hasConform) === 1} data-key={k}
                                                    data-part="qst" data-field="hasConform" onChange={onEltChange} value={parseInt(v.hasConform) === 1 ? 0 : 1} />
                                                <label className="btn btn-outline-secondary" for={"hasConform" + v.question}>Btn conforme</label>
                                            </div>

                                            <div>
                                                <input type="checkbox" className="btn-check" id={"hasPhoto" + v.question} checked={parseInt(v.hasPhoto) === 1} data-key={k}
                                                    data-part="qst" data-field="hasPhoto" onChange={onEltChange} value={parseInt(v.hasPhoto) === 1 ? 0 : 1} />
                                                <label className="btn btn-outline-secondary" for={"hasPhoto" + v.question}>Photo</label>
                                            </div>
                                            <img src={"/common/remove_icon.png"} onClick={deleteElt} data-key={k} data-part="qst"
                                                data-chapter={v.chapter} data-id={v.question} style={{ width: "30px" }}></img>
                                        </div>

                                        <div className="display-flex mb-3" style={{ width: "100%" }}>
                                            <div style={{ width: "50%", paddingRight: "5px" }}>
                                                <div className="form-floating">
                                                    <textarea className="form-control" placeholder="xxx" id="q" data-key={k} data-part="qst"
                                                        data-field="qst_lab" onChange={onEltChange} value={v.qst_lab} ></textarea>
                                                    <label for="q">Libellé français</label>
                                                </div>
                                            </div>

                                            <div style={{ width: "50%", paddingLeft: "5px" }}>
                                                <div className="form-floating">
                                                    <textarea className="form-control" placeholder="xxx" id="q" value={v.en_qst}
                                                        data-key={k} data-part="qst" data-field="en_qst" onChange={onEltChange} ></textarea>
                                                    <label for="q">Libellé anglais</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="div-90-border">
                                            <span className="text-decoration-underline">Options</span>
                                            <br></br>
                                            <br></br>
                                            {
                                                (v.options && v.options.length > 0) &&
                                                v.options.map((opt, k2) => {
                                                    return (
                                                        <div key={"opt_" + k2} className="input-group mb-3">
                                                            <span className="input-group-text" id="basic-addon1">Name</span>
                                                            <input type="text" className="form-control"
                                                                data-keyQst={k} data-keyAttr={k2} data-attr="name" data-type="opt"
                                                                onChange={changeAttr} value={opt.name} />

                                                            <span className="input-group-text" id="basic-addon1">Value</span>
                                                            <input type="text" className="form-control"
                                                                data-keyQst={k} data-keyAttr={k2} data-attr="value" data-type="opt"
                                                                onChange={changeAttr} value={opt.value} />

                                                            <img src={"/common/remove_icon.png"} onClick={removeRow} data-keyQst={k} data-keyAttr={k2}
                                                                data-part="opt" style={{ width: "30px" }}></img>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <img src={"/common/add-logo.png"} onClick={addRow} data-key={k} data-part="opt"
                                                style={{ width: "30px" }} ></img>
                                        </div>
                                        <br></br>

                                        <div className="div-90-border">
                                            <span className="text-decoration-underline">Checkboxs</span>
                                            <br></br>
                                            <br></br>
                                            {
                                                (v.chkboxs && v.chkboxs.length > 0) &&
                                                v.chkboxs.map((opt, k2) => {
                                                    return (
                                                        <div key={"chkboxs_" + k2} className="input-group mb-3">
                                                            <span className="input-group-text" id="basic-addon1">Name</span>
                                                            <input type="text" className="form-control"
                                                                data-keyQst={k} data-keyAttr={k2} data-attr="name" data-type="chkboxs"
                                                                onChange={changeAttr} value={opt.name} />

                                                            <span className="input-group-text" id="basic-addon1">Value</span>
                                                            <input type="text" className="form-control"
                                                                data-keyQst={k} data-keyAttr={k2} data-attr="value" data-type="chkboxs"
                                                                onChange={changeAttr} value={opt.value} />

                                                            <img src={"/common/remove_icon.png"} onClick={removeRow} data-keyQst={k} data-keyAttr={k2}
                                                                data-part="chkboxs" style={{ width: "30px" }}></img>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <img src={"/common/add-logo.png"} onClick={addRow} data-key={k} data-part="chkboxs"
                                                style={{ width: "30px" }} ></img>
                                        </div>
                                        <br></br>

                                        <div className="div-90-border">
                                            <span className="text-decoration-underline">Texte court</span>
                                            <br></br>
                                            <br></br>
                                            {
                                                (v.inputs && v.inputs.length > 0) &&
                                                v.inputs.map((opt, k2) => {
                                                    return (
                                                        <div key={"inpu_" + k + k2} className="input-group mb-3">
                                                            <span className="input-group-text" id="basic-addon1">Name</span>
                                                            <input type="text" className="form-control"
                                                                data-keyQst={k} data-keyAttr={k2} data-attr="name" data-type="inputs"
                                                                onChange={changeAttr} value={opt.name} />

                                                            <span className="input-group-text" id="basic-addon1">Value</span>
                                                            <input type="text" className="form-control"
                                                                data-keyQst={k} data-keyAttr={k2} data-attr="value" data-type="inputs"
                                                                onChange={changeAttr} value={opt.value} />

                                                            <img src={"/common/remove_icon.png"} onClick={removeRow} data-keyQst={k} data-keyAttr={k2}
                                                                data-part="inputs" style={{ width: "30px" }}></img>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <img src={"/common/add-logo.png"} onClick={addRow} data-key={k} data-part="inputs"
                                                style={{ width: "30px" }} ></img>
                                        </div>
                                        <br></br>

                                        <div className="div-90-border">
                                            <span className="text-decoration-underline">Texte long</span>
                                            <br></br>
                                            <br></br>
                                            {
                                                (v.textarea && v.textarea.length > 0) &&
                                                v.textarea.map((opt, k2) => {
                                                    return (
                                                        <div key={"txt_" + k + k2} className="input-group mb-3">
                                                            <span className="input-group-text" id="basic-addon1">Name</span>
                                                            <input type="text" className="form-control"
                                                                data-keyQst={k} data-keyAttr={k2} data-attr="name" data-type="textarea"
                                                                onChange={changeAttr} value={opt.name} />

                                                            <span className="input-group-text" id="basic-addon1">Value</span>
                                                            <input type="text" className="form-control"
                                                                data-keyQst={k} data-keyAttr={k2} data-attr="value" data-type="textarea"
                                                                onChange={changeAttr} value={opt.value} />

                                                            <img src={"/common/remove_icon.png"} onClick={removeRow} data-keyQst={k} data-keyAttr={k2}
                                                                data-part="textarea" style={{ width: "30px" }}></img>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <img src={"/common/add-logo.png"} onClick={addRow} data-key={k} data-part="textarea"
                                                style={{ width: "30px" }} ></img>
                                        </div>
                                        <br></br>


                                        <img src={"/common/add-logo.png"} onClick={addRow} data-key={k} data-part="qst"
                                            style={{ width: "30px" }} ></img>
                                    </li>
                                )
                            })
                        }

                        <img src={"/common/add-logo.png"} onClick={startRow} data-part="qst"
                            style={{ width: "30px" }} ></img>

                        <li className="list-group-item text-center">
                            <button type="button" className="btn btn-outline-success"
                                data-part="qst" onClick={save} disabled={saving}>
                                {saving && <span className="spinner-border spinner-border-sm"></span>}
                                Sauvegarder
                            </button>
                        </li>
                    </ul>
                </Fragment>
            }

        </div>
    )
}

export default FormMgt;