import { Fragment, useEffect, useState } from "react";
import CsContracts from "../../../services/customClient/CsContracts";
import errorManagement from "../../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../../functions/PageAnimation";
import { arrayRemoveElt, removeAndReturnArray } from "../../../functions/ArrayFunctions";
import { getFrFormat } from "../../../functions/StrFunctions";
import BusinessDao from "../../../services/machines/BusinessDao";
import ErrorMessage from "../../../common/smallComponents.js/ErrorMessage";

const Packing = (p) => {
    const [typePack, setTypePack] = useState({ "pack": false, "palet": false, "box": false, "envelope": false, "tube": false });
    const [labelPack] = useState({ "pack": "Colis", "palet": "Palette", "box": "Caisse", "envelope": "Enveloppe", "tube": "Tube" });
    const [list, setList] = useState();
    const [id, setId] = useState(null);
    const [saving, setSaving] = useState(false);
    const [existing, setExisting] = useState([]);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const emptyObj = { l_1: "", l_2: "", l_3: "", w_1: "", w_2: "", w_3: "", h_1: "", h_2: "", h_3: "", wght_1: "", wght_2: "", wght_3: "" };
    const envelope = { l_1: "370", l_2: "", l_3: "", w_1: "290", w_2: "", w_3: "", h_1: "", h_2: "", h_3: "", wght_1: "", wght_2: "", wght_3: "" };
    const [pack, setPack] = useState({
        pack: { ...emptyObj }, palet: { ...emptyObj }, box: { ...emptyObj },
        envelope: { ...envelope }, tube: { ...emptyObj }
    });
    const [othBusiness, setothBusiness] = useState([]);
    const [chooseBusiness, setchooseBusiness] = useState(false);

    const user = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        CsContracts.getPackingList(p.businessPack).then(
            (response) => {
                var arr = new Array();
                var cur = "";
                response.map(v => {
                    if (v.id !== cur) arr.push(v);
                    cur = v.id;
                });
                setExisting(arr);
                setList(response);
            },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        )
    }, []);

    /** */
    const onTypeChange = (e) => {
        var o = { ...typePack };
        o[e.target.getAttribute("id")] = e.target.checked;
        setTypePack(o);
    }

    /** */
    const setPackInfo = (e, type, field) => {
        var o = { ...pack };
        o[type][field] = e.target.value;
        setPack(o);
    }

    /** */
    const save = () => {
        var arr = new Array();
        Object.entries(typePack).map(v => {
            if (v[1]) {
                var o = { ...pack[v[0]] };
                o.type_pack = v[0];
                arr.push(o);
            }
        })

        p.setError();
        setSaving(true);
        CsContracts.savePackingList(p.businessPack, arr, id, othBusiness).then(
            () => {
                setSaving(false);
                p.setMsg("Sauvegardé et envoyé");
                p.setBusinessPack();
            },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
                setSaving(false);
            }
        )
    }

    /** */
    const updateCard = (e, id) => {
        if (e.target.checked) {
            setId(id);
            var _pack = { pack: { ...emptyObj }, palet: { ...emptyObj }, box: { ...emptyObj }, envelope: { ...emptyObj }, tube: { ...emptyObj } };
            var _typePack = { "pack": false, "palet": false, "box": false, "envelope": false, "tube": false };

            list.map(v => {
                if (v.id === id) {
                    _pack[v.type_pack] = v;
                    _typePack[v.type_pack] = true;
                }
            })

            setPack(_pack);
            setTypePack(_typePack);
        } else {
            newRequest();
        }
    }

    const newRequest = () => {
        setId(null);
        setPack({ pack: { ...emptyObj }, palet: { ...emptyObj }, box: { ...emptyObj }, envelope: { ...emptyObj }, tube: { ...emptyObj } });
        setTypePack({ "pack": false, "palet": false, "box": false, "envelope": false, "tube": false });
    }

    const addBusiness = (e) => {
        e.preventDefault();
        var business = e.target.business.value.trim().toUpperCase();
        setError();
        setLoading(true);

        BusinessDao.getBusinessInfoFocus(business).then(
            (res) => {
                if( !res.NOCDE ){
                    setError("Affaire introuvable!")
                }else{
                    var arr = [...othBusiness];
                    arr.push(business);
                    setothBusiness(arr);
                }
                
                setLoading(false);
            }, (error) => {
                setError("Affaire introuvable!")
                setLoading(false);
            }
        )
    }

    return <div className="packing">
        {!list && <div className="no-print"><ButtonWaiting />Recherche de fiche de colisage déjà effectués</div>}

        {
            list && <Fragment>
                {existing.length === 0 && <div>Aucune fiche de colisage trouvée</div>}
                {existing.length > 0 && <div>
                    <input type="checkbox" className="btn-check" id="label" disabled />
                    <label className="btn" htmlFor="label">Voir/Modifier la fiche du: </label>
                    {
                        existing.map((v, k) => {
                            return <Fragment key={"old" + k}>
                                <input type="checkbox" className="btn-check" id={"old" + k} autoComplete="off"
                                    onChange={(e) => updateCard(e, v.id)} checked={id === v.id} />
                                <label className="btn btn-outline-secondary ms-2" htmlFor={"old" + k}>{v.date}</label>
                            </Fragment>
                        })
                    }
                    <button className="btn btn-outline-secondary ms-2" onClick={newRequest}>Nouvelle demande</button>
                </div>}
            </Fragment>
        }

        <button className="btn btn-outline-info no-print" style={{ position: "absolute" }}
            onClick={() => p.setBusinessPack()}>Retourner à la liste</button>

        {/** Choice */}
        <div className="text-center no-print">
            <input type="checkbox" className="btn-check" id="label" disabled />
            <label className="btn" htmlFor="label">J'ai besoin de: </label>

            <input type="checkbox" className="btn-check" id="pack" autoComplete="off"
                onChange={onTypeChange} checked={typePack.pack} />
            <label className="btn btn-outline-secondary ms-2" htmlFor="pack">Colis</label>

            <input type="checkbox" className="btn-check" id="palet" autoComplete="off"
                onChange={onTypeChange} checked={typePack.palet} />
            <label className="btn btn-outline-secondary ms-2" htmlFor="palet">Palette</label>

            <input type="checkbox" className="btn-check" id="box" autoComplete="off"
                onChange={onTypeChange} checked={typePack.box} />
            <label className="btn btn-outline-secondary ms-2" htmlFor="box">Caisse</label>

            <input type="checkbox" className="btn-check" id="envelope" autoComplete="off"
                onChange={onTypeChange} checked={typePack.envelope} />
            <label className="btn btn-outline-secondary ms-2" htmlFor="envelope">Enveloppe bulle</label>

            <input type="checkbox" className="btn-check" id="tube" autoComplete="off"
                onChange={onTypeChange} checked={typePack.tube} />
            <label className="btn btn-outline-secondary ms-2" htmlFor="tube">Tube</label>
        </div>

        {/** Fill in */}
        <table className="table table-bordered border-dark mt-1 text-center fw-bold">
            <thead>
                <tr className="text-center">
                    <td colSpan={2}>
                        Affaire: <b>{p.businessPack}</b>
                        <div className="fw-bold">{othBusiness.join(", ")}</div>
                        <div className="no-print">
                            <a href="#" onClick={() => setchooseBusiness(true)}>Ajouter une affaire</a>
                        </div>
                    </td>
                    <td colSpan={3}>Document créé par <b>{user.trigram}</b> le <b>{getFrFormat()}</b></td>
                </tr>
            </thead>
            <tbody>
                {
                    Object.entries(typePack).map((v, k) =>
                        v[1] &&
                        <Fragment key={"k" + k}>

                            <tr className={v[0] + "-bg"}>
                                <td colSpan={2}>Type de colisage</td>
                                <td>{labelPack[v[0]]} 1</td>
                                <td>{labelPack[v[0]]} 2</td>
                                <td>{labelPack[v[0]]} 3</td>
                            </tr>
                            <tr className={v[0] + "-bg"}>
                                <td className="align-middle" rowSpan={v[0] !== "tube" && v[0] !== "envelope" ? 3 : 2}>Dimensions (mm)</td>
                                <td>Longueur (mm)</td>
                                <td>
                                    <input className="invisible-btn" onChange={(e) => setPackInfo(e, v[0], "l_1")}
                                        value={pack[v[0]].l_1}></input>
                                </td>
                                <td>
                                    <input className="invisible-btn" onChange={(e) => setPackInfo(e, v[0], "l_2")}
                                        value={pack[v[0]].l_2}></input>
                                </td>
                                <td>
                                    <input className="invisible-btn" onChange={(e) => setPackInfo(e, v[0], "l_3")}
                                        value={pack[v[0]].l_3}></input>
                                </td>
                            </tr>
                            <tr className={v[0] + "-bg"}>
                                <td>Largeur (mm)</td>
                                <td>
                                    <input className="invisible-btn" onChange={(e) => setPackInfo(e, v[0], "w_1")}
                                        value={pack[v[0]].w_1}></input>
                                </td>
                                <td>
                                    <input className="invisible-btn" onChange={(e) => setPackInfo(e, v[0], "w_2")}
                                        value={pack[v[0]].w_2}></input>
                                </td>
                                <td>
                                    <input className="invisible-btn" onChange={(e) => setPackInfo(e, v[0], "w_3")}
                                        value={pack[v[0]].w_3}></input>
                                </td>
                            </tr>
                            {
                                (v[0] !== "tube" && v[0] !== "envelope") &&
                                <tr className={v[0] + "-bg"}>
                                    <td>Hauteur (mm)</td>
                                    <td>
                                        <input className="invisible-btn" onChange={(e) => setPackInfo(e, v[0], "h_1")}
                                            value={pack[v[0]].h_1}></input>
                                    </td>
                                    <td>
                                        <input className="invisible-btn" onChange={(e) => setPackInfo(e, v[0], "h_2")}
                                            value={pack[v[0]].h_2}></input>
                                    </td>
                                    <td>
                                        <input className="invisible-btn" onChange={(e) => setPackInfo(e, v[0], "h_3")}
                                            value={pack[v[0]].h_3}></input>
                                    </td>
                                </tr>

                            }
                            <tr className={v[0] + "-bg"}>
                                <td colSpan={2}>Poids (kg)</td>
                                <td>
                                    <input className="invisible-btn" onChange={(e) => setPackInfo(e, v[0], "wght_1")}
                                        value={pack[v[0]].wght_1}></input>
                                </td>
                                <td>
                                    <input className="invisible-btn" onChange={(e) => setPackInfo(e, v[0], "wght_2")}
                                        value={pack[v[0]].wght_2}></input>
                                </td>
                                <td>
                                    <input className="invisible-btn" onChange={(e) => setPackInfo(e, v[0], "wght_3")}
                                        value={pack[v[0]].wght_3}></input>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={5}></td>
                            </tr>

                        </Fragment>
                    )
                }
            </tbody>
        </table>

        <div className="text-center no-print mb-3">
            <button className="btn btn-outline-secondary me-3" onClick={(e) => window.print()}>Imprimer</button>
            <button className="btn btn-outline-secondary"
                onClick={save} disabled={saving}>
                {saving && <ButtonWaiting />}
                Envoyer</button>
        </div>

        {chooseBusiness && <div className="custom-tooltip-v2">
            <div className="modal-body blue-purple-bg">
                <button type="button" className="btn-close" onClick={() => setchooseBusiness(false)}></button>
                {error && <ErrorMessage error={error} errorState={setError}/>}
                
                <form onSubmit={addBusiness}>
                    <h5 className="text-center">Saisissez le code affaire</h5>
                    <div className="input-group mb-3">
                        <input type="text" className="form-control text-center fw-bold"
                            placeholder="Code affaire" name="business" autoFocus/>
                        <button className="btn btn-success" disabled={loading}>
                            {loading && <ButtonWaiting />}
                            Ajouter</button>
                    </div>
                </form>

                <ul className="list-group">
                    {othBusiness.map((v, k) => {
                        return <li className="list-group-item" key={"oth_" + k}>
                            {v} 
                            <button type="button" className="btn-close" 
                                onClick={() => setothBusiness( removeAndReturnArray(othBusiness, k) )}></button>
                        </li>
                    })}
                </ul>

                <div className="text-center mt-3">
                    <button className="btn btn-success" onClick={() => setchooseBusiness(false)}>Terminer</button>
                </div>
            </div>
        </div>

        }
    </div>
}

export default Packing;