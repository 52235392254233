
const Explanations = (p) => {

    return <div>
        <br></br>
        <h4 className="text-center">Méthode de calcul de dimensions de caisses</h4>
        <br></br>
        <ul style={{ marginLeft: "10px" }}>
            <li className="fw-bold">FEFCO 0200</li>
            <ul>
                <li>L (int) = L (ext) - 2 x cannelure</li>
                <li>L (ext) = L (int) + 2 x cannelure</li>
                <li>W (int) = W (ext) - 2 x cannelure</li>
                <li>W (ext) = W (int) + 2 x cannelure</li>
                <li>H (int) = H (ext) - 2 x cannelure</li>
                <li>H (ext) = H (int) + 2 x cannelure</li>
            </ul>

            <li className="fw-bold">FEFCO 0701</li>
            <ul>
                <li>L (int) = L (ext) - 2 x cannelure</li>
                <li>L (ext) = L (int) + 2 x cannelure</li>
                <li>W (int) = W (ext) - 2 x cannelure</li>
                <li>W (ext) = W (int) + 2 x cannelure</li>
                <li>H (int) = H (ext) - cannelure</li>
                <li>H (ext) = H (int) + cannelure</li>
            </ul>

            <li className="fw-bold">FEFCO 0453, Super-Eco Int., I-Pack, Super-Eco Ext.</li>
            <ul>
                <li>L (int) = L (ext) - 4 x cannelure</li>
                <li>L (ext) = L (int) + 4 x cannelure</li>
                <li>W (int) = W (ext) - 2 x cannelure</li>
                <li>W (ext) = W (int) + 2 x cannelure</li>
                <li>H (int) = H (ext) - cannelure</li>
                <li>H (ext) = H (int) + cannelure</li>
            </ul>

            <li className="fw-bold">Machines IN</li>
            <ul>
                <li>hmaxi = h ext - (cannelure x 10)</li>
            </ul>

            <li className="fw-bold">Machines E-CUBE</li>
            <ul>
                <li>hmaxi = hext - (w ext/2 +15)</li>
                <li>hmini = hext - (w ext - 10)</li>
            </ul>

            <li className="fw-bold">Special case</li>
            <ul>
                <li><b>If</b> cannelure_caisse &#60; 3 <b>then</b>  h_ext_ap_red_coiffe_max = h_dim_ext – 30<br></br>
                    <b>else</b> h_ext_ap_red_coiffe_max = h_dim_ext – ( 10 x cannelure )</li>
            </ul>

        </ul>
        <br></br>
    </div>
}

export default Explanations