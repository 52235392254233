import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import { Link } from "react-router-dom";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { login, logout } from "../../actions/auth";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Login = (props) => {
  const { t } = useTranslation();

  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);
  const[urlBack, setUrlBack] = useState(null);

  const dispatch = useDispatch();

  useEffect( () => {
      if( localStorage.getItem("url_back") !== null ){
        setUrlBack( localStorage.getItem("url_back") );
        localStorage.removeItem("url_back");
      }
  }, []);

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(username, password))
        .then(() => {
          if( urlBack ){
            props.history.push( urlBack );
          }else{
            props.history.push("/home");
          }
          
          window.location.reload();
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const [locale, setLocale] = useState(i18n.language);
  i18n.on('languageChanged', (lng) => setLocale(i18n.language));
  function changeLocale (l) {
    if (locale !== l) {
      i18n.changeLanguage(l);
    }
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Simple tooltip
    </Tooltip>
  );

  /*if (isLoggedIn) {
    return <Redirect to="/profile" />;
  }*/

  return (
    <div>
      <h2 className="text-center">{ t('welcome') }</h2>

      <div className="col-md-12">
        <div className="card card-container">
          <img
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            alt="profile-img"
            className="profile-img-card"
          />

          <Form onSubmit={handleLogin} ref={form}>
            <div className="form-group">
              <label htmlFor="username">{ t('username') }</label>
              <Input
                type="text"
                className="form-control"
                name="username"
                value={username}
                onChange={onChangeUsername}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">{ t('password') }</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                value={password}
                onChange={onChangePassword}
                validations={[required]}
              />
            </div>
            <br></br>
            <div className="form-group text-center">
              <button className="btn btn-primary btn-block" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>{t('login')}</span>
              </button>
            </div>

            <Link to={"/password-forgotten"}>
              {t('forgotten password')}
            </Link>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
        </div>
      </div>
      <br/>
     
        <div className="text-center">
          <Link to={"/register"} className="btn btn-info">{ t('not registered') }</Link>

          <br/>
          <br/>
          <OverlayTrigger placement="top"
              overlay={ <Tooltip>Basculer en français</Tooltip> }>
              <a href="#" onClick={() => changeLocale('fr')}>
                <img src="/common/fr_lang.png"/>
              </a>
          </OverlayTrigger>
          
          <OverlayTrigger placement="top"
              overlay={ <Tooltip>Switch to English language</Tooltip> }>
              <a href="#" onClick={() => changeLocale('en')}>
                <img src="/common/en_lang.png"/>
              </a>
          </OverlayTrigger>
        </div>
      

    </div>
  );
};

export default Login;
