import { useEffect, useState } from "react";
import BusinessDao from "../../../services/machines/BusinessDao";
import { ButtonWaiting } from "../../../functions/PageAnimation";

const BusinessSearch = (p) => {
    const yearCode = String.fromCharCode(67 + (new Date().getFullYear() - 2016));
    const [searching, setSearching] = useState(false);
    const [businessList, setBusinessList] = useState();

    const user = JSON.parse(localStorage.getItem("user"));
    const hOnly = user && (!user.roles.includes("ROLE_STOREHOUSE") && !user.roles.includes("ROLE_ADMIN"));

    useEffect(() => {
        setBusinessList([{ NOCDE: "AATE01H" },
        { NOCDE: "GATE" + yearCode + "000" },
        { NOCDE: "GSCL" + yearCode + "000" },
        { NOCDE: "REMB" + yearCode + "100" }]);
    }, []);

    const searchBusiness = (e) => {
        e.preventDefault();
        var business = e.target.business.value.toUpperCase();
        business = business.substring(0, Math.min(7, business.length));
        setBusinessList();

        setSearching(true);
        BusinessDao.searchInFocus(business).then(
            (response) => {
                var arr = [];
             
                if(hOnly){
                    response.map(v => { if (v.NOCDE.endsWith("H")) arr.push(v) });
                }else{
                    arr = response;
                }               

                setBusinessList(arr)
                setSearching(false);
            }
        );
    }

    const selectBusiness = (e) => {
        /*

        var arr = [...p.hours];
        arr[p.businessSearch.row].business = businessList[k].NOCDE.trim();
        arr[p.businessSearch.row].label = businessList[k].DESIE.trim();
        arr[p.businessSearch.row].machineSearch = true;
        p.setHours(arr);
        p.setBusinessSearch();
        delete arr[p.businessSearch.row].machineList;

        var choosenMachine = arr[p.businessSearch.row].function;
        //Search machines
        BusinessDao.getMachines(businessList[k].NOCDE.trim().substring(0, 7) + "0").then(
            (response) => {
                var flag = false;
                for (let i = 0; i < response.length; i++)
                    if (response[i].no_machine.trim().endsWith(choosenMachine)) flag = true;

                if (!flag) arr[p.businessSearch.row].function = null;

                arr[p.businessSearch.row].machineList = response;
                delete arr[p.businessSearch.row].machineSearch;
                p.setHours([...arr]);
            },
            (error) => {
            }
        );*/
        var k = e.target.getAttribute("data-key");
        p.getMachines(businessList[k].NOCDE.trim());
    }

    return <div className="custom-tooltip">
        <button type="button" className="btn-close" aria-label="Close" onClick={() => p.setBusinessSrch()}></button>
        <h5 className="text-center">Rechercher une affaire</h5>
        <div className="text-center">
            <span className="fst-italic">Remarque: seuls les affaires en H sont autorisés</span>
        </div>

        <form onSubmit={searchBusiness}>
            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">Code affaire</span>
                <input type="text" className="form-control fw-bold text-center"
                    name="business" autoComplete="off" id="businessSearchIpt" autoFocus required />
                <button className="btn btn-success" disabled={searching}>
                    {searching && <ButtonWaiting />}
                    Chercher
                </button>
            </div>
        </form>

        {
            businessList && <ul className="list-group text-center fw-bold" style={{ maxHeight: "200px", overflowY: "auto" }}>
                {
                    businessList.map((v, k) => {
                        return <li key={"busin" + k} data-key={k} onClick={selectBusiness}
                            className="list-group-item selectionHover">{v.NOCDE}</li>
                    })
                }
            </ul>
        }
    </div>
}

export default BusinessSearch;