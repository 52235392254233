
const CustService = (props) => {
    return (
        < div className="table-responsive">
            <table className="table businessCardStyle">
                <thead>
                    <tr className="table-dark text-center rounded">
                        <th scope="col">Customer service</th>
                        <th scope="col">Liabilities</th>
                        <th scope="col">Other contractual Options & specifications</th>
                    </tr>
                </thead>
                <tbody>
                    <tr >
                        {/** CUSTOMER SERVICE */}
                        <td className="border-end" style={{width: "33.33%"}}>
                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{ width: "100px" }}>Spare parts</span>
                                <select className="form-select" readOnly={props.readOnly}
                                    onChange={props.updateBusinessField} defaultValue={props.businessInfo.lot_piece_det_en}
                                    data-field="lot_piece_det_en">
                                    <option value="">Choose...</option>
                                    <option value="Not sold">Not sold</option>
                                    <option value="Sold">Sold</option>
                                </select>

                                <span className="input-group-text" style={{ width: "100px" }}>Amount</span>
                                <input type="text" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.prix_vente_piece_det}
                                    data-field="prix_vente_piece_det" />
                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{ width: "100px" }}>Warranty</span>
                                <input type="text" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.garantie_aff_en}
                                    data-field="garantie_aff_en" list="duree_garantie" />

                                <span className="input-group-text" style={{ width: "200px" }}>Warranty description</span>
                                <input type="text" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.desc_garantie}
                                    data-field="desc_garantie" />
                            </div>
                        </td>

                        {/** LIABILITIES */}
                        <td className="border-end">
                            <div className="form-floating mb-3">
                                <textarea className="form-control" placeholder="xxx"
                                    style={{ minHeight: "100px" }} readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.penalite_retard}
                                    data-field="penalite_retard"></textarea>
                                <label className="fw-bold">Delay penalties</label>
                            </div>

                            <div className="form-floating mb-3">
                                <textarea className="form-control" placeholder="xxx"
                                    style={{ minHeight: "100px" }} readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.penalite_perf}
                                    data-field="penalite_perf"></textarea>
                                <label className="fw-bold">Performance penalties</label>
                            </div>
                        </td>

                        {/** OTHER CT SPEC */}
                        <td className="border-end">
                            <div>
                                <textarea className="form-control" placeholder="xxx"
                                    style={{ minHeight: "220px" }} readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.oth_ct_spec}
                                    data-field="oth_ct_spec"></textarea>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default CustService;