import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { arrayRemoveElt, getKeyString, removeAndReturnArray } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import getUrlParam, { getFrFormat } from "../../functions/StrFunctions";
import errorManagement from "../../services/errorManagement";
import BusinessDao from "../../services/machines/BusinessDao";
import transport from "../../services/supplyChain/transport";
import isEmail from "validator/lib/isEmail";

const NotifyPackExpedition = (props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [list, setList] = useState();
    const [recipient, setRecipient] = useState([]);

    useEffect(() => {
        var exp_id = getUrlParam(props, "exp_id");

        transport.getFullExpeditionInfo(exp_id).then(
            (r) => {
                console.log(r)
                setList(r.list);
                setRecipient([r.recipient]);
                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            } )   

    }, []);

    const onDateChange = (e, k) => {
        var value = e.target.value;

        var arr = [...list];
        arr[k].expDate = value;

        setList(arr);
    }

    const notify = () => {
        var toNotify = [];

        for (let i = 0; i < list.length; i++)
            if (list[i].expDate && list[i].expDate !== "") toNotify.push(list[i]);

        var err = "";
        //Check if at least one transportation is set
        if (toNotify.length === 0) 
            err = "Saisissez au moins une date<br>";
            
        //Check each reception address
        for( let i=0; i<recipient.length; i++ )
            if( !isEmail( recipient[i] ) )
                err += recipient[i] + " n'est pas une adresse valide"
        
        if( err !== "" ){
            setError(err);
            scrollToTop();
            return;
        }

        //detail_id
        transport.notifyExpeditionPack(toNotify, recipient).then(
            (r) => {
                setMsg("Mail envoyé !");
                scrollToTop();
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }

    const addRecipient = () => {
        var arr = [...recipient];
        arr.push("");
        setRecipient(arr);
    }

    const onRecipientChange = (e, k) => {
        var arr = [...recipient];
        arr[k] = e.target.value;
        setRecipient(arr);
    }

    return <Fragment>
        <h3 className="text-center">Notification d'expédition de colis</h3>
        <br></br>

        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} />}
        {loading && <WaitingRoundSnippers />}

        <div className="d-flex flex-wrap">
            <div style={{ width: "500px" }} className="ms-auto me-3 text-center">
                <table className="table">
                    <thead>
                        <tr className="table-secondary text-center">
                            <th>Type</th>
                            <th>Date d'expédition</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list && list.map((v, k) => {
                            return <tr key={k}>
                                <td>{v.type.trim()} {k + 1}</td>
                                <td>
                                    {v.truck_load_real && getFrFormat(v.truck_load_real, true)}
                                    {!v.truck_load_real &&
                                        <input type="date" className="form-control"
                                            onBlur={(e) => onDateChange(e, k)} />}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>

            </div>

            <div style={{ width: "300px" }} className="ms-3 me-auto text-center">
                <h6 className="text-center">Destinataires</h6>

                {recipient.map((v, k) => {
                    return <div key={"rec" + k} className="mb-3 d-flex">
                        <input className="form-control" value={v} onChange={(e)=>onRecipientChange(e, k)}/>
                        { k > 0 && <img src="/common/remove_icon.png" className="see-img w-30"
                            onClick={()=>setRecipient(removeAndReturnArray(recipient, k))}/>}
                    </div>
                })}

                <button className="btn btn-outline-secondary" onClick={addRecipient}>Ajouter destinataire</button>
            </div>

        </div>

        <div className="text-center">
            <button className="btn btn-success" onClick={notify}>Notifier</button>
        </div>


    </Fragment>

}

export default NotifyPackExpedition;