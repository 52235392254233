
import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL + "machines/info";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && "\"Bearer " + user.accessToken + "\"";

/**
 * Search by business or machine
 * @param {*} term 
 * @returns 
 */
const getAllMachineInfo = (term) => {
    return axios
        .post(API_URL + "/get/all",
            {
                fake_header: authHeader(),
                term: term
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getOneMachineInfo = (term) => {
    return axios
        .post(API_URL + "/get/one",
            {
                fake_header: authHeader(),
                term: term
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getRevisions = (machine) => {
    return axios
        .post(API_URL + "/get/revisions",
            {
                fake_header: authHeader(),
                machine: machine
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getSibling = (machine) => {
    return axios
        .post(API_URL + "/get/sibling",
            {
                fake_header: authHeader(),
                machine: machine
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getDropDownMenu = () => {
    return axios
        .post(API_URL + "/get/drop-down-menu",
            {
                fake_header: authHeader()
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getSubsets = (ref) => {
    return axios
        .post(API_URL + "/bom/subset/exists",
            {
                fake_header: authHeader(),
                ref: ref
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateMachineField = (machine, field, value, typeField) => {
    return axios
        .post(API_URL + "/update-field",
            {
                fake_header: authHeader(),
                machine: machine,
                field: field,
                value: value,
                typeField: typeField
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const duplicateMachine = (machine, machine_to_duplicate) => {
    return axios
        .post(API_URL + "/duplicate/machine",
            {
                fake_header: authHeader(),
                machine: machine,
                machine_to_duplicate: machine_to_duplicate
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getBoxes = (machines) => {
    return axios
        .post(API_URL + "/get/boxes",
            {
                fake_header: authHeader(),
                machines: machines
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const copyBoxes = (machine, boxes) => {
    return axios
        .post(API_URL + "/copy/boxes",
            {
                fake_header: authHeader(),
                machine: machine,
                boxes: boxes
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const createBox = (machine, track, box) => {
    return axios
        .post(API_URL + "/create/box",
            {
                fake_header: authHeader(),
                machine: machine,
                track: track,
                box: box
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const deleteBox = (machine, track, box) => {
    return axios
        .post(API_URL + "/delete/box",
            {
                fake_header: authHeader(),
                machine: machine,
                track: track,
                box: box
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateBox = (machine, field, value, track, box) => {
    return axios
        .post(API_URL + "/update-field/box",
            {
                fake_header: authHeader(),
                machine: machine,
                field: field,
                value: value,
                track: track,
                box: box
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateBoxDimensions = (o) => {
    return axios
        .post(API_URL + "/update-field/box/dimensions",
            {
                fake_header: authHeader(),
                box: o
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateDp = (field, value, id) => {
    return axios
        .post(API_URL + "/update-field/dropdown-menu",
            {
                fake_header: authHeader(),
                field: field,
                value: value,
                id: id
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const addDp = (field) => {
    return axios
        .post(API_URL + "/dropdown-menu/add",
            {
                fake_header: authHeader(),
                field: field
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateLid = (machine, field, value) => {
    return axios
        .post(API_URL + "/update-field/lid",
            {
                fake_header: authHeader(),
                machine: machine,
                field: field,
                value: value
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateGap = (machine, field, value) => {
    return axios
        .post(API_URL + "/update-field/gap",
            {
                fake_header: authHeader(),
                machine: machine,
                field: field,
                value: value
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getOptions = (machine) => {
    return axios
        .post(API_URL + "/get/options",
            {
                fake_header: authHeader(),
                machine: machine
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const addOption = (machine, type) => {
    return axios
        .post(API_URL + "/add/option",
            {
                fake_header: authHeader(),
                machine: machine,
                type_option: type
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateOption = (id, value) => {
    return axios
        .post(API_URL + "/update/option",
            {
                fake_header: authHeader(),
                id: id,
                value: value
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateOptionDp = (id, field, value) => {
    return axios
        .post(API_URL + "/options/update",
            {
                fake_header: authHeader(),
                id: id,
                field: field,
                value: value
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const addOptionDp = (type) => {
    return axios
        .post(API_URL + "/options/dp/add",
            {
                fake_header: authHeader(),
                type: type
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const deleteOptionDp = (id) => {
    return axios
        .post(API_URL + "/options/dp/delete",
            {
                fake_header: authHeader(),
                id: id
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getAllOptions = () => {
    return axios
        .post(API_URL + "/options/get-all",
            {
                fake_header: authHeader()
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const deleteOption = (id) => {
    return axios
        .post(API_URL + "/delete/option",
            {
                fake_header: authHeader(),
                id: id
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const deleteMachine = (machine, id_monday) => {
    return axios
        .post(API_URL + "/delete/machine",
            {
                fake_header: authHeader(),
                machine: machine,
                id_monday: id_monday
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const deleteDp = (id) => {
    return axios
        .post(API_URL + "/dropdown-menu/remove",
            {
                fake_header: authHeader(),
                id: id
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const exportMachineCard = (o) => {
    return axios
        .post(API_URL + "/export",
            {
                fake_header: authHeader(),
                ...o
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const exportTechnicalSpec = (machine) => {
    return axios
        .post(API_URL + "/technical-spec",
            {
                fake_header: authHeader(),
                machine: machine
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getMachinesCat = () => {
    return axios
        .post(API_URL + "/get/machines-categories",
            {
                fake_header: authHeader()
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateMachinesCat = (field, value, type_config) => {
    return axios
        .post(API_URL + "/update/machine-category",
            {
                fake_header: authHeader(),
                field: field,
                value: value,
                typ_config: type_config
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const addMachinesCat = (o) => {
    return axios
        .post(API_URL + "/add/machine-category",
            {
                fake_header: authHeader(),
                ...o
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const deleteMachinesCat = (type_config) => {
    return axios
        .post(API_URL + "/delete/machine-category",
            {
                fake_header: authHeader(),
                typ_config: type_config
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const saveCartonnier = (o) => {
    return axios
        .post(API_URL + "/update/cartonnier",
            {
                fake_header: authHeader(),
                ...o
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getPlanning = (o) => {
    return axios
        .post(API_URL + "/general-planning/get",
            {
                fake_header: authHeader(),
                ...o
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updatePlanning = () => {
    return axios
        .post(API_URL + "/general-planning/update",
            {
                fake_header: authHeader()
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getMachinePwd = (dwCode) => {
    return axios
        .post(API_URL + "/machine/get-pwd",
            {
                fake_header: authHeader(),
                dwCode: dwCode
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getAnnexes = (code) => {
    return axios
        .post(API_URL + "/get/annexes",
            {
                fake_header: authHeader(),
                business: code
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const deleteAnnexe = (machine, fileName) => {
    return axios
        .post(API_URL + "/delete-annexe",
            {
                fake_header: authHeader(),
                machine: machine,
                file: fileName
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

export default {
    getAllMachineInfo,
    getOneMachineInfo,
    getSubsets,
    updateMachineField,
    getDropDownMenu,
    createBox,
    updateBox,
    deleteBox,
    updateDp,
    updateBoxDimensions,
    updateLid,
    updateGap,
    getOptions,
    addOption,
    updateOption,
    deleteOption,
    getSibling,
    duplicateMachine,
    getBoxes,
    copyBoxes,
    deleteMachine,
    addDp,
    deleteDp,
    getAllOptions,
    updateOptionDp,
    getRevisions,
    exportMachineCard,
    exportTechnicalSpec,
    getMachinesCat,
    updateMachinesCat,
    deleteMachinesCat,
    addOptionDp,
    deleteOptionDp,
    saveCartonnier,
    addMachinesCat,
    getPlanning,
    getMachinePwd,
    getAnnexes,
    deleteAnnexe,
    updatePlanning
}