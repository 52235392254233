import { Fragment, useState } from "react";
import LabelsChoice from "../../../common/smallComponents.js/LabelsChoice";
import { downloadSingleFile, downloadTempFile } from "../../../services/functions/FilesProcess";
import { getXlsxSheetContent } from "../../../functions/XlsxMgt";
import Labels from "../../../services/storehouse/Labels";
import errorManagement from "../../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../../functions/PageAnimation";

const ExcelTags = (p) => {
    const [list, setList] = useState();
    const [downloading, setDownloading] = useState(false);

    /** */
    const downloadTemplate = () => {
        downloadSingleFile("templates/storehouse", "labels_template.xlsm");
    }

    /** */
    const readFile = (e) => {
        setList();

        var file = e.target.files;
        if (file.length === 0) return;

        file = file[0];
        var arr = new Array();
        getXlsxSheetContent(file, 1, function (res) {
            var o;
            res.map((v, k) => {
                if (k > 0) {
                    o = v.row._cells;
                    arr.push({
                        ar: o[0]._value.value,
                        ref: o[1]._value.value,
                        label: o[2]._value.value,
                        loc: o[3]._value.value,
                        qty: o[4]._value.value,
                        business: o[5]._value.value,
                        machine: o[6]._value.value,
                        se: o[7]._value.value
                    });
                }

            });

            setList(arr);
            document.getElementById("file").value = "";
        });


    }

    /** */
    const download = () => {
        p.setError();

        setDownloading(true);
        if (list.length === 0) {
            p.setError("Saisissez au moins une ligne.");
            return;
        }

        Labels.excelLabels(list, p.start).then(
            (res) => {
                downloadTempFile(res);
                setDownloading(false);
            }, (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
                setDownloading(false);
            }
        );
    }

    return <Fragment>
        <h5 className="text-center mb-3">Génération d'étiquettes depuis Excel</h5>

        <h6 className="text-center mb-3">Choisissez le fichier Excel, ainsi que l'emplacement de départ des étiquettes</h6>

        <div className="display-flex">
            <div className="col-3">
                <LabelsChoice start={p.start} onChooseTag={(e) => p.setStart(e)} />
            </div>

            <div className="col-6">
                <div className="input-group mb-3 col-6">
                    <input type="file" className="form-control" id="file" onChange={readFile} />
                </div>
            </div>

            <div className="col-3 text-center cursor-pointer" onClick={downloadTemplate}>
                <img src="/common/download_icon.png" style={{ width: "80px" }}></img><br></br>
                <span>Fichier modèle perdu ? Télécharge le !</span>
            </div>
        </div>

        {
            list && <Fragment>
                <div className="text-center">
                    <button className="btn btn-success" onClick={download} disabled={downloading}>
                        {downloading && <ButtonWaiting />}
                        Télécharger les étiquettes
                    </button>
                </div>

                <div className="col-10 offset-1">
                    <table className="table">
                        <thead>
                            <tr className="text-center">
                                <th>AR</th>
                                <th>Plan</th>
                                <th>Ref</th>
                                <th>Emp</th>
                                <th>Qte</th>
                                <th>Affaire</th>
                                <th>Machine</th>
                                <th>S/E</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((v, k) => {
                                return <tr key={k}>
                                    <td>{v.ar}</td>
                                    <td>{v.ref}</td>
                                    <td>{v.label}</td>
                                    <td>{v.loc}</td>
                                    <td>{v.qty}</td>
                                    <td>{v.business}</td>
                                    <td>{v.machine}</td>
                                    <td>{v.se}</td>
                                </tr>
                            })
                            }
                        </tbody>
                    </table>
                </div>
            </Fragment>
        }

    </Fragment>

}

export default ExcelTags;