import { Fragment, useEffect, useState } from "react";
import CsContracts from "../../../services/customClient/CsContracts";
import errorManagement from "../../../services/errorManagement";
import { scrollToTop } from "../../../functions/PageAnimation";
import WaitingRoundSnippers from "../../../common/smallComponents.js/WaitingRoundSnippers";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../../common/smallComponents.js/ReactTable";
import { getDateTimeFromDb } from "../../../functions/StrFunctions";
import { arrayRemoveElt } from "../../../functions/ArrayFunctions";

const IncidentDefaults = (p) => {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);

    useEffect(() => {
        CsContracts.getDefaultsList().then(
            (r) => {
                setList(r)
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(p.props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }, []);

    const onChangeOrDelete = (e, k, field) => {
        var o = new Object();
        o.id = list[k].id;

        var arr = [...list];

        if (field !== null) {
            o.type = "update";
            o.field = field;
            o.value = e.target.value;
            arr[k][field] = e.target.value;
        } else {
            o.type = "delete";
            arr = arrayRemoveElt(arr, k);
        }

        setList(arr);

        updateDb(o);
    }

    const addDefault = (e) => {
        e.preventDefault();

        var o = new Object();
        o.type = "add";
        o.broken_art = parseInt( e.target.broken_art.value );
        o.type_machine = e.target.type_machine.value;
        o.label = e.target.label.value;
  
        updateDb(o);
        document.getElementsByName("broken_art")[0].value = "";
        document.getElementsByName("type_machine")[0].value = "";
        document.getElementsByName("label")[0].value = "";

        var arr = [...list];
        o.id = -1;
        arr.push(arr);
        setList(arr);
    }

    const updateDb = (o) => {
        CsContracts.updateDefaults(o).then(
            () => { },
            (error) => {
                setError(errorManagement.handleError(p.props, error));
                scrollToTop();
            }
        )
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('id', {
            header: '#',
            cell: i => <div className="text-center fw-bold">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('broken_art', {
            header: 'Type de ticket',
            cell: i => <div className="text-center">{i.getValue() === 0 ? "Incident" : "Pièces cassées"}</div>
        }));

        arr.push(columnHelper.accessor('typ_machine', {
            header: 'Type de machine concernée',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('label', {
            header: 'Libellé du défaut',
            cell: i => <div className="text-center">
                <input className="form-control" defaultValue={i.getValue()}
                    style={{ minWidth: "300px" }} onBlur={(e) => onChangeOrDelete(e, i.row.id, "label")}></input>
            </div>
        }));

        arr.push(columnHelper.accessor('xx', {
            header: '',
            cell: i => <div className="text-center">
                <button className="btn btn-outline-danger"
                    onClick={() => onChangeOrDelete(null, i.row.id, null)}>Supprimer</button>
            </div>
        }));

        return arr;
    }
    //Set columns
    const columns = getColumns();

    return <Fragment>
        <h6 className="text-center">Liste des défauts du menu déroulant</h6>
        {loading && <WaitingRoundSnippers />}



        {!loading && <Fragment>
            <form onSubmit={addDefault} id="default-form">
                <div className="d-flex">
                    <div className="input-group mb-3 me-5">
                        <span className="input-group-text">Type de ticket</span>
                        <select className="form-select" name="broken_art" required>
                            <option value=""></option>
                            <option value="0">Incident</option>
                            <option value="1">Pièces cassées</option>
                        </select>

                        <span className="input-group-text">Type de machine concernée</span>
                        <select className="form-select" name="type_machine" required>
                            <option value=""></option>
                            <option value="BA_TECMA_18_20_24">BA_TECMA_18_20_24</option>
                            <option value="BA_TECMA_30_40">BA_TECMA_30_40</option>
                            <option value="BA15P">BA15P</option>
                            <option value="EC08">EC08</option>
                            <option value="EC16">EC16</option>
                            <option value="FORMEUSE_CAISSE_LANTEC">FORMEUSE_CAISSE_LANTEC</option>
                            <option value="IK">IK</option>
                            <option value="IN">IN</option>
                            <option value="LN">LN</option>
                            <option value="POSE_CALE">POSE_CALE</option>
                            <option value="POSE_COIFFE">POSE_COIFFE</option>
                            <option value="WN">WN</option>
                        </select>

                        <span className="input-group-text">Libellé du défaut</span>
                        <input type="text" className="form-control" name="label"/>

                        <button type="submit" className="btn btn-success">Ajouter</button>
                    </div>
                </div>
            </form>

            <ReactTable columns={columns} data={list} show={500}
                origin="cs_incident_defaults" classes={"no-padding fixFstCol"} top0={true} />
        </Fragment>}
    </Fragment>

}

export default IncidentDefaults;