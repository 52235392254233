import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Admin = (props) => {
    const { t } = useTranslation();

    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Admin
            </a>
            <ul className="dropdown-menu " aria-labelledby="navbarDropdown">
                <li>
                    <a href={"/settings/get-roles"} className="dropdown-item">
                        {t('role management')}
                    </a>
                </li>

                <li>
                    <a href={"/settings/user-management"} className="dropdown-item">
                        {t('user management')}
                    </a>
                </li>

                <li>
                    <a href={"/settings/contacts-mng"} className="dropdown-item">
                        {t('contact management')}
                    </a>
                </li>

                <li>
                    <a href={"/settings/form-mng/v1"} className="dropdown-item">
                        Form management
                    </a>
                </li>

                <li>
                    <a href={"/annoucement"} className="dropdown-item">
                        Annonces
                    </a>
                </li>

                <li>
                    <a href={"/navigation/management"} className="dropdown-item">
                        Gestion de la navigation de l'intranet
                    </a>
                </li>


            </ul>
        </li>
    )

}

export default Admin;