
const PartEight = (props) => {
    return (
        < div className="table-responsive">
            <table className="table businessCardStyle">
                <thead>
                    <tr className="bg-dark bg-gradient text-white text-center rounded">
                        <th scope="col">Documents</th>
                        <th scope="col">Planning</th>
                        <th scope="col">Limites prestations</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {/** Services clients */}
                        <td style={{ width: "33.3%" }} className="border-end">
                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={props.businessInfo.cde_client}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="cde_client"
                                        readOnly={props.readOnly} />
                                    <label>Commande client</label>
                                </div>

                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={props.businessInfo.offre_cli}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="offre_cli"
                                        readOnly={props.readOnly} />
                                    <label>Offre B+</label>
                                </div>

                            </div>

                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={props.businessInfo.spec_client}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="spec_client"
                                        readOnly={props.readOnly} />
                                    <label>Spéc. client</label>
                                </div>

                                <div className="form-floating mb-3">
                                <input type="text" className="form-control" value={props.businessInfo.revue_contrat}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="revue_contrat"
                                        readOnly={props.readOnly} />
                                    <label>Revue contrat</label>
                                </div>

                            </div>

                        </td>

                        {/** Autres */}
                        <td style={{ width: "33.3%" }} className="border-end">
                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="date" className="form-control" value={props.businessInfo.date_enlev}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="date_enlev"
                                        readOnly={props.readOnly} />
                                    <label>Date enlèvement</label>
                                </div>

                                <div className="form-floating mb-3">
                                    <input type="date" className="form-control" value={props.businessInfo.deb_mes_site}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="deb_mes_site"
                                        readOnly={props.readOnly} />
                                    <label>Début MeS sur site</label>
                                </div>

                            </div>

                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="date" className="form-control" value={props.businessInfo.date_livraison}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="date_livraison"
                                        readOnly={props.readOnly} />
                                    <label>Date livraison</label>
                                </div>

                                <div className="form-floating mb-3">
                                    <input type="date" className="form-control" value={props.businessInfo.deb_mep}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="deb_mep"
                                        readOnly={props.readOnly} />
                                    <label>Début MeP</label>
                                </div>

                            </div>

                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="date" className="form-control" value={props.businessInfo.date_fat}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="date_fat"
                                        readOnly={props.readOnly} />
                                    <label>Date FAT</label>
                                </div>

                                <div className="form-floating mb-3">
                                <textarea type="text" className="form-control" value={props.businessInfo.com_dates}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="com_dates"
                                        readOnly={props.readOnly}></textarea>
                                    <label>Commentaires</label>
                                </div>
                            </div>
                        </td>

                        {/** Particularités */}
                        <td style={{ width: "33.3%" }}>
                            
                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={props.businessInfo.emb_aff}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="emb_aff" list="emb_aff" data-translate="true"
                                        readOnly={props.readOnly}/>
                                    <label>Emballage</label>
                                </div>

                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={props.businessInfo.transp_aff}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" list="transp_aff" data-field="transp_aff" data-translate="true"
                                        readOnly={props.readOnly} />
                                    <label>Transport</label>
                                </div>
                            </div>
                            
                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={props.businessInfo.lang_notice}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="lang_notice" list="langue" data-translate="true"
                                        readOnly={props.readOnly}/>
                                    <label>Langue notice</label>
                                </div>

                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={props.businessInfo.decharg_manu}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="decharg_manu" list="decharg_manu" data-translate="true"
                                        readOnly={props.readOnly}/>
                                    <label>Décharg. et manut.</label>
                                </div>
                            </div>
                            
                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={props.businessInfo.nb_copie_papier}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="nb_copie_papier"
                                        readOnly={props.readOnly}/>
                                    <label>Nb copie(s) papier(s)</label>
                                </div>

                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={props.businessInfo.nb_copie_demat}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="nb_copie_demat"
                                        readOnly={props.readOnly} />
                                    <label>Nb copie(s) dématérialisée(s)</label>
                                </div>
                            </div>
                            
                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={props.businessInfo.install_aff}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="install_aff" list="install_aff" data-translate="true"
                                        readOnly={props.readOnly}/>
                                    <label>Installation</label>
                                </div>

                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={props.businessInfo.assist_prod}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="assist_prod" list="assist_prod" data-translate="true"
                                        readOnly={props.readOnly} />
                                    <label>Assistance à la prod.</label>
                                </div>
                            </div>
                            
                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={props.businessInfo.formation_perso}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="formation_perso" list="formation_perso" data-translate="true"
                                        readOnly={props.readOnly} />
                                    <label>Formation personnel</label>
                                </div>
                            </div>

                        </td>
                    </tr>

                </tbody>
            </table>

        </div>
    )
}

export default PartEight;