import { Fragment, useState } from "react";

const Toolbox = (props) => {
    const basicMsg = "Passer votre souris sur chaque rubrique pour en savoir plus.<br/>Cliquez sur l'une d'elle pour accéder à la page concernée.";
    const [msg, setMsg] = useState(basicMsg);

    const [selected, setSelected] = useState(-1);
    const style = { borderRadius: "0.5em", padding: "5px", borderStyle: "outset", borderWidth: "thin" }
    const selectedStyle = { borderRadius: "0.5em", padding: "5px", borderStyle: "outset", borderWidth: "thin", boxShadow: "10px 5px 5px grey", fontWeight: "bold", backgroundColor: "rgb(220,240,250)" }

    const elt = [
        {
            url: "https://i.sealedair.com/", title: "Intranet Sealed Air", img: "LogoSealedAirbulle.png",
            txt: "Intranet du groupe SealedAir, vous pouvez voir l'actualité de Sealed Air à travers le monde, saisir vos objectifs professionnels, accéder à des formations, voir votre profil salarié et bien d'autres choses.<br>Pour le découvrir munissez-vous de votre identifiant et mot de passe de Golbal Protect fourni pour les ressources humaines."
        },
        {
            url: "https://mycareer.sealedair.com/", title: "Plateforme My Sealed Air", img: "LogoSealedAirbulle.png",
            txt: "Une des rubriques de l'intranet Sealed Air qui vous apprend la politique RH de SEE, vous offre des formations gratuites, vous permet de saisir vos objectifs professionnels et vos performances, découvrez quels autres fonctionnalités vous sont offertes en cliquant dessus."
        },
        {
            url: "https://sealedair.service-now.com/sp", title: "Tickets IT SEE", img: "LogoSealedAirbulle.png",
            txt: "Remontez vos demandes ou incidents informatiques à notre responsable réseau de B+Equipment."
        },
        { url: "https://www.office.com/", title: "Office 365", img: "microsoft_office_logo.png",
            txt: "Connectez-vous à votre compte Microsoft pour accéder aux fonctionnalités telles que One Drive à l'aide de vos identifiant Global Protect." },
        {
            url: "https://b-be-mca.monday.com/workspaces/-1", title: "Monday", img: "monday_logo.png",
            txt: "Il s'agit d'outil de communication inter service permettant d'être à jour sur le déroulement des différents processus. Demandez un accès aux personnes en charge de Monday chez B+Equipment."
        },
        {
            url: "https://www.aris-focus.com/FTP/DOCS/FR/index.htm", title: "Documentation Focus", img: "aris_logo.png",
            txt: "Documentation officiel de Focus Evolution qui est notre ERP. Il s'agit de notre logiciel de gestion interne."
        },
        {
            url: "https://my.silae.fr/sign-in?source=pp", title: "Gestion absences et congés", img: "time_logo.png",
            txt: "Posez vos congés, vérifiez votre solde et faites valider votre demande."
        },
        {
            url: "https://forms.monday.com/forms/39b685e9b7098ae659a2182b930935d5?r=use1", title: "Ticket ou contact RH",
            img: "hr_logo.png",
            txt: "Posez vos questions ou remontez un incident aux ressources humaines de B+Equipment."
        },
        {
            url: "https://rh.witiwi.fr/", title: "Mutuelle", img: "wtw.png",
            txt: "Faites demande de remboursement, de devis santé et autres sur le site officiel. Pour y accéder, créez un compte simplement sur leur site."
        },
        {
            url: "https://www.opco-atlas.fr/", title: "OPCO Atlas", img: "opco_atlas_logo.png",
            txt: "Permet d’accéder à l’ensemble des services : actions de formation, de certification, etc.<br>Cette création de compte a été initiée par votre employeur et vous n’avez plus qu’à valider ce compte en cliquant sur le lien reçu par mail. Si vous ne l'avez pas reçu, demandez au service RH."
        },
        {
            url: "https://www.ca-els.com/epargnant/", title: "PERCO / PEE", img: "pee_perco.png",
            txt: "Permet de visualiser son PERCO et PEE."
        },
        {
            url: "https://www.actionlogement.fr/", title: "Action logement", img: "al.jpg",
            txt: "Aide à la recherche d’un logement ou d’un financement pour votre projet immobilier"
        },
        {
            url: "https://edocperso.fr/login", title: "Bulletins de Salaires", img: "edoc perso.png",
            txt: "Bulletins de Salaires"
        },
        {
            url: "https://teams.microsoft.com/l/channel/19%3af4207e22d74246539f86307913060e88%40thread.tacv2/General?groupId=d66e13e0-5cf0-4976-9953-47fd09ff102f&tenantId=2691a251-4c38-4643-af0b-0c0982f197bd",
            title: "Base de Données BDESE", img: "BDESE.png",
            txt: "Le CSE est consulté sur divers sujets, parmi lesquels la politique sociale de l’entreprise. Dans ce cadre, B+ EQUIPMENT met en place une base de données économiques, sociales et environnementales (BDESE) accessible en permanence aux membres du CSE et qui rassemble un ensemble d’informations règlementaires régulièrement mises à jour. "
        },

    ];

    const mouseEntered = (idx) => {
        setSelected(idx);
        setMsg(elt[idx].txt);
    }

    const mouseOut = (idx) => {
        setSelected(-1);
        setMsg(basicMsg);
    }

    const onClick = (idx) => {
        setSelected(idx);
        if (idx > -1) window.open( elt[idx].url, '_blank');
    }

    return <Fragment>

        <h5 className="text-center">Ma boite à outils informatiques</h5>

        <div className="alert alert-info col-8 offset-2 text-center fw-bold mb-3" style={{ height: "100px" }}>
            <div dangerouslySetInnerHTML={{ __html: msg }}></div>
        </div>

        <div className="display-flex" style={{ width: "100%" }}>
            <div className="col-4">
                <h6 className="text-center">Outils Sealed Air</h6>

                {
                    elt.map((v, k) => {
                        return (k >= 0 && k < 4) &&
                            <div className="col-8 offset-2 cursor-pointer display-flex mb-3" key={"elt"+k}
                                style={selected === k ? selectedStyle : style}
                                onMouseEnter={() => mouseEntered(k)} onMouseLeave={() => mouseOut(k)}
                                onMouseDown={() => onClick(-1)} onMouseUp={() => onClick(k)}>
                                <div className="mt-auto mb-auto">{v.title}</div>
                                <img src={"/toolbox/"+v.img} style={{ maxWidth: "100px", maxHeight: "50px", marginLeft: "auto" }} ></img>
                            </div>
                    })
                }



            </div>

            <div className="col-4">
                <h6 className="text-center">Outils B+ Equipment</h6>

                {
                    elt.map((v, k) => {
                        return (k >= 4 && k < 6) &&
                            <div className="col-8 offset-2 cursor-pointer display-flex mb-3" key={"elt"+k}
                                style={selected === k ? selectedStyle : style}
                                onMouseEnter={() => mouseEntered(k)} onMouseLeave={() => mouseOut(k)}
                                onMouseDown={() => onClick(-1)} onMouseUp={() => onClick(k)}>
                                <div className="mt-auto mb-auto">{v.title}</div>
                                <img src={"/toolbox/"+v.img} style={{ maxWidth: "100px", maxHeight: "50px", marginLeft: "auto" }} ></img>
                            </div>
                    })
                }

            </div>


            <div className="col-4">
                <h6 className="text-center">Outils RH B+Equipment</h6>

                {
                    elt.map((v, k) => {
                        return (k >= 6) &&
                            <div className="col-8 offset-2 cursor-pointer display-flex mb-3" key={"elt"+k}
                                style={selected === k ? selectedStyle : style}
                                onMouseEnter={() => mouseEntered(k)} onMouseLeave={() => mouseOut(k)}
                                onMouseDown={() => onClick(-1)} onMouseUp={() => onClick(k)}>
                                <div className="mt-auto mb-auto">{v.title}</div>
                                <img src={"/toolbox/"+v.img} style={{ maxWidth: "100px", maxHeight: "50px", marginLeft: "auto" }} ></img>
                            </div>
                    })
                }
            </div>
        </div>

    </Fragment>
}

export default Toolbox;