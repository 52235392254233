
export const setMachineInfoAnalyse = (machineInfo) => {
    var o = {};
    o.label = "Vérification du numéro de machine";
    o.comments = "Affaire: <b>" + machineInfo.affaire + "</b><br>"
        + "Machine: <b>" + machineInfo.no_machine + " (" + machineInfo.typ_config + ")" + "</b><br>"
        + "N° de ligne: <b>" + machineInfo.no_ligne + "</b><br>"
        + "Montage: " + machineInfo.manufacturing;
    o.status = "ok";
    
    return o;
}

export const checkTypeOfBom = (files) => {
    var o = {}
    var file = files[0];
    o.label = "Type de nomenclature";
    if (file.name.startsWith("NM")) {
        o.status = "ok";
        o.comments = "Nomenclature mécanique";
        o.value = "MECA";
    } else if (file.name.startsWith("NE")) {
        o.status = "ok";
        o.comments = "Nomenclature électrique";
        o.value = "ELEC";
    } else {
        o.status = "err";
        o.comments = "Le nom du fichier doit commencer par NM- ou NE-";
        o.value = null;
    }

    return o;
}

export const categoryRowAnalyse = (compilation) => {
    var o = {}
    o.label = "Vérification des catégories non renseignées";
    var err = "";


    for (let i = 2; i < compilation.length; i++) {
        
        if (compilation[i].cat.trim() === "")
            err += "Catégorie manquante dans le S/E " + compilation[i].se 
                    + " repère: " + compilation[i].rep + "<br>";
    }

    o.status = err.length === 0 ? "ok" : "error";
    o.comments = err.length === 0 ? "Ok" : err;
    return o;
}

export const duplicateRowAnalyse = (compilation) => {
    var o = {}
    o.label = "Vérification des doublons ( se + rep + ref )";
    var err = "";
    var arr = new Array();
    var key, se, rep, ref, maker;

    for (let i = 2; i < compilation.length; i++) {
        se = compilation[i].se.trim();
        rep = compilation[i].rep.trim();
        rep = rep.substring(0, Math.min(25, rep.length));
        ref = compilation[i].ref.toString().replace(/ /g, '').trim().toUpperCase();
        maker = compilation[i].maker.toString().trim();

        if (se === "" || rep === "" || ref === "" || maker === "")
            err += "Le S/E ou REP ou référence ou fabricant a été retrouvé vide à la ligne " + (i + 1) + "<br>";

        key = se + rep + ref;

        if (arr.includes(key)) {
            err += "Un doublon dans le S/E " + se + ", repère: " + rep + "<br>"
        } else {
            arr.push(key);
        }


    }

    o.status = err.length === 0 ? "ok" : "error";
    o.comments = err.length === 0 ? "Ok" : err;
    return o;
}

export const generalBomAnalyse = (compilation, machineInfo) => {
    var o = {}
    o.label = "Nomenclature absente de la nomenclature générale";
    var eg = new Array();
    var se, ref, err = "";

    // Get general BOM
    for (let i = 2; i < compilation.length; i++) {
        se = compilation[i].se.trim();
        ref = compilation[i].ref.toString().replace(/ /g, '').trim().toUpperCase();

        if (se.endsWith("EG") && (ref.startsWith("NM-") || ref.startsWith("NE-"))) eg.push(compilation[i]);
    }

    if (eg.length === 0) err += "Nomenclature générale manquante<br>";

    var split;
    // Check if general BOM is well written
    eg.map(v => {
        split = v.ref.split("-");
        if (split[1].substring(0, Math.min(7, split[1].length))
            !== machineInfo.affaire.substring(0, Math.min(7, machineInfo.affaire.length))) {
            err += v.ref + " devrait être rattaché au code affaire: " + machineInfo.affaire.substring(0, Math.min(7, machineInfo.affaire.length)) + "<br>";
        } else if (!checkRefFormat(v.ref)) {
            err += "Erreur de synthaxe: " + v.ref + "<br>";
        }
    });

    // Check if each subset has article
    var line = machineInfo.no_ligne;
    eg.map(v => {
        split = v.ref.split("-");
        var flag = false;

        //Check only if it's the same line
        if( split[2].charAt(0) === line ){
            for (let i = 2; i < compilation.length; i++) {
                se = compilation[i].se.trim();
                if (se.trim() === split[2].trim()) {
                    flag = true;
                    break;
                }
            }
    
            if (!flag) err += split[2] + " est un S/E qui n'a pas d'article en son sein<br>";
            flag = false;
        }
    });

    
    o.status = err.length === 0 ? "ok" : "error";
    o.comments = err.length === 0 ? "Ok, " + eg.length + " S/E trouvé(s)" : err;
    return o;
}

function checkRefFormat(ref) {
    ref = ref.split("-");

    if (ref.length < 5) return false;
    if (ref[0].length !== 2 ||
        ref[1].length !== 8 ||
        ref[2].length !== 4 ||
        ref[3].length !== 3) return false;

    return true;
}

export const refSynthaxAnalyse = (compilation) => {
    var o = {}
    o.label = "Vérification de la syntaxe des références de plan";
    var ref, err = "";

    for (let i = 2; i < compilation.length; i++) {
        ref = compilation[i].ref.toString().replace(/ /g, '').trim().toUpperCase();

        if( ref.startsWith("PL-") && !checkRefFormat(ref) ) 
            err +=  "Erreur de synthaxe: " + ref + " dans le S/E "+compilation[i].se
                + ", repère: " + compilation[i].rep + "<br>";
    }

    o.status = err.length === 0 ? "ok" : "error";
    o.comments = err.length === 0 ? "Ok" : err;
    return o;
}

export const checkArWithoutGeneralBom = (compilation) => {
    var o = {}
    o.label = "Références qui ne sont pas dans la nomenclature générale";
    var eg = new Array();
    var se, ref, err = "";

    // Get general BOM
    for (let i = 2; i < compilation.length; i++) {
        se = compilation[i].se.trim();
        ref = compilation[i].ref.toString().replace(/ /g, '').trim().toUpperCase();

        if (se.endsWith("EG") && (ref.startsWith("NM-") || ref.startsWith("NE-"))) 
            eg.push(ref.split("-")[2].trim());
    }

    if (eg.length === 0) return;
    
    for (let i = 2; i < compilation.length; i++) {
        se = compilation[i].se.trim();
        ref = compilation[i].ref.toString().trim().toUpperCase();
        if ( !se.endsWith("EG") && !eg.includes( se.trim()) ) 
            err += "La ref. " + ref + " n'est pas rattaché à une nomenclature générale, attendu: "+se+"<br>";
        
    }

    
    o.status = err.length === 0 ? "ok" : "error";
    o.comments = err.length === 0 ? "Ok" : err;
    return o;
}

export const paintedArticleAnalyse = (compilation) => {
    var o = {}
    o.label = "Ajout des articles peints";
    var painted = new Array();
    var cat, r, err = "";

    // Get painted articles
    for (let i = 2; i < compilation.length; i++) {
        cat = compilation[i].cat.trim().toUpperCase();

        if(cat === "PEINT"){
            r = {...compilation[i]};
            r.cat = "Peint";
            r.ref = "PR-" + r.ref.substring(3, r.ref.length);
            r.ar = "";
            painted.push(r);
        }  
    }

    o.status = err.length === 0 ? "ok" : "error";
    o.comments = err.length === 0 ? "Ok, " + painted.length + " articles peints ajoutés" : err;
    o.paintedArr = painted;
    return o;
}