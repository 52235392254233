import { Fragment, useEffect, useState } from "react";
import errorManagement from "../../services/errorManagement";
import rolesDao from "../../services/settings/rolesDao";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { arrayRemoveElt, getKeyInt } from "../../functions/ArrayFunctions";
import { ButtonWaiting, getHeight } from "../../functions/PageAnimation";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";

const SiteMap = (props) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const [roles, setRoles] = useState();
    const [request, setRequest] = useState([]);

    const [sending, setSending] = useState(false);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();

    useEffect(() => {
        rolesDao.getRoles().then(
            (response) => {
                setRoles(response.roles);
            },
            (error) => {
                errorManagement.handleError(props, error);
            }
        );
    }, []);

    const onCheckAccess = (e, k) => {
        var arr = [...request];

        if (e.target.checked) {
            arr.push(roles[k]);
        } else {
            arr = arrayRemoveElt(arr, getKeyInt(arr, "id", roles[k].id));
        }

        setRequest(arr);
    }

    const filter = (e) => {
        var search = e.target.value.trim().toLowerCase();

        var arr = [...roles];

        arr.map(v => {

            if (search === "") {
                delete v.hide;
            } else {
                var description = v.description.toLowerCase();
                const inputWords = search.split(' ');
                v.hide = !inputWords.every(word => description.includes(word));
            }

        })

        setRoles(arr);
    }

    const send = () => {
        setSending(true);
        rolesDao.requestAccess(request, user).then(
            () => {
                setSending(false);
                setMsg("Demande envoyée");
            },
            (error) => {
                setSending(false);
                setError( errorManagement.handleError(props, error) );
            }
        );
    }

    return <Fragment>
        <h5 className="text-center">Plan du site</h5>

        <div className="alert alert-info col-6 offset-3 text-center fw-bold">
            Les demandes d'accès seront fait à {user.manager_email}.<br></br>
            Si ce n'est pas/plus votre manager, merci de le signaler à <a href="mailto:wael.souabni@sealedair.com">Souabni Wael</a><br></br>
            Cochez les accès que vous voudriez et cliquez sur le bouton envoyer.
        </div>

        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        {!roles && <WaitingRoundSnippers />}

        {
            roles &&
            <div className="table-responsive">
                <input className="form-control mb-3 text-center fw-bold" placeholder="Chercher par mots clé"
                    onChange={filter}></input>

                <ul className="list-group" style={{ height: getHeight(), overflowY: "auto" }}>
                    {
                        roles.map((v, k) => {
                            return <li className="list-group-item"
                                style={{ display: v.hide !== undefined && v.hide ? "none" : "block" }} key={"r" + k}>
                                <div className="display-flex">
                                    {(user && user.roles.includes(v.name)) &&
                                        <img src="/common/check.png" style={{width: "30px"}}></img>
                                    }
                                    
                                    {(!user || !user.roles.includes(v.name)) &&
                                        <input type="checkbox" className="me-3" onChange={(e) => onCheckAccess(e, k)}></input>
                                    }


                                    <div className="me-2">{v.description}</div>
                                </div>
                            </li>;
                        })
                    }
                </ul>
            </div>
        }

        {
            request.length > 0 &&
            <div className="text-center">
                <button className="btn btn-success" onClick={send} disabled={sending}>
                    {sending && <ButtonWaiting />}
                    Envoyer la demande</button>
            </div>
        }

    </Fragment>

}

export default SiteMap;