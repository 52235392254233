import { Fragment } from "react";
import fahDao from "../../../services/common/fahDaoV2";
import errorManagement from "../../../services/errorManagement";
import { useState } from "react";
import { ButtonWaiting } from "../../../functions/PageAnimation";
import { downloadSingleFile } from "../../../services/functions/FilesProcess";

const ImportFocus = (p) => {
    const [searching, setSearching] = useState(false);
    const [list, setList] = useState();

    const getExportedCsv = (e) => {
        e.preventDefault();

        setSearching(true);
        fahDao.getOldClockImported(e.target.month.value, e.target.year.value).then(
            (response) => {
                setList(response);
                setSearching(false);
            }, (error) => {
                p.setError(errorManagement.handleError(p, error));
                setSearching(false);
            }
        );
    }

    const exportCsv = (date) => {
        setSearching(true);

        fahDao.downloadClockToImport(date).then(
            (response) => {
                var file = response.substring(response.lastIndexOf("\\") + 1, response.length);
                downloadSingleFile("temp", file);
                setSearching(false);
            }, (error) => {
                p.setError(errorManagement.handleError(p, error));
                setSearching(false);
            }
        );
    }

    return <Fragment>
        <h4 className="text-center">Exportation de pointages</h4>

        {
            p.newClockCsv.length > 0 && <div className="alert alert-success text-center fw-bold mb-3">
                De nouveaux pointages sont prêts à être importés dans Focus.
                Cliquez <a href="#" onClick={()=>exportCsv(null)}>ici</a> pour télécharger le fichier CSV.
            </div>
        }

        {
            p.newClockCsv.length === 0 && <div className="alert alert-info text-center fw-bold mb-3">
                Aucun nouveau pointage à importer
            </div>
        }

        <h4 className="text-center">Retrouver les pointages précédents</h4>

        <div className="mb-3 col-6 offset-3 mb-3">
            <form onSubmit={getExportedCsv}>
                <div className="input-group">
                    <label className="input-group-text">Mois</label>
                    <select className="form-select" name="month" required>
                        <option value="">Choose...</option>
                        <option value="1">Janvier</option>
                        <option value="2">Février</option>
                        <option value="3">Mars</option>
                        <option value="4">Avril</option>
                        <option value="5">Mai</option>
                        <option value="6">Juin</option>
                        <option value="7">Juillet</option>
                        <option value="8">Août</option>
                        <option value="9">Septembre</option>
                        <option value="10">Octobre</option>
                        <option value="11">Novembre</option>
                        <option value="12">Décembre</option>
                    </select>

                    <span className="input-group-text">Année</span>
                    <input type="number" className="form-control" name="year" required />

                    <button type="submit" className="btn btn-secondary" disabled={searching}>
                        {searching && <ButtonWaiting />}
                        Rechercher
                    </button>
                </div>
            </form>
        </div>

        {
            list && <ul className="list-group col-6 offset-3">
                {
                    list.map((v, k) => {
                        return <li className="list-group-item text-center fw-bold" 
                            onClick={()=>exportCsv(v.en_export)} key={"li" + k}>{v.fr_export} par {v.export_author}</li>
                    })
                }
            </ul>
        }

    </Fragment>
}

export default ImportFocus;