import axios from "axios";
import textPlainHeader from "../fake-header";
import authHeader from "../auth-header";

const API_URL = process.env.REACT_APP_API_URL + "fah/v2";
const user = JSON.parse(localStorage.getItem("user"));


/******************************************************************************
 * NEW ---- CANCELLED
 ******************************************************************************/
const getInstallerTimeClocking = (focus_id, week, year, oldBusiness, oldWeek, oldYear, oldHours,
    start, end, trigram) => {
    return axios
        .post(API_URL + "/installer/get/all", {
            fake_header: authHeader(),
            focus_id: focus_id,
            week: week,
            year: year,
            oldBusiness: oldBusiness,
            oldWeek: oldWeek,
            oldYear: oldYear,
            oldHours: oldHours,
            start: start,
            end: end,
            trigram: trigram
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

/******************************************************************************
 * NEW
 ******************************************************************************/

const getInstallerHours = (focus_id, week, year, oldBusiness, oldWeek, oldYear, oldHours) => {
    return axios
        .post(API_URL + "/installer/get/hours", {
            fake_header: authHeader(),
            focus_id: focus_id,
            week: week,
            year: year,
            oldBusiness: oldBusiness,
            oldWeek: oldWeek,
            oldYear: oldYear,
            oldHours: oldHours
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const saveInstallerHours = (week, year, hours, abs, summary, send, isManager, userInfo) => {
    return axios
        .post(API_URL + "/installer/save/hours", {
            fake_header: authHeader(),
            week: week,
            year: year,
            hours: hours,
            abs: abs,
            summary: summary,
            send: send,
            isManager: isManager,
            userInfo: userInfo
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getMyStaff = () => {
    return axios
        .post(API_URL + "/my-staff", {
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getMyInterim = (week, year) => {
    return axios
        .post(API_URL + "/my-staff/interim", {
            fake_header: authHeader(),
            week: week,
            year: year
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getTrackingManager = (year) => {
    return axios
        .post(API_URL + "/manager/tracking", {
            fake_header: authHeader(),
            year: year
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getToCheckManager = () => {
    return axios
        .post(API_URL + "/manager/to-check", {
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const managerReject = (member, week, year, status, cause) => {
    return axios
        .post(API_URL + "/manager/reject", {
            fake_header: authHeader(),
            member: member,
            week: week,
            year: year,
            status: status,
            cause: cause
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const sendClockTimeToInterim = (week, year, arr) => {
    return axios
        .post(API_URL + "/manager/send/interim", {
            fake_header: authHeader(),
            week: week,
            year: year,
            arr: arr
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateInterimMail = (email, field, interimCode) => {
    return axios
        .post(API_URL + "/manager/update/interim/email", {
            fake_header: authHeader(),
            email: email,
            field: field,
            interimCode: interimCode
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getMyTracking = (year) => {
    return axios
        .post(API_URL + "/my-self/tracking", {
            fake_header: authHeader(),
            year: year
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getTrackingAccountant = (year) => {
    return axios
        .post(API_URL + "/accountant/tracking", {
            fake_header: authHeader(),
            year: year
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getToCheckAccountant = () => {
    return axios
        .post(API_URL + "/accountant/to-check", {
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const checkingResp = (member, week, year, status, cause) => {
    return axios
        .post(API_URL + "/accountant/checking-resp", {
            fake_header: authHeader(),
            member: member,
            week: week,
            year: year,
            status: status,
            cause: cause
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getClockToImport = (date) => {
    return axios
        .post(API_URL + "/accountant/focus/get-new", {
            fake_header: authHeader(),
            date: date
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getOldClockImported = (week, year) => {
    return axios
        .post(API_URL + "/accountant/focus/get-old", {
            fake_header: authHeader(),
            week: week,
            year: year
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const downloadClockToImport = (date) => {
    return axios
        .post(API_URL + "/accountant/focus/create-file", {
            fake_header: authHeader(),
            date: date
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const downloadClockSheet = (trigram, week, year) => {
    return axios
        .post(API_URL + "/create-xls", {
            fake_header: authHeader(),
            trigram: trigram,
            week: week,
            year: year
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getSchedules = () => {
    return axios
        .post(API_URL + "/accountant/schedules/get", {
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const saveSchedules = (schedules) => {
    return axios
        .post(API_URL + "/accountant/schedules/save", {
            fake_header: authHeader(),
            schedules: schedules
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getInterimAccountant = (week, year, trigram) => {
    return axios
        .post(API_URL + "/accountant/interim/get", {
            fake_header: authHeader(),
            week: week, 
            year: year, 
            trigram: trigram
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const downloadAttachments = (list, zip_name) => {
    return axios
        .post(API_URL + "/download/attachments", {
            fake_header: authHeader(),
            list: list,
            zip_name: zip_name
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const remindFah = (email) => {
    return axios
        .post(API_URL + "/remind", {
            fake_header: authHeader(),
            email: email
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getC2History = (registration) => {
    return axios
        .post(API_URL + "/get/c2/history", {
            fake_header: authHeader(),
            registration: registration
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getInterimBill = (o) => {
    return axios
        .post(API_URL + "/accountant/interim/bill", {
            fake_header: authHeader(),
            ...o
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const correctC2 = (o) => {
    return axios
        .post(API_URL + "/accountant/c2/correct", {
            fake_header: authHeader(),
            ...o
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const deleteCorrectC2 = (o) => {
    return axios
        .post(API_URL + "/accountant/c2/delete", {
            fake_header: authHeader(),
            ...o
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getPaymentXls = (month, year) => {
    return axios
        .post(API_URL + "/hr/export/payment", {
            fake_header: authHeader(),
            month: month,
            year: year
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

export default {
    getInstallerHours,
    saveInstallerHours,
    getMyStaff,
    getTrackingManager,
    getToCheckManager,
    managerReject,
    getMyTracking,
    getMyInterim,
    getInstallerTimeClocking,
    sendClockTimeToInterim,
    updateInterimMail,
    getTrackingAccountant,
    getToCheckAccountant,
    checkingResp,
    getClockToImport,
    getOldClockImported,
    downloadClockToImport,
    downloadClockSheet,
    getSchedules,
    saveSchedules,
    getInterimAccountant,
    downloadAttachments,
    remindFah,
    getC2History,
    getInterimBill,
    correctC2,
    deleteCorrectC2,
    getPaymentXls
}