import { useState } from "react";
import { getValue } from "../../functions/XlsxMgt";
import { getKey } from "../../functions/ArrayFunctions";
import MachineInfo from "../../services/machines/MachineInfo";

export const controlBom = (file, xls, setAnalyse, machineInfo, callback) => {
    var arr = new Array();

    setMachineInfo(machineInfo, function (o) { arr.push(o); setAnalyse(arr) })
    checkTypeOfBom(file, function (o) { arr.push(o); setAnalyse(arr) });
    checkColumns(xls, function (o) { arr.push(o); setAnalyse(arr) });
    checkCategoryRow(xls, function (o) { arr.push(o); setAnalyse(arr) });
    checkDuplicateRow(xls, function (o) { arr.push(o); setAnalyse(arr) });
    checkGeneralBom(xls, machineInfo, function (o) { arr.push(o); setAnalyse(arr) });
    checkRefSynthax(xls, function (o) { arr.push(o); setAnalyse(arr) })
    checkArWithoutGeneralBom(xls, function (o) { arr.push(o); setAnalyse(arr) });
    
    //Need to return the new BOM with painted articles
    addPaintedArticle(xls, function (o) { 
        arr.push(o); 
        setAnalyse(arr); 
        callback({ 
            list: concatXls( xls, o.xls ),
            analyse: arr
        });
    });

    /* 
    * NO CONTROL NOW
    *
    checkReferenceToOtherMachine(xls, machineInfo, function (o) { 
        arr.push(o); 
        setAnalyse(arr);
    });*/

    
}

var template = {
    status: null,
    label: null,
    comments: null
}

/**
 * Display machine info
 * @param {*} machineInfo 
 */
function setMachineInfo(machineInfo, callback) {
    var o = { ...template };
    o.label = "Vérification du numéro de machine";
    o.comments = "Affaire: <b>" + machineInfo.affaire + "</b><br>"
        + "Machine: <b>" + machineInfo.no_machine + " (" + machineInfo.typ_config + ")" + "</b><br>"
        + "N° de ligne: <b>" + machineInfo.no_ligne + "</b><br>"
        + "Montage: " + machineInfo.manufacturing;
    o.status = "ok";

    callback(o);
}

/**
 *  Check if the file name like "NX-XXXX"
 * 
 * @param {*} file 
 * @param {*} callback 
 */
function checkTypeOfBom(file, callback) {
    var o = { ...template }
    o.label = "Type de nomenclature";
    if (file.name.startsWith("NM")) {
        o.status = "ok";
        o.comments = "Nomenclature mécanique";
    } else if (file.name.startsWith("NE")) {
        o.status = "ok";
        o.comments = "Nomenclature électrique";
    } else {
        o.status = "err";
        o.comments = "Le nom du fichier doit commencer par NM- ou NE-";
    }

    callback(o);
}

/**
 * Check if all columns are well placed
 * 
 * @param {*} xls 
 * @param {*} callback 
 */
export const checkColumns = (xls, callback) => {
    var o = { ...template }
    o.label = "Vérification des colonnes";
    var err = "";

    var header = xls[1].row.values;
    if (getValue(header[1]).toUpperCase().indexOf("CAT") === -1) err += "Colonne \"Catégorie\" attendue à la cellule A2 <br>";
    if (getValue(header[2]).toUpperCase().indexOf("S/E") === -1) err += "Colonne \"S/E\" attendue à la cellule B2 <br>";
    if (getValue(header[3]).toUpperCase().indexOf("REP") === -1) err += "Colonne \"QTE QTY\" attendue à la cellule S2 <br>";
    if (getValue(header[4]).toUpperCase().indexOf("QTE") === -1) err += "Colonne \"REP Mark\" attendue à la cellule S2 <br>";
    if (getValue(header[5]).toUpperCase().indexOf("DES") === -1) err += "Colonne \"DESIGNATION FR\" attendue à la cellule E2 <br>";
    if (getValue(header[6]).toUpperCase().indexOf("DES") === -1) err += "Colonne \"DESIGNATION EN\" attendue à la cellule F2 <br>";
    if (getValue(header[7]).toUpperCase().indexOf("REF") === -1) err += "Colonne \"REFERENCE\" attendue à la cellule G2 <br>";
    if (getValue(header[8]).toUpperCase().indexOf("FAB") === -1) err += "Colonne \"FABRICANT\" attendue à la cellule H2 <br>";
    if (getValue(header[9]).toUpperCase().indexOf("COD") === -1) err += "Colonne \"CODE ARTICLE\" attendue à la cellule I2 <br>";
    if (getValue(header[10]).toUpperCase().indexOf("USURE") === -1) err += "Colonne \"PIECE D'USURE\" attendue à la cellule J2 <br>";
    if (getValue(header[11]).toUpperCase().indexOf("RECH") === -1) err += "Colonne \"Pièces rechanges préconisées\" attendue à la cellule K2 <br>";
    if (getValue(header[12]).toUpperCase().indexOf("REM") === -1) err += "Colonne \"REMARQUES AR\" attendue à la cellule L2 <br>";
    if (getValue(header[13]).toUpperCase().indexOf("REM") === -1) err += "Colonne \"REMARQUES NM\" attendue à la cellule M2 <br>";


    o.status = err.length === 0 ? "ok" : "error";
    o.comments = err.length === 0 ? "Ok" : err;
    callback(o);
}

/**
 * Check if there is an empty cell in cat column
 * 
 * @param {*} xls 
 * @param {*} callback 
 */
export const checkCategoryRow = (xls, callback) => {
    var o = { ...template }
    o.label = "Vérification des catégories non renseignées";
    var err = "";


    for (let i = 2; i < xls.length; i++) {
        
        if (getValue(xls[i].row.values[1]).trim() === "")
            err += "Catégorie manquante à la ligne " + (i + 1) + "<br>";
    }

    o.status = err.length === 0 ? "ok" : "error";
    o.comments = err.length === 0 ? "Ok" : err;
    callback(o);
}

/**
 * Get BOM content, but if there is duplicate -> error
 * 
 * @param {*} xls 
 * @param {*} callback 
 */
export const checkDuplicateRow = (xls, callback) => {
    var o = { ...template }
    o.label = "Vérification des doublons ( se + rep + ref )";
    var err = "";
    var arr = new Array();
    var key, se, rep, ref, maker;

    for (let i = 2; i < xls.length; i++) {
        if (getQty(getValue(xls[i].row.values[4])) <= 0) continue;

        se = getValue(xls[i].row.values[2]).trim();
        rep = getValue(xls[i].row.values[3]).toString().trim();
        rep = rep.substring(0, Math.min(25, rep.length));
        ref = getValue(xls[i].row.values[7]).toString().replace(/ /g, '').trim().toUpperCase();
        maker = getValue(xls[i].row.values[8]).toString().trim();

        if (se === "" || rep === "" || ref === "" || maker === "")
            err += "Le S/E ou REP ou référence ou fabricant a été retrouvé vide à la ligne " + (i + 1) + "<br>";

        key = se + rep + ref;

        if (arr.includes(key)) {
            err += "Un doublon a été trouvée à la ligne " + (i + 1) + "<br>"
        } else {
            arr.push(key);
        }


    }

    o.status = err.length === 0 ? "ok" : "error";
    o.comments = err.length === 0 ? "Ok" : err;
    callback(o);
}

/**
 * Check general nomenclature
 * 
 * @param {*} xls 
 * @param {*} machineInfo 
 * @param {*} callback 
 */
const checkGeneralBom = (xls, machineInfo, callback) => {
    var o = { ...template }
    o.label = "Nomenclature absente de la nomenclature générale";
    var eg = new Array();
    var se, ref, err = "";

    // Get general BOM
    for (let i = 2; i < xls.length; i++) {
        if (getQty(getValue(xls[i].row.values[4])) <= 0) continue;

        se = getValue(xls[i].row.values[2]).trim();
        ref = getValue(xls[i].row.values[7]).toString().replace(/ /g, '').trim().toUpperCase();

        if (se.endsWith("EG") && (ref.startsWith("NM-") || ref.startsWith("NE-"))) eg.push(xls[i].row.values);
    }

    if (eg.length === 0) err += "Nomenclature générale manquante<br>";

    var split;
    // Check if general BOM is well written
    eg.map(v => {
        split = v[7].split("-");
        if (split[1].substring(0, Math.min(7, split[1].length))
            !== machineInfo.affaire.substring(0, Math.min(7, machineInfo.affaire.length))) {
            err += v[7] + " devrait être rattaché au code affaire: " + machineInfo.affaire.substring(0, Math.min(7, machineInfo.affaire.length)) + "<br>";
        } else if (!checkRefFormat(v[7])) {
            err += "Erreur de synthaxe: " + v[7] + "<br>";
        }
    });

    // Check if each subset has article
    var line = machineInfo.no_ligne;
    eg.map(v => {
        split = v[7].split("-");
        var flag = false;

        //Check only if it's the same line
        if( split[2].charAt(0) === line ){
            for (let i = 2; i < xls.length; i++) {
                if (getQty(getValue(xls[i].row.values[4])) <= 0) continue;
                se = getValue(xls[i].row.values[2]).trim();            
                if (se.trim() === split[2].trim()) {
                    flag = true;
                    break;
                }
            }
    
            if (!flag) err += split[2] + " est un S/E qui n'a pas d'article en son sein<br>";
            flag = false;
        }
    });

    
    o.status = err.length === 0 ? "ok" : "error";
    o.comments = err.length === 0 ? "Ok, " + eg.length + " S/E trouvé(s)" : err;
    callback(o);
}


/**
 * Check the reference to another machine
 * 
 * @param {*} xls 
 * @param {*} machineInfo 
 * @param {*} callback 
 */
const checkReferenceToOtherMachine = (xls, machineInfo, callback) => {
    var o = { ...template }
    o.label = "Vérification de la nomenclature générale p.2";
    var eg = new Array();
    var se, ref, err = "";

    // Get general BOM
    for (let i = 2; i < xls.length; i++) {
        if (getQty(getValue(xls[i].row.values[4])) <= 0) continue;

        se = getValue(xls[i].row.values[2]).trim();
        ref = getValue(xls[i].row.values[7]).toString().replace(/ /g, '').trim().toUpperCase();

        if (se.endsWith("EG") && (ref.startsWith("NM-") || ref.startsWith("NE-"))) eg.push(xls[i].row.values);
    }

    // For each general BOM check if the number line is different and search the reference in DB
    var toCheck = new Array();
    var isEc16 = machineInfo.typ_config.startsWith("EC16");
    var diff;
    eg.map( v => {
        diff = Math.abs( getLineNumber( v[7] ) - getLineNumber( machineInfo.no_ligne ) );
        if( (isEc16 && diff > 1) || !isEc16 )
            if(getLineNumber( v[7] ) !==  getLineNumber( machineInfo.no_ligne ) ) toCheck.push( v[7] );
    });

    if( toCheck.length === 0 ){
        o.status = err.length === 0 ? "ok" : "error";
        o.comments = err.length === 0 ? "Ok, pas de renvoi" : err;
        callback(o);
    }else{
        MachineInfo.getSubsets(toCheck).then(
            (response) => {
                toCheck.map( v => {
                    if( !response.includes( v ) ) 
                        err += "Le sous-ensemble " + v + " fait référence à une autre machine qui n'a pas encore été importée.<br>";
                })

                o.status = err.length === 0 ? "ok" : "error";
                o.comments = err.length === 0 ? "Ok, "+toCheck.length+" renvoi(s) trouvé(s)" : err;
                callback(o);
            },
            (error) => {
            }
        );
    }


}

/**
 * Check drawing references synthax well writed
 * 
 * @param {*} xls 
 * @param {*} callback 
 */
const checkRefSynthax = (xls, callback) => {
    var o = { ...template }
    o.label = "Vérification de la syntaxe des références de plan";
    var ref, err = "";

    for (let i = 2; i < xls.length; i++) {
        if (getQty(getValue(xls[i].row.values[4])) <= 0) continue;

        ref = getValue(xls[i].row.values[7]).toString().replace(/ /g, '').trim().toUpperCase();

        if( ref.startsWith("PL-") && !checkRefFormat(ref) ) err +=  "Erreur de synthaxe: " + ref + "<br>";
    }

    o.status = err.length === 0 ? "ok" : "error";
    o.comments = err.length === 0 ? "Ok" : err;
    callback(o);
}

/**
 * Check if there are articles without GE
 * 
 * @param {*} xls 
 * @param {*} callback 
 * @returns 
 */
const checkArWithoutGeneralBom = (xls,callback) => {
    var o = { ...template }
    o.label = "Références qui ne sont pas dans la nomenclature générale";
    var eg = new Array();
    var se, ref, err = "";

    // Get general BOM
    for (let i = 2; i < xls.length; i++) {
        if (getQty(getValue(xls[i].row.values[4])) <= 0) continue;

        se = getValue(xls[i].row.values[2]).trim();
        ref = getValue(xls[i].row.values[7]).toString().replace(/ /g, '').trim().toUpperCase();

        if (se.endsWith("EG") && (ref.startsWith("NM-") || ref.startsWith("NE-"))) 
            eg.push(ref.split("-")[2].trim());
    }

    if (eg.length === 0) return;
    
    for (let i = 2; i < xls.length; i++) {
        if (getQty(getValue(xls[i].row.values[4])) <= 0) continue;
        
        se = getValue(xls[i].row.values[2]).trim();
        ref = getValue(xls[i].row.values[7]).toString().trim().toUpperCase();       
        if ( !se.endsWith("EG") && !eg.includes( se.trim()) ) 
            err += "La ref. " + ref + " n'est pas rattaché à une nomenclature générale<br>";
        
    }

    
    o.status = err.length === 0 ? "ok" : "error";
    o.comments = err.length === 0 ? "Ok" : err;
    callback(o);
}


/**
 * Create painted articles
 * 
 * @param {*} o 
 * @returns 
 */
const addPaintedArticle = (xls,callback) => {
    var o = { ...template }
    o.label = "Ajout des articles peints";
    var painted = new Array();
    var cat, r, err = "";

    // Get painted articles
    for (let i = 2; i < xls.length; i++) {
        if (getQty(getValue(xls[i].row.values[4])) <= 0) continue;

        cat = getValue(xls[i].row.values[1]).trim().toUpperCase();

        if(cat === "PEINT"){
            r = [...xls[i].row.values];
            r[1] = "Peint";
            r[7] = "PR-" + r[7].substring(3, r[7].length);
            r[9] = "";
            painted.push(r);
        }  
        
    }

    o.status = err.length === 0 ? "ok" : "error";
    o.comments = err.length === 0 ? "Ok, " + painted.length + " articles peints ajoutés" : err;
    o.xls = painted;
    callback(o);
}

// Search articles (async)

// Correct cat


// Check if BOM exists and compare


function getQty(o) {
    return isNaN(o) ? -1 : parseFloat(o);
}

function checkRefFormat(ref) {
    ref = ref.split("-");

    if (ref.length < 5) return false;
    if (ref[0].length !== 2 ||
        ref[1].length !== 8 ||
        ref[2].length !== 4 ||
        ref[3].length !== 3) return false;

    return true;
}

function getLineNumber(ref) {
    if( ref.indexOf("-") !== -1 ) ref = ref.split("-")[2].charAt(0);
    
    if (isNaN(ref)) {
        var code = ref.toUpperCase().charCodeAt(0);
        if (code > 64 && code < 91){
            return (code - 64);
        }else{
            return -1;
        }
    } else {
        return parseInt(ref);
    }
}

function concatXls (xls, painted){
    var list = new Array();

    for (let i = 2; i < xls.length; i++) {
        if (getQty(getValue(xls[i].row.values[4])) <= 0) continue;
        list.push(xls[i].row.values); 
    }

    list = list.concat(painted);

    return list;
}