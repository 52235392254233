import { Fragment, useEffect, useState } from "react";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import errorManagement from "../../services/errorManagement";
import { getHeight, scrollToTop } from "../../functions/PageAnimation";
import BusinessDao from "../../services/machines/BusinessDao";
import { createColumnHelper } from "@tanstack/react-table";
import Moment from "moment";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import { currentEnDate, currentFrDate, getFrFormat, nbFormat } from "../../functions/StrFunctions";
import { confirmAlert } from "react-confirm-alert";
import { DesignedChckBox } from "../../common/smallComponents.js/DesignedIpt";
import BillFilter from "./smallCom/Bill/BillFilter";
import MachineHelp from "./smallCom/Bill/MachineHelp";
import ReactPaginate from "react-paginate";
import SparesHelp from "./smallCom/Bill/SparesHelp";

const BillSpares = (props) => {
    const [list, setList] = useState();
    const [data, setData] = useState([]);
    const [totPage, setTotPage] = useState();

    const [filter, setFilter] = useState();
    const [tab, setTab] = useState(0);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();

    const maxByPage = 300;

    const user = JSON.parse(localStorage.getItem("user"));
    const isAdmin = user && (user.roles.includes("ROLE_ADMIN_BILL") || user.roles.includes("ROLE_ADMIN"));
    //const reqBill = user && user.roles.includes("");

    Moment.locale('fr');

    useEffect(() => {
        getList();

        BusinessDao.getBillFilter("billing_track_spares").then(
            (r) => {
                setFilter(r);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        )
    }, []);
 
    function getList() {
        setError();
        setMsg();

        BusinessDao.getBillSparesList().then(
            (r) => {
                setData(r);

                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }

    function changePage(page) {
        setData(list.slice((page * maxByPage), (page * maxByPage) + maxByPage));
        document.getElementById("bill_table").scrollTo({ top: 0, behavior: 'smooth' });
    }

    function nb(nb) {
        return nb ? nbFormat(parseFloat(nb), "fr-FR", 2) : "";
    }

    function getColor(i) {
        var ech = i.row.original.ech;

        var today = new Date();
        today.setHours(0, 0, 0, 0);

        if (!ech || ech === "") return "td-bg-red";

        var toColor = ["del_note", "del_note_amount", "bill", "excl_tax_amount"];
        if (i.row.original.delta === "yes" && toColor.includes(i.cell.column.id)) return "td-bg-red";

    }

    /** */
    const updateTimeLine = (row, k) => {
        setError();
        setMsg();
        var arr = [...data];
        arr[k].bill_ech = row.bill_date;
        setData(arr);

        BusinessDao.updateSparesTimeline(row).then(
            (r) => {


            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('business', {
            header: 'Affaire',
            cell: i => <div className="td-bg-grey text-center fw-bold">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('proj_man', {
            header: 'CDP',
            cell: i => <div className={"text-center " + getColor(i)}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('preme', {
            header: 'Remise (%)',
            cell: i => <div className={"text-center " + getColor(i)}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('detail', {
            header: 'Détail',
            cell: i => <div className={"text-center " + getColor(i)}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('del_note', {
            header: 'BL',
            cell: i => <div className={"text-center " + getColor(i)}
                title={"Mnt tot BL: " + i.row.original.sum_bl_biz}>
                {i.getValue()}
                {
                    i.row.original.sum_bl_biz && <Fragment>
                        <br></br>
                        <span style={{ fontSize: "13px" }}>Mnt tot BL: {i.row.original.sum_bl_biz}</span>
                    </Fragment>
                }
            </div>
        }));

        arr.push(columnHelper.accessor('del_note_amount', {
            header: 'Montant BL',
            cell: i => <div className={"text-center " + getColor(i)}>{nb(i.getValue())}</div>
        }));

        arr.push(columnHelper.accessor('bill', {
            header: 'N° DA/Facture',
            cell: i => <div className={"text-center " + getColor(i)}>
                {i.getValue()}
                {i.getValue() && <Fragment>
                    <br></br>
                    <span style={{ fontSize: "13px" }}>Facturé le: {i.row.original.bill_date}</span>
                    {
                        (!i.row.original.bill_ech && i.row.original.bill !== "") && <div>
                            <a href="#" onClick={() => updateTimeLine(i.row.original, i.row.id)}>MAJ éch.</a>
                        </div>
                    }
                </Fragment>}
            </div>
        }));

        arr.push(columnHelper.accessor('excl_tax_amount', {
            header: 'Montant HT',
            cell: i => <div className={"text-center " + getColor(i)}>{nb(i.getValue())}</div>
        }));

        arr.push(columnHelper.accessor('date_to_bill', {
            header: 'Date Fact. prévue',
            cell: i => <div className={"text-center " + getColor(i)}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('reason_for_delay', {
            header: 'Motif retard',
            cell: i => <div className={"text-center " + getColor(i)}>
                <select className="form-select" defaultValue={i.getValue()} title={i.getValue()}
                    style={{ minWidth: "150px" }} onChange={(e) => onFieldChange(e, i.row.id, "reason_for_delay")}>
                    <option value=""></option>
                    <option value="Retard notice">Retard notice</option>
                    <option value="Retard client">Retard client</option>
                    <option value="Correct. Wael">Correct. Wael</option>
                    <option value="Réserves documentaires">Réserves documentaires</option>
                    <option value="Réserves fonctionnelles">Réserves fonctionnelles</option>
                    <option value="Erreur Focus">Erreur Focus</option>
                    <option value="Revue CdP">Revue CdP</option>
                </select>
            </div>
        }));

        arr.push(columnHelper.accessor('comments', {
            header: 'Commentaire',
            cell: i => <div className={"text-center " + getColor(i)}><textarea className="form-control"
                onBlur={(e) => onFieldChange(e, i.row.id, "comments")}
                defaultValue={i.getValue()}></textarea></div>
        }));

        arr.push(columnHelper.accessor('timeline', {
            header: 'Condition de paiement',
            cell: i => <div className={"text-center " + getColor(i)}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('to_bill', {
            header: 'A facturer',
            cell: i => <div className={"text-center " + getColor(i)}>{
                (!i.row.original.bill_number) && <div className="display-flex">
                    <DesignedChckBox checked={i.getValue() === 'O'} onChange={(e) => toBillChange(i.row.id)} />
                    {i.getValue() === 'O' &&
                        <div>Demande envoyée</div>
                    }
                </div>
            }
            </div>
        }));

        return arr;
    }

    //Set columns
    const columns = getColumns();

    // On field change
    const onFieldChange = (e, id, field) => {
        var val = e.target.value.trim() === "" ? null : e.target.value.trim();
        var arr = [...data];
        arr[id][field] = val;
        setData(arr);
        save(id, field, val);

        //Special case
        if (field === "corrected_date") save(id, "sc:corrected_date", val);

    }

    // On to bill change
    const toBillChange = (id) => {
        var val = data[id].to_bill;
        var arr = [...data];

        if (!val) {
            arr[id].to_bill = 'O';
            setData(arr);

            save(id, "to_bill", "O");
        } else {
            confirmAlert({
                title: "Confirmation",
                message: "Etes-vous sûr(e) de vouloir annuler la demande de facturation de l'affaire "
                    + arr[id].business + ", de l'étape " + arr[id].step + " ?",
                buttons: [
                    {
                        label: "Oui",
                        onClick: () => {
                            arr[id].to_bill = null;
                            setData(arr);

                            save(id, "to_bill", null);
                        }
                    },
                    {
                        label: "Non"
                    }
                ]
            });

        }
    }

    // Save in data base
    function save(id, field, value) {

        BusinessDao.updateBillMachine(data[id], field, value).then(
            () => {
                if (!data[id].hasRow) {
                    var arr = [...data];
                    arr[id].hasRow = "yes";
                    setData(arr);
                }
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        )
    }

    return <Fragment>
        {loading && <WaitingRoundSnippers />}<br></br>
        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        <ul className="nav nav-tabs">
            <li className="nav-item">
                <a className={'nav-link ' + (tab === 0 ? "active" : "")}
                    aria-current="page" href="#" onClick={() => setTab(0)}>Liste</a>
            </li>

            <li className="nav-item">
                <a className={'nav-link ' + (tab === 1 ? "active" : "")}
                    href="#" onClick={() => setTab(1)}>Filtres permanents</a>
            </li>

            <li className="nav-item">
                <a className={'nav-link ' + (tab === 2 ? "active" : "")}
                    href="#" onClick={() => setTab(2)}>Explications</a>
            </li>
        </ul>

        {/** TABLE */}
        {(tab === 0) &&
            data.length > 0 &&
            <ReactTable columns={columns} data={data}
                origin="bill_machines" classes={"no-padding fixFstCol"} top0={true} />
        }

        {/*
            (totPage && tab === 0) && <div className={"mt-2 " + (data.length > 0 ? "" : "d-none")}>
                <ReactPaginate breakLabel="..." nextLabel="next >"
                    onPageChange={(e) => changePage(e.selected)}
                    pageRangeDisplayed={5}
                    pageCount={totPage}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null} activeClassName={'active'}
                    breakClassName={'page-item'} breakLinkClassName={'page-link'} containerClassName={'pagination'}
                    pageClassName={'page-item'} pageLinkClassName={'page-link'} previousClassName={'page-item'}
                    previousLinkClassName={'page-link'} nextClassName={'page-item'} nextLinkClassName={'page-link'} />
            </div>
    */}

        {/** Filters */}
        {(tab === 1 && filter) && <BillFilter filter={filter} setFilter={setFilter}
            setError={setError} filter_code="billing_track_spares" />}

        {/** HELPS */}
        {(tab === 2) && <SparesHelp />}
    </Fragment>
}

export default BillSpares;