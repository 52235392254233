import { Fragment, useEffect, useState } from "react";
import getUrlParam, { forceString } from "../../functions/StrFunctions";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import Movement from "../../services/storehouse/Movement";
import errorManagement from "../../services/errorManagement";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import LabelsChoice from "../../common/smallComponents.js/LabelsChoice";
import { downloadSingleFile } from "../../services/functions/FilesProcess";

const MoveArticleRequest = (props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [list, setList] = useState();
    const [toMove, setToMove] = useState();
    const [moveDone, setMoveDone] = useState(false);
    const [moving, setMoving] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const [step, setStep] = useState(0);
    const token = user && "Bearer " + user.accessToken;
    const [ids, setIds] = useState();

    const API_URL = process.env.REACT_APP_API_URL;
    var url = API_URL + "articles/info/get-drawing?Authorization=" + token + "&article=";
    
    const canMoveSecu = user && user.roles.includes( "ROLE_SECU_STOCK" );

    useEffect(() => {
        var id = getUrlParam(props, "id");
        Movement.getArticleRequest(id).then(
            (response) => {
                var arr = [...response];

                var id = "";
                for( let i=0; i<arr.length; i++ )
                    if( id.indexOf( forceString( arr[i].id ) ) === -1 ) id += arr[i].id + ","
                
                id = id.substring(0, id.lastIndexOf(","));
                setIds(id);

                if( canMoveSecu ){ for( let i=0; i<arr.length; i++ ) arr[i].canMove = true; }
                else{ for( let i=0; i<arr.length; i++ ) arr[i].canMove = canMove(arr[i]); }               
                
                setList(arr)

                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }, []);

    function canMove(v){
        var requested = parseFloat( v.qty );
        var stock = parseFloat( v.QTSTL );
        var secu = parseFloat( v.STSEL );

        if( requested > stock ) return false;
        if( !canMoveSecu && requested > ( stock - secu ) ) return false;
        if( requested <= 0 ) return false;
        return true;
    }

    const updateQty = (e) => {
        setError();

        var key = e.target.getAttribute("data-key");
        var arr = [...list];
        arr[key].qty = parseFloat(e.target.value);

        if ( !canMove(arr[key]) ) {
            arr[key].canMove = false;
        }else{
            arr[key].canMove = true;
        }

        
        setList(arr);
    }

    const checkToMove = (e) => {
        setError();

        var key = e.target.getAttribute("data-key");
        var arr = [...list];

        arr[key].checked = e.target.checked ? true : undefined;
        setList(arr);

        var flag = undefined;
        arr.map(v => { if (v.checked) { flag = true } });
        setToMove(flag);
    }

    const checkToMoveAll = (e) => {
        setError();

        var arr = [...list];

        for( let i=0; i<arr.length; i++){
            if( arr[i].canMove ) arr[i].checked = e.target.checked ? true : undefined;
        }
            
                
        setList(arr);

        var flag = undefined;
        arr.map(v => { if (v.checked) { flag = true } });
        setToMove(flag);
    }

    const move = () => {
        setError();

        var err = "";
        var arr = new Array();

        list.map(v => {
            if (v.checked) {
                if (parseInt(v.qty) > parseInt(v.QTSTL))
                    err += "- L'article " + v.article + " doit être mouvementé à une quantité de " + v.QTSTL + " max<br>";

                if (parseInt(v.qty) <= 0)
                    err += "- L'article " + v.article + " doit être mouvementé à une quantité supérieure à 0<br>";


                arr.push(v);
            }
        });

        if (err.length > 0) {
            setError(err);
            scrollToTop();
        } else {
            setMoving(true);
            Movement.moveArticleRequest(list).then(
                (response) => {
                    setMsg(response);
                    setMoving(false);
                    setMoveDone(true);
                    setStep(2);
                    var arr = new Array();
                    list.map(v => {
                        var o = { ...v }

                        //Deduce picked qty
                        if (v.checked) {
                            o.qty = v.old_qty - v.qty;
                            o.old_qty = v.old_qty - v.qty;
                        }

                        arr.push(o);
                    })
                    setList(arr);

                }, (error) => {
                    setError(errorManagement.handleError(props, error));
                    setMoving(false);
                    scrollToTop();
                }
            )
        }
    }

    const setDenieCause = (e) => {
        var key = e.target.getAttribute("data-key");
        var arr = [...list];

        arr[key].denieCause = e.target.value.trim === "" ? undefined : e.target.value;
        setList(arr);
    }

    const notify = () => {
        var err = "";
        setError();

        list.map(v => {
            if (parseFloat(v.qty) > 0 && (!v.denieCause || v.denieCause.trim() === ""))
                err += "<li>" + v.CDARL + " n'a pas complètement été mouvementé. Mouvementez le ou justifiez le refus</li>";
        });

        if (err.length > 0) {
            setError(err);
            scrollToTop();
        } else {
            Movement.notifyAvailableArt(list).then(
                (response) => {
                    setMsg("Envoyé, vous serez en copie");
                    setMoving(false);
                    setStep(3);
                }, (error) => {
                    setError(errorManagement.handleError(props, error));
                    setMoving(false);
                    scrollToTop();
                }
            );
        }
    }

    const exportLabels = (start) => {
        var arr = new Array();

        list.map(v => { if (v.checked) { arr.push(v); } });

        setMoving(true);
        Movement.exportLabels(arr, start, ids).then(
            (response) => {
                var file = response.substring(response.lastIndexOf("\\") + 1, response.length);
                downloadSingleFile("temp", file);
                setMoving(false);
                setStep(1);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setMoving(false);
                scrollToTop();
            }
        )
    }

    return (<Fragment>

        <h2 className="text-center">Sortie comptoir</h2>
        {loading && <WaitingRoundSnippers />}<br></br>

        {error && <ErrorMessage error={error} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        {
            list &&
            <Fragment>
                <div className="table-responsive">
                    <div className="col-6">
                        <div className="card">
                            <h5 className="card-title">Demande n°{ids}</h5>
                            Demandeur: {list[0].requester}<br></br>
                            Date: {list[0].req_date}
                        </div>
                    </div>

                    <table className="table text-center">
                        <thead>
                            <tr className="table-info text-center fw-bold">
                                <th scope="col">

                                    <input type="checkbox" className="chkbox me-1"
                                            onClick={checkToMoveAll} disabled={moving} />
                                                Action</th>
                                {ids.indexOf(",") !== -1 && 
                                    <th scope="col">#</th>
                                }
                                <th scope="col">Affaire</th>
                                <th scope="col">N° machine</th>
                                <th scope="col">Article</th>
                                <th scope="col">Référence</th>
                                <th scope="col">Emplacement</th>
                                <th scope="col">NC</th>
                                <th scope="col">Quantité en stock</th>
                                <th scope="col">Quantité restante à mouvementer</th>
                                <th scope="col">Quantité demandée</th>
                                <th scope="col">Motif demande</th>
                                <th scope="col">Motif de refus</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.map((v, k) => {
                                    return <tr key={"r" + k}>
                                        <td>
                                            {v.canMove && 
                                                <input type="checkbox" data-key={k} className="chkbox"
                                            checked={v.checked} onClick={checkToMove} disabled={moving}></input>
                                            }
                                            {!v.canMove && <div className="text-danger fw-bold">Stock insuffisant</div>}
                                        </td>  
                                        {ids.indexOf(",") !== -1 && 
                                            <td>{v.id}</td>
                                        }  
                                        <td>{v.business}</td>
                                        <td>{v.machine}</td>
                                        <td>{v.article}</td>
                                        <td>
                                            {
                                                (v.ZN09PA !== null && v.ZN09PA !== 'ignore') &&
                                                <a target="_blank" href={url + v.article}>{v.ZN02PA}</a>
                                            }
                                            { (v.ZN09PA === null || v.ZN09PA === 'ignore') && v.ZN02PA }
                                        </td>
                                        <td>{v.ZN04PA}</td>
                                        <td>
                                            { v.no_nc && <a href={"/storehouse/quality/nc?id="+v.no_nc} 
                                                target="_blank" className="btn btn-outline-danger">
                                                NC en cours
                                            </a> }
                                        </td>
                                        <td>
                                            {v.QTSTL}
                                            { v.STSEL > 0 && <span> ({v.STSEL} en sécu)</span> }
                                        </td>
                                        <td>
                                            <input className="text-center form-control" readOnly={moving}
                                                type="number" value={v.qty} data-key={k} onChange={updateQty}></input>
                                        </td>
                                        <td>{v.qty}</td>
                                        <td>{v.cause}</td>
                                        <td>
                                            <textarea className="form-control"
                                                onChange={setDenieCause} data-key={k} value={v.denieCause}></textarea>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>

                    <br></br>

                    {
                        toMove &&
                        <div className="col-8 offset-2">
                            <table className="text-center" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th className={step >= 1 ? "bg-success bg-gradient" : ""}>Etape 1</th>
                                        <th className={step >= 2 ? "bg-success bg-gradient" : ""}>
                                            Etape 2
                                        </th>
                                        <th className={step >= 3 ? "bg-success bg-gradient" : ""}>Etape 3</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <td>
                                        <LabelsChoice onChooseTag={exportLabels} printingLabel={moving} />
                                    </td>
                                    <td>
                                        {!moveDone &&
                                            <Fragment>
                                                <button className="btn btn-secondary mb-3" disabled={moving}
                                                    onClick={move}>
                                                    {moving && <ButtonWaiting />}
                                                    Mouvementer
                                                </button>
                                                <br></br>
                                                {
                                                    step === 1 && <img src="/img/storehouse/ready.gif" style={{ width: "200px", borderRadius: "0.5em" }}></img>
                                                }
                                            </Fragment>
                                        }
                                    </td>
                                    <td>
                                        <Fragment>
                                            <button className="btn btn-secondary mb-3" disabled={moving} onClick={notify}
                                                style={{ marginLeft: "10px" }}>
                                                {moving && <ButtonWaiting />}
                                                Notifier par mail</button>
                                                <br></br>
                                            {
                                                step === 2 && <img src="/img/storehouse/send.gif" style={{ width: "200px", borderRadius: "0.5em" }}></img>
                                            }
                                        </Fragment>
                                    </td>
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </Fragment>
        }



    </Fragment>)

}

export default MoveArticleRequest;