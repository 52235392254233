import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
const ODMnav = (props) => {
    const { t } = useTranslation();

    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                ODM
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="/odm/list">Liste des ODM</a>
                <a className="dropdown-item" href="/odm/request">Nouvel ODM</a>
            </ul>
        </li>
    )

}

export default ODMnav;