import { useEffect, useState } from "react";
import getUrlParam from "../../functions/StrFunctions";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";

const MachineDashboard = (props) => {
    //const [machine, setMachine] = useState();
    const [error, setError] = useState();

    useEffect(()=>{
        var machine = getUrlParam(props, "machine");
        if( machine ){
            //setMachine(machine);
            window.location.href = "/workshop/production/tracking?machine=" + machine;
        }else{
            setError("Aucun numéro de machine renseigné !");
        }
    }, []);

    return <div>
        <h5 className="text-center">Dashboard</h5>
        {error && <ErrorMessage error={error}/>}

        {!error && <div className="text-center">Redirection en cours</div>}
        
    </div>
}

export default MachineDashboard;