import { Fragment } from "react";

const ChangeResume = (p) => {

    return (
        <div className="col-10 offset-1">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Champ</th>
                        <th scope="col">Ancienne valeur</th>
                        <th scope="col">Nouvelle valeur</th>
                        <th scope="col">Justification</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        p.change.map(
                            (v, k) =>
                                <tr key={"change_" + k}>
                                    <th scope="row">{v.label}</th>
                                    <td>{v.old_value}</td>
                                    <td>{v.new_value}</td>
                                    <td>
                                        <textarea className="form-control" value={v.cause} data-key={k} onChange={p.setChangeCause}></textarea>
                                    </td>
                                    <td>
                                        <img src={"/common/remove_icon.png"} data-key={k} onClick={p.cancelChange}
                                            style={{ maxWidth: "30px", margin: "auto" }} />
                                    </td>
                                </tr>
                        )
                    }


                    {/** OLD INDEX ADD OR CHANGE */}
                    {
                        (p.oldRef.length > 0) && p.oldRef.map((v, k) => {
                            {
                                return v.mode && <tr key={"change_old_" + k}>
                                    <td colSpan={4}>{v.mode === "create" ? "Ajout" : "Mise à à jour"} de l'ancien indice {v.CDARL}</td>
                                </tr>

                            }
                        })
                    }

                </tbody>
            </table>

            {/** SUPPLIER CHANGES */
                p.newSupplier.length > 0 &&
                <div className="alert alert-info">
                    {p.newSupplier.length} nouveau{p.newSupplier.length > 1 ? "x" : ""} fournisseur{p.newSupplier.length > 1 ? "s" : ""}:<br></br>
                    {
                        p.newSupplier.map(
                            v => {
                                if ((!v.CDFOU || v.CDFOU.trim() === "")
                                    || (!v.RSC1S || v.RSC1S.trim() === "")
                                    || (!v.PXUNU || v.PXUNU.trim() === "")
                                    || (!v.CDEVU || v.CDEVU.trim() === "")
                                    || (!v.CDUNU || v.CDUNU.trim() === "")
                                    || (!v.PCTRU || v.PCTRU.trim() === "")
                                    || (!v.CFCOU || v.CFCOU.trim() === "")
                                    || (!v.DTDPU || v.DTDPU.trim() === "")) {
                                    return <p className="fw-bold text-danger">Des infos sont manquants pour le fournisseur {v.CDFOU}, cette ligne ne sera pas crée</p>
                                }
                            }
                        )
                    }
                </div>
            }

            {/** SUPPLIER REMOVE */
                p.supplierToRemove.length > 0 &&
                <ul className="list-group">
                    {
                        p.supplierToRemove.map((v, k) => {
                            return <li key={"supToRem" + k} className="list-group-item">
                                Suppression du fournisseur: <b>{v.supplier}</b>,
                                devise: <b>{v.currency}</b>, unité: <b>{v.unit}</b>
                            </li>
                        })
                    }
                </ul>
            }


            {
                !p.err && <div className="text-center">
                    <br></br>
                    <button className="btn btn-success" disabled={p.saving}
                        onClick={p.save}>
                        {p.saving && (<span className="spinner-border spinner-border-sm"></span>)}
                        Valider
                    </button>
                </div>
            }

        </div>)
}

export default ChangeResume;