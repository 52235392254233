import { Fragment, useEffect } from "react";
import { useState } from "react";
import transport from "../../services/supplyChain/transport";
import errorManagement from "../../services/errorManagement";
import { scrollToTop } from "../../functions/PageAnimation";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import Moment from "moment";
import { Tooltip } from "react-bootstrap";
import CustomToolTip from "../../common/smallComponents.js/CustomToolTip";
import { downloadFile } from "../../functions/FormCtrl";

const Transport = (props) => {
    const [list, setList] = useState();
    const [exp, setExp] = useState();
    const [planning, setPlanning] = useState();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState();
    const [tab, setTab] = useState(1);
    const [toolTip, setToolTip] = useState();
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getList();
    }, []);

    const changeTab = (e) => {
        setTab(parseInt(e.target.getAttribute("data-tab")));
    }

    const getList = () => {
        transport.getTransportList().then(
            (response) => {
                setList(response.list);
                setPlanning(response.planning);
                setExp(response.exp);
                setLoading(false);
                setSaving(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
                setSaving(false);
            }
        );
    }

    Moment.locale("fr");

    const onOrderNbChange = (e) => {
        var order = e.target.value;
        var id = e.target.getAttribute("data-id");
        setSaving(true);

        transport.setOrderToTransport(id, order).then(
            (response) => {
                getList();
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }

    const getOtherPeriod = (e) => {
        e.preventDefault();
        setLoading(true);

        transport.getTransportListPeriod(e.target.date.value).then(
            (response) => {
                setList(response.list);
                setPlanning(response.planning);
                setLoading(false);
                setSaving(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
                setSaving(false);
            }
        );
    }

    const openToolTip = (e) => {
        var business = e.target.getAttribute("data-business");
        var machine = e.target.getAttribute("data-machine");

        var html;
        if (business === null) {
            html = "<h3 className='text-center'>Aucun transport programmé</h3>";
        } else {
            business = e.target.getAttribute("data-business").split("<br/>");
            machine = e.target.getAttribute("data-machine").split("<br/>");
            
            html = "<h3 className='text-center'>Détail des transports</h3>";
            html += "<ul>"
            for (var i = 0; i < business.length; i++) {
                html += "<li>" + business[i] + "</li>";
                try {
                    var m = machine[i].split(";");
                    html += "<ul>"
                    for (var j = 0; j < m.length - 1; j++) html += "<li>" + m[j] + "</li>";
                    html += "</ul>"
                } catch (e) {

                }

            }
            html += "</ul>"
        }

        
        business = e.target.getAttribute("data-business-dep");
        machine = e.target.getAttribute("data-machine-dep");

        if( business !== null ){
            html += "<h3 className='text-center'>Détail des demandes de départ</h3>";
            html += "<ul>"

            business = business.split("<br/>");
            machine = machine.split("<br/>");
            for (var i = 0; i < business.length; i++) {
                html += "<li>" + business[i] + "</li>";
                try {
                    var m = machine[i].split(";");
                    html += "<ul>"
                    for (var j = 0; j < m.length - 1; j++) html += "<li>" + m[j] + "</li>";
                    html += "</ul>"
                } catch (e) {

                }

            }
            html += "</ul>"
        }

        setToolTip(html)
    }

    const downloadList = () => {
        downloadFile(API_URL + "transport/export-xls");
    }

    const onCommentChange = (e) => {
        setError();
        var key = e.target.getAttribute("data-key");
        var machine = exp[key].no_machine;
        var val = e.target.value;

        transport.updateTransportField(machine, "delivery_comment", val, "text").then(
            (response) => {
                
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            });
    }

    const onCommentType = (e) => {
        var key = e.target.getAttribute("data-key");
        var val = e.target.value;
        var arr = [...exp];

        arr[key].delivery_comment = val;
        setExp(arr);
    }

    const onCheckDelivery = (e) => {
        setError();
        var key = e.target.getAttribute("data-key");
        var val = e.target.checked ? 1 : 0;
        var machine = exp[key].no_machine;
        
        var arr = [...exp];

        arr[key].delivery_confirm = val;
        setExp(arr);

        transport.updateTransportField(machine, "delivery_confirm", val, "number").then(
            (response) => {
                
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            });
    }

    return (
        <div>
            {loading && <WaitingRoundSnippers />}

            {error && <ErrorMessage error={error} />}

            {list &&
                <Fragment>
                    {
                        tab === 0 &&

                        <a href="#" onClick={downloadList}>
                            <img src={"/common/download_icon.png"} title="Télécharger la liste complète"
                                style={{ width: "30px", float: "right" }} />
                        </a>
                    }


                    <ul className="nav nav-tabs">
                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 1 ? "active" : "")}
                                href="#" onClick={changeTab} data-tab="1">Planning des transports</a>
                        </li>
                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 0 ? "active" : "")}
                                href="#" onClick={changeTab} data-tab="0">Liste des demandes de transport</a>
                        </li>
                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 2 ? "active" : "")}
                                href="#" onClick={changeTab} data-tab="2">Expéditions</a>
                        </li>
                    </ul>

                    <div className="table-responsive">
                        {
                            tab === 0 &&
                            <table className="table" style={{ display: (tab === 0 ? "" : "none") }}>
                                <thead>
                                    <tr className="table-info">
                                        <th scope="row">Affaire</th>
                                        <th style={{ maxWidth: "200px" }}>N° de série</th>
                                        <th>Camion</th>
                                        <th>Date prév. charg.</th>
                                        <th>Date de prév. livr.</th>
                                        <th>N° de cmd</th>
                                        <th>Lieu</th>
                                        <th>Prix</th>
                                        <th>Liens</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.map(
                                            (v, k) =>
                                                <tr key={"l_" + k}>
                                                    <th scope="row">{v.business}</th>
                                                    <td style={{ maxWidth: "200px", overflowWrap: "break-word" }}>{v.machines.substring(0, v.machines.length - 1)}</td>
                                                    <td>{v.truck_num}</td>
                                                    <td>{v.charg !== null && Moment(v.charg.replace("T", " ")).format("DD/MM/YYYY à HH:MM")}</td>
                                                    <td>{v.decharg !== null && Moment(v.decharg.replace("T", " ")).format("DD/MM/YYYY à HH:MM")}</td>
                                                    <td className="text-center" style={{ minWidth: "100px" }}>
                                                        {
                                                            !saving &&
                                                            <input className="form-control text-center" data-id={v.id}
                                                                onBlur={onOrderNbChange} defaultValue={v.order} />
                                                        }
                                                        {
                                                            saving && <div className="spinner-grow text-info text-center" role="status"></div>
                                                        }
                                                    </td>
                                                    <td>
                                                        {v.company}
                                                    </td>
                                                    <td>{v.price}</td>
                                                    <td>
                                                        <a target="_blank"
                                                            href={"http://10.141.44.140:8080/storehouse/delivery-note/delivery-request/" + v.business + "/" + v.id}>Demande de transp</a>
                                                        {
                                                            v.order &&
                                                            <Fragment>
                                                                <br></br>
                                                                <a target="_blank"
                                                                    href={"https://acps.sealedair.com/purchase/order/edition?order=" + v.order}>Visualiser cmd</a>
                                                            </Fragment>
                                                        }


                                                    </td>

                                                </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        }

                        {
                            tab === 1 &&
                            <Fragment>
                                <div className="col-6 offset-3" style={{ display: (tab === 1 ? "" : "none") }}>
                                    <form onSubmit={getOtherPeriod}>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1">Autre période</span>
                                            <input type="date" name="date" defaultValue={Moment(new Date()).format("YYYY-MM-DD")} className="form-control text-center" />
                                            <button type="submit" className="btn btn-success">Rechercher</button>
                                        </div>
                                    </form>

                                    <table className="table text-center">
                                        <thead>
                                            <tr>
                                                <th scope="col">Semaines</th>
                                                <th scope="col">Lundi</th>
                                                <th scope="col">Mardi</th>
                                                <th scope="col">Mercredi</th>
                                                <th scope="col">Jeudi</th>
                                                <th scope="col">Vendredi</th>
                                                <th scope="col">Samedi</th>
                                                <th scope="col">Dimanche</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                planning.map(
                                                    (v, k) =>
                                                        <Fragment>
                                                            <tr>
                                                                <td>{v[0].week}</td>
                                                                {
                                                                    v.map(
                                                                        (v2, k2) => {
                                                                            return <td onClick={openToolTip}
                                                                                data-business={v2.business} data-machine={v2.machines}
                                                                                data-business-dep={v2.business_depart} data-machine-dep={v2.machines_depart}
                                                                                className={!v2.cal ? "table-secondary cursor-pointer" :
                                                                                    v2.count && parseInt(v2.count) > 2 ? "table-danger cursor-pointer" :
                                                                                        v2.count && parseInt(v2.count) > 1 ? "table-warning cursor-pointer" : "table-success cursor-pointer"}>
                                                                                {v2.cal &&
                                                                                    <div style={{ fontSize: "12px" }} className="cursor-pointer"
                                                                                        data-business={v2.business} data-machine={v2.machines}>{Moment(v2.cal).format("DD/MM")}</div>
                                                                                }
                                                                                {
                                                                                    v2.business_depart 
                                                                                        && <img src={"/common/time.png"} style={{width: "20px"}}
                                                                                                data-business={v2.business} data-machine={v2.machines}
                                                                                               data-business-dep={v2.business_depart} data-machine-dep={v2.machines_depart}></img>
                                                                                }
                                                                            </td>}
                                                                    )
                                                                }
                                                            </tr>
                                                        </Fragment>
                                                )

                                            }

                                        </tbody>
                                    </table>
                                    <br></br>

                                    <h5 className="text-center">Légendes</h5>
                                    <table className="table text-center fw-bold">
                                        <thead>
                                            <tr>
                                                <th className="table-secondary">Période non couverte</th>
                                                <th className="table-success">Moins de 2 camions programmés</th>
                                                <th className="table-warning">Moins de 3 camions programmés</th>
                                                <th className="table-danger">Au moins 3 camions programmés</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>

                            </Fragment>
                        }

                        {
                            tab === 2 &&
                            <table className="table text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Affaire</th>
                                        <th scope="col">Machine</th>
                                        <th scope="col">Type de config</th>
                                        <th scope="col">Fabrication</th>
                                        <th scope="col">Date d'entrée</th>
                                        <th scope="col">Date de sortie</th>
                                        <th scope="col">Commentaires</th>
                                        <th scope="col">Livraison confirmée</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        exp.map(
                                            (v, k) =>
                                                <Fragment>
                                                    <tr>
                                                        <td>{v.affaire}</td>
                                                        <td>{v.no_machine}</td>
                                                        <td>{v.typ_config}</td>
                                                        <td>{v.manufacturing}</td>
                                                        <td><input type="date" className="form-control" readOnly></input></td>
                                                        <td><input type="date" className="form-control" value={v.delivery_date} readOnly></input></td>
                                                        <td>
                                                            <textarea className="form-control" value={v.delivery_comment}
                                                                data-key={k} onChange={onCommentType} onBlur={onCommentChange}></textarea>
                                                        </td>
                                                        <td>
                                                            <input type="checkbox" data-key={k} checked={v.delivery_confirm}
                                                                onClick={onCheckDelivery}></input>
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                        )

                                    }

                                </tbody>
                            </table>
                        }

                    </div>
                </Fragment>


            }

            {
                toolTip &&
                <div className="custom-tooltip" >
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => setToolTip()}></button>
                    <div dangerouslySetInnerHTML={{ __html: toolTip }}>

                    </div>
                </div>
            }
        </div>
    )


}

export default Transport;