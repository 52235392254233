import { Fragment, useState } from "react";
import { getBase64 } from "../../../../functions/StrFunctions";
import ShareFileDao from "../../../../services/common/ShareFileDao";
import errorManagement from "../../../../services/errorManagement";
import { scrollToTop } from "../../../../functions/PageAnimation";
import MachineInfo from "../../../../services/machines/MachineInfo";
import { arrayRemoveElt } from "../../../../functions/ArrayFunctions";

const PartFive = (props) => {
    const [imgDisplay, setImgDisplay] = useState();
    const [loading, setLoading] = useState(true);


    const addAnnexe = (e) => {
        if (props.readOnly) return;

        var arr = [...props.annexes];

        getBase64(e.target.files[0], (res) => {
            arr.push({
                name: e.target.files[0].name,
                img: res,
                new: true
            });
            props.setAnnexes(arr);
            e.target.value = "";
        });

        //Save in database
        var o = { machine: props.machineInfo.no_machine };
        ShareFileDao.uploadAnyFiles("machines/info/upload-annexe", o, e.target.files).then(
            (response) => {
                console.log(response)
            },
            (error) => {
                props.setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }

    /**
     * Display the image annexe
     * @param {*} e 
     */
    const displayAnnexe = (e) => {
        setImgDisplay(e.target.getAttribute("data-img"));
    }

    const deleteAnnexe = (e) => {
        if (props.readOnly) return;

        var key = e.target.getAttribute("data-key");
        var arr = [...props.annexes];

        MachineInfo.deleteAnnexe(props.machineInfo.no_machine, arr[key].name).then(
            (response) => {
                
            },
            (error) => {
                props.setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )

        arr = arrayRemoveElt(arr, key);

        props.setAnnexes(arr);

    }

    return (
        <Fragment>
            <div className="table-responsive">
                <table className="table businessCardStyle">
                    <thead>
                        <tr className="bg-dark bg-gradient text-white text-center rounded">
                            <th scope="col">Images annexes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/** ALREADY IMPORTED */}
                        {
                            props.annexes && props.annexes.length > 0 &&
                            <tr>
                                <td>
                                    <div className="mb-3 text-center">
                                        <label for="formFile"
                                            className="form-label fw-bold">Annexes déjà importés</label>

                                        <div style={{ display: "flex" }}>
                                            <div style={{ width: "50%", minWidth: "250px" }}>
                                                <ul className="list-group">
                                                    {
                                                        props.annexes.map((v, k) => {
                                                            return v.typ_card === "machine" && <li key={"ann" + k}
                                                                className="list-group-item"
                                                                data-img={v.img} onClick={displayAnnexe}>
                                                                {v.name}
                                                                {
                                                                    (!v.new && !props.readOnly) &&
                                                                    <img src={"/common/remove_icon.png"}
                                                                        style={{ marginLeft: "10px", width: "30px" }}
                                                                        onClick={deleteAnnexe} data-key={k}></img>
                                                                }
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            <div style={{ width: "50%", minWidth: "250px" }}>
                                                {imgDisplay &&
                                                    <div>
                                                        <div style={{ display: "flex" }}>
                                                            <h5 style={{ marginLeft: "auto", marginRight: "auto" }}>Apercu</h5>
                                                            <button type="button" className="btn-close" aria-label="Close"
                                                                onClick={displayAnnexe}></button>
                                                        </div>
                                                        <img src={imgDisplay} style={{ maxWidth: "200px" }}></img>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        }

                        {/** ADD ANNEXE */}
                        {!props.readOnly &&
                            <tr>
                                <td>
                                    <div className="mb-3 text-center">
                                        <label for="formFile"
                                            className="form-label fw-bold">Ajouter un annexe</label>
                                        <input className="form-control" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                            type="file" onChange={addAnnexe} />
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </Fragment>
    )
}

export default PartFive;