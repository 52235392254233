import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import errorManagement from "../../services/errorManagement";
import ProdTrackingDao from "../../services/machines/ProdTrackingDao";
import { createColumnHelper } from "@tanstack/react-table";
import { getDateTimeFromDb, getFrFormat } from "../../functions/StrFunctions";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { confirmAlert } from "react-confirm-alert";

const ProdTrackingList = (props) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const [detail, setDetail] = useState();
    const [searchDetail, setSearchDetail] = useState(false);
    const isAdmin = user && user.roles.includes("ROLE_ADMIN");

    useEffect(() => {
        ProdTrackingDao.getTrackingList().then(
            (response) => {
                //Correct last status
                for(let i=0; i<response.length; i++)
                    response[i].complete_status = getLastStatus(response[i].complete_status)
                

                setData(response);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            });
    }, []);

    const deleteRow = (k) => {
        var arr = [...detail];

        confirmAlert({
            title: "Suppression",
            message: "Confirmez-vous la suppression de cette tâche ?",
            buttons: [{
                label: "Oui", onClick: () => {
                    ProdTrackingDao.deleteRow(arr[k].id);
                    arr = arrayRemoveElt(arr, k);
                    setDetail(arr);
                }
            },
            { label: "Non" }]
        });
    }

    function getSubAss(val) {
        if (!val || val.split("-").length < 3) return "";

        return val.split("-")[2];
    }

    function getLastStatus(v){
        if( !v || v.indexOf("#") === -1 ) return "En attente";
        v = v.substring(0, v.lastIndexOf("#"));

        const userStatusMap = getStatusByUser(v.split("#"))

        for (let userId in userStatusMap) {
            const task = userStatusMap[userId];
            if (task.status !== "Terminé" && task.status !== "Chgt. pers.") {
                return task.status;
            }
        }

        return "Terminé";

        /*
        var split = v.split("#");
        var last_status = "Terminé";
        var pers_change = 0;
        var not_found = "";

        for(let i=0; i<split.length; i++){
            //If change of pers, can ignore on unavailable end
            if(  split[i].split('-')[2] === "Chgt. pers." ){
                pers_change++;
                if(i===0) return "Démarré";
            }else if( split[i].split('-')[2] !== "Terminé" && v.indexOf( 'Terminé-' + split[i].split('-')[3] ) === -1 ){
                //Is status is not ended and not ended but changement occured, can ignore it
                if( pers_change > 0 ){
                    pers_change--;
                    not_found += split[i].split('-')[3] + "-";
                }else if( not_found.indexOf(split[i].split('-')[3] + "-") !== -1 ){
                    //If change of pers occured and the same person is still not found, ignore it
                    continue;
                }else{
                    // else send the last status
                    return split[i].split("-")[2].replace("Chgt. pers.", "Démarré");
                }
            }         
        }*/

        //return last_status//.split("-")[2]
    }

    function getStatusByUser(v){
        const userStatusMap = {};
        const arr = [];

        v.forEach(task => {
            var split = task.split("-");
            // If the user is already registered, we compare the dates
            if (!userStatusMap[ split[3] ]) userStatusMap[ split[3] ] = {
                id: split[0],
                station: split[1],
                status: split[2],
                user_id: split[3]
            };
        });

        return userStatusMap;
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('business', {
            header: 'Affaire',
            cell: i => <div className="fw-bold text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('machine', {
            header: 'Machine',
            cell: i => <div className="text-center">{i.getValue().trim()}</div>
        }));

        arr.push(columnHelper.accessor('workstation', {
            header: 'Poste',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('french_label', {
            header: 'Tâche',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('subassembly', {
            header: 'S/E',
            cell: i => <div className="text-center">{getSubAss(i.getValue())}</div>
        }));

        /*arr.push(columnHelper.accessor('last_status', {
            header: 'Statut en cours',
            cell: i => <div className="fw-bold text-center" style={getBg(i.getValue())}>{i.getValue()}</div>
        }));*/

        arr.push(columnHelper.accessor('complete_status', {
            header: 'Statut en cours',
            cell: i => <div className="fw-bold text-center" style={getBg(i.getValue())}>
                {i.getValue()}
            </div>
        }));

        arr.push(columnHelper.accessor('date_start', {
            header: 'Date début',
            cell: i => <div className="text-center">{getFrFormat(i.getValue())}</div>
        }));

        arr.push(columnHelper.accessor('date_end', {
            header: 'Date dernière mise à jour',
            cell: i => <div className="text-center">{getFrFormat(i.getValue())}</div>
        }));

        arr.push(columnHelper.accessor('xx', {
            header: '',
            cell: i => <div className="text-center">
                <button className="btn btn-outline-secondary"
                    onClick={() => getDetail(i)}>Détails</button>
            </div>
        }));

        return arr;
    }

    const getDetail = (i) => {
        var o = i.row.original;

        setSearchDetail(true);
        ProdTrackingDao.getTrackingListDetail({
            machine: o.machine.trim(),
            subassembly: o.subassembly.trim(), workstation: o.workstation
        }).then(
            (response) => {
                setDetail(response);
                setSearchDetail(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setSearchDetail(false);
            }
        );
    }

    function getBg(status) {
        if (!status) {
            return { backgroundColor: "rgb(87, 155, 252)" }
        } else if (status === "Démarré" || status === "Redémarré") {
            return { backgroundColor: "rgb(253,171,61)" }
        } else if (status === "Terminé") {
            return { backgroundColor: "rgb(156, 211, 38)" }
        } else if (status === "Bloqué") {
            return { backgroundColor: "rgb(223, 47, 74)", color: "white" }
        } else if (status === "Chgt. pers.") {
            return { backgroundColor: "rgb(	253, 253, 150)" }
        }
    }

    //Set columns
    const columns = getColumns();

    const onChangeDetail = (k, e, field) => {
        var arr = [...detail];
        arr[k][field] = e.target.value;
        setDetail(arr)
    }

    const onBlurDetail = (k, e, field) => {
        var arr = [...detail];
        arr[k][field] = e.target.value;
        setDetail(arr);

        var o = {
            id: arr[k].id,
            field: field,
            value: e.target.value
        }

        ProdTrackingDao.updateField(o).then( () => {}, 
            (error) => {setError(errorManagement.handleError(props, error)); setDetail()} );
    }

    return <Fragment>
        <h5 className="text-center">Suivi d'activité</h5>

        {error && <ErrorMessage error={error} errorState={setError} />}
        {loading && <WaitingRoundSnippers />}


        <ReactTable columns={columns} data={data}
            origin="prodTrackList" classes={"no-padding"} top0={true} />

        {
            (searchDetail || detail) &&
            <div className="custom-tooltip-v2">
                <div className="modal-body blue-purple-bg xl">
                    {!searchDetail && <button type="button" className="btn-close" onClick={() => setDetail()}></button>}
                    {searchDetail && <WaitingRoundSnippers />}

                    {
                        (detail && detail.length > 0 ) && <div>
                            <h6 className="text-center">{detail[0].machine} - {detail[0].subassembly}</h6>

                            <table className="table text-center">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Exécutant</th>
                                        <th>Poste</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                        <th>Commentaire</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {detail && detail.map((v, k) =>
                                        <tr key={"d" + k}>
                                            <td className="fw-bold">{v.id}</td>
                                            <td className="fw-bold">{v.last_name} {v.first_name}</td>
                                            <td className="fw-bold">
                                                <select className="form-select" value={v.workstation}
                                                    onChange={(e) => onBlurDetail(k, e, "workstation")}
                                                    disabled={user.id !== v.manager && !isAdmin}>
                                                    <option selected>Choisissez...</option>
                                                    <option value="Cartérisation">Cartérisation</option>
                                                    <option value="Montage">Montage</option>
                                                    <option value="Intégration">Intégration</option>
                                                    <option value="Intégration électrique">Intégration électrique</option>
                                                </select>
                                            </td>
                                            <td className="fw-bold">{v.status}</td>
                                            <td className="fw-bold">
                                                <input type="datetime-local" className="form-control"
                                                    readOnly={user.id !== v.manager && !isAdmin}
                                                    onChange={(e) => onChangeDetail(k, e, "date")}
                                                    onBlur={(e) => onBlurDetail(k, e, "date")}
                                                    value={getDateTimeFromDb(v.date, "en")}></input>
                                            </td>
                                            <td className="fw-bold">
                                                <textarea className="form-control"
                                                    readOnly={user.id !== v.manager && !isAdmin}
                                                    onChange={(e) => onChangeDetail(k, e, "blocking_cause")}
                                                    onBlur={(e) => onBlurDetail(k, e, "blocking_cause")}
                                                    value={v.blocking_cause}></textarea>
                                            </td>
                                            <td>{(user.id === v.manager || isAdmin) &&
                                                <button className="btn btn-outline-danger ms-3" onClick={()=>deleteRow(k)}>Supprimer</button>
                                            }</td>
                                        </tr>)}
                                </tbody>
                            </table>
                        </div>
                    }


                </div>
            </div>
        }
    </Fragment>
}

export default ProdTrackingList;