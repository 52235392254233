import { Fragment, useEffect, useState } from "react"
import { getDate, updateDrawingUrl } from "../../functions/StrFunctions";
import Movement from "../../services/storehouse/Movement";
import errorManagement from "../../services/errorManagement";
import { scrollToTop } from "../../functions/PageAnimation";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import Moment from "moment";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import { useSelector } from "react-redux";

const StorageCtrl = (props) => {
    const [period, setPeriod] = useState();
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [error, setError] = useState();

    const { user: currentUser } = useSelector((state) => state.auth);
    const token = currentUser && "Bearer " + currentUser.accessToken;
    const API_URL = process.env.REACT_APP_API_URL;
    var url2 = API_URL + "articles/info/get-drawing-url?Authorization=" + token + "&url=";

    useEffect(() => {
        var d = new Date();
        d.setDate(d.getDate() - 1);
        var o = new Object();
        o.end = getDate(d, "en");

        d.setDate(d.getDate() - 30);
        o.start = getDate(d, "en");

        o.submited = false;
        setPeriod(o)
    }, [])

    const setDate = (e, field) => {
        var o = { ...period };
        o[field] = e.target.value;
        setPeriod(o);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        var o = { ...period };
        o.submited = true;
        setPeriod(o);

        setLoading(true);
        Movement.getStorageControl(period.start, period.end).then(
            (response) => {
                setList(response)
                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }

    Moment.locale('fr');

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('mvt_type',
            {
                header: 'Type de mouvement',
                cell: (i) => <div className="text-center">{i.getValue()}</div>
            }));

        arr.push(columnHelper.accessor('ar', {
            header: 'Article',
            cell: (i) => <div className="text-center">
                <a target="_blank" href={"/article/info/get?article="+i.getValue()}>{i.getValue()}</a></div>
        }));

        arr.push(columnHelper.accessor('ref', {
            header: 'Plan',
            cell: (i) => <div className="text-center">
                {i.row.original.drawing === null && <span>{i.getValue()}</span>
                }
                {(i.row.original.drawing != null && i.row.original.drawing !== 'ignore') &&
                    <a target="_blank"
                        href={url2 + updateDrawingUrl(i.row.original.drawing)}>{i.getValue()}</a>
                }
            </div>
        }));

        arr.push(columnHelper.accessor('location', {
            header: 'Renvoi',
            cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('xx', {
            header: 'Contrôle',
            cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));
        return arr;
    }

    const columns = getColumns();

    return <Fragment>
        {(period && period.submited) &&
            <div className="d-flex no-print" style={{ position: "absolute" }}>
                <div><button className="btn btn-secondary" onClick={() => window.print()}>Imprimer</button></div>
                <div><button className="btn btn-warning ms-3" onClick={() => window.location.reload()}>Nouvelle recherche</button></div>
            </div>}

        <h4 className="text-center">Contrôle de rangement - Entrée en stock Par: _______________________</h4>
        {loading && <WaitingRoundSnippers />}


        {period && !period.submited && <form onSubmit={onSubmit}>
            <div className="card col-6 offset-3">
                <h6 className="text-center mb-3">Sur quelle période voulez-vous contrôler</h6>

                <div className="input-group mb-3">
                    <span className="input-group-text">Du</span>
                    <input type="date" value={period.start} className="form-control"
                        name="start" onChange={(e) => setDate(e, "start")} />

                    <span className="input-group-text">au</span>
                    <input type="date" value={period.end} className="form-control"
                        onChange={(e) => setDate(e, "end")} name="end" />

                    <button className="btn btn-success">Chercher</button>
                </div>


            </div>
        </form>}

        {(period && period.submited) && <Fragment>
            <h5 className="text-center">Période: {getDate(period.start, "fr")} - {getDate(period.end, "fr")}</h5>
            <ReactTable columns={columns} data={list} top0={true}
                origin="storage-ctrl" show={1000} />
        </Fragment>}
    </Fragment>
}

export default StorageCtrl;