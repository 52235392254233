import { Fragment, useEffect, useState } from "react";
import transport from "../../../services/supplyChain/transport";
import getUrlParam, { getFrFormat } from "../../../functions/StrFunctions";
import errorManagement from "../../../services/errorManagement";
import { scrollToTop } from "../../../functions/PageAnimation";
import WaitingRoundSnippers from "../../../common/smallComponents.js/WaitingRoundSnippers";
import ErrorMessage from "../../../common/smallComponents.js/ErrorMessage";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../../common/smallComponents.js/ReactTable";

const Historic = (p) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [data, setData] = useState();

    useEffect(() => {

        transport.getHistoric(getUrlParam(p.props, "id")).then(
            (r) => {
                setLoading(false);
                setData(r)


            }, (error) => {
                setError(errorManagement.handleError(p.props, error));
                scrollToTop();
                setLoading(false);
            }
        )

    });

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();
               
        
        arr.push(columnHelper.accessor('datetime', {
            header: 'Date', cell: (i) => <div className="text-center">
                { getFrFormat( i.getValue(), false, true )}
            </div>
        }));

        arr.push(columnHelper.accessor('log_text', {
            header: 'Log',
            cell: (i) => <div dangerouslySetInnerHTML={{ __html: i.getValue() }}></div>
        }));
        return arr;
    }

    //Set columns
    const columns = getColumns();

    return <Fragment>
        <h5 className="text-center">Historique de l'expédition n°{getUrlParam(p.props, "id")}</h5>

        {loading && <WaitingRoundSnippers />}
        {error && <ErrorMessage error={error} errorState={setError} />}

        {data && <ReactTable columns={columns} data={data} top0={true} origin="log_shipment" />}
    </Fragment>

}

export default Historic;