import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import LogOut from "../../../components/auth/LogOut";
import i18n from "../../../i18n";

const ProfileNav = (props) => {
    const { t } = useTranslation();
    const [locale, setLocale] = useState(i18n.language);
    i18n.on('languageChanged', (lng) => setLocale(i18n.language));

    function changeLocale(l) {
        if (locale !== l) {
            i18n.changeLanguage(l);
        }
    }

    return <Fragment>

        {props.currentUser &&
            <ul className="navbar-nav">
                <li className="nav-item dropdown dropstart">
                    <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {props.currentUser ? props.currentUser.last_name + ' ' + props.currentUser.first_name
                            : t('user')}
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        {
                            props.currentUser ? (
                                <div>
                                    <li>
                                        <a href={"/profile"} className="dropdown-item">
                                            {t('profile')}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/logout" className="dropdown-item" onClick={LogOut}>
                                            {t('logout')}
                                        </a>
                                    </li>
                                </div>
                            ) :

                                (
                                    <div>
                                        <li>
                                            <a href={"/login"} className="dropdown-item">
                                                {t('login')}
                                            </a>
                                        </li>
                                        <li>
                                            <a href={"/register"} className="dropdown-item">
                                                {t('sign in')}
                                            </a>
                                        </li>
                                    </div>
                                )
                        }



                        {
                            (props.currentUser && props.currentUser.roles && props.currentUser.roles.includes('ROLE_INTERIM_CREAT')) &&
                            <li>
                                <a href="/register/interim" className="dropdown-item">
                                    Créer un compte intérimaire
                                </a>
                            </li>
                        }

                        {
                            (props.currentUser && props.currentUser.roles && props.currentUser.roles.includes('ROLE_CONTACTS')) &&
                            <li>
                                <a href="/settings/contacts-mng" className="dropdown-item">Carnet d'adresse</a>
                            </li>
                        }

                        <li><hr className="dropdown-divider" /></li>

                        {
                            locale !== "fr" &&
                            <li className="text-end">
                                <a href="#" onClick={() => changeLocale('fr')}>
                                    <img style={{ width: "40px" }} src="/common/fr_lang.png" />
                                </a>
                            </li>
                        }

                        {
                            locale !== "en" &&
                            <li className="text-end">
                                <a href="#" onClick={() => changeLocale('en')}>
                                    <img style={{ width: "40px" }} src="/common/en_lang.png" />
                                </a>
                            </li>
                        }


                    </ul>
                </li>
            </ul>
        }

        {
            !props.currentUser && <ul className="navbar-nav">
                <li className="nav-item me-2">
                    <a href={"/login"} className="dropdown-item">
                    <img src="/common/user.png" className="me-2" style={{width: "30px"}} ></img>
                    {t('login')}</a>
                </li>
            </ul>
        }

    </Fragment>

}

export default ProfileNav;