import { createColumnHelper } from "@tanstack/react-table";
import { Fragment, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import ReactTable from "../../../common/smallComponents.js/ReactTable";
import { ButtonWaiting } from "../../../functions/PageAnimation";
import BomDaoV2 from "../../../services/machines/BomDaoV2";

const SpeTab = (p) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const [working, setWorking] = useState(false);
    const [speCreating, setPeCreating] = useState(false);
    const [progress, setProgress] = useState(0);
    const [speCreationRes, setspeCreationRes] = useState([]);

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('cat', {
            header: 'Catégorie',
            filterFn: 'selectMultiple',
            cell: i => <div className="display-flex">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('se', {
            header: 'S/E',
            cell: i => <div className="text-center fw-bold">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('rep', {
            header: 'Repère',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('qty', {
            header: 'Qté',
            cell: i => <div className="display-flex">
            <input type="number" className="form-control text-center" defaultValue={i.getValue()}
                //onChange={p.onFieldChange} 
                onBlur={p.onFieldBlur} data-key={i.row.original.parent_key}
                data-table="" data-field="qty" data-id={i.row.original.id}
                readOnly={i.row.original.qty_changing}></input>
            {i.row.original.qty_changing && <button className="btn"><ButtonWaiting /></button>}
        </div>
        }));

        arr.push(columnHelper.accessor('french_label', {
            header: 'Désignation FR',
            cell: i => <div>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('ref', {
            header: 'Référence',
            cell: i => <div className="display-flex">
            <input className="form-control" defaultValue={i.getValue()} //onChange={p.onFieldChange}
                onBlur={p.onFieldBlur} style={{ minWidth: "250px" }} data-key={i.row.original.parent_key} title={i.getValue()}
                data-table="" data-field="ref" data-id={i.row.original.id}></input>
            {i.row.original.ref_changing && <button className="btn"><ButtonWaiting /></button>}
        </div>
        }));

        arr.push(columnHelper.accessor('maker', {
            header: 'Fabricant',
            cell: i => <div className="display-flex">
            <input className="form-control" defaultValue={i.getValue()} //onChange={p.onFieldChange}
                onBlur={p.onFieldBlur} data-key={i.row.original.parent_key} title={i.getValue()}
                data-table="" data-field="maker" data-id={i.row.original.id}></input>
            {i.row.original.maker_changing && <button className="btn"><ButtonWaiting /></button>}
        </div>
        }));

        arr.push(columnHelper.accessor('ar', {
            header: 'Code article',
            filterFn: 'strWithEmpty',
            cell: i => <div className="display-flex">
            <input className="form-control" defaultValue={i.getValue()} //onChange={p.onFieldChange}
                onBlur={p.onFieldBlur} data-key={i.row.original.parent_key} title={i.getValue()}
                data-table="" data-field="ar" data-id={i.row.original.id}
                style={{ minWidth: "150px" }}></input>
            {i.row.original.ar_changing && <button className="btn"><ButtonWaiting /></button>}
        </div>
        }));

        arr.push(columnHelper.accessor('generic', {
            header: 'Article générique',
            filterFn: 'strWithEmpty',
            cell: i => <div className="display-flex">
                {p.genericChecking && <div><ButtonWaiting />Recherche d'article générique</div>}
                {i.getValue()}
            </div>
        }));

        arr.push(columnHelper.accessor('stock_at_term', {
            header: 'Stock',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('OBSES', {
            header: 'Coms',
            cell: i => <div>
                        <div>{(i.getValue() && i.getValue() !== null && i.getValue().trim() !== "") ?
                            <img title={i.getValue()} src={"/common/tooltip.png"}></img> : ""}
                        </div>
                    </div>
        }));

        return arr;
    }

    //Set columns
    const columns = getColumns();

    /**
     * Validate BOM
     */
    const createSpe = async () => {
        var arr = new Array();
        setPeCreating(true);

        for (let i = 0; i < p.list.length; i++) {
            var res = await BomDaoV2.createSpeArt(p.list[i], p.machine);
            arr.push(res);
            console.log(res)
            setProgress(((i / (p.list.length - 1)) * 100).toFixed(0));
        }
        setspeCreationRes(arr);
        setProgress(100);
        setPeCreating(false);
    }

    return <Fragment>

        <div className="mb-3 text-center">
            <button className="btn btn-outline-success" disabled={speCreating}
                onClick={createSpe}>
                {speCreating && <ButtonWaiting />}
                Lancer la création des articles
            </button>
            <p className="fst-italic">Remarque: inutile de lancer la création avant le lancement en achat, cela se fera automatiquement en arrière plan</p>

        </div>

        {
            (speCreating || progress === 100) && <Fragment>
                <h5 className="text-center">Création d'article spécifique. Ne pas rafraîchir la page</h5>
                <div className="col-8 offset-2">
                    <ProgressBar animated now={progress} label={`${progress}%`} />
                    <br></br>
                    <ul className="list-group">
                        {
                            speCreationRes.map((v, k) => {
                                return v && <li className={"list-group-item "
                                    + (v.info.startsWith("Erreur") ? " bg-danger bg-gradient text-white" : " bg-success bg-gradient text-white")}>
                                    {v.ref}: {v.info}
                                </li>
                            })
                        }

                    </ul>
                    {

                        progress === 100 &&
                        <div className="text-center">
                            <br></br>
                            <button className="btn btn-success" onClick={() => window.location.reload()}>
                                Cliquez ici pour actualiser les tableaux
                            </button>
                        </div>
                    }
                </div>
                <br></br>
            </Fragment>
        }

<ReactTable columns={columns} data={p.list}
                origin="bom_content" classes={"no-padding"} top0={true} />
    </Fragment>

}

export default SpeTab;