import React, { useMemo, useState } from "react";
import bomOrderDao from "../../services/purchase/bomOrderDao";
import errorManagement from "../../services/errorManagement";
import { DefaultColumnFilter, filterMethods } from "../../functions/TableFuntions";
import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../common/smallComponents.js/ReactTable";

const OrderMachineCheck = (props) => {
    const [machine, setMachine] = useState("");
    const [business, setBusiness] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(null);
    const [data, setData] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [orderDetail, setOrderDetail] = useState(null);

    // Set machine
    const onMachineChange = (e) => {
        setMachine(e.target.value);
    }

    //Set business
    const onBusinessChange = (e) => {
        setBusiness(e.target.value);
    }

    //Search list
    const onSubmitForm = (e) => {
        e.preventDefault();
        
        setError("");
        setLoading(true);     

        bomOrderDao.getOrderMachineCheck(machine, business).then(
            (response) => {
                setData(response);
                setLoading(false);            
            },
            (error) => {
                setError( errorManagement.handleError(props, error) );
                setLoading(false);
            }
        );
    }

    //Get order detail
    const getOrderDetail = (e) => {
        setShowDetail(true);

        bomOrderDao.getOrdersDetail( e.target.getAttribute("data-ar"), e.target.getAttribute("data-order") ).then(
            (response) => {
                setOrderDetail( response );
            },
            (error) => {
                setError( errorManagement.handleError(props, error) );
                setLoading(false);
            }
        );
        
    }

    const closeOrderDetail = () => {
        setShowDetail(false);
        setOrderDetail(null);
    }
    
    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('machine', {
            header: 'Machine',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('ar', {
            header: 'Article',
            cell: i => <div className="text-center">
                <a target={"_blank"} href={"/article/info/get?article=" + i.getValue()}>{i.getValue()}</a>
                <br></br>
                <span>{i.row.original.zn02pa}</span></div>
        }));

        arr.push(columnHelper.accessor('cat', {
            header: 'Catégorie',
            filterFn: 'selectMultiple',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('blocked', {
            header: 'Bloqué',
            filterFn: 'strWithEmpty',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('lastIdx', {
            header: 'Nouveaux indices',
            cell: i => <div className="text-center">{i.getValue() !== "" && 
                i.getValue().split(",").map(
                    v => 
                    <div>
                    <a target="_blank" href={"/article/info/get?article="+v.split("#")[0]}>{v.split("#")[1]}</a>
                    <br></br>
                    </div>
                )}</div>
        }));

        arr.push(columnHelper.accessor('label', {
            header: 'Désignation',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('supplier', {
            header: 'Fournisseur principal',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('qty', {
            header: 'Qté nomenclature',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('in_store', {
            header: 'Qté en stock',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('tot_need', {
            header: 'Qté en besoin TOTAL',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('tot_biz', {
            header: 'Qté commandée sur affaire',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('order_for_stock', {
            header: 'Qté commandée sur stock livré avant',
            cell: i => <div className="text-center">
                <div>{i.getValue()}</div>
                <div>
                    {i.getValue() !== null && 
                        <button className="btn btn-info" 
                            data-ar={i.row.original.ar} 
                            data-order={i.row.original.order_number_for_stock}
                            onClick={getOrderDetail}>Détail</button>}
                </div>
            </div>
        }));

        arr.push(columnHelper.accessor('order_for_stock_delayed', {
            header: 'Qté commandée sur stock livré après',
            cell: i => <div className="text-center">
            <div>{i.getValue()}</div>
            <div>
                {i.getValue() !== null && 
                    <button className="btn btn-info" 
                        data-ar={i.row.original.ar} 
                        data-order={i.row.original.order_number_for_stock_after}
                        onClick={getOrderDetail}>Détail</button>}
            </div>
        </div>
        }));

        arr.push(columnHelper.accessor('comments', {
            header: 'Commentaires',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));
        

        return arr;
    }


    //Set columns
    const columns = getColumns();


    return (
        <div>
            <form onSubmit={onSubmitForm}>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">Machine</span>
                    </div>
                    <input type="text" className="form-control text-center font-weight-bold" 
                        placeholder="Saisissez le numéro de machine (sans le préfixe)"
                        onChange={onMachineChange}/>

                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">Affaire</span>
                    </div>
                    <input type="text" className="form-control text-center font-weight-bold"
                        onChange={onBusinessChange}/>
                    
                    <button type="submit" className="btn btn-success" disabled={loading}>
                        {loading && ( <span className="spinner-border spinner-border-sm"></span> )}
                        <span>Rechercher</span>
                    </button>
                </div>
            </form>

            {
                error && 
                    <div className="alert alert-danger text-center font-weight-bold" role="alert"> {error} </div>
                
            }

            { data.length > 0 && <ReactTable columns={columns} data={data}
                origin="order_mach_check" classes={"no-padding fixFstCol"} top0={true} />   }

            {
                showDetail && 
                    <div className="card floating">
                        <div className="card-body">
                            <h5 className="card-title text-center">Détail de commandes</h5>
                            
                            {
                                !orderDetail &&
                                    <div className="text-center">
                                        <div className="spinner-grow text-primary" role="status"></div>
                                        <div className="spinner-grow text-secondary" role="status"></div>
                                        <div className="spinner-grow text-success" role="status"></div>
                                        <br/><br/>
                                    </div>
                            }

                            {
                                orderDetail &&
                                    <div>
                                        <table className="table text-center">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th scope="col">N° commande</th>
                                                    <th scope="col">Qté</th>
                                                    <th scope="col">Date prévue de livraison</th>
                                                    <th scope="col">Date confirmée</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                
                                                    {orderDetail.map(
                                                        (v, k) => 
                                                            <tr key={k}>
                                                                <th scope="row">{v.order_}</th>
                                                                <td>{v.qty}</td>
                                                                <td>{v.delivery_wished}</td>
                                                                <td>{v.delivery_confirmed}</td>
                                                            </tr>
                                                    )}
                                                    
                                                
                                            </tbody>
                                        </table>
                                    </div>
                            }
                            
                            <div className="text-center">
                                <button className="btn btn-warning" onClick={closeOrderDetail}>Fermer</button>
                            </div>
                        </div>
                    </div>
            }
            

        </div>
    )

}

export default OrderMachineCheck;