
const Context = (props) => {
    return (
        < div className="table-responsive">
            <table className="table businessCardStyle">
                <thead>
                    <tr className="table-dark text-center rounded">
                        <th scope="col" colSpan={3}>Context</th>
                    </tr>
                </thead>
                <tbody>
                    <tr >
                        <td className="border-end">
                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{ width: "150px" }}>Type of shipping</span>
                                <input type="text" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.type_expedit}
                                    data-field="type_expedit" list="type_expedit" />
                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{ width: "150px" }}>Type of product</span>
                                <input type="text" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.produit_aff}
                                    data-field="produit_aff" list="produit_aff" />
                            </div>
                        </td>

                        <td className="border-end">
                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{ width: "200px" }}>Type of installation</span>
                                <input type="text" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.type_inst_en}
                                    data-field="type_inst_en" list="type_inst" data-translate="true" />
                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{ width: "200px" }}>Integrator</span>
                                <input type="text" className="form-control"
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.ensemb_mach}
                                    data-field="ensemb_mach" readOnly={props.readOnly} />
                            </div>
                        </td>

                        <td className="border-end">
                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{ width: "150px" }}>Previous project</span>
                                <input type="text" className="form-control"
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.antecedent_cli}
                                    data-field="antecedent_cli" readOnly={props.readOnly} />
                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{ width: "150px" }}>3PL</span>
                                <input type="text" className="form-control"
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.thirdPL}
                                    data-field="thirdPL" readOnly={props.readOnly} />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default Context;