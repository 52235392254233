import { useState } from "react";
import { Fragment } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ticketsDao from "../../services/qhse/ticketsDao";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting, getDeviceType, scrollToTop } from "../../functions/PageAnimation";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import getUrlParam, { getBase64, msToTime } from "../../functions/StrFunctions";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import { useEffect } from "react";
import CheckConnDao from "../../services/common/CheckConnDao";

const Building = (props) => {
    const [id, setId] = useState();
    const [username, setUsername] = useState();
    const [description, setdescription] = useState();
    const [floor, setFloor] = useState();
    const [criticality, setCriticality] = useState();
    const [qhseComment, setqhseComment] = useState();
    const [statut, setStatut] = useState("Ouvert");
    const [contact, setContact] = useState();
    const [date, setDate] = useState();
    const [report, setReport] = useState();
    const [usedMaterial, setUsedMaterial] = useState();
    const [interventionStart, setInterventionStart] = useState();
    const [interventionEnd, setInterventionEnd] = useState();
    const [duration, setDuration] = useState();
    const [position, setPosition] = useState();
    const [dataPhoto, setDataPhoto] = useState();
   
    const [displayDrawing, setDisplayDrawing] = useState(false);
    const drawingDivTop = 100;
    const drawingDivLeft = 0;

    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [takeAPhoto, setTakeAPhoto] = useState(false);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    const user = JSON.parse(localStorage.getItem("user"));
    const [isQhse, setIsQhse] = useState(false);
    const [isMine, setIsMine] = useState(true);

    const [chrono, setChrono] = useState();

    useEffect(() => {
        CheckConnDao.internal().then(
            (response) => { },
            (error) => { errorManagement.handleError(props, error); });
        setError();

        var id = getUrlParam(props, "id");

        if (id !== null) {

            setLoading(true);
            setId(id);

            ticketsDao.getBuildingTicket(id).then(
                (response) => {
                    setdescription(response.description);
                    setDataPhoto(response.img);
                    setCriticality(response.criticality);
                    setContact(response.contact);
                    setUsername(response.userName);
                    setDate(response.date);
                    setFloor(response.location);
                    setqhseComment(response.qhseComment);
                    setStatut(response.statut);
                    setReport(response.report);
                    setUsedMaterial(response.usedMaterial);

                    if(response.interventionDateStart  )
                        setInterventionStart( new Date(response.interventionDateStart) );

                    if( response.interventionDateEnd )    
                        setInterventionEnd( new Date(response.interventionDateEnd) );
                    
                    if( response.interventionDateStart ){
                        var start = new Date(response.interventionDateStart);
                        var end = new Date();
                        
                        if( response.interventionDateEnd ){
                            end =  new Date(response.interventionDateEnd);
                            var min = Math.round(msToTime( end - start, "MIN" ));
                            var hour = Math.round(msToTime( end - start, "HOURS" ));
                            setDuration( hour + "h " + min + "min ");
                        }else{
                            startChrono(start);
                        }                        
                    }
                        

                    setPosition(response.position.split(","));
                    
                    setLoading(false);
                    setIsMine(parseInt(user.id) === parseInt(response.author));
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    setLoading(false);
                }
            )
        }


        CheckConnDao.internal().then(
            (response) => { if (user.roles.includes("ROLE_QHSE")) setIsQhse(true); },
            (error) => { setError(errorManagement.handleError(props, error)); }
        );
    }, []);


    const onSubmitForm = (e) => {
        e.preventDefault();
        setError();

        var err = "";

        if( floor === undefined ){
            err += "Veuillez choisir l'emplacement<br>";
        }else if( position === undefined ){
            err += "Veuillez choisir l'emplacement exact sur le plan<br>";
        }

        if( err !== "" ){
            setError(err);
            scrollToTop();
            return;
        }

        var o = {
            location: floor,
            contact: contact,
            description: description,
            criticality: criticality,
            img: dataPhoto,
            statut: statut,
            qhseComment: qhseComment,
            report: report,
            usedMaterial:usedMaterial,
            interventionDateStart: interventionStart,
            interventionDateEnd: interventionEnd,
            position: position.toString()
        }

        setSaving(true);
       
        if (id !== undefined && id !== null) {
            o.id = id;
            ticketsDao.updateBuildingTicket(o).then(
                (response) => {
                    window.location.href = "/qhse/building/list"
                    setSaving(false);
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                    setSaving(false);
                }
            )
        } else {
            ticketsDao.createBuildingTicket(o).then(
                (response) => {
                    window.location.href = "/qhse/building/list"
                    setSaving(false);
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                    setSaving(false);
                }
            )
        }

    }

    /**
  * Open photo device
  * @param {*} e 
  */
    const takePhoto = (e) => {
        setTakeAPhoto(true);
    }

    /**
     * On choose file
     * @param {*} e 
     * @returns 
     */
    const onChooseFile = (e) => {
        if (e.target.files.length === 0)
            return;

        getBase64(e.target.files[0], (res) => {
            setDataPhoto(res);
        });
    }

    /**
     * Close photo device
     */
    const cancelPhoto = () => {
        setTakeAPhoto(false);
    }

    /**
     * Save the photo
     * @param {*} data 
     */
    const handlePhoto = (data) => {
        setDataPhoto(data);
        setTakeAPhoto(false);
    }

    /**
     * Delete the taken photo or uploaded img
     */
    const deletePhoto = () => {
        setDataPhoto();
    }

    /**
     * Get exact position of problem
     * @param {*} e 
     */
    const getPosition = (e) => {
        var bounds = e.target.getBoundingClientRect();
        var left = bounds.left;
        var top = bounds.top;
        var x = e.pageX - left;
        var y = e.pageY - top;
        var cw = e.target.clientWidth
        var ch = e.target.clientHeight
        var iw = e.target.naturalWidth
        var ih = e.target.naturalHeight
        var px = x / cw * iw
        var py = y / ch * ih

        setPosition([px - 25, py - 40]);

    }

    const startIntervention = (e) => {
        e.preventDefault();

        var start = new Date();
        setInterventionStart(start);
        startChrono(start);

        ticketsDao.updateBuildingField(id, "interventionDateStart", start);
    }

    const endIntervention = (e) => {
        e.preventDefault();

        var end = new Date();
        setInterventionEnd(end);
        clearInterval(chrono);

        ticketsDao.updateBuildingField(id, "interventionDateEnd", end);
    }

    function startChrono(interventionStart){
        setChrono(setInterval( function(){
            var min = Math.round(msToTime( new Date() - interventionStart, "MIN" ));
            var hour = Math.round(msToTime( new Date() - interventionStart, "HOURS" ));
            setDuration( hour + "h " + min + "min " )
        }, 1000 ));

    }

    const reStartIntervention = (e) => {
        e.preventDefault(); 
        startChrono(interventionStart);
        setInterventionEnd();
        ticketsDao.updateBuildingField(id, "interventionDateEnd", null);
    }

    const onStatutChange = (e) => {
        var field = e.target.getAttribute("data-field");
        var id = e.target.getAttribute("data-id");
        var value = e.target.value;

        ticketsDao.statutChange(id, field, value).then(
            (response) => { 
                setMsg(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }

    return <div className="table-responsive">
        <h4 className="text-center">Ticket bâtiment</h4>
        <br></br>

        {error && <ErrorMessage error={error} />}
        {msg && <SuccessMessage msg={msg} />}
        {loading && <WaitingRoundSnippers />}

        {
            !loading &&
            <Fragment>

                {   /** Plan to point where is the problem exactly */
                    (floor && displayDrawing) &&
                    <div style={{
                        position: "absolute", zIndex: "1",
                        top: drawingDivTop + "px", left: drawingDivLeft,
                        width: "710px", opacity: "1"
                    }}>
                        {position && <img src={"/img/qhse/position.png"}
                            style={{ position: "absolute", width: "50px", top: position[1] + "px", left: position[0] + "px" }} />}
                        {floor === 1 && <img id="plan" onClick={getPosition} src={"/img/qhse/b1_first.png"}></img>}
                        {floor === 2 && <img onClick={getPosition} src={"/img/qhse/b1_ground.png"}></img>}
                        {floor === 3 && <img onClick={getPosition} src={"/img/qhse/b2_first.png"}></img>}
                        {floor === 4 && <img onClick={getPosition} src={"/img/qhse/b2_ground.png"}></img>}
                        <br></br>
                        <span className="fw-bold">Cliquez sur le plan pour indiquer où se trouve l'emplacement exact</span>
                        <br></br>
                        <button className="btn btn-primary"
                            onClick={(e) => { e.preventDefault(); setDisplayDrawing(false) }}
                            style={{ position: "absolute", right: "50px" }}>Fermer</button>
                    </div>
                }

                <form onSubmit={onSubmitForm} disabled={saving} className="col-10 offset-1"
                    style={{ opacity: displayDrawing ? 0.33 : 1 }}>

                    <div className="mb-3">
                        <div className="form-floating">
                            <select className="form-select" 
                                onChange={(e) => {setFloor(parseInt(e.target.value)); setPosition(undefined)}}
                                style={{ minWidth: "230px" }} value={floor} disabled={displayDrawing} required>
                                <option value={undefined}>Choisissez...</option>
                                <option value="2">Site 1: rez-de-chaussée</option>
                                <option value="1">Site 1: 1er étage</option>
                                <option value="4">Site 2: rez-de-chaussée</option>
                                <option value="3">Site 2: 1er étage</option>
                            </select>
                            <label for="floatingSelect">Emplacement</label>
                        </div>
                    </div>

                    {
                        floor && <button className="btn btn-secondary mb-3"
                            onClick={(e) => { e.preventDefault(); setDisplayDrawing(true) }}>Choisissez l'emplacement exact</button>
                    }

                    {
                        username &&
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Auteur</span>
                            <input type="text" value={username} className="form-control" readOnly />
                            <span className="input-group-text" id="basic-addon1">Date</span>
                            <input type="date" value={date} className="form-control" readOnly />
                        </div>
                    }

                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" placeholder="xxx" readOnly={displayDrawing}
                            value={contact} onChange={(e) => setContact(e.target.value)} />
                        <label for="floatingInput">Interlocuteur sur place (si autre que l'auteur)</label>
                    </div>

                    <div className="form-floating mb-3">
                        <textarea className="form-control" placeholder="xxx" style={{ height: "100px" }} readOnly={displayDrawing}
                            value={description} onChange={(e) => setdescription(e.target.value)} required></textarea>
                        <label for="floatingTextarea">Description de la demande d'intervention</label>
                    </div>

                    <div className="form-floating mb-3">
                        <select className="form-select" disabled={displayDrawing}
                            onChange={(e) => setCriticality(e.target.value)}
                            value={criticality} style={{ minWidth: "230px" }} required>
                            <option value="">Choisissez...</option>
                            <option value="1">Normal</option>
                            <option value="2">Urgent</option>
                            <option value="3">Impératif</option>
                        </select>
                        <label for="floatingSelect">Degré d'urgence</label>
                    </div>

                    <div className="form-floating mb-3">
                        <select className="form-select" disabled={!isQhse} style={{ minWidth: "230px" }}
                            onChange={onStatutChange} value={statut}>
                            <option value="Ouvert">Ouvert</option>
                            <option value="En cours">En cours</option>
                            <option value="Clôturé">Clôturé</option>
                        </select>
                        <label for="floatingSelect">Statut</label>
                    </div>

                    {
                        isQhse &&
                        <Fragment>
                            <div className="form-floating mb-3">
                                <textarea className="form-control" placeholder="xxx" style={{ height: "100px" }}
                                    onChange={(e) => setReport(e.target.value)} value={report} readOnly={!isQhse}></textarea>
                                <label for="floatingTextarea">Rapport d'intervention</label>
                            </div>

                            <div className="form-floating mb-3">
                                <textarea className="form-control" placeholder="xxx" style={{ height: "100px" }}
                                    onChange={(e) => setUsedMaterial(e.target.value)} value={usedMaterial} readOnly={!isQhse}></textarea>
                                <label for="floatingTextarea">Matériel utilisé / changé</label>
                            </div>

                            <div className="form-floating mb-3">
                                <textarea className="form-control" placeholder="xxx" style={{ height: "100px" }}
                                    onChange={(e) => setqhseComment(e.target.value)} value={qhseComment} readOnly={!isQhse}></textarea>
                                <label for="floatingTextarea">Observation particulière</label>
                            </div>
                            
                            {
                                !interventionStart && 
                                    <button className="btn btn-primary mb-3" onClick={startIntervention}>Démarrer l'intervention</button>
                            }

                            { duration && <p className="fw-bold mb-3">Durée: {duration}</p> }

                            {  interventionStart && !interventionEnd 
                                && <button className="btn btn-danger mb-3" onClick={endIntervention}>Fin de l'intervention</button>}

                            {
                                (interventionStart && interventionEnd) && 
                                    <button className="btn btn-warning mb-3" onClick={reStartIntervention}>Reprendre l'intervention</button>
                            }

                        </Fragment>
                    }


                    {(getDeviceType() === "mobile" && isMine) &&
                        <div style={{ display: "flex", marginBottom: "10px" }}>
                            {
                                !takeAPhoto &&
                                <Fragment  >
                                    <button type="button" className="custom-file-upload photo" style={{ marginLeft: "auto", marginRight: "auto" }}
                                        onClick={takePhoto}></button>

                                    <label className="custom-file-upload folder" style={{ marginLeft: "auto", marginRight: "auto" }}>
                                        <input type="file" accept="image/*" onChange={onChooseFile} />
                                    </label>
                                </Fragment>
                            }

                            {takeAPhoto &&
                                <button type="button" className="btn btn-info" onClick={cancelPhoto}>Annuler</button>}

                            <br></br>
                            {takeAPhoto &&
                                <Camera idealFacingMode={FACING_MODES.ENVIRONMENT} onTakePhoto={(data) => { handlePhoto(data) }} />}
                        </div>
                    }

                    {(getDeviceType() !== "mobile" && isMine) &&
                        <div className="input-group mb-3">
                            <label className="input-group-text" htmlFor="file">Ajouter une image</label>
                            <input type="file" accept="image/*" className="form-control" onChange={onChooseFile} id="file" name="attachment" />
                        </div>
                    }

                    {dataPhoto &&
                        <div className="text-center" style={{ marginBottom: "20px" }}>
                            <img src={dataPhoto} style={{ maxWidth: getDeviceType() === "mobile" ? "300px" : "500px" }} />
                            <br></br>
                            {
                                isMine && <button type="button" style={{ marginTop: "10px", marginLeft: "10px" }} className="btn btn-danger" onClick={deletePhoto}>Supprimer</button>
                            }

                        </div>
                    }

                    {
                        !msg && <div className="text-center">
                            <button type="submit" className="btn btn-success" disabled={saving}>
                                {saving && <ButtonWaiting />}
                                Soumettre
                            </button>
                        </div>
                    }

                </form>
            </Fragment>
        }
        <br></br>

    </div>

}

export default Building;