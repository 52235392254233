import axios from "axios";
import EditableCell from "../../components/common/EditableCell";
import { SelectEmptyOrNot } from "../../functions/TableFuntions";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";
import { createColumnHelper } from "@tanstack/react-table";
import errorManagement from "../errorManagement";
import { Fragment } from "react";
import Moment from 'moment';
import { emptyOrNot, strictSearch } from "../../common/smallComponents.js/Filter";
import { isSentClass, setSent } from "../../components/supplyChain/functions/SubcontractingTable";
import { removeEltArray } from "../../functions/ArrayFunctions";

const API_URL = process.env.REACT_APP_API_URL + "subcontracting";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && "\"Bearer " + user.accessToken + "\"";

Moment.locale('fr');

/**
 * Get records table 
 * @param {*} machine 
 * @returns 
 */
const getSubcontractingTable = (machine) => {

    return axios
        .post(API_URL + "/get-table", {
            fake_header: authHeader(),
            machine: machine
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const getSubcontractingTableV2 = (machine, setLoading, setError, setData, setFiles, setMachine,
    setMachineInfo, setChat, props) => {

    setLoading(true);
    setError(null);
    setData([]);
    return axios
        .post(API_URL + "/get-table", {
            fake_header: authHeader(),
            machine: machine
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then(
            (response) => {
                response = response.data;
                if (response.list.length === 0) {
                    setError("Aucune machine trouvée!");
                    setLoading(false);
                    return;
                }

                setData(response.list);
                setFiles(response.files)
                setLoading(false);
                setMachine(response.list[0].machine);
                setMachineInfo(response.machine_info);
                
                if (response.chat !== null) setChat(JSON.parse(response.chat));
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
}

const getSubcontractingTableXls = (data, machineInfo) => {

    return axios
        .post(API_URL + "/get-table/xls", {
            fake_header: authHeader(),
            list: data,
            machineInfo: machineInfo
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

/**
 * Update comment written by B+ user
 * @param {*} need_number 
 * @param {*} comment 
 * @returns 
 */
const setInternalComment = (need_number, comment) => {
    return axios
        .post(API_URL + "/set-internal-comment", {
            fake_header: authHeader(),
            need_number: need_number,
            comment: comment
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const setExternalComment = (need_number, comment) => {
    return axios
        .post(API_URL + "/set-external-comment", {
            fake_header: authHeader(),
            need_number: need_number,
            comment: comment
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

/**
 * Update sent qty
 * 
 * @param {*} need_number 
 * @param {*} id 
 * @param {*} comment 
 * @returns 
 */
const setSentQty = (need_number, qty) => {
    return axios
        .post(API_URL + "/set-sent-qty", {
            fake_header: authHeader(),
            need_number: need_number,
            qty: qty === "" ? null : qty,
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

/**
 * Update sent qty
 * 
 * @param {*} need_number 
 * @param {*} id 
 * @param {*} comment 
 * @returns 
 */
const setSent2Qty = (need_number, qty) => {
    return axios
        .post(API_URL + "/set-sent2-qty", {
            fake_header: authHeader(),
            need_number: need_number,
            qty: qty === "" ? null : qty,
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

/**
 * Get subcontractors shared machines with and shared files
 * @param {} machine 
 * @returns 
 */
const getSubcontractingManagmt = (machine) => {
    return axios
        .post(API_URL + "/manage", {
            machine,
            fake_header: authHeader()
        },
            { headers: textPlainHeader() }
        )
        .then((response) => {
            return response.data;
        });
}

/**
 * Add subcontractor with who share the machine
 * @param {*} machine 
 * @param {*} newSubcontractor 
 * @returns 
 */
const setNewSubcontractor = (machine, newSubcontractor) => {
    return axios
        .post(API_URL + "/add-subcontractor", {
            machine: machine,
            subcontractor: newSubcontractor,
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

/**
 * Delete the shared subcontractor
 * @param {*} machine 
 * @param {*} subcontractor 
 * @returns 
 */
const deleteSubcontractor = (machine, subcontractor) => {
    return axios
        .post(API_URL + "/remove-subcontractor", {
            machine: machine,
            subcontractor: subcontractor,
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

/**
 * Upload files to share
 * @param {*} machine 
 * @param {*} files 
 * @returns 
 */
const shareFiles = (machine, files, all_files) => {
    let formData = new FormData();
    var fileSize = 0;

    for (let i = 0; i < files.length; i++) {
        if (!filesAlreadyUpladed(files[i], all_files)) {
            formData.append("file", files[i]);
            fileSize += files[i].size;
        }
    }

    if (fileSize === 0)
        throw new Error("Vous avez déjà importé ce(s) fichier(s)")

    //if( fileSize > 10485760 )
    //   throw new Error("Le ou les fichiers sont trop volumineux! Le maximum est de 1 048 576 bytes")


    formData.append("machine", machine);
    formData.append("fake_header", token);

    return axios({
        method: "post",
        url: API_URL + "/add-files-to-share",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    })
        .then((response) => {
            return response.data;
        });
}


/**
 * Upload files to share
 * @param {*} machine 
 * @param {*} files 
 * @returns 
 */
const subcontractorShareFiles = (machine, files, all_files) => {
    let formData = new FormData();
    var fileSize = 0;

    for (let i = 0; i < files.length; i++) formData.append("file", files[i]);

    formData.append("machine", machine);
    formData.append("fake_header", token);

    return axios({
        method: "post",
        url: API_URL + "/subcontractor-add-files-to-share",
        data: formData
    })
        .then((response) => {
            return response.data;
        });
}

function filesAlreadyUpladed(file, all_files) {
    for (let i = 0; i < all_files.length; i++) {
        if (all_files[i].file.toUpperCase() === file.name.toUpperCase().replace("XLSX", "XLS")) {
            return all_files[i].exists;
        }
    }

    return false;
}

/**
 * Remove file
 * @param {*} machine 
 * @param {*} file 
 * @returns 
 */
const removeFile = (machine, file) => {
    return axios
        .post(API_URL + "/remove-file", {
            machine: machine,
            file: file,
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

/**
 * Remove subcontractor file
 * 
 * @param {*} machine 
 * @param {*} file 
 * @returns 
 */
const removeSubcontractorFile = (machine, file) => {
    return axios
        .post(API_URL + "/remove-subcontractor-file", {
            machine: machine,
            file: file,
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

/**
 * Can't return the list of files so do a second request refresh file list
 * @param {*} machine 
 * @returns 
 */
const refreshFileList = (machine, file) => {
    return axios
        .post(API_URL + "/refresh-file-list", {
            machine: machine,
            'fake-header': authHeader()
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}


const downloadFile = (file) => {
    return axios
        .post(API_URL + "/download-file", {
            file: file,
            fake_header: authHeader()
        }, { responseType: 'blob', contentType: "multipart/form-data" })
        .then((response) => {
            return response;
        });

}

const downloadAllFile = (machine) => {

    return axios
        .post(API_URL + "/download-all-files", {
            machine: machine,
            fake_header: authHeader()
        }, { responseType: 'blob', contentType: "multipart/form-data" })
        .then((response) => {
            return response;
        });

}

const downloadAllSubcontractorFile = (machine) => {

    /*return axios
    .post(API_URL + "/download-subcontractor-all-files", { 
        machine: machine, 
        fake_header: authHeader() 
    }, { 
        responseType: 'blob', 
        contentType: "multipart/form-data" })
    .then((response) => {
        return response;
    });*/

    let formData = new FormData();
    formData.append("machine", machine);
    formData.append("fake_header", JSON.stringify(authHeader()));

    fetch(API_URL + "/download-subcontractor-all-files", {
        method: 'POST',
        param: { fake_header: authHeader() }
    })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `FileName.zip`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        });

}

const getInternalList = () => {
    return axios
        .post(API_URL + "/get-list/internal", {
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => { return response.data });
}

const getExternalList = () => {
    return axios
        .post(API_URL + "/get-list", {
            fake_header: authHeader(),
            email: user.email
        }, { headers: textPlainHeader() })
        .then((response) => { return response.data });
}

const closeMachine = (machine, status) => {
    return axios
        .post(API_URL + "/close", {
            machine: machine,
            close: status,
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => { return response.data });
}


const updateStatus = (id, status) => {
    return axios
        .post(API_URL + "/update/status", {
            id: id,
            status: status,
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => { return response.data });
}

/**
 * Get columns metadata
 * @param {*} t 
 * @returns 
 */
const getColumnsInfo = (t) => {

    return [
        {
            Header: 'ID',
            accessor: "id"
        },
        {
            Header: t('category'),
            accessor: "category"
        },
        {
            Header: t('subset'),
            accessor: "subset"
        },
        {
            Header: t('mark'),
            accessor: "mark"
        },
        {
            Header: t('qty'),
            accessor: "qty"
        },
        {
            Header: t('french label'),
            accessor: "label"
        },
        {
            Header: t('reference'),
            accessor: "reference"
        },
        {
            Header: t('article'),
            accessor: "article"
        },
        {
            Header: t('sent'),
            accessor: "sent",
            Filter: SelectEmptyOrNot,
            filter: 'empty',
            Cell: EditableCell
        },
        {
            Header: t('orders'),
            accessor: "order",
            Filter: SelectEmptyOrNot,
            filter: 'empty'
        },
        {
            Header: t('delivery date'),
            accessor: "del_date"
        },
        {
            Header: t('sent2'),
            accessor: "sent2Date",
            Cell: EditableCell,
            Filter: SelectEmptyOrNot,
            filter: 'empty'
        },
        {
            Header: t('b+ remarks'),
            accessor: "bp_internal_remarks",
            Cell: EditableCell,
            Filter: SelectEmptyOrNot,
            filter: 'empty'
        },
        {
            Header: t('subcontractor remarks'),
            accessor: "bp_external_remarks",
            Cell: EditableCell,
            Filter: SelectEmptyOrNot,
            filter: 'empty'
        }
    ]
}



/**
 * 
 * @param {*} machine 
 * @param {*} chat 
 * @returns 
 */
const saveChat = (machine, chat) => {
    return axios
        .post(API_URL + "/save-chat", {
            machine: machine,
            chat: chat,
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => { return response.data });
}

const syncBom = (machine) => {
    return axios
        .post(API_URL + "/sync-bom", {
            machine: machine,
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => { return response.data });
}

const setDateSent = (machine, date) => {
    return axios
        .post(API_URL + "/set-sent-date", {
            machine: machine,
            fake_header: authHeader(),
            date: date
        }, { headers: textPlainHeader() })
        .then((response) => { return response.data });
}

const getLabels = (arr, start, business, machine, type) => {
    return axios
        .post(API_URL + "/labels", {
            fake_header: authHeader(),
            machine: machine,
            arr: arr,
            start: start,
            business: business,
            type: type
        }, { headers: textPlainHeader() })
        .then((response) => { return response.data });
}

const addRow = (newRow) => {
    return axios
        .post(API_URL + "/add-row", {
            fake_header: authHeader(),
            ...newRow
        }, { headers: textPlainHeader() })
        .then((response) => { return response.data });
}

/************************************************************
 * *************************** V2 ***************************
 ************************************************************/

/**
 * Get columns
 */
const getColumnsV2 = (t, machineInfo, isInternal, p) => {
    var arr = new Array();
    const columnHelper = createColumnHelper();

     {/** */}
     const chooseLabel = (e, k) => {
        var arr = [...p.labelsToPrint];
        if( e.target.checked ){arr.push(k);}
        else{ arr = removeEltArray(arr, k) }
        p.setLabelsToPrint(arr);
    }

    //Categorie
    arr.push(columnHelper.accessor('category', {
        header: <div>
            <span className="fw-light fst-italic">{machineInfo && machineInfo.affaire}</span><br></br>{t('category')}
        </div>,
        cell: i => <div className="text-center">
                <input className="form-check-input me-2" type="checkbox" 
                    checked={p.labelsToPrint.includes(i.row.id)} onChange={(e)=>chooseLabel(e, i.row.id)}/>
            {i.getValue()}
        </div>,
        filter: "strict-search",
        filterFn: (rows, columnIds, filterValue) => {
            return strictSearch(filterValue, rows.original[columnIds]);
        }
    }));

    //S/E
    arr.push(columnHelper.accessor('subset', {
        header: <div>
            <span className="fw-light fst-italic">{machineInfo && machineInfo.no_machine}</span><br></br>{t('subset')}
        </div>,
        cell: i => <div className="text-center">{i.getValue()}</div>
    }));

    // Rep
    arr.push(columnHelper.accessor('mark', {
        header: <div>
            <span className="fw-light fst-italic">{machineInfo && machineInfo.typ_config}</span><br></br>{t('mark')}
        </div>,
        cell: i => <div className="text-center">{i.getValue()}</div>
    }));

    // Qté demandée
    arr.push(columnHelper.accessor('qty', {
        header: t('qty'),
        cell: i => <div className="text-center">{i.getValue()}</div>
    }));

    // Désigantion fr
    arr.push(columnHelper.accessor('label', {
        header: t('french label'),
        cell: i => <div className="text-center">{i.getValue()}</div>
    }));

    // Référence
    arr.push(columnHelper.accessor('reference', {
        header: t('reference'),
        cell: i => <div className="text-center" style={{minWidth: "230px"}}>{i.getValue()}</div>
    }));

    // Code article
    arr.push(columnHelper.accessor('article', {
        header: t('article'),
        cell: i => <div className={isSentClass(i.row)} style={{ height: "100%" }}>{i.getValue()}</div>
    }));

    // Qté reçue ou mvt
    if (isInternal) {
        arr.push(columnHelper.accessor('sent', {
            header: t('sent'),
            cell: i => <div className="text-center">
                <input type="number" className="form-control text-center" defaultValue={i.getValue()}
                    data-key={i.cell.row.id} data-id={i.row.original.id} id={"sent_" + i.row.original.id}
                    data-need={i.row.original.need_nb}
                    onBlur={p.updateData} style={{ maxWidth: "80px", borderColor: !i.row.original.need_nb ? "orange" : "" }}></input>
            </div>,
            filter: "customEmptyOrNot",
            filterFn: (rows, columnIds, filterValue) => {
                return emptyOrNot(filterValue, rows.original[columnIds]);
            }
        }));
    } else {
        arr.push(columnHelper.accessor('sent', {
            header: t('sent'),
            cell: i => <div className="text-center">{i.getValue()}</div>,
            filter: "customEmptyOrNot",
            filterFn: (rows, columnIds, filterValue) => {
                return emptyOrNot(filterValue, rows.original[columnIds]);
            }
        }));
    }

    // Commandes
    arr.push(columnHelper.accessor('order', { header: t('orders'),
    cell: i => <div className="text-center">{i.getValue()}</div> }));

    // Date d'expédition
    arr.push(columnHelper.accessor('sent2Date', { header: t('delivery date'),
    cell: i => <div className="text-center">{i.getValue() && Moment(i.getValue()).format("DD/MM/yyyy")}</div> }));

    // Qté envoyée
    if (isInternal) {
        arr.push(columnHelper.accessor('sent2', {
            header: t('sent2'),
            cell: i => <div className={"text-center " 
                                + ( i.getValue() && (parseFloat( i.getValue() ) !== parseFloat( i.row.original.qty )) ? "red-bg" : ""  ) } 
                            style={{ maxWidth: "80px" }}>
                { i.row.original.sent2Date && <Fragment>
                        {i.getValue()}<br></br>
                        <label style={{fontSize: "10px"}} className="txt-danger cursor-pointer"
                            onClick={()=>p.cancelQtySent(i.row.id)}>Annuler</label>
                    </Fragment> 
                }
                
                { !i.row.original.sent2Date &&
                    <input type="number" className="form-control text-center" defaultValue={i.getValue()}
                        data-key={i.cell.row.id} data-id={i.row.original.id} id={"sent2_" + i.row.original.id}
                        onBlur={p.updateData}
                        onTouchStart={p.checkDblClick} onDoubleClick={(e) => p.updateData(e, true)}></input>
                }
                
            </div>,
            filter: "customEmptyOrNot",
            filterFn: (rows, columnIds, filterValue) => {
                return emptyOrNot(filterValue, rows.original[columnIds]);
            }
        }));
    } else {
        arr.push(columnHelper.accessor('sent2', {
            header: t('sent2'),
            cell: i => <div className="text-center">{i.getValue()}</div>,
            filter: "customEmptyOrNot",
            filterFn: (rows, columnIds, filterValue) => {
                return emptyOrNot(filterValue, rows.original[columnIds]);
            }
        }));
    }

    if (isInternal) {
        arr.push(columnHelper.accessor('bp_internal_remarks', {
            header: t('b+ remarks'),
            cell: i => <div className="input-group">
                <textarea className="form-control" defaultValue={i.getValue()}
                    data-key={i.cell.row.id} data-id={i.row.original.id} id={"bp_internal_remarks_" + i.row.original.id}
                    onBlur={p.updateData}></textarea>
            </div>,
            filter: "customEmptyOrNot",
            filterFn: (rows, columnIds, filterValue) => {
                return emptyOrNot(filterValue, rows.original[columnIds]);
            }
        }));
    } else {
        arr.push(columnHelper.accessor('bp_internal_remarks', {
            header: t('b+ remarks'),
            cell: i => <div className="text-center">{i.getValue()}</div>,
            filter: "customEmptyOrNot",
            filterFn: (rows, columnIds, filterValue) => {
                return emptyOrNot(filterValue, rows.original[columnIds]);
            }
        }));
    }

    if (isInternal) {
        arr.push(columnHelper.accessor('bp_external_remarks', {
            header: t('subcontractor remarks'),
            cell: i => <div className="text-center">{i.getValue()}</div>,
            filter: "customEmptyOrNot",
            filterFn: (rows, columnIds, filterValue) => { return emptyOrNot(filterValue, rows.original[columnIds]); }
        }));
    } else {
        arr.push(columnHelper.accessor('bp_external_remarks', {
            header: t('subcontractor remarks'),
            cell: i => <div className="input-group">
                <textarea className="form-control" defaultValue={i.getValue()}
                    data-key={i.cell.row.id} data-id={i.row.original.id} id={"bp_external_remarks" + i.row.original.id}
                    onBlur={p.setExternalComment}></textarea>
            </div>,
            filter: "customEmptyOrNot",
            filterFn: (rows, columnIds, filterValue) => { return emptyOrNot(filterValue, rows.original[columnIds]); }
        }));
    }

    return arr;
}

/**
 * Update any field
 */
const updateField = (field, value, id) => {
    return axios
        .post(API_URL + "/v2/update-field", {
            fake_header: authHeader(),
            field: field,
            value: value,
            id: id
        }, { headers: textPlainHeader() })
        .then((response) => { return response.data });
}

const getMovementNb = (machine, list, machineInfo) => {
    return axios
        .post(API_URL + "/v2/get-movement-nb", {
            fake_header: authHeader(),
            machine: machine,
            list: list,
            mach_info: machineInfo
        }, { headers: textPlainHeader() })
        .then((response) => { return response.data });
}

export default {
    getSubcontractingTable,
    getColumnsInfo,
    setInternalComment,
    getSubcontractingManagmt,
    getSubcontractingTableXls,
    setNewSubcontractor,
    deleteSubcontractor,
    shareFiles,
    removeFile,
    downloadFile,
    setSentQty,
    setExternalComment,
    downloadAllFile,
    refreshFileList,
    subcontractorShareFiles,
    removeSubcontractorFile,
    downloadAllSubcontractorFile,
    getInternalList,
    closeMachine,
    getExternalList,
    saveChat,
    syncBom,
    setSent2Qty,
    setDateSent,
    getLabels,
    updateStatus,
    getColumnsV2,
    getSubcontractingTableV2,
    updateField,
    getMovementNb,
    addRow
}