import { compareItems } from "@tanstack/match-sorter-utils"
import { sortingFns } from "@tanstack/react-table"
import { useState } from "react"
import { useEffect } from "react"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { forceString } from "../../functions/StrFunctions"
import MultiSelectCheckbox from "./DesignedIpt"

export default function Filter({ column, table, }) {
    
    const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)

    const columnFilterValue = column.getFilterValue();

    const customFilter = column.columnDef.filter;
    const customFilterFn = column.columnDef.filterFn;
  
    const sortedUniqueValues = useMemo(
        () =>
            typeof firstValue === 'number'
                ? []
                : Array.from(column.getFacetedUniqueValues().keys()).sort(),
        [column.getFacetedUniqueValues()]
    )

    return ( customFilter && customFilter === "customEmptyOrNot" ) ? <div>
        <div>
            <DebouncedSelectEmptyOrNot
                onChange={value => column.setFilterValue(value)}
                id={"filter-txt-"+column.id} className="form-select"
                style={{ maxWidth: "150px" }}  title={"Number of recordings: " + column.getFacetedUniqueValues().size}
            />
        </div>
    </div> 
    : ( customFilterFn && customFilterFn === "selectMultiple" ) ? <div>
        <div>
            <SelectMultiple
                onChange={value => column.setFilterValue(value)} 
                sortedUniqueValues={sortedUniqueValues}
                id={"filter-txt-"+column.id} className="form-select"
                style={{ maxWidth: "150px" }}  title={"Number of recordings: " + column.getFacetedUniqueValues().size}
            />
        </div>
    </div> 
    :( customFilter && customFilter === "strict-search" ) ? <div>
        <div>
            <DebouncedStrictSearch
                onChange={value => column.setFilterValue(value)}
                id={"filter-txt-"+column.id} className="form-select" sortedUniqueValues={sortedUniqueValues}
                style={{ maxWidth: "150px" }}  title={"Number of recordings: " + column.getFacetedUniqueValues().size}
            />
        </div>
    </div>
    : typeof firstValue === 'number' ? (
        <div className="input-group " 
            style={{maxWidth: "150px", marginLeft: "auto", marginRight: "auto"}}>
                <DebouncedInput
                    type="text" column={column}
                    value={getValueIpt(columnFilterValue)} 
                    onChange={value => filterNumber(column, value) } list={"number_option_list"}
                    placeholder="ex:1, 1;3..." className="form-control filter" id={"filter-max-"+column.id}
                    title={"Number of recordings: " + column.getFacetedUniqueValues().size}
                />
            
        </div>
    ) : (
        <>
            <datalist id={column.id + 'list'}>
                {sortedUniqueValues.slice(0, 5000).map((value) => ( <option value={value} key={value} /> ))}
                { (column.columnDef.filterFn && column.columnDef.filterFn === "strWithEmpty") && 
                    <option value="(Vide)" key="(Vide)" /> }
            </datalist>
            <div>
            <DebouncedInput
                type="text" value={(columnFilterValue ?? '')}
                onChange={value => column.setFilterValue(value)} title={"Number of recordings: " + column.getFacetedUniqueValues().size}
                placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
                column={column}
                className="form-control filter" list={column.id + 'list'} id={"filter-txt-"+column.id}
            />
            </div>
        </>
    )
}

export const getExpander = (columnHelper) => {
    return columnHelper.accessor('expander', {
        header: null,
        enableColumnFilter: false,
        cell: ({ row }) => {
            return row.getCanExpand() ? (
                <div className={"expandBtn " + (row.getIsExpanded() ? "open" : "close")}
                    {...{
                        onClick: row.getToggleExpandedHandler(),
                        style: { cursor: 'pointer' },
                    }}
                >
                    <img src="/common/right_arrow_icon.png" className="see-img w-30" />
                </div>
            ) : (
                '🔵'
            )
        }
    })
}

function filterNumber(column, value){
    var min, max;

    if( value === "> 0" ){
        min = 1;
        max= 99999;
    }else if( value === ">= 0" ){
        min = 0;
        max= 99999;
    }else if( value === "< 0" ){
        min = -1;
        max= -99999;
    }else if( value === "<= 0" ){
        min = 0;
        max= -99999;
    }else if( value === "= 0" ){
        min = 0;
        max= 0;
    }else if( value.indexOf(";") === -1 ){
        if( !isNaN( parseFloat(value.trim()) ) ) min = parseFloat(value.trim());
        max=min;
    }else{
        var split = value.split(";");
        if( !isNaN( parseFloat(split[0].trim()) ) ) min = parseFloat(split[0].trim());
        if( !isNaN( parseFloat(split[1].trim()) ) ) max = parseFloat(split[1].trim());
    }   

    return column.setFilterValue([min, max])
}

function getValueIpt(columnFilterValue){
    if(!columnFilterValue) return "";

    var ipt = "";
    if( columnFilterValue[0] ) ipt = columnFilterValue[0];
    if( columnFilterValue[1] ) ipt += ";" + columnFilterValue[1];
    return ipt
}

// A debounced input react component
function DebouncedInput({ value: initialValue, onChange, debounce = 500, ...props }) {
    const [value, setValue] = useState(initialValue)

    useEffect(() => { setValue(initialValue) }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => { onChange(value) }, debounce)
        return () => clearTimeout(timeout)
    }, [value])

    return (<div className="d-flex">
            <input {...props} value={value}
            onChange={e => setValue(e.target.value)} style={{marginLeft: "auto", marginRight: "auto"}} />
            { value !== "" && 
            <button type="button" className="btn-close" onClick={()=>removeFilter(props.column)}
                style={{ zIndex: "3000", position: "absolute" }}></button>}
            </div>)
}



function DebouncedSelectEmptyOrNot({ value: initialValue, onChange, debounce = 500, ...props }) {
    const [value, setValue] = useState(initialValue)
    const {t} = useTranslation();

    useEffect(() => { setValue(initialValue) }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => { onChange(value) }, debounce)
        return () => clearTimeout(timeout)
    }, [value])

    return (
        <select {...props} value={value} onChange={e => setValue(e.target.value)}>
            <option value="all">{t("all")}</option>
            <option value="empty">{t("empty")}</option>
            <option value="not empty">{t("not empty")}</option>
        </select>
    )
}

function SelectMultiple({ value: initialValue, onChange, debounce = 500, ...props }) {
    const [value, setValue] = useState(initialValue)
    var options = props.sortedUniqueValues && props.sortedUniqueValues.slice(0, 5000);
    options.push("(Vide)");
    const [selectedOptions, setSelectedOptions] = useState(options);

    useEffect(() => { setValue(initialValue) }, [initialValue])

    useEffect(() => { setValue(selectedOptions.join(",")); onChange(selectedOptions.join(",")) }, [selectedOptions])

    return (
        <MultiSelectCheckbox
            options={options}
            selectedOptions={selectedOptions}
            onChange={setSelectedOptions}
      />
    )
}

const removeFilter = (column) => {
    column.setFilterValue("")
}

function DebouncedStrictSearch({ value: initialValue, onChange, debounce = 500, ...props }) {
    const [value, setValue] = useState(initialValue)
    const {t} = useTranslation();

    useEffect(() => { setValue(initialValue) }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => { onChange(value) }, debounce)
        return () => clearTimeout(timeout)
    }, [value])

    return (
        <select {...props} value={value} onChange={e => setValue(e.target.value)}>
            <option value="all">{t("all")}</option>
            {props.sortedUniqueValues.slice(0, 5000).map((value) => 
                ( <option value={value} key={value} >{value}</option> ))}
            <option value="empty">{t("empty")}</option>
            <option value="not empty">{t("not empty")}</option>
        </select>
    )
}

export const fuzzySort = (rowA, rowB, columnId) => {
    let dir = 0
    
    // Only sort by rank if the column has ranking information
    if (rowA.columnFiltersMeta[columnId]) {
        dir = compareItems(
            rowA.columnFiltersMeta[columnId]?.itemRank.toLowerCase(),
            rowB.columnFiltersMeta[columnId]?.itemRank.toLowerCase()
        )
    }
    
    // Provide an alphanumeric fallback for when the item ranks are equal
    return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir
}

export const emptyOrNot = (type, value) => {
    if( type === "empty" ){
        return forceString( value ).trim() === "";
    }else if( type === "not empty" ){
        return forceString( value ).trim() !== "";
    }

    return true;
}

export const strictSearch = (type, value) => {

    if( type === "empty" ){
        return forceString( value ).trim() === "";
    }else if( type === "not empty" ){
        return forceString( value ).trim() !== "";
    }else if( type === "all" ){
        return true;
    }else{
        return forceString( value ) === forceString( type );
    }

}
