import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import errorManagement from "../../services/errorManagement";
import rolesDao from "../../services/settings/rolesDao";
import usersDao from "../../services/settings/usersDao";

const UsersManagement = ( props ) => {
    const [loading, setLoading] = useState(false);
    const[roles, setRoles] = useState(null);
    const [error, setError] = useState(null);
    const[search, setSearch] = useState(null);
    const[list, setList] = useState(null);

    moment.locale('fr');

    useEffect(
        () => {
            rolesDao.getRoles().then(
                (response) => {
                    setRoles(response.roles);
                },
                (error) => {
                    setError( errorManagement.handleError(props, error) )
                }
            );
        }
    ,[]);

    const searchByUser = (e) => {
        e.preventDefault();
        setLoading(true);

        usersDao.getUserByName(search).then(
            (response) => {
                setList(response);
                setLoading(false);
            },
            (error) => {
                setError( errorManagement.handleError(props, error) )
                setLoading(false);
            }
        );
    }

    const searchByRole = () => {
        setLoading(true);

        usersDao.getUserByRole(search).then(
            (response) => {
                setList(response);
                setLoading(false);
            },
            (error) => {
                setError( errorManagement.handleError(props, error) )
                setLoading(false);
            }
        );
    }

    const onSearchChange = (e)  => {
        setSearch(e.target.value);
    }

    return(
        <div>
            <h1 className="text-center">Gestion des utilisateurs</h1>
            
            <br></br>

            {
                error && 
                    <div className="alert alert-danger text-center font-weight-bold" role="alert"> {error} </div>
            }

            <form onSubmit={searchByUser}>
                <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">Recherche par nom</span>
                        </div>
                        <input type="text" className="form-control" placeholder="Nom ou prénom" onChange={onSearchChange}/>
                        <button type="submit" className="btn btn-success" disabled={loading}>
                            {loading && ( <span className="spinner-border spinner-border-sm"></span> )}
                            <span>Rechercher</span>
                        </button>
                </div>
            </form>

            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <label className="input-group-text">Rechercher par role</label>
                </div>
                <select className="custom-select" onChange={onSearchChange}>
                    <option defaultValue="">Choose...</option>
                    {
                        roles && roles.map( (v, k) => <option value={v.id} key={k}>{v.name}</option> )
                    }
                </select>
                <button type="button" className="btn btn-success" disabled={loading} onClick={searchByRole}>
                    {loading && ( <span className="spinner-border spinner-border-sm"></span> )}
                    <span>Rechercher</span>
                </button>
            </div>

            <br></br>
            
            {
                list && 
                <table className="table">
                    <thead className="thead-dark">
                        <tr className="text-center">
                            <th scope="col">#</th>
                            <th scope="col">Username</th>
                            <th scope="col">Email</th>
                            <th scope="col">Statut</th>
                            <th scope="col">Manager</th>
                            <th scope="col">Company</th>
                            <th scope="col">Creation date</th>                    
                            <th scope="col">Last use</th>                
                            <th scope="col"></th>            
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                    {list.map(
                                (v, k) => 
                        <tr key={k}>
                                <th scope="row">{v.id}</th>
                                <td>{ v.username }</td>
                                <td>{ v.email }</td>
                                <td>{ v.statut }</td>
                                <td>{ v.manager_mail }</td>
                                <td>{ v.company }</td>
                                <td>{ moment( v.creation_date ).format('DD/MM/yyyy') }</td>
                                <td>{ moment( v.last_use ).format('DD/MM/yyyy') }</td>
                                <td>
                                    <Link className="btn btn-secondary" to={"/settings/user-setting?id=" + v.id} target="_blank" rel="noopener noreferrer" >
                                        Droits
                                    </Link>
                                </td>
                                <td>
                                    <Link className="btn btn-secondary" to={"/settings/user/edit?id=" + v.id} target="_blank" rel="noopener noreferrer" >
                                        Editer
                                    </Link>
                                </td>
                        </tr>
                        )}
                    </tbody>
                </table>
            }
            

        </div>
    );

};

export default UsersManagement;