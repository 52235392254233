import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Purchase = (props) => {
    const { t } = useTranslation();

    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Achats
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                    <a href="/purchase/order/machine/check" className="dropdown-item">
                        Suivi achats / machines
                    </a>
                </li>

                <li>
                    <a href={"/customer-client/picking/list"} className="dropdown-item">Affaires SAV</a>
                </li>

                <li>
                    <a href={"/purchase/orders/tracking"} className="dropdown-item">Relances commandes</a>
                </li>


            </ul>
        </li>
    )

}

export default Purchase;