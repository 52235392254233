import { Fragment } from "react";
import ErrorMessage from "../../../common/smallComponents.js/ErrorMessage";
import { arrayRemoveElt, getKey } from "../../../functions/ArrayFunctions";
import { currentEnDate } from "../../../functions/StrFunctions";
import { useState } from "react";
import ArticleInfo from "../../../services/articles/ArticleInfo";
import errorManagement from "../../../services/errorManagement";
import { scrollToTop } from "../../../functions/PageAnimation";

const Supplier = (p) => {

    const [supplierSearch, setsupplierSearch] = useState(false);
    /**
    * Update main supplier
    * @param {*} e 
    */
    const updateMainSupplier = (e) => {
        var key = parseInt(e.target.getAttribute("data-key"));
        var combination;
        //Is already main sup
        if (p.supplier[key].CDFPU === "O") return;

        removeMainSupplierChange(function () {

            //Remove the previous main sup among new suppliers
            var arr = [...p.newSupplier];
            for (let i = 0; i < arr.length; i++) arr[i].CDFPU = "N";
            p.setNewsupplier(arr);

            var found = false;
            //Remove the previous main sup
            for (let i = 0; i < p.supplier.length; i++) {
                if (p.supplier[i].CDFPU === "O") {
                    combination = p.supplier[i].CDFOU + " + " + p.supplier[i].CDEVU + " + " + p.supplier[i].CDUNU;
                    updateSupplier("CDFPU: " + combination, "N", "O", "Principal: " + combination, i, function () {
                        combination = p.supplier[key].CDFOU + " + " + p.supplier[key].CDEVU + " + " + p.supplier[key].CDUNU;
                        updateSupplier("CDFPU: " + combination, "O", "N", "Principal: " + combination, key, function () { });
                    });
                    found = true;
                    break;
                }

                if (!found) {
                    combination = p.supplier[key].CDFOU + " + " + p.supplier[key].CDEVU + " + " + p.supplier[key].CDUNU;
                    updateSupplier("CDFPU: " + combination, "O", "N", "Principal: " + combination, key, function () { });
                }

            }
        });
    }

    /**
     * Remove main supplier p.change to avoid duplicate
     */
    const removeMainSupplierChange = (_callback) => {
        var arr = [...p.change];
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].field.startsWith("CDFPU")) arr = arrayRemoveElt(arr, i);
        }
        p.setChange(arr);

        _callback();
    }

    /**
    * Update main supplier among added supplier
    * @param {*} e 
    */
    const updateNewMainSupplier = (e) => {
        var key = parseInt(e.target.getAttribute("data-key"));
        var combination;
        //Is already main sup
        if (p.newSupplier[key].CDFPU === "O") return;

        removeMainSupplierChange(function () {
            //Remove the previous main sup in existing supplier
            for (let i = 0; i < p.supplier.length; i++) {
                if (p.supplier[i].CDFPU === "O") {
                    combination = p.supplier[i].CDFOU + " + " + p.supplier[i].CDEVU + " + " + p.supplier[i].CDUNU;
                    updateSupplier("CDFPU: " + combination, "N", "O", "Principal: " + combination, i, function () { });
                    break;
                }
            }
        });

        //Remove the previous main sup among new suppliers
        var arr = [...p.newSupplier];
        for (let i = 0; i < arr.length; i++) arr[i].CDFPU = "N";
        arr[key].CDFPU = "O";
        p.setNewsupplier(arr);
    }



    /**
     * Update basic info supplier
     * @param {*} e 
     */
    const updateSupplierInfo = (e) => {
        var key = parseInt(e.target.getAttribute("data-key"));
        var combination = p.supplier[key].CDFOU + " + " + p.supplier[key].CDEVU + " + " + p.supplier[key].CDUNU;
        var field = e.target.getAttribute("data-field");
        var label = e.target.getAttribute("data-label");
        var value = e.target.value;
        var old_value = p.supplier[key][field];

        updateSupplier(field + ": " + combination, value, old_value, label + ": " + combination, key, function () {
            //If p.change price for main supplier, update price cost
            setTimeout(() => {
                if (field === "PXUNU" && p.supplier[key]["CDFPU"] === "O")
                    p.ctrlAndUpdateArticle("farticl", "PXREL", value* parseFloat(p.supplier[key]["CFCOU"]), "Prix de revient");
            }, 2000);
        });


    }

    /**
     * Add new supplier row
     */
    const addNewSupplier = () => {
        var o = new Object();

        o = {
            CDFOU: null,
            RSC1S: null,
            CDFPU: "N",
            REFAU: "",
            PXUNU: null,
            CDEVU: null,
            CDUNU: null,
            PCTRU: "0",
            CFCOU: "1",
            DTDPU: currentEnDate(),
            waiting: true
        }

        var arr = [...p.newSupplier];
        arr.push(o);
        p.setNewsupplier(arr);
    }

    /**
     * Update new supplier info
     * @param {*} e 
     */
    const updateNewSupplier = (e) => {
        var field = e.target.getAttribute("data-field");
        var key = e.target.getAttribute("data-key");
        var value = e.target.value;

        setNewSupplierInfo(key, field, value);

        //Set conversion on p.unit p.change
        if (field === "CDUNU") {
            var unit = e.target.options[e.target.selectedIndex].innerHTML;

            if (unit.startsWith("UC:")) {
                var u = 1 / parseInt(unit.substring(unit.indexOf(":") + 1, unit.length));
                setNewSupplierInfo(key, "CFCOU", u.toFixed(3));
            } else {
                setNewSupplierInfo(key, "CFCOU", 1);
            }
        }
    }

    /**
     * Update state new supplier
     * @param {*} key 
     * @param {*} field 
     * @param {*} value 
     */
    const setNewSupplierInfo = (key, field, value) => {
        var arr = [...p.newSupplier];
        if (value !== null && (value.toString() === "Choose..." || value.toString().trim() === "")) value = null;

        arr[key][field] = value;
        p.setNewsupplier(arr);
    }

    /**
     * Check if supplier exists and set name
     * @param {*} e 
     */
    const checkSupplier = (e) => {
        var supplier = e.target.value;
        var key = e.target.getAttribute("data-key");
        setsupplierSearch(true);

        p.setError();
        ArticleInfo.getSupplierInfo(supplier).then(
            (response) => {
                setNewSupplierInfo(key, "CDFOU", response.CTIES);
                setNewSupplierInfo(key, "RSC1S", response.RSC1S);
                setNewSupplierInfo(key, "waiting", false);
                setsupplierSearch(false);
            },
            (error) => {
                p.setError(errorManagement.handleError(p.props, error));
                scrollToTop();
                setNewSupplierInfo(key, "waiting", true);
                setsupplierSearch(false);
            }
        );
    }

    /**
     * Set p.change for supplier
     * @param {*} field 
     * @param {*} value 
     * @param {*} old_value 
     * @param {*} label 
     * @param {*} sup_key 
     * @param {*} _callback 
     */
    const updateSupplier = (field, value, old_value, label, sup_key, _callback) => {
        var table = "FARTFOU";

        var arr = [...p.change];
        var combination = label.substring(label.indexOf(":"), label.length);

        var key = getKey(arr, "field", field);
        if (key === null) {
            var o = {
                table: table,
                field: field,
                old_value: old_value,
                new_value: value,
                label: label,
                cause: ""
            }

            arr.push(o);
        } else {
            arr[key].new_value = value;
            arr[key].label = label;
        }

        //Update date also
        var key = getKey(arr, "field", "DTDPU" + combination);
        if (key === null) {
            var o = {
                table: table,
                field: "DTDPU" + combination,
                old_value: "",
                new_value: currentEnDate(),
                label: "Date de mise à jour" + combination,
                cause: ""
            }

            arr.push(o);
        }

        p.setChange(arr);

        if (field.indexOf(":") !== -1) field = field.substring(0, field.indexOf(":"));
        var arr2 = [...p.supplier];
        arr2[sup_key][field] = value;
        arr2[sup_key]["DTDPU"] = currentEnDate();
        p.setsupplier(arr2);

        if (_callback() !== null) _callback();
    }

    /**
     * Delete new supplier
     * @param {*} e 
     */
    const deleteNewSupplier = (e) => {
        var key = e.target.getAttribute("data-key");
        var arr = [...p.newSupplier];
        arr = arrayRemoveElt(arr, key);
        p.setNewsupplier(arr);
    }

    /**
     * Delete existing supplier
     * @param {*} e 
     */
    const deleteExistingSupplier = (e) => {
        var key = parseInt(e.target.getAttribute("data-key"));

        var arr = [...p.supplierToRemove];
        var o = {
            supplier: p.supplier[key].CDFOU,
            currency: p.supplier[key].CDEVU,
            unit: p.supplier[key].CDUNU
        };

        arr.push(o);

        p.setSupplierToRemove(arr);

        var sp = [...p.supplier];
        sp = arrayRemoveElt(sp, key);
        p.setsupplier(sp);
    }

    return (
        <Fragment>
            {p.articleInfo.DEPAL === 0.01 && <ErrorMessage error="Attention! dernier prix d'achat à 0.01€ !" />}

            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Fournisseur*</th>
                        <th scope="col">Raison sociale*</th>
                        <th scope="col">Principal*</th>
                        <th scope="col">Ref. article</th>
                        <th scope="col">Prix par unité de commande*</th>
                        <th scope="col">Devise*</th>
                        <th scope="col">Unité*</th>
                        <th scope="col">Remise*</th>
                        <th scope="col">Conversion*</th>
                        <th scope="col">Mise à jour*</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        p.supplier.map(
                            (v, k) =>
                                <tr key={"supplier" + k}>
                                    <td>
                                        <input type="text" className="form-control" value={v.CDFOU} readOnly />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value={v.RSC1S} readOnly />
                                    </td>
                                    <td>
                                        <input type="radio" className="btn-check" checked={v.CDFPU === "O"}
                                            name="main_supplier" id={"prc" + k} autocomplete="off" />
                                        <label className="btn btn-outline-secondary" readOnly={!p.purchase}
                                            data-key={k} onClick={updateMainSupplier}
                                            style={{ minWidth: "50px" }} htmlFor="success-outlined">&nbsp;</label>
                                    </td>

                                    <td>
                                        <input type="text" className="form-control" onChange={updateSupplierInfo}
                                            data-table="fartfou" data-field="REFAU" value={v.REFAU}
                                            data-label="Ref. article" data-key={k}
                                            id="REFAU" readOnly={!p.purchase} />
                                    </td>
                                    <td>
                                    <div className="input-group">
                                        <input type="number" step="any" className="form-control" onChange={updateSupplierInfo}
                                            data-table="fartfou" data-field="PXUNU" value={v.PXUNU}
                                            data-label="Prix unitaire" data-key={k}
                                            id="PXUNU" readOnly={!p.purchase} style={{width: "100px"}}/>
                                        <input className="form-control"
                                            value={ (v.PXUNU && v.CFCOU) && ( (v.PXUNU * v.CFCOU).toFixed(2) + " €/u") } disabled/>
                                        </div>
                                    </td>
                                    <td>
                                        <select className="form-select" disabled>
                                            <option selected>Choose...</option>
                                            {
                                                p.currency.map(
                                                    (v2, k2) =>
                                                        <option key={"currency_" + k2} selected={v.CDEVU === v2.CDEV1}
                                                            value={v2.LDEV1}>{v2.CDEV1}</option>
                                                )
                                            }
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-select" disabled>
                                            <option selected>Choose...</option>
                                            {
                                                p.unit.map(
                                                    (v2, k2) =>
                                                        <option key={"unit_" + k2} selected={v.CDUNU === v2.CODCS}
                                                            value={v2.CODCS}>{v2.LI1CS}</option>
                                                )
                                            }
                                        </select>
                                    </td>
                                    <td>
                                        <input type="number" className="form-control" onChange={updateSupplierInfo}
                                            data-table="fartfou" data-field="PCTRU" value={v.PCTRU}
                                            data-label="Remise" data-key={k}
                                            id="PCTRU" readOnly={!p.purchase} style={{width: "50px"}} />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value={v.CFCOU} readOnly />
                                    </td>
                                    <td>
                                        <input type="date" className="form-control" onChange={updateSupplierInfo}
                                            data-table="fartfou" data-field="DTDPU" value={v.DTDPU}
                                            id="DTDPU" readOnly={!p.purchase}
                                            data-label="Mise à jour" data-key={k} />
                                    </td>
                                    {
                                        p.purchase &&
                                        <td>
                                            <img src={"/common/remove_icon.png"} data-key={k} onClick={deleteExistingSupplier}
                                                style={{ maxWidth: "30px", margin: "auto" }} />
                                        </td>
                                    }

                                </tr>
                        )
                    }

                    {
                        p.newSupplier.map(
                            (v, k) =>
                                <tr key={"p.newSupplier" + k}>
                                    <td>
                                        <input type="text" className="form-control" data-field="CDFOU"
                                            data-key={k} value={v.CDFOU} onBlur={checkSupplier} onChange={updateNewSupplier} readOnly={!v.waiting} />
                                    </td>

                                    {
                                        supplierSearch &&
                                        <td>
                                            <div className="spinner-border" role="status"></div>
                                        </td>
                                    }

                                    <td style={{ display: (v.waiting ? "none" : "") }}>
                                        <input type="text" className="form-control" data-field="RSC1S" value={v.RSC1S} readOnly />
                                    </td>

                                    <td style={{ display: (v.waiting ? "none" : "") }}>
                                        <input type="radio" className="btn-check" checked={v.CDFPU === "O"}
                                            name="main_supplier" id={"newMain" + k} autocomplete="off" />
                                        <label className="btn btn-outline-secondary" readOnly={!p.purchase}
                                            data-key={k} onClick={updateNewMainSupplier}
                                            style={{ minWidth: "50px" }} htmlFor="success-outlined">&nbsp;</label>
                                    </td>

                                    <td style={{ display: (v.waiting ? "none" : "") }}>
                                        <input type="text" className="form-control" onChange={updateNewSupplier}
                                            data-table="fartfou" data-field="REFAU" value={v.REFAU}
                                            data-label="Ref. article" data-key={k}
                                            id="REFAU" readOnly={!p.purchase} />
                                    </td>

                                    <td style={{ display: (v.waiting ? "none" : "") }}>
                                    <div className="input-group">
                                        <input type="number" step="any" className="form-control" onChange={updateNewSupplier}
                                            data-table="fartfou" data-field="PXUNU" value={v.PXUNU}
                                            data-label="Prix unitaire" data-key={k}
                                            id="PXUNU" readOnly={!p.purchase} style={{width: "100px"}} />
                                            <input className="form-control" 
                                                value={ (v.PXUNU && v.CFCOU) && ( (v.PXUNU * v.CFCOU).toFixed(2) + " €/u") } disabled/>
                                                </div>
                                    </td>

                                    <td style={{ display: (v.waiting ? "none" : "") }}>
                                        <select className="form-select" data-field="CDEVU" data-key={k} onChange={updateNewSupplier}>
                                            <option selected>Choose...</option>
                                            {
                                                p.currency.map(
                                                    (v2, k2) =>
                                                        <option key={"currency_" + k2} selected={v.CDEVU === v2.CDEV1}
                                                            value={v2.CDEV1}>{v2.CDEV1}</option>
                                                )
                                            }
                                        </select>
                                    </td>

                                    <td style={{ display: (v.waiting ? "none" : "") }}>
                                        <select className="form-select" data-field="CDUNU" data-key={k} onChange={updateNewSupplier}>
                                            <option selected>Choose...</option>
                                            {
                                                p.unit.map(
                                                    (v2, k2) =>
                                                        <option key={"unit_" + k2} selected={v.CDUNU === v2.CODCS}
                                                            value={v2.CODCS}>{v2.LI1CS}</option>
                                                )
                                            }
                                        </select>
                                    </td>

                                    <td style={{ display: (v.waiting ? "none" : "") }}>
                                        <input type="number" className="form-control" onChange={updateNewSupplier}
                                            data-table="fartfou" data-field="PCTRU" value={v.PCTRU}
                                            data-label="Remise" data-key={k}
                                            id="PCTRU" readOnly={!p.purchase} />
                                    </td>

                                    <td style={{ display: (v.waiting ? "none" : "") }}>
                                        <input type="text" className="form-control" step="any" value={v.CFCOU} readOnly />
                                    </td>

                                    <td style={{ display: (v.waiting ? "none" : "") }}>
                                        <input type="date" className="form-control" onChange={updateNewSupplier}
                                            data-table="fartfou" data-field="DTDPU" value={v.DTDPU}
                                            id="DTDPU" readOnly={!p.purchase}
                                            data-label="Mise à jour" data-key={k} />
                                    </td>

                                    {
                                        p.purchase &&
                                        <td>
                                            <img src={"/common/remove_icon.png"} data-key={k} onClick={deleteNewSupplier}
                                                style={{ maxWidth: "30px", margin: "auto" }} />
                                        </td>
                                    }

                                </tr>
                        )
                    }

                </tbody>
            </table>
            <br></br>
            {
                p.purchase &&
                <div className="text-center">
                    <button className="btn btn-secondary" onClick={addNewSupplier}>Ajouter une ligne</button>
                </div>
            }

        </Fragment>)
}

export default Supplier;