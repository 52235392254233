import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import LabelsChoice from "../../../common/smallComponents.js/LabelsChoice";
import { ButtonWaiting, scrollToTop } from "../../../functions/PageAnimation";
import errorManagement from "../../../services/errorManagement";
import { downloadSingleFile } from "../../../services/functions/FilesProcess";
import subcontracting from "../../../services/supplyChain/subcontracting";
import Select from "react-select";
import { ArticleAutoComplete } from "../../../common/smallComponents.js/DesignedIpt";
import SuccessMessage from "../../../common/smallComponents.js/SuccessMessage";

const PackingListMenu = (p) => {
    const [checked, setChecked] = useState(false);
    const [typeLabels, setTypeLabels] = useState("all");
    const [distinctsVal, setDistinctsVal] = useState([]);
    const [selectedBlocks, setSelectedBlocks] = useState([]);
    const [downloadingLab, setDownloadingLab] = useState(false);
    const fileTypes = ["JPG", "PNG", "GIF", "XLS", "XLSX", "PDF", "DOCX"];
    const [files, setFiles] = useState([]);
    const [addingFiles, setAddingFiles] = useState(false);
    const [loading, setLoading] = useState(false);
    const [savingDate, setSavingDate] = useState(false);
    const [error, setError] = useState();
    const [newRow, setnewRow] = useState({ ar: "", cat: "", se: "", rep: "N/A", qty: 0, 
        label: "", ref: "", machine: p.machineInfo.no_machine, english_label: "", blocked: "", added: false });
    const [disinctSubset, setDistinctSubset] = useState([]);

    const user = JSON.parse(localStorage.getItem("user"));
    const isInternal = user && user.roles.includes("ROLE_INTERNAL")
    const isAdmin = user && user.roles.includes("ROLE_ADMIN")

    const API_URL = process.env.REACT_APP_API_URL;
    const token = p.user && "Bearer " + p.user.accessToken;
    const { t } = useTranslation('subcontracting');

    const exportLabels = (start) => {
        var arr = new Array();
        setError();

        if (typeLabels === "all") {
            arr = [...p.data];
        } else if (typeLabels === "subassembly") {
            if (selectedBlocks.length === 0) {
                setError("Sélectionnez au moins un sous-ensemble!");
                return;
            }

            p.data.map((v) => {
                if (selectedBlocks.includes(v.subset))
                    arr.push(v)
            })
        } else if (typeLabels === "cat") {
            if (selectedBlocks.length === 0) {
                setError("Sélectionnez au moins une catégorie!");
                return;
            }

            p.data.map((v) => {
                if (selectedBlocks.includes(v.category))
                    arr.push(v)
            })
        } else {
            p.labelsToPrint.map(v => arr.push(p.data[v]));
        }

        setDownloadingLab(true);
        subcontracting.getLabels(arr, start, p.machineInfo.affaire, p.machineInfo.no_machine, typeLabels).then(
            (response) => {
                var file = response.substring(response.lastIndexOf("\\") + 1, response.length);
                downloadSingleFile("temp", file);
                setDownloadingLab(false);
            }, (error) => {
                p.setError(errorManagement.handleError(p.props, error));
                setDownloadingLab(false);
            }
        )
    }

    function downloadBplusFiles() {
        window.open("", "_blank").location.href =
            API_URL + "subcontracting/download-all-files?machine=" + p.machineInfo.no_machine + "&Authorization=" + token;
    }

    function downloadSubcontractorFiles() {
        window.open("", "_blank").location.href =
            API_URL + "subcontracting/download-subcontractor-all-files?machine=" + p.machineInfo.no_machine + "&Authorization=" + token;
    }

    const removeFile = (e) => {
        const f = new Array(e.target.getAttribute("data-file"));
        p.setError(null);

        confirmAlert({
            title: "Annulation de partage",
            message: "Etes-vous sûr de vouloir supprimer le fichier " + f,
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setLoading(true);

                        subcontracting.removeSubcontractorFile(p.machineInfo.no_machine, f).then(
                            (response) => {
                                if (response !== null)
                                    setFiles(response);

                                setLoading(false);
                            },
                            (error) => {
                                p.setError(errorManagement.handleError(p.props, error));
                                setLoading(false);
                            }
                        );

                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const shareFiles = (file) => {
        setAddingFiles(true);

        subcontracting.subcontractorShareFiles(p.machineInfo.no_machine, file).then(
            (response) => {

                if (response !== null)
                    setFiles(response)

                setAddingFiles(false);
            },
            (error) => {
                p.setError(errorManagement.handleError(p.props, error, document));
                document.getElementsByClassName("scroll-to-top")[0].click();
                setAddingFiles(false);
            })
    };

    const onSentValidation = (e) => {
        e.preventDefault();
        var date = e.target.date.value;
        var machine = p.machineInfo.no_machine;
        setSavingDate(true);
        subcontracting.setDateSent(machine, date).then(
            (response) => {
                setSavingDate(false);
            },
            (error) => {
                p.setError(errorManagement.handleError(p.props, error));
                scrollToTop();
            }
        );
    }

    const downloadXlsFiles = () => {
        setSavingDate(true);
        subcontracting.getSubcontractingTableXls(p.data, p.machineInfo).then(
            (response) => {
                var file = response.substring(response.lastIndexOf("\\") + 1, response.length);
                downloadSingleFile("temp", file);
                setSavingDate(false);
            }, (error) => {
                p.setError(errorManagement.handleError(p.props, error));
                setSavingDate(false);
            }
        )
    }

    useEffect(() => {
        var arr = [];
        var used = [];
        var f = typeLabels === "subassembly" ? "subset" : "category";

        p.data.map((v) => {
            if (!used.includes(v[f])) {
                arr.push({ value: v[f], label: v[f] })
                used.push(v[f]);
            }
        })

        setDistinctsVal(arr);

    }, [typeLabels]);

    useEffect(() => {
        var arr = [];
        p.data && p.data.map((v) => { if (!arr.includes(v.subset)) arr.push(v.subset) });
        setDistinctSubset(arr);
    }, [p.data]);

    const onBlockChoose = (e) => {
        var arr = [];
        e.map(v => arr.push(v.value));
        setSelectedBlocks(arr);
    }

    const onArticleChange = (val) => {
        var o = { ...newRow };
        o.ar = val;
        setnewRow(o);
    }

    const onArticleChoose = (e) => {
        var o = { ...newRow };
        o.ar = e.CDARL.trim();
        o.cat = e.ZN15PA;
        o.label = e.DES1L;
        o.ref = e.ZN02PA;
        o.english_label = e.ZN12PA;
        o.blocked = e.BLOCL;

        var lastRep = 0;
        p.data.map(v => { if (v.mark && v.mark.startsWith("new_")) lastRep++; })
        o.rep = "new_" + (lastRep + 1);
        
        setnewRow(o);
    }

    const addRow = (e) => {
        e.preventDefault();
        var o = {...newRow};
        o.se = e.target.subset.value;
        o.qty = parseFloat( e.target.qty.value );

        if( o.ref === "" ){
            setError("Sélectionnez un article !");
            scrollToTop();
            return;
        }

        setDownloadingLab(true);
        subcontracting.addRow(o).then(
            (r) => {
                var arr = [...p.data];
                arr.push({
                    id: r.id,
                    machine: r.machine,
                    subset: r.se,
                    category: r.cat,
                    mark: r.rep,
                    qty: r.qty,
                    label: r.french_label,
                    reference: r.ref,
                    article: r.ar,
                    bp_internal_remarks: "",
                    bp_external_remarks: "",
                    sent: "",
                    sent2: "",
                    sent2Date: "",
                    manufactured: 0
                });
                p.setData(arr);

                o.added = true;
                setnewRow(o);
                setDownloadingLab(false);
            }, (error) => {
                p.setError(errorManagement.handleError(p.props, error));
                setDownloadingLab(false);
            }
        )
    }

    return <div>

        {/** Download labels */}
        {isInternal &&
            <div>
                <div>
                    <h5 className="text-center">Télécharger les étiquettes</h5>

                    <div className="text-center">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" onChange={() => setTypeLabels("all")}
                                checked={typeLabels === "all"} />
                            <label className="form-check-label" for="inlineRadio1">Toutes</label>
                        </div>

                        {p.labelsToPrint.length > 0 &&
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" onClick={() => setTypeLabels("selected")}
                                    checked={typeLabels === "selected"} />
                                <label className="form-check-label" for="inlineRadio2">Sélection</label>
                            </div>}

                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" onClick={() => setTypeLabels("subassembly")}
                                checked={typeLabels === "subassembly"} />
                            <label className="form-check-label" for="inlineRadio2">S/E</label>
                        </div>

                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" onClick={() => setTypeLabels("cat")}
                                checked={typeLabels === "cat"} />
                            <label className="form-check-label" for="inlineRadio2">Catégories</label>
                        </div>

                        {(typeLabels === "subassembly" || typeLabels === "cat") &&
                            <div style={{ color: "black" }}>
                                <Select className="mb-3" options={distinctsVal} isMulti defaultValue={selectedBlocks}
                                    closeMenuOnSelect={false} onChange={onBlockChoose}
                                    onClick={(e) => e.stopPropagation()} />
                            </div>
                        }
                    </div>
                    {error && <div className="text-danger bg-light p-2 rounded">{error}</div>}
                    <LabelsChoice onChooseTag={exportLabels} printingLabel={downloadingLab} />

                </div>

            </div>
        }

        <div className="mb-3 text-center">
            <h5>{savingDate && <ButtonWaiting />}Envois</h5>
            <a href="#" onClick={downloadXlsFiles}>Télécharger le tableau sous Excel</a>
        </div>


        {/** Sending info */}
        {isInternal &&
            <div className="col-10 offset-1">
                <form onSubmit={onSentValidation} className="mb-3">
                    <h6 className="text-center">Date d'expédition</h6>
                    <div className="input-group mb-3">
                        <input className="form-control" type="date" name="date" required></input>
                        <button type="submit" className="btn btn-success" disabled={savingDate}>
                            {savingDate && <ButtonWaiting />}
                            Ok</button>
                    </div>
                </form>

                <br></br>

                <h5 className="text-center">Ajouter une ligne</h5>
                {newRow.added && <SuccessMessage msg="Ligne ajoutée"/>}
                <form onSubmit={addRow} className="mb-3">
                    <ArticleAutoComplete value={newRow.ar} onChooseArt={(e) => onArticleChoose(e)}
                        setValue={(val) => onArticleChange(val)} searchDetail={true} />

                    <div className="input-group mt-3 mb-3">
                        <span className="input-group-text">S/E</span>
                        <select className="form-select" name="subset" required>
                            <option value="N/A">Aucun S/E</option>
                            {disinctSubset.map((v, k) => <option key={'sb' + k} value={v}>{v}</option>)}
                        </select>
                    </div>

                    <div className="input-group mb-3">
                        <span className="input-group-text">Qté demandée</span>
                        <input type="number" className="form-control" name="qty" defaultValue={0} required/>
                    </div>

                    <div className="text-center">
                        <button className="btn btn-success" disabled={downloadingLab}>
                            {downloadingLab && <ButtonWaiting/>}
                            Ajouter</button>
                    </div>

                </form>
            </div>
        }

        { (!isInternal || isAdmin ) && <a className="btn btn-secondary"
            href="#" onClick={downloadBplusFiles}>{t('download files')}</a>
        }


    </div>

}

export default PackingListMenu;