import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import getUrlParam, { extractValueFromTag, forceString, getCurrentDateTime, getFrFormat } from "../../functions/StrFunctions";
import CheckConnDao from "../../services/common/CheckConnDao";
import errorManagement from "../../services/errorManagement";
import ProdTrackingDao from "../../services/machines/ProdTrackingDao";
import QrCodeReader from "../storehouse/BarCodeReader";
import { confirmAlert } from "react-confirm-alert";
import usersDao from "../../services/settings/usersDao";
import MachineInfo from "../../services/machines/MachineInfo";

const ProdTracking = (props) => {
    const [error, setError] = useState();
    const [text, setText] = useState();
    const [trigram, setTrigram] = useState("");
    const [loading, setLoading] = useState(false);
    const [machInfo, setMachInfo] = useState();
    //0: select machine, 1: select trigram, 2: select subassembly, 3: start activity, 4: activity started
    const [step, setStep] = useState(0);
    const [inProgress, setInProgress] = useState();

    const [isBlocked, setIsBlocked] = useState(false);

    const [subAssList, setSubAssList] = useState();

    const [machine, setMachine] = useState();
    const [tracking, setTracking] = useState({
        "done": null, "id": null, "machine": null, subassembly: null, "status": null, "date": null,
        "user_id": null, "last_name": null, "first_name": null, "ui": null, "trigram": null, blocking_cause: null
    });

    useEffect(() => {
        // Check connection
        CheckConnDao.userChecking().then(() => { }, () => {
            localStorage.setItem("url_back", window.location.pathname + window.location.search);
            window.location.href = "/login";
        });

        var machine = getUrlParam(props, "machine");

        if (machine !== null) {
            setMachine(machine);
            setStep(1);
            setError();
            setLoading(true);
            MachineInfo.getOneMachineInfo(machine).then(
                (r) => { if (r === "") setError("Machine introuvable"); 
                            setMachine(r.no_machine); setMachInfo(r); setLoading(false) },
                (error) => { setError(errorManagement.handleError(props, error)); setLoading(false) })
        }
    }, []);

    useEffect(() => {
        if (text) {
            var url = "/workshop/production/tracking?machine=";
            if (text.indexOf("machine=")) url += text.substring(text.indexOf("machine=") + 8, text.length);

            window.location.href = url;
        }
    }, [text]);

    {/** Search the user from trigram */ }
    const getUser = (e) => {
        e.preventDefault();

        setError();
        setLoading(true);
        ProdTrackingDao.getTask(e.target.trigram.value, machine.trim()).then(
            (response) => {
                //console.log(response)
                // If no process in progress...
                if (response[0].done === "b") {
                    setStep(2);
                    setTracking(response[0]);
                } else {
                    
                    // If one process in progress...
                    if (response.length === 1) {
                        var o = { ...response[0] }
                        var o2 = {...response[0]};
                        o2.status_complete = response[0].id + "-" + response[0].workstation_act 
                        + "-" + response[0].status + "-" + trigram + "#"

                        o.subassembly = { ...o2 };
                        o.user_origin = "<id>"+response[0].ui+"</id><trigram>"+response[0].trigram+"</trigram>";
                        
                        setTracking(o);
                        setStep(4);
                    } else {
                        //If multiple process in progress..
                        setStep(2);
                        setInProgress(response);
                        var o = { ...tracking }
                        o.first_name = response[0].first_name;
                        o.last_name = response[0].last_name;
                        o.trigram = response[0].trigram;
                        o.workstation = response[0].workstation;
                        o.ui = response[0].ui;
                        setTracking(o);
                    }
                }

                setLoading(false);

                // If workstation is set search sub assembly
                getActivity(response[0].workstation);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setTrigram("");
                setLoading(false);
            });
    }

    {/** Search subassembly or station according workstation */ }
    const getActivity = (workstation) => {
        if (workstation === null) return;

        setLoading(true);
        ProdTrackingDao.getActivities(machine, workstation).then(
            (r) => {
                setSubAssList(r);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setTrigram("");
                setLoading(false);
            }
        )

    }

    {/** Go back */ }
    const goBack = () => {
        if (step === 1) window.location.href = "/workshop/production/tracking";

        if (step === 2) { var o = { ...tracking }; o.trigram = null; setTracking(o); setTrigram("") }
        else if (step === 3) { onChooseSubAssembly(undefined); }

        setStep(step - 1);
    }

    {/** Create task */ }
    const createTask = () => {
        setError();

        var o = { ...tracking };
        o.machine = machine;
        o.status = "Démarré";
        o.date = getCurrentDateTime("en");
        o.user_id = tracking.ui;
        
        var o2 = {...tracking.subassembly};
        o2.status_complete = "999-" + tracking.workstation + "-Démarré-"+trigram + "#" + o2.status_complete
        o.subassembly = o2;

        o.user_origin = "<id>" + tracking.ui + "</id><trigram>" + trigram + "</trigram>"
        setTracking(o);
        saveTask(o);
    }

    {/** Blocked task */ }
    const setBlocking = (e) => {
        e.preventDefault();

        setError();

        var o = { ...tracking };
        o.machine = machine;
        o.status = "Bloqué";
        o.blocking_cause = e.target.blocking_cause.value;
        o.date = getCurrentDateTime("en");
        o.user_id = tracking.ui;

        var o2 = {...tracking.subassembly};
        o2.status_complete = "999-" + tracking.workstation + "-Bloqué-"+trigram + "#" + o2.status_complete
        o.subassembly = o2;

        setTracking(o);

        setIsBlocked(false);
        saveTask(o);
    }

    const switchPerson = () => {
        setError();

        confirmAlert({
            title: "Fin de tâche",
            message: "Vous prenez la place de " + extractValueFromTag( tracking.user_origin, "trigram" ) + " sur ce sous-ensemble ?",
            buttons: [{ label: "Oui", onClick: () => { doSwitch() }},{ label: "Non"}]
        });

        
    }

    const doSwitch = async () => {
        /*var o = { ...tracking };
        o.machine = machine;
        o.status = "Terminé";
        o.blocking_cause = "Changement de personnel: " + extractValueFromTag( o.user_origin, "trigram" ).toUpperCase() + " -> " + trigram;
        o.date = getCurrentDateTime("en");
        o.user_id = parseInt( extractValueFromTag( o.user_origin, "id" ) );
        o.subassembly = tracking.subassembly;
        
        saveTask(o).then( (r) => {
            console.log(r)*/
            var o2 = { ...tracking };
            o2.machine = machine;
            o2.status = "Chgt. pers.";
            o2.blocking_cause = trigram + " remplace " + extractValueFromTag( tracking.user_origin, "trigram" ).toUpperCase();
            o2.date = getCurrentDateTime("en");
            o2.user_id = tracking.ui;
            o2.trigram = trigram;
            o2.user_origin = "<id>" + tracking.ui + "</id><trigram>" + trigram + "</trigram>"
            //o2.subassembly = tracking.subassembly;
            var o3 = {...tracking.subassembly};
            o3.status_complete = "999-" + tracking.workstation + "-Redémarré-"+trigram + "#" + o3.status_complete
            o2.subassembly = o3;

            setTracking(o2);
            saveTask(o2);
    
            setIsBlocked(false);
        //});

        
    }

    {/** Restart task */ }
    const setRestarted = (e) => {
        setError();

        var o = { ...tracking };
        o.machine = machine;
        o.status = "Redémarré";
        o.blocking_cause = "";
        o.date = getCurrentDateTime("en");
        o.user_id = tracking.ui;
        
        var o2 = {...tracking.subassembly};
        o2.status_complete = "999-" + tracking.workstation + "-Redémarré-"+trigram + "#" + o2.status_complete
        o.subassembly = o2;

        setTracking(o);

        saveTask(o);
    }

    {/** Restart task */ }
    const endTask = (e) => {
        setError();

        confirmAlert({
            title: "Fin de tâche",
            message: "Confirmez-vous avoir complété votre tâche ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        var o = { ...tracking };
                        o.machine = machine;
                        o.status = "Terminé";
                        o.blocking_cause = "";
                        o.date = getCurrentDateTime("en");
                        o.user_id = tracking.ui;
                        
                        var o2 = {...tracking.subassembly};
                        o2.status_complete = "999-" + tracking.workstation + "-Terminé-"+trigram + "#" + o2.status_complete
                        o.subassembly = o2;

                        setTracking(o);

                        saveTask(o);
                    }
                },
                {
                    label: "Non"
                }
            ]
        });

    }

    async function saveTask(o){
        setLoading(true);

        let res = await ProdTrackingDao.createTask(o).then(
            (response) => {
                var o = { ...tracking };
                o.id = response;
                o.status = o.status;
                o.date = o.date;
                setStep(4)
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );

        return res
    }

    {/** Choose subset */ }
    const onChooseSubAssembly = (k) => {
        if (k === undefined) {
            var o = { ...tracking };
            o.subassembly = undefined;
            setTracking(o);
        } else {
            var o = { ...tracking };
            o.subassembly = subAssList[k];
            o.status = getStatus( subAssList[k] );
            o.blocking_cause = subAssList[k].blocking_cause || "";
            o.user_origin = subAssList[k].person || "";
            
            setTracking(o);
            setStep(o.status !== null ? 4 : 3);
        }
    }

    {/** Continue with another s/e if blocked */ }
    const doAnotherSubAssembly = () => {
        var o = { ...tracking };
        o.blocking_cause = null;
        o.id = null;
        o.status = null;
        o.subassembly = null;

        setTracking(o);
        setStep(2);
    }

    const changeUser = () => {
        var o = { ...tracking };
        o.blocking_cause = null;
        o.id = null;
        o.status = null;
        o.subassembly = null;
        o.trigram = null;

        setTrigram("");
        setTracking(o);
        setStep(2);
    }

    {/** Save the workstation  */ }
    const onWorkstationChoose = (e) => {
        var o = { ...tracking };
        usersDao.updateMyProfileById(o.ui, "workstation", e.target.value);
        o.workstation = e.target.value;
        setTracking(o);

        getActivity(e.target.value);
    }

    function getBg(status) {
        if ( status === "En attente" ) {
            return { backgroundColor: "rgb(87, 155, 252)", color: "white" }
        } else if (status === "Démarré" || status === "Redémarré" || status === "Chgt. pers.") {
            return { backgroundColor: "rgb(253,171,61)" }
        } else if (status === "Terminé") {
            return { backgroundColor: "rgb(156, 211, 38)" }
        } else if (status === "Bloqué") {
            return { backgroundColor: "rgb(223, 47, 74)", color: "white" }
        }
    }

    function getStatus( row ){
        if( row.status_complete === null ) return "En attente";

        var split_act = row.status_complete.substring(0, row.status_complete.lastIndexOf("#")).split("#");
        var last_status = null;

        // For each task creation check...
        for( let i = 0; i<split_act.length; i++ ){
            var detail = split_act[i].split("-");
            if(row.workstation === detail[1] && last_status===null) 
                last_status = detail[2] === "Chgt. pers." ? "Démarré" : detail[2];

            // If the task matching the suitable activity you can get the status
            // For the same sub-assembly you can't get status of integration if the task concerns assembly
            if( row.workstation === detail[1]){
                if( detail[3].toUpperCase().trim() === trigram.toUpperCase().trim() )
                    return detail[2] === "Chgt. pers." ? "Démarré" : detail[2];
            }
        }
        
        //If nothing found for this workstation
        return last_status || "En attente";
    }

    function getUserOfTask( row ){
        if( row.status_complete === null ) return "";

        //To avoid split with empty cell
        var split_act = row.status_complete.substring(0, row.status_complete.lastIndexOf("#")).split("#");
        
        var user = "";
        // For each task creation check...
        for( let i = 0; i<split_act.length; i++ ){
            var detail = split_act[i].split("-");

            // If the task matching the suitable activity you can get the user
            // For the same sub-assembly you can't get status of integration if the task concerns assembly
            if( row.workstation === detail[1] && detail[2] !== "Terminé" && user.indexOf(detail[3]) === -1 ) 
                user += detail[3] + " - ";
        }
        
        if(user.indexOf("-")!==-1) user = user.substring(0, user.lastIndexOf("-"))

        //If nothing found for this workstation
        return user;
    }

    function canContinue(){
        if( !tracking.subassembly.status_complete ) return false;

        var split_act = tracking.subassembly.status_complete;
        split_act = split_act.substring(0, split_act.lastIndexOf("#")).split("#");
        
        for( let i = 0; i<split_act.length; i++ ){
            var detail = split_act[i].split("-");

            if( detail[3].toUpperCase().trim() === trigram.toUpperCase().trim() )
                return detail[2] !== "Terminé";
        }

        return false;
    }

    function getMyStatus(){
        if( !tracking ||!tracking.subassembly || !tracking.subassembly.status_complete ) return "En attente";

        var split_act = tracking.subassembly.status_complete;
        split_act = split_act.substring(0, split_act.lastIndexOf("#")).split("#");
        var last_status = "En attente";

        for( let i = 0; i<split_act.length; i++ ){
            var detail = split_act[i].split("-");
            //Save the last status in the case if the current user never started but should do
            if(i===0) last_status = detail[2];

            if( detail[3].toUpperCase().trim() === trigram.toUpperCase().trim() )
                return detail[2] ;
        }

        return last_status;
    }

    return <div className="mb-3">

        {error && <ErrorMessage error={error} errorState={setError} />}
        {loading && <WaitingRoundSnippers />}

        {(step > 0 && step < 4 && !loading) && <div className="d-flex mb-3 mt-1 p-1 cursor-pointer" onClick={goBack}
            style={{ borderStyle: "solid", width: "210px", borderWidth: "thin", borderRadius: "0.5em" }}>
            <img src="/common/goBack.png" className="ms-1" style={{ width: "50px" }} />
            <h6 className="me-auto mt-auto mb-auto ms-3">Retour en arrière</h6>
        </div>}

        {(step >= 4 && !loading) && <Fragment>

            <div className="d-flex mb-3 mt-1 p-1 cursor-pointer"
                onClick={() => window.location.href = "/workshop/production/tracking"}
                style={{ borderStyle: "solid", width: "210px", borderWidth: "thin", borderRadius: "0.5em" }}>
                <img src="/common/goBack.png" className="ms-1" style={{ width: "50px" }} />
                <h6 className="me-auto mt-auto mb-auto ms-3">Changer de machine</h6>
            </div>

            <div className="d-flex mb-3 mt-1 p-1 cursor-pointer"
                onClick={changeUser}
                style={{ borderStyle: "solid", width: "210px", borderWidth: "thin", borderRadius: "0.5em" }}>
                <img src="/common/goBack.png" className="ms-1" style={{ width: "50px" }} />
                <h6 className="me-auto mt-auto mb-auto ms-3">Changer d'utilisateur</h6>
            </div>

            {/* CAncelled
                (tracking.status === "Bloqué" || tracking.status === "Terminé") &&*/}
                <div className="d-flex mb-3 mt-1 p-1 cursor-pointer"
                    onClick={doAnotherSubAssembly}
                    style={{ borderStyle: "solid", width: "210px", borderWidth: "thin", borderRadius: "0.5em" }}>
                    <img src="/common/goBack.png" className="ms-1" style={{ width: "50px" }} />
                    <h6 className="me-auto mt-auto mb-auto ms-3">Faire un autre S/E</h6>
                </div>
            

        </Fragment>}



        {/** Scan QrCode */}
        {!machine && <Fragment>
            <h2 className="text-center">Scannez le Qr Code de la machine</h2>
            <QrCodeReader setText={setText} setError={setError} />
            <br></br>
            <h2 className="text-center">Ou saisissez le au clavier</h2>
            <form className="col-6 offset-3 ">
                <div className="input-group">
                    <input className="form-control fw-bold text-center" name="machine"
                        placeholder="N° de machine COMPLET"></input>
                    <button type="submit" className="btn btn-secondary">Chercher</button>
                </div>
            </form>
        </Fragment>}


        {tracking.trigram && <h3 className="text-center mb-3">Bonjour {tracking.first_name} {tracking.last_name}</h3>}

        {machine && <h2 className="text-center mb-3">Machine: {machine} {machInfo && <span>({machInfo.typ_config})</span>}</h2>}
        {machInfo && <h5 className="text-center mb-3">Aff: {machInfo.affaire}</h5>}

        {/** Enter the trigram */}
        {(machine && !loading && !tracking.trigram) && <form onSubmit={getUser}>
            <h2 className="text-center">Saisissez votre trigramme</h2>

            <div className="col-6 offset-3">
                <div className="input-group">
                    <input className="form-control text-center fw-bold" value={trigram}
                        onChange={(e) => setTrigram(e.target.value)} name="trigram" autoFocus></input>
                    <button type="submit" className="btn btn-success">Soumettre</button>
                </div>
            </div>
        </form>}

        {/** Enter the workstation if not saved */}
        {(machine && tracking.trigram && !tracking.workstation) &&
            <div className="col-6 offset-3">
                <div className="input-group mb-3 ">
                    <label className="input-group-text">Quel est votre poste</label>
                    <select className="form-select" onChange={onWorkstationChoose}>
                        <option selected>Choisissez...</option>
                        <option value="Cartérisation">Cartérisation</option>
                        <option value="Montage">Montage</option>
                        <option value="Intégration">Intégration</option>
                        <option value="Intégration électrique">Intégration électrique</option>
                    </select>
                </div>
            </div>
        }

        {/** Select the sub assembly */}
        {(machine && subAssList && tracking.trigram && tracking.workstation && !tracking.subassembly) && <div>
            <h5 className="text-center">Choisissez le sous-ensemble sur lequel vous allez travailler</h5>

            <div className="table-responsive">
                <table className="table text-center">
                    <thead>
                        <tr className="fw-bold table-info text-center">
                            <td>Code sous-ensemble</td>
                            <td>Libellé sous-ensemble</td>
                            <td>Poste</td>
                            <td>Statut</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {subAssList.map((v, k) => {
                            return <tr key={"se" + k}>
                                <td>{v.ref.split("-")[2]}</td>
                                <td>{v.french_label.trim()}</td>
                                <td>{v.workstation}</td>

                                <td className="text-center fw-bold react-table " style={getBg(getStatus( v ))}>
                                    <div>{getStatus( v )}</div>
                                    <div>{getUserOfTask(v)}</div>
                                </td>

                                <td className="text-center">
                                    <button className="btn btn-secondary"
                                        onClick={() => onChooseSubAssembly(k)}>Choisir cette activité</button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>}

        {tracking.subassembly && <h4 className="text-center">Sous-ensemble: {tracking.subassembly.french_label}</h4>}

        {(tracking.subassembly && tracking.status === "En attente" && !loading) &&
            <div className="text-center mt-3" onClick={createTask}>
                <button className="pushable green">
                    <span className="front green">Démarrer tâche</span>
                </button>
            </div>
        }

        {(getMyStatus() !== "En attente" && !loading) && <div className="mt-3">
            <div className="text-center">

                <div style={{ fontSize: "30px" }}>
                    <div>Statut:</div>
                    <div className="fw-bold">{getMyStatus() === "Chgt. pers." ? "Démarré" : getMyStatus() }</div>
                </div>
                
                {/** If the user is the same, he can continue */}
                { canContinue() && <Fragment>

                    {( ( getMyStatus().endsWith("émarré") || getMyStatus() === "Chgt. pers." ) && !isBlocked) &&
                        <div className="d-flex d-wrap">

                            <div className="text-center col-6">
                                <button className="pushable green mb-5" onClick={endTask}>
                                    <span className="front green">Tâche terminée</span>
                                </button>
                            </div>

                            <div className="text-center col-6">
                                <button className="pushable red" onClick={() => setIsBlocked(true)}>
                                    <span className="front red">Tâche bloquée</span>
                                </button>
                            </div>

                        </div>
                    }

                    {getMyStatus() === "Bloqué" &&
                        <div className="text-center mt-3">
                            <button className="pushable yellow">
                                <span className="front yellow" onClick={setRestarted}>Reprendre tâche</span>
                            </button>
                        </div>
                    }

                    {isBlocked && <form onSubmit={setBlocking}>
                        <h4 className="text-center">Saisissez la raison du blocage</h4>

                        <select className="form-select ms-auto me-auto mb-3" name="blocking_cause"
                            style={{ maxWidth: "300px" }} required>
                            <option value="">Selectionnez</option>
                            <option value="Erreur BE">Erreur BE</option>
                            <option value="Pièce NC">Pièce NC</option>
                            <option value="Pièces manquantes">Pièces manquantes</option>
                            <option value="Recherche de matériel">Recherche de matériel</option>
                            <option value="Erreur de stock">Erreur de stock</option>
                            <option value="Pas d'emplacement pour machine">Pas d'emplacement pour machine</option>
                            <option value="Assistance sur une autre activité">Assistance sur une autre activité</option>
                        </select>

                        <div className="text-center">
                            <button className="btn btn-success">Valider</button>
                        </div>
                    </form>}
                </Fragment>}

                {/** If it's an another user, he can only switch */}
                { !canContinue() && <Fragment>
                    <div className="text-center mt-3">
                        <button className="pushable orange">
                            <span className="front orange" onClick={switchPerson}>Changement de personnel</span>
                        </button>
                    </div>

                    <div className="text-center mt-3">
                        <button className="pushable blue">
                            <span className="front blue" onClick={createTask}>Renfort de personnel</span>
                        </button>
                    </div>
                </Fragment>}

            </div>

        </div>}

    </div>

}

export default ProdTracking;