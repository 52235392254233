import { Fragment } from "react";
import WaitingRoundSnippers from "./WaitingRoundSnippers";

/**
 * onChooseTag, start (optional)
 * @param {*} props 
 * @returns 
 */
const LabelsChoice = (props) => {

    const onChoose = (e) => {
        const start = e.target.getAttribute("data-start");
         props.onChooseTag(start);
    }

    function getBg(idx){
        if( props.start && parseInt(idx) === parseInt(props.start) ) return "aqua";
        return "";
    }

    return (
        <div className="text-center">

            {
                !props.printingLabel && 
                    <Fragment>
                        <span className="label-txt">Choisissez le départ d'étiquettes:</span>
                        <table id="labels" style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <tr>
                                <td data-start="1" onClick={onChoose} style={{ backgroundColor: getBg(1)}}></td>
                                <td data-start="2" onClick={onChoose} style={{ backgroundColor: getBg(2)}}></td>
                                <td data-start="3" onClick={onChoose} style={{ backgroundColor: getBg(3)}}></td>                    
                            </tr>
                            <tr>
                                <td data-start="4" onClick={onChoose} style={{ backgroundColor: getBg(4)}}></td>
                                <td data-start="5" onClick={onChoose} style={{ backgroundColor: getBg(5)}}></td>
                                <td data-start="6" onClick={onChoose} style={{ backgroundColor: getBg(6)}}></td>                    
                            </tr>
                            <tr>
                                <td data-start="7" onClick={onChoose} style={{ backgroundColor: getBg(7)}}></td>
                                <td data-start="8" onClick={onChoose} style={{ backgroundColor: getBg(8)}}></td>
                                <td data-start="9" onClick={onChoose} style={{ backgroundColor: getBg(9)}}></td>                    
                            </tr>
                            <tr>
                                <td data-start="10" onClick={onChoose} style={{ backgroundColor: getBg(10)}}></td>
                                <td data-start="11" onClick={onChoose} style={{ backgroundColor: getBg(11)}}></td>
                                <td data-start="12" onClick={onChoose} style={{ backgroundColor: getBg(12)}}></td>                    
                            </tr>
                            <tr>
                                <td data-start="13" onClick={onChoose} style={{ backgroundColor: getBg(13)}}></td>
                                <td data-start="14" onClick={onChoose} style={{ backgroundColor: getBg(14)}}></td>
                                <td data-start="15" onClick={onChoose} style={{ backgroundColor: getBg(15)}}></td>                    
                            </tr>
                            <tr>
                                <td data-start="16" onClick={onChoose} style={{ backgroundColor: getBg(16)}}></td>
                                <td data-start="17" onClick={onChoose} style={{ backgroundColor: getBg(17)}}></td>
                                <td data-start="18" onClick={onChoose} style={{ backgroundColor: getBg(18)}}></td>                    
                            </tr>
                            <tr>
                                <td data-start="19" onClick={onChoose} style={{ backgroundColor: getBg(19)}}></td>
                                <td data-start="20" onClick={onChoose} style={{ backgroundColor: getBg(20)}}></td>
                                <td data-start="21" onClick={onChoose} style={{ backgroundColor: getBg(21)}}></td>                    
                            </tr>
                            <tr>
                                <td data-start="22" onClick={onChoose} style={{ backgroundColor: getBg(22)}}></td>
                                <td data-start="23" onClick={onChoose} style={{ backgroundColor: getBg(23)}}></td>
                                <td data-start="24" onClick={onChoose} style={{ backgroundColor: getBg(24)}}></td>                    
                            </tr>
                        </table>
                    </Fragment>
                }

                {
                    props.printingLabel && 
                        <div className="label-txt">
                            Création d'étiquettes en cours...
                            <WaitingRoundSnippers />
                        </div>
                }
                <br></br>
        </div>
    )
}

export default LabelsChoice;