import Moment from "moment";
import { arrayRemoveElt, getKey } from "../../../functions/ArrayFunctions";
import { useState } from "react";
import { ButtonWaiting, scrollToTop } from "../../../functions/PageAnimation";
import errorManagement from "../../../services/errorManagement";
import ArticleInfo from "../../../services/articles/ArticleInfo";

const Prices = (p) => {
    const [creating, setCreating] = useState(false);

    // Update manually
    const updateArticle = (e) => {
        var table = e.target.getAttribute("data-table");
        var field = e.target.getAttribute("data-field");
        var label = document.querySelector("label[for='" + e.target.id + "']").innerHTML;
        var value = e.target.value;

        var old_value = p.prices[0].PVARB;
        var arr = [...p.change];

        var key = getKey(arr, "field", field);
        if (key === null) {
            var o = {
                table: table,
                field: field,
                old_value: old_value,
                new_value: value,
                label: label,
                cause: ""
            }

            arr.push(o);
        } else {
            if (parseFloat(arr[key].old_value) === parseFloat(value)
                || arr[key].old_value === value
                || (arr[key].old_value === null && value === "")) {
                arr = arrayRemoveElt(arr, key);
            } else {
                arr[key].new_value = value;
            }
        }

        p.setChange(arr);

        var arr = [...p.prices];
        arr[0].PVARB = value;
        p.setprices(arr);
    }

    // Update price
    const updatePrice = () => {
        p.setError();
        setCreating(true);

        ArticleInfo.updatePriceSell(p.articleInfo.CDARL, p.prices[0].PVARB).then(
            (response) => {
                var arr = [...p.prices];
                arr[0].PVARB = response;
                p.setprices(arr);
                setCreating(false);
            },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
                setCreating(false);
            }
        )
    }

    const addPrice = () => {
        p.setError();
        setCreating(true);

        ArticleInfo.addPriceSell(p.articleInfo.CDARL).then(
            (response) => {
                var arr = new Array();
                arr.push({
                    DTDPB: "1993-01-01",
                    DTFPB: "2199-12-31",
                    PVARB: response
                })
                p.setprices(arr);
                setCreating(false);
            },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
                setCreating(false);
            }
        )
    }

    return (
        <div className="col-6 offset-3">
            <table className="table text-center">
                <thead>
                    <tr>
                        <th scope="col">Date de début*</th>
                        <th scope="col">Date de fin*</th>
                        <th scope="col">Prix de vente (€)*</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        p.prices.map(
                            (v, k) =>
                                <tr key={"price_" + k}>
                                    <th scope="row">
                                        <input type="date" className="form-control" data-key={k}
                                            data-table="FARTPXV" data-field="DTDPB" value={v.DTDPB}
                                            id="DTDPB" readOnly />
                                    </th>
                                    <td>
                                        <input type="text" className="form-control"
                                            value={Moment(v.DTFPB).format("DD/MM/yyyy")} readOnly />
                                    </td>
                                    <td>
                                        <label className="d-none" htmlFor="PVARB">Prix de vente</label>
                                        <input type="number" step="any" className="form-control" data-key={k}
                                            onChange={updateArticle} data-table="FARTPXV" data-field="PVARB"
                                            value={v.PVARB} id="PVARB" readOnly />
                                    </td>
                                </tr>
                        )
                    }

                </tbody>
            </table>

            {
                p.prices.length > 0 && <div className="alert alert-info">
                    Depuis le 19/01/2024, il  n'y a qu'un seul prix de vente qui est mis à jour chaque soir
                    en fonction du dernier prix d'achats.<br></br>
                    Ce prix d'achat est multiplié par un coefficient
                    correspondant à sa catégorie d'article et sa fourchette de prix.
                    <br></br>Si vous avez des doutes
                    concernant ce prix, demandez au service achats de vérifier le prix de revient puis cliquez
                    sur le bouton ci-dessous pour mettre à jour le prix de vente.
                    <br></br><br></br>
                    <h6 className="text-center">Prix de revient: {p.articleInfo.PXREL} €</h6>
                    <div className="text-center">
                        {(p.articleInfo.PXREL === 0 || p.articleInfo.PXREL === 0.01) &&
                            <span className="text-danger fw-bold">Le prix de revient est de {p.articleInfo.PXREL} €, veuillez le mettre à jour</span>
                        }
                        {
                            (p.articleInfo.PXREL !== 0 && p.articleInfo.PXREL !== 0.01) &&
                            <button className="btn btn-success" onClick={updatePrice}
                                disabled={creating}>
                                {creating && <ButtonWaiting />}
                                Mettre à jour le prix de vente
                            </button>
                        }
                    </div>
                </div>
            }

            {
                p.prices.length === 0 && <div className="alert alert-info">
                    <h6 className="text-center">Prix de revient: {p.articleInfo.PXREL} €</h6>
                    <div className="text-center">
                        {(p.articleInfo.PXREL === 0 || p.articleInfo.PXREL === 0.01) &&
                            <span className="text-danger fw-bold">Le prix de revient est de {p.articleInfo.PXREL} €, veuillez le mettre à jour</span>
                        }
                        {
                            (p.articleInfo.PXREL !== 0 && p.articleInfo.PXREL !== 0.01) &&
                            <button className="btn btn-success" onClick={addPrice}
                                disabled={creating}>
                                {creating && <ButtonWaiting />}
                                Créer le prix de vente
                            </button>
                        }
                    </div>
                </div>
            }

        </div>)
}

export default Prices;