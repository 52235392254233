import { useEffect } from "react";
import { useState } from "react";
import ticketsDao from "../../services/qhse/ticketsDao";
import Moment from "moment";
import { scrollToTop } from "../../functions/PageAnimation";
import errorManagement from "../../services/errorManagement";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { Fragment } from "react";
import CustomToolTip from "../../common/smallComponents.js/CustomToolTip";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";

const BuildingList = (props) => {
    const [list, setList] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [tab, setTab] = useState(0);
    const [msg, setMsg] = useState();

    Moment.locale("fr");

    const user = JSON.parse(localStorage.getItem("user"));
    const [isQhse, setIsQhse] = useState(false);

    useEffect(() => {
        ticketsDao.getBuildingList().then(
            (response) => {
                setList(response);
                if (user.roles.includes("ROLE_QHSE")) setIsQhse(true);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );
    }, []);

    const changeTab = (e) => {
        setTab(parseInt(e.target.getAttribute("data-tab")));
    }

    const onFieldChange = (e) => {
        var field = e.target.getAttribute("data-field");
        var id = e.target.getAttribute("data-id");
        var value = e.target.value;

        ticketsDao.updateBuildingField(id, field, value).then(
            (response) => { },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }

    const onStatutChange = (e) => {
        setMsg();
        setError();
        var field = e.target.getAttribute("data-field");
        var id = e.target.getAttribute("data-id");
        var value = e.target.value;

        ticketsDao.statutChange(id, field, value).then(
            (response) => { 
                setMsg(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }

    return (
        <div>
            <h2 className="text-center">Liste des remontées bâtiment</h2>

            { loading && <WaitingRoundSnippers /> }

            {error && <ErrorMessage error={error} />}

            { msg && <SuccessMessage msg={msg} msgState={setMsg} /> }

            {
                list &&
                <Fragment>
                    <ul className="nav nav-tabs">
                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 0 ? "active" : "")}
                                href="#" onClick={changeTab} data-tab="0">En cours</a>
                        </li>
                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 1 ? "active" : "")}
                                href="#" onClick={changeTab} data-tab="1">Clôturé</a>
                        </li>
                    </ul>

                    {
                        tab === 0 &&
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr className="table-info">
                                        <th scope="col">Date</th>
                                        <th scope="col">Auteur</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Localisation</th>
                                        <th scope="col">Criticité</th>
                                        <th scope="col">Solution</th>
                                        {
                                            isQhse &&
                                            <Fragment>
                                                <th scope="col">Qui ?</th>
                                                <th scope="col">Quand ?</th>
                                                <th scope="col">Quoi ?</th>
                                            </Fragment>
                                        }
                                        <th scope="col">Statut</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.map(
                                            (v, k) => {
                                                return (
                                                    v.statut !== "Clôturé" &&
                                                    <tr key={"l_" + k}>
                                                        <th scope="row">{Moment(v.date).format("DD/MM/yyyy")}</th>
                                                        <td>{v.username}</td>
                                                        <td>
                                                            <CustomToolTip props={
                                                                { tooltipText: v.description, originText: v.description.substring(0, 31) + (v.description.length > 31 ? "..." : "") }} />
                                                        </td>
                                                        <td>
                                                            <select className="form-select" value={v.location} disabled>
                                                                <option value="-1">...</option>
                                                                <option value="2">Site 1: rez-de-chaussée</option>
                                                                <option value="1">Site 1: 1er étage</option>
                                                                <option value="4">Site 2: rez-de-chaussée</option>
                                                                <option value="3">Site 2: 1er étage</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select className="form-select" value={v.criticality} disabled>
                                                                <option value="">Choisissez...</option>
                                                                <option value="1">Normal</option>
                                                                <option value="2">Urgent</option>
                                                                <option value="3">Impératif</option>
                                                            </select>
                                                        </td>
                                                        <td>{v.proposal && v.proposal.substring(0, 31) + (v.proposal.length > 31 ? "..." : "")}</td>
                                                        {
                                                            isQhse &&
                                                            <Fragment>
                                                                <td>
                                                                    <input className="form-control" type="text" data-id={v.id} data-field="who"
                                                                        onBlur={onFieldChange} defaultValue={v.who}></input>
                                                                </td>
                                                                <td>
                                                                    <input className="form-control" type="date" data-id={v.id} data-field="[when]"
                                                                        onBlur={onFieldChange} defaultValue={v.when}></input>
                                                                </td>
                                                                <td>
                                                                    <input className="form-control" type="text" data-id={v.id} data-field="what"
                                                                        onBlur={onFieldChange} defaultValue={v.what}></input>
                                                                </td>
                                                            </Fragment>}
                                                        <td>
                                                            <select className="form-select" onChange={onStatutChange} disabled={!isQhse}
                                                                data-id={v.id} data-field="statut">
                                                                <option value="Ouvert">Ouvert</option>
                                                                <option value="En cours" selected={v.statut === 'En cours'}>En cours</option>
                                                                <option value="Clôturé" selected={v.statut === 'Clôturé'}>Clôturé</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-outline-info" href={"/qhse/building/form?id=" + v.id} target="_blank">Entrer</a>
                                                        </td>

                                                    </tr>)
                                            }
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    }

                    {
                        tab === 1 &&
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr className="table-info">
                                    <th scope="col">Date</th>
                                        <th scope="col">Auteur</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Localisation</th>
                                        <th scope="col">Criticité</th>
                                        <th scope="col">Solution</th>
                                        {
                                            isQhse &&
                                            <Fragment>
                                                <th scope="col">Qui ?</th>
                                                <th scope="col">Quand ?</th>
                                                <th scope="col">Quoi ?</th>
                                            </Fragment>
                                        }
                                        <th scope="col">Statut</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.map(
                                            (v, k) => {
                                                return (
                                                    v.statut === "Clôturé" &&
                                                    <tr key={"l_" + k}>
                                                        <th scope="row">{Moment(v.date).format("DD/MM/yyyy")}</th>
                                                        <td>{v.username}</td>
                                                        <td>
                                                            <CustomToolTip props={
                                                                { tooltipText: v.description, originText: v.description.substring(0, 31) + (v.description.length > 31 ? "..." : "") }} />
                                                        </td>
                                                        <td>
                                                            <select className="form-select" value={v.location} disabled>
                                                                <option value="-1">...</option>
                                                                <option value="2">Site 1: rez-de-chaussée</option>
                                                                <option value="1">Site 1: 1er étage</option>
                                                                <option value="4">Site 2: rez-de-chaussée</option>
                                                                <option value="3">Site 2: 1er étage</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select className="form-select" value={v.criticality} disabled>
                                                                <option value="">Choisissez...</option>
                                                                <option value="1">Normal</option>
                                                                <option value="2">Urgent</option>
                                                                <option value="3">Impératif</option>
                                                            </select>
                                                        </td>
                                                        <td>{v.proposal && v.proposal.substring(0, 31) + (v.proposal.length > 31 ? "..." : "")}</td>
                                                        {
                                                            isQhse &&
                                                            <Fragment>
                                                                <td>
                                                                    <input className="form-control" type="text" data-id={v.id} data-field="who"
                                                                        onBlur={onFieldChange} defaultValue={v.who}></input>
                                                                </td>
                                                                <td>
                                                                    <input className="form-control" type="date" data-id={v.id} data-field="[when]"
                                                                        onBlur={onFieldChange} defaultValue={v.when}></input>
                                                                </td>
                                                                <td>
                                                                    <input className="form-control" type="text" data-id={v.id} data-field="what"
                                                                        onBlur={onFieldChange} defaultValue={v.what}></input>
                                                                </td>
                                                            </Fragment>}
                                                        <td>
                                                            <select className="form-select" onChange={onStatutChange} disabled={!isQhse}
                                                                data-id={v.id} data-field="statut">
                                                                <option value="Ouvert">Ouvert</option>
                                                                <option value="En cours" selected={v.statut === 'En cours'}>En cours</option>
                                                                <option value="Clôturé" selected={v.statut === 'Clôturé'}>Clôturé</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-outline-info" href={"/qhse/building/form?id=" + v.id} target="_blank">Entrer</a>
                                                        </td>

                                                    </tr>)
                                            }
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    }

                </Fragment>
            }

        </div>)
}

export default BuildingList;