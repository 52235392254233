
const PartFour = (props) => {
    return (
        < div className="table-responsive">
            <table className="table businessCardStyle">
                <thead>
                    <tr className="bg-dark bg-gradient text-white text-center rounded">
                        <th scope="col">Services clients</th>
                        <th scope="col">Autres</th>
                        <th scope="col">Particularités</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {/** Services clients */}
                        <td style={{ width: "33.3%" }} className="border-end">
                            <div style={{ display: "flex" }}>
                                <div className="form-floating">
                                    <select className="form-select" defaultValue={props.businessInfo.lot_piece_det}
                                            onChange={props.updateBusinessField} data-field="lot_piece_det" 
                                            disabled={props.readOnly} data-translate="true">
                                        <option value=""></option>
                                        <option value="Non vendu">Non vendu</option>
                                        <option value="Vendu">Vendu</option>
                                    </select>
                                    <label for="floatingSelect">Lot de pièces dét. init.</label>
                                </div>

                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={props.businessInfo.prix_vente_piece_det}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="prix_vente_piece_det" list="prix_vente_piece_det"
                                        readOnly={props.readOnly} />
                                    <label>Prix de vente</label>
                                </div>

                            </div>

                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={props.businessInfo.contrat_maint_init}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" list="cmi_vendu" data-field="contrat_maint_init"
                                        readOnly={props.readOnly} />
                                    <label>Contrat de maint. initial</label>
                                </div>

                                <div className="form-floating mb-3">
                                    <textarea type="text" className="form-control" value={props.businessInfo.cmi_desc}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="cmi_desc"
                                        readOnly={props.readOnly}></textarea>
                                    <label>Description ct. de maint.</label>
                                </div>

                            </div>

                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={props.businessInfo.garantie_aff}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" list="duree_garantie" data-field="garantie_aff"
                                        readOnly={props.readOnly} data-translate="true" />
                                    <label>Garantie (durée/évènement)</label>
                                </div>

                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={props.businessInfo.desc_garantie}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="desc_garantie"
                                        readOnly={props.readOnly} />
                                    <label>Description de la garantie</label>
                                </div>
                            </div>
                        </td>

                        {/** Autres */}
                        <td style={{ width: "33.3%" }} className="border-end">
                            <div>
                                <div className="form-floating mb-3">
                                    <textarea type="text" className="form-control" value={props.businessInfo.penalite_retard}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="penalite_retard"
                                        readOnly={props.readOnly}></textarea>
                                    <label>Pénalité retard</label>
                                </div>
                            </div>

                            <div>
                                <div className="form-floating mb-3">
                                    <textarea type="text" className="form-control" value={props.businessInfo.penalite_perf}
                                        onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                        placeholder="xxx" data-field="penalite_perf"
                                        readOnly={props.readOnly} ></textarea>
                                    <label>Pénalité de performance</label>
                                </div>
                            </div>
                        </td>

                        {/** Particularités */}
                        <td style={{ width: "33.3%" }}>
                            <textarea type="text" className="form-control" value={props.businessInfo.particularites_aff}
                                onBlur={props.updateBusinessField} onChange={props.onBusinessChange}
                                placeholder="xxx" data-field="particularites_aff" rows="8"
                                readOnly={props.readOnly}></textarea>

                        </td>
                    </tr>

                </tbody>
            </table>

        </div>
    )
}

export default PartFour;