import { Fragment, useEffect, useState } from "react";
import getUrlParam from "../../functions/StrFunctions";
import Home from "./expeditions-comp/Home";
import NewRequest from "./expeditions-comp/NewRequest";
import ListRequest from "./expeditions-comp/ListRequest";
import ShipmentRequest from "./expeditions-comp/ShipmentRequest";
import PackingDocs from "./expeditions-comp/PackingDocs";
import Historic from "./expeditions-comp/Historic";

const Expeditions = (props) => {
    const [page, setPage] = useState("");

    useEffect( () => { 
        var page = getUrlParam(props, "page");
        if( !page ) page = "home";
        setPage( page ); 
    });

    return <Fragment>

        { page === "home" && <Home props={props} /> }
        { ( page && page === "form" ) && <NewRequest props={props}/> }
        { ( page && page === "tracking" ) && <ListRequest props={props}/> }
        { ( page && page === "shipment-request" ) && <ShipmentRequest props={props}/> }
        { ( page && page === "doc" ) && <PackingDocs props={props}/> }
        { ( page && page === "historic" ) && <Historic props={props}/> }

    </Fragment>

}

export default Expeditions;