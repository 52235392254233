import { useEffect, useState } from "react";
const PartSix = (p) => {
    var convoy = new Array();
    var typeApi = new Array();
    var typeInter = new Array();
    var lang = new Array();
    var precede = new Array();

    p.dp && p.dp.map(v => {
        if (v.field === "sens_conv") {
            convoy.push(v.label);
        } else if (v.field === "type_api") {
            typeApi.push(v.label);
        } else if (v.field === "typ_intercon_au") {
            typeInter.push(v.label);
        } else if (v.field === "langue") {
            lang.push(v.label);
        } else if (v.field === "procede_emb") {
            precede.push(v.label);
        }
    })


    return <div className="table-responsive">
        <div className="table-responsive">
            <table className="table businessCardStyle">
                <thead>
                    <tr className="bg-dark bg-gradient text-white text-center rounded">
                        <th scope="col">Mécanique</th>
                        <th scope="col">Contrôle / Commande</th>
                        <th scope="col">Habillage</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="border-end text-center" style={{ width: "33.33%" }}>

                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" value={p.machineInfo.cadence_mach} placeholder="xxx"
                                    onChange={p.onMachineInfoType} onBlur={p.updateMachineTable}
                                    data-field="cadence_mach" data-type="text" readOnly={p.readOnly} />
                                <label>Cadence</label>
                            </div>

                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" value={p.machineInfo.niv_convoy} placeholder="xxx"
                                    onChange={p.onMachineInfoType} onBlur={p.updateMachineTable}
                                    data-field="niv_convoy" data-type="text" readOnly={p.readOnly} />
                                <label>Niveau de convoyage</label>
                            </div>

                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" value={p.machineInfo.sens_conv} placeholder="xxx"
                                    onChange={p.onMachineInfoType} onBlur={p.updateMachineTable} list="convoy"
                                    data-field="sens_conv" data-type="text" readOnly={p.readOnly} />
                                <label>Sens de convoyage</label>
                            </div>


                        </td>

                        <td className="border-end text-center" style={{ width: "33.33%" }}>
                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={p.machineInfo.type_api} placeholder="xxx"
                                        onChange={p.onMachineInfoType} onBlur={p.updateMachineTable} list="typeApi"
                                        data-field="type_api" data-type="text" readOnly={p.readOnly} />
                                    <label>Type API</label>
                                </div>

                                {(p.machineInfo.group !== "BA_TECMA_18_20_24" && p.machineInfo.group !== "BA_TECMA_30_40") &&
                                    <div className="form-floating mb-3" style={{ marginLeft: "10px" }}>
                                        <input type="text" className="form-control" value={p.machineInfo.type_prise} placeholder="xxx"
                                            onChange={p.onMachineInfoType} onBlur={p.updateMachineTable}
                                            data-field="type_prise" data-type="text" readOnly={p.readOnly} />
                                        <label>Type prise armoire</label>
                                    </div>}
                            </div>

                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={p.machineInfo.voltage_mach} placeholder="xxx"
                                        onChange={p.onMachineInfoType} onBlur={p.updateMachineTable}
                                        data-field="voltage_mach" data-type="text" readOnly={p.readOnly} />
                                    <label>Voltage</label>
                                </div>

                                {(p.machineInfo.group !== "BA_TECMA_18_20_24" && p.machineInfo.group !== "BA_TECMA_30_40") &&
                                    <div className="form-floating mb-3" style={{ marginLeft: "10px" }}>
                                        <input type="text" className="form-control" value={p.machineInfo.type_interconn} placeholder="xxx"
                                            onChange={p.onMachineInfoType} onBlur={p.updateMachineTable} list="typeInter"
                                            data-field="type_interconn" data-type="text" readOnly={p.readOnly} />
                                        <label>Type d'interconnexion AU</label>
                                    </div>
                                }

                            </div>

                            <div className="form-floating mb-3" style={{ marginLeft: "10px" }}>
                                <input type="text" className="form-control" value={p.machineInfo.freq_elec} placeholder="xxx"
                                    onChange={p.onMachineInfoType} onBlur={p.updateMachineTable}
                                    data-field="freq_elec" data-type="text" readOnly={p.readOnly} />
                                <label>Fréquence électrique</label>
                            </div>
                        </td>

                        <td className="border-end text-center" style={{ width: "33.33%" }}>
                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={p.machineInfo.coul_chassis} placeholder="xxx"
                                        onChange={p.onMachineInfoType} onBlur={p.updateMachineTable}
                                        data-field="coul_chassis" data-type="text" readOnly={p.readOnly} />
                                    <label>Couleur châssis</label>
                                </div>

                                <div className="form-floating mb-3" style={{ marginLeft: "10px" }}>
                                    <input type="text" className="form-control" value={p.machineInfo.langue_mach} placeholder="xxx"
                                        onChange={p.onMachineInfoType} onBlur={p.updateMachineTable} list="lang"
                                        data-field="langue_mach" data-type="text" readOnly={p.readOnly} />
                                    <label>Langue</label>
                                </div>
                            </div>

                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={p.machineInfo.coul_carter} placeholder="xxx"
                                        onChange={p.onMachineInfoType} onBlur={p.updateMachineTable}
                                        data-field="coul_carter" data-type="text" readOnly={p.readOnly} />
                                    <label>Couleur carters</label>
                                </div>

                                <div className="form-floating mb-3" style={{ marginLeft: "10px" }}>
                                    <input type="text" className="form-control" value={p.machineInfo.procede_mach} placeholder="xxx"
                                        onChange={p.onMachineInfoType} onBlur={p.updateMachineTable} list="precede"
                                        data-field="procede_mach" data-type="text" readOnly={p.readOnly} />
                                    <label>Procédé</label>
                                </div>
                            </div>
                        </td>

                    </tr>

                </tbody>
            </table>

        </div>

        {/** LISTS */}
        <datalist id="convoy">
            {convoy.map((v, k) => {
                return <option value={v}>{v}</option>
            })}
        </datalist>

        <datalist id="typeApi">
            {typeApi.map((v, k) => {
                return <option value={v}>{v}</option>
            })}
        </datalist>

        <datalist id="typeInter">
            {typeInter.map((v, k) => {
                return <option value={v}>{v}</option>
            })}
        </datalist>

        <datalist id="lang">
            {lang.map((v, k) => {
                return <option value={v}>{v}</option>
            })}
        </datalist>

        <datalist id="precede">
            {precede.map((v, k) => {
                return <option value={v}>{v}</option>
            })}
        </datalist>
    </div>
}

export default PartSix;