import { useEffect, useState } from "react";
import WaitingRoundSnippers from "../../../common/smallComponents.js/WaitingRoundSnippers";
import transport from "../../../services/supplyChain/transport";
import errorManagement from "../../../services/errorManagement";
import { scrollToTop } from "../../../functions/PageAnimation";
import ErrorMessage from "../../../common/smallComponents.js/ErrorMessage";
import { getCurrentWeekYear, getDateTimeFromDb, getWeekYear } from "../../../functions/StrFunctions";

const Home = (props) => {
    const [highlights, setHighlights] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        transport.getList(true).then(
            (r) => {
                analyse(r)
            }, (error) => {
                setError(errorManagement.handleError(props.props, error));
                scrollToTop();
            }
        );
    }, []);

    function analyse(arr) {
        var analyse = {
            thisWeekDel: [],
            supplyChainWaiting: [],
            toOrder: []
        };

        var o;
        var curWeek = getCurrentWeekYear();

        for (let i = 0; i < arr.length; i++) {
            o = arr[i];

            //Check loading this week
            var week = getWeekYear(o.date_load);
            if (week.year === curWeek.year && week.week === curWeek.week) analyse.thisWeekDel.push({ ...o });

            //Check status waiting
            if (o.status === "En attente de DT") analyse.supplyChainWaiting.push({ ...o });

            //Check orders
            if (o.status === "DT envoyée" && (o.transp_aff !== null && o.transp_aff.indexOf("B+") !== -1 ) 
                && o.order_nb === null) analyse.toOrder.push({ ...o });

        }

        setHighlights(analyse);

    }

    const user = JSON.parse(localStorage.getItem("user"));
    const isSupplyChain = user && (user.roles.includes("ROLE_SUPPLY_CHAIN") || user.roles.includes("ROLE_ADMIN"));
    const isPurchase = user && (user.roles.includes("ROLE_PURCHASE") || user.roles.includes("ROLE_ADMIN"));

    function getCssDiv() {
        return {
            maxWidth: "250px", height: "100px", fontSize: "20px", backgroundColor: "rgb(0, 137, 150)",
            borderRadius: "0.5em",
            boxShadow: "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px"
        }
    }

    function getList(arr, label) {
        if (arr.length > 0) {
            return <ul style={{ fontSize: "14px" }}>
                {arr.map((v, k) => {
                    return <li key={label + k}>
                        <a className="fw-bold me-2"
                            href={"/supply-chain/tools/expedition?page=shipment-request&shipment_id=" + v.id
                                + (v.exp_id ? "&id=" + v.exp_id : "")}>
                            {v.req_id}:
                        </a>
                        {v.business} pour le {getDateTimeFromDb(v.date_load, "fr", true)}
                    </li>
                })}
            </ul>
        }

    }

    function nav(page) { window.location.href = "/supply-chain/tools/expedition?page=" + page }

    return <div className="mt-auto mb-auto">
        <h4 className="text-center">Outil d'expédition</h4>
        {error && <ErrorMessage error={error} errorState={setError} />}

        <div className="d-flex flex-wrap">

            <div className="card col-4" style={{ minWidth: "300px" }}>
                <h4 className="text-center mb-3">Gérer les expéditions</h4>

                <div className="mb-5 p-2 text-center fw-bold m-auto text-white d-flex align-items-center cursor-pointer"
                    style={getCssDiv()}>
                    <div onClick={() => nav("form")} className="text-decoration-none text-white">Créer une demande d'expédition</div>
                </div>

                {(isSupplyChain || isPurchase) &&
                    <div className="mb-5 p-2 text-center fw-bold m-auto text-white d-flex align-items-center cursor-pointer"
                        style={getCssDiv()}>
                        <div onClick={() => nav("tracking")} className="text-decoration-none text-white m-auto">Suivre les demandes envoyées</div>
                    </div>
                }

                {(!isSupplyChain || !isPurchase) &&
                    <div className="mb-5 p-2 text-center fw-bold m-auto text-white d-flex align-items-center cursor-pointer"
                        style={getCssDiv()}>
                        <div onClick={() => nav("tracking&all=true")} className="text-decoration-none text-white m-auto">Suivre mes expéditions</div>
                    </div>
                }

            </div>

            <div className="card col-4" style={{ minWidth: "300px" }}>
                <h4 className="text-center">Highlights</h4>
                {!highlights && <WaitingRoundSnippers />}

                {highlights && <div>

                    <div>
                        <h6>{highlights.thisWeekDel.length} chargement(s) de camion(s) cette semaine</h6>
                        { getList( highlights.thisWeekDel, "thisWeekDel" ) }
                    </div>

                    <div>
                        <h6>{highlights.supplyChainWaiting.length} demande(s) de transport en attente de traitement</h6>
                        { getList( highlights.supplyChainWaiting, "supplyChainWaiting" ) }
                    </div>

                    <div>
                        <h6>{highlights.toOrder.length} demande(s) de transport en attente de commande</h6>
                        { getList( highlights.toOrder, "supplyChainWaiting" ) }
                    </div>

                </div>}
            </div>
        </div>




    </div>

}

export default Home;
