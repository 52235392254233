import { Fragment, useEffect, useState } from "react";
import { getKey, removeEltArray } from "../../../functions/ArrayFunctions";
import { forceString, getCurrentWeekYear } from "../../../functions/StrFunctions";
import fahDao from "../../../services/common/fahDaoV2";
import ErrorMessage from "../../../common/smallComponents.js/ErrorMessage";
import Moment from "moment";

const SendToInterim = (p) => {
    const [checked, setChecked] = useState([]);
    const [missing, setMissing] = useState([]);
    const [week, setWeek] = useState();
    const [year, setYear] = useState();
    const [isSending, setIsSending] = useState(false);
    const [error, setError] = useState();
    Moment.locale("fr");

    useEffect(() => {
        setIsSending(false);
        setWeek(p.toSendInterim.week);
        setYear(p.toSendInterim.year);

        if (p.myStaff === undefined) return;

        var arr = new Array();
        p.myStaff.map((v) => {
            if (v.isInterim === "O")
                if (getKey(p.myInterim, "focus_id", v.focus_id) === null) arr.push(v);
        });

        setMissing(arr);
    }, []);

    const getMessage = (v) => {
        if (!v.status || v.status === "N") {
            return "FAH non faite par le salarié";
        } else if (v.status === "S") {
            return "Vous n'avez pas encore validé cette FAH";
        } else if (v.date_sent_interim !== null) {
            return "Vous avez déjà envoyé cette FAH le " + Moment(v.date_sent_interim).format("DD/MM/YYYY");
        }

        return "";
    }

    const getBg = (v) => {
        if (!v.status || v.status === "N") {
            return "bg-danger";
        } else if (v.status === "S") {
            return "bg-warning";
        } else if (v.date_sent_interim !== null) {
            return "bg-success text-white";
        }

        return "";
    }

    const changePeriod = (e) => {
        e.preventDefault();
        var trigram = e.target.trigram ? e.target.trigram.value : "";

        p.setToSendInterim({ week: week, year: year, trigram: trigram });
        p.getInterim(week, year, trigram);
    }

    const checkAll = (e) => {
        var arr = new Array();

        if (e.target.checked) {
            p.myInterim.map((v, k) => {
                if (getKey(p.myInterim, "focus_id", v.focus_id) !== null)
                    if (getMessage(v) === "") arr.push(k);
            });


        }

        setChecked(arr);
    }

    const checkOne = (e, k) => {
        var arr = [...checked];

        if (e.target.checked) {
            if (!arr.includes(k)) arr.push(k);
        } else {
            arr = removeEltArray(arr, k);
        }

        setChecked(arr);
    }

    const updateEmail = (e, k, field) => {
        setError();

        var val = e.target.value;
        var old = p.myInterim[k][field];
        if (forceString(old).toUpperCase().trim() === val.toUpperCase().trim() || val === "") return;

        var arr = [...p.myInterim];
        arr[k][field] = val;

        fahDao.updateInterimMail(val, field, arr[k].codeInterim);

        p.setMyInterim(arr);

    }

    const send = () => {
        var arr = new Array();
        setError();

        checked.map(v => { arr.push(p.myInterim[v]); });

        for (let i = 0; i < arr.length; i++) {
            if (arr[i].address === null || arr[i].address.trim() === "") {
                setError("Au moins une adresse mail du contact principal n'est pas renseignée");
                return;
            }
        }
        
        setIsSending(true)
        fahDao.sendClockTimeToInterim(week, year, arr);
        setChecked([]);
    }

    return <Fragment>
        <h5 className="text-center">Cochez ceux que vous voulez envoyer pour la période {week + '/' + year}</h5>
        {error && <ErrorMessage error={error} errorState={setError} />}
        {isSending && <div className="alert alert-success text-center fw-bold">Envoi(s) en cours, vous serez en copie</div>}

        <form className="col-6 offset-3" onSubmit={changePeriod}>
            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">Période</span>
                {
                    p.isAccountant && <input type="text" name="trigram" placeholder="Trigramme manager"
                        className="form-control text-center fw-bold" />
                }
                <input type="number" value={week} placeholder="Semaine" onChange={(e) => setWeek(e.target.value)}
                    className="form-control text-center fw-bold" />
                <input type="number" value={year} onChange={(e) => setYear(e.target.value)}
                    className="form-control text-center fw-bold" />
                <button className="btn btn-secondary">Changer</button>
            </div>
        </form>

        {
            (missing && missing.length > 0) && <div className="alert alert-danger mb-3 fw-bold">
                Contrat interim non trouvé sur la période pour: <br></br>
                <ul>
                    {missing.map((v, k) => {
                        return <li key={"missing" + k}>{v.lastname} {v.firstname}</li>
                    })}
                </ul>
            </div>
        }

        <table className="table">
            <thead>
                <tr>
                    <th>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" onChange={checkAll} />
                            <label className="form-check-label" for="flexCheckDefault">A envoyer</label>
                        </div>
                    </th>
                    <th>Nom</th>
                    <th>Prénom</th>
                    <th>Trigramme</th>
                    <th>Agence d'intérim</th>
                    <th>Mail du contact principal</th>
                    <th>Mail du contact en copie</th>
                    <th>Message du système</th>
                </tr>
            </thead>
            <tbody>
                {
                    p.myInterim.map((v, k) => {
                        return <tr key={"founded" + k}>
                            <td>
                                {(v.status && v.status !== "N" && v.status !== "S") &&
                                    <input type="checkbox" className="to_send" 
                                        checked={checked.includes(k)}
                                        onChange={(e) => checkOne(e, k)}></input>}
                            </td>
                            <td>{v.lastname}</td>
                            <td>{v.firstname}</td>
                            <td>{v.trigram}</td>
                            <td>
                                {v.nameInterim}<br></br>
                                <span style={{ fontSize: "10px" }}>Du {Moment(v.DTDEBI).format("DD/MM/yyyy")} au {Moment(v.DTFINI).format("DD/MM/yyyy")}</span>
                            </td>
                            <td>
                                <input className="form-control" onBlur={(e) => { updateEmail(e, k, "address") }}
                                    defaultValue={v.address}></input>
                            </td>
                            <td>
                                <input className="form-control" onBlur={(e) => { updateEmail(e, k, "oth_mail") }}
                                    defaultValue={v.oth_mail}></input>
                            </td>
                            <td className={getBg(v)}>
                                {getMessage(v)}
                            </td>
                        </tr>
                    })
                }
            </tbody>
        </table>

        <div className="text-center">
            {
                checked.length > 0 &&
                <button className="btn btn-outline-success" onClick={send}>Envoyer</button>
            }
        </div>

    </Fragment>

}

export default SendToInterim;