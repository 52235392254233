import { Fragment, useEffect, useState } from "react";
import getUrlParam from "../../functions/StrFunctions";
import MachineInfo from "../../services/machines/MachineInfo";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import errorManagement from "../../services/errorManagement";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import PartOne from "./smallCom/machine/PartOne";
import PartTwo from "./smallCom/machine/PartTwo";
import Explanations from "./smallCom/machine/Explanations";
import PartThree from "./smallCom/machine/PartThree";
import PartFour from "./smallCom/machine/PartFour";
import PartFive from "./smallCom/machine/PartFive";
import PartSix from "./smallCom/machine/PartSix";
import PartSeven from "./smallCom/machine/PartSeven";
import PartEight from "./smallCom/machine/PartEight";
import DpManagement from "./smallCom/machine/DpManagement";
import PartNine from "./smallCom/machine/PartNine";
import PartTen from "./smallCom/machine/PartTen";
import Annexes from "./smallCom/machine/Annexes";
import Tab from "./smallCom/machine/Tab";
import MachinesCat from "./smallCom/machine/MachinesCat";

const MachineCard = (props) => {
    const [machineNb, setMachineNb] = useState();
    const [machineInfo, setMachineInfo] = useState();
    const [box1, setBox1] = useState();
    const [box2, setBox2] = useState();
    const [options, setOptions] = useState();
    const [dpOptions, setDpOptions] = useState();
    const [dp, setDp] = useState();
    const [machCat, setMachCat] = useState();
    const [sibling, setSibling] = useState();
    const [revisions, setRevisions] = useState();
    const [allMachInfo, setAllMachInfo] = useState();
    const [annexes, setAnnexes] = useState([]);
    
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [tab, setTab] = useState(0);
    const user = JSON.parse(localStorage.getItem("user"));
    const [readOnly] = useState(user && !user.roles.includes("ROLE_WRITE_FM"));

    useEffect(() => {
        var pMachine = getUrlParam(props, "machine");
        setMachineNb(pMachine);

        //Get machines infos
        MachineInfo.getAllMachineInfo(pMachine).then(
            (response) => {
                setMachineInfo(response[0]);
                getBoxes(response);
                setAllMachInfo(response);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

        //Get dropdown menu
        MachineInfo.getDropDownMenu().then(
            (response) => {
                setDp(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );

        MachineInfo.getAllOptions().then(
            (response) => {
                setDpOptions(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );

        //Get options
        MachineInfo.getOptions(pMachine).then(
            (response) => {
                setOptions(response.options);
                setDpOptions(response.list);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );

         //Annexes
         MachineInfo.getAnnexes(pMachine).then(
            (response) => {
                setAnnexes(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

        //Get all machines of this business
        MachineInfo.getSibling(pMachine).then(
            (response) => {
                setSibling(response)
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );

        //Get revisions
        MachineInfo.getRevisions(pMachine).then(
            (response) => {
                setRevisions(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );

        // Get machines cat
        MachineInfo.getMachinesCat().then(
            (response) => {
                setMachCat(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );

    }, []);

    /**
     * Extract boxes from results
     * @param {*} res 
     */
    function getBoxes(res) {
        var b1 = new Array();
        var b2 = new Array();

        res.map(v => {
            if (v.no_piste === 1) b1.push(v);
            if (v.no_piste === 2) b2.push(v);
        });

        setBox1(b1);
        if (res[0].group === "BA_TECMA_30_40" || res[0].group === "EC16") setBox2(b2);
    }

    /**
     * Update machine table
     */
    const updateMachineTable = (e) => {
        if (readOnly) return;

        var field = e.target.getAttribute("data-field");
        var value = e.target.value;
        var typeField = e.target.getAttribute("data-type");

        var o = { ...machineInfo };
        o[field] = value;
        setMachineInfo(o);

        MachineInfo.updateMachineField(machineInfo.no_machine, field, value, typeField).then(
            () => {
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        )
    }

    const onMachineInfoType = (e) => {
        var field = e.target.getAttribute("data-field");
        var value = e.target.value;

        var o = { ...machineInfo };
        o[field] = value;
        setMachineInfo(o);
    }

    return <Fragment>
        <h3 className="text-center">Machine {machineNb} {machineInfo && <span>( {machineInfo.typ_config} )</span>}</h3>
        {machineInfo &&
            <h5 className="text-center">Affaire: <a target="_blank" href={"/business?code=" + machineInfo.id_aff}>{machineInfo.id_aff}</a></h5>}

        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        {/** TAB */}
        <Tab readOnly={readOnly} machineInfo={machineInfo} tab={tab} setTab={setTab} dp={dp} machCat={machCat} />

        {/** MACHINE CARD */
            tab === 0 && <Fragment>
                {/** GENERAL */}
                {machineInfo &&
                    <PartOne machineInfo={machineInfo} setMachineInfo={setMachineInfo} setError={setError}
                        updateMachineTable={updateMachineTable} dp={dp} readOnly={readOnly} />}
                <br></br>

                {/** CAISSES */
                    machineInfo && <PartTwo machineInfo={machineInfo}
                        box1={box1} setBox1={setBox1} box2={box2} setBox2={setBox2}
                        dp={dp} setError={setError} readOnly={readOnly} />}
                <br></br>

                {/** COIFFE */
                    (machineInfo && (machineInfo.group === "POSE_COIFFE" || machineInfo.group === "IN" ||
                        machineInfo.group === "LN" || machineInfo.group === "IK")) &&
                    <PartThree machineInfo={machineInfo} setMachineInfo={setMachineInfo} setError={setError}
                        dp={dp} readOnly={readOnly} />
                }
                <br></br>

                {/** CALE */
                    (machineInfo && (machineInfo.group === "WN" || machineInfo.group === "POSE_CALE")) &&
                    <PartFour machineInfo={machineInfo} setMachineInfo={setMachineInfo} setError={setError}
                        dp={dp} readOnly={readOnly} />
                }
                <br></br>

                {/** COLLE - GENERALITES - PARTICULARITES MACHINES */
                    machineInfo &&
                    <PartFive machineInfo={machineInfo} setMachineInfo={setMachineInfo} setError={setError}
                        dp={dp} readOnly={readOnly} updateMachineTable={updateMachineTable} onMachineInfoType={onMachineInfoType} />
                }
                <br></br>

                {/** MECANIQUE - CONTROLE/COMMANDE - HABILLAGE */
                    machineInfo &&
                    <PartSix machineInfo={machineInfo} setMachineInfo={setMachineInfo} setError={setError}
                        dp={dp} readOnly={readOnly} updateMachineTable={updateMachineTable} onMachineInfoType={onMachineInfoType} />
                }
                <br></br>

                {/** OPTIONS */
                    (options && dpOptions) &&
                    <PartSeven machineInfo={machineInfo} setError={setError}
                        dpOptions={dpOptions} readOnly={readOnly} options={options} />
                }

                {/** ANNEXES */
                    machineInfo && <Annexes annexes={annexes} setAnnexes={setAnnexes} 
                        readOnly={readOnly} machineInfo={machineInfo} setError={setError} />
                }
                <br></br>

            </Fragment>
        }

        {/** CARTONNIER */
            (tab === 4 && machineInfo && box1 && allMachInfo && annexes) &&
            <PartTen machineInfo={machineInfo} setError={setError}
                box1={box1} setBox1={setBox1} box2={box2} setBox2={setBox2}
                dp={dp} readOnly={readOnly} options={options} allMachInfo={allMachInfo} 
                annexes={annexes} setAnnexes={setAnnexes}/>
        }
        <br></br>

        {/** COMPLEMENT */}
        {(tab === 5 && machineInfo) &&
            <PartEight machineInfo={machineInfo} setMachineInfo={setMachineInfo} setError={setError} sibling={sibling}
                readOnly={readOnly} updateMachineTable={updateMachineTable} onMachineInfoType={onMachineInfoType}
                revisions={revisions} />}

        {/** EXPORTS */
            (tab === 2 && machineInfo) &&
            <PartNine machineInfo={machineInfo} revisions={revisions} setRevisions={setRevisions} setError={setError} />
        }

        {/** DROPDOWN MENU MANAGEMENT */}
        {(tab === 1 && dp && dpOptions) && <DpManagement dp={dp} setDp={setDp} dpOptions={dpOptions}
            setDpOptions={setDpOptions} setError={setError} />}

        {/** MACHINES CATEGORIES MANAGEMENT */}
        {(tab === 6 && machCat) && <MachinesCat machCat={machCat} setMachCat={setMachCat} setError={setError} />}

        {/** EXPLANATIONS MENU */}
        {tab === 3 && <Explanations />}

    </Fragment>
}

export default MachineCard;