import { useState } from "react";
import { Fragment } from "react";
import Labels from "../../services/storehouse/Labels";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { useEffect } from "react";
import errorManagement from "../../services/errorManagement";
import { scrollToTop } from "../../functions/PageAnimation";
import ErrorManagement from "../../services/errorManagement";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import QrCodeReader from "./BarCodeReader";

const ScanScrews = (props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [scanMode, setScanMode] = useState("scanner");
    const [text, setText] = useState();

    const [list, setList] = useState();
    const [ar, setAr] = useState();
    const [qty, setQty] = useState("");

    const [nbScan, setNbScan] = useState(0);
    const [lastScan, setLastScan] = useState();
    let timer = null;

    useEffect(() => {
        Labels.getScrews().then(
            (response) => {
                setList(response);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }, []);

    /**
     * Check article on scan
     * @param {*} e 
     */
    const onScan = (e) => {
        reset();

        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            var scanned = document.getElementById("scanIpt").value.trim();

            if (scanned.trim() === "") return;

            if (!list.includes(scanned)) {
                setError("L'article " + scanned + " n'est pas répertorié dans la base de données des visseries");
                scrollToTop();
                document.getElementById("scanIpt").value = "";
                document.getElementById("scanIpt").focus();
            } else {
                setAr(scanned);
                setLastScan(scanned);
                setNbScan(nbScan + 1);
            }
        }, 500);
    }

    /**
     * On qr code scan
     */
    useEffect(() => {
        if (text !== undefined) {
            if (!list.includes(text)) {
                setError("L'article " + text + " n'est pas répertorié dans la base de données des visseries");
                scrollToTop();
            } else {
                setAr(text);
                setLastScan(text);
                setNbScan(nbScan + 1);
            }
        }
    }, [text]);

    /**
     * Save requested qty
     */
    const save = () => {
        if (ar.trim() === "" || isNaN(qty)) {
            setError("Merci de remplir tous les champs");
            scrollToTop();
        } else {
            Labels.requestScrewToOrder(ar, qty);
            reset();
            if( scanMode === "scanner" ){
                document.getElementById("scanIpt").value = "";
                document.getElementById("scanIpt").focus();
            }
        }

    }

    const reset = () => {
        setError();
        setAr();
        setQty();
    }

    return <Fragment>
        <h4 className="text-center">Demande de visserie</h4>
        <div className="text-center">
            {scanMode === "scanner" && <button className="btn btn-outline-secondary" onClick={() => setScanMode("cam")}>Mode photo</button>}
            {scanMode === "cam" && <button className="btn btn-outline-secondary" onClick={() => setScanMode("scanner")}>Mode scanner</button>}
        </div>
        {loading && <WaitingRoundSnippers />}
        <br></br>
        {error && <ErrorMessage error={error} errorState={setError} />}

        {
            !loading && <div>
                {
                    scanMode === "scanner" &&
                    <div className="input-group mb-3">
                        <input className="form-control text-center" onKeyDown={onScan} autoComplete="off"
                            placeholder="Cliquez ici pour commencer à scanner" id="scanIpt"></input>
                    </div>
                }


                {ar && <input type="number" className="form-control text-center mb-3"
                    placeholder="Qté à commander" value={qty} onChange={(e) => setQty(e.target.value)}></input>}

                {
                    ( !ar && scanMode ) === "cam" &&
                    <QrCodeReader setText={setText} setError={setError} />
                }

                {ar &&
                    <div className="text-center">
                        <button className="btn btn-success" onClick={reset}>Annuler</button>

                        <button className="btn btn-success" onClick={save}>Valider</button>
                    </div>
                }
                <p className="text-center fw-bold">Nb AR scannés: {nbScan}</p>
                {lastScan && <p className="text-center fw-bold">Dernier AR scanné: {lastScan}</p>}
            </div>
        }
    </Fragment>

}

export default ScanScrews;