import { Fragment, useEffect, useState } from "react";
import { arrayRemoveElt, getKeyInt, getKeyString, removeEltArray } from "../../../../functions/ArrayFunctions";
import { scrollToTop } from "../../../../functions/PageAnimation";
import errorManagement from "../../../../services/errorManagement";
import BusinessDao from "../../../../services/machines/BusinessDao";

const BillFilter = (p) => {
    const [fields, setFields] = useState([]);
    const user = JSON.parse(localStorage.getItem("user"));
    const isAdmin = user && (user.roles.includes("ROLE_ADMIN_BILL") || user.roles.includes("ROLE_ADMIN"));

    const [newFilter, setNewFilter] = useState({ field: "", first_input: "", operator: "", type_data: "" });

    useEffect(() => {
        initArray();
    }, []);

    useEffect(() => {
        initArray();
    }, [p.filter]);


    function initArray() {
        var arr = new Array();
        arr.push({ value: "business", text: "Affaire", blocked: isBlocked("business"), type: "text" });
        arr.push({ value: "proj_man", text: "Chef de projet", blocked: isBlocked("proj_man"), type: "text" });
        arr.push({ value: "detail", text: "Client", blocked: isBlocked("detail"), type: "text" });
        arr.push({ value: "step", text: "Etapes", blocked: isBlocked("step"), type: "text" });
        arr.push({ value: "rate", text: "Taux", blocked: isBlocked("rate"), type: "number" });
        arr.push({ value: "excl_tax_base", text: "Base HT", blocked: isBlocked("excl_tax_base"), type: "number" });
        arr.push({ value: "excl_tax_amount", text: "Montant HT", blocked: isBlocked("excl_tax_amount"), type: "number" });
        arr.push({ value: "focus_date", text: "Date Focus", blocked: isBlocked("focus_date"), type: "date" });
        arr.push({ value: "corrected_date", text: "Date corrigée", blocked: isBlocked("corrected_date"), type: "date" });
        arr.push({ value: "reason_for_delay", text: "Motif de retard", blocked: isBlocked("reason_for_delay"), type: "text" });
        arr.push({ value: "payment_condition", text: "Conditions de paiement", blocked: isBlocked("payment_condition"), type: "text" });
        arr.push({ value: "deposit", text: "Cautions", blocked: isBlocked("deposit"), type: "text" });
        arr.push({ value: "comments", text: "Commentaire", blocked: isBlocked("comments"), type: "text" });
        arr.push({ value: "vat", text: "TVA", blocked: isBlocked("vat"), type: "number" });
        arr.push({ value: "ttc_amount", text: "Montant TTC", blocked: isBlocked("ttc_amount"), type: "number" });
        
        var bill_field = p.filter_code === "billing_track_spares" ? "bill" : "bill_number";
        arr.push({ value: bill_field, text: "N° de facture", blocked: isBlocked(bill_field), type: "text" });
                
        arr.push({ value: "bill_date", text: "Date de facture", blocked: isBlocked("bill_date"), type: "date" });
        arr.push({ value: "calc_paym_date", text: "Date paie. calculée", blocked: isBlocked("calc_paym_date"), type: "date" });
        arr.push({ value: "payed", text: "Payé", blocked: isBlocked("payed"), type: "boolean" });
        arr.push({ value: "closed_biz", text: "Date de clôture aff.", blocked: isBlocked("closed_biz"), type: "date" });
        arr.push({ value: "eff_paym_date", text: "Date paie. effectif", blocked: isBlocked("eff_paym_date"), type: "date" });
        arr.push({ value: "to_bill", text: "A facturer", blocked: isBlocked("to_bill"), type: "boolean" });
        arr.push({ value: "del_note", text: "N° de BL (spares only)", blocked: isBlocked("del_note"), type: "text" });
        
        setFields(arr);
    }

    function isBlocked(field) {
        for (let i = 0; i < p.filter.length; i++) { if (p.filter[i].field === field) return true; };
        return false;
    }

    /**
     * 
     */
    const onEltChange = (e, k, field) => {
        var arr = [...p.filter];
        arr[k][field] = e.target.value;
        p.setFilter(arr);
    }

    const saveUpdate = (k) => {
        BusinessDao.updateBillFilter(p.filter[k], p.filter_code).then(
            () => { }, (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        )
    }

    /**
     * 
     * @param {*} k 
     * @returns 
     */
    const getCondition = (k) => {

        if (k) {
            return <Fragment>
                <option value="isNull">est vide</option>
                <option value="isNotNull">n'est pas vide</option>

                {(p.filter && p.filter[k].type_data !== "boolean") &&
                    <option value="equal">est égal à</option>
                }

                {(p.filter && p.filter[k].type_data === "text") &&
                    <Fragment>
                        <option value="startsWith">commence par</option>
                        <option value="contains">contient</option>
                        <option value="endsWith">se finit par</option>
                    </Fragment>
                }

                {(p.filter && (p.filter[k].type_data === "number" || p.filter[k].type_data === "date")) &&
                    <Fragment>
                        <option value="upper">supérieur à</option>
                        <option value="lower">inférieur à</option>
                    </Fragment>
                }

            </Fragment>
        } else {
            return <Fragment>
                <option value="">Choisissez...</option>
                <option value="isNull">est vide</option>
                <option value="isNotNull">n'est pas vide</option>

                {newFilter.type_data !== "boolean" &&
                    <option value="equal">est égal à</option>
                }

                {newFilter.type_data === "text" &&
                    <Fragment>
                        <option value="startsWith">commence par</option>
                        <option value="contains">contient</option>
                        <option value="endsWith">se finit par</option>
                    </Fragment>
                }

                {(newFilter.type_data === "number" || newFilter.type_data === "date") &&
                    <Fragment>
                        <option value="upper">supérieur à</option>
                        <option value="lower">inférieur à</option>
                    </Fragment>
                }

            </Fragment>
        }

    }

    /**
     * 
     * @param {*} e 
     * @param {*} field 
     */
    const onNewFilterChange = (e, field) => {
        var o = { ...newFilter };
        o[field] = e.target.value;

        if (field === "field" && o[field] !== "") o.type_data = fields[getKeyString(fields, "value", o[field])].type;
        setNewFilter(o);
    }

    /**
     * 
     */
    const addFilter = (e) => {
        e.preventDefault();
        p.setError();

        if (newFilter.field.trim() === "" || newFilter.operator.trim() === "") {
            p.setError("Saisissez le champ et la condition !");
            return;
        }

        if (newFilter.operator !== "isNull" && newFilter.operator !== "isNotNull" && newFilter.first_input.trim() === "") {
            p.setError("Saisissez la valeur de comparaison !");
            return;
        }

        var o = { ...newFilter };

        var arr = [...p.filter];
        arr.push(newFilter);
        p.setFilter(arr);

        BusinessDao.updateBillFilter(newFilter, p.filter_code).then(
            () => { }, (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        )

        o.field = "";
        o.first_input = "";
        o.operator = "";
        o.type_data = "";
        setNewFilter(o);

    }

    const deleteFilter = (k) => {
        BusinessDao.deleteBillFilter(p.filter[k], p.filter_code).then(
            () => { }, (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        )

        var arr = [...p.filter];
        arr = arrayRemoveElt(arr, k);
        p.setFilter(arr);


    }

    return <Fragment>

        <h5 className="mt-3">Filtre(s) saisi(s)</h5>
        {
            p.filter && p.filter.map((v, k) => {
                return <div className="input-group mb-3" key={"fi" + k}>
                    {
                        (v.field === "proj_man" && !isAdmin) &&
                        <span className="input-group-text">Filtre non modifiable: Chef de projet = {v.first_input}</span>
                    }

                    {
                        (v.field !== "proj_man" || (v.field === "proj_man" && isAdmin)) &&
                        <Fragment>
                            <span className="input-group-text">Champ</span>
                            <select className="form-select" value={v.field} style={{ maxWidth: "250px" }} disabled>
                                {fields.map((v, k) => {
                                    return <option key={"f" + k} value={v.value}
                                        disabled={v.blocked}>{v.text}</option>
                                })}
                            </select>

                            <span className="input-group-text">condition:</span>
                            <select className="form-select" value={v.operator} onBlur={() => saveUpdate(k)}
                                onChange={(e) => onEltChange(e, k, "operator")}>
                                {getCondition(k)}
                            </select>

                            {
                                (v.operator && v.operator !== "isNull" && v.operator !== "isNotNull") &&
                                <input type={v.type_data} value={v.first_input || ''} onBlur={() => saveUpdate(k)}
                                    onChange={(e) => onEltChange(e, k, "first_input")}
                                    className="form-control"></input>
                            }
                        </Fragment>
                    }



                    {
                        ( v.field !== "proj_man" || (v.field === "proj_man" && isAdmin) ) &&
                        <button className="btn btn-outline-danger" onClick={() => deleteFilter(k)}>Supprimer</button>
                    }


                </div>
            })
        }


        <h5 className="mt-5 text-center">Ajouter un filtre</h5>
        <form onSubmit={addFilter} style={{ maxWidth: "800px" }} className="ms-auto me-auto">
            <div className="input-group mb-3">
                <span className="input-group-text">Champ</span>
                <select className="form-select" value={newFilter.field} style={{ maxWidth: "250px" }}
                    onChange={(e) => onNewFilterChange(e, "field")}>
                    <option value="">Choisir...</option>
                    {fields.map((v, k) => {
                        return ((v.value !== "proj_man" && !v.blocked) ||
                            (v.value === "proj_man" && isAdmin && !v.blocked)) &&
                            <option key={"f" + k} value={v.value} data-type={v.type}
                                disabled={v.blocked}>{v.text}</option>
                    })}
                </select>

                {
                    newFilter.field !== "" && <Fragment>
                        <span className="input-group-text">condition:</span>
                        <select className="form-select" value={newFilter.operator}
                            onChange={(e) => onNewFilterChange(e, "operator")}>
                            {getCondition()}
                        </select>
                    </Fragment>
                }

                {
                    (newFilter.operator !== "" && newFilter.field !== ""
                        && newFilter.operator !== "isNull" && newFilter.operator !== "isNotNull") &&
                    <input type={newFilter.type_data} value={newFilter.first_input}
                        className="form-control" onChange={(e) => onNewFilterChange(e, "first_input")}></input>
                }

                <button type="submit" className="btn btn-success">Ajouter</button>

            </div>
        </form>
        <br></br>
        <div className="text-center mt-5">
            <button className="btn btn-outline-success" onClick={() => window.location.reload()}>Actualiser la liste</button>
        </div>

    </Fragment>
}

export default BillFilter;