import { Fragment, useEffect, useState } from "react";
import { ArticleAutoComplete } from "../../common/smallComponents.js/DesignedIpt";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import errorManagement from "../../services/errorManagement";
import BusinessDao from "../../services/machines/BusinessDao";
import MachineInfo from "../../services/machines/MachineInfo";
import Movement from "../../services/storehouse/Movement";

const MoveOutPc = (props) => {
    var o = {
        article: "", location: "", machine: "", business: "", secu: "", qty: "", s_ar: "",
        s_business: "", s_machine: "", type: "out", onBusiness: true, comment: ""
    };
    const [list, setList] = useState([{ ...o }]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [moving, setMoving] = useState(false);
    
    const user = JSON.parse(localStorage.getItem("user"));
    const canMoveSecu = user && user.roles.includes( "ROLE_SECU_STOCK" );

    const onArticleChoose = (e, k) => {
 
        if (e === null) {
            setError("Article non trouvé");
            scrollToTop();
            e = new Object();
            e.CDARL = "";
            e.ZN02PA = "";
            e.QTSTL = "";
        }

        var arr = [...list];
        arr[k].article = e.CDARL;
        arr[k].s_ar = e.CDARL;
        arr[k].secu = e.STSEL;
        arr[k].stock = parseFloat(e.QTSTL);
        arr[k].location = e.ZN04PA;
        arr[k].qty = "";
        if (parseFloat(e.QTSTL) <= 0 && arr[k].type === "out") arr[k].err = "Stock à 0, aucune sortie possible";

        cancelInput(k, "qty");

        setList(arr);
    }

    const onArticleChange = (v, k) => {
        var arr = [...list];
        arr[k].article = v;
        setList(arr);
    }

    /**
  * On business blur get the machines
  * 
  * @param {*} e 
  * @returns 
  */
    const getMachines = (e, key) => {
        var arr = [...list];
        var business = e.target.value;
        var last_letter = e.target.value.charAt(7).toUpperCase();

        if (arr[key].s_business.toUpperCase().trim() === business.toUpperCase().trim()) return;

        if (business === null || business === "") {
            delete arr[key].machList;
            setList(arr);
            return;
        }

        delete arr[key].err_biz;

        var oldVal = e.target.getAttribute("data-old");
        if (oldVal && oldVal === business) return;

        if (business.length < 8) {
            arr[key].err_biz = "Le code affaire comporte 8 caractères !";
            arr[key].business = "";
            arr[key].s_business = "";
            setList(arr);
            return;
        }

        business = business.substring(0, 7) + "0";

        setLoading(true);
        MachineInfo.getAllMachineInfo(business).then(
            (response) => {
                var machList = new Array();
                response.map(v => machList.push(v.no_machine));
                arr[key].business = business.toUpperCase().substring(0, 7) + last_letter;
                arr[key].s_business = business.toUpperCase().substring(0, 7) + last_letter;
                arr[key].machList = machList;
                setList(arr);
                setLoading(false);
            },
            () => {
                //Search in Focus if is maybe customer client
                BusinessDao.getBusinessInfoFocus(e.target.value).then(
                    (response2) => {
                        if(response2.NOCDE){
                            var machList = new Array();
                            machList.push("000000");
                            arr[key].machList = machList;
                            arr[key].business = response2.NOCDE;
                            setList(arr);
                            setLoading(false);
                        }else{
                            arr[key].err_biz = "Affaire introuvable ou sans machine";
                            arr[key].business = "err";
                            setList(arr);
                            setLoading(false);
                        }
                    }, (error) => {
                        arr[key].err_biz = "Affaire introuvable ou sans machine";
                        arr[key].business = "err";
                        setList(arr);
                        setLoading(false);
                    }
                )
            }
        );
    }

    /**
    * On choose machine in select
    */
    const onMachineSelect = (e) => {
        var key = e.target.getAttribute("data-key");
        var arr = [...list];
        var machine = e.target.value;

        arr[key].machine = machine;
        setList(arr);
    }

    /**
     * On blur machine get business
     */
    const getBusiness = (e) => {
        var key = e.target.getAttribute("data-key");
        var arr = [...list];
        var machine = e.target.value.toUpperCase().trim();

        if (arr[key].s_machine === machine) return;

        delete arr[key].err_mach;

        if (machine === null || machine === "") {
            delete arr[key].business;
            setList(arr);
            return;
        }

        if (machine.length < 4) {
            arr[key].err_mach = "Le code machine comporte 4 caractères !";
            setList(arr);
            return;
        }

        setLoading(true);

        MachineInfo.getAllMachineInfo(machine).then(
            (response) => {
                arr[key].business = response[0].id_aff.substring(0, 7) + prompt("Le code affaire se termine avec quelle lettre ?", "H");
                arr[key].s_business = arr[key].business;
                arr[key].machine = machine;
                arr[key].s_machine = machine;
                setList(arr);
                setLoading(false);
            },
            (error) => {
                arr[key].machine = "";
                arr[key].s_machine = "";
                arr[key].business = "";
                arr[key].s_business = "";
                arr[key].err_mach = "Code machine introuvable !";
                setList(arr);
                setLoading(false);
            }
        );
    }

    function cancelInput(row, field) {
        var table = document.getElementsByTagName("table")[0].rows;
        row = parseInt(row) + 1;
        table[row].getElementsByClassName(field)[0].value = "";
    }

    /**
     * 
     */
    const addRow = () => {
        var arr = [...list];
        setError();

        if( lastIsOk() ) {
            arr.push({ ...o });
            setList(arr);
        }
    }

    const move = async () => {
        var arr = [...list];
        
        if( lastIsOk() ){
            setMoving(true);
            for (let i = 0; i < list.length; i++) {
                await Movement.movePC(list[i]).then(
                    (response) => {
                        arr[i].msg = response;
                        setList(arr);
                    },
                    (error) => {
                        arr[i].msg = errorManagement.handleError(props, error);
                        setList(arr);
                    }
                )
            }
    
            setMsg("Mouvements terminés.");
            setMoving(false);
        }

    }

    function lastIsOk(){
        var arr = [...list];
        var last = arr[arr.length - 1];

        if (last.err || last.err_biz || last.err_mach || last.err_qty) {
            var err = "";
            if (last.err) err += last.err + "<br>";
            if (last.err_biz) err += last.err_biz + "<br>";
            if (last.err_mach) err += last.err_mach + "<br>";
            if (last.err_qty) err += last.err_qty + "<br>";
            setError(err);
            scrollToTop();
            return false;
        } else if (last.article.trim() === "" || 
                    ( last.onBusiness && ( last.machine.trim() === "" || last.business.trim() === "" ) )
                    || last.qty.toString().trim() === "") {
            setError("Tous les champs sont obligatoires pour passer à la suite");
            scrollToTop();
            return false;
        }

        return true;
    }

    /**
     * 
     * @param {*} e 
     * @param {*} k 
     */
    const checkQty = (e, k) => {
        var arr = [...list];
        var qty = parseFloat(e.target.value);
        var secu = parseFloat(arr[k].stock) - parseFloat(arr[k].secu);
        delete arr[k].err_qty;

        if (qty > arr[k].stock && arr[k].type === "out") {
            arr[k].err_qty = "La quantité dispo en stock est de " + arr[k].stock + " unité(s), vous ne pouvez pas mouvementer plus";
            e.target.value = "";
            arr[k].qty = "";
        }else if( qty > secu && !canMoveSecu ){
            arr[k].err_qty = "Vous n'êtes pas autorisé à piocher dans le stock de sécu. Qté à max à sortir: " + Math.max(0, secu);
            e.target.value = "";
            arr[k].qty = "";
        }else if (qty <= 0) {
            arr[k].err_qty = "Le minimum à mouvementer est 1 unité";
            arr[k].qty = "";
        } else {
            arr[k].qty = qty;
        }

        setList(arr);
    }

    const changeType = (e, k, value) => {
        var arr = [...list];
        arr[k].type = value;

        if( value === "out" && parseFloat(arr[k].stock) <= 0 ){
            arr[k].err = "Stock à 0, aucune sortie possible";
        }else{
            arr[k].err = arr[k].err.replace("Stock à 0, aucune sortie possible", "");
        }

        setList(arr);
    }

    const onBusinessMoveChange = (e, k) => {
        var onBusiness = e.target.checked;
        var arr = [...list];
        arr[k].onBusiness = onBusiness;
        arr[k].business=null;
        arr[k].machine=null;
        setList(arr);
    }

    const changeComment  = (k, value) => {
        var arr = [...list];
        arr[k].comment = value;
        setList(arr);
    }

    return <Fragment>
        <h4 className="text-center">Mouvements de pièces</h4>
        {error && <ErrorMessage error={error} />}
        {msg && <SuccessMessage msg={msg} />}
        
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr className="table-info text-center fw-bold">
                        <th scope="col">Mvt. sur affaire</th>
                        <th scope="col">Code Article</th>
                        <th scope="col">Renvoi</th>
                        <th scope="col">N° de série de machine</th>
                        <th scope="col">Affaire</th>
                        <th scope="col">Qté en stock</th>
                        <th scope="col">Stock de sécu</th>
                        <th scope="col">Entrée/sortie</th>
                        <th scope="col">Qté à mouvementer</th>
                        <th scope="col">Commentaires</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((v, k) => {
                            return <tr key={"move" + k}>
                                <td className="text-center">
                                <input className="form-check-input" type="checkbox" checked={v.onBusiness}
                                    onChange={(e)=>onBusinessMoveChange(e, k)}/>
                                </td>
                                <th scope="row">
                                <ArticleAutoComplete value={v.article} onChooseArt={(e) => onArticleChoose(e, k)}
                                                setValue={(val) => onArticleChange(val, k)} searchDetail={true} />
                                </th>
                                <td>
                                    {v.location && v.location}
                                </td>
                                <td>
                                    {
                                        (v.onBusiness && v.machList) &&
                                        <select className="form-select" data-key={k} onChange={onMachineSelect} value={v.machine} required>
                                            <option value="">Choisissez</option>
                                            <option value="SAV">SAV</option>
                                            {
                                                v.machList.map((v2, k2) => {
                                                    return <option key={v2 + "_" + k2 + "_" + k} value={v2}>{v2}</option>
                                                })
                                            }
                                        </select>
                                    }
                                    {
                                        (v.onBusiness && !v.machList) &&
                                        <input className="form-control machine" data-key={k} data-field="machine"
                                            onBlur={getBusiness} defaultValue={v.machine} required></input>
                                    }
                                </td>
                                <td>
                                    {v.onBusiness && 
                                    <input className="form-control business" placeholder="N° affaire" defaultValue={v.business}
                                        onBlur={(e) => getMachines(e, k)}></input>
                                    }
                                </td>
                                
                                <td className="text-center">{v.stock && v.stock}</td>
                                <td className="text-center">{v.secu}</td>
                                <td className="text-center" style={{ width: "200px" }} >
                                    <select className="form-select" value={v.type}
                                    onChange={(e) => changeType(e, k, e.target.value)}>
                                        <option value="out">Sortie</option>
                                        <option value="in">Entrée</option>
                                    </select>
                                </td>
                                <td>
                                    <input type="number" className="form-control qty" placeholder="Qté"
                                        min={1} max={v.stock || 1} onBlur={(e) => checkQty(e, k)}></input>
                                </td>
                                <td style={{ width: "300px" }}
                                    className={(v.err || v.err_biz || v.err_mach || v.err_qty || ( v.msg && v.msg.toUpperCase().indexOf("ERREUR") !== -1 ) ) ? "bg-danger text-white"
                                        : (v.msg && v.msg.indexOf("SORTIE PC") !== -1) ? "bg-success text-white" : ""}>
                                    <div>
                                        {v.err && <p>{v.err}</p>}
                                        {v.err_biz && <p>{v.err_biz}</p>}
                                        {v.err_mach && <p>{v.err_mach}</p>}
                                        {v.err_qty && <p>{v.err_qty}</p>}
                                        {v.msg && <p dangerouslySetInnerHTML={{__html: v.msg}}></p>}
                                    </div>
                                    <div>
                                        <div className="form-floating">
                                            <textarea className="form-control" placeholder="xxx"
                                                onBlur={(e) => changeComment(k, e.target.value)}></textarea>
                                            <label for="floatingTextarea">Commentaire du mvt</label>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
            {
                (!moving && !msg) && <Fragment>
                    <img src="/common/add-logo.png" className="cursor-pointer" onClick={addRow}></img>
                    <div className="text-center">
                        <button className="btn btn-success" onClick={move}>Mouvementer</button>
                    </div>
                </Fragment>
            }

            {
                moving && <div>
                    <ButtonWaiting /> Mouvement en cours...
                </div>
            }


        </div>
    </Fragment>

}

export default MoveOutPc;