import { Fragment, useEffect, useState } from "react";
import ticketsDao from "../../../services/qhse/ticketsDao";
import ErrorMessage from "../../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../../common/smallComponents.js/WaitingRoundSnippers";
import errorManagement from "../../../services/errorManagement";
import { ButtonWaiting, getHeight, scrollToTop } from "../../../functions/PageAnimation";
import CustomToolTip, { CustomToolTipInfo } from "../../../common/smallComponents.js/CustomToolTip";
import ShareFileDao from "../../../services/common/ShareFileDao";
import { forceString } from "../../../functions/StrFunctions";

const NewProductForm = (p) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const isQhse = (user.roles.includes("ROLE_QHSE") || user.roles.includes("ROLE_ADMIN"));
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const emptyForm = {
        prodName: "", supplier: "", hasQuote: "", quoteId: -1, physicalState: "", hasFds: "", fdsId: -1,
        useDate: "", prodUse: "", yearConso: "", dailyUse: "", freqUse: "", workstation: "",
        salaryNb: 0, desc: "", forseeExp: "", riskFds: "", protectionColl: "", epi: "", checklist: ""
    };

    const emptyObj = {
        qhse_validation_date: "", qhse_validation_user: "", qhse_com: "",
        doct_validation_date: "", doct_name: "", doct_com: "", author: "", id: -1, signature: null,
        doc_signature: null
    }

    const [form, setForm] = useState({ ...emptyForm });
    const [infos, setInfos] = useState({ ...emptyObj });

    function getSpanCss() {
        return { width: "300px", whiteSpace: "normal" }
    }

    const updateField = (e) => {
        var field = e.target.getAttribute("name");
        var val = e.target.value;

        var o = { ...form };
        o[field] = val;
        setForm(o);
    }

    const save = (e) => {
        e.preventDefault();

        var f = JSON.stringify(form);

        var id = p.selectedForm ? p.selectedForm.id : -1;

        setLoading(true);
        ticketsDao.saveNewChemicalProduct(id, f, infos).then(
            (res) => {
                window.location.reload();
                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(p, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }

    useEffect(() => {
        if (p.selectedForm) {
            setForm(JSON.parse(p.selectedForm.form))
            setInfos({
                qhse_validation_date: p.selectedForm.qhse_validation_date,
                qhse_validation_user: p.selectedForm.qhse_validation_user,
                qhse_com: p.selectedForm.qhse_com || "",
                doct_validation_date: p.selectedForm.doct_validation_date,
                doct_name: p.selectedForm.doct_name,
                doct_com: p.selectedForm.doct_com || "",
                author: p.selectedForm.author,
                id: p.selectedForm.id,
                signature: p.selectedForm.qhse_validation_date ? user.signature : null,
                doc_signature: p.selectedForm.doct_validation_date ? user.signature : null
            })
        }
    }, []);

    const setVisa = (e) => {
        var o = { ...infos };

        if (e.target.checked) {
            o.signature = user.signature;
        } else {
            o.signature = null;
        }

        setInfos(o);
    }

    const newForm = () => {
        setForm({ ...emptyForm });
        setInfos({ ...emptyObj });
        p.setselectedForm();
    }

    const updateInfos = (e, field) => {
        var o = { ...infos };
        o[field] = e.target.value;
        setInfos(o);
    }

    const uploadFile = (e, field) => {
        var o = { ...form };

        if (e.target.length === 0) {
            var id, url;
            if (field === "fds") {
                id = o.fdsId;
                url = o.fdsUrl;
            } else if (field === "quote") {
                id = o.quoteId;
                url = o.quoteUrl;
            }

            ShareFileDao.deleteDocsByType(id, url).then(
                (res) => {

                    if (field === "fds") {
                        o.fdsId = "";
                        delete o.fdsUrl;
                    } else if (field === "quote") {
                        o.quoteId = "";
                        delete o.quoteUrl;
                    }

                    setForm(o);
                }, (error) => {
                    console.error(error)
                })

        } else {
            ShareFileDao.uploadByType("new_prod", form.prodName + ": " + field, e.target.files[0]).then(
                (res) => {

                    if (field === "fds") {
                        o.fdsId = res.id;
                        o.fdsUrl = res.url;
                    } else if (field === "quote") {
                        o.quoteId = res.id;
                        o.quoteUrl = res.url;
                    }

                    setForm(o);
                }, (error) => {
                    console.error(error)
                }
            )
        }
    }

    const updateCheckList = (e) => {
        var o = {...form};
        var chk = o.checklist || "";
        if( e.target.checked ){
            chk += e.target.value + ";";
        }else{
            chk = chk.replace(e.target.value + ";", "")
        }

        o.checklist = chk;
        setForm(o);
    }

    return <div>
        <h5 className="text-center">Validation de l'utilisation d'un nouveau produit</h5>
        {p.selectedForm && <div className="text-center">
            <a href="#" onClick={newForm}>Nouveau formulaire</a>
        </div>}
        {error && <ErrorMessage error={error} errorState={setError} />}
        {loading && <WaitingRoundSnippers />}

        <div className="table-responsive ms-auto me-auto" style={{ width: "1500px" }}>
            <form onSubmit={save}>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={getSpanCss()}>Nom du produit</span>
                    <input type="text" className="form-control" name="prodName" value={form.prodName}
                        onChange={updateField} required />
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={getSpanCss()}>Fournisseur</span>
                    <input type="text" className="form-control" name="supplier" value={form.supplier}
                        onChange={updateField} required />
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={getSpanCss()}>Avez-vous un devis ?</span>

                    <div className="input-group-text">
                        <input className="form-check-input mt-0" type="radio" value="yes"
                            name="hasQuote" onChange={updateField} checked={form.hasQuote === "yes"} required />
                    </div>
                    <input type="text" className="form-control" value="Oui" readOnly />

                    <div className="input-group-text">
                        <input className="form-check-input mt-0" type="radio" value="no"
                            name="hasQuote" onChange={updateField} checked={form.hasQuote === "no"} required />
                    </div>
                    <input type="text" className="form-control" value="Non" readOnly />

                    {form.hasQuote === "yes" && <Fragment>
                        {!p.selectedForm && <input type="file" className="form-control"
                            onChange={(e) => uploadFile(e, "quote")} required />}

                        {p.selectedForm &&
                            <a className="btn btn-success" target="_blank"
                                href={ShareFileDao.getUrlToDisplayDocByType(form.quoteId)}>Voir</a>}
                    </Fragment>}
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={getSpanCss()}>Etat physique</span>
                    <select className="form-select" name="physicalState" value={form.physicalState}
                        onChange={updateField} required>
                        <option value="">...</option>
                        <option value="Aérosol">Aérosol</option>
                        <option value="Gaz">Gaz</option>
                        <option value="Liquide">Liquide</option>
                        <option value="Poudre">Poudre</option>
                        <option value="Solide">Solide</option>
                    </select>
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={getSpanCss()}>Avez-vous une FDS ?</span>
                    <div className="input-group-text">
                        <input className="form-check-input mt-0" type="radio" value="yes"
                            name="hasFds" onChange={updateField} checked={form.hasFds === "yes"} required />
                    </div>
                    <input type="text" className="form-control" value="Oui" readOnly />

                    <div className="input-group-text">
                        <input className="form-check-input mt-0" type="radio" value="no"
                            name="hasFds" onChange={updateField} checked={form.hasFds === "no"} required />
                    </div>
                    <input type="text" className="form-control" value="Non" readOnly />

                    {form.hasFds === "yes" && <Fragment>
                        {!p.selectedForm && <input type="file" className="form-control"
                            onChange={(e) => uploadFile(e, "fds")} required />}
                        {p.selectedForm &&
                            <a className="btn btn-success" target="_blank"
                                href={ShareFileDao.getUrlToDisplayDocByType(form.fdsId)}>Voir</a>}
                    </Fragment>}
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={getSpanCss()}>Usage du produit
                        <CustomToolTipInfo props={{ tooltipText: "A quoi sert ce produit ?" }} /></span>
                    <input type="text" className="form-control" name="prodUse" value={form.prodUse}
                        onChange={updateField} required />

                    <span className="input-group-text">Date de début d'utilisation</span>
                    <input type="date" className="form-control" name="useDate" value={form.useDate}
                        onChange={updateField} required />
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={getSpanCss()}>Consommation annuelle envisagée </span>
                    <input type="text" className="form-control" name="yearConso" value={form.yearConso}
                        onChange={updateField} placeholder="Précisez l'unité kg, L, m²" required />

                    <span className="input-group-text">Quantité jounalière</span>
                    <input type="text" className="form-control" name="dailyUse" value={form.dailyUse}
                        onChange={updateField} required />

                    <span className="input-group-text">Fréquence d'utilisation prévue </span>

                    <select className="form-select" name="freqUse" value={form.freqUse}
                        onChange={updateField} required>
                        <option value="">...</option>
                        <option value="Journalière">Journalière</option>
                        <option value="Hebdomadaire">Hebdomadaire</option>
                        <option value="Mensuelle">Mensuelle</option>
                        <option value="Annuelle">Annuelle</option>
                    </select>
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={getSpanCss()}>Poste(s) de travail concerné(s)</span>
                    <input type="text" className="form-control" name="workstation" value={form.workstation}
                        onChange={updateField} required />

                    <span className="input-group-text">Nombre de salariés concernés</span>
                    <input type="number" className="form-control" name="salaryNb" value={form.salaryNb}
                        onChange={updateField} required />
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={getSpanCss()}>Description du mode d'utilisation</span>
                    <textarea className="form-control" name="desc" value={form.desc}
                        onChange={updateField} required></textarea>
                </div>

                {(isQhse && p.selectedForm) &&
                    <Fragment>
                        <div className="input-group mb-3">
                            <span className="input-group-text" style={getSpanCss()}>Voies d'exposition prévisibles</span>
                            <select className="form-select" name="forseeExp" value={form.forseeExp}
                                onChange={updateField} required>
                                <option value="">...</option>
                                <option value="Cutanée">Cutanée</option>
                                <option value="Inhalation">Inhalation</option>
                                <option value="Occasionnel">Occasionnel</option>
                                <option value="Permanent">Permanent</option>
                            </select>
                        </div>

                        <div className="input-group mb-3">
                            <span className="input-group-text" style={getSpanCss()}>Phrases de risque ou Mentions de danger (rubrique 2 FDS)</span>
                            <textarea className="form-control" name="riskFds" value={form.riskFds} required
                                onChange={updateField}></textarea>
                        </div>

                        <div className="input-group mb-3">
                            <span className="input-group-text" style={getSpanCss()}>Protection collective aux postes de travail</span>
                            <textarea className="form-control" name="protectionColl" value={form.protectionColl} required
                                onChange={updateField}></textarea>
                        </div>

                        <div className="input-group mb-3">
                            <span className="input-group-text" style={getSpanCss()}>Equipements de protections individuelles</span>
                            <textarea className="form-control" name="epi" value={form.epi} required
                                onChange={updateField}></textarea>
                        </div>


                        <div className="input-group mb-3">
                            <span className="input-group-text" style={getSpanCss()}>Avis du responsable sécurité</span>
                            <textarea className="form-control" value={infos.qhse_com} required
                                onChange={(e) => updateInfos(e, "qhse_com")}></textarea>
                        </div>

                        {(user.signature && !infos.signature) &&
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" onChange={setVisa} />
                                <label className="form-check-label">Apposer le visa</label>
                            </div>}

                        {infos.signature && <img src={infos.signature} />}

                        {!user.signature && <div className="fw-bold">
                            Veuillez créer une signature dans votre <a href="/profile">profil</a> pour approuver ce
                            formulaire
                        </div>}


                        <h5 className="mt-3">Check list</h5>

                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="fds" onChange={updateCheckList} 
                                checked={ forceString( form.checklist ).indexOf("fds") !== -1  }/>
                            <label className="form-check-label">FDS intégrée dans l'intranet</label>
                            </div>

                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="ndp" onChange={updateCheckList} 
                                checked={ forceString( form.checklist ).indexOf("ndp") !== -1  } />
                            <label className="form-check-label">NDP intégrée dans l'intranet</label>
                        </div>

                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="training" onChange={updateCheckList} 
                                checked={ forceString( form.checklist ).indexOf("training") !== -1  } />
                            <label className="form-check-label">Formation nouveau produit réalisée</label>
                        </div>

                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="tags" onChange={updateCheckList} 
                                checked={ forceString( form.checklist ).indexOf("tags") !== -1  } />
                            <label className="form-check-label">Etiquettes créées</label>
                        </div>

                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="prev" onChange={updateCheckList} 
                                checked={ forceString( form.checklist ).indexOf("prev") !== -1  } />
                            <label className="form-check-label">Moyens de préventions</label>
                        </div>

                    </Fragment>
                }

                <div className="text-center mb-3">
                    <button className="btn btn-success" disabled={loading}>
                        {loading && <ButtonWaiting />}
                        {p.selectedForm ? "Mettre à jour" : "Soumettre"}</button>
                </div>
            </form>
        </div>
    </div>

}

export default NewProductForm;