import { getKey } from "../../functions/ArrayFunctions";

export const getDistinctSubset = (se) => {

    var arr = new Array();
    var o, key;

    se.map(v => {
        key = getKey(arr, "chapter", v.chapter);
        if (key === null) {

            o = {
                "chapter": v.chapter,
                "label": v.chapter_label,
                "conform": v.conform === 1 ? 1 : 0,
                "no_conform": v.conform === 0 ? 1 : 0,
                "cpt": 1
            }
            arr.push(o);
        } else {
            arr[key].cpt++;
            if (v.conform === 1) arr[key].conform++;
            if (v.conform === 0) arr[key].no_conform++;
        }
    })

    return arr;
};