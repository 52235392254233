import { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import bomOrderDao from "../../services/purchase/bomOrderDao";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import Moment from "moment";

const NeedDates = (props) => {
    const [list, setList] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        bomOrderDao.getNeedDatesError().then(
            (response) => {
                setList(response);
                setLoading(false);
            },
            (error) => {
            }
        );
    }, []);

    return (
        <Fragment>
            <h4 className="text-center">Date de besoins non-alignés au planning général</h4>
            <br></br>
            {loading && <WaitingRoundSnippers />}

            {
                list &&
                <div className="col-8 offset-2">
                    <table className="table">
                        <thead className="fixed-header table-info">
                            <tr>
                                <th scope="col">Affaire</th>
                                <th scope="col">Machine</th>
                                <th scope="col">Date du besoin</th>
                                <th scope="col">Semaine du besoin</th>
                                <th scope="col">Semaine de sortie plannifiée</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.map(
                                    (v, k) =>
                                        <tr key={"r_" + k}>
                                            <th scope="row">{v.affaire}</th>
                                            <td>{v.fonction}</td>
                                            <td>{Moment(v.delai_besoin).format("DD/MM/YYYY")}</td>
                                            <td>{v.need_date}</td>
                                            <td>{v.stock_date}</td>
                                        </tr>
                                )
                            }

                        </tbody>
                    </table>
                </div>
            }

        </Fragment>
    )
}

export default NeedDates;