import { useState } from "react";
const PartFive = (p) => {
    const [dp] = useState(p.dp);

    return <div className="table-responsive">
        <div className="table-responsive">
            <table className="table businessCardStyle">
                <thead>
                    <tr className="bg-dark bg-gradient text-white text-center rounded">
                        <th scope="col">Colle</th>
                        <th scope="col">Généralités</th>
                        <th scope="col">Particularités machines</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="border-end text-center" style={{ width: "33.33%" }}>

                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" value={p.machineInfo.fourn_colle} placeholder="xxx"
                                    onChange={p.onMachineInfoType} onBlur={p.updateMachineTable}
                                    data-field="fourn_colle" data-type="text" readOnly={p.readOnly} />
                                <label>Fournisseur</label>
                            </div>

                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" value={p.machineInfo.ref_colle} placeholder="xxx"
                                    onChange={p.onMachineInfoType} onBlur={p.updateMachineTable}
                                    data-field="ref_colle" data-type="text" readOnly={p.readOnly} />
                                <label>Référence</label>
                            </div>


                        </td>

                        <td className="border-end text-center" style={{ width: "33.33%" }}>
                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={p.machineInfo.produits_mach} placeholder="xxx"
                                        onChange={p.onMachineInfoType} onBlur={p.updateMachineTable}
                                        data-field="produits_mach" data-type="text" readOnly={p.readOnly} />
                                    <label>Produits</label>
                                </div>

                                <div className="form-floating mb-3" style={{ marginLeft: "10px" }}>
                                    <input type="text" className="form-control" value={p.machineInfo.no_ligne} placeholder="xxx"
                                        onChange={p.onMachineInfoType} onBlur={p.updateMachineTable}
                                        data-field="no_ligne" data-type="text" readOnly={p.readOnly} />
                                    <label>N° de ligne</label>
                                </div>
                            </div>

                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={p.machineInfo.poids_max_colis} placeholder="xxx"
                                        onChange={p.onMachineInfoType} onBlur={p.updateMachineTable}
                                        data-field="poids_max_colis" data-type="text" readOnly={p.readOnly} />
                                    <label>Poids max colis</label>
                                </div>

                                <div className="form-floating mb-3" style={{ marginLeft: "10px" }}>
                                    <input type="text" className="form-control" value={p.machineInfo.mach_amont_aval} placeholder="xxx"
                                        onChange={p.onMachineInfoType} onBlur={p.updateMachineTable}
                                        data-field="mach_amont_aval" data-type="text" readOnly={p.readOnly} />
                                    <label>Machine amont/aval</label>
                                </div>
                            </div>
                        </td>

                        <td className="border-end text-center" style={{ width: "33.33%" }}>
                            <div className="form-floating mb-3">
                                <textarea type="text" className="form-control" style={{minHeight: "130px"}}
                                    value={p.machineInfo.particularites_mach} placeholder="xxx"
                                    onChange={p.onMachineInfoType} onBlur={p.updateMachineTable}
                                    data-field="particularites_mach" data-type="text" readOnly={p.readOnly} />
                                <label>Particularités</label>
                            </div>
                        </td>

                    </tr>

                </tbody>
            </table>

        </div>
    </div>
}

export default PartFive;