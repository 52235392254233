import { useState } from "react";
import MachineInfo from "../../../../services/machines/MachineInfo";
import errorManagement from "../../../../services/errorManagement";
import { scrollToTop } from "../../../../functions/PageAnimation";
const PartThree = (p) => {
    const [dp] = useState(p.dp);

    const onSelectChange = (e) => {
        saveChange(e);
    }

    const onInputType = (e) => {
        var field = e.target.getAttribute("data-field");
        var value = e.target.value;

        var o = { ...p.machineInfo };
        o[field] = value;
        p.setMachineInfo(o);
    }

    const onInputChange = (e) => {
        saveChange(e);
    }

    const saveChange = (e) => {
        if( p.readOnly ) return;
        var field = e.target.getAttribute("data-field");
        var value = e.target.value;

        var o = { ...p.machineInfo };
        o[field] = value;
        p.setMachineInfo(o);

        MachineInfo.updateLid(p.machineInfo.no_machine, field, value).then(
            () => { },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        )
    }

    return <div className="table-responsive">
        <div className="table-responsive">
            <table className="table businessCardStyle">
                <thead>
                    <tr className="bg-dark bg-gradient text-white text-center rounded">
                        <th scope="col" colSpan={3}>Coiffe</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="border-end text-center" style={{ width: "50%" }}>
                            <div className="form-floating mb-3">
                                <select className="form-select" data-field="cannelure_coiffe" disabled={p.readOnly}
                                    value={p.machineInfo.cannelure_coiffe} onChange={onSelectChange}>
                                    <option value=""></option>
                                    {
                                        p.dp && p.dp.map((v, k2) => {
                                            if (v.field === "cannelure")
                                                return <option key={"box1Type" + k2} value={v.label}>{v.label}</option>
                                        })
                                    }
                                </select>
                                <label for="Fabricant">Cannelure</label>
                            </div>

                            <div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={p.machineInfo.hauteur_rabat_coiffe}
                                        placeholder="xxx" data-field="hauteur_rabat_coiffe" readOnly={p.readOnly}
                                        onBlur={onInputChange} onChange={onInputType} />
                                    <label>Rabats</label>
                                </div>
                            </div>

                            <div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={p.machineInfo.bande_arrachement}
                                        placeholder="xxx" data-field="bande_arrachement" readOnly={p.readOnly}
                                        onBlur={onInputChange} onChange={onInputType} />
                                    <label>Bande d'arrachement</label>
                                </div>
                            </div>



                        </td>

                        <td className="border-end text-center" style={{ width: "50%" }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "50%" }}>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" value={p.machineInfo.ech_l}
                                            placeholder="xxx" data-field="ech_l" readOnly={p.readOnly}
                                            onBlur={onInputChange} onChange={onInputType} />
                                        <label>Echancrure L</label>
                                    </div>
                                </div>
                                <div style={{ width: "50%" }}>
                                    <div className="form-floating mb-3" style={{ marginLeft: "10px" }} >
                                        <input type="text" className="form-control" value={p.machineInfo.ech_h}
                                            placeholder="xxx" data-field="ech_h" readOnly={p.readOnly}
                                            onBlur={onInputChange} onChange={onInputType} />
                                        <label>Echancrure H</label>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="form-floating mb-3">
                                    <textarea type="text" className="form-control" value={p.machineInfo.ech_com}
                                        placeholder="xxx" data-field="ech_com" readOnly={p.readOnly}
                                        onBlur={onInputChange} onChange={onInputType} style={{minHeight: "100px"}} />
                                    <label>Commentaire echancrure</label>
                                </div>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={2}>
                            <div>
                                <div className="form-floating mb-3">
                                    <textarea type="text" className="form-control" value={p.machineInfo.autre_particularites}
                                        placeholder="xxx" data-field="autre_particularites" readOnly={p.readOnly}
                                        onBlur={onInputChange} onChange={onInputType} style={{minHeight: "100px"}}/>
                                    <label>Particularités</label>
                                </div>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>

        </div>
    </div >
}

export default PartThree;