import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import CheckList from "../../services/customClient/CheckList";
import errorManagement from "../../services/errorManagement";
import { confirmAlert } from "react-confirm-alert";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";

const MaintenanceCheckList = (props) => {
    const { t } = useTranslation(['check-list', 'common']);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [list, setList] = useState();
    const [machine, setMachine] = useState();
    const [machineInfo, setMachineInfo] = useState();
    const [typeMachine, setTypeMachine] = useState();
    const [currentDate, setCurrentDate] = useState();
    const [canCreate, setCanCreate] = useState(true);

    useEffect(() => {
        setCurrentDate(new Date().toJSON().slice(0, 10));
    }, []);

    const onSearch = (e) => {
        e.preventDefault();
        setError("");
        setLoading(true);
        var machine = e.target.machine.value;

        if (machine.trim() === "") {
            setError(t("fill in all"));
            return;
        }

        CheckList.getCheckListList(machine).then(
            (response) => {
                setMachine(response.machine.no_machine);
                setTypeMachine(response.machine.typ_config);
                setMachineInfo(response.machine);
                setList(response.list);
                
                if( response.list.length > 0 )
                    response.list.map(v => { if (v.creation_date === currentDate) setCanCreate(false) });

                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        );

    }

    const newForm = () => {
        setLoading(true);

        CheckList.createForm(machineInfo).then(
            (response) => {
                window.location.href = "/customer-client/check-list/maintenance?machine=" + response.machine
                    + "&date=" + response.date;
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        );
    }

    const deleteForm = (e) => {
        var key = e.target.getAttribute("data-key");
        var machine = list[key].mach_nb.trim();
        var date = list[key].creation_date;

        confirmAlert({
            title: "Confirmation",
            message: "Etes-vous sûr(e) de vouloir supprimer le formulaire de la machine " 
                + machine + " du " + date + " ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setLoading(true);
                        CheckList.deleteForm(machine, date).then(
                            (response) => {
                                var arr = [...list];
                                arr = arrayRemoveElt(arr, key);
                                setList(arr);
                                setLoading(false);
                            },
                            (error) => {
                                setError(errorManagement.handleError(props, error));
                                setLoading(false);
                                scrollToTop();
                            }
                        );
                    }
                },
                {
                    label: "Non"
                }
        ]});
    }

    return (
        <div>
            <h1 className="text-center">{t('list of maintenance')}</h1>

            <form onSubmit={onSearch}>
                <div className="col-8 offset-2">
                    <div className="input-group mb-3">
                        <input type="text" className="form-control text-center fw-bold" name="machine" placeholder="Machine" />
                        <button type="submit" className="btn btn-outline-success">{t('search', { ns: "common" })}</button>
                    </div>
                </div>
            </form>

            {error && <ErrorMessage error={error} errorState={setError} />}
            {loading && <WaitingRoundSnippers />}

            {
                machine &&
                <div className="col-8 offset-2">
                    <h4 className="text-center">{t('type of machine') + " " + typeMachine}</h4>

                    <table className="table text-center">
                        <thead>
                            <tr className="table-info">
                                <th scope="col">Date</th>
                                <th scope="col">{t('user', { ns: "common" })}</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.map(
                                    (v, k) => {
                                        return (<tr key={k}>
                                            <td>{v.creation_date}</td>
                                            <td>{v.controller}</td>
                                            <td>
                                                <a className="btn btn-success" target="_blank" disabled={loading}
                                                    href={"/customer-client/check-list/maintenance?"
                                                        + "machine=" + machine + "&date=" + v.creation_date}>
                                                            {loading && <ButtonWaiting />}
                                                            {t('continue')}
                                                </a>
                                            </td>
                                            <td>
                                                <button className="btn btn-outline-danger" disabled={loading}
                                                    href="#" onClick={deleteForm} data-key={k}>
                                                        {loading && <ButtonWaiting />}
                                                        {t('delete')}
                                                </button>
                                            </td>
                                        </tr>)
                                    }
                                )
                            }

                        </tbody>
                    </table>
                    <br></br>

                    {
                        canCreate &&
                        <div className="text-center">
                            <button className="btn btn-outline-secondary"
                                onClick={newForm} disabled={loading}>
                                {loading && <ButtonWaiting />}
                                {t('new check list')}
                            </button>
                        </div>
                    }

                </div>
            }


        </div>
    );

}

export default MaintenanceCheckList;