import { useState } from "react";
import { Fragment } from "react";
import Movement from "../../services/storehouse/Movement";
import errorManagement from "../../services/errorManagement";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import { ButtonWaiting } from "../../functions/PageAnimation";

const StoreageReceiption = (props) => {
    const row = {
        business: "",
        machine: "",
        subcontractor: "",
        projectManager: "",
        outStock: "",
        mes: "",
        status: "",
        loading: false
    };

    const [data, setData] = useState([{ ...row }]);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();

    const getMachineInfo = (e) => {
        var key = e.target.getAttribute("data-key");
        var value = e.target.value;
        setError();

        if (value === "") return;

        var arr = [...data];
        arr[key].loading = true;
        setData(arr);

        Movement.getMachineInfoAndMes(value).then(
            (response) => {
                if( response === "" ){
                    setError("Machine n°"+ value +" inconnu")
                }else{
                    arr = [...data];
                    arr[key].machine = response.no_machine;
                    arr[key].business = response.affaire;
                    if (response.manufacturing !== "B+"){
                        if(response.manufacturing === "Lundgren"){
                            arr[key].subcontractor = "AMME";
                        }else{
                            arr[key].subcontractor = response.manufacturing;
                        }
                    } 
                    arr[key].projectManager = response.chef_proj;
                    arr[key].mes = response.mes;
                    arr[key].outStock = response.storehouse;
                    arr[key].hasKit = response.hasKit;
                }
                
                arr[key].loading = false;
                setData(arr);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                arr[key].loading = false;
                setData(arr);
            }
        );
    }

    const onMachineChange = (e) => {
        var key = e.target.getAttribute("data-key");
        var value = e.target.value;
        var arr = [...data];
        arr[key].machine = value;
        setData(arr);
    }

    const addRow = () => {
        var arr = [...data];
        arr.push({ ...row });
        setData(arr);
    }

    const removeRow = (e) => {
        var key = e.target.getAttribute("data-key");
        var arr = [...data];

        arr = arrayRemoveElt(arr, key);
        setData(arr);
    }

    const onStatusChange = (e) => {
        var key = parseInt( e.target.getAttribute("data-key") );
        var value = e.target.value;
        var arr = [...data];
        arr[key].status = value;
        setData(arr);
    }

    return (
        <Fragment>
            <h3 className="text-center">Rangement réception</h3>
            <br></br>

            {error && <ErrorMessage error={error} />}
            {msg && <SuccessMessage msg={msg} />}


            <table className="table text-center">
                <thead>
                    <tr className="table-secondary">
                        <th scope="col">Affaire</th>
                        <th scope="col">N° série</th>
                        <th scope="col">Sous-traitance</th>
                        <th scope="col">Kit répa</th>
                        <th scope="col">Chef de projet</th>
                        <th scope="col">Semaine sortie de stock</th>
                        <th scope="col">Etat d'avancement</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map(
                            (v, k) => {
                                return (
                                    <tr>
                                        <th scope="row">
                                            {v.loading && <ButtonWaiting />}
                                            {v.business}
                                        </th>
                                        <td>
                                            <input className="form-control text-center no-print" 
                                                onChange={onMachineChange} onBlur={getMachineInfo} data-key={k} value={v.machine}></input>
                                            <span className="print-only">{v.machine}</span>
                                        </td>
                                        <td>{v.subcontractor}</td>
                                        <td>
                                            {
                                                v.hasKit > 0 && <img style={{width: "40px"}} src={"/common/check.png"}></img>
                                            }
                                        </td>
                                        <td>{v.projectManager}</td>
                                        <td>{v.outStock}</td>
                                        <td>
                                            <select className="form-select text-center no-print" 
                                                data-key={k} onChange={onStatusChange}>
                                                <option selected>Choisissez...</option>
                                                <option value="Casiers">Casiers</option>
                                                <option value="Picking">Picking</option>
                                                <option value="Atelier">Atelier</option>
                                                <option value="Casier à créer">Casier à créer</option>
                                            </select>
                                            <span className="print-only">{v.status}</span>
                                        </td>
                                        {
                                            k > 0 &&
                                            <td className="no-print">
                                                <img src={"/common/remove_icon.png"} data-key={k}
                                                    style={{ height: "30px" }} onClick={removeRow}></img>
                                            </td>
                                        }
                                    </tr>)
                            })
                    }
                    <tr>
                        <td colSpan={7} className="text-start no-print">
                            <img src={"/common/add-logo.png"} onClick={addRow}></img>
                        </td>
                    </tr>

                </tbody>
            </table>

            <div className="text-center no-print">
                <button className="btn btn-success" onClick={() => {window.print()}}>Imprimer</button>
            </div>


        </Fragment>
    );

}

export default StoreageReceiption;