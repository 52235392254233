
const WaitingRoundSnippers = () => {
    return(
        <div className="text-center">
            {/*<div className="spinner-grow text-secondary" role="status"></div>
            <div className="spinner-grow text-success" role="status"></div>
            <div className="spinner-grow text-danger" role="status"></div>
            <div className="spinner-grow text-warning" role="status"></div>*/}

            <div>
                <img src="/common/bullet_see.png" className="rotate-no-limite"/>
                <div>Loading...</div>
            </div>
        </div>
    )
}

export default WaitingRoundSnippers;