import { Fragment, useState } from "react";
import { useEffect } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import Movement from "../../services/storehouse/Movement";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import CheckConnDao from "../../services/common/CheckConnDao";

const MoveInScan = (props) => {
    const [article, setArticle] = useState();
    const [business, setBusiness] = useState();
    const [qty, setQty] = useState();
    const [comment, setComment] = useState("");
    const [location, setLocation] = useState();
    const [machine, setMachine] = useState();
    const [need, setNeed] = useState();
    const [error, setError] = useState();
    const [errors, setErrors] = useState([]);
    const [msg, setMsg] = useState();
    const [moving, setMoving] = useState(false);
    const [needMoved, setNeedMoved] = useState([]);
    const [displayErr, setDisplayErr] = useState(false);

    useEffect(() => {
        CheckConnDao.internal().then(
            (response) => { },
            (error) => {
                setError(errorManagement.handleError(props, error));
            }
        );

        document.getElementById("reader").focus();
    }, []);

    const onReaderChange = (e) => {
        var values = e.target.value.split("-");

        if (values.length >= 5) {
            setBusiness(values[0]);
            setMachine(values[1]);
            setArticle(values[2]);
            setLocation(values[3]);
            setQty(values[4]);
            if (values.length === 6) setNeed(values[5])

            var arr = [...needMoved];
            arr.push(values[5]);
            setNeedMoved(arr);

        }

        document.getElementById("reader").value = "";
    }

    document.onclick = function (e) {
        if (e.target.tagName !== "INPUT" && !e.target.classList.contains("btn-close")) document.getElementById("reader").focus();
    }

    const reset = () => {
        setBusiness();
        setQty();
        setArticle();
        setLocation();
        setMachine();
        setComment("");
        document.getElementById("qty").value = "";
        document.getElementById("comment").value = "";
    }

    const move = () => {
        setError();
        if (!business || !qty || qty.trim() === "") {
            setError("Saisissez toutes les informations !");
        } else {
            //setMoving(true);
            //Don't wait the response
            Movement.moveInArticle(article, business, qty, machine, comment).then(
                () => {
                    //setMsg(article + " mouvementé !");
                    //reset();
                    //setMoving(false);
                },
                (error) => {
                    /*setError(errorManagement.handleError(props, error));
                    scrollToTop();
                    setMoving(false);
                    reset();*/
                    var arr = [...errors];
                    arr.push(errorManagement.handleError(props, error));
                    setErrors(arr);
                }
            );
            reset();
        }
    }

    return <div className="text-center">
        <h5>Entrée de pièces depuis affaire</h5>

        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        {
            errors.length > 0 &&
            <img src="/common/alert_icon.png" onClick={() => setDisplayErr(true)}
                style={{ position: "absolute", right: "5px", top: "80px" }} />
        }

        {
            !displayErr && <Fragment>

                <input id="reader" onChange={onReaderChange} style={{ width: "1px" }}></input>
                <span className="fw-bold">{article}</span>
                <br></br>
                <span className="fw-bold">{business} - {machine}</span>
                <br></br>
                <div className="col-8 offset-2">
                    <div className="input-group mb-3">
                        <span className="input-group-text">Qté</span>
                        <input type="number" id="qty" className="form-control text-center fw-bold"
                            value={qty} onChange={(e) => { setQty(e.target.value) }} />
                    </div>
                </div>
                <br></br>
                <div className="input-group mb-3">
                    <span className="input-group-text">Commentaire</span>
                    <input type="text" id="comment" className="form-control text-center fw-bold"
                        value={comment} onChange={(e) => { setComment(e.target.value) }} />
                </div>

                <br></br>
                <div>
                    <button className="btn btn-warning" onClick={reset} disabled={moving}>
                        {moving && <ButtonWaiting />}
                        Reset
                    </button>
                    <button className="btn btn-success" onClick={move} disabled={moving} style={{ marginLeft: "10px" }}>
                        {moving && <ButtonWaiting />}
                        Mouvementer
                    </button>
                </div>
            </Fragment>
        }

        {
            displayErr && <div className="card">
                <button type="button" className="btn-close" aria-label="Close" onClick={() => { setDisplayErr(false); setErrors([]) }}></button>
                <ul className="list-group">
                    {
                        errors.map((v, k) => {
                            return <li className="list-group-item" key={k}>{v}</li>
                        })
                    }
                </ul>
            </div>
        }

    </div>
}

export default MoveInScan;