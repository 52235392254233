import { useState } from "react";
import { Fragment } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import MachineInfo from "../../services/machines/MachineInfo";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { getKey } from "../../functions/ArrayFunctions";
import ticketsDao from "../../services/qhse/ticketsDao";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting, getDeviceType, scrollToTop } from "../../functions/PageAnimation";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import getUrlParam, { getBase64 } from "../../functions/StrFunctions";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import { useEffect } from "react";
import CheckConnDao from "../../services/common/CheckConnDao";

const QualityFeedbackForm = (props) => {
    const [id, setId] = useState();
    const [business, setbusiness] = useState("");
    const [machine, setmachine] = useState("");
    const [description, setdescription] = useState();
    const [problem, setproblem] = useState();
    const [criticality, setCriticality] = useState();
    const [proposal, setproposal] = useState();
    const [machineType, setmachineType] = useState("");
    const [qhseComment, setqhseComment] = useState();
    const [statut, setStatut] = useState();

    const [machineList, setmachineList] = useState();
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [takeAPhoto, setTakeAPhoto] = useState(false);
    const [dataPhoto, setDataPhoto] = useState();

    const [loading, setLoading] = useState(false);
    const [machineSearch, setMachineSearch] = useState(false);
    const [saving, setSaving] = useState(false);

    const user = JSON.parse(localStorage.getItem("user"));
    const [isQhse, setIsQhse] = useState(false);
    const [isMine, setIsMine] = useState(true);
    const [isBusiness, setIsBusiness] = useState(true);

    useEffect(() => {
        CheckConnDao.internal().then(
            (response) => { },
            (error) => { errorManagement.handleError(props, error); });


        var id = getUrlParam(props, "id");

        if (id !== null) {

            setLoading(true);
            setId(id);

            ticketsDao.getQualityFeedBack(id).then(
                (response) => {
                    setbusiness(response.business);
                    if( response.business === "" ) setIsBusiness(false);
                    setmachine(response.machine);
                    setmachineType(response.machine_type);
                    setdescription(response.description);
                    setproblem(response.problem);
                    setproposal(response.proposal);
                    setDataPhoto(response.img);
                    setCriticality(response.criticality);
                    setLoading(false);
                    setStatut(response.statut);
                    setError();
                    setIsMine(parseInt(user.id) === parseInt(response.author));
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    setLoading(false);
                }
            )
        }


        CheckConnDao.internal().then(
            (response) => { if (user.roles.includes("ROLE_QHSE")) setIsQhse(true); },
            (error) => { setError(errorManagement.handleError(props, error)); }
        );
    }, []);

    const onInputChange = (e) => {
        var field = e.target.getAttribute("name");
        var val = e.target.value;

        setError();

        switch (field) {
            case 'business':
                setbusiness(val.toUpperCase());
                break;
            case 'machine':
                setmachine(val);
                break;
            case 'description':
                setdescription(val);
                break;
            case 'problem':
                setproblem(val);
                break;
            case 'proposal':
                setproposal(val);
                break;
            case 'machineType':
                setmachineType(val);
                break;
            case 'comment_qhse':
                setqhseComment(val);
                break;
        }

    }

    const onMachineSelect = (e) => {
        setmachine(e.target.value);
        setmachineType(e.target.options[e.target.selectedIndex].getAttribute("data-type"));

    }

    const onBusinessChange = (e) => {
        if (!isMine) return;

        if (business === undefined || business === "") {
            setmachineList();
            setmachine("");
            setmachineType("");
            return;
        }

        if (business.length < 7) {
            setError("Le code affaire doit faire minimum 7 caractères.");
            return;
        }

        setMachineSearch(true);
        MachineInfo.getAllMachineInfo(business.substring(0, 7)).then(
            (response) => {
                setMachineSearch(false);
                var arr = new Array();

                response.map((v) => {
                    if (getKey(arr, "no_machine", v.no_machine) === null)
                        arr.push(v);
                });

                setmachineList(arr);
            },
            (error) => {
                setMachineSearch(false);
                setmachineList();
                setmachine("");
                setmachineType("");
            }
        );
    }

    const onMachineChange = (e) => {
        if (!isMine) return;

        if (machine === undefined || machine === "") {
            setbusiness("");
            setmachine("");
            setmachineType("");
            return;
        }

        if (machine.length < 4) {
            setError("Le code machine doit faire minimum 4 caractères.");
            return;
        }

        setMachineSearch(true);
        MachineInfo.getOneMachineInfo(machine).then(
            (response) => {
                setMachineSearch(false);
                setbusiness(response.affaire);
                setmachineType(response.typ_config);
            },
            (error) => {
                setMachineSearch(false);
                setbusiness("");
                setmachine("");
                setmachineType("");
            }
        );
    }

    const onSubmitForm = (e) => {
        e.preventDefault();
        setError();

        setSaving(true);

        if (id !== undefined && id !== null) {
            ticketsDao.updateQualityFeedBack(id, business, machine, machineType, description, problem,
                proposal, dataPhoto, qhseComment, statut, criticality).then(
                    (response) => {
                        setSaving(false);
                        setMsg("Sauvegardé !");
                        scrollToTop();
                    },
                    (error) => {
                        setError(errorManagement.handleError(props, error));
                        scrollToTop();
                        setSaving(false);
                    }
                )
        } else {
            ticketsDao.saveQualityFeedBack(business, machine, machineType, description,
                problem, proposal, dataPhoto, criticality).then(
                    (response) => {
                        setSaving(false);
                        setMsg("Sauvegardé !");
                        scrollToTop();
                    },
                    (error) => {
                        setError(errorManagement.handleError(props, error));
                        scrollToTop();
                        setSaving(false);
                    }
                )
        }

    }

    /**
  * Open photo device
  * @param {*} e 
  */
    const takePhoto = (e) => {
        setTakeAPhoto(true);
    }

    /**
     * On choose file
     * @param {*} e 
     * @returns 
     */
    const onChooseFile = (e) => {
        if (e.target.files.length === 0)
            return;

        getBase64(e.target.files[0], (res) => {
            setDataPhoto(res);
        });
    }

    /**
     * Close photo device
     */
    const cancelPhoto = () => {
        setTakeAPhoto(false);
    }

    /**
     * Save the photo
     * @param {*} data 
     */
    const handlePhoto = (data) => {
        setDataPhoto(data);
        setTakeAPhoto(false);
    }

    /**
     * Delete the taken photo or uploaded img
     */
    const deletePhoto = () => {
        setDataPhoto();
    }

    /**
     * Change statut
     */
    const onStatutChange = (e) => {
        setStatut(e.target.value)
    }

    const checkIsBusiness = (e) => {
        if( e.target.value === "business" ){
            setIsBusiness(true)
        }else{
            setIsBusiness(false);
            setbusiness("");
            setmachine("");
            setmachineType("");
            setmachineList();
        }
    }

    return <Fragment>
        <h4 className="text-center">Remontées qualité</h4>
        <br></br>

        {error && <ErrorMessage error={error} />}
        {msg && <SuccessMessage msg={msg} />}
        {loading && <WaitingRoundSnippers />}

        {
            !loading &&
            <form className="col-10 offset-1" onSubmit={onSubmitForm} disabled={saving}>
                
                <div className="mb-3">
                    <span className="fw-bold">Type de remontée: </span>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" 
                            name="typeForm" value="business" checked={isBusiness} 
                            onClick={checkIsBusiness}/>
                            <label className="form-check-label" for="inlineRadio1">Affaire</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="typeForm"
                             value="notBusiness" checked={!isBusiness} 
                             onClick={checkIsBusiness}/>
                            <label className="form-check-label" for="inlineRadio2">Hors affaire</label>
                    </div>
                </div>

                <div style={{ display: isBusiness ? "" : "none" }}>
                    <div className="input-group mb-3">
                        <span className="input-group-text" style={{ width: "250px" }}>Affaire</span>
                        <input type="text" className="form-control" name="business"
                            value={business} onChange={onInputChange} onBlur={onBusinessChange} readOnly={!isMine} 
                            required={isBusiness} />
                    </div>

                    {
                        !machineSearch && <Fragment>
                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{ width: "250px" }}>Machine</span>
                                {
                                    !machineList && <input type="text" className="form-control" name="machine"
                                        value={machine} onChange={onInputChange} onBlur={onMachineChange} readOnly={!isMine} 
                                            required={isBusiness} />
                                }

                                {
                                    machineList &&
                                    <select className="form-select" name="machine" onChange={onMachineSelect} 
                                        required={isBusiness}>
                                        <option selected>Choisissez la machine...</option>
                                        {
                                            machineList.map(
                                                (v, k) => <option key={"m" + k} data-type={v.typ_config}
                                                    value={v.no_machine}>{v.no_machine}</option>
                                            )
                                        }
                                    </select>
                                }

                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{ width: "250px" }}>Type de machine</span>
                                <input type="text" className="form-control" name="machineType"
                                    value={machineType} onChange={onInputChange} readOnly={!isMine} 
                                        required={isBusiness} />
                            </div>
                        </Fragment>
                    }
                </div>

                {machineSearch && <WaitingRoundSnippers />}

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "250px" }}>Descriptif du problème qualité</span>
                    <textarea className="form-control" name="description"
                        value={description} onChange={onInputChange} readOnly={!isMine} required></textarea>
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "250px" }}>Problème engendré</span>
                    <textarea className="form-control" name="problem"
                        value={problem} onChange={onInputChange} readOnly={!isMine} required></textarea>
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "250px" }}>Criticité</span>
                    <select className="form-select" onChange={(e) => { setCriticality(e.target.value) }}>
                        <option value="">Choisir...</option>
                        <option value="Mineure" selected={criticality === "Mineure"}>Mineure: résolution immédiate, pas d'impact sur la production/client</option>
                        <option value="Majeure" selected={criticality === "Majeure"}>Majeure: résolution non immédiate, impact modéré sur la production/client</option>
                        <option value="Critique" selected={criticality === "Critique"}>Critique: résolution à long terme, arrêt de la production, impact critique pour le client</option>
                    </select>
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "250px" }}>Proposition d'améliorations</span>
                    <textarea className="form-control" name="proposal"
                        value={proposal} onChange={onInputChange} readOnly={!isMine}></textarea>
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "250px" }}>Commentaires QHSE</span>
                    <textarea className="form-control" name="comment_qhse"
                        value={qhseComment} onChange={onInputChange} readOnly={!isQhse}></textarea>
                </div>

                <div className="input-group mb-3">
                    <label className="input-group-text" style={{ width: "250px" }}>Statut</label>
                    <select className="form-select" disabled={!isQhse} name="statut" onChange={onStatutChange}>
                        <option value="Ouvert">Ouvert</option>
                        <option value="En cours" selected={statut === 'En cours'}>En cours</option>
                        <option value="Clôturé" selected={statut === 'Clôturé'}>Clôturé</option>
                    </select>
                </div>

                {(getDeviceType() === "mobile" && isMine) &&
                    <div style={{ display: "flex", marginBottom: "10px" }}>
                        {
                            !takeAPhoto &&
                            <Fragment  >
                                <button type="button" className="custom-file-upload photo" style={{ marginLeft: "auto", marginRight: "auto" }}
                                    onClick={takePhoto}></button>

                                <label className="custom-file-upload folder" style={{ marginLeft: "auto", marginRight: "auto" }}>
                                    <input type="file" accept="image/*" onChange={onChooseFile} />
                                </label>
                            </Fragment>
                        }

                        {takeAPhoto &&
                            <button type="button" className="btn btn-info" onClick={cancelPhoto}>Annuler</button>}

                        <br></br>
                        {takeAPhoto &&
                            <Camera idealFacingMode={FACING_MODES.ENVIRONMENT} onTakePhoto={(data) => { handlePhoto(data) }} />}
                    </div>
                }

                {(getDeviceType() !== "mobile" && isMine) &&
                    <div className="input-group mb-3">
                        <label className="input-group-text" htmlFor="file">Ajouter une image</label>
                        <input type="file" accept="image/*" className="form-control" onChange={onChooseFile} id="file" name="attachment" />
                    </div>
                }

                {dataPhoto &&
                    <div className="text-center" style={{ marginBottom: "20px" }}>
                        <img src={dataPhoto} style={{ maxWidth: getDeviceType() === "mobile" ? "300px" : "500px" }} />
                        <br></br>
                        {
                            isMine && <button type="button" style={{ marginTop: "10px", marginLeft: "10px" }} className="btn btn-danger" onClick={deletePhoto}>Supprimer</button>
                        }

                    </div>
                }

                {
                    !msg && <div className="text-center">
                        <button type="submit" className="btn btn-success" disabled={saving}>
                            {saving && <ButtonWaiting />}
                            Soumettre
                        </button>
                    </div>
                }

            </form>
        }
    </Fragment>

}

export default QualityFeedbackForm;