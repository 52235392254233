import { nbFormat } from "../../../../functions/StrFunctions";

const PayTerms = (props) => {
    return (
        <div className="table-responsive">
            <table className="table businessCardStyle">
                <thead>
                    <tr className="table-dark text-center rounded">
                        <th scope="col" colSpan={3}>Payment terms</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="text-center fw-bold">
                        <td style={{ width: "33.33%" }} className="border-end">Term</td>
                        <td className="border-end">Date</td>
                        <td className="border-end">Amount</td>
                    </tr>

                    {props.terms.map((v, k) => {
                        return <tr key={"t" + k}>
                            <td className="border-end">{v.term}</td>
                            <td className="border-end">{v.date_term}</td>
                            <td className="border-end">{nbFormat(v.amount, 'fr-FR', 2)}</td>
                        </tr>
                    })}

                    <tr>
                        <td colSpan={3}>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Down Payment Bank Bond</span>
                                <input type="text" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.down_pay_bk_bond}
                                    data-field="down_pay_bk_bond" />

                                <span className="input-group-text">Warranty Bank Bond</span>
                                <input type="text" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.war_bk_bond}
                                    data-field="war_bk_bond"/>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default PayTerms;