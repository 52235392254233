import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import MachineInfo from "../../services/machines/MachineInfo";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting } from "../../functions/PageAnimation";

const MachinePwd = (props) => {
    const [error, setError] = useState();
    const [result, setResult] = useState();
    const { t } = useTranslation("business");
    const [loading, setLoading] = useState(false);

    const getPwd = (e) => {
        e.preventDefault();
        setError();

        var dwCode = parseInt(e.target.dwCode.value);

        if (isNaN(dwCode)) {
            setError(t("wrong pwd"));
            return;
        }

        setLoading(true);
        MachineInfo.getMachinePwd(dwCode).then(
            (response) => {
                setResult(response.toString());
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }

    return <Fragment>
        {error && <ErrorMessage error={error} errorState={setError} />}

        {!result && <Fragment>

            <h4 className="text-center mb-3">{t('type machine code')}</h4>

            <form className="text-center" onSubmit={getPwd}>
                <input type="text" className="form-control ms-auto me-auto text-center fw-bold mb-3"
                    style={{ maxWidth: "500px", fontSize: "30px" }} name="dwCode" autoFocus required />

                <button type="submit" className="btn btn-success" disabled={loading}>
                    {loading && <ButtonWaiting />}
                    {t('generate')}
                </button>
            </form>

        </Fragment>}

        {result && <Fragment>
            <h4 className="text-center mb-3">{t('type this pwd')}</h4>

            <div className="d-flex flex-wrap">
                {[...Array(result.length)].map((v, k) => {
                    return <div className={"bg-secondary-subtle me-1 p-2 border fw-bold fs-4 mb-3 "
                        + (k === 0 ? "ms-auto" : k === result.length - 1 ? "me-auto" : "")
                    } key={"nb" + k}>
                        {result.charAt(k)}
                    </div>
                })
                }
            </div>

            <div className="text-center">
                <button className="btn btn-outline-secondary" onClick={()=>setResult()}>Try again</button>
            </div>
                       

        </Fragment>}



    </Fragment>

}

export default MachinePwd;