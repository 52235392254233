import React, { useState } from 'react';
import CheckConnDao from '../../services/common/CheckConnDao';


const Test = (p) => {
  const [translatedText, setTranslatedText] = useState('');

  const translate = async (e) => {
    e.preventDefault();
    var val = e.target.text.value;
    
    const translation = await CheckConnDao.translate(val, 'ZH');
    setTranslatedText(translation)
  }

  return <div>
    
  </div>
};

export default Test;