import { Fragment, useEffect, useState } from "react";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import FormMgtDao from "../../services/settings/FormMgtDao";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import errorManagement from "../../services/errorManagement";

const FormCreation = (props) => {
    const [list, setList] = useState();
    const [formList, setFormList] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    const [form, setForm] = useState();
    const [index, setIndex] = useState();
    const [typeMachine, setTypeMachine] = useState();

    const [emptyObj, setEmptyObj] = useState({
        "form": "",
        "chp_lab": "",
        "en_chp_lab": "",
        "index": "",
        "chapter": "",
        "mach_type": "",
        "rubric": "",
        "rub_lab": "",
        "en_rub_lab": "",
        "question": "",
        "qst_lab": "",
        "chkboxs": "",
        "inputs": "",
        "hasConform": 1,
        "options": "",
        "textarea": "",
        "en_qst": "",
        "hasPhoto": 0
    })

    /**
     * get all forms
     */
    useEffect(() => {
        setLoading(true);

        FormMgtDao.getForms().then(
            (response) => {
                setFormList(response);
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );
    }, []);

    /**
         * Set in array the choosen form
         * @param {*} e 
         */
    const getForm = (e) => {
        e.preventDefault();
        setList();
        const f = e.target.form.value;
        const index = e.target.index.value;
        const machine_type = e.target.machine_type.value;

        setLoading(true);
        FormMgtDao.getForm(f, index, machine_type).then(
            (response) => {
                setList(response);
                setLoading(false);

                var o = { ...emptyObj };
                o.form = form;
                o.index = index;
                o.mach_type = typeMachine;
                setEmptyObj(o);
            },
            (error) => {
                setLoading(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );


    }

    const changeField = (e, k, field) => {
        var arr = [...list];
        if (e.target.type === "checkbox") {
            arr[k][field] = e.target.checked ? 1 : 0;
        } else {
            arr[k][field] = e.target.value;
        }

        setList(arr);
    }

    /**
     * 
     * @param {*} v 
     * @returns 
     */
    const chapterRow = (v, k) => {
        return <tr className="fw-bold text-center" style={{ backgroundColor: "#C6D9F1" }}>
            <td>
                <div className="input-group" style={{ maxWidth: "800px", margin: "auto" }}>
                    <span className="input-group-text">Chapitre {v.chapter}: </span>
                    <input type="text" className="form-control fw-bold" placeholder="Libellé du chapitre FR"
                        value={v.chp_lab} onChange={(e) => changeField(e, k, "chp_lab")} />
                    <input type="text" className="form-control fw-bold" placeholder="Libellé du chapitre EN"
                        value={v.en_chp_lab} onChange={(e) => changeField(e, k, "en_chp_lab")} />
                    {
                        k > 0 &&
                        <img src="/common/remove_icon.png" style={{ width: "30px", height: "30px" }}
                            onClick={() => deleteChapter(k)}></img>
                    }
                </div>
            </td>
        </tr>
    }

    /**
     * 
     * @param {*} v 
     * @returns 
     */
    const rubricRow = (v, k) => {
        return <tr className="fw-bold text-center" style={{ backgroundColor: "#d6e4f5" }}>
            <td>
                <div className="input-group" style={{ maxWidth: "800px", margin: "auto" }}>
                    <span className="input-group-text">Rubrique {v.rubric}: </span>
                    <input type="text" className="form-control fw-bold" placeholder="Libellé de la rubrique FR"
                        value={v.rub_lab} onChange={(e) => changeField(e, k, "rub_lab")} />
                    <input type="text" className="form-control fw-bold" placeholder="Libellé de la rubrique EN"
                        value={v.en_rub_lab} onChange={(e) => changeField(e, k, "en_rub_lab")} />
                    {
                        v.rubric !== 1 &&
                        <img src="/common/remove_icon.png" style={{ width: "30px", height: "30px" }}
                            onClick={() => deleteRubric(k)}></img>
                    }
                </div>
            </td>
        </tr>
    }

    /**
     * 
     * @param {*} v 
     * @returns 
     */
    const qstRow = (v, k) => {
        return <tr className="fw-bold">
            <td>
                <div>
                    {/** QUESTION LABELS */}
                    <div className="input-group mb-3">
                        <span className="input-group-text">Question {v.question}:</span>

                        <input type="text" className="form-control" placeholder="Libellé de la question en français"
                            value={v.qst_lab || ""} onChange={(e) => changeField(e, k, "qst_lab")} />

                        <input type="text" className="form-control" placeholder="Libellé de la question en anglais"
                            value={v.en_qst || ""} onChange={(e) => changeField(e, k, "en_qst")} />
                    </div>

                    {/** CONFORM AND PHOTO BTNS */}
                    <div className="mb-3">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" checked={parseInt(v.hasConform) === 1}
                                onChange={(e) => changeField(e, k, "hasConform")} />
                            <label className="form-check-label">Afficher les boutons conforme/non-conforme</label>
                        </div>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" checked={parseInt(v.hasPhoto) === 1}
                                onChange={(e) => changeField(e, k, "hasPhoto")} />
                            <label className="form-check-label">Autoriser les prises de photos</label>
                        </div>
                    </div>

                    {/** OPTIONS? CHECKBOX AND INPUT */}
                    <div className="accordion mb-3" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id={"heading" + list[k].question }>
                                <button className="accordion-button collapsed" type="button" 
                                    data-bs-toggle="collapse" data-bs-target={"#collapse" + list[k].question} 
                                    aria-expanded="false" aria-controls={"collapse" + list[k].question}>
                                    Saisie utilisateur (zone de texte, radio, case à cocher)
                                </button>
                            </h2>
                            <div id={"collapse" + list[k].question} className="accordion-collapse collapse" 
                                aria-labelledby={"heading" + list[k].question } data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="div-90-border mb-3">
                                        <p>Affichage une case d'option (un seule réponse possible parmi une liste que vous proposerez ci-dessous)</p>
                                        {getOptions(v, k)}
                                    </div>

                                    <div className="div-90-border mb-3">
                                        <p>Affichage de cases à cocher (plusieurs réponses possibles parmi une liste que vous proposerez ci-dessous)</p>
                                        {getCheckboxes(v, k)}
                                    </div>

                                    <div className="div-90-border mb-3">
                                        <p>Affichage d'un text court</p>
                                        {getInputs(v, k)}
                                    </div>

                                    <div className="div-90-border">
                                        <p>Affichage d'un text long</p>
                                        {getTextArea(v, k)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/** ADD SOMETHING IN THIS PLACE */}
                    <div className="display-flex">
                        <button className="btn me-2" style={{ backgroundColor: "#C6D9F1" }}
                            onClick={() => addChapter(k)}>Ajouter un chapitre</button>
                        <button className="btn me-2" style={{ backgroundColor: "#d6e4f5" }}
                            onClick={() => addRubric(k)}>Ajouter une rubrique</button>
                        <button className="btn btn-outline-secondary me-2"
                            onClick={() => addQuestion(k)}>Ajouter une question</button>
                        { v.question > 1 && <button className="btn btn-danger"
                                onClick={() => deleteQuestion(k)}>Supprimer cette question</button> }

                    </div>

                </div>
            </td>
        </tr>
    }

    /**
     * Get options render
     * @param {*} v 
     * @param {*} k 
     * @returns 
     */
    const getOptions = (v, k) => {
        const addRow = () => {
            var arr = [...list];
            if (arr[k].options.length === 0) arr[k].options = new Array();
            arr[k].options.push({ "name": "opt_qst_" + arr[k].question, "value": "", required: false, checked: false });
            setList(arr);
        }

        const removeRow = (keyAttr) => {
            var arr = [...list];
            var opt = arr[k].options;
            opt = opt.slice(0, keyAttr).concat(opt.slice(keyAttr + 1, opt.length));
            arr[k].options = opt;
            setList(arr);
        }

        const changeAttr = (e, keyAttr) => {
            var arr = [...list];
            arr[k].options[keyAttr].value = e.target.value;
            setList(arr);
        }

        return <Fragment>
            {(v.options && v.options.length > 0) &&
                v.options.map((opt, k2) => {
                    return (
                        <div key={"opt_" + k2} className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Réponse possible {k2+1}</span>
                            <input type="text" className="form-control" onChange={(e)=>changeAttr(e, k2)} value={opt.value} />
                            <img src={"/common/remove_icon.png"} onClick={()=>removeRow(k2)} style={{ width: "30px" }}></img>
                        </div>
                    )
                })
            }
            <img src={"/common/add-logo.png"} onClick={addRow} style={{ width: "30px" }} ></img>

        </Fragment>
    }

    /**
     * Get checkboxes render
     * @param {*} v 
     * @param {*} k 
     * @returns 
     */
    const getCheckboxes = (v, k) => {
        const addRow = () => {
            var arr = [...list];
            if (arr[k].chkboxs.length === 0) arr[k].chkboxs = new Array();
            arr[k].chkboxs.push({ "name": "chk_qst_" + arr[k].question + "_" + (arr[k].chkboxs.length+1), 
                "value": "", required: false, checked: false });
            setList(arr);
        }

        const removeRow = (keyAttr) => {
            var arr = [...list];
            var chk = arr[k].chkboxs;
            chk = chk.slice(0, keyAttr).concat(chk.slice(keyAttr + 1, chk.length));
            arr[k].chkboxs = chk;
            setList(arr);
        }

        const changeAttr = (e, keyAttr) => {
            var arr = [...list];
            arr[k].chkboxs[keyAttr].value = e.target.value;
            setList(arr);
        }

        return <Fragment>
            {(v.chkboxs && v.chkboxs.length > 0) &&
                v.chkboxs.map((chk, k2) => {
                    return (
                        <div key={"opt_" + k2} className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Réponse possible {k2+1}</span>
                            <input type="text" className="form-control" onChange={(e)=>changeAttr(e, k2)} value={chk.value} />
                            <img src={"/common/remove_icon.png"} onClick={()=>removeRow(k2)} style={{ width: "30px" }}></img>
                        </div>
                    )
                })
            }
            <img src={"/common/add-logo.png"} onClick={addRow} style={{ width: "30px" }} ></img>

        </Fragment>
    }

    /**
     * Get inputs render
     * @param {*} v 
     * @param {*} k 
     * @returns 
     */
    const getInputs = (v, k) => {
        const addRow = () => {
            var arr = [...list];
            if (arr[k].inputs.length === 0) arr[k].inputs = new Array();
            arr[k].inputs.push({ "name": "", "value": "", required: false, checked: false });
            setList(arr);
        }

        const removeRow = (keyAttr) => {
            var arr = [...list];
            var ipt = arr[k].inputs;
            ipt = ipt.slice(0, keyAttr).concat(ipt.slice(keyAttr + 1, ipt.length));
            arr[k].inputs = ipt;
            setList(arr);
        }

        const changeAttr = (e, keyAttr) => {
            var arr = [...list];
            arr[k].inputs[keyAttr].name = e.target.value;
            setList(arr);
        }

        return <Fragment>
            {(v.inputs && v.inputs.length > 0) &&
                v.inputs.map((ipt, k2) => {
                    return (
                        <div key={"opt_" + k2} className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Intitulé de l'info demandé</span>
                            <input type="text" className="form-control" onChange={(e)=>changeAttr(e, k2)} value={ipt.name} />
                            <img src={"/common/remove_icon.png"} onClick={()=>removeRow(k2)} style={{ width: "30px" }}></img>
                        </div>
                    )
                })
            }
            <img src={"/common/add-logo.png"} onClick={addRow} style={{ width: "30px" }} ></img>

        </Fragment>
    }

    /**
     * Get textarea render
     * @param {*} v 
     * @param {*} k 
     * @returns 
     */
    const getTextArea = (v, k) => {
        const addRow = () => {
            var arr = [...list];
            if (arr[k].textarea.length === 0) arr[k].textarea = new Array();
            arr[k].textarea.push({ "name": "", "value": "", required: false, checked: false });
            setList(arr);
        }

        const removeRow = (keyAttr) => {
            var arr = [...list];
            var txt = arr[k].textarea;
            txt = txt.slice(0, keyAttr).concat(txt.slice(keyAttr + 1, txt.length));
            arr[k].textarea = txt;
            setList(arr);
        }

        const changeAttr = (e, keyAttr) => {
            var arr = [...list];
            arr[k].textarea[keyAttr].name = e.target.value;
            setList(arr);
        }

        return <Fragment>
            {(v.textarea && v.textarea.length > 0) &&
                v.textarea.map((txt, k2) => {
                    return (
                        <div key={"opt_" + k2} className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Intitulé de l'info demandé</span>
                            <input type="text" className="form-control" onChange={(e)=>changeAttr(e, k2)} value={txt.name} />
                            <img src={"/common/remove_icon.png"} onClick={()=>removeRow(k2)} style={{ width: "30px" }}></img>
                        </div>
                    )
                })
            }
            <img src={"/common/add-logo.png"} onClick={addRow} style={{ width: "30px" }} ></img>

        </Fragment>
    }

    /**
     * Add chapter
     */
    const addChapter = (k) => {
        var curChap = list[k].chapter;
        var arr = new Array();
        var i = 0;
        var curQst = 1;

        // Insert previous chapter rows
        while (list[i] && list[i].chapter <= curChap) {
            arr.push(list[i]);
            curQst = list[i].question;
            i++;
        }

        // Insert the new row
        var o = { ...emptyObj };
        o.chapter = curChap + 1;
        o.rubric = 1;
        curQst++;
        o.question = curQst;
        arr.push(o);

        // Insert the nex chapter by incrementing the chapter number
        for (let j = i; j < list.length; j++) {
            o = { ...list[j] };
            o.chapter = o.chapter + 1;
            curQst++;
            o.question = curQst;
            arr.push(o);
        }

        setList(arr)
    }

    /**
     * Delete chapter
     */
    const deleteChapter = (k) => {
        var curChap = list[k].chapter;
        var arr = new Array();
        var i = 0;
        var curQst = 1;

        // Insert previous chapter rows
        while (list[i] && list[i].chapter < curChap) {
            arr.push({ ...list[i] });
            curQst = list[i].question;
            i++;
        }

        // Ignore deleted chapter
        while (list[i] && list[i].chapter === (curChap)) i++;

        // Insert the nex chapter by decrementing the chapter number
        for (let j = i; j < list.length; j++) {
            var o = { ...list[j] };
            o.chapter = o.chapter - 1;
            curQst++;
            o.question = curQst;
            arr.push(o);
        }

        setList(arr)
    }

    /**
     * Add rubric
     */
    const addRubric = (k) => {
        var curChap = list[k].chapter;
        var curRubric = list[k].rubric;
        var arr = new Array();
        var i = 0;
        var curQst = 1;

        // Insert previous chapter rows
        while (list[i] && (list[i].chapter !== curChap)) {
            arr.push({ ...list[i] });
            curQst = list[i].question;
            i++;
        }

        // Insert previous rubric
        while (list[i] && (list[i].chapter === curChap && list[i].rubric <= curRubric)) {
            arr.push({ ...list[i] });
            curQst = list[i].question;
            i++;
        }

        // Insert the new row
        var o = { ...emptyObj };
        o.chapter = curChap;
        o.rubric = curRubric + 1;
        curQst++;
        o.question = curQst;
        arr.push(o);

        // Insert the next chapter by incrementing the rubric number
        for (let j = i; j < list.length; j++) {
            var o = { ...list[j] };
            if (list[j].chapter === curChap) o.rubric = o.rubric + 1;
            curQst++;
            o.question = curQst;
            arr.push(o);
        }

        setList(arr);
    }

    /**
     * Delete rubric
     * 
     * @param {*} k 
     */
    const deleteRubric = (k) => {
        var curChap = list[k].chapter;
        var curRubric = list[k].rubric;
        var arr = new Array();
        var i = 0;
        var curQst = 1;

        // Insert previous chapter rows
        while (list[i] && (list[i].chapter !== curChap)) {
            arr.push({ ...list[i] });
            curQst = list[i].question;
            i++;
        }

        // Insert previous rubric
        while (list[i] && (list[i].chapter === curChap && list[i].rubric < curRubric)) {
            arr.push({ ...list[i] });
            curQst = list[i].question;
            i++;
        }

        //Ignore rubric to delete
        while (list[i] && (list[i].chapter === curChap && list[i].rubric === curRubric)) i++;

        // Insert the next chapter by decrementing the rubric number
        for (let j = i; j < list.length; j++) {
            var o = { ...list[j] };
            if (list[j].chapter === curChap) o.rubric = o.rubric - 1;
            curQst++;
            o.question = curQst;
            arr.push(o);
        }

        setList(arr);
    }

    /**
     * Add question
     */
    const addQuestion = (k) => {
        var curChap = list[k].chapter;
        var curRubric = list[k].rubric;
        var curQst = list[k].question;
        var arr = new Array();
        var i = 0;

        // Insert previous questions
        while (list[i] && (list[i].question <= curQst)) {
            arr.push({ ...list[i] });
            i++;
        }

        //Insert the question
        var o = { ...emptyObj };
        o.chapter = curChap;
        o.rubric = curRubric;
        curQst++;
        o.question = curQst;
        arr.push(o);

        // Insert the next questions by incrementing the question number
        for (let j = i; j < list.length; j++) {
            var o = { ...list[j] };
            curQst++;
            o.question = curQst;
            arr.push(o);
        }

        setList(arr);
    }

    /**
     * Delete question
     */
    const deleteQuestion = (k) => {
        var curQst = list[k].question;
        var arr = new Array();

        list.map(v => {
            if (v.question < curQst) arr.push(v);
            if (v.question > curQst) {
                v.question = v.question - 1;
                arr.push(v);
            }
        })

        setList(arr);
    }

    /**
     * SAVE
     */
    const save = () => {
        setSaving(true);
        FormMgtDao.saveFormV2(list).then(
            () => {
                setSaving(false);
            },
            (error) => {
                setSaving(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );
    }

    return <Fragment>
        {loading && <WaitingRoundSnippers />}
        {error && <ErrorMessage error={error} errorState={setError} />}
   
        {
            formList &&
            <form onSubmit={getForm}>
                <div className="input-group mb-3">
                    <span className="input-group-text">Formulaire</span>
                    <input type="text" list="forms" name="form" className="form-control"
                        onChange={(e) => setForm(e.target.value)} value={form} required></input>

                    <span className="input-group-text">Indice</span>
                    <input type="text" list="forms" name="index" defaultValue="A" className="form-control"
                        onChange={(e) => setIndex(e.target.value)} value={index} required></input>

                    <span className="input-group-text">Type de machine</span>
                    <input type="text" name="machine_type" className="form-control"
                        onChange={(e) => setTypeMachine(e.target.value)} value={typeMachine} required></input>

                    <button type="submit" className="btn btn-success">Commencer</button>
                </div>
                <br></br>

                <datalist id="forms">
                    {formList.map((v, k) => { return <option key={"l" + k} value={v}></option> })}
                </datalist>

            </form>
        }

        {
            list && <Fragment>

                <table className="table table-bordered">
                    <thead></thead>
                    <tbody>
                        {
                            list.map((v, k) => {
                                return <Fragment key={k}>
                                    {k === 0 &&
                                        <Fragment>
                                            {chapterRow(v, k)}
                                            {rubricRow(v, k)}
                                        </Fragment>
                                    }

                                    {(k > 0 && v.chapter !== list[k - 1].chapter) &&
                                        <Fragment>
                                            {chapterRow(v, k)}
                                            {rubricRow(v, k)}
                                        </Fragment>
                                    }

                                    {(k > 0 && v.chapter === list[k - 1].chapter && v.rubric !== list[k - 1].rubric) &&
                                        rubricRow(v, k)
                                    }

                                    {qstRow(v, k)}

                                </Fragment>
                            })
                        }
                    </tbody>
                </table>

                <div className="text-center mb-3">
                    <button className="btn btn-outline-success" onClick={save} disabled={saving}>
                        {saving && <ButtonWaiting />}
                        Sauvegarder
                    </button>
                </div>

            </Fragment>
        }

    </Fragment>

}

export default FormCreation;