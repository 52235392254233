import { Fragment, useState } from "react";
import { ButtonWaiting, scrollToTop } from "../../../functions/PageAnimation";
import Select from "react-select";
import LabelsChoice from "../../../common/smallComponents.js/LabelsChoice";
import { arrayRemoveElt } from "../../../functions/ArrayFunctions";
import Labels from "../../../services/storehouse/Labels";
import errorManagement from "../../../services/errorManagement";
import { downloadTempFile } from "../../../services/functions/FilesProcess";
import Autocomplete from "react-autocomplete";
import AutocompletionArt from "../../../common/smallComponents.js/AutocompletionArt";
import { ArticleAutoComplete } from "../../../common/smallComponents.js/DesignedIpt";
import ArticleInfo from "../../../services/articles/ArticleInfo";

{/** settagFormat, getCss, setStart, start, list, setList */ }
const FreeTags = (p) => {
    const [downloading, setDownloading] = useState(false);

    const onArticleChoose = (e, k) => {

        if( e === null ){
            p.setError("Article non trouvé");
            scrollToTop();
            e = new Object();
            e.CDARL="";
            e.DES1L="";
            e.ZN02PA="";
        }

        var arr = [...p.list];
        arr[k].code = e.CDARL.trim();
        arr[k].des = e.DES1L;
        arr[k].ref = e.ZN02PA;
        p.setList(arr);

    }

    const onArticleChange = (v, k) => {
        var arr = [...p.list];
        arr[k].code = v;
        p.setList(arr);
    }

    const onFieldChange = (e, k, field) => {
        var arr = [...p.list];
        arr[k][field] = e.target.value;
        p.setList(arr);
    }

    const addRow = () => {
        var arr = [...p.list];
        arr.push({ code: "", ref: "", label: "", qty: 1 });
        p.setList(arr);
    }

    const deleteRow = (k) => {
        var arr = [...p.list];
        arr = arrayRemoveElt(arr, k);
        p.setList(arr);
    }

    const download = () => {
        p.setError();

        setDownloading(true);
        if (p.list.length === 0) {
            p.setError("Saisissez au moins une ligne.");
            return;
        }

        for (let i = 0; i < p.list.length; i++) {
            var o = p.list[i];
            if (o.code.trim() === "" || o.ref.trim() === "" || isNaN(o.qty)) {
                p.setError("Tous les champs sont obligatoires.");
                return;
            }
        }

        Labels.freeLabels(p.list, p.format, p.start).then(
            (res) => {
                downloadTempFile(res);
                setDownloading(false);
            }, (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
                setDownloading(false);
            }
        );
    }

    return <Fragment>
        <h5 className="text-center mb-3">Saisie libre des étiquettes</h5>

        <h6 className="text-center mb-3">Choisissez l'agencement des étiquettes, ainsi que l'emplacement de départ des étiquettes</h6>
        <div className="display-flex">
            <div className="col-6 display-flex">
                <div className=" ms-auto border p-2 rounded fw-bold cursor-pointer mt-auto mb-auto text-center"
                    style={p.getCss("ARD")} onClick={() => p.settagFormat("ARD")}>
                    <span style={{ display: "inline-block", verticalAlign: "middle" }}>BOÎTES PIÈCES DU COMMERCE<br></br>
                        RENVOI RACK/ÉTAGÈRE</span>
                </div>

                <div className="ms-5 me-4 border p-2 rounded fw-bold cursor-pointer mt-auto mb-auto text-center"
                    style={p.getCss("RAD")} onClick={() => p.settagFormat("RAD")}>
                    BOÎTES PIÈCES USINÉES
                </div>
            </div>
            <div className="col-6">
                <LabelsChoice start={p.start} onChooseTag={(e) => p.setStart(e)} />
            </div>

        </div>

        <div className="col-8 offset-2">
            {
                p.list.map((v, k) => {
                    return <div className="input-group mb-3" key={k}>
                        <ArticleAutoComplete value={v.code} onChooseArt={(e) => onArticleChoose(e, k)}
                            setValue={(val) => onArticleChange(val, k)} searchDetail={true} />
                        <input type="text" className="form-control" placeholder="Désignation" value={v.des} readOnly />
                        <input type="text" className="form-control" placeholder="Plan"
                            value={v.ref} onChange={(e) => onFieldChange(e, k, "ref")} />
                        <input type="text" className="form-control text-center fw-bold" style={{ maxWidth: "50px" }} placeholder="x" readOnly />
                        <input type="number" className="form-control" value={v.qty} style={{ maxWidth: "70px" }}
                            onChange={(e) => onFieldChange(e, k, "qty")} />
                        <button className="btn btn-outline-danger" onClick={() => deleteRow(k)}>Supprimer</button>
                    </div>
                })
            }
            <button className="btn btn-outline-secondary" onClick={addRow}>Ajouter un article</button>
        </div>

        <div className="text-center">
            <button className="btn btn-success" onClick={download} disabled={downloading}>
                {downloading && <ButtonWaiting />}
                Télécharger les étiquettes
            </button>
        </div>
    </Fragment>

}

export default FreeTags;