import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { ButtonWaiting } from "../../functions/PageAnimation";
import errorManagement from "../../services/errorManagement";
import Movement from "../../services/storehouse/Movement";
import ScanReader from "./components/ScanReader";

const RotatingInventoryScan = (props) => {
    const [list, setList] = useState("");
    const [article, setArticle] = useState("");
    const [qty, setQty] = useState();
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const [cursor, setCursor] = useState(0);
    const [lastCursor, setLastCursor] = useState(0);

    useEffect(() => {
        Movement.getRotatingInventoryListToScan().then(
            (response) => {
                setList(response);
                setLoading(false);

                if (response.length === 0) setError("Il n'y a rien à inventorier pour "+user.trigram+" !")
                
                setTimeout(() => {document.getElementById("qty").focus()}, 500);

                setCursor(0)
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }, []);


    const save = () => {
        if (!qty || !article) {
            setError("Saisissez la quantité")
        } else {
            setSaving(true);
            Movement.updateRotatingInventoryList("ZN27PA", qty, article).then(
                (response) => {
                    var arr = [...list];
                    arr[cursor].ZN27PA = qty;
                    setList(arr);

                    document.getElementById("qty").focus()
                    setCursor(cursor + 1);
                    setSaving(false);
                }, (error) => {
                    setError(errorManagement.handleError(props, error));
                    setSaving(false);
                }
            )
        }
    }

    useEffect(() => {
        if (list) {
            if (cursor < list.length) {
                setArticle(list[cursor].CDARPA);
                setQty(list[cursor].ZN27PA || "");
            } else {
                setArticle("");
                setQty("");
            }

            if (cursor > lastCursor) setLastCursor(cursor);
        }
    }, [cursor, list])

    return <div className="text-center">
        <h5 className="mb-3">Inventaire tournant</h5>

        {loading && <WaitingRoundSnippers />}


        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        {(!loading && list.length > 0) && <Fragment>

            {cursor < list.length && <Fragment>
                <h6 className="mt-3">{list[cursor].CDARPA.trim()} {(list[cursor].ZN15PA && list[cursor].ZN15PA.trim() !== "") &&
                    <span> / {list[cursor].ZN04PA}</span>}</h6>
                <div className="mb-3">{list[cursor].ZN02PA.trim()}</div>
                <div className="fst-italic">{list[cursor].ZN03PA.trim()}</div>

                <br></br>
                <div className="col-8 offset-2 mb-3">
                    <div className="input-group mb-3">
                        <span className="input-group-text">Qté</span>
                        <input type="number" id="qty" className="form-control text-center fw-bold"
                            value={qty} onChange={(e) => { setQty(e.target.value) }} />
                    </div>
                </div>
                <br></br>

            </Fragment>}

            { cursor >= list.length && <div className="mb-3">
                <h6 className="mb-3">Fin d'inventaire</h6>
                <img style={{width: "90%"}} src="/img/storehouse/funny-end.gif"/>
            </div>}

            <div className="d-flex">
                {cursor > 0 && <button className="btn btn-secondary" onClick={() => setCursor(cursor - 1)}>&lt;</button>}

                {cursor < list.length &&
                    <button className="btn btn-success ms-auto me-auto" onClick={save} disabled={saving} style={{ marginLeft: "10px" }}>
                        {saving && <ButtonWaiting />}
                        Valider
                    </button>}

                {(cursor < lastCursor && cursor < list.length) && <button className="btn btn-secondary" onClick={() => setCursor(cursor + 1)}>&gt;</button>}
            </div>
        </Fragment>}

    </div>
}

export default RotatingInventoryScan;