import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL +"autocomplete/articles";

const getArtCode = () => {
    return axios
    .post(API_URL + "/get-distinct",
    {fake_header: authHeader()},
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getArtCodeFromStock = () => {
    return axios
    .post(API_URL + "/get-distinct/from-stock",
    {fake_header: authHeader()},
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

export default {
    getArtCode,
    getArtCodeFromStock
};