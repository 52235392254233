import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL +"storehouse/labels";

const createLabels = (order, detail,  start) => {
    return axios
        .post(API_URL + "/purchase-order", {
                fake_header: authHeader(),
                order: order,
                start: start,
                arr: detail
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const saveArticleToInventory = (ar, location) => {
    return axios
        .post(API_URL + "/inventory/scan", {
                fake_header: authHeader(),
                ar: ar,
                location: location
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const getScrews = () => {
    return axios
        .post(API_URL + "/screws/get-ar", {
                fake_header: authHeader()
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const requestScrewToOrder = (ar, qty) => {
    return axios
        .post(API_URL + "/screws/scan/set-qty", {
                fake_header: authHeader(),
                ar: ar,
                qty: qty
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const freeLabels = (list, format, start) => {
    return axios
        .post(API_URL + "/free", {
                fake_header: authHeader(),
                list: list,
                format: format,
                start: start
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const excelLabels = (list, start) => {
    return axios
        .post(API_URL + "/excel", {
                fake_header: authHeader(),
                list: list,
                start: start
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

export default {
    createLabels,
    saveArticleToInventory,
    getScrews,
    requestScrewToOrder,
    freeLabels,
    excelLabels
}