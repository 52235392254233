import { useEffect } from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SignaturePad from "react-signature-canvas";

const SignatureComp = (p) => {
    const [step, setStep] = useState(0);
    const user = JSON.parse(localStorage.getItem("user"));
    const sigSee = useRef();
    const sigClient = useRef();
    const [error, setError] = useState();
    const { t } = useTranslation(['check-list', 'common']);

    const setNext = () => {
        setError();

        if (step === 0) {
            if (sigSee.current.isEmpty() || p.internalSignature.name.trim() === "") {
                setError(t('please_sign'))
            } else {
                var o = { ...p.internalSignature };
                o.signature = sigSee.current.getTrimmedCanvas().toDataURL("image/png");
                p.setInternalSignature(o);
                setStep(step + 1);
            }
        } else if (step === 1) {
            if (sigClient.current.isEmpty() || p.clientSignature.name.trim() === "") {
                setError(t('please_sign'))
            } else {
                var o = { ...p.clientSignature };
                o.signature = sigClient.current.getTrimmedCanvas().toDataURL("image/png");
                p.setClientSignature(o);
                setStep(step + 1);                
            }
        }
    }

    useEffect(()=>{
        if( p.clientSignature.signature !== "" && p.clientSignature.name !== "" ){
            p.generateReport();
            setTimeout(() => { close() }, 3000);
        } 
    }, [p.clientSignature]);

    const onNameChange = (e, type) => {
        if (type === "client") {
            var o = { ...p.clientSignature };
            o.name = e.target.value;
            p.setClientSignature(o);
        } else {
            var o = { ...p.internalSignature };
            o.name = e.target.value;
            p.setInternalSignature(o);
        }
    }

    function close(){
        p.setSign(false); 
        p.setInternalSignature({ name: user.last_name + " " + user.first_name, signature: "" });
        p.setClientSignature({ name: "", signature: "" });
    }

    return <div className="custom-tooltip-v2">

        <div className="modal-body" style={{ backgroundColor: "#cfcfc4" }}>
            <button type="button" className="btn-close" aria-label="Close" onClick={() => close()}></button>

            {step === 0 && <div>
                <h4 className="text-center mb-">B+ signature</h4>

                <div className="input-group mb-3">
                    <span className="input-group-text">{t("last name", { ns: "common" })}</span>
                    <input type="text" className="form-control text-center fw-bold" value={p.internalSignature.name}
                        onChange={(e) => onNameChange(e, "see")} />
                </div>

                <div className="text-center">
                    <SignaturePad ref={sigSee} canvasProps={{ className: "signatureCanvas" }} />
                    <br></br>
                    <button className="btn btn-success mt-3" onClick={setNext}>{t('next')}</button>
                </div>
            </div>}

            {step === 1 && <div>
                <h4 className="text-center mb-">Client signature</h4>

                <div className="input-group mb-3">
                    <span className="input-group-text">{t("last name", { ns: "common" })}</span>
                    <input type="text" className="form-control text-center fw-bold" value={p.clientSignature.name}
                        onChange={(e) => onNameChange(e, "client")} />
                </div>

                <div className="text-center">
                    <SignaturePad ref={sigClient} canvasProps={{ className: "signatureCanvas" }} />
                    <br></br>
                    <button className="btn btn-success mt-3" onClick={setNext}>{t('next')}</button>
                </div>
            </div>}

            {step === 2 && <div>
                <div className="text-center fw-bold mt-3">{t('report_creation')}</div>
            </div>}

            {error && <div className="mt-3 text-center text-danger fw-bold">{error}</div>}
        </div>

    </div>
}

export default SignatureComp;