import { createColumnHelper } from "@tanstack/react-table";
import { Fragment, useEffect, useState } from "react";
import { ArticleAutoComplete, SideNavBarV3, isSideBarOpened } from "../../common/smallComponents.js/DesignedIpt";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import errorManagement from "../../services/errorManagement";
import { downloadSingleFile } from "../../services/functions/FilesProcess";
import Movement from "../../services/storehouse/Movement";
import ArticleInfo from "../../services/articles/ArticleInfo";
import { updateDrawingUrl } from "../../functions/StrFunctions";

const ArticlesLocation = (props) => {
    const [loading, setLoading] = useState(true);
    const [locations, setLocations] = useState([]);
    const [newLocation, setNewLocation] = useState("");
    const [data, setData] = useState([]);

    const [tab, setTab] = useState(0);

    const o = { ar: "", ref: "", info: "", location: "" };
    const [newAr, setNewAr] = useState([{ ...o }]);

    const [searching, setSearching] = useState(false);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [pin, setPin] = useState(isSideBarOpened());
    const [searchingPlan, setSearchingPlan] = useState(false);

    const user = JSON.parse(localStorage.getItem("user"));
    const readOnly = user && (!user.roles.includes("ROLE_LOCATION_WRITE") && !user.roles.includes("ROLE_ADMIN"));

    const token = user && "Bearer " + user.accessToken;
    const API_URL = process.env.REACT_APP_API_URL;
    var url2 = API_URL + "articles/info/get-drawing-url?Authorization=" + token + "&url=";

    useEffect(() => {
        getItems("");
    }, []);

    const getItems = (term) => {
        Movement.getLocations(term).then(
            (response) => {
                setData(response);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
            }
        );

        Movement.getLocationsNames(term).then(
            (response) => {
                setLocations(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
            }
        );
    }

    const searchPlan = (art) => {
        setSearchingPlan(true);

        ArticleInfo.getAllArtInfoStrictSearch(art).then(
            (res) => { 
                if( res.article.ZN09PA.indexOf("Partage") === -1 ){
                    setError("Plan non trouvé");
                }else{
                    window.open( url2 + updateDrawingUrl(res.article.ZN09PA), '_blank' );
                }
                
                setSearchingPlan(false) 
            },
            (err) => { setError("Plan non trouvé"); setSearchingPlan(false) }
        )
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('ar', {
            header: 'Code AR',
            cell: i => <div className="td-bg-grey text-center fw-bold">
                <a target="_blank" href={"/article/info/get?article="+i.getValue()}>{i.getValue()}</a></div>
        }));

        arr.push(columnHelper.accessor('ref', {
            header: 'Référence',
            cell: i => <div className={"text-center d-flex "}>
                <div className="ms-auto">{i.getValue()}</div>
                <div className="me-auto">
                { i.getValue().startsWith("PL-") && 
                <button className="btn btn-outline-secondary" disabled={searchingPlan}
                    onClick={()=>searchPlan(i.row.original.ar)}>
                        {searchingPlan && <ButtonWaiting />}
                        {!searchingPlan && <img src="/common/loupe.png" title="Chercher le plan"
                            style={{width: "20px", height: "20px"}}/>}
                    </button>
                }
                </div>

            </div>
        }));

        arr.push(columnHelper.accessor('info', {
            header: 'Information complémentaire',
            cell: i => <div className={"text-center "}>
                <input className="form-control" defaultValue={i.getValue()} readOnly={readOnly}
                    onBlur={(e) => onChangeLocation(e, i.row.id, "info")}></input>
            </div>
        }));

        arr.push(columnHelper.accessor('location', {
            header: 'Emplacement',
            cell: i => <div className={"text-center "}>
                <select className="form-select" value={i.getValue()} disabled={readOnly}
                    onChange={(e) => onChangeLocation(e, i.row.id, "location")}>
                    <option value="">Choisissez...</option>
                    {locations.map((v2, k2) => {
                        return <option key={"loc_" + i.row.id + "_" + k2} value={v2.value}>{v2.value}</option>
                    })}
                </select>
            </div>
        }));

        if (!readOnly) {
            arr.push(columnHelper.accessor('xxx', {
                header: '',
                cell: i => <div className={"text-center "}>{
                    <button className="btn btn-outline-danger" onClick={() => deleteLoc(i.row.id)}>
                        Supprimer
                    </button>
                }</div>
            }));
        }

        return arr;
    }

    //Set columns
    const columns = getColumns();

    /**
     * 
     */
    const onChangeLocation = (e, k, field) => {
        var arr = [...data];
        arr[k][field] = e.target.value;
        setData(arr);

        Movement.updateLocations(field, e.target.value, arr[k].ar).then(
            (response) => { },
            (error) => {
                setError(errorManagement.handleError(props, error));
            }
        )
    }

    /**
     * 
     */
    const onNewArtChange = (e, k, field) => {
        var arr = [...newAr];
        arr[k][field] = e.target ? e.target.value : e;
        setNewAr(arr);
    }

    const onChooseArt = (e, k) => {
        var arr = [...newAr];
        arr[k].ar = e.CDARL.trim();
        arr[k].ref = e.ZN02PA;
        setNewAr(arr);
    }

    const addRow = () => {
        var arr = [...newAr];
        arr.push({ ...o });
        setNewAr(arr);
    }

    /**
     * 
     */
    const save = () => {
        setError();
        for (let i = 0; i < newAr.length; i++) {
            var o = newAr[i];
            if (o.ar.trim() === "" || o.ref === "" || o.location === "") {
                setError("Veuillez remplir tous les champs");
                return;
            }
        }

        setSearching(true);
        Movement.saveLocations(newAr).then(
            (response) => {
                setMsg("Sauvegardé !");
                setSearching(false);
                scrollToTop();
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setSearching(false);
            }
        );
    }

    /**
     * 
     * @param {*} k 
     */
    const deleteLoc = (k) => {
        var arr = [...data];
        Movement.deleteLocation(arr[k].ar);
        arr = arrayRemoveElt(arr, k);
        setData(arr);
    }

    const addLocationMenu = (e) => {
        for (let i = 0; i < locations.length; i++) {
            if (locations[i].value.trim() === newLocation.trim()) {
                setError("Cet emplacement existe déjà");
                setNewLocation("");
                return;
            }
        }

        setError();
        saveMenuLocation(newLocation, "INSERT");

        var arr = [...locations];
        arr.push({ value: newLocation });
        setLocations(arr);

        setNewLocation("");
    }

    /**
     * 
     */
    const deleteLocMenu = (k) => {
        saveMenuLocation(locations[k].value, "DELETE");

        var arr = [...locations];
        arr = arrayRemoveElt(arr, k);
        setLocations(arr);
    }

    /**
     * 
     * @param {*} location 
     * @param {*} typeChange 
     */
    const saveMenuLocation = (location, typeChange) => {
        Movement.manageMenuLocations(location, typeChange);
    }

    /**
     * 
     */
    const exportLocations = () => {
        setLoading(true);

        Movement.exportLocations().then(
            (response) => {
                var file = response.substring(response.lastIndexOf("\\") + 1, response.length);
                downloadSingleFile("temp", file);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }

    /**
     * 
     */
    const deleteNewAr = (k) => {
        var arr = [...newAr];
        arr = arrayRemoveElt(arr, k);
        setNewAr(arr);
    }

    return <div className="bg-see navbarV3 right-side">

        <SideNavBarV3 setPin={setPin} content={
            <div className="p-2">
                {loading && <WaitingRoundSnippers />}

                { !loading && <Fragment>
                    {!readOnly && <Fragment>
                        <a className="mb-3" href="#" onClick={() => setTab(0)}>Liste</a>
                        <br></br><br></br>

                        <a className="mb-3" href="#" onClick={() => setTab(2)}>Ajouter une référence</a>
                        <br></br><br></br>

                        <a className="mb-3" href="#" onClick={() => setTab(1)}>Menu déroulant</a>
                        <br></br><br></br>
                    </Fragment>}

                    <a className="mb-3" href="#" onClick={exportLocations}>Exporter au format Excel</a>
                    <br></br><br></br>

                </Fragment>}

            </div>
        }
        />

        <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>
            <h5 className="text-center">Tableau de renvois</h5>
            {error && <ErrorMessage error={error} errorState={setError} />}
            {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

            {data.length === 0 && <WaitingRoundSnippers />}

            {/** ARTICLE LIST */
                (tab === 0 && data.length > 0 && locations.length > 0) &&
                <div>
                    {!readOnly && <h6>Les modifications de la liste est mise à jour après votre saisie.
                        Aucun bouton "Valider n'est nécessaire" </h6>}

                    <ReactTable columns={columns} data={data}
                        origin="locations_ar" classes={"no-padding"} top0={true} />
                </div>
            }

            {/** LOCATIONS LIST */
                (tab === 1 && data.length > 0 && locations.length > 0) && <div className="col-8 offset-2">
                    <div className="alert alert-danger text-center fw-bold" role="alert">LES EMPLACEMENTS COMMENÇANTS PAR LA LETTRE "P"
                        SONT RÉSERVÉS AUX PNEUMATIQUES</div>

                    <table className="table">
                        <thead>
                            <tr className="table-info">
                                <th scope="col">Emplacements</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="table-secondary">
                                <td>
                                    <input className="form-control" value={newLocation}
                                        onChange={(e) => setNewLocation(e.target.value)} ></input>
                                </td>
                                <td>
                                    <button className="btn btn-outline-secondary" onClick={addLocationMenu}>Ajouter</button>
                                </td>
                            </tr>
                            {locations.map((v, k) => {
                                return <tr key={"l" + k}>
                                    <td>{v.value} </td>
                                    <td>
                                        <button className="btn btn-outline-danger" onClick={() => deleteLocMenu(k)}>
                                            Supprimer
                                        </button>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            }

            {
                (tab === 2 && locations.length > 0) && <div className="col-10 offset-1">
                    {
                        newAr.map((v, k) => {
                            return <div className="input-group mb-3" key={"newAr" + k}>
                                <span className="input-group-text">Code article</span>
                                <ArticleAutoComplete value={v.ar} onChooseArt={(e) => onChooseArt(e, k)}
                                    setValue={(val) => onNewArtChange(val, k, "ar")} searchDetail={true} />

                                <span className="input-group-text">Ref.</span>
                                <input className="form-control" value={v.ref} readOnly></input>

                                <span className="input-group-text">Infos.</span>
                                <input className="form-control" value={v.info}
                                    onChange={(e) => onNewArtChange(e, k, "info")}></input>

                                <span className="input-group-text">Emplacement</span>
                                <select className="form-select" value={v.location}
                                    onChange={(e) => onNewArtChange(e, k, "location")}>
                                    <option value="">Choisissez...</option>
                                    {locations.map((v2, k2) => {
                                        return <option key={"newAr" + k + "_" + k2} value={v2.value}>{v2.value}</option>
                                    })}
                                </select>
                                <button className="btn btn-outline-danger" onClick={() => deleteNewAr(k)}>Supprimer</button>
                            </div>
                        })
                    }

                    <button className="btn btn-outline-secondary" onClick={addRow}>Ajouter une ligne</button>

                    <div className="text-center">
                        <button className="btn btn-success" disabled={searching} onClick={save}>
                            {searching && <ButtonWaiting />}
                            Sauvegarder
                        </button>
                    </div>
                </div>
            }

        </div>
    </div>

}

export default ArticlesLocation;