import { Fragment, useEffect, useState } from "react";
import QrCodeReader from "../../../storehouse/BarCodeReader";
import ProdTrackingDao from "../../../../services/machines/ProdTrackingDao";
import errorManagement from "../../../../services/errorManagement";
import { ButtonWaiting } from "../../../../functions/PageAnimation";

const GetMachNuser = (p) => {
    const [text, setText] = useState();
    const [machine, setMachine] = useState("");
    const [trigram, setTrigram] = useState("");

    useEffect( () => {
        if( p.machineParam ) setMachine(p.machineParam);
        if( p.trigramParam ) setTrigram(p.trigramParam);
    }, [p.machineParam, p.trigramParam]);

    useEffect(() => {
        var machine = "";
        if (text) {
            if (text.indexOf("machine=")) machine = text.substring(text.indexOf("machine=") + 8, text.length);
            setMachine(machine);
            document.getElementById('trigram').focus();
        }
    }, [text]);

    const search = (e) => {
        e.preventDefault();

        p.setError();
        var machinePattern = /^[1-9][0-9]\/[1-9][0-9]{3}$/;

        if (machine.trim() === "" || trigram.trim() === "") {
            p.setError("Saisissez la machine et le trigramme");
        } else if (!machinePattern.test(machine)) {
            p.setError("Le numéro de machine doit avoir le format XX/XXXX, ex: 21/1580");
        } else {
            getInfos();
        }
    }

    const getInfos = () => {
        p.setLoading(true);
        ProdTrackingDao.getTrackingInfo(trigram, machine).then(
            (res) => {
                p.setLoading(false);
                p.setMachine(res.machineInfos);
                p.setUser(res.user);
                p.setBOM(res.bom);
                p.setStatus(res.status);
                p.setStep("choose_activity");
            }, (error) => {
                p.setError(errorManagement.handleError(p.props, error));
                p.setLoading(false);
            }
        )
    }

    return <Fragment>
        <h2 className="text-center">Contrôle production</h2>

        <form onSubmit={search}>
            <div className={(!machine ? "d-flex" : "") + " ms-auto me-auto mb-3"} style={{ width: "300px" }}>
                {machine === "" && <div className="me-2">
                    <h6 className="text-center">Scannez le Qr Code</h6>
                    <QrCodeReader setText={setText} setError={p.setError} />
                </div>}

                <div>
                    <h6 className="text-center">Ou saisissez le au clavier</h6>

                    <div className="input-group mt-auto me-auto">
                        <input className="form-control fw-bold text-center" name="machine"
                            placeholder="N° de machine COMPLET" value={machine}
                            onChange={(e) => setMachine(e.target.value)}></input>
                    </div>
                </div>
            </div>

            <div className="ms-auto me-auto mb-3" style={{ width: "300px" }}>
                <input className="form-control fw-bold text-center mb-3" id="trigram" value={trigram}
                    placeholder="Trigramme" onChange={(e) => setTrigram(e.target.value)} autoComplete="off"></input>
            </div>

            <div className="text-center mb-3">
                <button type="submit" className="btn btn-secondary" disabled={p.loading}>
                    {p.loading && <ButtonWaiting />}
                    Chercher</button>
            </div>
        </form>


        {machine !== "" && <div className="text-center">
            <button className="btn btn-outline-secondary" onClick={() => setMachine("")} disabled={p.loading}>
                {p.loading && <ButtonWaiting />}Scanner un autre Qr</button>
        </div>}
    </Fragment>

}

export default GetMachNuser;