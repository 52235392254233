import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { arrayRemoveElt, getKeyString } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import getUrlParam from "../../functions/StrFunctions";
import errorManagement from "../../services/errorManagement";
import BusinessDao from "../../services/machines/BusinessDao";
import transport from "../../services/supplyChain/transport";

const NotifyExpedition = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [machines, setMachines] = useState();
    const [truck, setTruck] = useState();
    const [detailId, setDetailId] = useState();
    const [business, setBusiness] = useState();
    const [selectedMachines, setSelectedMachines] = useState([]);

    useEffect( () => {
        var business = getUrlParam(props, "business");

        if( business ){
            setBusiness(business);
            setDetailId( getUrlParam(props, "detail_id") );
            setTruck( getUrlParam(props, "truck_nb") );
            searchBusiness(null, business)
        }

    }, []);

    const searchBusiness = (e, business_param) => {
        if(e) e.preventDefault();

        var business = business_param || e.target.business.value.toUpperCase();
        setError();
        setLoading(true);

        BusinessDao.getMachines(business).then(
            (response) => {
                var arr = new Array();
                var m = "";

                response.map(v => {
                    if (m.indexOf(v.no_machine) === -1) arr.push(v);                    
                    m += v.no_machine + ";";
                });
                
                setMachines(arr);
                setBusiness(response[0].id_aff);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

        /*MachineInfo.getAllMachineInfo(business).then(
            (response) => {
                var arr = new Array();
                var m = "";

                response.map(v => {
                    if (m.indexOf(v.no_machine) === -1) arr.push(v);                    
                    m += v.no_machine + ";";
                });
                
                setMachines(arr);
                setBusiness(response[0].id_aff);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );*/
    }

    const send = (e) => {
        e.preventDefault();
        setError();

        if (selectedMachines.length === 0) {
            setError("Selectionnez au moins une machine à expédier");
            scrollToTop();
            return;
        }

        for( let i =0; i<selectedMachines.length; i++ ){
            if( !selectedMachines[i].track ){
                setError("Selectionnez le numéro de camion pour chaque machine envoyé.<br>Remarque: si "
                    + " la machine est dans plusieurs camions, sélectionnez l'un d'eux (celui qui contient le plus de pièces par ex).");
                scrollToTop();
                return;
            }
        }

        var delivery = e.target.delivery.value;
        var comments = e.target.comments.value;
        setLoading(true);

        transport.notifyExpedition(business, selectedMachines, delivery, comments, detailId).then(
            (response) => {
                setMsg("Message envoyé");
                scrollToTop();
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )

    }

    
    {/**  */}
    const checkMachine = (e, k) => {
        var arr = [...selectedMachines];
        

        if( e.target.checked ){
            var m = [...machines];
            if(truck !== undefined) m[k].track = parseInt(truck);
            arr.push(m[k]);
        }else{
            arr = arrayRemoveElt(arr, getKeyString(arr, "no_machine", machines[k].no_machine));
        }

        setSelectedMachines(arr);
    }

    const isChecked = (machine, truck) => {
        var k = getKeyString(selectedMachines, "no_machine", machine); 
        return selectedMachines[k].track === truck;
    }

    {/** */}
    const checkTrack = (machine, track) => {
        var arr = [...selectedMachines];
        arr[getKeyString(arr, "no_machine", machine)].track = track;
        setSelectedMachines(arr);
    }

    return <Fragment>
        <h3 className="text-center">Notification d'expédition de machines</h3>
        <br></br>

        {error && <ErrorMessage error={error} />}
        {msg && <SuccessMessage msg={msg} />}

        <form className="col-6 offset-3" onSubmit={searchBusiness}>
            <div className="input-group mb-3">
                <span className="input-group-text">Code affaire</span>
                <input type="text" className="form-control fw-bold text-center" name="business" 
                    value={business} onChange={(e)=>setBusiness(e.target.value)} required />
                <button className="btn btn-success" disabled={loading}>
                    {loading && <ButtonWaiting />}
                    Rechercher
                </button>
            </div>
        </form>

        {loading && <WaitingRoundSnippers />}

        {
            machines &&
            <form onSubmit={send}>
                <br></br>
                <h4 className="text-center">Choisissez les machines que vous expédiez</h4>

                <table className="table table-bordered table-responsive ms-auto me-auto border-dark">
                    <thead>
                        <tr className="text-center">
                            <td></td>
                            {machines.map((v, k) => {
                                return <td key={"machine" + k}>
                                    { !v.delivery_date && <Fragment>
                                        <input type="checkbox" className="btn-check machine" disabled={v.delivery_date}
                                        value={v.no_machine} id={k} onClick={(e)=>checkMachine(e, k)} />
                                    <label className="btn btn-outline-success" htmlFor={k}>
                                        {v.no_machine}<br></br>
                                        {v.typ_config}
                                    </label>
                                    </Fragment>}

                                    {v.delivery_date && <div style={{fontSize: "12px"}}>
                                        {v.no_machine}<br></br>{v.typ_config}<br></br>déjà expédié avec le camion {v.track} !</div>}                                   
                                </td>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(5)].map((v, k) => {
                            return <tr key={"track" + k} className={"text-center " + 
                            ( ( truck && parseInt(truck) !== (k+1) ) ? "d-none" : "" )}>
                                <td className="fw-bold">Camion {k + 1}</td>
                                {machines.map((v2, k2) => {
                                    return <td key={"machTrack" + k + k2}>
                                        { getKeyString(selectedMachines, "no_machine", v2.no_machine) !== null &&
                                            <input className="form-check-input" type="radio" name={"track" + v2.no_machine}
                                                onChange={()=>checkTrack(v2.no_machine, (k+1))} 
                                                checked={isChecked(v2.no_machine, (k+1))}></input>
                                        }
                                        
                                    </td>
                                })}
                            </tr>
                        })}
                    </tbody>
                </table>

                <br></br>
                <div className="form-floating mb-3 col-6 offset-3">
                    <textarea className="form-control" name="comments" placeholder="xxx"></textarea>
                    <label for="floatingInput">Commentaires (optionnel)</label>
                </div>

                <br></br>
                <div className="form-floating mb-3 col-6 offset-3">
                    <input type="date" className="form-control" name="delivery" placeholder="xxx" required />
                    <label for="floatingInput">Date d'expédition</label>
                </div>

                <br></br>
                <div className="text-center">
                    <button className="btn btn-success" disabled={loading}>
                        {loading && <ButtonWaiting />}
                        Envoyer</button>
                </div>
            </form>
        }

    </Fragment>

}

export default NotifyExpedition;