import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import { ButtonWaiting } from "../../functions/PageAnimation";
import { currentEnDate, readPublicFile } from "../../functions/StrFunctions";
import fahDaoV2 from "../../services/common/fahDaoV2";
import errorManagement from "../../services/errorManagement";
import { downloadTempFile } from "../../services/functions/FilesProcess";

const Billing = (props) => {
    const [params, setParams] = useState();
    const [cart, setCart] = useState(0);
    const [curMonth, setCuronth] = useState();
    const [curYear, setCurYear] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const months = [
        { label: "Janvier", value: 1 },
        { label: "Février", value: 2 },
        { label: "Mars", value: 3 },
        { label: "Avril", value: 4 },
        { label: "Mai", value: 5 },
        { label: "Juin", value: 6 },
        { label: "Juillet", value: 7 },
        { label: "Août", value: 8 },
        { label: "Septembre", value: 9 },
        { label: "Octobre", value: 10 },
        { label: "Novembre", value: 11 },
        { label: "Décembre", value: 12 }
    ];

    useEffect(() => {
        readPublicFile("params", setParams);
        var date = currentEnDate().split("-");
        setCuronth(parseInt(date[1]));
        setCurYear(date[0]);
    }, []);

    useEffect(() => { params && setCart(params.fah_cart); }, [params]);

    const getBills = (e) => {
        e.preventDefault();

        var params = {
            cart: e.target.cart.value,
            month: e.target.month.value,
            year: e.target.year.value,
            dept: e.target.dept.value,
            agency: e.target.agency.value,
        }

        setLoading(true);
        fahDaoV2.getInterimBill(params).then(
            (r) => {
                downloadTempFile(r);
                console.log(r);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props.props, error));
                setLoading(false);
            }
        );
    }

    const getPaymentFile = (e) => {
        e.preventDefault();

        setLoading(true);
        fahDaoV2.getPaymentXls(e.target.month.value, e.target.year.value).then(
            (r) => {
                downloadTempFile(r);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props.props, error));
                setLoading(false);
            }
        );
    }

    return <Fragment>
        <h5 className="text-center mb-3">Tableau de contrôle de facturation interimaires</h5>
        {error && <ErrorMessage error={error} errorState={setError} />}

        <form className="ms-auto me-auto" style={{ maxWidth: "1200px" }} onSubmit={getBills}>
            <div className="input-group mb-3">
                <span className="input-group-text">Valeur panier</span>
                <input type="number" className="form-control text-center" value={cart}
                    name="cart" required readOnly />

                <span className="input-group-text">Mois</span>
                <select className="form-select" name="month" defaultValue={curMonth} required>
                    <option value="">Choose...</option>
                    { months.map( (v, k) => {
                        return <option value={v.value}>{v.label}</option>
                    })}
                </select>
                
                <span className="input-group-text">Année</span>
                <input type="number" className="form-control text-center"
                    name="year" defaultValue={curYear} required />

                <label className="input-group-text">Service</label>
                <select className="form-select text-center" name="dept" required>
                    <option value="">Choisissez le service</option>
                    <option value="ALL">Tous</option>
                    <option value="ACH">Achats</option>
                    <option value="ADM">Administration</option>
                    <option value="ATE">Atelier</option>
                    <option value="BE">Bureau d'étude</option>
                    <option value="CDP">Chefs de projets</option>
                    <option value="COM">Commerce</option>
                    <option value="DIR">Direction</option>
                    <option value="LOG">Logistique</option>
                    <option value="QHS">QHSE</option>
                    <option value="SAV">Service client</option>
                </select>

                <label className="input-group-text">Agence interim.</label>
                <select className="form-select text-center" name="agency" required>
                    <option value="ALL">Tous</option>
                </select>

                <button type="submit" className="btn btn-outline-success"
                    disabled={loading}>{loading && <ButtonWaiting />}Rechercher</button>
            </div>

        </form>
        <br></br>
        <h5 className="text-center mb-3">Elements Variables des FAH</h5>

        <form className="ms-auto me-auto" style={{ maxWidth: "1200px" }} onSubmit={getPaymentFile}>
            <div className="input-group mb-3">
               
                <span className="input-group-text">Mois</span>
                <select className="form-select text-center" name="month" defaultValue={curMonth} required>
                    <option value="">Choose...</option>
                    { months.map( (v, k) => {
                        return <option value={v.value}>{v.label}</option>
                    })}
                </select>
                
                <span className="input-group-text">Année</span>
                <input type="number" className="form-control text-center"
                    name="year" defaultValue={curYear} required />

                <button type="submit" className="btn btn-outline-success"
                    disabled={loading}>{loading && <ButtonWaiting />}Exporter</button>
            </div>

        </form>
    </Fragment>
}

export default Billing;