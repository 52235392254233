
const MachineHelp = () => {
    return <div className="col-10 offset-1 mt-3">
        <h5>Lignes affichées</h5>
        <p className="ms-4">
            Les lignes affichées proviennent de Focus uniquement. Si vous ne trouvez pas votre affaire ou 
            s'il manque une ligne, vérifiez que les échéanciers aient bien été renseignés sur Focus ou 
            essayez de les retrouver à l'aide d'un filtre permanent.
        </p>

        <h5>Colonnes affichées</h5>
        <p className="ms-4">
            Vous pouvez choisir les colonnes à masquer, de même que leurs ordonnancements en cliquant en haut 
            à droite du tableau sur l'icône: 
            <img src="/common/settings.png" className="ms-2" style={{ maxWidth: "40px" }}></img>
        </p>

        <h5>Codes couleurs</h5>
        <p className="display-flex ms-4">
            <div className="red-td-bg p-1 text-white rounded text-center me-1" 
                style={{width: '70px'}}>Rouge</div> Date corrigée non calculée. Modifiez le champ 
            commentaire pour déclencher le calcul puis actualisez la page.
        </p>
        <p className="display-flex ms-4">
            <div className="orange-td-bg p-1 rounded text-center me-1" 
                style={{width: '70px'}}>Orange</div> Retard de facturation. La date corrigée est postérieure à la date du jour 
            tandis que le n° de facture n'a pas été saisi sur Focus.
        </p>

        <p className="display-flex ms-4">
            <div className="purple-td-bg text-white p-1 rounded text-center me-1" 
                style={{width: '70px'}}>Violet</div> Retard de paiement. La date de paiement calculée est postérieure à la date du jour 
                tandis que la facture n'a pas été payée.
        </p>

        <h5>Filtres permanents</h5>
        <p className="ms-4">
            Contrairement aux filtres que vous pouvez faire sur l'en-tête du tableau, il s'agit d'un filtre 
            qui est sauvegardé pour vos futures visites. Il est également plus précis.
            <br></br>
            Pour enregistrer un filtre choisissez le champ sur lequel vous voulez filtrer, puis la condition 
            qui doit s'appliquer pour afficher les enregistrements. Enfin cliquez sur "Ajouter". Une fois vos filtres 
            paramétrés, cliquez sur "Actualiser la liste" pour mettre à jour le tableau avec vos filtres.
        </p>
        <p>
            <div className="fw-bold text-decoration-underline fst-italic">Exemples de filtres:</div>
            <ul>
                <li>
                    Afficher les retards de facturation:
                    <img src="/doc/bill_late.png"></img>
                </li>
                <li>
                    Afficher les retards de paiement:
                    <img src="/doc/payment_late.png"></img>
                </li>
            </ul>
        </p>
    </div>
}

export default MachineHelp;