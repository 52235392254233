
const Schedule = (props) => {
    return (
        < div className="table-responsive">
            <table className="table businessCardStyle">
                <thead>
                    <tr className="table-dark text-center rounded">
                        <th scope="col" colSpan={3}>Schedule</th>
                    </tr>
                </thead>
                <tbody>
                    <tr >
                        <td className="border-end">
                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{ width: "200px" }}>FAT</span>
                                <input type="date" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.date_fat}
                                    data-field="date_fat" />
                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{ width: "200px" }}>Delivery date</span>
                                <input type="date" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.date_livraison}
                                    data-field="date_livraison" />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{ width: "200px" }}>Commissioning date</span>
                                <input type="date" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.deb_mes_site}
                                    data-field="deb_mes_site" />
                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{ width: "200px" }}>Go-live date</span>
                                <input type="date" className="form-control"
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.deb_mep}
                                    data-field="deb_mep" />
                            </div>
                        </td>

                        <td className="border-end">
                            <div className="input-group mb-3">
                                <span className="input-group-text">Comment</span>
                                <textarea className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.com_fat_d}
                                    data-field="com_fat_d" />
                            </div>
                            
                            <div className="input-group mb-3">
                                <span className="input-group-text">Comment</span>
                                <textarea className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.com_del_d}
                                    data-field="com_del_d" />
                            </div>
                            
                            <div className="input-group mb-3">
                                <span className="input-group-text">Comment</span>
                                <textarea className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.com_mes_d}
                                    data-field="com_mes_d" />
                            </div>
                            
                            <div className="input-group mb-3">
                                <span className="input-group-text">Comment</span>
                                <textarea className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.com_mep_d}
                                    data-field="com_mep_d" />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default Schedule;