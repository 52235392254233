import { useEffect } from "react";
import { Fragment } from "react"
import addNotification from "react-push-notification"

const NotifyArticleRequest = (e) => {
    var i;

    const notify = () => {

        addNotification({
            title: 'Test',
            message: 'message',
            duration: 4000,
            native: true
        });
    }

    useEffect(() => {
        clearInterval(i);
        setInterval(() => {
            i = getResults()
        }, 5000);
    }, []);

    function getResults(){
    }

    return <Fragment>
        <button className="btn btn-outline-secondary" onClick={notify}>Click</button>
        
    </Fragment>
}

export default NotifyArticleRequest;