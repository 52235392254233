import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import isEmail from "validator/lib/isEmail";
import "react-confirm-alert/src/react-confirm-alert.css";
import subcontracting from "../../services/supplyChain/subcontracting";
import { FileUploader } from "react-drag-drop-files";
import errorManagement from "../../services/errorManagement";
import Users from "../../services/autocomplete/Users";
import Autocomplete from "react-autocomplete";
import { closeNav, openNav } from "../../functions/SideNav";
import getUrlParam from "../../functions/StrFunctions";
import { useSelector } from "react-redux";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { ButtonWaiting } from "../../functions/PageAnimation";

const SubcontractingManagement = (props) => {

    const [machine, setMachine] = useState("");
    const [machineInfo, setMachineInfo] = useState();
    const [newSubcontractor, setNewSubcontractor] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [subcontractProcessing, setSubcontractProcessing] = useState(false);
    const [addingFiles, setAddingFiles] = useState(false);
    const [subHasSharedFile, setSubHasSharedFile] = useState(false);

    const [nbOfarticle, setNbOfarticle] = useState(null);
    const [subcontractors, setSubcontractors] = useState([]);
    const [files, setFiles] = useState([]);
    const [filesMsg, setFilesMsg] = useState(null);
    const [closed, setClosed] = useState();

    const [emails, setEmails] = useState([]);
    const [syncInProgress, setSyncInProgress] = useState(false);
    const [syncRes, setSyncRes] = useState();

    const user = JSON.parse(localStorage.getItem("user"));
    const API_URL = process.env.REACT_APP_API_URL;
    const token = user && "Bearer " + user.accessToken;

    //const fileTypes = ["XLSX", "XLS", "PDF"];


    const onMachineChange = (e) => {
        setMachine(e.target.value);
    }

    function renderInputsEmails(props) {
        return <input className="form-control" id="new_subcontractor" placeholder="Saisir l'email" {...props} />
    }

    function renderEmails(val) {
        return val.email.toLowerCase().indexOf(newSubcontractor.toLocaleLowerCase()) !== -1
    }

    /**
     * Get all emails for autocomplete
     */
    useEffect(() => {
        Users.getEmails().then(
            (response) => {
                setEmails(response)
            }
        );

        //Launch search if machine is in URL param
        var machine = getUrlParam(props, "machine");
        if (machine !== null) {
            setMachine(machine);
            document.getElementById("machine-srch").value = machine;
            setTimeout(function () { document.getElementById("btn-validation").click() }, 500);
        }
    }, []);

    /**
     * Get number of articles, subcontractors contacts and files
     */
    const getSucontractingInfos = (e) => {
        e.preventDefault();

        setLoading(true);
        setError(null);
        setNbOfarticle(null);
        setSubcontractors([]);
        setFiles([]);
        setMachineInfo();

        subcontracting.getSubcontractingManagmt(machine).then(
            (response) => {

                setClosed(response.infos.closed);
                setNbOfarticle(response.article_nb);
                if (response.infos.subcontractors != null)
                    setSubcontractors(response.infos.subcontractors);
                if (response.infos.shared_files !== null)
                    setFiles(response.infos.shared_files);

                setFilesMsg(response.files_msg);
                setMachine(response.infos.machine);
                setMachineInfo(response.machine_info);
                if (response.infos.sub_shared_files !== null) setSubHasSharedFile(true);

                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }

    /**
     * Add new subcontractor
     * @returns 
     */
    const addSubcontractor = () => {
        setError(null);

        if (!isEmail(newSubcontractor)) {
            setError("Veuillez saisir une adresse mail !");
            return;
        } else {
            if (subcontractors.includes(newSubcontractor)) {
                alert("Vous l'avez déjà ajouté");
                return;
            }

            setSubcontractProcessing(true);
            document.getElementById("new_subcontractor").value = "";
            subcontracting.setNewSubcontractor(machine, newSubcontractor).then(
                (response) => {
                    setSubcontractors(response.subcontractors);
                    setSubcontractProcessing(false);
                    setNewSubcontractor("")
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    setLoading(false);
                }
            );
        }
    }

    /**
     * Remove subcontractor
     * @param {*} e 
     */
    const removeSubcontractor = (e) => {
        const sb = e.target.getAttribute("data-subct");
        setError(null);

        confirmAlert({
            title: "Annulation de partage",
            message: "Etes-vous sûr de vouloir annuler le partage avec " + sb,
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setSubcontractProcessing(true);

                        subcontracting.deleteSubcontractor(machine, sb).then(
                            (response) => {
                                setSubcontractors(response.subcontractors);
                                setSubcontractProcessing(false);
                            },
                            (error) => {
                                setError(errorManagement.handleError(props, error));
                                setLoading(false);
                            }
                        );

                    }
                },
                {
                    label: "Non"
                }
            ]
        });

    }

    /**
     * Add files to share with subcontractor
     * @param {*} file 
     */
    const onFileChange = (file) => {
        setAddingFiles(true);
        setError(null);

        try {
            subcontracting.shareFiles(machine, file, files).then(
                (response) => {
                    if (response.infos.shared_files !== null)
                        setFiles(response.infos.shared_files);

                    setAddingFiles(false);
                },
                (error) => {
                    setError(errorManagement.handleError(props, error, document));
                    document.getElementsByClassName("scroll-to-top")[0].click();
                    setAddingFiles(false);
                }
            )
        } catch (e) {
            setError(e.message);
            setAddingFiles(false);
        }
    };

    /**
     * Remove Shared file
     * @param {*} e 
     */
    const removeFile = (e) => {
        const f = new Array(e.target.getAttribute("data-file"));
        setError(null);

        confirmAlert({
            title: "Annulation de partage",
            message: "Etes-vous sûr de vouloir supprimer le fichier " + f,
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setAddingFiles(true)

                        subcontracting.removeFile(machine, f).then(
                            (response) => {
                                if (response.infos.shared_files !== null)
                                    setFiles(response.infos.shared_files);

                                setAddingFiles(false);
                            },
                            (error) => {
                                setError(errorManagement.handleError(props, error));
                                setLoading(false);
                            }
                        );

                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    };

    const removeAllFiles = (e) => {
        const f = new Array();
        setError(null);

        var list = document.getElementsByClassName("shared-files")
        for (let i = 0; i < list.length; i++)
            f.push(list[i].getAttribute("data-file"))


        confirmAlert({
            title: "Annulation de partage",
            message: "Etes-vous sûr de vouloir supprimer les " + (f.length) + " fichiers ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setAddingFiles(true)

                        subcontracting.removeFile(machine, f).then(
                            (response) => {
                                setFiles(response.shared_files);
                                setAddingFiles(false);
                            },
                            (error) => {
                                setError(errorManagement.handleError(props, error));
                                setLoading(false);
                            }
                        );

                    }
                },
                {
                    label: "Non"
                }
            ]
        });

    };

    /**
     * Download file shared
     * @param {*} e 
     */
    const downloadFile = (e) => {
        const f = e.target.getAttribute("data-file");
        setError(null);
        setAddingFiles(true);

        subcontracting.downloadFile(f).then(
            (response) => {
                var csvURL = window.URL.createObjectURL(response.data);
                var tempLink = document.createElement('a');
                tempLink.href = csvURL;
                tempLink.setAttribute('download', f);
                tempLink.click();
                setAddingFiles(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );



    };

    const closeMachine = () => {
        subcontracting.closeMachine(machine, closed).then((response) => { setClosed(parseInt(response)) });
    }

    function downloadSubcontractorFiles() {
        window.open("", "_blank").location.href =
            API_URL + "subcontracting/download-subcontractor-all-files?machine=" + machine + "&Authorization=" + token;
    }

    const sync = () => {
        setSyncInProgress(true);
        setSyncRes();
        
        subcontracting.syncBom(machineInfo.no_machine).then(
            (response) => {
                setSyncRes(response);
                setSyncInProgress(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setSyncInProgress(false);
            }
        );
    }

    return (
        <div>
            <h3 className="text-center">Gestion de sous-traitance</h3>

            {
                error && (
                    <div className="alert alert-danger text-center font-weight-bold" role="alert">{error}</div>
                )
            }


            <form onSubmit={getSucontractingInfos}>
                <div className="row justify-content-center">
                    <div className="col-6">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Machine</span>
                            </div>
                            <input type="text" className="form-control text-center font-weight-bold"
                                id="machine-srch" onChange={onMachineChange} />
                            <button type="submit" id="btn-validation" className="btn btn-success" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>Rechercher</span></button>
                        </div>
                    </div>
                </div>
            </form>

            {
                nbOfarticle && (
                    <div>
                        <div id="mySidenav" className="sidenav text-white">
                            <a href="#" className="closebtn" onClick={closeNav}>&times;</a>
                            <div style={{ width: "90%", height: "90%", marginLeft: "auto", marginRight: "auto", overflow: "auto" }}>
                                <h3>Tableau de colisage</h3>
                                <p>Voir le tableau de colisage en temps réel dans un nouvel onglet</p>
                                <br />
                                <h3>Machine clôturée:</h3>
                                <p>Retire la machine de l'onglet "En cours de partage" dans votre liste.
                                    Et interdit au ST d'accéder à nouveau à cette machine.
                                    Si la case est cochée alors c'est clôturé sinon c'est ouvert.
                                    Vous pouvez clôturer et déclôturer à tout moment sans perdre de données.</p>
                                <br />
                                <h3>Cette machine est partagée avec:</h3>
                                <p>De base, la machine est partagée avec les sous-traitants
                                    mentionnés dans la fiche machine (Lantech, Tecma, AMME ou SEE US).<br></br>
                                    Les contacts de ces sous-traitants sont à gérer &nbsp;
                                    <a href="/settings/contacts-mng" target="_blank"
                                        style={{ textDecoration: "underline", display: "inline", fontSize: "1rem", fontWeight: "bold" }}>ici</a>.
                                    Si vous devez en rajouter, saisissez le et cliquez sur "Ajouter".
                                </p>
                                <br />
                                <h3>Vous avez partagé les fichiers suivants:</h3>
                                <p>
                                    Il est inscrit la liste des sous-ensemble. <br></br>
                                    - Ligne est rouge: vous n'avez pas importé ce fichier.
                                    <span className="fw-bold text-warning"> Attention aux indices des plans!</span>
                                    <br></br>
                                    - Ligne verte: le fichier a été importé
                                    <br></br>
                                    - Ligne grise: fichier importé mais ne correspondant pas à un
                                    sous-ensemble de la nomenclature
                                </p>
                                <p>Pour partager un ou plusieurs fichiers, cliquez sur n'importe quel sous-ensemble ou
                                    glissez les fichiers sur n'importe quel sous-ensemble.
                                </p>
                                <p>Remarque: s'il est inscrit, par exemple, qu'il y a 2 fichiers
                                    en trop mais que vous ne voyez qu'une ligne en gris, cela peut
                                    signifier que vous avez importé 2 fois le même fichier mais la liste
                                    ne dédouble pas la ligne. Rien de gênant et aucune action est requise.
                                    Le fichier ne pourra pas être téléchargé 2 fois.</p>
                            </div>
                        </div>

                        <button type="button" className="btn btn-secondary see-bg"
                            onClick={openNav} style={{ position: "absolute", marginBottom: "5px" }}>
                            <img src="/common/menu.png" style={{ width: "25px" }}></img>
                        </button>
                    </div>
                )
            }

            {
                machineInfo &&
                <div className="alert alert-secondary col-6 offset-3 text-center fw-bold">
                    <span>{machineInfo.affaire}<br></br>
                    {machineInfo.no_machine} ({machineInfo.typ_config})</span>
                    <br></br>
                    { !syncInProgress && 
                        <a href="#" onClick={sync}>Synchroniser la nomenclature avec l'intranet de base</a>
                    }
                    { syncInProgress && <WaitingRoundSnippers /> }
                    {
                        syncRes && 
                            <div className="fst-italic">
                                {syncRes.added} ajout(s) et {syncRes.removed} suppression(s)
                                {
                                    syncRes.resume != null &&
                                        <div>
                                            <a target="_blank"
                                                href={process.env.REACT_APP_API_URL +"common/share-file/public/download/single?folder=history bom update&file=" 
                                                        + syncRes.resume.substring( syncRes.resume.lastIndexOf("\\")+1, syncRes.resume.length ) }>
                                                            Télécharger le récapitulatif</a>
                                        </div>
                                }
                            </div>
                    }
                </div>
            }

            {
                nbOfarticle && (
                    <div>
                        <br></br>
                        <h4 className="text-center">Cette machine contient {nbOfarticle} articles.</h4>
                        <div className="text-center">
                            <a className="btn btn-info" href={'/subcontracting/packing-list?machine=' + machine} target="_blank">Voir le tableau de colisage</a>
                        </div>
                        <br />

                        <div className="col-6 offset-3">
                            <div className="input-group mb-3">
                                <div className="input-group-text">
                                    <input className="form-check-input mt-0" type="checkbox"
                                        defaultChecked={closed === 1}
                                        onClick={closeMachine} />
                                </div>
                                <input type="text" className="form-control fw-bold" placeholder="Machine clôturée" readOnly />
                            </div>
                        </div>

                        {
                            subHasSharedFile &&
                            <div className="text-center">
                                 <br></br>
                                <a href="#" className="btn btn-warning" onClick={downloadSubcontractorFiles}>Télécharger les fichiers du sous-traitant</a>
                                <br></br><br></br>
                            </div>
                        }

                        <h4 className="text-center">Cette machine est partagée avec:</h4>
                        <ul className="list-group col-6 offset-3">
                            {subcontractors &&
                                subcontractors.map((s, key) =>
                                    <li key={key} className="list-group-item list-group-item-secondary text-center font-weight-bold">
                                        {s}
                                        <img src="/common/remove_icon.png"
                                            style={{ position: "absolute", width: "30px", right: "0" }}
                                            onClick={removeSubcontractor}
                                            data-subct={s} />
                                    </li>)
                            }

                            {
                                subcontractProcessing && (
                                    <div className="text-center">
                                        <div className="spinner-grow text-info" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>
                                )
                            }

                        </ul>
                        <br></br>
                       
{/*
                            <Autocomplete
                                getItemValue={(emails) => emails.email}
                                items={emails}
                                renderItem={(item, isHighlighted) =>
                                    <div key={item.email}
                                        style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                                        className="form-control" id="new_subcontractor" placeholder="Saisir l'email"
                                    >
                                        {item.email}
                                    </div>
                                }
                                value={newSubcontractor}
                                onChange={(e) => setNewSubcontractor(e.target.value)}
                                onSelect={(val) => setNewSubcontractor(val)}
                                shouldItemRender={renderEmails}
                                renderInput={renderInputsEmails}
                            />*/}

                            <form onSubmit={addSubcontractor} className="mb-3 col-6 offset-3">
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">Ajouter</span>
                                    <input type="text" className="form-control" list="emails" placeholder="Saisir l'email" />
                                    <datalist id="emails">
                                    {
                                        emails.map( (v, k) => {
                                            return  <option key={"mails"+k} value={v.email}></option>
                                        } )
                                    }    
                            </datalist>

                                    <button type="submit" className="btn btn-success" disabled={loading}>Ajouter
                                        {loading && <ButtonWaiting />}
                                    </button>
                                </div>
                                
                            </form>

                            

                        <div style={{ width: 200, margin: 20 }}>

                        </div>

                        <br />
                        {
                            filesMsg && (
                                <div className="row justify-content-center">
                                    <div className="alert alert-info text-center font-weight-bold col-6" role="alert">{filesMsg}</div>
                                </div>
                            )
                        }
                        <h4 className="text-center">Vous avez partagé les fichiers suivants:</h4>
                        <ul className="list-group col-6 offset-3">
                            {files && files.length > 0 &&
                                <button className="btn btn-danger" onClick={removeAllFiles}>Tout supprimer</button>
                            }
                            {
                                files &&
                                <div>
                                    {files.map((f, key) =>
                                        <li key={key}
                                            className={'list-group-item list-group-item-'
                                                + (f.exists === null ? "secondary" : f.exists ? "success" : "danger")
                                                + ' font-weight-bold'}>
                                            <div style={{ display: "flex" }}>
                                                <FileUploader handleChange={onFileChange}
                                                    name="file" multiple={true}
                                                    disabled={addingFiles}
                                                    classes="w-100"
                                                    children={
                                                        <span data-file={f.file} className="shared-files">{f.file}</span>
                                                    } />


                                                {addingFiles && <div className="spinner-border" role="status"
                                                    style={{ position: "absolute", width: "30px", right: "0" }}></div>}

                                                {
                                                    !addingFiles &&
                                                    <img src="/common/remove_icon.png"
                                                        style={{ width: "30px", right: "0" }}
                                                        onClick={removeFile} data-file={f.file} />
                                                }

                                            </div>
                                        </li>)}
                                </div>

                            }
                        </ul>
                        <br />


                    </div>
                )
            }
        </div>
    );

}

export default SubcontractingManagement;