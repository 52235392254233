import React, { useState, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { forgottenPassord } from "../../actions/auth";
import authService from "../../services/auth.service";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import { useTranslation } from "react-i18next";

const ForgottenPassword = (props) => {
    const form = useRef();
    const checkBtn = useRef();

    const [email, setEmail] = useState("");
    const [email2, setEmail2] = useState();
    const [successful, setSuccessful] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [sendTo, setSendTo] = useState();

    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };

    const handleRegister = (e) => {
        e.preventDefault();

        setLoading(true);
        setSuccessful(false);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            dispatch(forgottenPassord(email, email2))
                .then(() => {
                    setSuccessful(true);
                    setLoading(false);
                })
                .catch(() => {
                    setSuccessful(false);
                    setLoading(false);
                });

        } else {
            setLoading(false);
        }
    };

    const checkUser = (e) => {
        e.preventDefault();
        setError();

        setLoading(true);

        authService.getUserByEmailOrUserName(email).then(
            (r) => {
                setSendTo([r.email, r.manager_mail]);
                setEmail(r.email);
                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }

    return <Fragment>
        {error && <ErrorMessage error={error} />}

        <Form onSubmit={checkUser} ref={form}>
            {!successful && (
                <div className="col-4 offset-4">
                    <div className="input-group">
                        <span className="input-group-text" htmlFor="mail">{t('username') + ' ' + t('or')} Email</span>
                        <input type="text" className="form-control" name="email"
                            value={email} onChange={onChangeEmail} readOnly={sendTo || loading} required></input>

                        <button className="btn btn-primary btn-block" disabled={loading}>
                            {loading && <ButtonWaiting />}
                            <span>{t('check')}</span>
                        </button>
                    </div>


                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </div>
            )}

            {message && (
                <div className="form-group col-6 offset-3">
                    <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                        {message}
                    </div>
                </div>
            )}

        </Form>

        {
            ( sendTo && !successful) && <div className="mt-3 col-4 offset-4">
                <h6>{t('choose-mail')}</h6>

                <div className="form-check">
                    <input className="form-check-input" type="radio" onChange={() => setEmail2(sendTo[0])}
                        checked={email2 === sendTo[0]} />
                    <label className="form-check-label">
                        {sendTo[0]}
                    </label>
                </div>

                <div className="form-check">
                    <input className="form-check-input" type="radio" onChange={() => setEmail2(sendTo[1])}
                        checked={email2 === sendTo[1]} />
                    <label className="form-check-label">
                        {sendTo[1]}
                    </label>
                </div>

                {
                    email2 && <div className="text-center mt-3">
                        <button className="btn btn-outline-success" onClick={handleRegister} disabled={loading}>
                            {loading && <ButtonWaiting />}
                            {t('reset-pwd')}
                        </button>
                    </div>
                }
            </div>
        }
    </Fragment>
};

export default ForgottenPassword;