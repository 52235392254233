import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Fragment, useEffect, useState } from "react";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { arrayRemoveElt } from "../../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../../functions/PageAnimation";
import { currentEnDate, extractValueFromTag, extractValueFromTagV2, getBase64, updateTagValue } from "../../../functions/StrFunctions";
import CheckList from "../../../services/customClient/CheckList";
import errorManagement from "../../../services/errorManagement";
import ErrorMessage from "../../../common/smallComponents.js/ErrorMessage";
import { downloadSingleFile, downloadTempFile } from "../../../services/functions/FilesProcess";
import { useTranslation } from "react-i18next";
import SignatureComp from "./SignatureComp";

const InterventionReport = (p) => {
    const [reports, setReports] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [missingAnsw, setMissingAnsw] = useState();
    const { t } = useTranslation(['check-list', 'common']);

    const contentBlock = htmlToDraft("");
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(contentState))

    const user = JSON.parse(localStorage.getItem("user"));
    const [sign, setSign] = useState(false);
    const [clientSignature, setClientSignature] = useState({ name: "", signature: "" });
    const [internalSignature, setInternalSignature] = useState({ name: user.last_name + " " + user.first_name, signature: "" });

    const intReportObj = {
        mach_nb: "", date: "", form: "commissioning", id: -1, fault: "", cause: "",
        action: "", solution: "", boxQuality: "", status: "", meters: "", departure: "",
        category: ""
    }

    useEffect(() => {
        //Check if all answers are been set
        setMissingAnsw();

        var err = "";
        p.compilation.map((v) => {
            v.form.map((v2) => {
                if (v2.hasConform === 1 && v2.conform === null) {
                    if (err.indexOf("Machine " + v.machine.trim()) === -1)
                        err += "<h5 className=\"mt-3\">" + "Machine " + v.machine.trim() + "</h5>";

                    if (err.indexOf(v2.chapter_label) === -1)
                        err += "<h6>Chapitre " + v2.chapter_label + "</h6>"

                    err += "<span>Question " + v2.question + ") " + v2.question_label + "</span><br>"
                }
            })
        })

        if (err !== "")
            setMissingAnsw("Vous devez répondre à toutes les questions de la check "
                + "list avant de poursuivre, il manque:<br>" + err)

    }, []);

    useEffect(() => {
        if (p.reports) createTreeReports(p.reports);

        if (p.reports && p.reports[0].value2) {
            var obs = p.reports[0].value2

            const contentBlock = htmlToDraft(obs);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(() => EditorState.createWithContent(contentState));
        }
    }, [p.reports]);

    {/** */ }
    function createTreeReports(reports) {
        //sort reports
        reports = reports.sort((r1, r2) => (r1.mach_nb.trim().localeCompare(r2.mach_nb.trim())));

        var machines = new Array();
        var machObj = new Object();
        var machReports = new Array();

        reports.map((v, k) => {
            if (k === 0) {
                machObj.machine = v.mach_nb.trim();
                machObj.date = v.creation_date;
                machObj.value3 = v.value3;
                machObj.parentIdx = k;
            } else if (v.mach_nb.trim() !== reports[k - 1].mach_nb.trim()) {
                machObj.report = machReports;
                machines.push(machObj);

                machObj = new Object();
                machReports = new Array();
                machObj.machine = v.mach_nb.trim();
                machObj.date = v.creation_date;
                machObj.value3 = v.value3;
                machObj.parentIdx = k;
            }

            v.parentIdx = k;

            if (v.id) machReports.push(v);
        })

        machObj.report = machReports;
        machines.push(machObj);

        setReports(machines)
    }

    {/** */ }
    function getMachineInfo(machine, field) {
        for (let i = 0; i < p.machines.length; i++)
            if (machine === p.machines[i].no_machine) return p.machines[i][field];

        return "";
    }

    {/** */ }
    function getCommisionningStates(arr) {
        var nc = new Array();
        for (let i = 0; i < arr.length; i++) if (arr[i].hasConform && arr[i].conform === 0) nc.push(arr[i]);

        return <Fragment>
            {nc.length === 0 && <span className="ms-3 text-success fw-bold">RAS</span>}
            {nc.length > 0 && <ul>
                {nc.map((v, k) => { return <li key={"nc" + k + v.mach_nb}>{v.question_label}</li> })}
            </ul>}
        </Fragment>
    }

    {/** */ }
    const addReport = (machine, category, date, closed) => {
        var arr = [...p.reports];
        var o = { ...intReportObj };
        o.mach_nb = machine;
        o.category = category;
        o.date = date;
        o.closed = closed ? 1 : 0;
        o.chk_gp_id = p.id;

        setLoading(true);
        CheckList.addReport(o.mach_nb, o.date, o.form, o.category, o.closed, o.chk_gp_id).then(
            (response) => {
                o.id = response;
                arr.push(o);
                p.setReports(arr);
                setLoading(false);
            }, (error) => {
                setLoading(false);
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        );
    }

    {/** */ }
    const updateReport = (field, value, id, parentIdx) => {
        var arr = [...p.reports];

        arr[parentIdx][field] = value;
        p.setReports(arr);

        CheckList.updateReport(field, value, id).then(
            () => { }, (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        );
    }

    {/** */ }
    const deleteReport = (id, parentIdx) => {
        var arr = [...p.reports];
        arr = arrayRemoveElt(arr, parentIdx);
        p.setReports(arr);

        CheckList.deleteReport(id).then(
            () => { }, (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        );
    }

    {/** Save comments */ }
    const saveComments = () => {
        var obs = draftToHtml(convertToRaw(editorState.getCurrentContent()));

        var arr = [...p.reports]
        arr[0].value2 = obs;
        p.setReports(arr);

        //Save in database
        CheckList.updateHeaderById(p.typeForm, p.id, "value2", obs);
    }

    const writeValue3 = (v, k, field, value) => {
        var value3 = v.value3;
        var extVal = extractValueFromTagV2(value3, field);

        if (extVal === null ) {
            value3 += "<" + field + ">" + value + "</" + field + ">";
        } else {
            value3 = updateTagValue(value3, field, value);
        }

        //Update current state
        var arr = [...reports];
        arr[k].value3 = value3;
        setReports(arr);

        //Update parent state
        var arr2 = [...p.reports];
        arr2[arr[k].parentIdx].value3 = value3;
        p.setReports(arr2);

        //Save in database
        CheckList.updateHeader(arr[k].machine, arr[k].date, p.typeForm, 1, "value3", value3)
    }

    {/** */ }
    const addAttachment = (e, k) => {
        getBase64(e.target.files[0], (res) => {
            var arr = [...p.compilation];
            var o = {
                file: res,
                machine: arr[k].machine
            }
            arr[k].attachments.push(o);
            p.setCompilation(arr);

            //Save in data base
            CheckList.addAttachment(p.typeForm, arr[k].machine, currentEnDate(), -1, res, p.id).then(
                (response) => {
                    var arr2 = [...p.compilation];
                    o.id = response;
                    arr2[k].attachments[arr[k].attachments.length - 1] = o;
                    p.setCompilation(arr2);
                }
            )
        });

    }

    {/** */ }
    const deleteAttachment = (id, k, k2) => {
        var arr = [...p.compilation];
        var arr2 = arr[k].attachments

        arr2 = arrayRemoveElt(arr2, k2);
        arr[k].attachments = arr2;

        p.setCompilation(arr);

        CheckList.deleteAttachment(id);
    }

    {/** */ }
    const saveInterventionReason = (e) => {
        CheckList.updateHeaderById(p.typeForm, p.id, "value1", e.target.value);
        var arr = [...p.reports]
        arr[0].value1 = e.target.value;
        p.setReports(arr);
    }

    {/** */ }
    const generateReport = () => {
        setLoading(true);
        setError();

        CheckList.generateCommissioningReport(p.machines, p.compilation, p.reports,
            internalSignature, clientSignature, p.lang).then(
                (response) => {
                    if (response.indexOf("\\temp") !== -1) { downloadTempFile(response); }
                    else { downloadSingleFile("maintenance_chk_list", response); }

                    setLoading(false);
                }, (error) => {
                    console.log(error)
                    setError(errorManagement.handleError(p.props, error));
                    setLoading(false);
                    scrollToTop();
                }
            )
    }

    return <Fragment>
        {error && <ErrorMessage error={error} />}
        {missingAnsw && <ErrorMessage error={missingAnsw} noCenter />}

        {!missingAnsw && <Fragment>
            {/** RAISON INTERVENTION */}
            <div className="card">
                <div className="input-group mb-3">
                    <span className="input-group-text">{t('reason_interv')}</span>
                    <select className="form-select" defaultValue={p.reports[0].value1}
                        onChange={saveInterventionReason} >
                        <option value="">Choose...</option>
                        <option value={t("commissioning")}>{t("commissioning")}</option>
                        <option value={t("prod_startup")}>{t("prod_startup")}</option>
                        <option value={t("go_live")}>{t("go_live")}</option>
                        <option value={t("remove_reserv")}>{t("remove_reserv")}</option>
                        <option value={t("Retrofit")}>{t("Retrofit")}</option>
                    </select>
                </div>
            </div>

            {/** STATUT MES */}
            <div className="card">
                <h5 className="mb-3">{t("commiss_status")}</h5>

                {/** Others machines */
                    p.compilation && p.compilation.map((v, k) => {
                        return <div className="mb-1" key={"cs" + k}>
                            <h5>Machine {v.machine} ({getMachineInfo(v.machine, "typ_config")})</h5>
                            {getCommisionningStates(v.form)}
                        </div>
                    })}

            </div>

            {/** TRAVAIL EFFECTUE */}
            <div className="card">
                <h5 className="mb-3">{t('work_perf')}</h5>

                {/**Others machines*/
                    reports && reports.map((v, k) => {
                        return <div className="mb-5" key={"ir" + k}>
                            <h5>Machine {v.machine} ({getMachineInfo(v.machine, "typ_config")})</h5>

                            <div className="input-group mb-3" style={{ maxWidth: "500px" }}>
                                <span className="input-group-text">{t('meters_arr')}</span>
                                <input type="number" className="form-control" defaultValue={extractValueFromTag(v.value3, "meter_arrival")}
                                    onBlur={(e) => writeValue3(v, k, "meter_arrival", e.target.value)} />

                                <span className="input-group-text" >{t('departure')}</span>
                                <input type="number" className="form-control" defaultValue={extractValueFromTag(v.value3, "meter_departure")}
                                    onBlur={(e) => writeValue3(v, k, "meter_departure", e.target.value)} />
                            </div>

                            <div className="table-responsive ms-3">
                                <table className="table text-center" style={{ minWidth: "800px" }}>
                                    <thead>
                                        <tr className="table-secondary">
                                            <th>{t('fault_name')}</th>
                                            <th>Cause</th>
                                            <th>Action</th>
                                            <th>Faulty parts to be changed or upgraded</th>
                                            <th>{t('status')}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {v.report.map((v2, k2) => {
                                            return v2.category === "job_done" &&
                                                <tr key={"rep" + k + "-" + k2}>
                                                    <td>
                                                        <input className="form-control" defaultValue={v2.fault}
                                                            onBlur={(e) => updateReport("fault", e.target.value, v2.id, v2.parentIdx)}></input>
                                                    </td>
                                                    <td>
                                                        <input className="form-control" defaultValue={v2.cause}
                                                            onBlur={(e) => updateReport("cause", e.target.value, v2.id, v2.parentIdx)}></input>
                                                    </td>
                                                    <td>
                                                        <input className="form-control" defaultValue={v2.action}
                                                            onBlur={(e) => updateReport("action", e.target.value, v2.id, v2.parentIdx)}></input>
                                                    </td>
                                                    <td>
                                                        <input className="form-control" defaultValue={v2.solution}
                                                            onBlur={(e) => updateReport("solution", e.target.value, v2.id, v2.parentIdx)}></input>
                                                    </td>
                                                    <td>
                                                        <select className="form-select" defaultValue={v2.closed}
                                                            onChange={(e) => updateReport("closed", e.target.value, v2.id, v2.parentIdx)}>
                                                            <option value="">Choose...</option>
                                                            <option value={1}>{t('solved')}</option>
                                                            <option value={0}>{t('not_solved')}</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <img src="/common/remove_icon.png" style={{ maxWidth: "30px" }}
                                                            onClick={() => deleteReport(v2.id, v2.parentIdx)} />
                                                    </td>
                                                </tr>
                                        })}
                                        <tr>
                                            <td colSpan={5}>
                                                <button className="btn btn-outline-secondary" disabled={loading}
                                                    onClick={() => addReport(v.machine, "job_done", v.date, true)}>
                                                    {loading && <ButtonWaiting />}
                                                    Ajouter
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <table className="table text-center" style={{ minWidth: "800px" }}>
                                    <thead>
                                        <tr className="table-secondary">
                                            <th>{t('box_qual')}</th>
                                            <th>{t('throughput')} {t('theo_real')}</th>
                                            <th>{t('test_status')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="col-4">
                                                <select className="form-select" defaultValue={extractValueFromTag(v.value3, "box_quality")}
                                                    onBlur={(e) => writeValue3(v, k, "box_quality", e.target.value)}>
                                                    <option value="">Choose...</option>
                                                    <option value="1">{t('good')}</option>
                                                    <option value="0">{t('bad')}</option>
                                                </select>
                                            </td>
                                            <td className="col-4">
                                                <div className="input-group mb-3 ms-auto me-auto" style={{ maxWidth: "200px" }}>
                                                    <input className="form-control text-center fw-bold" value={getMachineInfo(v.machine, "cadence_mach")} readOnly></input>
                                                    <input className="form-control text-center fw-bold" onBlur={(e) => writeValue3(v, k, "throughput", e.target.value)}
                                                        defaultValue={extractValueFromTag(v.value3, "throughput")} ></input>
                                                </div>
                                            </td>
                                            <td className="col-4">
                                                <select className="form-select" defaultValue={extractValueFromTag(v.value3, "test_status")}
                                                    onBlur={(e) => writeValue3(v, k, "test_status", e.target.value)}>
                                                    <option value="">Choose...</option>
                                                    <option value="1">{t('validated')}</option>
                                                    <option value="0">{t('not_validated')}</option>
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    })}


            </div>

            <div className="card">
                <h5 className="mb-3">Photos</h5>

                {p.compilation.map((v, k) => {
                    return <div key={"img_mach_" + k} className="mb-3">
                        <h6>Machine {v.machine}</h6>

                        {v.attachments.map((v2, k2) => {
                            return <div key={"photo" + k2} className="mb-2">
                                <img src={v2.file} style={{ maxWidth: "150px" }} />

                                {!v2.id && <div>Saving...</div>}
                                {v2.id && <div className="text-danger cursor-pointer" onClick={() => deleteAttachment(v2.id, k, k2)}>Supprimer</div>}
                            </div>
                        })}

                        <div className="input-group mb-3">
                            <input type="file" className="form-control" accept="image/png, image/gif, image/jpeg"
                                onChange={(e) => addAttachment(e, k)} />
                        </div>
                    </div>
                })

                }
            </div>

            <div className="card">
                <h5>Observations</h5>


                <Editor editorState={editorState}
                    onEditorStateChange={setEditorState} onBlur={saveComments}
                    wrapperClassName="draftEditor-wrapper" editorClassName="draftEditor-editor" />
            </div>

            {/** TIMES */}
            <div className="card">
                <h5>Temps de travail</h5>

                <div className="table-responsive">
                    <table style={{ borderStyle: "solid", borderWidth: "thin" }}>
                        <tr>
                            <td className="text-end border border-dark p-2">
                                <span className="me-1 fw-bold">Jour</span>
                                <span className="fs-6 fst-italic">Day</span>
                            </td>

                            <td className="text-center border border-dark p-2" colSpan={2}>
                                <span className="me-1 fw-bold">Lundi</span><br></br>
                                <span className="fs-6 fst-italic">Monday</span>
                            </td>

                            <td className="text-center border border-dark p-2" colSpan={2}>
                                <span className="me-1 fw-bold">Mardi</span><br></br>
                                <span className="fs-6 fst-italic">Tuesday</span>
                            </td>

                            <td className="text-center border border-dark p-2" colSpan={2}>
                                <span className="me-1 fw-bold">Mercredi</span><br></br>
                                <span className="fs-6 fst-italic">Wednesday</span>
                            </td>

                            <td className="text-center border border-dark p-2" colSpan={2}>
                                <span className="me-1 fw-bold">Jeudi</span><br></br>
                                <span className="fs-6 fst-italic">Thursday</span>
                            </td>

                            <td className="text-center border border-dark p-2" colSpan={2}>
                                <span className="me-1 fw-bold">Vendredi</span><br></br>
                                <span className="fs-6 fst-italic">Friday</span>
                            </td>

                            <td className="text-center border border-dark p-2" colSpan={2}>
                                <span className="me-1 fw-bold">Samedi</span><br></br>
                                <span className="fs-6 fst-italic">Saturday</span>
                            </td>

                            <td className="text-center border border-dark p-2" colSpan={2}>
                                <span className="me-1 fw-bold">Dimanche</span><br></br>
                                <span className="fs-6 fst-italic">Sunday</span>
                            </td>
                        </tr>

                        <tr>
                            <td className="text-end border border-dark p-2">
                                <span className="me-1 fw-bold">Horaires</span>
                                <span className="fs-6 fst-italic">Time</span>
                            </td>

                            <td className="text-center fw-bold border border-dark p-2">A</td>
                            <td className="text-center fw-bold border border-dark p-2">D</td>

                            <td className="text-center fw-bold border border-dark p-2">A</td>
                            <td className="text-center fw-bold border border-dark p-2">D</td>

                            <td className="text-center fw-bold border border-dark p-2">A</td>
                            <td className="text-center fw-bold border border-dark p-2">D</td>

                            <td className="text-center fw-bold border border-dark p-2">A</td>
                            <td className="text-center fw-bold border border-dark p-2">D</td>

                            <td className="text-center fw-bold border border-dark p-2">A</td>
                            <td className="text-center fw-bold border border-dark p-2">D</td>

                            <td className="text-center fw-bold border border-dark p-2">A</td>
                            <td className="text-center fw-bold border border-dark p-2">D</td>

                            <td className="text-center fw-bold border border-dark p-2">A</td>
                            <td className="text-center fw-bold border border-dark p-2">D</td>
                        </tr>

                        <tr>
                            <td className="text-end border border-dark p-2">
                                <span className="me-1 fw-bold">Matin</span>
                                <span className="fs-6 fst-italic">AM</span>
                            </td>

                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "monday_a_am")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "monday_a_am", e.target.value)}></input>
                            </td>
                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "monday_d_am")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "monday_d_am", e.target.value)}></input>
                            </td>

                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "tuesday_a_am")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "tuesday_a_am", e.target.value)}></input>
                            </td>
                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "tuesday_d_am")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "tuesday_d_am", e.target.value)}></input>
                            </td>

                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "wednesday_a_am")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "wednesday_a_am", e.target.value)}></input>
                            </td>
                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "wednesday_d_am")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "wednesday_d_am", e.target.value)}></input>
                            </td>

                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "thursday_a_am")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "thursday_a_am", e.target.value)}></input>
                            </td>
                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "thursday_d_am")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "thursday_d_am", e.target.value)}></input>
                            </td>

                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "friday_a_am")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "friday_a_am", e.target.value)}></input>
                            </td>
                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "friday_d_am")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "friday_d_am", e.target.value)}></input>
                            </td>

                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "saturday_a_am")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "saturday_a_am", e.target.value)}></input>
                            </td>
                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "saturday_d_am")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "saturday_d_am", e.target.value)}></input>
                            </td>

                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "sunday_a_am")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "sunday_a_am", e.target.value)}></input>
                            </td>
                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "sunday_d_am")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "sunday_d_am", e.target.value)}></input>
                            </td>
                        </tr>

                        <tr>
                            <td className="text-end border border-dark p-2">
                                <span className="me-1 fw-bold">Après-midi</span>
                                <span className="fs-6 fst-italic">PM</span>
                            </td>

                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "monday_a_pm")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "monday_a_pm", e.target.value)}></input>
                            </td>
                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "monday_d_pm")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "monday_d_pm", e.target.value)}></input>
                            </td>

                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "tuesday_a_pm")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "tuesday_a_pm", e.target.value)}></input>
                            </td>
                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "tuesday_d_pm")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "tuesday_d_pm", e.target.value)}></input>
                            </td>

                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "wednesday_a_pm")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "wednesday_a_pm", e.target.value)}></input>
                            </td>
                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "wednesday_d_pm")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "wednesday_d_pm", e.target.value)}></input>
                            </td>

                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "thursday_a_pm")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "thursday_a_pm", e.target.value)}></input>
                            </td>
                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "thursday_d_pm")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "thursday_d_pm", e.target.value)}></input>
                            </td>

                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "friday_a_pm")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "friday_a_pm", e.target.value)}></input>
                            </td>
                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "friday_d_pm")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "friday_d_pm", e.target.value)}></input>
                            </td>

                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "saturday_a_pm")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "saturday_a_pm", e.target.value)}></input>
                            </td>
                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "saturday_d_pm")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "saturday_d_pm", e.target.value)}></input>
                            </td>

                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "sunday_a_pm")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "sunday_a_pm", e.target.value)}></input>
                            </td>
                            <td className="text-center fw-bold border border-dark p-2">
                                <input className="form-control text-center" style={{ minWidth: "70px" }} 
                                    defaultValue={extractValueFromTag(p.reports[0].value3, "sunday_d_pm")}
                                    onBlur={(e) => writeValue3(p.reports[0], 0, "sunday_d_pm", e.target.value)}></input>
                            </td>
                        </tr>

                    </table>
                </div>
            </div>

            <div className="text-center mt-3 mb-3">
                <button className="btn btn-secondary" disabled={loading} onClick={generateReport}>
                    {loading && <ButtonWaiting />}
                    {t('download_overview')}
                </button>

                <button className="btn btn-success ms-3" disabled={loading} onClick={() => setSign(true)}>
                    {loading && <ButtonWaiting />}
                    {t('sign_and_download')}
                </button>
            </div>
        </Fragment>}

        {sign && <SignatureComp setSign={setSign} internalSignature={internalSignature}
            clientSignature={clientSignature} setClientSignature={setClientSignature}
            setInternalSignature={setInternalSignature} generateReport={generateReport} />}

    </Fragment>

}

export default InterventionReport;