import { Fragment } from "react";
import fahDao from "../../services/common/fahDaoV2";
import { useEffect } from "react";
import errorManagement from "../../services/errorManagement";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import { useState } from "react";
import { getCurrentWeekYear } from "../../functions/StrFunctions";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import Tracking from "./smallComponents/Tracking";

const MyFahTracking = (props) => {
    const [tracking, setTracking] = useState();
    const [error, setError] = useState();
    const [trackYear, setTrackYear] = useState();
    
    useEffect(() => {
        var curPeriod = getCurrentWeekYear();
        var y = curPeriod.week === 1 ? curPeriod.year - 1 : curPeriod.year;
        setTrackYear(y);

        getTracking(y);
    }, []);

    const doFahForm = (week) => {
        window.location.href = "/fah/v2?week_from_track=" + (week + 1) + "&year_from_track=" + trackYear;
    }

    const getTracking = (y) => {
        setTracking();

        fahDao.getMyTracking(y).then(
            (response) => {
                setTracking(response);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
            });
    }

    return <Fragment>
        {error && <ErrorMessage error={error} />}
        {!tracking && <WaitingRoundSnippers />}

        {tracking && 
            <Tracking trackYear={trackYear} setTrackYear={setTrackYear}
                tracking={tracking} getTracking={getTracking} doFah={doFahForm} />
        }

    </Fragment>

}

export default MyFahTracking;