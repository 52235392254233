import { Fragment } from "react"
import Moment from "moment";
import CustomToolTip from "../../../common/smallComponents.js/CustomToolTip";

const BbsoTable = (p) => {

    Moment.locale("fr");

    return <Fragment>

        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr className="table-info">
                        <th scope="col">Date d'incident</th>
                        <th scope="col">Auteur</th>
                        <th scope="col">Département</th>
                        <th scope="col">Description</th>
                        <th scope="col">Mes actions</th>
                        <th scope="col">Type d'observation</th>
                        <th scope="col">Risque</th>
                        <th scope="col">Catégorie</th>
                        {
                            p.isQhse &&
                            <Fragment>
                                <th scope="col">Qui ?</th>
                                <th scope="col">Quand ?</th>
                                <th scope="col">Quoi ?</th>
                            </Fragment>
                        }
                        <th scope="col">Statut</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        p.list.map(
                            (v, k) => {
                                return (

                                    <tr key={"l_" + k}>
                                        <th scope="row">{Moment(v.incidentDate).format("DD/MM/yyyy à HH:MM")}</th>
                                        <td>{v.last_name + ' ' + v.first_name}</td>
                                        <td>{v.department}</td>
                                        <td>
                                            <CustomToolTip props={
                                                { tooltipText: v.description, originText: v.description.substring(0, 31) + (v.description.length > 31 ? "..." : "") }} />
                                        </td>
                                        <td>
                                            <CustomToolTip props={
                                                { tooltipText: v.my_action, originText: v.my_action.substring(0, 31) + (v.my_action.length > 31 ? "..." : "") }} />
                                        </td>
                                        <td>{v.type_obs}</td>
                                        <td>{v.risk}</td>
                                        <td>{v.category}</td>
                                        {
                                            p.isQhse &&
                                            <Fragment>
                                                <td>
                                                    <input className="form-control" type="text" data-id={v.id} data-field="who"
                                                        onBlur={p.onFieldChange} defaultValue={v.who}></input>
                                                </td>
                                                <td>
                                                    <input className="form-control" type="date" data-id={v.id} data-field="[when]"
                                                        onBlur={p.onFieldChange} defaultValue={v.when}></input>
                                                </td>
                                                <td>
                                                    <input className="form-control" type="text" data-id={v.id} data-field="what"
                                                        onBlur={p.onFieldChange} defaultValue={v.what}></input>
                                                </td>
                                            </Fragment>}
                                        <td>
                                            <select className="form-select" onChange={p.onFieldChange} disabled={!p.isQhse}
                                                data-id={v.id} data-field="status">
                                                <option value="Ouvert">Ouvert</option>
                                                <option value="En cours" selected={v.status === 'En cours'}>En cours</option>
                                                <option value="Clôturé" selected={v.status === 'Clôturé'}>Clôturé</option>
                                            </select>
                                        </td>
                                        <td>
                                            <a className="btn btn-outline-info" href={"/qhse/bbso/form?id=" + v.id} target="_blank">Entrer</a>
                                        </td>
                                    </tr>)
                            }
                        )
                    }
                </tbody>
            </table>
            <br></br>
        </div>

    </Fragment>

}

export default BbsoTable;