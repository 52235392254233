import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import { nbFormat } from "../../functions/StrFunctions";
import errorManagement from "../../services/errorManagement";
import { downloadTempFile } from "../../services/functions/FilesProcess";
import BusinessDao from "../../services/machines/BusinessDao";

const BusinessBudget = (props) => {
    const [loading, setLoading] = useState(false);
    const [dw, setDw] = useState(false);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [detail, setDetail] = useState("");

    const user = JSON.parse(localStorage.getItem("user"));
    const isSee = user && (user.roles.includes("ROLE_SEE"));

    const { t } = useTranslation('business');

    const [list, setList] = useState();
    const [machines, setMachines] = useState();
    const [totalDpe, setTotalDpe] = useState(0);
    const [totalFte, setTotalFte] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            document.getElementById("search").focus();
        }, 1000);
    }, []);

    const getBudget = (e) => {
        e.preventDefault();

        setLoading(true);
        BusinessDao.getBudget(e.target.business.value).then(
            (response) => {
                if( response.length === 0 ){
                    setError("Aucun budget trouvé");
                }else{
                    setList(response);
                    dispatchCost(response);
                }     
                setLoading(false);           
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

    };

    function dispatchCost(list) {
        var machines = new Array();
        var curMach = "";

        var machine_bom = new Object();
        var totMach = 0, totFte = 0, totDpe = 0;

        
        list.map( (v, k) => {
            
            // Inialize or reinitialize machine number            
            if (getMachNb(v.machine) !== curMach) {
                if (curMach !== "") {
                    machine_bom.total = totMach;
                    machines.push(machine_bom);
                }
                machine_bom = { machine: v.machine, fte: [], tot_fte: 0, dpe: [], tot_dpe: 0, subsetTitle: 0 }
                curMach = getMachNb(v.machine);

                totMach = 0;
            }

            var tot = v.qty * v.price;

            // Add the detail of cost
            if (v.subset === "FAH") { machine_bom.fte.push(v); machine_bom.tot_fte += tot; totFte += tot; }
            else { 
                var isNew = false;
                if( k === 0 || v.machine !== list[k-1].machine ){
                    machine_bom.subsetTitle = machine_bom.dpe.push({title: true, subset: v.subset, price: tot}) - 1;
                    //machine_bom.dpe.push({title: true, subset: v.subset, price: tot});
                    isNew = true;
                } else if( v.subset !== list[k-1].subset ){
                    machine_bom.subsetTitle = machine_bom.dpe.push({title: true, subset: v.subset, price: tot}) - 1;
                    isNew = true;
                }

                machine_bom.dpe.push(v); 
                if(!isNew) machine_bom.dpe[machine_bom.subsetTitle].price += tot;
                machine_bom.tot_dpe += tot; 
                totDpe += tot; 
            }

            totMach += tot;

        })

        machine_bom.total = totMach;
        machines.push(machine_bom);

        setTotalDpe(totDpe);
        setTotalFte(totFte);
        setMachines(machines);
    }

    function getMachNb(val) {
        if (val === null || val.trim() === "0" || val.trim() === "00"
            || val.trim() === "000" || val.trim() === "0000" || val.trim() === "00000") return "000000";

        return val;
    }

    {/** Download xls */ }
    const downloadXls = () => {
        setDw(true);

        BusinessDao.downloadBudget(list).then(
            (response) => {
                downloadTempFile(response);
                setDw(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setDw(false);
            }
        );
    }

    return <Fragment>
        <h5 className="text-center">{t('ct budget')}</h5>

        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}
        {loading && <WaitingRoundSnippers />}

        {machines && <Fragment>
            {dw && <WaitingRoundSnippers />}
            {!dw && <img src="/common/xls_logo.png" className="cursor-pointer" style={{ position: "absolute", right: "15px" }}
                onClick={downloadXls}></img>}
        </Fragment>}

        <form onSubmit={getBudget} className="col-4 offset-4">
            <div className="input-group mb-3">
                <input type="text" className="form-control text-center fw-bold" name="business"
                    placeholder={t('Enter the contract ref')} id="search" autoComplete="off" disabled={loading} />
                <button className="btn btn-outline-success">
                    {loading && <ButtonWaiting />}
                    {t('search')}
                </button>
            </div>
        </form>

        {
            machines && machines.map((v, k) => {
                return <div key={"mach" + k} className="ms-2">
                    <h4>#{v.machine}: {nbFormat(v.total, "en-US", 2)} €</h4>

                    <div className="accordion ms-4 mb-3" style={{ maxWidth: "800px" }} id={"machine" + v.machine}>
                        {/** Pieces */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button"
                                    data-bs-toggle="collapse" data-bs-target={"#machine" + v.machine + "fte"}
                                    aria-expanded="false" aria-controls={"machine" + v.machine + "fte"}>
                                    Total {t('pce')}: {nbFormat(v.tot_dpe, "en-US", 2)} €
                                </button>
                            </h2>
                            <div id={"machine" + v.machine + "fte"} className="accordion-collapse collapse"
                                aria-labelledby="headingTwo" data-bs-parent={"#machine" + v.machine + "fte"}>

                                {
                                    !isSee &&
                                    <div className="accordion-body">
                                        <ul>
                                            {
                                                v.dpe.map((v2, k2) => {
                                                    return !v2.title ? <li key={"mach" + v.machine + "pce" + k2} 
                                                        className={ v.machine+"_"+v2.subset === detail ? "" : "d-none" }>
                                                        {v2.article} ({v2.label}): {v2.qty} x {v2.price} = {(v2.qty * v2.price).toFixed(2)} €
                                                    </li> :
                                                    <h5 key={"title" + v.machine + "pce" + k2}>
                                                        <span className="fw-bold me-2">{v2.subset}: {v2.price.toFixed(2)} €</span>
                                                        <span className="fs-6 text-decoration-underline cursor-pointer" onClick={()=>
                                                            setDetail( detail === v.machine+"_"+v2.subset ? "" : 
                                                            v.machine+"_"+v2.subset)}>Detail</span>
                                                    </h5>
                                                })
                                            }
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>

                        {/** FAH */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button"
                                    data-bs-toggle="collapse" data-bs-target={"#machine" + v.machine + "fah"}
                                    aria-expanded="false" aria-controls={"machine" + v.machine + "fah"}>
                                    Total {t('fah')}: {nbFormat(v.tot_fte, "en-US", 2)} €
                                </button>
                            </h2>
                            <div id={"machine" + v.machine + "fah"} className="accordion-collapse collapse"
                                aria-labelledby="headingThree" data-bs-parent={"#machine" + v.machine + "fah"}>

                                {
                                    !isSee &&
                                    <div className="accordion-body">
                                        <ul>
                                            {
                                                v.fte.map((v2, k2) => {
                                                    return <li key={"mach" + v.machine + "pce" + k2}>
                                                        {v2.article}: {v2.qty} x {v2.price} = {(v2.qty * v2.price).toFixed(2)} €
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            })
        }

        {
            machines &&
            <div className="alert alert-info ms-2" style={{ maxWidth: "800px" }}>
                <table style={{ fontSize: "1.25rem" }} className="fw-bold">
                    <tr>
                        <td style={{ minWidth: "150px" }}>Total {t('pce')}</td>
                        <td>{nbFormat(totalDpe, "en-US", 2)} €</td>
                    </tr>
                    <tr>
                        <td>Total {t('fah')}</td>
                        <td>{nbFormat(totalFte, "en-US", 2)} €</td>
                    </tr>
                    <tr>
                        <td>Total {t('business')}</td>
                        <td>{nbFormat(totalDpe + totalFte, "en-US", 2)} €</td>
                    </tr>
                </table>
            </div>
        }
    </Fragment>

}

export default BusinessBudget