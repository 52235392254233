import axios from "axios";
import authHeader from "../auth-header";

const API_URL = process.env.REACT_APP_API_URL +"workshop/check-list";
const user = JSON.parse(localStorage.getItem("user"));

const isConnected = () => {
    return axios
        .post(API_URL + "/connected", 
        {
            fake_header: authHeader()
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        }).then((response) => {
            return response.data;
        });
};

const getAssemblyChkListForm = (mach_nb) => {
    return axios
        .post(API_URL + "/assembly/list", 
        {
            fake_header: authHeader(),
            mach_nb: mach_nb
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        }).then((response) => {
            return response.data;
        });
};

const getElecChkListForm = (mach_nb) => {
    return axios
        .post(API_URL + "/electrical/list", 
        {
            fake_header: authHeader(),
            mach_nb: mach_nb
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        }).then((response) => {
            return response.data;
        });
};

const saveAssemblyChkListForm = (machine, form) => {
    return axios
        .post(API_URL + "/assembly/save", 
        {
            fake_header: authHeader(),
            machine: machine,
            form: form
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        }).then((response) => {
            return response.data;
        });
};

const saveElecChkListForm = (machine, form) => {
    return axios
        .post(API_URL + "/electrical/save", 
        {
            fake_header: authHeader(),
            machine: machine,
            form: form
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        }).then((response) => {
            return response.data;
        });
};

const saveAssemblyNcForm = (machine) => {
    return axios
        .post(API_URL + "/assembly/non-conformity", 
        {
            fake_header: authHeader(),
            mach_nb: machine
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        }).then((response) => {
            return response.data;
        });
};

const saveAssemblyUndoneForm = (machine) => {
    return axios
        .post(API_URL + "/assembly/undone", 
        {
            fake_header: authHeader(),
            mach_nb: machine
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        }).then((response) => {
            return response.data;
        });
};


const setAnswerCheckList = (mach_nb, question ,answer) => {
    return axios
        .post(API_URL + "/set-answer", {
            machine: mach_nb,
            question: question,
            answer: answer
         }, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const setBuilder = (mach_nb, chapter ,username, form) => {
    return axios
        .post(API_URL + "/set-builder", {
            machine: mach_nb,
            chapter: chapter,
            username: username,
            form: form
         }, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const getIntegrationChkList = (machine) => {
    return axios
    .post(API_URL + "/intregration/list", { 
        fake_header: authHeader(),
        machine: machine 
    }, { headers: {
        'Accept': 'application/json',
        'Content-Type': 'text/plain'
        } 
    })
    .then((response) => {
        return response.data;
    });
}

const setConform = (machine, question, conform) => {
    return axios
    .post(API_URL + "/intregration/set-answer", { 
        fake_header: authHeader(),
        machine: machine,
        question: question, 
        conform: conform, 
        author: user.email
    }, { headers: {
        'Accept': 'application/json',
        'Content-Type': 'text/plain'
        } 
    })
    .then((response) => {
        return response.data;
    });
}


const saveIntegrationChkListForm = (machine, form) => {
    return axios
        .post(API_URL + "/intregration/save", 
        {
            fake_header: authHeader(),
            machine: machine,
            form: form
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        }).then((response) => {
            return response.data;
        });
};


const saveIntegrationMissing = (machine, missing) => {
    return axios
        .post(API_URL + "/intregration/missing/save", 
        {
            fake_header: authHeader(),
            machine: machine,
            missing: missing
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        }).then((response) => {
            return response.data;
        });
};

const setAssemblyMargerSense = (mach_nb, chapter ,sense) => {
    return axios
        .post(API_URL + "/set-marger-sense", {
            machine: mach_nb,
            chapter: chapter,
            sense: sense,
            form: "montage"
         }, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

export default{
    isConnected,
    getAssemblyChkListForm,
    saveAssemblyChkListForm,
    saveAssemblyNcForm,
    setAnswerCheckList,
    setBuilder,
    getIntegrationChkList,
    saveIntegrationChkListForm,
    setConform,
    saveIntegrationMissing,
    saveAssemblyUndoneForm,
    setAssemblyMargerSense,
    getElecChkListForm,
    saveElecChkListForm
};