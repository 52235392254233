import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL + "common/check-connection";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && "\"Bearer " + user.accessToken + "\"";

const internal = () => {
    return axios
        .post(API_URL + "/internal", {
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const userChecking = () => {
    return axios
        .post(API_URL + "/user", {
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const writeSettings = (param) => {
    return axios
        .post(API_URL + "/txt/write-setting", {
            fake_header: authHeader(),
            param: param
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getSettings = (param) => {
    return axios
        .post(API_URL + "/txt/get-setting", {
            fake_header: authHeader(),
            param: param
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getAnnouncements = (all) => {
    return axios
        .post(API_URL + "/announcement/get", {
            fake_header: authHeader(),
            all: all
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const addAnnouncements = (o) => {
    return axios
        .post(API_URL + "/announcement/add", {
            fake_header: authHeader(),
            ...o
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const deleteAnnouncement = (id) => {
    return axios
        .post(API_URL + "/announcement/delete", {
            fake_header: authHeader(),
            id: id
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const addNavigation = (o) => {
    return axios
        .post(API_URL + "/navigation/add", {
            fake_header: authHeader(),
            ...o
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getNavigation = () => {
    return axios
        .post(API_URL + "/navigation/get", {
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const deleteNavigation = (id) => {
    return axios
        .post(API_URL + "/navigation/delete", {
            fake_header: authHeader(),
            id: id
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

/**
 * 
 * @param {*} text 
 * @param {*} targetLang 
 * @returns 
 */
const translate = async (text, targetLang) => {
    
    const apiKey = process.env.REACT_APP_DEEPL_KEY;    
    //Need to change it if  upgrade
    const url = `https://api-free.deepl.com/v2/translate`;

    try {
        const response = await axios.post(url, null, {
        params: {
            auth_key: apiKey,
            text: text,
            target_lang: targetLang,
        },
        });

        return response.data.translations[0].text;
    } catch (error) {
        console.error('Error translating text:', error);
        return null;
    }
}

export default {
    internal,
    userChecking,
    writeSettings,
    getSettings,
    getAnnouncements,
    addAnnouncements,
    deleteAnnouncement,
    addNavigation,
    getNavigation,
    deleteNavigation,
    translate
}