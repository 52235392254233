import { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import { getCurrentWeekYear, weeksInYear } from "../../../functions/StrFunctions";

const PeriodForm = (p) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const [prevWeek, setPrevWeek] = useState();
    const [prevYear, setPrevYear] = useState();
    const [curWeek, setCurWeek] = useState();
    const [curYear, setCurYear] = useState();

    const [oldBusiness, setOldBusiness] = useState(false);
    const [oldHours, setOldHours] = useState(false);

    useEffect(() => {
        var curPeriod, w, y;

        if( p.periodFromTrack ){
            curPeriod = p.periodFromTrack;
            y = curPeriod.year;
            w = curPeriod.week;
        }else{
            curPeriod = getCurrentWeekYear();
            y = curPeriod.week === 1 ? curPeriod.year - 1 : curPeriod.year;
            w = curPeriod.week === 1 ? weeksInYear( y ) : curPeriod.week - 1;
        }
               
        setCurWeek(w);
        setCurYear(y);
    }, [p.periodFromTrack]);

    return <Fragment>
        <form>
            <div className="custom-tooltip">
                <h5 className="text-center">Période</h5>

                <div className="input-group mb-3">
                    <input type="number" name="week" className="form-control text-center fw-bold"
                        defaultValue={curWeek} required />
                    <input type="number" name="year" className="form-control text-center fw-bold"
                        defaultValue={curYear} required />
                </div>

                <div className="input-group mb-3">
                    <div className="input-group-text">
                        <input className="form-check-input mt-0" name="oldBusiness" type="checkbox" value="O"
                            onChange={(e) => { setOldBusiness(!oldBusiness) }} defaultChecked={oldBusiness} />
                    </div>
                    <input type="text" className="form-control fw-bold" defaultValue="Rapatrier les affaires" readOnly />
                </div>

                {
                    oldBusiness && <Fragment>
                        <div className="input-group mb-3">
                            <input type="number" name="oldWeek" className="form-control text-center fw-bold"
                                defaultValue={curWeek} required={oldBusiness} />
                            <input type="number" name="oldYear" className="form-control text-center fw-bold"
                                defaultValue={curYear} required={oldBusiness} />
                        </div>

                        <div className="input-group mb-3">
                            <div className="input-group-text">
                                <input className="form-check-input mt-0" name="oldHours" type="checkbox" value="O"
                                    onChange={(e) => { setOldHours(!oldHours) }} checked={oldHours} />
                            </div>
                            <input type="text" className="form-control fw-bold" value="Rapatrier les heures" readOnly />
                        </div>
                    </Fragment>
                }


                <div className="text-center">
                    <button className="btn btn-success">Commencer</button>
                </div>
            </div>
        </form>
    </Fragment>
}

export default PeriodForm;