import { Fragment } from "react";

const PartFive = (props) => {
    return (
        <Fragment>
            <div className="table-responsive">
                <table className="table businessCardStyle">
                    <thead>
                        <tr className="bg-dark bg-gradient text-white text-center rounded">
                            <th scope="col">Logo client</th>
                            <th scope="col">Logo client final</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {/** CLIENT */}
                            <td style={{ width: "33.3%" }} className="border-end">
                                {!props.readOnly &&
                                    <div className="mb-3 text-center">
                                        <label for="formFile"
                                            className="form-label fw-bold">{props.businessInfo.logo_client ?
                                                "Modifier" : "Ajouter"} le logo</label>
                                        <input className="form-control" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                            type="file" onChange={props.addLogo} data-field="logo_client" />
                                    </div>
                                }


                                {props.businessInfo.logo_client &&
                                    <div className="text-center">
                                        <img src={props.businessInfo.logo_client} style={{ maxWidth: "300px" }}></img>
                                    </div>
                                }
                            </td>

                            {/** FINAL CLIENT */}
                            <td style={{ width: "33.3%" }}>
                                {!props.readOnly &&
                                    <div className="mb-3 text-center">
                                        <label for="formFile"
                                            className="form-label fw-bold">{props.businessInfo.logo_cli_fin ?
                                                "Modifier" : "Ajouter"} le logo</label>
                                        <input className="form-control" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                            type="file" onChange={props.addLogo} data-field="logo_cli_fin" />
                                    </div>
                                }
                                {props.businessInfo.logo_cli_fin &&
                                    <div className="text-center">
                                        <img src={props.businessInfo.logo_cli_fin} style={{ maxWidth: "300px" }}></img>
                                    </div>
                                }

                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div className="table-responsive">
                <table className="table businessCardStyle">
                    <thead>
                        <tr className="bg-dark bg-gradient text-white text-center rounded">
                            <th scope="col">Images annexes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/** ALREADY IMPORTED */}
                        {
                            props.annexes.length > 0 &&
                            <tr>
                                <td>
                                    <div className="mb-3 text-center">
                                        <label for="formFile"
                                            className="form-label fw-bold">Annexes déjà importés</label>

                                        <div style={{ display: "flex" }}>
                                            <div style={{ width: "50%", minWidth: "250px" }}>
                                                <ul className="list-group">
                                                    {
                                                        props.annexes.map((v, k) => {
                                                            return <li key={"ann" + k}
                                                                className="list-group-item"
                                                                data-img={v.img} onClick={props.displayAnnexe}>
                                                                {v.name}
                                                                {
                                                                    (!v.new && !props.readOnly) &&
                                                                    <img src={"/common/remove_icon.png"}
                                                                        style={{ marginLeft: "10px", width: "30px" }}
                                                                        onClick={props.deleteAnnexe} data-key={k}></img>
                                                                }
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            <div style={{ width: "50%", minWidth: "250px" }}>
                                                {props.imgDisplay &&
                                                    <div>
                                                        <div style={{ display: "flex" }}>
                                                            <h5 style={{ marginLeft: "auto", marginRight: "auto" }}>Apercu</h5>
                                                            <button type="button" className="btn-close" aria-label="Close"
                                                                onClick={props.displayAnnexe}></button>
                                                        </div>
                                                        <img src={props.imgDisplay} style={{ maxWidth: "200px" }}></img>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        }

                        {/** ADD ANNEXE */}
                        {!props.readOnly &&
                            <tr>
                                <td>
                                    <div className="mb-3 text-center">
                                        <label for="formFile"
                                            className="form-label fw-bold">Ajouter un annexe</label>
                                        <input className="form-control" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                            type="file" onChange={props.addAnnexe} />
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </Fragment>
    )
}

export default PartFive;