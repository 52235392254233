import { useEffect } from "react";
import { useState } from "react";
import errorManagement from "../../services/errorManagement";
import orderInfo from "../../services/purchase/orderInfo";
import getUrlParam, { getFrFormat, updateDrawingUrl } from "../../functions/StrFunctions";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { useSelector } from "react-redux";
import { Fragment } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import { closeNav, openNav } from "../../functions/SideNav";
import LabelsChoice from "../../common/smallComponents.js/LabelsChoice";
import Labels from "../../services/storehouse/Labels";
import { arrayRemoveElt, getKey } from "../../functions/ArrayFunctions";
import { downloadTempFile } from "../../services/functions/FilesProcess";
import { SideNavBarV3, isSideBarOpened } from "../../common/smallComponents.js/DesignedIpt";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import { confirmAlert } from "react-confirm-alert";

const OrderEdition = (props) => {

    const [loading, setLoading] = useState(true);
    const [msg, setMsg] = useState();
    const [error, setError] = useState();
    const [order, setOrder] = useState();
    const [header, setHeader] = useState();
    const [comment, setComment] = useState();
    const [detail, setDetail] = useState();
    const [userRoles, setUserRoles] = useState();
    const [totOrder, setTotOrder] = useState(0);
    const [updatingDate, setUpdatingDate] = useState(false);
    const [updatedDate, setUpdatedDate] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [printingLabel, setPrintingLabel] = useState(false);
    const [label, setLabel] = useState([]);
    const [isStockOrder, setIsStockOrder] = useState();
    const [dateToChange, setDateToChange] = useState([]);
    const [dateToSave, setDateToSave] = useState([]);
    const [pin, setPin] = useState(isSideBarOpened());
    const [oldIdx, setOldIdx] = useState();

    const { user: currentUser } = useSelector((state) => state.auth);
    const token = currentUser && "Bearer " + currentUser.accessToken;
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        var param = getUrlParam(props, "order");

        if (currentUser === null) {
            localStorage.setItem("url_back", window.location.pathname + window.location.search);
            props.history.push('/login');
            window.location.href = "/login";
        }

        setUserRoles(currentUser.roles);

        if (param !== null) {
            setOrder(param);
            getOrderInfo(param);
        } else {
            setLoading(false);
        }

    }, []);

    const getOrderInfo = (order) => {
        orderInfo.getOrderInfo(order).then(
            (response) => {
                setLoading(false);
                setHeader(response.header);
                setComment(response.header_comment);

                var ar = [];
                var arr = new Array();
                var old = [];

                for (let i = 0; i < response.detail.length; i++) {
                    var v = response.detail[i];
                    if (v.ZN04PA && v.ZN04PA.startsWith('P')) {
                        if (!ar.includes(v.CDARE)) {
                            arr.push(v);
                            ar.push(v.CDARE);
                        } else {
                            v.no_label = true;
                            arr.push(v);
                        }
                    } else {
                        arr.push(v);
                    }

                    if( v.oldIdxStock && v.oldIdxStock > 0 ) old.push(v);
                }

                setOldIdx(old);
                console.log(old)

                setDetail(arr);
                checkStockOrder(response.detail);

                updateTotal(response.detail);

            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        )
    }

    const updateTotal = (detail) => {
        var tot = 0;

        detail.map(v => tot += v.QTEGE * v.PUNDE);

        setTotOrder(tot);
    }

    /**
     * Update price in Focus
     * @param {*} e 
     * @returns 
     */
    const updatePrice = (e) => {
        var arr = [...detail];
        var row = parseInt(e.target.getAttribute("data-row"));
        var oldPrice = parseFloat(e.target.getAttribute("data-oldvalue"));
        var newPrice = parseFloat(e.target.value);
        var key = e.target.getAttribute("data-key")

        if (oldPrice === newPrice) return;

        arr.map(v => { if (parseFloat(v.NLIGE) === row) v.PUNDE = newPrice; })
        setDetail(arr);

        confirmAlert({
            title: "Mise à jour FRN",
            message: "Voulez-vous mettre à jour le prix du fournisseur dans la fiche article ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        var frn = {
                            frn: detail[key].CDFOE,
                            unit: detail[key].CDUNE,
                            ar: detail[key].CDARE,
                            currency: detail[key].CDEVU,
                            price: newPrice,
                            oldPrice: oldPrice
                        }

                        updatePriceDb(arr, order, row, newPrice, frn);
                    }
                },
                {
                    label: "Non",
                    onClick: () => {
                        updatePriceDb(arr, order, row, newPrice, null);
                    }
                }
            ]
        });

        console.log(detail[row])

    }

    function updatePriceDb(arr, order, row, newPrice, frn) {
        orderInfo.updateOrderPrice(order, row, newPrice, frn).then(
            (response) => {
                updateTotal(arr);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));

            });
    }

    /**
     * Update actualized date in Focus for all rows
     * @param {*} e 
     */
    const updateActualizedDate = (e) => {
        e.preventDefault();

        var date = e.target.date.value;
        setUpdatingDate(true);
        setUpdatedDate(false);

        orderInfo.updatActualizedDate(order, date).then(
            (response) => {
                setUpdatingDate(false);
                setUpdatedDate(true);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setUpdatingDate(false);
            }
        )
    }

    /**
     * Update actualized date in Focus for one row
     * @param {*} e 
     */
    const updateActualizedDateOneRow = (e) => {
        var date = e.target.value;
        var line = e.target.getAttribute("data-line");
        setError();

        orderInfo.updatActualizedDateOneRow(order, line, date).then(
            (response) => {
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
            }
        )
    }

    const onActualizedDateChangeRow = (e) => {
        var arr = [...detail];
        var key = e.target.getAttribute("data-key");
        var date = e.target.value;
        arr[key].DTLIE = date;
        setDetail(arr);
    }

    const updateQty = (e) => {
        setError();
        var key = parseInt(e.target.getAttribute("data-key"));
        var val = parseFloat(e.target.value);
        var arr = [...detail];
        var o = { ...arr[key] };

        var old_value = parseFloat(o.QTEGE);

        if (isNaN(val)) {
            setError("Saisissez un nombre !");
            scrollToTop();
            e.target.value = old_value;
            return;
        }


        //If no change stop here
        if (old_value === val) return;

        if (val <= 0) {
            setError("Modification non enregistrée sur Focus. La quantité doit être supérieure à zéro !");
            scrollToTop();
            e.target.value = old_value;
            return;
        }

        //Change not allowed after receiption
        if (o.SLDQE !== 'N') {
            setError("Modification non enregistrée sur Focus. Une réception a déjà eu lieu.");
            scrollToTop();
            e.target.value = old_value;
            return;
        }

        var QTESE = (val * o.QTESE) / o.QTEGE;
        var QTRSE = (val * o.QTRSE) / o.QTEGE;

        o.QTEGE = val;
        o.QTERE = val;
        o.QTESE = QTESE;
        o.QTRSE = QTRSE;

        arr[key] = o;
        setDetail(arr);

        orderInfo.updateQty(order, o).then(
            (response) => { },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                e.target.value = old_value;
            }
        );
    }

    const downloadDrawings = () => {
        if (detail.length > 10) {
            setMsg("La commande comporte beaucoup de lignes, nous vous envoyons les plans par mail");
            scrollToTop();
            orderInfo.createDrawingsZipAndSend(order).then(
                () => { },
                (error) => { setError(errorManagement.handleError(props, error)); }
            );
        } else {
            setDownloading(true)
            orderInfo.createDrawingsZip(order).then(
                (response) => {
                    setDownloading(false);
                    downloadTempFile(response)
                },
                (error) => {
                    setDownloading(false);
                    setError(errorManagement.handleError(props, error));
                    setUpdatingDate(false);
                }
            );
        }


    }

    const generateLabel = (e) => {
        const start = e;

        setPrintingLabel(true);
        Labels.createLabels(order, label, start).then(
            (response) => {
                setPrintingLabel(false);
                downloadTempFile(response)
            },
            (error) => {
                setPrintingLabel(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        )
    }

    /**
     * Set label to print
     * @param {*} e 
     */
    const printLabel = (e) => {
        var checked = e.target.checked;

        if (checked) {
            var arr = [...label];
            var index = parseInt(e.target.getAttribute("data-key"));
            arr.push(detail[index]);
            setLabel(arr);
        } else {
            var arr = new Array();
            var row = parseInt(e.target.getAttribute("data-row"));
            arr.map(v => { if (v.NLIGE !== row) arr.push(v) });
            setLabel(arr);
        }

    }

    /**
     * Print all labels
     */
    const printAllLabel = (e) => {
        var checked = e.target.checked;

        if (checked) {
            var arr = new Array();
            detail.map(v => { if (!v.no_label) arr.push(v) });
            setLabel(arr);
        } else {
            var arr = new Array();
            setLabel(arr);
        }

        var checkboxes = document.getElementsByClassName("label-chk");
        for (var i = 0; i < checkboxes.length; i++) checkboxes[i].checked = checked;
    }

    /**
     * Add delivery row
     */
    const addDeliveryRow = () => {
        var lastRow = 0;
        for (let i = 0; i < detail.length; i++) {
            if (parseInt(detail[i].NLIGE) > lastRow) lastRow = parseInt(detail[i].NLIGE);
            if (detail[i].CDARE.trim() === 'YR_FRAIS_00001') {
                setError("Vous avez déjà une ligne de port à la ligne: " + detail[i].NLIGE);
                scrollToTop();
                return;
            }
        }

        setLoading(true);
        orderInfo.addDeliveryRow(header.CDFOC, lastRow + 1, detail[detail.length - 1], detail[0].NOCCE).then(
            (response) => {
                window.location.reload();
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );
    }

    function checkStockOrder(detail) {
        if (!currentUser.roles.includes("ROLE_PURCHASE")) return;

        var art = new Array();
        for (let i = 0; i < detail.length; i++) {
            if (detail[i].NOCCE === null && !art.includes(detail[i].CDARE))
                art.push(detail[i].CDARE);
        }



        if (art.length > 0) {
            setIsStockOrder("E");

            orderInfo.getDateToUpdate(detail[0].NOCFE, art).then(
                (response) => {
                    var arr = new Array();
                    for (let i = 0; i < response.length; i++) if (response[i].date) arr.push(response[i]);
                    setDateToChange(arr);
                    setIsStockOrder("OK");
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                    setIsStockOrder("NOK");
                }
            );
        }

    }

    const validateDateChange = (e) => {
        var arr = [...dateToSave];
        var key = e.target.getAttribute("data-key");
        var art = (dateToChange[key]);

        if (e.target.checked) {
            arr.push(art);
        } else {
            arr = arrayRemoveElt(arr, getKey(arr, "article", art.article));
        }

        setDateToSave(arr);
    }

    const saveDateProposal = () => {
        setLoading(true);

        orderInfo.updatActualizedDateByArticles(order, dateToSave).then(
            (response) => {
                window.location.reload();
            },
            (error) => {
                setLoading(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        )

    }

    var url2 = API_URL + "articles/info/get-drawing-url?Authorization=" + token + "&url=";

    function getReceiption(v) {

        if (v.receipt !== "#") {
            var split = v.receipt.split("#");
            return "Reçu " + split[0].replace(".000", "") + ", le " + split[1]
        }

        return "";
    }

    return (
        <div className="order-edition bg-see navbarV3 right-side">
            <SideNavBarV3 setPin={setPin} content={
                header &&

                <Fragment>
                    <div className="ps-2 pe-2">
                        <a className="mb-3" href="#" onClick={window.print}>Imprimer</a>
                        <br></br>
                        {
                            ((currentUser.roles.includes("ROLE_STOREHOUSE") || userRoles.includes("ROLE_ADMIN"))
                                && label.length > 0) &&
                            <Fragment>
                                <br></br>
                                <LabelsChoice onChooseTag={generateLabel} printingLabel={printingLabel} />
                            </Fragment>
                        }

                        <a href="#" onClick={downloadDrawings}>Télécharger les plans</a>

                        {
                            downloading &&
                            <div>
                                <WaitingRoundSnippers />
                                <br></br>
                                Téléchargement en cours
                            </div>
                        }
                    </div>
                </Fragment>
            } />

            <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>
                {loading && <WaitingRoundSnippers />}

                {error && <ErrorMessage error={error} errorState={setError} />}
                {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

                {!order && <form className="col-6 offset-3">
                    <div className="input-group">
                        <input className="form-control fw-bold text-center" name="order" 
                            placeholder="Saisissez le numéro de commande" autoFocus autoComplete="off" required />
                        <button className="btn btn-outline-success">Recherche</button>
                    </div>
                </form>}

                {
                    header &&
                    <div>
                        {/** Header */}
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <img src={"/Logo B+ Eq SealedAir.png"} style={{ width: "50%" }} />
                                    <br />
                                    <span className="fw-bold">Adresse de livraison:</span>
                                    <br />
                                    <span>{header.NMLIC}</span>
                                    <br />
                                    <span>{header.ADRLC + ' ' + header.ADRLC2}</span>
                                </div>

                                <div className="col text-center">
                                    <h1>
                                        <span>{'Commande n°' + header.NOCFC}</span>
                                        {
                                            (header.NOAVC != null && detail[0].ZN11PH != 'N') && <span> {"_" + header.NOAVC} </span>
                                        }
                                    </h1>
                                    <h2>{'Fournisseur: ' + header.CDFOC}</h2>
                                    <span>
                                        {"Le " + header.DTCRC + ", suivi par " + (header.LI1CS != null ? header.LI1CS : header.CACHC)}
                                    </span>
                                    {
                                        detail[0].ZN10PH != null &&
                                        <div>
                                            <br></br>
                                            <span>{"Donneur d'ordre: " + detail[0].ZN10PH}</span>
                                        </div>
                                    }
                                    {
                                        detail[0].ZN15PH != null &&
                                        <div>
                                            <br></br>
                                            <span>{"Destinataire: " + detail[0].ZN15PH}</span>
                                        </div>
                                    }
                                    {header.dl !== "" &&
                                        <div className="accordion accordion-flush m-auto no-print" id="accordion" style={{ maxWidth: "300px" }}>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                        Voir les bons de livraison scannés
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseOne" className="accordion-collapse collapse"
                                                    data-bs-parent="#accordion" style={{ maxWidth: "300px" }}>
                                                    <div className="accordion-body">
                                                        {
                                                            header.dl.split(",").map((v, k) => {
                                                                return <li key={"bl" + k}>
                                                                    <a target="_blank" href={url2 + updateDrawingUrl(v)}>BL n°{k + 1}</a>
                                                                </li>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<a target="_blank" className="hide"
                                                href={ "http://10.141.44.140:8080/purchase-dpt/edition/order/delivery-note?order_num=" + order }>Voir
                                    les bons de livraison scannés</a> */}
                                        </div>
                                    }

                                    <br></br>
                                    <br></br>

                                    {
                                        userRoles.includes("ROLE_PURCHASE") &&
                                        <div className="alert alert-secondary no-print" role="alert">
                                            <form onSubmit={updateActualizedDate}>
                                                <span className="fw-bold">Mettre à jour la date actualisée de toute la commande</span>
                                                <div className="input-group mb-3">
                                                    <input type="date" className="form-control" name="date" required />
                                                    <button disabled={updatingDate} type="submit" className="btn btn-success">
                                                        {updatingDate && <span className="spinner-border spinner-border-sm"></span>}
                                                        Sauvegarder
                                                    </button>
                                                </div>
                                            </form>
                                            {
                                                updatedDate &&
                                                <div className="alert alert-success" role="alert">Mise à jour effectuée</div>
                                            }
                                        </div>
                                    }

                                </div>
                            </div>

                            {
                                (userRoles.includes("ROLE_PURCHASE") && isStockOrder) &&
                                <div className="alert alert-info no-print" role="alert">
                                    {
                                        isStockOrder === "E" &&
                                        <p>Au moins une ligne a été commandée sur stock, analyse en cours...</p>
                                    }

                                    {
                                        (isStockOrder === "OK" && dateToChange.length === 0) &&
                                        <p>Ok, rien à signaler pour les dates</p>
                                    }

                                    {
                                        (isStockOrder === "OK" && dateToChange.length > 0) &&
                                        <Fragment>
                                            <p>Vous devriez revoir les dates de commande pour {dateToChange.length} article(s):</p>
                                            <ul className="list-group">
                                                {
                                                    dateToChange.map(
                                                        (v, k) => <li key={"dt" + k} className="list-group-item" style={{ backgroundColor: "transparent" }}>
                                                            <input type="checkbox" data-key={k} onClick={validateDateChange}
                                                                style={{ marginRight: "5px" }}></input>
                                                            {v.article} à modifier pour le {v.date} &nbsp;
                                                            <a target="_blank" href={"/article/info/stock/dynamic?article=" + v.article}>voir stock dynamique</a>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                            {
                                                dateToSave.length > 0 &&
                                                <div className="text-center">
                                                    <br></br>
                                                    <button className="btn btn-success" disabled={loading}
                                                        onClick={saveDateProposal}>
                                                        {loading && <ButtonWaiting />}
                                                        Valider les dates
                                                    </button>
                                                </div>
                                            }
                                        </Fragment>
                                    }

                                    {oldIdx.length > 0 && <div>
                                        <h6>Attention un ou des anciens indices sont disponibles en stock</h6>
                                            {oldIdx.map( (v, k) => {
                                                return <li key={"oldIdx" + k}>
                                                    L'ancien indice de l'<strong>{v.CDARE}</strong> est <strong>{v.ZN08PA}</strong>, qté. dispo: {v.oldIdxStock} (voir ligne {v.NLIGE})
                                                </li>
                                            } )}
                                        </div>}

                                </div>
                            }

                        </div>

                        {/** Header comments */}
                        {
                            (comment != null && comment !== "") &&
                            <div className="alert alert-info fw-bold col-10 offset-1" role="alert">Remarque: {comment}</div>
                        }

                        <br></br>

                        {/** Detail */}
                        <div className="table-responsive">
                            <div className="text-end fw-bold no-print">
                                Total commande: {parseFloat(totOrder).toFixed(1)} €
                            </div>
                            <table className="table text-center table-responsive">
                                <thead className="table-dark">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Désignation / Observation</th>
                                        <th scope="col"> Référence Code article </th>
                                        <th scope="col">Affaire</th>
                                        <th scope="col">Date livr. prévue </th>
                                        <th scope="col">Qté</th>
                                        <th scope="col" className="no-print">P.U.H.T (en €)</th>
                                        <th scope="col" className="no-print">P.T.H.T (en €)</th>
                                        {(currentUser.roles.includes("ROLE_STOREHOUSE") || userRoles.includes("ROLE_ADMIN")) &&
                                            <th scope="col" className="no-print">
                                                A étiquetter <input type="checkbox" onClick={printAllLabel} />
                                            </th>
                                        }
                                        {(currentUser.roles.includes("ROLE_PURCHASE") || userRoles.includes("ROLE_ADMIN")) &&
                                            <th scope="col" className="no-print">Date actualisée</th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        detail.map((v, k) => {
                                            return (
                                                <Fragment key={"order_" + k}>
                                                    <tr>
                                                        <td className="fw-bold">{v.NLIGE}</td>
                                                        <td>
                                                            {v.ZN03PA}
                                                            <br />
                                                            Ref: {v.ZN02PA}
                                                        </td>
                                                        <td>
                                                            {
                                                                v.ZN09PA === null && <span style={{ fontSize: "12px" }}>{v.ZN02PA}</span>
                                                            }
                                                            {
                                                                (v.ZN09PA != null && v.ZN09PA !== 'ignore') &&
                                                                <a target="_blank" style={{ fontSize: "12px" }}
                                                                    href={url2 + updateDrawingUrl(v.ZN09PA)}>{v.ZN02PA}</a>
                                                            }
                                                            <br></br>
                                                            <a target="_blank" href={"/article/info/get?article=" + v.CDARE}>{v.CDARE}</a>
                                                            {
                                                                v.ZN04PA !== null && <span className="fst-italic"> ({v.ZN04PA}) </span>
                                                            }
                                                        </td>
                                                        <td>
                                                            {(v.NONNE !== "SAV" && v.NOCCE === null) && "ACHSTOCK"}
                                                            {(v.NONNE !== "SAV" && v.NOCCE !== null) && v.NOCCE}
                                                            {v.NONNE === "SAV" && <a target="_blank" href={"http://10.141.44.140:8080/storehouse/picking/list?business=" + v.NOCCE + "&delivery_date=&warranty=&type_pack=&standard=&status=&prc=&affected=&coms="}>{v.NOCCE}</a>}
                                                            <br></br>
                                                            {v.NONNE + (v.IDEPE !== null ? " / " + v.IDEPE : "")}
                                                        </td>
                                                        <td>
                                                            <div className="text-center">{getFrFormat(v.DTLIE, true)}</div>
                                                            <div className="no-print fst-italic" style={{ fontSize: "12px", maxWidth: "150px" }}>{getReceiption(v)}</div>
                                                        </td>
                                                        <td>
                                                            {userRoles.includes("ROLE_PURCHASE") &&
                                                                <input type="number" step="any" onBlur={updateQty} data-key={k}
                                                                    className="form-control text-center" defaultValue={v.QTEGE} />}

                                                            {!userRoles.includes("ROLE_PURCHASE") && parseFloat(v.QTEGE).toFixed(1)}
                                                            <br />
                                                            {v.LIBELLE_UNITE}
                                                        </td>
                                                        <td style={{ maxWidth: "150px" }} className="no-print">
                                                            {userRoles.includes("ROLE_PURCHASE") &&
                                                                <input type="number" step="any" onBlur={updatePrice} data-row={v.NLIGE} data-oldvalue={v.PUNDE}
                                                                    className="form-control text-center" data-key={k} defaultValue={v.PUNDE} />}

                                                            {!userRoles.includes("ROLE_PURCHASE") && parseFloat(v.PUNDE).toFixed(1)}
                                                        </td>
                                                        <td className="no-print">
                                                            {parseFloat(v.QTEGE * v.PUNDE).toFixed(1)}
                                                        </td>
                                                        {(currentUser.roles.includes("ROLE_STOREHOUSE") || userRoles.includes("ROLE_ADMIN")) &&
                                                            <td className="no-print">
                                                                {
                                                                    !v.no_label && <input type="checkbox" className="label-chk" style={{ width: "30px", height: "30px" }}
                                                                        data-key={k} data-row={v.NLIGE} onChange={printLabel} />
                                                                }

                                                            </td>
                                                        }
                                                        {(currentUser.roles.includes("ROLE_PURCHASE") || userRoles.includes("ROLE_ADMIN")) &&
                                                            <td className="no-print">
                                                                <input type="date" className="form-control" data-line={v.NLIGE}
                                                                    data-key={k} value={v.DTLIE} onChange={onActualizedDateChangeRow} onBlur={updateActualizedDateOneRow} />
                                                            </td>
                                                        }
                                                    </tr>
                                                    {((v.obses !== null && v.obses !== "") || (v.REFAU !== null && v.REFAU !== "") || (v.COMMW !== null && v.COMMW !== "")) &&
                                                        <tr>
                                                            <td className="text-center fw-bold" colSpan="10" style={{ paddingLeft: "50px" }}>
                                                                {(v.REFAU !== null && v.REFAU !== "") && <p>{v.REFAU}</p>}
                                                                {(v.COMMW !== null && v.COMMW !== "") && <p>{v.COMMW}</p>}
                                                                {(v.obses !== null && v.obses !== "" && v.obses !== v.COMMW) && <p>{v.obses}</p>}
                                                            </td>
                                                        </tr>
                                                    }
                                                    {
                                                        (userRoles.includes("ROLE_PURCHASE") &&
                                                            v.NORME !== null && v.QTRAM < v.QTESE) &&
                                                        <tr className="bg-danger text-white fw-bold">
                                                            <td colSpan="9" >
                                                                Attention: cette ligne n'a que <strong>{v.QTRAM}</strong> article{v.QTRAM > 1 ? "s" : ""} en besoin !
                                                            </td>
                                                        </tr>
                                                    }

                                                </Fragment>
                                            );
                                        })
                                    }

                                    {(userRoles.includes("ROLE_PURCHASE") || userRoles.includes("ROLE_ADMIN")) &&
                                        <tr className="no-print">
                                            <td colSpan="10">
                                                <button className="btn btn-secondary" disabled={loading}
                                                    onClick={addDeliveryRow}>
                                                    {loading && <ButtonWaiting />}
                                                    Ajouter des frais de port
                                                </button>
                                            </td>
                                        </tr>
                                    }

                                    <tr className="no-print">
                                        <td colSpan="6"></td>
                                        <td className="fw-bold">Total</td>
                                        <td>{parseFloat(totOrder).toFixed(1)}</td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>

                        <div>
                            Observations:
                            <br></br>
                            {detail[0].ZN01PH}
                            <br></br>
                            {detail[0].ZN02PH}
                        </div>
                    </div>
                }




            </div>
        </div>
    )

}

export default OrderEdition;