import React from "react"
import { readOnly } from "../supplyChain/functions/SubcontractingTable"

/**
 * index is row
 * id is column
 * @param {*} param0 
 * @returns 
 */ 
const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateData, // This is a custom function that we supplied to our table instance
    currentUser: currentUser
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)
  
    const onChange = e => {
      setValue(e.target.value)
    }
  
    // We'll only update the external data when the input is blurred
    const onBlur = (e) => {
        updateData(id, value, e);

        
    }
  
    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue)
    }, [initialValue])
    
    return <input 
              className="form-control"//{id === "sent" && "text-center"} 
              value={value ? value : undefined} 
              style={inputStyle(id)}
              onChange={onChange} 
              onBlur={onBlur} 
              onFocus={onFocus}
              readOnly={readOnly(id, currentUser)}/>
  }

  const inputStyle = (id) => {
    if( id === "sent" ) return {width: '50px', marginLeft: "auto", marginRight: "auto"};

    return {width: ''};
  };

  const onFocus = (e) => {
    e.target.setAttribute("oldValue", e.target.value);
  }

  export default EditableCell;