import { Fragment, useEffect, useState } from "react";
import getUrlParam from "../../functions/StrFunctions";
import { useMemo } from "react";
import { DefaultColumnFilter, SelectEmptyOrNot, filterMethods } from "../../functions/TableFuntions";
import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table";
import NeedsMngt from "../../services/articles/NeedsMngt";
import errorManagement from "../../services/errorManagement";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import Moment from "moment";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import { confirmAlert } from "react-confirm-alert";
import LabelsChoice from "../../common/smallComponents.js/LabelsChoice";
import Movement from "../../services/storehouse/Movement";
import { downloadSingleFile } from "../../services/functions/FilesProcess";

const DisplayNeeds = (props) => {
    const [id, setId] = useState();
    const [type, setType] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [header, setHeader] = useState();
    const user = JSON.parse(localStorage.getItem("user"));
    const [data, setData] = useState([]);
    const [toDeleteNeed, setToDeleteNeed] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const [moving, setMoving] = useState(false);
    const [sending, setSending] = useState(false);
    const [bodyMailStorehouse, setbodyMailStorehouse] = useState("");
    Moment.locale("fr");

    useEffect(() => {
        var id = getUrlParam(props, "id");
        setId(id);
        setType(getUrlParam(props, "type"));

        NeedsMngt.getFreeNeed(id).then(
            (response) => {
                setHeader(response.header);
                setData(response.detail);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );

    }, []);

    //Set columns
    const columns = useMemo(
        () => [
            {
                Header: "#",
                accessor: "normm"
            },
            {
                Header: "Affaire",
                accessor: "nocdm"
            },
            {
                Header: "Machine",
                accessor: "nonnm"
            },
            {
                Header: "Article",
                accessor: "cdarm"
            },
            {
                Header: "Référence",
                accessor: "zn02pa"
            },
            {
                Header: "Libellé",
                accessor: "zn03pa"
            },
            {
                Header: "Qte att.",
                accessor: "qtram"
            },
            {
                Header: "Qte réservée",
                accessor: "affected",
                Filter: SelectEmptyOrNot,
                filter: 'empty'
            },
            {
                Header: "Reste à préparer",
                accessor: "remain"
            },
            {
                Header: "Qté dispo en stock",
                accessor: "available"
            },
            {
                Header: "Cmd. stock",
                accessor: "cmd_stck",
                Filter: SelectEmptyOrNot,
                filter: 'empty'
            },
            {
                Header: "Délai demandé",
                accessor: "dtlim"
            },
            {
                Header: "Cmd. besoins",
                accessor: "cmd_need",
                Filter: SelectEmptyOrNot,
                filter: 'empty'
            },
            {
                Header: "Fournisseur",
                accessor: "cdfou"
            }
        ], []
    );

    //Set filters for table
    const filterTypes = useMemo(() => (filterMethods()), []);

    //Set default columns of table
    const defaultColumn = useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter
        }),
        []
    );

    //Create table
    const tableInstance = useTable({ columns, data, defaultColumn, filterTypes }, useFilters, useGlobalFilter, useSortBy);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance

    // To delete need
    const toDelete = (e) => {
        var need = e.target.getAttribute("data-need");
        var arr = [...toDeleteNeed];

        if (e.target.checked) {
            arr.push(need);
        } else {
            arr = arrayRemoveElt(arr, arr.indexOf(need));
        }

        setToDeleteNeed(arr);
    }

    /**
     * Delete needs
     */
    const deleteNeed = () => {
        confirmAlert({
            title: "Confirmation",
            message: "Etes-vous sûr(e) de vouloir supprimer " + toDeleteNeed.length + " article(s) ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setDeleting(true);
                        NeedsMngt.deleteFreeNeed(toDeleteNeed).then(
                            (response) => {
                                window.location.reload();
                            },
                            (error) => {
                                setError(errorManagement.handleError(props, error));
                                setDeleting(false);
                            }
                        )
                    }
                },
                {
                    label: "Non"
                }
            ]
        });


    }

    /**
     * Create labels
     */
    const exportLabels = (start) => {
        var arr = new Array();

        data.map(v => { if (v.affected !== null) arr.push(v) });

        setMoving(true);
        NeedsMngt.generateLabel(arr, start, header.id, header.user_name, header.date_request).then(
            (response) => {
                var file = response.substring(response.lastIndexOf("\\") + 1, response.length);
                downloadSingleFile("temp", file);
                setMoving(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setMoving(false);
            }
        )
    }

    /**
     * Send storehouse mail
     */
    const sendStorehouseMail = () => {
        if (bodyMailStorehouse.trim() === "") {
            setError("Saisissez le corps du mail avant d'envoyer.");
            scrollToTop();
            return;
        }

        setSending(true);
        NeedsMngt.storehouseSendMail(header, bodyMailStorehouse).then(
            (response) => {
                setMsg("Mail envoyé !");
                scrollToTop();
                setSending(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setSending(false);
            }
        );
    }

    return <Fragment>
        {loading && <WaitingRoundSnippers />}
        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        {
            header &&
            <Fragment>
                <div style={{ display: "flex" }}>
                    <div className="card" style={{ width: "30%", marginLeft: "0px", marginTop: "5px" }}>
                        <div className="card-body fw-bold">
                            Demande n°{header.id}<br></br>
                            Demandeur: {header.user_name}<br></br>
                            Date de la demande: {Moment(header.date_request).format("DD/MM/yyyy")}
                        </div>
                    </div>

                    {
                        (user.roles.includes('ROLE_STOREHOUSE') || user.roles.includes('ROLE_ADMIN') ) &&
                        <div className="accordion" id="accordionExample" style={{ width: "50%", marginLeft: "0px", marginTop: "5px" }}>
                            <div className="accordion-item">
                                <h2 className="accordion-header text-center" id="headingOne">
                                    <button className="accordion-button fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Outils magasiniers
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <h5>Générer les étiquettes des articles réservés</h5>
                                        <LabelsChoice onChooseTag={exportLabels} printingLabel={moving} />
                                    </div>

                                    <div className="input-group mb-3">
                                        <span className="input-group-text">Envoyer un mail<br></br>(double cliquez pour utiliser le modèle)</span>
                                        <textarea className="form-control" onDoubleClick={(e) => { setbodyMailStorehouse(e.target.getAttribute("placeholder")) }}
                                            onChange={(e) => { setbodyMailStorehouse(e.target.value) }}
                                            placeholder="Nous avons traité entièrement cette demande. Merci de la clôturer. Vous pourrez trouver votre colis..."
                                            value={bodyMailStorehouse}></textarea>

                                    </div>

                                    <div className="text-center mb-3">
                                        <button className="btn btn-success" disabled={sending}
                                            onClick={sendStorehouseMail}>
                                            {sending && <ButtonWaiting />}
                                            Envoyer
                                        </button>
                                    </div>
                                </div>


                            </div>
                            <br></br>
                        </div>
                
                    }
                </div>

                <table {...getTableProps()} className="table text-center" id="subcontracting_table">
                    <thead>
                        {// Loop over the header rows
                            headerGroups.map(headerGroup => (
                                // Apply the header row props
                                <tr className="table-dark fixed-header" {...headerGroup.getHeaderGroupProps()}>
                                    {// Loop over the headers in each row
                                        headerGroup.headers.map(column => (
                                            // Apply the header cell props
                                            <th {...column.getHeaderProps(column.getSortByToggleProps(),)}>
                                                {column.render('Header')}

                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? ' 🔽'
                                                            : ' 🔼'
                                                        : ''}
                                                </span>

                                            </th>

                                        ))}

                                    {
                                        user.id === header.user_id &&
                                        <th>A supprimer</th>
                                    }
                                </tr>



                            ))}
                        {// Loop over the header rows
                            headerGroups.map(headerGroup => (
                                // Apply the header row props
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {// Loop over the headers in each row
                                        headerGroup.headers.map(column => (
                                            // Apply the header cell props
                                            <th {...column.getHeaderProps()}>
                                                {/* Render the columns filter UI */}
                                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                                            </th>
                                        ))}
                                    {
                                        user.id === header.user_id &&
                                        <th>{
                                            toDeleteNeed.length > 0 &&
                                            <button className="btn btn-danger" onClick={deleteNeed}
                                                disabled={deleting}>
                                                {deleting && <ButtonWaiting />}
                                                Supprimer
                                            </button>
                                        }</th>
                                    }
                                </tr>
                            ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {// Loop over the table rows
                            rows.map(row => {
                                // Prepare the row for display
                                prepareRow(row)
                                return (
                                    // Apply the row props
                                    <tr {...row.getRowProps()} className={row.cells[8].value === 0 ? "bg-success bg-gradient" : ""}>
                                        {// Loop over the rows cells
                                            row.cells.map(cell => {
                                                return (
                                                    <td {...cell.getCellProps()}>
                                                        {cell.render('Cell')}
                                                    </td>
                                                )
                                            })}
                                        {
                                            user.id === header.user_id &&
                                            <td>
                                                <input type="checkbox" onClick={toDelete}
                                                    data-need={row.cells[0].value}></input>
                                            </td>
                                        }
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </Fragment>
        }
    </Fragment>

}

export default DisplayNeeds;