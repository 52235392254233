import { Fragment, useState } from "react";
import Moment from "moment";
import BusinessDao from "../../../services/machines/BusinessDao";
import { ButtonWaiting } from "../../../functions/PageAnimation";
import fahDao from "../../../services/common/fahDaoV2";
import { downloadSingleFile } from "../../../services/functions/FilesProcess";
import WaitingRoundSnippers from "../../../common/smallComponents.js/WaitingRoundSnippers";

/**
 * 
 * @param {*} p 
 * @returns 
 */
const FahHeader = (p) => {
    const [downloading, setDownloading] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));

    const downloadXls = () => {
        setDownloading(true);

        fahDao.downloadClockSheet(p.userInfo.trigram, p.week, p.year).then(
            (response) => {
                var file = response.substring(response.lastIndexOf("\\") + 1, response.length);
                downloadSingleFile("temp", file);
                setDownloading(false);
            }, (error) => {
                setDownloading(false);
            }
        )
    }

    return <Fragment>{
        p.weekDays && <tr className="no-change-bg text-center">
            <td rowSpan={p.rowSpan} className="big-separator">
                <div className="fw-bold">
                    <h5>Période: {p.week} / {p.year}</h5>
                    {
                        p.userInfo && <div>
                            <span>{p.userInfo.focusInfo && p.userInfo.focusInfo.PNOM} {p.userInfo.focusInfo && p.userInfo.focusInfo.PPRENO}&nbsp;
                                ({p.userInfo.focusInfo && p.userInfo.focusInfo.ZN01PP})</span><br></br>
                            <span>{p.userInfo.focusInfo && (p.userInfo.focusInfo.CDINT === "O" ? "Intérimaire" : "Salarié")}</span>
                        </div>
                    }
                </div>
                <div className="form-check" style={{ fontSize: "12px", maxWidth: "130px", margin: "auto" }}>
                    <input className="form-check-input" type="checkbox" checked={p.minimalDisplay}
                        onChange={() => p.setMinimalDisplay(!p.minimalDisplay)} />
                    <label className="form-check-label" for="flexCheckDefault">
                        Affichage minimal
                    </label>
                </div>
                <div style={{ position: "absolute", top: "5px" }} className="cursor-pointer"
                    title="Télécharger le fichie Excel" onClick={downloadXls}>
                    {downloading && <WaitingRoundSnippers />}
                    {!downloading && <img src="/common/xls_logo.png"></img>}
                </div>
            </td>

            <td className={"big-separator " + p.weekDays[0].holidayClass} colSpan={p.colSpan}>
                Lundi {Moment(p.weekDays[0].en_date).format("DD/MM/yyyy")}
                <br></br>
                <span>{p.weekDays[0].schedule}h </span>
                {p.weekDays[0].holiday}
            </td>

            <td className={"big-separator " + p.weekDays[1].holidayClass} colSpan={p.colSpan}>
                Mardi {Moment(p.weekDays[1].en_date).format("DD/MM/yyyy")}
                <br></br>
                <span>{p.weekDays[1].schedule}h </span>
                {p.weekDays[1].holiday}
            </td>

            <td className={"big-separator " + p.weekDays[2].holidayClass} colSpan={p.colSpan}>
                Mercredi {Moment(p.weekDays[2].en_date).format("DD/MM/yyyy")}
                <br></br>
                <span>{p.weekDays[2].schedule}h </span>
                {p.weekDays[2].holiday}
            </td>

            <td className={"big-separator " + p.weekDays[3].holidayClass} colSpan={p.colSpan}>
                Jeudi {Moment(p.weekDays[3].en_date).format("DD/MM/yyyy")}
                <br></br>
                <span>{p.weekDays[3].schedule}h </span>
                {p.weekDays[3].holiday}
            </td>

            <td className={"big-separator " + p.weekDays[4].holidayClass} colSpan={p.colSpan}>
                Vendredi {Moment(p.weekDays[4].en_date).format("DD/MM/yyyy")}
                <br></br>
                <span>{p.weekDays[4].schedule}h </span>
                {p.weekDays[4].holiday}
            </td>

            <td className={"big-separator " + p.weekDays[5].holidayClass} colSpan={p.colSpan}>
                Samedi {Moment(p.weekDays[5].en_date).format("DD/MM/yyyy")}
                <br></br>
                <span>{p.weekDays[5].schedule}h </span>
                {p.weekDays[5].holiday}
            </td>

            <td className={"big-separator " + p.weekDays[6].holidayClass} colSpan={p.colSpan}>
                Dimanche {Moment(p.weekDays[6].en_date).format("DD/MM/yyyy")}
                <br></br>
                <span>{p.weekDays[6].schedule}h </span>
                {p.weekDays[6].holiday}
            </td>

            <td colSpan={p.colSpan} className="big-separator">
                Total semaine
                <br></br>
                <div>
                    {p.summary.tot_schedule && <span>{p.summary.tot_schedule.toFixed(1)}h</span>}
                    <img src="/common/refresh.png" style={{ maxWidth: "20px", marginLeft: "5px" }}
                        onClick={p.recalculateSchedule} id="recalculate_schedule" />
                </div>

            </td>
        </tr>
    }
    </Fragment>
}



/**
 * BUSINESS SEARCH
 * 
 * @param {*} p 
 * @returns 
 */
const BusinessSearch = (p) => {
    const yearCode = String.fromCharCode(67 + (p.year - 2016));
    const [searching, setSearching] = useState(false);
    const [businessList, setBusinessList] = useState();

    setTimeout(() => {
        document.getElementById("businessSearchIpt").focus();
    }, 500);

    const searchBusiness = (e) => {
        e.preventDefault();
        var business = e.target.business.value.toUpperCase();
        setBusinessList();

        //Correct automatically the code of year
        if ("CGRF".indexOf(business.substring(0, 1)) > -1 && !business.startsWith("CSAL") 
                && business !== "G6890019" ) {
            if (business.length < 4) {
                for (let i = business.length; i < 4; i++)
                    business += "_";
                business += yearCode;
            } else {
                business = business.substring(0, 4) + yearCode
                    + business.substring(5, business.length);
            }
        }

        setSearching(true);
        BusinessDao.searchInFocus(business).then(
            (response) => {
                setBusinessList(response)
                setSearching(false);
            }
        );
    }

    const selectBusiness = (e) => {
        var k = e.target.getAttribute("data-key");

        var arr = [...p.hours];
        arr[p.businessSearch.row].business = businessList[k].NOCDE.trim();
        arr[p.businessSearch.row].label = businessList[k].DESIE.trim();
        arr[p.businessSearch.row].machineSearch = true;
        p.setHours(arr);
        p.setBusinessSearch();
        delete arr[p.businessSearch.row].machineList;

        var choosenMachine = arr[p.businessSearch.row].function;
        //Search machines
        BusinessDao.getMachines(businessList[k].NOCDE.trim().substring(0, 7) + "0").then(
            (response) => {
                var flag = false;
                for (let i = 0; i < response.length; i++)
                    if (response[i].no_machine.trim().endsWith(choosenMachine)) flag = true;

                if (!flag) arr[p.businessSearch.row].function = null;

                arr[p.businessSearch.row].machineList = response;
                delete arr[p.businessSearch.row].machineSearch;
                p.setHours([...arr]);
            },
            (error) => {
            }
        );
    }

    return <div className="custom-tooltip">
        <button type="button" className="btn-close" aria-label="Close" onClick={() => p.setBusinessSearch()}></button>
        <h5 className="text-center">Rechercher une affaire</h5>

        <form onSubmit={searchBusiness}>
            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">Code affaire</span>
                <input type="text" className="form-control fw-bold text-center"
                    name="business" autoComplete="off" id="businessSearchIpt" required />
                <button className="btn btn-success" disabled={searching}>
                    {searching && <ButtonWaiting />}
                    Chercher
                </button>
            </div>
        </form>

        {
            businessList && <ul className="list-group text-center fw-bold" style={{ maxHeight: "200px", overflowY: "auto" }}>
                {
                    businessList.map((v, k) => {
                        return <li key={"busin" + k} data-key={k} onClick={selectBusiness}
                            className="list-group-item selectionHover">
                                <div data-key={k}>{v.NOCDE}</div>
                                <div data-key={k} className="fst-italic fw-normal">{v.DESIE}</div>
                            </li>
                    })
                }
            </ul>
        }
    </div>
}

var noChange = ["c1", "c2", "delay", "allowed_abs", "not_allowed_abs"];
function caculateSchedule(weekDays, abs) {
    var arr = [...weekDays];

    arr.map((v, k) => {
        if (!v.schedule_save) v.schedule_save = v.schedule;

        if (v.holiday !== "") {
            v.schedule = 0;
        } else {
            if (k === 0 && abs.abs_l !== null) {
                v.schedule = abs.abs_l === "none" ? v.schedule_save
                    : abs.abs_l.indexOf("_half") !== -1 ? parseFloat(v.schedule_save) / 2
                        : noChange.includes(abs.abs_l) ? v.schedule_save : 0;
            } else if (k === 1 && abs.abs_m !== null) {
                v.schedule = abs.abs_m === "none" ? v.schedule_save
                    : abs.abs_m.indexOf("_half") !== -1 ? parseFloat(v.schedule_save) / 2
                        : noChange.includes(abs.abs_m) ? v.schedule_save : 0;
            } else if (k === 2 && abs.abs_mm !== null) {
                v.schedule = abs.abs_mm === "none" ? v.schedule_save
                    : abs.abs_mm.indexOf("_half") !== -1 ? parseFloat(v.schedule_save) / 2
                        : noChange.includes(abs.abs_mm) ? v.schedule_save : 0;
            } else if (k === 3 && abs.abs_j !== null) {
                v.schedule = abs.abs_j === "none" ? v.schedule_save
                    : abs.abs_j.indexOf("_half") !== -1 ? parseFloat(v.schedule_save) / 2
                        : noChange.includes(abs.abs_j) ? v.schedule_save : 0;
            } else if (k === 4 && abs.abs_v !== null) {
                v.schedule = abs.abs_v === "none" ? v.schedule_save
                    : abs.abs_v.indexOf("_half") !== -1 ? parseFloat(v.schedule_save) / 2
                        : noChange.includes(abs.abs_v) ? v.schedule_save : 0;
            }
        }
    })

    return arr;
}


export {
    FahHeader,
    BusinessSearch,
    caculateSchedule
};
