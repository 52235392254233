import { Fragment, useState } from "react";
import { diffTwoTimeInFr, getDateTimeFromDb } from "../../../functions/StrFunctions";
import CsContracts from "../../../services/customClient/CsContracts";
import errorManagement from "../../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../../functions/PageAnimation";
import IncidentMail from "./IncidentMail";

const IncidentCard = (p) => {
    
    const onTicketChange = (e, field) => {
        var o = { ...p.it };
        o[field] = e.target.value;

        if ((field === "call_time" || field === "end_of_intervention") && o.call_time && o.end_of_intervention)
            o.intervention_duration = diffTwoTimeInFr(o.call_time, o.end_of_intervention);

        p.setIt(o);
    }

    const saveForm = () => {
        p.setError();
        p.setLoading(true);

        CsContracts.saveTicket(p.it).then(
            (r) => {
                window.location.href = "/customer-client/incident-ticket/list?page=list&msg=saved";
                p.setLoading(false);
            },
            (error) => {
                p.setError(errorManagement.handleError(p.props, error));
                p.setLoading(false);
                scrollToTop();
            }
        )
    }

    return <Fragment>
        {p.it.id === 0 && <h5 className="text-center">Nouveau ticket incident</h5>}
        {p.it.id > 0 && <h5 className="text-center">Ticket incident n°{p.it.id}</h5>}

        {!p.hotline && <div className="alert alert-warning text-center fw-bold">
            Aucun contrat de hotline prévu pour cette période. Veuillez vous en assurer sur Focus.
        </div>}

        {p.hotline && <div className="alert alert-info text-center fw-bold">
            Un contrat de hotline est prévu pour la période du&nbsp;{p.hotline.start_hotline}&nbsp;au&nbsp;
            {p.hotline.end_hotline}&nbsp;(affaire: {p.hotline.business})
        </div>}

        <div className="card">
            <h4>L'affaire</h4>

            <div className="d-flex">
                <div className="input-group mb-3 me-5">
                    <span className="input-group-text" style={{ width: "150px" }}>Code affaire</span>
                    <input type="text" className="form-control" defaultValue={p.it.business} readOnly />
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "150px" }}>Tel</span>
                    <input type="text" className="form-control" defaultValue={p.it.tel}
                        onBlur={(e) => onTicketChange(e, "tel")} />
                </div>
            </div>

            <div className="d-flex">
                <div className="input-group mb-3 me-5">
                    <span className="input-group-text" style={{ width: "150px" }}>Client</span>
                    <input type="text" className="form-control" defaultValue={p.it.client}
                        onBlur={(e) => onTicketChange(e, "client")} />
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "150px" }}>N° de machine</span>
                    <input type="text" className="form-control" value={p.machineInfo.no_machine} readOnly />
                </div>
            </div>

            <div className="d-flex">
                <div className="input-group mb-3 me-5">
                    <span className="input-group-text" style={{ width: "150px" }}>Contact</span>
                    <input type="text" className="form-control" defaultValue={p.it.contact}
                        onBlur={(e) => onTicketChange(e, "contact")} />
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "150px" }}>Type de machine</span>
                    <input type="text" className="form-control" value={p.machineInfo.typ_config} readOnly />
                </div>
            </div>

            <div className="d-flex">
                <div className="input-group mb-3 me-5">
                    <span className="input-group-text" style={{ width: "150px" }}>Désignation hotline</span>
                    <input type="text" className="form-control" value={p.hotline && p.hotline.designation} readOnly />
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "150px" }}>Durée garantie</span>
                    <input type="text" className="form-control" value={p.machineInfo.garantie_aff} readOnly />
                </div>
            </div>

        </div>

        <div className="card">
            <h4>L'incident</h4>

            <div className="d-flex">
                <div className="input-group mb-3 me-5">
                    <span className="input-group-text" style={{ width: "230px" }}>Intervenant</span>
                    <input type="text" className="form-control" defaultValue={p.it.speaker} list="operator"
                        onBlur={(e) => onTicketChange(e, "speaker")} />

                    <datalist id="operator">
                        {p.installers && p.installers.map((v, k) => {
                            return <option value={v.trigram} key={"inst" + k} />
                        })}
                    </datalist>
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "230px" }}>Solutions</span>
                    <textarea className="form-control" defaultValue={p.it.solutions}
                        onBlur={(e) => onTicketChange(e, "solutions")} ></textarea>
                </div>
            </div>

            <div className="d-flex">
                <div className="input-group mb-3 me-5">
                    <span className="input-group-text" style={{ width: "230px" }}>Début d'intervention</span>
                    <input type="datetime-local" className="form-control"
                        defaultValue={getDateTimeFromDb(p.it.call_time, "en")}
                        onBlur={(e) => onTicketChange(e, "call_time")} />
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "230px" }}>Pièce incriminée</span>
                    <input type="text" className="form-control" defaultValue={p.it.defective_parts}
                        onBlur={(e) => onTicketChange(e, "defective_parts")} />
                </div>
            </div>

            <div className="d-flex">
                <div className="input-group mb-3 me-5">
                    <span className="input-group-text" style={{ width: "230px" }}>Motif de l'appel</span>
                    <select type="text" className="form-select" value={p.it.request_subject}
                        onChange={(e) => onTicketChange(e, "request_subject")}>
                        <option value=""></option>
                        {p.defaults.map((v, k) =>
                            <option key={"def" + k} value={v.label}>{v.label}</option>)}
                    </select>
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "230px" }}>Fin d'intervention</span>
                    <input type="datetime-local" className="form-control"
                        defaultValue={getDateTimeFromDb(p.it.end_of_intervention, "en")}
                        onBlur={(e) => onTicketChange(e, "end_of_intervention")} />
                </div>
            </div>

            <div className="d-flex">
                <div className="input-group mb-3 me-5">
                    <span className="input-group-text" style={{ width: "230px" }}>Commentaires</span>
                    <textarea type="text" className="form-control" defaultValue={p.it.comments}
                        onBlur={(e) => onTicketChange(e, "comments")} />
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "230px" }}>Etat de la machine à la fin</span>
                    <select type="text" className="form-select" defaultValue={p.it.state_mach_end}
                        onChange={(e) => onTicketChange(e, "state_mach_end")}>
                        <option value=""></option>
                        <option value="N">Choisissez...</option>
                        <option value="A">Arrêt</option>
                        <option value="M" selected="selected">Marche</option>
                        <option value="PT">Passe travers pli</option>
                    </select>
                </div>
            </div>

            <div className="d-flex">
                <div className="input-group mb-3 me-5">
                    <span className="input-group-text" style={{ width: "230px" }}>Etat de la machine au départ</span>
                    <select type="text" className="form-select" defaultValue={p.it.state_mach_start}
                        onChange={(e) => onTicketChange(e, "state_mach_start")}>
                        <option value=""></option>
                        <option value="N">Choisissez...</option>
                        <option value="A">Arrêt</option>
                        <option value="M" selected="selected">Marche</option>
                        <option value="PT">Passe travers pli</option>
                    </select>
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" style={{ width: "230px" }}>Durée d'intervention</span>
                    <input type="text" className="form-control" value={p.it.intervention_duration} readOnly />
                </div>
            </div>
        </div>

        <div className="text-center mb-3">
            <button className="btn btn-success" onClick={saveForm} disabled={p.loading}>
                {p.loading && <ButtonWaiting />}
                Soumettre
            </button>
        </div>
        
        { p.send && <IncidentMail setSend={p.setSend} it={p.it} machineInfo={p.machineInfo}/>}

    </Fragment>
}

export default IncidentCard;