import { Fragment } from "react";
import { useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { scrollToTop } from "../../functions/PageAnimation";
import HtsDao from "../../services/customClient/HtsDao";
import errorManagement from "../../services/errorManagement";
import { confirmAlert } from "react-confirm-alert";
import { setMessage } from "../../actions/message";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";

const HSTlist = (props) => {
    const [list, setList] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const user = JSON.parse(localStorage.getItem("user"));
    const [tab, setTab] = useState(0);
    const [readonly, setReadonly] = useState(user && user.roles && !user.roles.includes("ROLE_HST_CREATION"));
    const [menu, setMenu] = useState();
    const [searchList, setSearchList] = useState();
    const [searching, setSearching] = useState(false);
    const [sending, setSending] = useState(false);

    useState(() => {
        HtsDao.getRequestList().then(
            (response) => {
                setList(response);
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );

        HtsDao.getForms().then(
            (response) => {
                var arr = new Array();
                response.map(
                    v => { if (!arr.includes(v.chap_lab)) arr.push(v.chap_lab); }
                );

                setMenu(arr);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );
    }, [])

    /**
     * Cancel request
     * @param {} e 
     */
    const cancelRequest = (e) => {
        const article = e.target.getAttribute("data-article");
        const date = e.target.getAttribute("data-date");
        setLoading(true);

        confirmAlert({
            title: "Confirmation",
            message: "Etes-vous sûr de vouloir annuler la demande HTS code de l'article" + article + " ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        HtsDao.cancelRequest(article, date).then(
                            (response) => {
                                setList(response);
                                setLoading(false);
                            },
                            (error) => {
                                setLoading(false);
                                setError(errorManagement.handleError(props, error));
                                scrollToTop();
                            }
                        );
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    /**
     * Change tab
     * @param {*} e 
     */
    const changeTab = (e) => {
        setTab(parseInt(e.target.getAttribute("data-tab")));
    }

    const onHtsCodeChange = (e) => {
        var key = e.target.getAttribute("data-key");
        var arr = [...list];
        arr[key].value1 = e.target.value;
        setList(arr);
    }

    const saveHtsCode = (e) => {
        var key = e.target.getAttribute("data-key");
        var article = list[key].mach_nb;
        var date = list[key].creation_date;
        var hstCode = e.target.value;

        HtsDao.updateHtsCode(article, date, hstCode).then(
            (response) => {
                scrollToTop();
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );

    }

    const search = (e) => {
        e.preventDefault();
        setSearching(true);
        setSearchList();

        var art = e.target.art.value;
        var user = e.target.user.value;
        var cat = e.target.cat.value;
        var classCode = e.target.classCode.value;

        HtsDao.getRequestListSearch(art, user, cat, classCode).then(
            (response) => {
                setSearchList(response);
                setSearching(false);
            },
            (error) => {
                setSearching(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );
    }

    const notifyEmergency = () => {
        setMessage();
        confirmAlert({
            title: "Confirmation",
            message: "Etes-vous sûr de vouloir envoyer un mail pour demander de remplir les HTS code en urgence ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setSending(true);
                        HtsDao.sendEmergencyMail().then(
                            (response) => {
                                setSending(false);
                                setMessage("Mail envoyé !");
                            },
                            (error) => {
                                setSending(false);
                                setError(errorManagement.handleError(props, error));
                                scrollToTop();
                            }
                        );
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    return (
        <div>
            <h4 className="text-center">List</h4>

            {loading && <WaitingRoundSnippers />}

            {message && <SuccessMessage msg={message} />}

            {error && <ErrorMessage error={error} />}

            {
                readonly && 
                    <div className="text-center">
                        <button className="btn btn-danger" onClick={notifyEmergency} disabled={sending}>
                            {sending && <span className="spinner-border spinner-border-sm"></span>}
                            Demander de remplir HTS code en urgence
                        </button>
                    </div>
            }

            {
                list &&
                <Fragment>

                    <ul className="nav nav-tabs fixed-header" style={{ backgroundColor: "white" }}>
                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 0 ? "active" : "")}
                                style={{ backgroundColor: "white" }}
                                href="#" onClick={changeTab} data-tab="0">Request</a>
                        </li>

                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 1 ? "active" : "")}
                                style={{ backgroundColor: "white" }}
                                href="#" onClick={changeTab} data-tab="1">Search</a>
                        </li>

                    </ul>

                    {
                        tab === 0 &&
                        <table className="table text-center">
                            <thead className="table-dark">
                                <tr>
                                    <th scope="col">Article</th>
                                    <th scope="col">Author</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">HST code</th>
                                    <th scope="col">Classification Form<br></br>Number</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list.map(
                                        (v, k) => {
                                            return <tr key={"r" + k}>
                                                <th scope="row">{v.mach_nb}</th>
                                                <td>{v.controller}</td>
                                                <td>{v.creation_date}</td>
                                                <td>
                                                    {readonly && v.value1}
                                                    {
                                                        !readonly &&
                                                        <input type="text" data-key={k} onChange={onHtsCodeChange}
                                                            onBlur={saveHtsCode}
                                                            className="form-control text-center" value={v.value1 === null ? "" : v.value1} />
                                                    }
                                                </td>
                                                <td>{v.value2}</td>
                                                <td><a className="btn btn-success" target="_blank"
                                                    href={"/customer-client/hts/form?article=" +
                                                        v.mach_nb.trim() + "&date=" + v.creation_date}>Enter</a></td>
                                                <td><a className="btn btn-danger"
                                                    onClick={cancelRequest}
                                                    data-article={v.mach_nb.trim()}
                                                    data-date={v.creation_date}>Annuler</a></td>
                                            </tr>
                                        }
                                    )
                                }

                            </tbody>
                        </table>
                    }

                    {
                        tab === 1 &&
                        <Fragment>
                            <br></br>
                            <form onSubmit={search}>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">Search by</span>
                                    <input type="text" name="art" className="form-control" placeholder="Article code" />
                                    <input type="text" name="user" className="form-control" placeholder="Requester name" />
                                    <select className="form-select" name="cat">
                                        <option value="">Category</option>
                                        {
                                            menu.map(
                                                (v, k) =>
                                                    <option key={"cat_" + k} value={v}>{v}</option>
                                            )
                                        }
                                    </select>
                                    <input type="text" name="classCode" className="form-control" placeholder="Classification number" />
                                    <button type="submit" className="btn btn-success">&#x1F50E;</button>
                                </div>
                            </form>

                            {searching && <WaitingRoundSnippers />}

                            {
                                searchList &&
                                <table className="table text-center">
                                    <thead className="table-dark">
                                        <tr>
                                            <th scope="col">Article</th>
                                            <th scope="col">Author</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">HST code</th>
                                            <th scope="col">Classification Form<br></br>Number</th>
                                            <th scope="col"></th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            searchList.map(
                                                (v, k) => {
                                                    return <tr key={"r" + k}>
                                                        <th scope="row">{v.mach_nb}</th>
                                                        <td>{v.controller}</td>
                                                        <td>{v.creation_date}</td>
                                                        <td>
                                                            {readonly && v.value1}
                                                            {
                                                                !readonly &&
                                                                <input type="text" data-key={k} onChange={onHtsCodeChange}
                                                                    onBlur={saveHtsCode}
                                                                    className="form-control text-center" value={v.value1 === null ? "" : v.value1} />
                                                            }
                                                        </td>
                                                        <td>{v.value2}</td>
                                                        <td><a className="btn btn-success" target="_blank"
                                                            href={"/customer-client/hts/form?article=" +
                                                                v.mach_nb.trim() + "&date=" + v.creation_date}>Enter</a></td>
                                                        <td><a className="btn btn-danger"
                                                            onClick={cancelRequest}
                                                            data-article={v.mach_nb.trim()}
                                                            data-date={v.creation_date}>Annuler</a></td>
                                                    </tr>
                                                }
                                            )
                                        }

                                    </tbody>
                                </table>
                            }
                        </Fragment>

                    }

                </Fragment>
            }
        </div>
    )
}

export default HSTlist;