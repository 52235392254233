import { createColumnHelper } from "@tanstack/react-table";
import { Fragment, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import ReactTable from "../../../common/smallComponents.js/ReactTable";
import { ButtonWaiting, scrollToTop } from "../../../functions/PageAnimation";
import errorManagement from "../../../services/errorManagement";
import BomDaoV2 from "../../../services/machines/BomDaoV2";
 
const TempTable = (p) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const [working, setWorking] = useState(false);

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('cat', {
            header: 'Catégorie',
            filterFn: 'selectMultiple',
            cell: i => <div className="display-flex">
                <input className="form-control" defaultValue={i.getValue()} //onChange={p.onFieldChange}
                    onBlur={p.onFieldBlur} data-key={i.row.index} title={i.getValue()}
                    data-table="temp" data-field="cat" data-id={i.row.original.id}
                    style={{ minWidth: "150px" }}></input>
                {i.row.original.cat_changing && <button className="btn"><ButtonWaiting /></button>}
            </div>
        }));

        arr.push(columnHelper.accessor('se', {
            header: 'S/E',
            cell: i => <div className="text-center fw-bold">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('rep', {
            header: 'Repère',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('qty', {
            header: 'Qté',
            cell: i => <div className="display-flex">
                <input type="number" className="form-control text-center" defaultValue={i.getValue()}
                    onBlur={p.onFieldBlur} data-key={i.row.index}
                    data-table="temp" data-field="qty" data-id={i.row.original.id}
                    readOnly={i.row.original.qty_changing}></input>
                {i.row.original.qty_changing && <button className="btn"><ButtonWaiting /></button>}
            </div>
        }));

        arr.push(columnHelper.accessor('french_label', {
            header: 'Désignation FR',
            cell: i => <div>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('ref', {
            header: 'Référence',
            cell: i => <div className="display-flex">
                <input className="form-control" defaultValue={i.getValue()} //onChange={p.onFieldChange}
                    onBlur={p.onFieldBlur} style={{ minWidth: "250px" }} data-key={i.row.index} title={i.getValue()}
                    data-table="temp" data-field="ref" data-id={i.row.original.id}></input>
                {i.row.original.ref_changing && <button className="btn"><ButtonWaiting /></button>}
            </div>
        }));

        arr.push(columnHelper.accessor('maker', {
            header: 'Fabricant',
            cell: i => <div className="display-flex">
            <input className="form-control" defaultValue={i.getValue()} //onChange={p.onFieldChange}
                onBlur={p.onFieldBlur} data-key={i.row.index} title={i.getValue()}
                data-table="temp" data-field="maker" data-id={i.row.original.id}></input>
            {i.row.original.maker_changing && <button className="btn"><ButtonWaiting /></button>}
        </div>
        }));

        arr.push(columnHelper.accessor('ar', {
            header: 'Code article',
            filterFn: 'strWithEmpty',
            cell: i => <div className="display-flex">
            <input className="form-control" defaultValue={i.getValue()} //onChange={p.onFieldChange}
                onBlur={p.onFieldBlur} data-key={i.row.index} title={i.getValue()}
                data-table="temp" data-field="ar" data-id={i.row.original.id}
                style={{ minWidth: "150px" }}></input>
            {i.row.original.ar_changing && <button className="btn"><ButtonWaiting /></button>}
        </div>
        }));

        arr.push(columnHelper.accessor('stock_at_term', {
            header: 'Stock',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('OBSES', {
            header: 'Coms',
            cell: i => (i.getValue() && i.getValue() !== null && i.getValue().trim() !== "") ?
                    <img title={i.getValue()} src={"/common/tooltip.png"}></img> : ""
        }));

        return arr;
    }

    //Set columns
    const columns = getColumns();

    /**
     * Validate BOM
     */
    const validateBom = () => {

        confirmAlert({
            title: "Confirmation",
            message: "Etes-vous sûr de vouloir valider la nomenclature de la machine " + p.machine
                + " ? " + (p.existingBom ? "Cette action écrasera la nomenclature déjà enregistrée dans l'intranet." : ""),
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setWorking(true);
                        p.setMsg("Enregistrement en cours... La création des articles peut être très longue.");
                        scrollToTop();
                        BomDaoV2.confirmBom(p.machine, p.typeBom, p.list).then(
                            () => {
                                window.location.reload();
                            },
                            (error) => {
                                p.setMsg();
                                setWorking(false);
                                p.setError(errorManagement.handleError(p, error));
                                scrollToTop();
                            }
                        );
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    return <Fragment>
        {
            (user.roles.includes('ROLE_MECHANICAL') || user.roles.includes('ROLE_ELECTRICAL') ||
                user.roles.includes('ROLE_ADMIN')) &&
            <div className="mb-3 text-center">
                <button className="btn btn-success" disabled={working}
                    onClick={validateBom}>
                    {working && <ButtonWaiting />}
                    Valider et comparer avec Focus
                </button>
            </div>
        }

<ReactTable columns={columns} data={p.list}
                origin="bom_content" classes={"no-padding"} top0={true} />
    </Fragment>

}

export default TempTable;