import { Fragment, useEffect, useState } from "react";
import { SideNavBarV3, isSideBarOpened } from "../../common/smallComponents.js/DesignedIpt";
import MachineInfo from "../../services/machines/MachineInfo";
import errorManagement from "../../services/errorManagement";
import { getHeight, scrollToTop } from "../../functions/PageAnimation";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import getUrlParam, { getCurrentWeekYear } from "../../functions/StrFunctions";
import ReactPaginate from "react-paginate";

const GeneralPlanning = (props) => {
    const [pin, setPin] = useState(isSideBarOpened());

    const [loading, setLoading] = useState(true);
    const [plan, setPlan] = useState(false);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [form, setForm] = useState({
        page: null, business: "", machine: "", cdp: "",
        week: "", year: "", department: "", type_mach: "", duration: 1
    });
    const dptName = {
        order: "Prise de commande", be: "Etudes", purchase: "Achats", start_purchase: "Début d'achats",
        shopper: "Sorties de pièces", manufacturing: "Montage", commis: "Mise en service", mes: "MES/MEP",
        supplier: "Départ chez le fournisseur", fat: "FAT", delivery: "Départ d'usine", internat_del: "Expédition internationale",
        MeS: "Mise en service", MeP: "Mise en prod"
    }
    const eventName = {
        prototype: { txt: "PT", title: "Prototypes" }, preseries: { txt: "PS", title: "Pré-séries" },
        amme: { txt: "AM", title: "Envoi chez AMME pour montage" }, ayer: { txt: "AY", title: "Envoi chez SealedAir US pour montage" },
        MeS: { txt: "MeS", title: "Mise en service sur site" }, MeP: { txt: "MeP", title: "Mise en production" },
    };
    const [moved, setMoved] = useState(false);
    const [done, setDone] = useState(0);

    useEffect(() => {
        var o = { ...form };
        if (getUrlParam(props, "business") !== null)
            for (const [key, value] of Object.entries(form))
                o[key] = getUrlParam(props, key);

        setForm(o);

        getPlanning(o);
    }, []);

    function getPlanning(o) {
        setLoading(true);
        MachineInfo.getPlanning(o).then(
            (response) => {
                setPlan(response);
                setDone(0)
                scrollLeft();
                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }

    const updatePlanning = () => {
        setLoading(true);
        MachineInfo.updatePlanning().then(
            (response) => {
                window.location.reload();
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }

    function getDpt(week, year, detail) {
        var period = week + "/" + year;
        var i = detail.indexOf(period);
        if (i === -1) return "";

        var dpt = detail.substring(0, i);
        dpt = dpt.substring(dpt.lastIndexOf("+") + 1, dpt.length).replace(":", "")
        return dpt;
    }

    function getTitle(week, year, detail, interval) {
        var title = "";
        var dpt = getDpt(week, year, detail);

        if (dptName[dpt]) return dptName[dpt] + ": " + interval

        return title;
    }

    function getFirstRowTxt(week, year, detail, interval, type) {
        var txt = "";
        var dpt = getDpt(week, year, detail);
        if (eventName[dpt] && type === "txt") return eventName[dpt].txt
        if (eventName[dpt] && type === "title") return eventName[dpt].title + ": " + interval
    }

    const changePage = (e) => {
        var o = { ...form }
        o.page = e;
        getPlanning(o);
    }

    useEffect(() => {
        if (plan && !moved) {
            scrollLeft();
        }
    }, [plan]);

    function scrollLeft() {
        if (document.getElementById("period_" + plan.period) !== null) {
            var leftRect = document.getElementById("period_" + plan.period).offsetLeft
            setMoved(true);
            document.getElementById("gp-div").scrollTo({ left: leftRect, behavior: "smooth" })
        } else {
            setDone(done+1);
            if( done <= 10 ) setTimeout(() => { scrollLeft(); }, 500);
        }
    }

    return <div className="bg-see navbarV3 right-side" id="general-planning">

        <SideNavBarV3 setPin={setPin} content={plan && <div className="ps-3 pe-3">
            <div className="text-center mb-3">
                {loading && <WaitingRoundSnippers />}
                {!loading && <a href="#" onClick={updatePlanning}>Cliquez ici pour synchroniser avec le serveur</a>}
            </div>
            <div className="text-center">
                <a target="_blank" href="https://sealedair.sharepoint.com/:x:/r/teams/SealedAirGemenos/_layouts/15/doc2.aspx?action=edit&sourcedoc=%7B816a617f-0fe8-4247-855b-a8e1dfa9f638%7D&wdOrigin=TEAMS-ELECTRON.recent.recent&wdExp=TEAMS-CONTROL">Ouvrir le fichier Excel</a>
            </div>
            <div className="h-divider">
                <div className="shadow-divider"></div>
            </div>
            <h6 className="text-center">Rechercher</h6>
            {getUrlParam(props, "business") !== null &&
                <div className="text-center mb-3">
                    <a href="/general-planning">Supprimer la recherche</a>
                </div>}

            <form>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" name="business"
                        placeholder="xxx" defaultValue={form.business} />
                    <label>Affaire</label>
                </div>

                <div className="form-floating mb-3">
                    <input type="text" className="form-control" name="machine" placeholder="xxx"
                        defaultValue={form.machine} />
                    <label>Machine</label>
                </div>

                <div className="form-floating mb-3">
                    <input type="text" className="form-control" name="type_mach" placeholder="xxx"
                        defaultValue={form.type_mach} />
                    <label>Type de machine</label>
                </div>

                <div className="form-floating mb-3">
                    <input type="text" className="form-control" name="cdp" placeholder="xxx"
                        defaultValue={form.cdp} />
                    <label>Chef de projet</label>
                </div>

                <div className="text-center fst-italic">
                    Semaine en cours: {plan.cur_wk}
                </div>

                <div className="d-flex">

                    <div className="form-floating mb-3 me-1">
                        <input type="number" className="form-control" name="week" placeholder="xxx"
                            defaultValue={form.week} />
                        <label>Semaine</label>
                    </div>

                    <div className="form-floating mb-3">
                        <input type="number" className="form-control" name="year" placeholder="xxx"
                            defaultValue={form.year} />
                        <label>Année</label>
                    </div>

                    <div className="form-floating mb-3">
                        <input type="number" className="form-control" name="duration" placeholder="xxx"
                            defaultValue={form.duration} />
                        <label>Durée</label>
                    </div>

                </div>


                <div className="form-floating mb-3">
                    <select className="form-select" name="department" defaultValue={form.department}>
                        <option value="">Choisissez...</option>
                        <option className="order" value="order">Prise de commande</option>
                        <option className="be" value="be">Études</option>
                        <option className="start_purchase" value="start_purchase">Débuts Achats</option>
                        <option className="purchase" value="purchase">Achats</option>
                        <option className="shopper" value="shopper">Sorties pcs</option>
                        <option className="manufacturing" value="manufacturing">Montage</option>
                        <option className="commis" value="commis">MeS atelier</option>
                        <option className="mes" value="MeS">MeS site</option>
                        <option className="mes" value="MeP">MeP site</option>
                        <option className="supplier" value="supplier">Envoi FRN</option>
                        <option className="fat" value="fat">FAT</option>
                        <option className="delivery" value="delivery">Départ du site</option>
                        <option className="internat_del" value="internat_del">Expédition internationale</option>
                        <option className="prototype" value="prototype">Prototype</option>
                        <option className="preseries" value="preseries">Pré-série</option>
                        <option className="ayer" value="ayer">AYER</option>
                        <option className="amme" value="amme">AMME</option>
                    </select>
                    <label>Service</label>
                </div>

                <input type="hidden" name="page" value={form.page || 0}></input>

                <div className="text-center">
                    <button className="btn btn-outline-success">Rechercher</button>
                </div>
            </form>
        </div>} />

        <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>
            <h5 className="text-center">Planning général</h5>
            {loading && <WaitingRoundSnippers />}

            {(plan && !loading) && <div className="table-responsive mb-3" id="gp-div"
                style={{ height: getHeight("general-planning", 150) }}>
                <table className="mb-3" id="general-planning-table">
                    <tr className="years">
                        <th colSpan={2} className="void_th"></th>
                        <th className="cdp_td">CDP</th>
                        <th className="mes_td hd">Resp.</th>
                        {plan.plan.years.map((v, i) => {
                            return <th key={"y" + i} colSpan={v.nb_of_week}>{v.year}</th>
                        })}
                    </tr>

                    <tr className="weeks">
                        <th colSpan={2} className="void_th"></th>
                        <th className="cdp_td"></th>
                        <th className="mes_td hd">MeS</th>
                        {plan.plan.weeks.map((v, i) => {
                            return <th key={"w" + i} title={v.interval}
                                id={"period_" + v.week + "_" + v.year}
                                className={"period_" + v.week + "_" + v.year + " "
                                    + (v.week + "_" + v.year === plan.period ? "bg-danger text-white" : "")}>
                                {v.week}</th>
                        })}
                    </tr>

                    {plan.plan.list.map((v, k) => {
                        return <Fragment>
                            <tr className="dpt">
                                <td className="business_td" rowSpan={2}>{v.business}</td>
                                <td className="machine_td" rowSpan={2}>{v.machine}</td>
                                <td className="cpd_td" rowSpan={2}>{v.chef_proj}</td>
                                <td className="mes_td dt" rowSpan={2}>{v.resp_med_mep}</td>

                                {plan.plan.weeks.map((v2, k2) => {
                                    return <td key={"dep_" + k2} data-interval={v2.interval}
                                        className={"p_" + v2.week + "_" + v2.year + " squares"}
                                        title={getFirstRowTxt(v2.week, v2.year, v.detail, v2.interval, "title")}>
                                        {getFirstRowTxt(v2.week, v2.year, v.detail, v2.interval, "txt")}
                                    </td>
                                })}
                            </tr>

                            <tr className="machines">
                                {plan.plan.weeks.map((v2, k2) => {
                                    return <td key={"mach_" + k2} data-interval={v2.interval}
                                        className={"p_" + v2.week + "_" + v2.year + " squares " +
                                            getDpt(v2.week, v2.year, v.detail)}
                                        title={getTitle(v2.week, v2.year, v.detail, v2.interval)}></td>
                                })}
                            </tr>
                        </Fragment>
                    })}

                </table>


            </div>
            }

            <div className={loading ? "d-none" : ""}>
                <ReactPaginate breakLabel="..." nextLabel="next >"
                    onPageChange={(e) => changePage(e.selected)}
                    pageRangeDisplayed={5}
                    pageCount={plan.pages && parseInt(plan.pages)}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null} activeClassName={'active'}
                    breakClassName={'page-item'} breakLinkClassName={'page-link'} containerClassName={'pagination'}
                    pageClassName={'page-item'} pageLinkClassName={'page-link'} previousClassName={'page-item'}
                    previousLinkClassName={'page-link'} nextClassName={'page-item'} nextLinkClassName={'page-link'} />
            </div>

        </div>

    </div>

}

export default GeneralPlanning;