import { useTranslation } from "react-i18next";

const Subcontracting = (props) => {
    const { t } = useTranslation();

    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {t('subcontracting')}
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">


                <li>
                    <a href={"/subcontracting/list"} className="dropdown-item">
                        {t('subcontracting list')}
                    </a>
                </li>

                <li>
                    <a href={"/subcontracting/packing-list"} className="dropdown-item">
                        {t('packing table')}
                    </a>
                </li>

            </ul>
        </li>
    )

}

export default Subcontracting;