import { useEffect } from "react";
import { useState } from "react";
import ticketsDao from "../../services/qhse/ticketsDao";
import Moment from "moment";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import errorManagement from "../../services/errorManagement";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { Fragment } from "react";
import QualityFbTable from "./smallComponent/QualityFbTable";
import { downloadSingleFile } from "../../services/functions/FilesProcess";

const QualityFeedbackList = (props) => {
    const [list, setList] = useState();
    const [closed, setClosed] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [tab, setTab] = useState(0);
    const [searchRst, setSearchRst] = useState([]);
    const [exporting, setExporting] = useState(false);
    Moment.locale("fr");

    const user = JSON.parse(localStorage.getItem("user"));
    const [isQhse, setIsQhse] = useState(false);

    useEffect(() => {
        ticketsDao.getQualityFeedBackList().then(
            (response) => {
                var opened = new Array();
                var closed = new Array();

                response.map(v => {
                    if (v.statut === "Clôturé") {
                        closed.push(v);
                    } else {
                        opened.push(v);
                    }
                })

                setList(opened);
                setClosed(closed);

                if (user.roles.includes("ROLE_QHSE")) setIsQhse(true);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );
    }, []);

    const changeTab = (e) => {
        setTab(parseInt(e.target.getAttribute("data-tab")));
    }

    const onFieldChange = (e) => {
        var field = e.target.getAttribute("data-field");
        var id = e.target.getAttribute("data-id");
        var value = e.target.value;

        ticketsDao.updateQualityFeedBackField(id, field, value).then(
            (response) => { },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }

    const search = (e) => {
        e.preventDefault();
        var term = e.target.term.value;
        var start = e.target.start.value;
        var end = e.target.end.value;

        if( term.trim() === "" && start.trim() === "" && end.trim() === "" ){
            setError("Saissez un valeur à rechercher");
            return;
        }else if( (start.trim() !== "" && end.trim() === "") || ( start.trim() === "" && end.trim() !== "" ) ){
            setError("Si vous mettez une date de début, mettez la date de fin. Et inversement.");
            return;
        }

        setLoading(true);
        setSearchRst();
        ticketsDao.searchQualityFb(term, start, end).then(
            (response) => {
                setSearchRst(response)
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );
    }

    const exportToXls = () => {
        var arr = new Array();
        
        searchRst.map(v=>{
            delete v.img;
            arr.push(v);
        });

        setExporting(true);
        ticketsDao.arrToXls(arr).then(
            (response) => {
                var fileName = response.substring(response.lastIndexOf("\\") + 1, response.length);
                downloadSingleFile("temp", fileName);
                setExporting(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setExporting(false);
            }
        );
    }

    return (
        <div>
            <h2 className="text-center">Liste des remontées qualité</h2>

            {
                loading && <WaitingRoundSnippers />
            }

            {error && <ErrorMessage error={error} errorState={setError}/>}

            {
                list &&
                <Fragment>
                    <ul className="nav nav-tabs">
                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 0 ? "active" : "")}
                                href="#" onClick={changeTab} data-tab="0">En cours</a>
                        </li>
                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 1 ? "active" : "")}
                                href="#" onClick={changeTab} data-tab="1">Clôturé</a>
                        </li>

                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 3 ? "active" : "")}
                                href="#" onClick={changeTab} data-tab="3">
                                Rechercher
                            </a>
                        </li>
                    </ul>

                    {
                        tab === 0 && <QualityFbTable isQhse={isQhse} list={list} onFieldChange={onFieldChange} />
                    }

                    {
                        tab === 1 && <QualityFbTable isQhse={isQhse} list={closed} onFieldChange={onFieldChange} />
                    }

                    {
                        tab === 3 &&
                        <Fragment>
                            <br></br>
                            <form onSubmit={search}>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">Recherche par mot clé</span>
                                    <input type="text" className="form-control" name="term"
                                        placeholder="Description, type d'observation, risque, utilisateur" />
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">Recherche par dates</span>
                                    <span className="input-group-text" id="basic-addon1">Début</span>
                                    <input type="date" className="form-control" name="start" />
                                    <span className="input-group-text" id="basic-addon1">Fin</span>
                                    <input type="date" className="form-control" name="end" />
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-secondary" disabled={loading}>
                                        {loading && <ButtonWaiting />}
                                        Rechercher
                                    </button>
                                </div>
                            </form>
                            <br></br>
                            {
                                ( searchRst && searchRst.length > 0 )  && 
                                <Fragment>
                                    <div className="text-center mb-3">
                                        <button className="btn btn-outline-secondary" disabled={exporting} onClick={exportToXls}>
                                            { exporting && <ButtonWaiting /> }
                                            Exporter sous Excel
                                        </button>
                                    </div>
                                    <QualityFbTable list={searchRst} isQhse={isQhse} onFieldChange={onFieldChange} />
                                </Fragment>
                            }
                        </Fragment>
                    }
                </Fragment>
            }

        </div>)
}

export default QualityFeedbackList;