import { Fragment, useEffect, useState } from "react";
import NeedsMngt from "../../services/articles/NeedsMngt";
import errorManagement from "../../services/errorManagement";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import { confirmAlert } from "react-confirm-alert";
import { ButtonWaiting } from "../../functions/PageAnimation";

const ListNeeds = (props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [list, setList] = useState();
    const [deleting, setDeleting] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        NeedsMngt.listFreeNeed().then(
            (response) => {
                setList(response);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        )
    }, []);

    const closeNeeds = (e) => {
        var id = e.target.getAttribute("data-id");
        confirmAlert({
            title: "Confirmation",
            message: "Cette action mettra cette ligne en statut clôturé, confirmez ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => { 
                        setDeleting(true);
                        NeedsMngt.closeFreeNeed(id).then(
                            (response) => {
                                window.location.reload();
                            },
                            (error) => {
                                setError(errorManagement.handleError(props, error));
                                setDeleting(false);
                            }
                        )
                    }
                },
                {
                    label: "Non"
                }
        ]});
    }

    return (
        <Fragment>
            <h4 className="text-center">Mes demandes d'articles</h4>
            <div className="text-center">
                <a href={"/article/needs/create"}>Nouvelle demande</a>
                <br></br>
            </div>
            <br></br>
            {loading && <WaitingRoundSnippers />}
            {error && <ErrorMessage error={error} errorState={setError} />}
            {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

            {
                list &&
                <table className="table text-center">
                    <thead>
                        <tr className="table-info">
                            <th scope="col">#</th>
                            <th scope="col">Demandeur</th>
                            <th scope="col">Date</th>
                            <th scope="col">Statut</th>
                            <th scope="col">Progression</th>
                            <th scope="col">Dispo à sortir</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            list.map((v, k) =>
                                <tr key={k}>
                                    <th scope="row">{v.id}</th>
                                    <td>{v.user_name}</td>
                                    <td>{v.date_request}</td>
                                    <td>{v.closed === 0 ? "En cours" : "Clôturé"}</td>
                                    <td>{((v.total_out / v.total_qty) * 100).toFixed(0)}%</td>
                                    <td>{v.affected}</td>
                                    <td>

                                        <a href={"/article/needs/display?id=" + v.id + "&type=" + v.type_request}
                                            style={{ marginLeft: "10px" }} target="_blank"
                                            className="btn btn-outline-secondary" disabled={deleting}>
                                                {deleting && <ButtonWaiting />}
                                                Entrer
                                            </a>


                                        {
                                            (user.roles.includes('ROLE_ADMIN') || user.id === v.user_id) &&
                                            <a href="#" onClick={closeNeeds} style={{ marginLeft: "10px" }} 
                                                className="btn btn-outline-danger" data-id={v.id} disabled={deleting}>
                                                {deleting && <ButtonWaiting />}
                                                Clôturer
                                            </a>
                                        }
                                    </td>
                                </tr>
                            )
                        }

                    </tbody>
                </table>
            }

        </Fragment>
    )

}

export default ListNeeds;