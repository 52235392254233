import { useRef, useState } from "react";
import { Fragment } from "react";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import { useEffect } from "react";
import ticketsDao from "../../services/qhse/ticketsDao";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import CreateSignature from "../../common/smallComponents.js/CreateSignature";
import SignaturePad from "react-signature-canvas";
import { getKey } from "../../functions/ArrayFunctions";

const EquipmentRequestList = (props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [data, setData] = useState([]);
    const [reqList, setReqList] = useState();
    const [row, setRow] = useState(-1);
    const [toSend, setToSend] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const [canSign, setCanSign] = useState(false);
    const signature = useRef({});

    useEffect(() => {

        ticketsDao.equipmentRequestList(true).then(
            (response) => {
                setData(response);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );
    }, []);

    /**
     * 
     * @param {*} e 
     */
    const openRequest = (e) => {
        var id = e.target.getAttribute("data-id");
        var arr = new Array();
        var cs = false;

        data.map(v => {
            if (v.id_request === id) {
                arr.push(v);
                //If has been notified but not signed can sign
                if (v.closure_date !== null && v.req_signature === null) cs = true;
            }
        });

        setReqList(arr);
        setRow(parseInt(e.target.getAttribute("data-key")));
        setCanSign(cs);
    }

    /**
     * 
     * @param {*} e 
     */
    const onCheckReqList = (e) => {
        var key = parseInt(e.target.getAttribute("data-key"));
        var arr = [...reqList];
        var checked = e.target.checked;
        var toS = false;

        arr.map((v, k) => {
            if (k === key) {
                if (checked) {
                    v.toSend = checked;
                } else {
                    delete v.toSend;
                }
            }

            if (v.toSend) toS = true;
        })

        setToSend(toS);
        setReqList(arr);
    }

    /**
     * 
     */
    const notify = () => {
        var arr = new Array();

        reqList.map(v => { v.toSend && arr.push(v); });

        if (arr.length === 0) {
            setError("Veuillez cocher les équipements que vous fournissez avant de notifier.");
            scrollToTop();
        } else {
            setLoading(true);
            ticketsDao.equipmentRequestNotify(arr).then(
                (response) => {
                    setData(response);
                    setLoading(false);
                    setReqList();
                    setRow(-1);
                    setToSend(false);
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                    setLoading(false);
                }
            );
        }
    }

    const clear = () => {
        signature.current.clear();
    }

    const save = () => {
        var arr = [...reqList];
        var tempData = [...data];
        var toSign = new Array();

        if (signature.current.isEmpty()) {
            alert("Merci de signer avant de valider !")
            return;
        }

        var signatureImg = signature.current.getTrimmedCanvas().toDataURL("image/png");

        arr.map(v => {
            if (v.closure_date !== null && v.req_signature === null) {
                v.req_signature = signatureImg;
                toSign.push(v);
                tempData[getKey(tempData, "id_request", v.id_request)].req_signature = signatureImg;
            }
        });

        ticketsDao.equipmentRequestSignature(toSign, signatureImg, user.signature).then(
            () => { },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );

        setReqList(arr);
        setCanSign(false);
        setData(tempData);
    }

    return <Fragment>
        <h4 className="text-center">Liste des demandes d'équipement</h4>
        {error && <ErrorMessage error={error} errorState={setError} />}
        {loading && <WaitingRoundSnippers />}

        {
            data &&
            <div className="col-10 offset-1">
                <table className="table">
                    <thead>
                        <tr className="table-info">
                            <th scope="col">Date de demande</th>
                            <th scope="col">Demandeur</th>
                            <th scope="col">Utilisateur final</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((v, k) => {
                                return (k === 0 || v.id_request !== data[k - 1].id_request) &&
                                    <Fragment key={"rr" + k}>
                                        <tr>
                                            <th scope="row">{v.date_req}</th>
                                            <td>{v.author_name}</td>
                                            <td>{v.final_user}</td>
                                            <td>
                                                {
                                                    row !== k &&
                                                    < a className="btn btn-outline-secondary" data-key={k}
                                                        data-id={v.id_request} onClick={openRequest}>Ouvrir</a>
                                                }

                                                {
                                                    row === k &&
                                                    < a className="btn btn-outline-secondary" data-key={k}
                                                        data-id={v.id_request}
                                                        onClick={() => { setRow(-1); setReqList() }}>Fermer</a>
                                                }
                                            </td>
                                        </tr>

                                        {
                                            (row === k && reqList) && <tr>
                                                <td colSpan={4}>
                                                    <div className="table-responsive col-10 offset-1" style={{ backgroundColor: "rgb(230, 230, 230)", borderRadius: "0.5em" }}>
                                                        <table className="table fw-bold"><tbody>
                                                            {
                                                                reqList.map((v2, k2) => {
                                                                    return v2.req_signature === null &&
                                                                        <tr key={"det" + k2}>
                                                                            <td className="align-middle">
                                                                                <img src={v2.photo} style={{ maxWidth: "150px" }}></img>
                                                                            </td>
                                                                            <td className="align-middle">
                                                                                {v2.designation}
                                                                            </td>
                                                                            <td className="align-middle">
                                                                                {(v2.size !== null && v2.size !== "") && "Taille: " + v2.size}
                                                                            </td>
                                                                            {
                                                                                (user && user.roles.includes("ROLE_QHSE") && v2.closure_date === null) &&
                                                                                <td className="align-middle">
                                                                                    <input type="checkbox" onChange={onCheckReqList}
                                                                                        data-key={k2} checked={v2.toSend}></input>
                                                                                </td>
                                                                            }
                                                                        </tr>

                                                                })
                                                            }
                                                        </tbody>
                                                        </table>

                                                        {

                                                            (toSend && k === row) &&
                                                            <div className="text-center mb-3">
                                                                <button className="btn btn-success" onClick={notify} disabled={loading}>
                                                                    {loading && <ButtonWaiting />}
                                                                    Notifier
                                                                </button>
                                                            </div>
                                                        }

                                                        {
                                                            ( user && user.roles.includes("ROLE_QHSE") && canSign ) && <div className="text-center mb-3">
                                                                <SignaturePad ref={signature} canvasProps={{ className: "signatureCanvas" }} />
                                                                <br></br>

                                                                <div className="display-flex">
                                                                    <button className="btn btn-warning" onClick={clear} style={{ margin: "auto" }}
                                                                        disabled={loading}>
                                                                        {loading && <ButtonWaiting />}
                                                                        Effacer
                                                                    </button>

                                                                    <button className="btn btn-success" onClick={save} style={{ margin: "auto" }}
                                                                        disabled={loading}>
                                                                        {loading && <ButtonWaiting />}
                                                                        Valider
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </Fragment>
                            })
                        }

                    </tbody>
                </table>
            </div>
        }

        {(user && user.roles.includes("ROLE_QHSE") && user.signature === null) && <CreateSignature />}



    </Fragment >
}

export default EquipmentRequestList;