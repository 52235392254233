import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { resetPassord } from "../../actions/auth.js";

import { required, vpassword } from "../../functions/FormCtrl.js";
import getUrlParam from "../../functions/StrFunctions.js";


const ResetPassword = (props) => {
    const form = useRef();
    const checkBtn = useRef();

    const [password, setPassword] = useState("");
    const [id, setId] = useState("");
    const [successful, setSuccessful] = useState(false);
    const [loading, setLoading] = useState(false);

    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();

    //Write id in hidden field
    useEffect( () => {
        setId(getUrlParam( props, "param" ));
    }, [] );

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleRegister = (e) => {
        e.preventDefault();
    
        setSuccessful(false);
        setLoading(true);
    
        form.current.validateAll();
    
        if (checkBtn.current.context._errors.length === 0) {
          dispatch(resetPassord(id, password))
            .then(() => {
              setSuccessful(true);
              setLoading(false);
            })
            .catch(() => {
              setSuccessful(false);
              setLoading(false);
            });
        }else{
            setLoading(false);
        }
      };

    return(
        <div>
            <Form onSubmit={handleRegister} ref={form}>
                {!successful && (
                    <div>
                        <Input 
                            type="hidden"
                            name="id"
                            value={id}/>

                        <div className="form-group">
                            <label htmlFor="password">Write the new password and reset</label>
                            <Input
                                type="password"
                                className="form-control"
                                name="password"
                                value={password}
                                onChange={onChangePassword}
                                validations={[required, vpassword]}
                            />
                        </div>

                        <div className="form-group">
                            <button className="btn btn-primary btn-block" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>Reset password</span>
                            </button>
                        </div>
                    </div>
                )}

                {message && (
                    <div className="form-group">
                        <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                            {message}
                        </div>
                    </div>
                )}
                <CheckButton style={{ display: "none" }} ref={checkBtn} />

            </Form>
        </div>
    );
    
};

export default ResetPassword;