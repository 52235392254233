import { Fragment } from "react";
import { currentEnDate, currentFrDate, nbFormat, numWords } from "../../../../functions/StrFunctions";
import { useState } from "react";
import { arrayRemoveElt, getKey, removeEltArray } from "../../../../functions/ArrayFunctions";
import CreateSignature from "../../../../common/smallComponents.js/CreateSignature";
import BusinessDao from "../../../../services/machines/BusinessDao";
import errorManagement from "../../../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../../../functions/PageAnimation";
import WaitingRoundSnippers from "../../../../common/smallComponents.js/WaitingRoundSnippers";

const CustomerInvoice = (p) => {
    const [loading, setLoading] = useState(false);
    const [blRef, setBlRef] = useState("BL-" + p.businessInfo.id_aff + "-CLIE-002-");
    const [amount, setAmount] = useState(0);
    const [detail, setDetail] = useState([]);

    const user = JSON.parse(localStorage.getItem("user"));

    /**
     * 
     */
    const addRow = () => {
        var arr = [...detail];
        arr.push({ po: "", description: "", price: "" });
        setDetail(arr);
    }

    /**
     * 
     */
    const removeRow = (e) => {
        var k = e.target.getAttribute("data-row");
        var arr = [...detail];
        arr = arrayRemoveElt(arr, k);
        setDetail(arr);

        setAmount(getAmount(arr));
    }

    /**
     * 
     */
    const updateField = (e) => {
        var k = e.target.getAttribute("data-key");
        var field = e.target.getAttribute("data-field");

        var arr = [...detail];
        arr[k][field] = e.target.value;
        setDetail(arr);

        if( field === "price" ) setAmount(getAmount(arr));
    }

 
    /**
     * 
     */
    const submit = () => {
        setLoading(true);
        var div ={
            amount: amount,
            amountInWords: numWords(amount).toUpperCase(),
            blRef: blRef
        };
        
        setLoading(true);
        BusinessDao.exportCustomerInvoice(p.businessInfo, p.businessInfoFocus, detail, 
                p.customerInvoice, div, false).then(
            (response) => {
                p.setMsg("Fichier sauvegardé dans le répertoire: " + response);
                scrollToTop();
                setLoading(false);
            }, (error) => {
                setLoading(false);
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        );
    }

    /**
     * 
     * @param {*} e 
     */
    const generateDuplicata = (e) => {
        var k = parseInt(e.target.getAttribute("data-key"));
        var o = { ...p.customerInvoice[k] };

        var div ={
            amount: o.amount,
            amountInWords: o.amountInWords,
            blRef: o.blRef
        };
        
        setLoading(true);
        BusinessDao.exportCustomerInvoice(p.businessInfo, p.businessInfoFocus, o.detailList, 
                p.customerInvoice.slice(0, k), div, true).then(
            (response) => {
                p.setMsg("Fichier sauvegardé dans le répertoire: " + response);
                scrollToTop();
                setLoading(false);
            }, (error) => {
                setLoading(false);
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        );
    }

    function getAmount(detail){
        var tot = 0;
        detail.map(v => {if( !isNaN( v.price ) ) tot+= parseFloat( v.price )});
        return tot;
    }

    return <Fragment>
        <br></br>
        {
            p.customerInvoice.length > 0 && <div className="alert alert-info">
                <h5>Factures déjà effectué(s)</h5>
                <ul>
                    {p.customerInvoice.map((v, k) => {
                        return <li key={"blfze" + k}>
                            <a href="#" data-key={k} onClick={generateDuplicata}>{v.auteur_suivi}: {v.ci_ref}</a>
                        </li>
                    })}
                </ul>
                {loading && <WaitingRoundSnippers />}
            </div>
        }


        <div className="table-responsive" style={{ maxWidth: "900px", marginLeft: "auto", marginRight: "auto" }}>
            <div>
                <img src="/Logo B+ Eq SealedAir.png" style={{ maxWidth: "200px" }}></img>
                <br></br>
                B+ Equipment
                465, avenue du Col de l’Ange<br></br>
                F – 13420 Gémenos<br></br>
                RCS MARSEILLE N° 429.253.438<br></br>
                Ph. : +33 (0)4 42 36 15 00<br></br>
                E.mail : bplus-contact@sealedair.com<br></br><br></br>
                S.A.S au capital de 154.240 Euros<br></br>
                Siren 429 253 438<br></br><br></br>
                htpp://www.bplus-equip.com<br></br>
                N° Intra Communautaire : FR 63 42 9253 438<br></br>
            </div>

            <div className="fw-bold text-end mb-3">
                {p.businessInfoFocus.RSDFE} {p.businessInfoFocus.RSCFE !== null ? p.businessInfoFocus.RSCFE : ""}<br></br>
                {p.businessInfoFocus.ARDFE} {p.businessInfoFocus.ARCFE !== null ? p.businessInfoFocus.ARCFE : ""}<br></br>
                {p.businessInfoFocus.AVIFE}, {p.businessInfoFocus.CPOFE}<br></br>
                {p.businessInfoFocus.CPAFE}
            </div>

            <p>O/Réf: CI-{p.businessInfo.id_aff}-CLI-002-{String.fromCharCode(64 + p.customerInvoice.length + 1)}</p>

            <p className="text-end">Gémenos, {currentEnDate()}</p>

            <p className="fw-bold">Attention to {p.businessInfo.nom_chef_proj}</p>

            <table className="table table-bordered">
                <thead>
                    <tr>
                        <td className="align-middle"><span className="fw-bold">CUSTOMS INVOICE</span> (ORIGINAL)</td>
                        <td className="fw-bold text-center align-middle">Number of copies: 2</td>
                        <td className="text-center align-middle"><span className="fw-bold">TOTAL AMOUNT</span> (EURO)<br></br>(FOR CUSTOMS ONLY)</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={2}>
                            <p><b>B+ reference (Seller):</b> {p.businessInfo.id_aff}</p>
                            <p><b>Sealed Air USA purchase order (Importer of Record):</b> {p.businessInfoFocus.RFCDE}</p>
                            <p className="fw-bold">Shipped to (consignee):</p>
                            <div className="text-center fw-bold mb-3">
                                {p.businessInfo.societe_livrais}<br></br>
                                {p.businessInfo.adress_livrais}<br></br>
                                {p.businessInfo.adress_comp_livrais !== null && <Fragment>
                                    {p.businessInfo.adress_comp_livrais}<br></br>
                                </Fragment>}
                                {p.businessInfo.pays_livrais}<br></br>
                            </div>
                            <p className="fw-bold">Subject: </p>
                            <p style={{ marginLeft: "30px" }}>{p.businessInfoFocus.RFCDE}</p>
                        </td>
                        <td></td>
                    </tr>

                    {
                        detail.map((v, k) => {
                            return <tr key={"vngeuiugj" + k}>
                                <td colSpan={2}>
                                    <div className="col-8 offset-2">
                                        <input className="form-control" placeholder="PO du PDF" onChange={updateField}
                                            value={v.po} data-field="po" data-key={k}></input><br></br>

                                        <select className="form-select" value={v.description} onChange={updateField}
                                            data-field="description" data-key={k}>
                                            <option value="">Choisissez...</option>
                                            <option value="Description: automated machine that processes corrugated boxes">Description: automated machine that processes corrugated boxes</option>
                                            <option value="Description: sub-assemblies for automated machine that processes corrugated boxes">Description: sub-assemblies for automated machine that processes corrugated boxes</option>
                                        </select>
                                    </div>
                                </td>
                                <td className="align-middle text-center">
                                    <input type="number" className="form-control" data-field="price" data-key={k}
                                        onChange={updateField} placeholder="Montant en €" value={k.price}></input><br></br>
                                    <button className="btn btn-outline-warning" data-row={k} 
                                        onClick={removeRow}>Supprimer la ligne</button>
                                </td>
                            </tr>
                        })
                    }

                    <tr>
                        <td colSpan={2}><button className="btn btn-secondary" onClick={addRow}>Ajouter une ligne</button></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td colSpan={2}>
                            <p><b>Incoterm:</b> EXW France</p>
                            <p><b>EORI:</b> FR 429 253 438 00056</p>
                            <div className="display-flex">
                                <div><b>Packing List:</b> see delivery note reference </div>
                                <div style={{ marginLeft: "5px" }}>
                                    <input className="form-control" style={{ width: "220px" }} onChange={(e) => setBlRef(e.target.value)}
                                        value={blRef}></input>
                                </div>
                            </div>
                        </td>
                        <td>

                        </td>
                    </tr>

                    <tr>
                        <td colSpan={2} className="fw-bold text-end">Total amount (for customs only)</td>
                        <td className="fw-bold">€ { nbFormat( amount, "en-US", 2 )}</td>
                    </tr>

                    <tr>
                        <td colSpan={2} className="fw-bold align-middle">
                            <div className="text-decoration-underline">TOTAL AMOUNT OF PRESENT INVOICE (FOR CUSTOMS ONLY):</div>
                            { numWords(amount).toUpperCase() } EUROS
                        </td>
                        <td>
                            {user.signature !== null && <img src={user.signature}></img>}
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>

        <div className="text-center mb-3">
            <button className="btn btn-success" onClick={submit} disabled={loading}>
                {loading && <ButtonWaiting />}
                Soumettre
            </button>
        </div>

        {user.signature === null && <CreateSignature />}
    </Fragment>
}

export default CustomerInvoice;