import { Fragment, useEffect } from "react";
import { useState } from "react";
import ticketsDao from "../../services/qhse/ticketsDao";
import Moment from "moment";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import errorManagement from "../../services/errorManagement";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import BbsoTable from "./smallComponent/BbsoTable";
import { downloadSingleFile } from "../../services/functions/FilesProcess";

const BbsoList = (props) => {
    const [list, setList] = useState([]);
    const [listClosed, setListClosed] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [tab, setTab] = useState(0);
    const user = JSON.parse(localStorage.getItem("user"));
    const [isQhse, setIsQhse] = useState(false);
    const [searchRst, setSearchRst] = useState([]);
    const [exporting, setExporting] = useState(false);

    Moment.locale("fr");

    useEffect(() => {
        //Non closed BBSO
        getCurrentBbsoList();

        //Closed BBSO
        getClosedBbsoList();
    }, []);

    const changeTab = (e) => {
        setTab(parseInt(e.target.getAttribute("data-tab")));
    }

    const onFieldChange = (e) => {
        var field = e.target.getAttribute("data-field");
        var id = e.target.getAttribute("data-id");
        var value = e.target.value;

        ticketsDao.updateBbsoField(id, field, value).then(
            (response) => { },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        )
    }

    const getCurrentBbsoList = () => {
        var offset = list.length === 0 ? 0 : list.length;

        ticketsDao.getBbsoList(false, offset).then(
            (response) => {
                if (response.length === 0) {
                    return;
                }

                var arr = [...list];
                var concat = arr.concat(response);
                setList(concat);
                setLoading(false);
                if (user.roles.includes("ROLE_QHSE")) setIsQhse(true);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );
    }

    const getClosedBbsoList = () => {
        var offset = listClosed.length === 0 ? 0 : listClosed.length;

        ticketsDao.getBbsoList(true, offset).then(
            (response) => {
                if (response.length === 0)
                    return;

                var arr = [...listClosed];
                var concat = arr.concat(response);
                setListClosed(concat);
                setLoading(false);
                if (user.roles.includes("ROLE_QHSE")) setIsQhse(true);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );
    }

    const search = (e) => {
        e.preventDefault();
        var term = e.target.term.value;
        var start = e.target.start.value;
        var end = e.target.end.value;

        if (term.trim() === "" && start.trim() === "" && end.trim() === "") {
            setError("Saissez un valeur à rechercher");
            return;
        } else if ((start.trim() !== "" && end.trim() === "") || (start.trim() === "" && end.trim() !== "")) {
            setError("Si vous mettez une date de début, mettez la date de fin. Et inversement.");
            return;
        }

        setLoading(true);
        setSearchRst();
        ticketsDao.searchBbso(term, start, end).then(
            (response) => {
                setSearchRst(response)
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );
    }

    const exportToXls = () => {
        var arr = new Array();

        searchRst.map(v => {
            delete v.image;
            arr.push(v);
        });

        setExporting(true);
        ticketsDao.arrToXls(arr).then(
            (response) => {
                var fileName = response.substring(response.lastIndexOf("\\") + 1, response.length);
                downloadSingleFile("temp", fileName);
                setExporting(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setExporting(false);
            }
        );
    }

    return (

        <div>
            <h2 className="text-center">Liste des BBSO</h2>

            {error && <ErrorMessage error={error} errorState={setError} />}


            <Fragment>
                <ul className="nav nav-tabs">
                    <li className="nav-item fw-bold">
                        <a className={"nav-link " + (tab === 0 ? "active" : "")}
                            href="#" onClick={changeTab} data-tab="0">
                            {list.length === 0 && <ButtonWaiting />}
                            En cours
                        </a>
                    </li>

                    <li className="nav-item fw-bold">
                        <a className={"nav-link " + (tab === 1 ? "active" : "")}
                            href="#" onClick={changeTab} data-tab="1">
                            {listClosed.length === 0 && <ButtonWaiting />}
                            Clôturé
                        </a>
                    </li>

                    <li className="nav-item fw-bold">
                        <a className={"nav-link " + (tab === 3 ? "active" : "")}
                            href="#" onClick={changeTab} data-tab="3">
                            Rechercher
                        </a>
                    </li>
                </ul>

                {tab === 0 && <BbsoTable list={list} isQhse={isQhse} onFieldChange={onFieldChange} />}

                {tab === 1 && <BbsoTable list={listClosed} isQhse={isQhse} onFieldChange={onFieldChange} />}

                {
                    tab === 3 &&
                    <Fragment>
                        <br></br>
                        <form onSubmit={search}>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">Recherche par mot clé</span>
                                <input type="text" className="form-control" name="term"
                                    placeholder="Description, type d'observation, risque, utilisateur" />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">Recherche par dates</span>
                                <span className="input-group-text" id="basic-addon1">Début</span>
                                <input type="date" className="form-control" name="start" />
                                <span className="input-group-text" id="basic-addon1">Fin</span>
                                <input type="date" className="form-control" name="end" />
                            </div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-secondary" disabled={loading}>
                                    {loading && <ButtonWaiting />}
                                    Rechercher
                                </button>
                            </div>
                        </form>
                        <br></br>
                        {
                            (searchRst && searchRst.length > 0) &&
                            <Fragment>
                                <div className="text-center mb-3">
                                    <button className="btn btn-outline-secondary" disabled={exporting} onClick={exportToXls}>
                                        {exporting && <ButtonWaiting />}
                                        Exporter sous Excel
                                    </button>
                                </div>
                                <BbsoTable list={searchRst} isQhse={isQhse} onFieldChange={onFieldChange} />
                            </Fragment>
                        }
                    </Fragment>
                }


            </Fragment>


        </div>)
}

export default BbsoList;