import axios from "axios";
import textPlainHeader from "../fake-header";
import authHeader from "../auth-header";

const API_URL = process.env.REACT_APP_API_URL + "odm";
const user = JSON.parse(localStorage.getItem("user"));

const getNewOdm = () => {
    return axios
        .post(API_URL + "/new", {
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const saveOdm = (odm, send, type) => {
    return axios
        .post(API_URL + "/"+type+"/save", {
            fake_header: authHeader(),
            odm: odm,
            send: send
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const downloadOdm = (odm) => {
    return axios
        .post(API_URL + "/download", {
            fake_header: authHeader(),
            odm: odm
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const getOdmList = (term) => {
    return axios
        .post(API_URL + "/list", {
            fake_header: authHeader(),
            term: term
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const getOdmById = (id) => {
    return axios
        .post(API_URL + "/get", {
            fake_header: authHeader(),
            id: id
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}


export default {
    getNewOdm,
    saveOdm,
    getOdmList,
    getOdmById,
    downloadOdm
}