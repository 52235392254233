import { Fragment } from "react";
import { currentFrDate } from "../../../../functions/StrFunctions";
import { useState } from "react";
import { arrayRemoveElt, getKey, removeEltArray } from "../../../../functions/ArrayFunctions";
import CreateSignature from "../../../../common/smallComponents.js/CreateSignature";
import BusinessDao from "../../../../services/machines/BusinessDao";
import errorManagement from "../../../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../../../functions/PageAnimation";
import WaitingRoundSnippers from "../../../../common/smallComponents.js/WaitingRoundSnippers";

const DeliveryNote = (p) => {
    const [mc, setMC] = useState([]);
    const [crate, setCrate] = useState([]);
    const [loading, setLoading] = useState(false);
    var o = { crate: "", machine: "", l: "", w: "", h: "", weight: "", crateDesc: "" };

    const user = JSON.parse(localStorage.getItem("user"));

    /**
     * 
     * @param {*} e 
     */
    const onCheckMachine = (e) => {
        var val = parseInt(e.target.value);
        var arr = [...mc];

        if (e.target.checked) { arr.push(val) } else { arr = removeEltArray(arr, val) };

        setMC(arr);
    }

    /**
     * 
     */
    const addRow = () => {
        var arr = [...crate];
        arr.push({ ...o });
        setCrate(arr);
    }

    /**
     * 
     */
    const removeRow = (e) => {
        var k = e.target.getAttribute("data-row");
        var arr = [...crate];
        arr = arrayRemoveElt(arr, k);
        setCrate(arr);
    }

    /**
     * 
     */
    const updateCrate = (e) => {
        var k = e.target.getAttribute("data-row");
        var field = e.target.getAttribute("data-field");

        var arr = [...crate];
        arr[k][field] = e.target.value;
        setCrate(arr);
    }

    /**
     * 
     */
    const submit = () => {
        setLoading(true);
        var choosenMachines = new Array();
        mc.map(v => choosenMachines.push(p.machines[v]));
        BusinessDao.exportDeliveryNote(choosenMachines, crate, p.businessInfo, p.deliveryNote, false).then(
            (response) => {
                p.setMsg("Fichier sauvegardé dans le répertoire: " + response);
                scrollToTop();
                setLoading(false);
            }, (error) => {
                setLoading(false);
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        );
    }

    const generateDuplicata = (e) => {
        var k = parseInt(e.target.getAttribute("data-key"));
        var o = { ...p.deliveryNote[k] };

        var choosenMachines = new Array();
        o.machines.split(";").map(v => choosenMachines.push({ no_machine: v }));

        setLoading(true);
        BusinessDao.exportDeliveryNote(choosenMachines, o.crate, p.businessInfo, p.deliveryNote.slice(0, k), true).then(
            (response) => {
                p.setMsg("Fichier sauvegardé dans le répertoire: " + response);
                scrollToTop();
                setLoading(false);
            }, (error) => {
                setLoading(false);
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        );
    }

    return <Fragment>
        <br></br>
        {
            p.deliveryNote.length > 0 && <div className="alert alert-info">
                <h5>Bordereau(x) d'expédition déjà effectué(s)</h5>
                <ul>
                    {p.deliveryNote.map((v, k) => {
                        return <li key={"blfze" + k}>
                            <a href="#" data-key={k} onClick={generateDuplicata}>{v.auteur_suivi}: {v.bl_ref}</a>
                        </li>
                    })}
                </ul>
                {loading && <WaitingRoundSnippers />}
            </div>
        }

        <div className="mb-3">
            <div className="form-check form-check-inline">
                <label className="form-check-label">Machines concernées par le BL:</label>
            </div>

            {
                p.machines.map((v, k) => {
                    return <div key={"machBl" + k} className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value={k} onClick={onCheckMachine} />
                        <label className="form-check-label">{v.no_machine + " (" + v.typ_config + ")"}</label>
                    </div>
                })
            }
        </div>

        <div className="table-responsive" style={{ maxWidth: "800px", marginLeft: "auto", marginRight: "auto" }}>
            <table className="table table-bordered">
                <thead>
                    <tr className="fw-bold">
                        <td className="text-center align-middle">
                            <img src="/Logo B+ Eq SealedAir.png" style={{ maxWidth: "200px" }}></img>
                        </td>
                        <td style={{ width: "70%" }} className="text-center">
                            <p>BORDEREAU D'EXPEDITION (Delivery Note)</p>
                            <p>N°: BL-{p.businessInfo.id_aff}-CLIE-{ (p.deliveryNote.length + 1).toString().padStart(3, "0") }-A.docx</p>
                        </td>
                        <td className="text-center align-middle">
                            Date: {currentFrDate()}
                        </td>
                    </tr>

                    <tr><td colSpan={3}></td></tr>

                    <tr>
                        <td colSpan={3}>
                            Référence (reference) : {p.businessInfo.id_aff}
                            {mc.map((v, k) => <span key={"chijzf" + k}> - {p.machines[v].no_machine}</span>)}
                        </td>
                    </tr>
                </thead>

                <tbody></tbody>
            </table>

            <table className="table table-bordered">
                <thead>
                    <tr>
                        <td className="align-top">
                            <p><span className="fw-bold">Expéditeur</span> (from) :</p>

                            <div className="mb-3" style={{ marginLeft: "5%" }}>
                                <span className="fw-bold">Sealed Air – B+Equipments</span>
                                <br></br>
                                465 Avenue du Col de l’Ange<br></br>
                                13420 Gémenos<br></br>
                                FRANCE
                            </div>

                            <p>Tel: {user.phone_nb}</p>

                            <div className="display-flex">
                                <div style={{ width: "20%" }}>{user.first_name + " " + user.last_name}</div>
                                <div className="text-center">
                                    {user.signature !== null && <img src={user.signature}></img>}
                                </div>
                            </div>
                        </td>

                        <td style={{ width: "50%" }} className="align-top">
                            <p><span className="fw-bold">Destinataire</span> (from) :</p>

                            <div className="mb-3" style={{ marginLeft: "5%" }}>
                                <span className="fw-bold">{p.businessInfo.societe_livrais}</span>
                                <br></br>
                                {p.businessInfo.adress_livrais}<br></br>
                                {p.businessInfo.adress_comp_livrais !== null && <Fragment>
                                    {p.businessInfo.adress_comp_livrais}<br></br>
                                </Fragment>}
                                {p.businessInfo.pays_livrais}<br></br>
                            </div>

                            <p>A l'attention de  (for the attention of) :</p>

                            {p.businessInfo.nom_chef_proj}<br></br>
                            {p.businessInfo.mail_chef_proj}
                        </td>

                    </tr>
                </thead>

                <tbody></tbody>
            </table>

            <table className="table table-bordered">
                <thead></thead>
                <tbody>
                    <tr>
                        <td className="text-center">Détail du colisage (Packages details)</td>
                    </tr>

                    {
                        crate.map((v, k) => {
                            return <tr key={"tdzagfdygze" + k}>
                                <td>
                                    <div className="input-group mb-3">
                                        <select className="form-select"
                                            data-row={k} data-field="type" onChange={updateCrate} value={v.type}>
                                            <option value="">Choose...</option>
                                            <option value="Crate">Crate</option>
                                            <option value="Envelope">Envelope</option>
                                            <option value="Package">Package</option>
                                            <option value="Plastic pallet">Plastic pallet</option>
                                        </select>

                                        <input type="number" className="form-control" placeholder="n°"
                                            data-row={k} data-field="crate" onChange={updateCrate} value={v.crate} />

                                        <span className="input-group-text">Machine</span>
                                        <select className="form-select"
                                            data-row={k} data-field="machine" onChange={updateCrate} value={v.machine}>
                                            <option value="">Choose...</option>
                                            <option value="Spare parts">Spare parts</option>
                                            {mc.map((v, k) => {
                                                return <option key={"fzefffg" + k}
                                                    value={p.machines[v].typ_config + " " + p.machines[v].no_machine}>
                                                    {p.machines[v].typ_config + " " + p.machines[v].no_machine}
                                                </option>
                                            })}
                                        </select>

                                        <button className="btn btn-outline-danger" data-row={k} onClick={removeRow}>Effacer la ligne</button>
                                    </div>

                                    <div className="input-group mb-3">
                                        {
                                            v.type !== "Envelope" && <Fragment>
                                                <span className="input-group-text">Ext. dimensions</span>
                                                <input type="number" className="form-control" placeholder="Length"
                                                    data-row={k} data-field="l" onChange={updateCrate} value={v.l} />

                                                <input type="number" className="form-control" placeholder="Width"
                                                    data-row={k} data-field="w" onChange={updateCrate} value={v.w} />

                                                <input type="number" className="form-control" placeholder="Height"
                                                    data-row={k} data-field="h" onChange={updateCrate} value={v.h} />
                                            </Fragment>
                                        }

                                        <input type="number" className="form-control" placeholder="Weight"
                                            data-row={k} data-field="weight" onChange={updateCrate} value={v.weight} />
                                    </div>

                                    <div className="form-floating">
                                        <textarea className="form-control" placeholder="xxx" data-row={k} data-field="crateDesc"
                                            onChange={updateCrate} value={v.crateDesc}></textarea>
                                        <label for="floatingTextarea">Détail de crate</label>
                                    </div>
                                </td>
                            </tr>
                        })
                    }

                    <tr><td><button className="btn btn-secondary" onClick={addRow}>Ajouter une ligne</button></td></tr>
                </tbody>
            </table>

        </div>

        <div className="text-center mb-3">
            <button className="btn btn-success" onClick={submit} disabled={loading}>
                {loading && <ButtonWaiting />}
                Soumettre
            </button>
        </div>

        {user.signature === null && <CreateSignature />}

    </Fragment>

}

export default DeliveryNote;