import { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
//import BomDao from "../../services/machines/BomDao";
import getUrlParam, { forceString } from "../../functions/StrFunctions";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import MachineInfo from "../../services/machines/MachineInfo";
import TempTable from "./smallComp/TempTab";
import SavedTab from "./smallComp/SavedTab";
import Historic from "./smallComp/Historic";
import SpeTab from "./smallComp/SpeTab";
import StdTab from "./smallComp/StdTab";
import TempCompareTab from "./smallComp/TempCompareTab";
import BlockedTab from "./smallComp/BlockedTab";
import NoNeedTab from "./smallComp/NoNeedTab";
import { confirmAlert } from "react-confirm-alert";
import FocusTab from "./smallComp/FocusTab";
import FocusCompareTab from "./smallComp/FocusCompareTab";
import { ProgressBar } from "react-bootstrap";
import BomDaoV2 from "../../services/machines/BomDaoV2";
import Cabinets from "./smallComp/Cabinets";

const BomDetail = (props) => {
    const [machine, setMachine] = useState();
    const [machineInfo, setMachineInfo] = useState();
    const [typeBom, setTypeBom] = useState();
    const [temp, setTemp] = useState();
    const [existingBom, setExistingBom] = useState();
    const [focusBom, setFocusBom] = useState();
    const [blocked, setBlocked] = useState();
    const [notNeed, setNotNeed] = useState();
    const [speToCreate, setSpeToCreate] = useState();
    const [StdToCreate, setStdToCreate] = useState();
    const [historic, setHistoric] = useState([]);
    const [comparisonTemp, setComparisonTemp] = useState();
    const [comparisonFocus, setComparisonFocus] = useState();
    const [working, setWorking] = useState(false);
    const [progress, setProgress] = useState(-1);
    const [genericChecking, setgenericChecking] = useState(true);
    const [cabinets, setCabinets] = useState([]);

    const [tab, setTab] = useState();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const user = JSON.parse(localStorage.getItem("user"));
    const [readOnly] = useState(user && user.roles.includes("ROLE_BOM_VIEW"));

    /**
     * Get all infos on opening page
     */
    useEffect(() => {
        var machine = getUrlParam(props, "machine");
        var type = getUrlParam(props, "type");
        setMachine(machine);
        setTypeBom(type);

        //Get temp BOM
        BomDaoV2.getTempBomOnly(machine, type).then(
            (response) => {
                setTemp(response)
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

        //Get saved BOM
        BomDaoV2.getSavedBomOnly(machine, type).then(
            (response) => {
                setExistingBom(response)
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

        //Get Focus BOM
        BomDaoV2.getFocusBomOnly(machine, type).then(
            (response) => {
                setFocusBom(response)
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

        //Get machine infos
        MachineInfo.getOneMachineInfo(machine).then(
            (response) => {
                setMachineInfo(response)
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

        //Get historic
        BomDaoV2.getHistoric(machine, type).then(
            (response) => {
                setHistoric(response);
            },
            () => {
            }
        );

        if (type === "ELEC") {
            //Get cabinets
            BomDaoV2.getCabinets().then(
                (response) => {
                    setCabinets(response);
                },
                () => {
                }
            );
        }

    }, []);

    /**
     * Recalculate infos
     */
    useEffect(() => {

        // Calculate with saved table
        if (existingBom && existingBom.length > 0) articlesToCreate(existingBom);

        //Compare Two BOM: temp and saved OR saved and Focus
        if (existingBom && (temp && temp.length > 0)) {
            compareTemp(temp, existingBom);
        } else if (existingBom && (focusBom && focusBom.length > 0)) {
            compareFocus(existingBom, focusBom);
        }

    }, [temp, existingBom, focusBom]);

    useEffect(() => {
        if (!speToCreate || !genericChecking) return;

        setgenericChecking(true);

        BomDaoV2.getGenericCreated(speToCreate).then(
            (response) => {
                setSpeToCreate(response)
                setgenericChecking(false);
            },
            (error) => {
                setgenericChecking(false);
            }
        )


    }, [speToCreate]);


    /**
     * Change field and flag it
     * 
     * @param {*} e 
     */
    const onFieldChange = (e) => {
        var table = e.target.getAttribute("data-table");
        var k = e.target.getAttribute("data-key");
        var field = e.target.getAttribute("data-field");

        if (table === "N/A") table = "";

        var arr;
        if (table === "temp") {
            arr = [...temp];
            arr[k][field + "_changed"] = true;
            setTemp(arr);
        } else if (table === "") {
            arr = [...existingBom];
            arr[k][field + "_changed"] = true;
            setExistingBom(arr);
        }
    }

    /**
     * Save change in data base and flag it
     * @param {*} e 
     */
    const onFieldBlur = (e) => {
        var table = e.target.getAttribute("data-table");
        var field = e.target.getAttribute("data-field");
        var k = e.target.getAttribute("data-key");
        var value = e.target.value;

        if (table === "N/A") table = "";

        var arr;
        if (table === "temp") {
            var old_value = temp[k][field];
            if (old_value === value) return;

            arr = [...temp];
            arr[k][field + "_changing"] = true;
            arr[k][field] = value;
            arr[k].old_value = old_value;
            setTemp(arr);

            BomDaoV2.updateBomField(machine, "_" + table, field, arr[k], typeBom).then(
                (response) => {
                    var arr2 = [...temp];
                    delete arr2[k][field + "_changing"];
                    delete arr2[k].old_value;
                    //console.log(response)
                    //update article or ref
                    if (response.CDARL !== undefined) arr2 = updateRow(e, arr2, k, response);

                    setTemp(arr2);
                },
                (error) => {
                    var arr2 = [...temp];
                    delete arr2[k][field + "_changing"];
                    setTemp(arr2);
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                }
            )
        } else if (table === "") {
            var old_value = existingBom[k][field];
            if (old_value === value) return;

            arr = [...existingBom];
            arr[k][field + "_changing"] = true;
            arr[k][field] = value;
            arr[k].old_value = old_value;
            setExistingBom(arr);

            BomDaoV2.updateBomField(machine, table, field, arr[k], typeBom).then(
                (response) => {
                    var arr2 = [...existingBom];
                    delete arr2[k][field + "_changing"];
                    delete arr2[k].old_value;

                    //update article or ref
                    arr2 = updateRow(e, arr2, k, response);

                    setExistingBom(arr2);
                },
                (error) => {
                    var arr2 = [...existingBom];
                    delete arr2[k][field + "_changing"];
                    setExistingBom(arr2);
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                }
            )
        }
    }

    /**
     * update function
     * 
     * @param {*} e 
     * @param {*} arr2 
     * @param {*} k 
     * @param {*} response 
     * @returns 
     */
    function updateRow(e, arr2, k, response) {

        if (response.CDARL && response.CDARL !== '') {
            arr2[k].ar = response.CDARL;
            arr2[k].maker = response.ZN01PA;
            arr2[k].ref = response.ZN02PA;
            arr2[k].cat = response.ZN15PA;
            arr2[k].french_label = response.DES1L;
        } else {
            arr2[k].ar = "";
            arr2[k].maker = "";
            arr2[k].ref = response.ZN02PA;
            arr2[k].cat = "";
            arr2[k].french_label = "";
        }

        return arr2;
    }

    /**
     * Check article to create
     */
    const articlesToCreate = (arr) => {

        var std = new Array(), spe = new Array(), blc = new Array();
        var noNeed = new Array(), old = new Array();
        arr.map((v, k) => {

            if (!v.se.endsWith("EG") && v.ar === "" && parseInt(v.is_spe) > 0) {
                v.parent_key = k;
                spe.push(v);

                //It's standard if it's not in general BOM and the ref do not contains business code
                //Or it's in general BOM but the ref do not starts with NM-, like PR ou PL for frames
            } else if ((!v.se.endsWith("EG") && v.ar === "" && parseInt(v.is_spe) === 0)
                || (v.se.endsWith("EG") && v.ar === "" && parseInt(v.is_spe) === 0 && !forceString(v.ref).startsWith("NM-"))) {
                v.parent_key = k;
                std.push(v);
            }

            if (v.BLOCL !== undefined && v.BLOCL !== "N"
                && (v.ZN15PA !== null && !v.ZN15PA.toUpperCase().startsWith("CSM")
                    && !v.ZN15PA.toUpperCase().startsWith("CMP") && !v.ZN15PA.toUpperCase().startsWith("HST PNEU"))
                && parseFloat(v.stock_at_term) <= 0) {
                v.parent_key = k;
                blc.push(v);
            }

            if (v.GEBEL !== undefined && v.GEBEL === "N" && parseFloat(v.stock_at_term) <= 0
                && (v.ZN15PA !== null && !v.ZN15PA.toUpperCase().startsWith("CSM") && !v.ZN15PA.toUpperCase().startsWith("CMP"))) {
                v.parent_key = k;
                noNeed.push(v);
            }
        });

        if (spe.length > 0) setSpeToCreate(spe);
        if (std.length > 0) setStdToCreate(std);
        if (blc.length > 0) setBlocked(blc);
        if (noNeed.length > 0) setNotNeed(noNeed);
    }

    /**
     * Compare temp BOm with Intranet BOM
     * @param {*} temp 
     * @param {*} existingBom 
     */
    const compareTemp = (temp, existingBom) => {
        var comparison = new Array(), updated = new Array();
        var search;
        var flag = false;

        //Check added articles
        temp.map((v, k) => {
            //console.log(v)
            search = v.se.trim().replace(".0", '').toUpperCase()
                + v.rep.trim().toUpperCase() + v.ref.replace(/ /g, '').trim().toUpperCase();
            flag = false;

            existingBom.map(v2 => {
                if (search === v2.se.trim().toUpperCase() + v2.rep.replace(".0", '').toUpperCase().trim() + v2.ref.replace(/ /g, '').trim().toUpperCase()) {
                    flag = true;
                    return;
                }
            });

            if (!flag) {
                v.table = "temp";
                v.parent_key = k;
                v.type_rst = "add";
                comparison.push(v);
            }
        });

        // Check removed articles
        existingBom.map((v, k) => {
            search = v.se.toUpperCase().trim() + v.rep.replace(".0", '').toUpperCase().trim() + v.ref.replace(/ /g, '').trim().toUpperCase();
            flag = false;

            temp.map(v2 => {
                if (search === v2.se.toUpperCase().trim() + v2.rep.replace(".0", '').toUpperCase().trim() + v2.ref.replace(/ /g, '').trim().toUpperCase()) {
                    flag = true;

                    //Check if qty are the same
                    if (parseFloat(v.qty) !== parseFloat(v2.qty)) {
                        v2.table = "N/A";
                        v2.parent_key = k;
                        v2.type_rst = "update";
                        comparison.push(v2);
                    }
                    return;
                }
            });

            if (!flag) {
                v.table = "temp";
                v.parent_key = k;
                v.type_rst = "remove";
                comparison.push(v);
            }
        });


        setComparisonTemp(comparison)

    }

    //Compare temp (intranet BOM) with existing (Focus BOM)
    const compareFocus = (existing, focus) => {
        var comparison = new Array();
        var search;
        var flag = false;

        //Check existing in focus articles to delete
        focus.map((v, k) => {
            search = forceString(v.se).trim() + forceString(v.rep).replace('.0').toUpperCase().replace(".0", '').trim()
                + forceString(v.ar).replace(/ /g, '').trim().toUpperCase();
            flag = false;

            //Ignore CSM & CMP
            if (!forceString(v.cat).toUpperCase().startsWith("CSM") && !forceString(v.cat).toUpperCase().startsWith("CMP") &&
                !forceString(v.ref).startsWith("NM-") && !forceString(v.ref).startsWith("NE-")) {
                existing.map(v2 => {
                    if (search === forceString(v2.se).trim() + forceString(v2.rep).replace('.0').toUpperCase().replace(".0", '').trim()
                        + forceString(v2.ar).replace(/ /g, '').trim().toUpperCase()) {
                        flag = true;
                        return;
                    }
                });

                if (!flag) {
                    v.table = "focus";
                    v.parent_key = k;
                    v.type_rst = "remove";
                    comparison.push(v);
                }
            }
        });

        // Check missing articles in intranet to add in Focus
        existing.map((v, k) => {

            //Search subset by ref
            if (!forceString(v.cat).toUpperCase().startsWith("CSM") && !forceString(v.cat).toUpperCase().startsWith("CMP") &&
                (forceString(v.ref).startsWith("NM-") || forceString(v.ref).startsWith("NE-"))) {
                //Search without index to avoid deletion old index
                search = forceString(v.se).trim() + forceString(v.rep).replace('.0').toUpperCase().replace(".0", '').trim()
                    + forceString(v.ref).replace(/ /g, '').trim().toUpperCase();
                flag = false;

                focus.map(v2 => {
                    if (search === forceString(v2.se).trim() + forceString(v2.rep).replace('.0').toUpperCase().replace(".0", '').trim()
                        + forceString(v2.ref).replace(/ /g, '').trim().toUpperCase()) {
                        flag = true;

                        //Check if qty are the same
                        if (parseFloat(v.qty) !== parseFloat(v2.qty)) {
                            v.table = "N/A";
                            v.parent_key = k;
                            v.type_rst = "update";
                            comparison.push(v);
                        }
                        return;
                    }
                });
            } else if (!forceString(v.cat).toUpperCase().startsWith("CSM") && !forceString(v.cat).toUpperCase().startsWith("CMP")) {
                search = forceString(v.se).trim() + forceString(v.rep).replace('.0').toUpperCase().replace(".0", '').trim()
                    + forceString(v.ar).replace(/ /g, '').trim().toUpperCase();
                flag = false;

                focus.map(v2 => {
                    if (search === forceString(v2.se).trim() + forceString(v2.rep).replace('.0').toUpperCase().replace(".0", '').trim()
                        + forceString(v2.ar).replace(/ /g, '').trim().toUpperCase()) {
                        flag = true;

                        //Check if qty are the same
                        if (parseFloat(v.qty) !== parseFloat(v2.qty)) {
                            v.table = "N/A";
                            v.parent_key = k;
                            v.type_rst = "update";
                            comparison.push(v);
                        }
                        return;
                    }
                });
            }



            if (!flag) {
                v.table = "N/A";
                v.parent_key = k;
                v.type_rst = "add";
                comparison.push(v);
            }
        });

        setComparisonFocus(comparison);
    }

    /**
     * Start purchase
     */
    const startPurchase = () => {
        confirmAlert({
            title: "Confirmation",
            message: "Etes-vous sûr de vouloir lancer la machine " + machine
                + " des nomenclatures en achats ? ",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setWorking(true);

                        BomDaoV2.notifyPurchaser(machine, typeBom, (focusBom && focusBom.length > 0)).then(
                            () => {
                                setMsg("Un mail a été envoyé, vous serez en copie !");
                                setWorking(false);
                                scrollToTop();
                            },
                            (error) => {
                                setWorking(false);
                                setError(errorManagement.handleError(props, error));
                                scrollToTop();
                            }
                        );
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    /**
     * Generate Excel file to import BOM into Focus
     */
    const generateFocusXls = () => {

        setWorking(true);

        //Get general BOM and check if the others subsets are created if there are
        var eg = new Array();
        var se, ref;
        var err = "";
        existingBom.map(v => {
            se = v.se.trim();
            ref = v.ref.toString().replace(/ /g, '').trim().toUpperCase();
            if (se.endsWith("EG") && (ref.startsWith("NM-") || ref.startsWith("NE-"))) eg.push(v);
            if (v.cat === null || v.cat.trim === "" || v.cat === "?") v.cat = "Spé";
        });

        if (err !== "") {
            setError(err);
            scrollToTop();
            return;
        }

        var othSubset = new Array();
        setError();

        var isEc16 = machineInfo.typ_config.startsWith("EC16");
        eg.map(v => {
            if (!isEc16)
                if (v.ref.split("-")[2].charAt(0) !== machineInfo.no_ligne) othSubset.push(v.ref)
        });

        if (othSubset.length > 0) {
            BomDaoV2.getOtherMachineSubset(othSubset).then(
                (response) => {
                    var err = "";
                    var flag = false;
                    var otSb = new Array();

                    othSubset.map(v => {
                        flag = false;
                        response.map(v2 => {
                            if (v.trim() === v2.destt.trim()) {
                                otSb.push({
                                    code: v2.cdart,
                                    ref: v.trim()
                                });
                                flag = true;
                            }
                        });

                        if (!flag) err += "<li>" + v + "</li>";
                    });

                    if (err !== "") {
                        setError("Des sous-ensembles font référence à d'autres sous-ensemble qui n'ont pas été importés "
                            + "dans Focus, veuillez commencer par ceux-là: " + err);
                        setWorking(false);
                    } else {
                        //merge founded codes with bom array
                        var arr = [...existingBom];
                        arr.map(v => {
                            var se = v.se.trim();
                            var ref = v.ref.toString().replace(/ /g, '').trim().toUpperCase();
                            if (se.endsWith("EG") && (ref.startsWith("NM-") || ref.startsWith("NE-"))) {
                                //Search the code
                                otSb.map(v2 => {
                                    if (ref.trim() === v2.ref.trim()) {
                                        v.ar = v2.code;
                                        return;
                                    }
                                })
                            }
                        });

                        setExistingBom(arr);
                        createFile(arr);
                    }
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                    setLoading(false);
                }
            );
        } else {
            createFile(existingBom);
        }

    }

    /**
     * Create and download file
     * @param {*} arr 
     */
    const createFile = (arr) => {
        BomDaoV2.firstImportFile(machineInfo, arr, typeBom).then(
            (response) => {
                setMsg(response);
                setWorking(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
                setWorking(false);
            }
        )
    }

    const syncCat = async () => {
        var start = 0, end = 100;
        setProgress(0);

        while (start < existingBom.length) {
            var arr = existingBom.slice(start, end);

            var res = await BomDaoV2.refreshCategories(arr);
            setProgress(((end / existingBom.length) * 100).toFixed(0));
            start = end;
            end = Math.min(end + 100, existingBom.length);
        }

        window.location.reload();
    }

    return <Fragment>
        <h4 className="text-center">Nomenclature {machineInfo &&
            <a target="_blank" href={"/machine?machine=" + machineInfo.no_machine}>{machineInfo.no_machine} ({machineInfo.typ_config})</a>}</h4>
        {
            machineInfo && <h5 className="text-center">
                Affaire: <a target="_blank" href={"/business?code=" + machineInfo.affaire}>{machineInfo.affaire}</a></h5>
        }

        {loading && <WaitingRoundSnippers />}
        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}


        <div className="display-flex">
            {/** NAVBAR */}
            <div style={{ marginRight: "10px", minWidth: "150px" }}>
                <ul className="nav flex-column">
                    {(temp && temp.length > 0) && <li className="nav-item">
                        <a className={"nav-link fw-bold " + (tab === "temp" ? "bg-info bg-gradient text-white" : "")}
                            style={{ borderRadius: "0.5em" }} href="#" onClick={() => setTab("temp")}>NM Excel</a>
                    </li>
                    }

                    {(existingBom && existingBom.length > 0) && <li className="nav-item">
                        <a className={"nav-link fw-bold " + (tab === "saved" ? "bg-info bg-gradient text-white" : "")}
                            style={{ borderRadius: "0.5em" }} href="#" onClick={() => setTab("saved")}>NM intranet</a>
                    </li>
                    }

                    {(!temp || temp.length === 0) && <Fragment>

                        {
                            (speToCreate && speToCreate.length > 0) &&
                            <li className="nav-item">
                                <a className={"nav-link fw-bold " + (tab === "speToCreate" ? "bg-info bg-gradient text-white" : "")}
                                    style={{ borderRadius: "0.5em" }} href="#" onClick={() => setTab("speToCreate")}>
                                    {speToCreate.length} spé à créer
                                </a>
                            </li>
                        }

                        {
                            (StdToCreate && StdToCreate.length > 0) &&
                            <li className="nav-item">
                                <a className={"nav-link fw-bold " + (tab === "StdToCreate" ? "bg-info bg-gradient text-white" : "")}
                                    style={{ borderRadius: "0.5em" }} href="#" onClick={() => setTab("StdToCreate")}>
                                    {StdToCreate.length} std à créer
                                </a>
                            </li>
                        }

                        {
                            (focusBom && focusBom.length > 0) &&
                            <li className="nav-item">
                                <a className={"nav-link fw-bold " + (tab === "focusBom" ? "bg-info bg-gradient text-white" : "")}
                                    style={{ borderRadius: "0.5em" }} href="#" onClick={() => setTab("focusBom")}>
                                    NM Focus
                                </a>
                            </li>
                        }

                        {
                            (blocked && blocked.length > 0) &&
                            <li className="nav-item">
                                <a className={"nav-link fw-bold " + (tab === "blocked" ? "bg-info bg-gradient text-white" : "")}
                                    style={{ borderRadius: "0.5em" }} href="#" onClick={() => setTab("blocked")}>
                                    {blocked.length} art. bloqués
                                </a>
                            </li>
                        }

                        {
                            (notNeed && notNeed.length > 0) &&
                            <li className="nav-item">
                                <a className={"nav-link fw-bold " + (tab === "notNeed" ? "bg-info bg-gradient text-white" : "")}
                                    style={{ borderRadius: "0.5em" }} href="#" onClick={() => setTab("notNeed")}>
                                    {notNeed.length} à ne pas cmd
                                </a>
                            </li>
                        }
                    </Fragment>
                    }

                    {
                        comparisonTemp &&
                        <li className="nav-item">
                            <a className={"nav-link fw-bold " + (tab === "comparisonTemp" ? "bg-info bg-gradient text-white" : "")}
                                style={{ borderRadius: "0.5em" }} href="#" onClick={() => setTab("comparisonTemp")}>
                                {comparisonTemp.length} à ajouter/supp.
                            </a>
                        </li>
                    }

                    {
                        (!comparisonTemp && comparisonFocus) &&
                        <li className="nav-item">
                            <a className={"nav-link fw-bold " + (tab === "comparisonFocus" ? "bg-info bg-gradient text-white" : "")}
                                style={{ borderRadius: "0.5em" }} href="#" onClick={() => setTab("comparisonFocus")}>
                                {comparisonFocus.length} à ajouter/supp.
                            </a>
                        </li>
                    }

                    {historic && historic.length > 0 && <li className="nav-item">
                        <a className={"nav-link fw-bold " + (tab === "historic" ? "bg-info bg-gradient text-white" : "")}
                            style={{ borderRadius: "0.5em" }} href="#" onClick={() => setTab("historic")}>Historique</a>
                    </li>
                    }

                    {typeBom && typeBom === "ELEC" && cabinets.length > 0 && <li className="nav-item">
                        <a className={"nav-link fw-bold " + (tab === "cabinets" ? "bg-info bg-gradient text-white" : "")}
                            style={{ borderRadius: "0.5em" }} href="#" onClick={() => setTab("cabinets")}>Grille de prix des armoires</a>
                    </li>
                    }

                    {
                        (existingBom && existingBom.length > 0 && (user && user.roles.includes("ROLE_DESIGNER"))) &&
                        <button className={"btn btn-" + (focusBom && focusBom.length > 0 ? "warning" : "success")}
                            onClick={startPurchase} disabled={working}>
                            {working && <ButtonWaiting />}
                            {(focusBom && focusBom.length > 0) ? "Lancer une mise à jour en achat" : "Lancer en achat"}
                        </button>
                    }

                    {
                        (existingBom && existingBom.length > 0 && existingBom[0].created_in_focus !== 'N' &&
                            !speToCreate && !StdToCreate && (!focusBom || focusBom.length === 0)
                            && (user && user.roles.includes("ROLE_PURCHASE"))) &&
                        <button className="btn btn-success" onClick={generateFocusXls} disabled={working}>
                            {working && <ButtonWaiting />}
                            Générer le fichier d'import
                        </button>
                    }

                    {
                        (existingBom && existingBom.length > 0) && <Fragment>
                            <br></br>
                            <button className="btn btn-outline-secondary" onClick={syncCat} disabled={working}>
                                {working && <ButtonWaiting />}
                                Synchroniser les catégories
                            </button>
                        </Fragment>
                    }

                </ul>

            </div>

            {/** TABLES */}
            <div style={{ width: "100%" }}>
                {progress >= 0 && <Fragment>
                    <h5 className="text-center">Mise à jour des catégories. Ne pas rafraîchir la page</h5>
                    <div className="col-8 offset-2">
                        <ProgressBar animated now={progress} label={`${progress}%`} />
                        <br></br>
                    </div>
                </Fragment>
                }

                {tab === "temp" &&
                    <TempTable list={temp} onFieldChange={onFieldChange} onFieldBlur={onFieldBlur}
                        machine={machine} typeBom={typeBom} existingBom={existingBom}
                        speToCreate={speToCreate} setMsg={setMsg} setError={setError} />}

                {tab === "saved" &&
                    <SavedTab list={existingBom} onFieldChange={onFieldChange} onFieldBlur={onFieldBlur}
                        machine={machine} typeBom={typeBom} existingBom={existingBom}
                        speToCreate={speToCreate} />
                }

                {tab === "focusBom" &&
                    <FocusTab list={focusBom} />
                }

                {tab === "speToCreate" &&
                    <SpeTab list={speToCreate} onFieldChange={onFieldChange} onFieldBlur={onFieldBlur}
                        machine={machine} typeBom={typeBom} existingBom={existingBom} genericChecking={genericChecking} />
                }

                {tab === "StdToCreate" &&
                    <StdTab list={StdToCreate} onFieldChange={onFieldChange} onFieldBlur={onFieldBlur}
                        machine={machine} typeBom={typeBom} setMsg={setMsg} />
                }

                {
                    tab === "comparisonTemp" &&
                    <TempCompareTab list={comparisonTemp} onFieldChange={onFieldChange} onFieldBlur={onFieldBlur}
                        machine={machine} typeBom={typeBom} />
                }

                {
                    (tab === "comparisonFocus" && machineInfo) &&
                    <FocusCompareTab list={comparisonFocus} onFieldChange={onFieldChange} onFieldBlur={onFieldBlur}
                        machine={machine} typeBom={typeBom} machineInfo={machineInfo} setError={setError} setMsg={setMsg} />
                }

                {
                    tab === "blocked" &&
                    <BlockedTab list={blocked} onFieldChange={onFieldChange} onFieldBlur={onFieldBlur}
                        machine={machine} typeBom={typeBom} />
                }

                {
                    tab === "notNeed" &&
                    <NoNeedTab list={notNeed} onFieldChange={onFieldChange} onFieldBlur={onFieldBlur}
                        machine={machine} typeBom={typeBom} />
                }

                {tab === "cabinets" && <Cabinets cabinets={cabinets} setCabinets={setCabinets} props={props}
                    setError={setError} />}


                {tab === "historic" && <Historic historic={historic} />}
            </div>
        </div>



    </Fragment>

}

export default BomDetail;