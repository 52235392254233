import axios from "axios";
import { dataURLtoFile } from "../../functions/StrFunctions";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL +"customer-client/hts";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && "\"Bearer " + user.accessToken + "\"";

const getForms = () => {
    return axios
    .post(API_URL + "/emptyForm",
    {
        fake_header: authHeader()
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getLastClassNbList = () => {
    return axios
    .post(API_URL + "/get-last-classificationNb-list",
    {
        fake_header: authHeader()
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const saveRequest = (form, article, classificationCode) => {
    return axios
    .post(API_URL + "/save",
    {
        fake_header: authHeader(),
        form: form,
        article: article,
        classificationCode: classificationCode
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getRequestList = () => {
    return axios
    .post(API_URL + "/list",
    {
        fake_header: authHeader(),
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getRequestListSearch = (article, user, cat, classNumber) => {
    return axios
    .post(API_URL + "/search/list",
    {
        fake_header: authHeader(),
        art: article,
        user: user,
        cat: cat,
        classCode: classNumber
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getAnswers = (article, date) => {
    return axios
    .post(API_URL + "/get-answers",
    {
        fake_header: authHeader(),
        article: article,
        date: date
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const cancelRequest = (article, date) => {
    return axios
    .post(API_URL + "/cancel",
    {
        fake_header: authHeader(),
        article: article,
        date: date
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const updateHtsCode = (article, date, code, attachments) => {
    return axios
    .post(API_URL + "/set-hst-code",
    {
        fake_header: authHeader(),
        article: article,
        date: date,
        code: code,
        attachments: attachments
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const sendEmergencyMail = () => {
    return axios
    .post(API_URL + "/send-emergency-email",
    {
        fake_header: authHeader()
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const requestAddInfo = (article, date, comment) => {
    return axios
    .post(API_URL + "/request-add-info",
    {
        fake_header: authHeader(),
        article: article,
        date: date,
        comment: comment
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const saveAttachment = (files, coms, article) => {
    let formData = new FormData();

    formData.append("article", article);

    files.forEach(file => {
        formData.append("files", file);
    });

    coms.forEach(com => {
        formData.append("coms", com);
    });

    formData.append("fake_header", token);

    return axios({
        method: "post",
        url: API_URL + "/save/attachment",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    })
        .then((response) => {
            return response.data;
        });
}

const deleteAttachmentById = (attachments) => {
    return axios
    .post(API_URL + "/delete/attachment/by-id",
    {
        fake_header: authHeader(),
        attachments: attachments
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

export const getAttachments = (article, date) => {
    return axios
        .post(API_URL + "/get/attachment", {
            fake_header: authHeader(),
            article:article, 
            date: date
        }, 
        { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}



export default {
    getForms,
    getLastClassNbList,
    saveRequest,
    getRequestList,
    sendEmergencyMail,
    getRequestListSearch,
    getAnswers,
    cancelRequest,
    updateHtsCode,
    requestAddInfo,
    saveAttachment,
    deleteAttachmentById,
    getAttachments
}