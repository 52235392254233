import { nbFormat } from "../../../../functions/StrFunctions";

const CReview = (props) => {
    return (
        < div className="table-responsive">
            <table className="table businessCardStyle">
                <thead>
                    <tr className="table-dark text-center rounded">
                        <th scope="col" colSpan={3}>Contract Review</th>
                    </tr>
                </thead>
                <tbody>
                    <tr >
                        <td className="border-end">
                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{width: "150px"}}>Purchase order</span>
                                <input type="text" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.cde_client} data-field="cde_client" />

                                <span className="input-group-text">Date</span>
                                <input type="date" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.date_commande} data-field="date_commande" />
                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{width: "150px"}}>Offer</span>
                                <input type="text" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.offre_cli} data-field="offre_cli" />

                                <span className="input-group-text">Date</span>
                                <input type="date" className="form-control" readOnly={props.readOnly}
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.date_offer} data-field="date_offer" />
                            </div>
                        </td>

                        <td className="border-end">
                            <div className="input-group mb-3">
                                <span className="input-group-text">Value</span>
                                <input type="text" className="form-control" readOnly
                                    defaultValue={nbFormat(props.focusInfo.amount, 'en-US', 2)} data-field="date_commande"
                                />
                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text">NDA</span>
                                <input type="text" className="form-control"
                                    onBlur={props.updateBusinessField} defaultValue={props.businessInfo.nda} readOnly={props.readOnly} data-field="nda" />
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={2} className="border-end">
                            <div className="input-group">
                                <span className="input-group-text">Other contractual documentation</span>
                                <textarea className="form-control" onBlur={props.updateBusinessField} 
                                defaultValue={props.businessInfo.other_ct_doc} data-field="other_ct_doc" ></textarea>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>

        </div>
    )
}

export default CReview;